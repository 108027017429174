let init = {
  selectedType: null,
  selectedId: null,
  newX: 0,
  newY: 0,
}

function dropActions (state = init, action) {

  if (action.type === 'SET_DROP_OBJECT') {
    return {
      ...state,
      selectedType: action.objectType,
      selectedId: action.objectId,
      newX: 0,
      newY: 0,
    }

  } else {
    return { ...state }
  }
}

export default dropActions