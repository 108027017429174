import React from 'react'
import { NavLink } from 'react-router-dom'

import Icon, { iconTypes } from './icons/IconFactory'
import Text from './Text'

import './ListMenuNavItem.scss'

function getListMenuNavItemContent (icon, title, arrow, disabled, tooltip) {
  return (
    <div className={'list-menu-nav-item' + (disabled ? ' disabled' : '')} title={tooltip}>
      {icon &&
      <div className={'list-menu-nav-item-icon'}>
        <Icon name={icon}/>
      </div>
      }
      <Text className={'list-menu-nav-text' + (disabled ? '-disabled' : '')}>{title}</Text>
      {
        arrow &&
        <div className={'list-menu-nav-item-arrow'}>
          <Icon name={iconTypes.arrow}/>
        </div>
      }
    </div>
  )
}

/**
 * @return {null}
 */
function ListMenuNavItem ({ replace, to, activeClassName, icon, title, arrow, visible = true, disabled = false, tooltip = '' }) {
  if (!visible) {
    return null
  }

  return (
    <li className={'list-menu-nav-list-item'}>
      {!disabled ?
        <NavLink replace={replace} to={to} activeClassName={activeClassName}>
          {getListMenuNavItemContent(icon, title, arrow, disabled, tooltip)}
        </NavLink> :
        <div>
          {getListMenuNavItemContent(icon, title, arrow, disabled, tooltip)}
        </div>
      }
    </li>
  )

}

export default ListMenuNavItem