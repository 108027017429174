import React, { Component, createRef } from 'react'
import { settings } from '../config.service'
import './AuthProvider.scss'
import AuthContext from './AuthContext'
import AuthService from './auth.service'
import { analyticsService } from '../analytics/analytics.service'
import { getLicense } from '../actions'
import { connect } from 'react-redux'

class AuthProvider extends Component {

  constructor (props, context) {
    super(props, context)
    this.serviceBarElementRef = createRef()
    this.state = {
      user: null,
    }


    // notify when service bar is defined
    window.customElements.whenDefined('service-bar')
      .then(() => this.serviceBarDidMount())

  }

  componentDidMount () {
    // The 'current' property to access the 'service-bar' component gets assigned
    // when the 'AuthProvider' component mounts
    this.authService = AuthService.getInstance(this.serviceBarElementRef.current)

  }

  serviceBarDidMount () {
    this.authService.getUser()
      .then(user => {
        if(user){
          this.setState({ user: user });
        } else {
          // after the servicebar has mount and we have no user, we redirect the user to identity's login page, using the service bar 
          !AuthService.loginFinished(user, window.location) && !AuthService.isLoginRedirect(window.location) && this.authService.login();
        }
      });

    analyticsService.init()
    this.props.getLicense()
  }

  render () {
    return (
      <AuthContext.Provider value={this.state}>
        <div className="service-bar">
          <service-bar ref={this.serviceBarElementRef}
                       is-spa={true}
                       client-id={settings.ssoClientId}
                       scope={settings.ssoScope}
                       settings-uri={settings.serviceBarSettingsUri}/>
        </div>
        {this.props.children}
      </AuthContext.Provider>
    )
  }

}

let mapStateToProps = (state) => {
  return {}
}
let mapDispatchToProps = {
  getLicense: getLicense,
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider)