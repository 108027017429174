import React from 'react'

const SvgAppCreatorLoungesIconInactive = props => (
  <svg className={props.className} viewBox="0 0 54 54">
    <path data-name="Rechteck 426" fill="none" d="M0 0h54v54H0z"/>
    <path
      data-name="Pfad 915"
      d="M26.148 24.115l-3.343 1.8-4.739-2.557 8.082-4.357zm.618.986l2.734 1.475-2.734 1.475-2.734-1.475zm8.708-1.736l-4.739 2.549-3.36-1.807v-5.106zm-9.323 5.673v5.588l-9.152-4.925v-5.6zm1.227 0l9.155-4.926v5.589l-9.155 4.926z"
      fill="#8f939d"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgAppCreatorLoungesIconInactive
