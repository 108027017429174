import { FULFILLED, PENDING, REJECTED } from '../../common/globalConstants'
import { httpClient } from '../../common/httpClient'

const FETCH_INDUSTRY_ATTRIBUTES = 'FETCH_INDUSTRY_ATTRIBUTES'

export const industryAttributesActionTypes = {

  FETCH_INDUSTRY_ATTRIBUTES: FETCH_INDUSTRY_ATTRIBUTES,
  FETCH_INDUSTRY_ATTRIBUTES_PENDING: FETCH_INDUSTRY_ATTRIBUTES + PENDING,
  FETCH_INDUSTRY_ATTRIBUTES_FULFILLED: FETCH_INDUSTRY_ATTRIBUTES + FULFILLED,
  FETCH_INDUSTRY_ATTRIBUTES_REJECTED: FETCH_INDUSTRY_ATTRIBUTES + REJECTED,
}

export function fetchIndustries () {
  return {
    type: industryAttributesActionTypes.FETCH_INDUSTRY_ATTRIBUTES,
    payload: httpClient.get(`/api/industry-attributes`),
  }
}
