import React, { Component } from 'react'
import { createOrganisationalUnitAssignment } from '../../floorplan/actions/actions'
import { connect } from 'react-redux'
import equal from 'fast-deep-equal'
import { dragBlockingItem, dropBlockingItem } from '../../floorplan/actions/ui-actions'
import { getAssignedDepartments } from '../../common/redux/organisationunits/organisational-units-reducer'

import './DraggedListItemRenderContainer.scss'

class DraggedListItemRenderContainer extends Component {

  constructor (props, context) {
    super(props, context)

    this.state = {
      'display': 'none',
      'color': '#ffffff',
    }
    this.dragged = null

  }

  componentDidMount () {
    window.addEventListener('mouseup', this.onDocumentMouseUp, true)
  }

  componentWillUnmount () {
    window.removeEventListener('mouseup', this.onDocumentMouseUp, true)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!equal(this.props.uiState.blockingItemDragged, prevProps.uiState.blockingItemDragged)) {
      this.createDragInObject(this.props.uiState.blockingItemDragged)
    }
    if (!equal(this.props.departments, prevProps.departments)) {
      this.removeDragInObject()
    }
  }

  onDocumentMouseMove = (event) => {

    let w = document.getElementById('TempBlock').offsetWidth / 2
    let h = document.getElementById('TempBlock').offsetHeight / 2
    let x = event.clientX - w
    let y = event.clientY - h
    document.getElementById('TempBlock').style.left = x + 'px'
    document.getElementById('TempBlock').style.top = y + 'px'
  }

  onDocumentMouseUp = (event) => {

    window.removeEventListener('mousemove', this.onDocumentMouseMove, true)

    if (this.dragged !== null) {

      let hoverList = document.querySelectorAll(':hover')
      let droppableFloorId = null
      let droppableBuildingId = null
      for (let j = 0; j < hoverList.length; j++) {
        if (hoverList[j].className === 'rowback') {
          const element = hoverList[j].parentNode.getElementsByClassName('blocks-row')[0]
          droppableFloorId = element.dataset.floorid
          droppableBuildingId = element.dataset.buildingid
        }
      }
      if (droppableFloorId === null) {
        for (let j = 0; j < hoverList.length; j++) {
          if (hoverList[j].className === 'blocks-row') {
            const element = hoverList[j]
            droppableFloorId = element.dataset.floorid
            droppableBuildingId = element.dataset.buildingid
          }
        }
      }
      if (droppableFloorId !== null) {
        const assignment = {
          buildingId: droppableBuildingId,
          floorId: droppableFloorId,
          index: 0,
        }

        if (!this.dragged.parentId) {
          this.dragged.organisationalUnits
            .filter(orgUnit => !orgUnit.assigned)
            .forEach(orgUnit => {
              this.props.createOrganisationalUnitAssignment(orgUnit.id, assignment)
            })
        } else {
          this.props.createOrganisationalUnitAssignment(this.dragged.id, assignment)
        }

        this.props.dropBlockingItem(true)
      }

      this.removeDragInObject()
    }

  }

  removeDragInObject = () => {
    this.setState({
      display: 'none',
    })

    this.dragged = null

    this.props.dragBlockingItem(null)
  }

  createDragInObject (id) {
    if (id !== null) {

      this.setDragInSceneObject(this.props.uiState.blockingItemDragged)

      let color = this.dragged.color
      let rgba = [255 * color.red, 255 * color.green, 255 * color.blue, color.alpha]
      this.setState({
        display: 'block',
        color: `rgba(${rgba})`,
      })

      window.addEventListener('mousemove', this.onDocumentMouseMove, true)

    }
  }

  setDragInSceneObject (id) {
    if (id != null) {
      this.props.unassignedDepartments.forEach(superDepartments => {
        if (superDepartments.id === id)
          this.dragged = superDepartments
        else if (superDepartments.organisationalUnits.length) {
          superDepartments.organisationalUnits.forEach(department => {
            if (department.id === id)
              this.dragged = department
          })
        }
      })
    } else {
      this.dragged = null
    }
  }

  render () {

    return (
      <div id={'TempBlock'} style={{ display: this.state.display, backgroundColor: this.state.color }}>

      </div>

    )
  }
}

let mapStateToProps = (state) => {
  return {
    uiState: state.floorPlanUiState,
    departments: getAssignedDepartments(state),
    unassignedDepartments: state.placedOrganisationalUnits.unassignedDepartments,
  }
}

let mapDispatchToProps = {
  dragBlockingItem: dragBlockingItem,
  dropBlockingItem: dropBlockingItem,
  createOrganisationalUnitAssignment: createOrganisationalUnitAssignment,
}

export default connect(mapStateToProps, mapDispatchToProps)(DraggedListItemRenderContainer)

