import { httpClient } from '../../../httpClient'
import { getProjectId, projectApi } from '../../../utils'
import { FULFILLED, PENDING, REJECTED } from '../../../globalConstants'
import { floorPlanActionTypes } from '../../../../floorplan/constants'
import { getUndoInformation } from '../../undo/undo-actions'

const FETCH_APPLICATION_PROFILES = 'FETCH_FLOORPLAN_APPLICATION_PROFILES'
const UPDATE_APPLICATION_PROFILES = 'UPDATE_APPLICATION_PROFILES'
const UPLOAD_APPLICATION_PROFILES = 'UPLOAD_APPLICATION_PROFILES'

export const applicationProfilesActionTypes = {

  FETCH_APPLICATION_PROFILES: FETCH_APPLICATION_PROFILES,
  FETCH_APPLICATION_PROFILES_PENDING: FETCH_APPLICATION_PROFILES + PENDING,
  FETCH_APPLICATION_PROFILES_FULFILLED: FETCH_APPLICATION_PROFILES + FULFILLED,
  FETCH_APPLICATION_PROFILES_REJECTED: FETCH_APPLICATION_PROFILES + REJECTED,

  UPDATE_APPLICATION_PROFILES: UPDATE_APPLICATION_PROFILES,
  UPDATE_APPLICATION_PROFILES_PENDING: UPDATE_APPLICATION_PROFILES + PENDING,
  UPDATE_APPLICATION_PROFILES_FULFILLED: UPDATE_APPLICATION_PROFILES + FULFILLED,
  UPDATE_APPLICATION_PROFILES_REJECTED: UPDATE_APPLICATION_PROFILES + REJECTED,

  UPLOAD_APPLICATION_PROFILES: UPLOAD_APPLICATION_PROFILES,
  UPLOAD_APPLICATION_PROFILES_PENDING: UPLOAD_APPLICATION_PROFILES + PENDING,
  UPLOAD_APPLICATION_PROFILES_FULFILLED: UPLOAD_APPLICATION_PROFILES + FULFILLED,
  UPLOAD_APPLICATION_PROFILES_REJECTED: UPLOAD_APPLICATION_PROFILES + REJECTED,

}

export function fetchApplicationProfiles () {
  return {
    type: applicationProfilesActionTypes.FETCH_APPLICATION_PROFILES,
    payload: httpClient.get(encodeURI(`${projectApi()}/application-profiles`)),
  }
}

export function updateApplicationProfiles (unitId, units) {

  console.log(units)

  return (dispatch) => {
    dispatch({
      type: applicationProfilesActionTypes.UPDATE_APPLICATION_PROFILES,
      payload: httpClient.patch(encodeURI(`${projectApi()}/application-profiles/${unitId}`), units),
    })
      .then(() => {
        dispatch(fetchApplicationProfiles())
        dispatch(getUndoInformation(getProjectId()))

      })
  }
}

export function downloadApplicationProfilesFile () {
  return {
    type: floorPlanActionTypes.FETCH_FILE,
    payload: httpClient.getFile(encodeURI(`${projectApi()}/application-profiles/xlsx`)),
  }
}

export function uploadApplicationProfilesFile (file, replace) {
  let form = new FormData()
  form.append('replace', replace)
  form.append('file', file)

  return (dispatch) => {
    dispatch({
      type: UPLOAD_APPLICATION_PROFILES,
      payload: httpClient.post(encodeURI(`${projectApi()}/application-profiles/xlsx`), form),
    })
      .then(() => dispatch(fetchApplicationProfiles()))

  }
}

