import React from 'react'

import Text from '../../../../common/components/Text'
import Icon from '../../../../common/components/icons/IconFactory'

import './IconField.scss'

function IconField ({ label, icon }) {

  return (
    <div className={'icon-field'}>
      {label && <Text outerClassName={'label'} component={'label'}>
        {label}
      </Text>}
      {icon && <Icon className={'icon'} name={icon}/>}
    </div>
  )
}

export default IconField