import React, { useState } from 'react'
import { ChromePicker } from 'react-color'
import { getRGBFromHex } from '../utils'

import './ColorPicker.scss'

function ColorPicker (props) {

  const color = getRGBFromHex(props.color)

  const [background, setBackground] =
    useState(color)

  const handleChangeComplete = data => {
    props.onChange(data)
    if (data.rgb !== background) {
      setBackground(data.rgb)
    }
  }

  return (
    <div className={'color-picker' + props.className}>
      <ChromePicker disableAlpha={true} color={background} onChange={handleChangeComplete}/>
    </div>
  )
}

export default ColorPicker