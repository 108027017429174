import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createNewProject, saveUserProject, uploadProjectFile } from '../../common/redux/projects/projects-actions'
import { fetchFloorIFCFile, fetchFloorPlanFile, fetchFloorPlanPdf, fetchPConExportFile, fetchProjectFile } from '../../floorplan/actions/actions'
import { getSelectedBuildingId, getSelectedFloorId } from '../../common/redux/appstate/appstate-reducer'

import { settings as config } from '../../common/config.service'

import ModalConsumer from '../../common/components/modal/ModalConsumer'
import { dialogNames } from '../../floorplan/constants'

import ListMenu from '../../common/components/ListMenu'
import ListSubMenuItem from './ListSubMenuItem'
import { ListMenuItem } from '../../common/components/ListMenuItem'

import UploadDialog from './UploadDialog'
import SummaryFileDownloadDialog from './dialogs/SummaryFileDownloadDialog'
import XLSXDownloadDialog from './dialogs/XLSXDownloadDialog'
import LoadProjectDialog from './dialogs/LoadProjectDialog'
import DeleteProjectDialog from './dialogs/DeleteProjectDialog'

import DropdownMenu from '../../common/components/DropdownMenu'

import './ProjectsMenu.scss'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

function ProjectsMenu ({context, onClose}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [pdfExportDisabled, setPdfExportDisabled] = useState(config.pdfExportDisabled)
  const [hoveredMenuItem, setHoveredMenuItem] = useState(null)

  const project = useSelector(state => state.projects.project)
  const buildingId = useSelector(state => getSelectedBuildingId(state))
  const floorId = useSelector(state => getSelectedFloorId(state))
  const advancedSettings = useSelector(state => state.settings.appSettings.advancedSettings)
  const { projectId } = useParams()

    const subHeader = project && project.companyName ? project.companyName : null

    return (
      <DropdownMenu
        onClose={() => closeMenu()}
        subHeader={subHeader}
        className={'projects-menu' + (advancedSettings ? ' dark-mode' : '')}>

        <ModalConsumer>
          {({ showModal }) => (
            <ListMenu className={advancedSettings ? 'dark-mode' : ''}>
              <ListMenuItem onClick={() => handleCreateNewClick()} onMouseEnter={(menuItem) => setHoveredMenuItem(menuItem)}>Create New</ListMenuItem>
              <ListMenuItem onClick={() => handleOpenModalClick(showModal, LoadProjectDialog)} onMouseEnter={(menuItem) => setHoveredMenuItem(menuItem)}>
                Open Recent...
              </ListMenuItem>
              <ListMenuItem disabled={!project}
                            onClick={() => handleOpenModalClick(showModal, DeleteProjectDialog)}
                            onMouseEnter={(menuItem) => setHoveredMenuItem(menuItem)}>
                Delete Project
              </ListMenuItem>
              <ListMenuItem disabled={!project || !buildingId || !floorId}
                            onClick={() => handleOpenModalClick(showModal, SummaryFileDownloadDialog)}
                            onMouseEnter={(menuItem) => setHoveredMenuItem(menuItem)}>
                Save Project as PDF
              </ListMenuItem>
              <ListMenuItem disabled={!project || !buildingId || !floorId}
                            onClick={() => handleFloorPlanPdfDownload()}
                            onMouseEnter={(menuItem) => setHoveredMenuItem(menuItem)}>
                Save Floorplan as PDF
              </ListMenuItem>
              <ListSubMenuItem className={advancedSettings ? 'dark-mode' : ''} title={'Export'}
                               onMouseEnter={(menuItem) => setHoveredMenuItem(menuItem)}
                               selectedMenuItem={hoveredMenuItem}>
                <ListMenu className={'submenu-export' + (advancedSettings ? ' dark-mode' : '')}>
                  <ListMenuItem disabled={!project}
                                onClick={() => handleProjectFileDownload(project.id)}>
                    Project File
                  </ListMenuItem>
                  <ListMenuItem disabled={!project || !buildingId || !floorId}
                                onClick={() => handleFloorPlanFileDownload(buildingId, floorId)}>
                    DXF-File
                  </ListMenuItem>
                  <ListMenuItem disabled={!project || !buildingId || !floorId}
                                onClick={() => handleFloorPlanIFCDownload(buildingId, floorId)}>
                    IFC-File
                  </ListMenuItem>
                  <ListMenuItem disabled={!project || !buildingId || !floorId}
                                onClick={() => handleOpenModalClick(showModal, XLSXDownloadDialog, { dialogType: dialogNames.XSLX_FURNITURE_FILE_DOWNLOAD })}>
                    Furniture XLS-File
                  </ListMenuItem>
                  <ListMenuItem disabled={!project || !buildingId || !floorId}
                                onClick={() => handleOpenModalClick(showModal, XLSXDownloadDialog, { dialogType: dialogNames.XSLX_DEPARTMENT_FILE_DOWNLOAD })}>
                    Departments XLS-File
                  </ListMenuItem>
                  <ListMenuItem disabled={!project || !buildingId || !floorId}
                                onClick={() => handlePConDownload(buildingId, floorId)}>
                    pCon Transfer-File
                  </ListMenuItem>
                </ListMenu>
              </ListSubMenuItem>
              <ListMenuItem onClick={() => handleOpenModalClick(showModal, UploadDialog,
                {
                  title: 'Upload project file',
                  upload: handleUploadCOPFile,
                })}
                            onMouseEnter={(menuItem) => setHoveredMenuItem(menuItem)}>
                Import Project-File...
              </ListMenuItem>


            </ListMenu>
          )}
        </ModalConsumer>
      </DropdownMenu>
    )


  function handleSetHoveredMenuItem (menuItem) {
    if (menuItem !== hoveredMenuItem) {
      setHoveredMenuItem(menuItem)
    }
  }

  function handlePConDownload (buildingId, floorId) {
    closeMenu()
    dispatch(fetchPConExportFile(buildingId, floorId))
  }

  function handleProjectFileDownload (projectId) {
    closeMenu()
    dispatch(fetchProjectFile(projectId))
  }

  function handleFloorPlanFileDownload (buildingId, floorId) {
    closeMenu()
    dispatch(fetchFloorPlanFile(buildingId, floorId))
  }

  function handleFloorPlanIFCDownload (buildingId, floorId) {
    closeMenu()
    dispatch(fetchFloorIFCFile(buildingId, floorId))
  }

  function handleFloorPlanPdfDownload () {
    closeMenu()
    dispatch(fetchFloorPlanPdf())
  }

  function handleCreateNewClick () {
    closeMenu()
    dispatch(createNewProject(advancedSettings)).then((response) => {
      navigate(response)
    }).catch((error) => {
      console.error('error', error)
    })



  }

  function handleSaveUserProject () {
    closeMenu()
    dispatch(saveUserProject(project.id, project.projectName))
  }

  function closeMenu () {
    onClose()
  }

  function handleOpenModalClick (showModal, component, data) {
    closeMenu()
    showModal(component, data)
  }

  function handleUploadCOPFile (file) {
    closeMenu()
    dispatch(uploadProjectFile(file))
  }

}


export default ProjectsMenu


