import React, { Component } from 'react'
import MenuSection from '../../../../common/components/MenuSection'
import ToggleSwitch from '../../../../common/components/ToggleSwitch'
import { connect } from 'react-redux'

import './SettingsWall.scss'
import { changeSideCode, changeWallCode, getUndoAvailable, storeApplicationBuilderUndo } from '../../../actions/frontendActions'
import { withTranslation } from 'react-i18next'

const switchTypeEnum = {
  WALL: 0,
  DOOR: 1,
  DISTANCE: 2,
  HALLWAY: 3,
}

const codeChars = {
  NONE: '-',
  WALL: '1',
  DOOR: 'd',
  HALLWAY: 'h',
  DISTANCE: 'c',
}

class SettingsWall extends Component {

  render () {
    let switches = this.calculateSwitchStates()
    const {t} = this.props

    let wallName

    switch (this.props.selectedWall) {
      case 1:
        wallName = t('left')
        break
      case 2:
        wallName = t('top')
        break
      case 3:
        wallName = t('right')
        break
      case 0: //fallthrough
      default:
        wallName = t('bottom')
    }

    return (
      <div className={'settings-wall'}>
        <MenuSection title={t('wall') + ' ' + wallName }>
          <ToggleSwitch className={'settings-wall-switch'}
                        label={t('wall')}
                        field={{
                          name: 'wall',
                          value: switches[0],
                          onChange: (event) => this.handleWallSettingChanged(switchTypeEnum.WALL, event),
                        }}/>
          <ToggleSwitch className={'settings-door-switch'}
                        label={t('door')}
                        field={{
                          name: 'door',
                          value: switches[1],
                          onChange: (event) => this.handleWallSettingChanged(switchTypeEnum.DOOR, event),
                        }}/>
          {this.props.selectedWall !== 0 &&
          <ToggleSwitch className={'settings-distance-switch'}
                        label={t('distance')}
                        field={{
                          name: 'distance',
                          value: switches[2],
                          onChange: (event) => this.handleWallSettingChanged(switchTypeEnum.DISTANCE, event),
                        }}/>
          }

          {this.props.selectedWall !== 0 &&
          <ToggleSwitch className={'settings-hallway-switch'}
                        label={t('hallway')}
                        field={{
                          name: 'hallway',
                          value: switches[3],
                          onChange: (event) => this.handleWallSettingChanged(switchTypeEnum.HALLWAY, event),
                        }}/>
          }
        </MenuSection>
      </div>
    )
  }

  handleWallSettingChanged (switchType, event) {
    let switchValue = event.target.checked
    let changePosition = this.props.selectedWall
    let wallCode = this.props.furnitureEnsemblePlan.wallCode.replace(/0/g, '-')
    let sideCode = this.props.furnitureEnsemblePlan.sideCode

    this.props.getUndoAvailable()
    this.props.storeApplicationBuilderUndo()

    switch (switchType) {
      case switchTypeEnum.WALL:
        wallCode = this.setCharAt(wallCode, changePosition, switchValue ? codeChars.WALL : codeChars.NONE)
        this.props.changeWallCode(wallCode)
        break
      case switchTypeEnum.DOOR:
        wallCode = this.setCharAt(wallCode, changePosition, switchValue ? codeChars.DOOR : codeChars.WALL)
        this.props.changeWallCode(wallCode)
        break
      case switchTypeEnum.DISTANCE:
        sideCode = this.setCharAt(sideCode, changePosition, switchValue ? codeChars.DISTANCE : codeChars.NONE)
        this.props.changeSideCode(sideCode)
        break
      case switchTypeEnum.HALLWAY:
        sideCode = this.setCharAt(sideCode, changePosition, switchValue ? codeChars.HALLWAY : codeChars.NONE)
        this.props.changeSideCode(sideCode)
        break
      default:
        console.error('Wrong switchType for handling switches!')
    }
    this.props.getUndoAvailable()
  }

  setCharAt (code, pos, value) {
    //console.log('replace char at pos: ' + pos + ' with char: ' + value)
    if (pos > code.length - 1) return code
    return code.substring(0, pos) + value + code.substring(pos + 1)
  }

  calculateSwitchStates () {
    let result = [false, false, false, false]

    if (!this.props.furnitureEnsemblePlan)
      return result

    let wallCode = this.props.furnitureEnsemblePlan.wallCode.replace(/0/g, '-')
    let sideCode = this.props.furnitureEnsemblePlan.sideCode

    result[0] = wallCode[this.props.selectedWall] !== codeChars.NONE
    result[1] = wallCode[this.props.selectedWall] === codeChars.DOOR
    result[2] = sideCode[this.props.selectedWall] === codeChars.DISTANCE
    result[3] = sideCode[this.props.selectedWall] === codeChars.HALLWAY

    return result
  }

}

let mapStateToProps = (state) => {
  return {
    furnitureEnsemblePlan: state.furnitureEnsembles.furnitureEnsemblePlan,
    selectedWall: parseInt(state.applicationBuilderUiState.selectedElement.data),
  }
}

let mapDispatchToProps = {
  changeWallCode: changeWallCode,
  changeSideCode: changeSideCode,
  storeApplicationBuilderUndo: storeApplicationBuilderUndo,
  getUndoAvailable: getUndoAvailable,
}

export default withTranslation() (connect(mapStateToProps, mapDispatchToProps)(SettingsWall))
