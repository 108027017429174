export function insertChildIntoParent (parent, child, index) {
    const children = parent.children

    parent.children = []

    children.splice(index, 0, child)

    children.forEach(child => parent.add(child))

    return parent
}

export function removeAllChildrenFromParent(parent) {
    for (let i = parent.children.length - 1; i >= 0; i--) {
        parent.remove(parent.children[i])
    }
}

export function addAllChildrenToOtherGroup(group, otherGroup) {
    for (let i = group.children.length - 1; i >= 0; i--) {
        const v = group.children[i]

        otherGroup.add(v)
        group.remove(v)
    }
}