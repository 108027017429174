import { FULFILLED, PENDING, REJECTED } from '../../../floorplan/constants'
import { httpClient } from '../../httpClient'
import { getProjectId, projectApi } from '../../utils'
import { getUndoInformation } from '../undo/undo-actions'

const FETCH_VARIATIONS = 'FETCH_VARIATIONS'
const CREATE_VARIATION = 'CREATE_VARIATION'
const UPDATE_VARIATION = 'UPDATE_VARIATION'
const DELETE_VARIATION = 'DELETE_VARIATION'

export const variationsActionTypes = {

  FETCH_VARIATIONS: FETCH_VARIATIONS,
  FETCH_VARIATIONS_PENDING: FETCH_VARIATIONS + PENDING,
  FETCH_VARIATIONS_FULFILLED: FETCH_VARIATIONS + FULFILLED,
  FETCH_VARIATIONS_REJECTED: FETCH_VARIATIONS + REJECTED,

  CREATE_VARIATION: CREATE_VARIATION,
  CREATE_VARIATION_PENDING: CREATE_VARIATION + PENDING,
  CREATE_VARIATION_FULFILLED: CREATE_VARIATION + FULFILLED,
  CREATE_VARIATION_REJECTED: CREATE_VARIATION + REJECTED,

  UPDATE_VARIATION: UPDATE_VARIATION,
  UPDATE_VARIATION_PENDING: UPDATE_VARIATION + PENDING,
  UPDATE_VARIATION_FULFILLED: UPDATE_VARIATION + FULFILLED,
  UPDATE_VARIATION_REJECTED: UPDATE_VARIATION + REJECTED,

  DELETE_VARIATION: DELETE_VARIATION,
  DELETE_VARIATION_PENDING: DELETE_VARIATION + PENDING,
  DELETE_VARIATION_FULFILLED: DELETE_VARIATION + FULFILLED,
  DELETE_VARIATION_REJECTED: DELETE_VARIATION + REJECTED,

  UPDATE_VARIATION_ID: 'UPDATE_VARIATION_ID',
}

export const fetchVariationsAndUpdateQuery = (projectId, variationId, currentSearch, currentPathname) => {

  /*function isExistingVariation (variations, variationId) {

    if (variationId) {
      return !!variations.find(variation => variation.id === variationId)
    }
    return false
  }

  function getDefaultVariation (variations) {
    return variations && variations.length > 0 ? variations[0].id : null
  }

  const dispatch = useDispatch();

  return dispatch(fetchVariationsAPI(projectId))
    .then(({ value }) => {
      let givenVariationExists = isExistingVariation(value.data, variationId);
      const variation = givenVariationExists ? variationId : getDefaultVariation(value.data);

      // Annahme: setQueryParam gibt jetzt die neue URL zurück
      const newSearch = setQueryParam('variation', variation, currentSearch, currentPathname);
      dispatch(getUndoInformation(projectId));

      // Rückgabe der für die Navigation notwendigen Daten
      return {
        pathname: currentPathname,
        search: newSearch,
      };
    });*/

}

export function fetchVariations (projectId) {
  return (dispatch) => {
    dispatch(fetchVariationsAPI(projectId))
      .then(() => {
        dispatch(getUndoInformation(projectId))
      })
  }
}

export function fetchVariationsAPI (projectId) {
  return {
    type: variationsActionTypes.FETCH_VARIATIONS,
    payload: httpClient.get(projectApi(projectId) + '/variations'),
  }
}

export function updateVariationId (variationId) {
  return {
    type: variationsActionTypes.UPDATE_VARIATION_ID,
    payload: variationId,
  }

}

export function createVariation (projectId, variationData) {
  return (dispatch) => {
    dispatch(createVariationAPI(projectId, variationData))
      .then(() => {
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function createVariationAPI (projectId, variationData) {
  return {
    type: variationsActionTypes.CREATE_VARIATION,
    payload: httpClient.post(projectApi(projectId) + '/variations', variationData),
  }
}

export function updateVariation (projectId, variationId, variationData) {
  return {
    type: variationsActionTypes.UPDATE_VARIATION,
    payload: httpClient.put(projectApi(projectId) + '/variations/' + variationId, variationData),
  }
}

export function updateAndFetchVariations (projectId, variationId, variationData) {
  return (dispatch) => {
    dispatch(updateVariation(projectId, variationId, variationData))
      .then(() => {
        dispatch(fetchVariationsAPI(projectId))
        dispatch(getUndoInformation(projectId))
      })

  }
}

export function deleteVariation (projectId, variationId) {
  return {
    type: variationsActionTypes.DELETE_VARIATION,
    payload: httpClient.delete(projectApi(projectId) + '/variations/' + variationId),
  }
}

export function deleteAndFetchVariations (projectId, variationId) {
  return (dispatch) => {
    dispatch(deleteVariation(projectId, variationId))
      .then(() => {
        dispatch(fetchVariations(projectId))
        dispatch(getUndoInformation(projectId))
      })

  }
}