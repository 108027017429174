import React, { Component } from 'react'
import { connect } from 'react-redux'
import Measure from 'react-measure'
import equal from 'fast-deep-equal'
import * as THREE from 'three'
import { Anchor, FN3DExtended, Gizmo, Room, Selection, Selector } from '../../../common/three/classes'
import OrbitControls from '../../../common/three/orbit'
import { findNearest } from '../../../common/three/threefunctions'
import { PatternManager } from '../../../common/three/PatternManager'
import { FurniturePlacer } from '../../../common/three/FurniturePlacer'
import { drawDistanceLines } from '../../../common/three/measureLines'
import { mapFurnitureListTo3DObjectsXT, uuidv4 } from '../../../common/three/utils'
import { color, mouseActionType } from '../../../common/three/const'
import { updatePatternAreaWPCount, updatePatternPlan } from '../../actions/actions'
import dashPattern from '../../../assets/images/png/pattern_distance.png'
import { checkCameraPosition } from '../../../common/three/CameraControl'

import './PatternBuilderPlan.scss'

const initState = {
  furniturePopperVisible: false,
  popperPosX: 100,
  popperPosY: 100,
  selectedFurniture: null,
  selectedArea: null,
  popperActive: false,
  updateCounter: false,
}

class PatternBuilderPlan extends Component {

  constructor (props) {
    super(props)
    this.state = initState
  }

  componentDidUpdate (prevProps, prevState, snapshot) {

    if (this.furniture3DObjects.length === 0 || !equal(this.props.furnitureLibrary, prevProps.furnitureLibrary)) {
      this.furniture3DObjects = this.mapFurnitureLibrary()
    }

    if ((this.furniture3DObjects.length > 0)) {
      this.patternIsDirty = true
    }

    if (!equal(this.props.furnitureMenuItemDragId, prevProps.furnitureMenuItemDragId)) {

      if (this.props.furnitureMenuItemDragId !== null) {
        this.createNewFurniture(this.props.furnitureMenuItemDragId)
        this.patternIsDirty = false

      }
    }

    if (!equal(this.props.pattern, prevProps.pattern)) {
      let resetSelection = true

      Object.values(this.props.pattern)
        .forEach(child => {
          if (child && child.furnitureList) {
            child.furnitureList.forEach((furn) => {
              if (this.SELECTED && furn.uuid === this.SELECTED.uuid) {
                resetSelection = false
              }
            })
          }
        })

      if (resetSelection)
        this.selection.visible = false
    }

    if (!equal(this.props.pattern.id, prevProps.pattern.id)) {
      this.selection.visible = false
    }

  }

  componentDidMount () {
    this.width = this.mount.clientWidth
    this.height = this.mount.clientHeight
    this.planeRotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2)
    this.planeRotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2)
    this.textureLoader = new THREE.TextureLoader()
    this.furniturePlacer = new FurniturePlacer()
    this.furniturePlacer.baseLineColor = color.furniturePlacerBaseLine

    this.allowedDragAreas = []
    this.selectStage1 = new THREE.Group()
    this.selectStage2 = new THREE.Group()
    this.selectStage3 = new THREE.Group()
    this.selectStage4 = new THREE.Group()
    this.placedNewFurniture = 0
    this.patternIsSet = false
    this.patternIsDirty = true
    this.areasToUpdateWPCount = []
    this.areaWPCountDirty = false


    this.fnlist = []

    this.mousePositionBeforeClick = new THREE.Vector3()
    this.objectPositionBeforeClick = new THREE.Vector3()
    this.lineMat = new THREE.LineBasicMaterial({ color: color.gizmo })

    this.scene = new THREE.Scene()
    this.raycaster = new THREE.Raycaster()
    this.firstCenter = false

    this.INTERSECTED = null
    this.LASTINTERSECTED = null
    this.INTERSECTIONPOSITION = null
    this.ACTIVE = null
    this.MOUSEACTION = null
    this.selectors = []

    this.HOVER = null
    this.SELECTED = null
    this.mouse = new THREE.Vector2(1000, 1000)
    //ADD CAMERA
    this.camera = new THREE.PerspectiveCamera(
      75,
      this.width / this.height,
      2,
      50,
    )
    this.camera.position.y = 5
    this.renderer = new THREE.WebGLRenderer({ antialias: true })
    this.renderer.setClearColor(color.white)
    this.renderer.setSize(this.width, this.height)
    let pr = window.devicePixelRatio
    this.renderer.setPixelRatio(pr)
    this.setupMaterials()

    this.renderer.autoClearDepth = false
    this.renderer.depth = false
    this.renderer.sortObjects = false

    this.mount.appendChild(this.renderer.domElement)

    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.controls.maxPolarAngle = 1.5
    this.controls.mouseButtons = { ZOOM: THREE.MOUSE.MIDDLE, PAN: THREE.MOUSE.RIGHT }
    this.controls.enableRotate = false
    this.controls.addEventListener('change', () => {
      this.hidePoppers()
      checkCameraPosition(this.camera, this.controls, new THREE.Vector3(-15, 2.5, -15), new THREE.Vector3(15, 25, 5))
    })

    this.sceneShifter = new THREE.Group()
    this.scene.add(this.sceneShifter)
    this.selection = new Selection()
    this.selection.visible = false

    this.sceneShifter.add(this.selectStage3)

    this.sceneShifter.add(this.selectStage1)
    this.sceneShifter.add(this.selection)
    this.selection.setForPatternBuilder()

    this.sceneShifter.add(this.selectStage2)

    this.sceneShifter.add(this.selectStage4)
    this.detachedObjectPositionBeforeClick = new THREE.Vector3()

    this.distanceLines = new THREE.Group()
    this.scene.add(this.distanceLines)

    this.mat = new THREE.MeshBasicMaterial({ color: color.white })
    this.geo = new THREE.PlaneGeometry(1, 1, 2, 2)

    let l2geo = new THREE.Geometry()
    l2geo.vertices.push(
      new THREE.Vector3(-0.5, 0.01, -.5),
      new THREE.Vector3(0.5, 0.01, -0.5),
      new THREE.Vector3(0.5, 0.01, 0.5),
      new THREE.Vector3(-0.5, 0.01, 0.5),
      new THREE.Vector3(-0.5, 0.01, -0.5),
    )

    let rsgeo = new THREE.Shape()
    let size = 0.07
    let offset = 0.03

    let point1 = new THREE.Vector3(size, -offset, 0)
    let point2 = new THREE.Vector3(0, -offset - size, 0)
    let point3 = new THREE.Vector3(-size, -offset, 0)

    rsgeo.moveTo(point1.x, point1.y)
    rsgeo.lineTo(point2.x, point2.y)
    rsgeo.lineTo(point3.x, point3.y)
    rsgeo.lineTo(point1.x, point1.y)

    let lineGeo = new THREE.Geometry()
    lineGeo.vertices.push(point1, point2, point3, point1)

    let editor = document.getElementById('PatternBuilderEditor')
    editor.addEventListener(mouseActionType.mousedown, this.onDocumentMouseDown, true)
    editor.addEventListener(mouseActionType.mousemove, this.onDocumentMouseMove, true)
    editor.addEventListener(mouseActionType.mouseup, this.onDocumentMouseUp, true)

    document.addEventListener(mouseActionType.mousemove, this.onGeneralMouseMove, true)

    let cpgeo = new THREE.PlaneGeometry(1000, 1000, 8)
    let cpmat = new THREE.MeshBasicMaterial({ color: color.yellow, visible: false })

    this.catchers = new THREE.Group()
    this.scene.add(this.catchers)
    this.catchplane = new THREE.Mesh(cpgeo, cpmat)

    this.catchplane.applyMatrix4(this.planeRotation)
    this.catchers.add(this.catchplane)

    this.gizmo = new Gizmo()
    this.gizmo.visible = false
    this.selectStage4.add(this.gizmo)

    this.anchors = []
    this.anchorLines = []

    this.dockToAnchor = null

    this.copyLayer = new THREE.Group()
    this.sceneShifter.add(this.copyLayer)

    this.furniture3DObjects = this.mapFurnitureLibrary()

    this.start()
  }

  mapFurnitureLibrary () {
    return this.props.furnitureLibrary ? mapFurnitureListTo3DObjectsXT(this.props.furnitureLibrary) : []
  }

  componentWillUnmount () {
    let editor = document.getElementById('PatternBuilderEditor')
    editor.removeEventListener(mouseActionType.mousedown, this.onDocumentMouseDown, true)
    editor.removeEventListener(mouseActionType.mousemove, this.onDocumentMouseMove, true)
    editor.removeEventListener(mouseActionType.mouseup, this.onDocumentMouseUp, true)

    document.removeEventListener(mouseActionType.mousemove, this.onGeneralMouseMove, true)
    this.stop()
    this.mount.removeChild(this.renderer.domElement)// cannot read componets of undefined
  }

  setupPatternManager = (patternData) => {

    if (!this.patternIsSet) {
      this.patternManager = new PatternManager()
      this.patternManager.connect(this.selectStage1, this.copyLayer, this.props.applicationSettings)
      this.patternIsSet = true

    }
    this.selectors = []
    this.selectStage2.children = []
    this.allowedDragAreas = []
    this.patternManager = new PatternManager()
    this.patternManager.connect(this.selectStage1, this.copyLayer, this.props.applicationSettings)
    this.patternManager.setupElements(patternData)
    this.placeAllFurniture()
    this.patternManager.checkOversize()
    this.patternManager.adjustSizes()
    this.moveFurnitureRoots()
    this.patternManager.rearrangeRootFurniture()


    this.patternManager.drawScene(this.props.pattern, this.props.patternCount.workplaces)
    this.makeOriginFurnitureBlack()
    if (!this.firstCenter) {
      this.centerCamera()
      this.firstCenter = true
    }

    //cleanup FNList  -> only active Elements survice
    let templist = this.fnlist
    this.fnlist = []
    for (let i = 0; i < templist.length; i++) {
      if (templist[i].patternArea.patternData.active) {
        this.fnlist.push(templist[i])
      }
    }

    this.anchors = []
    this.anchorLines = []

    // Create Object Anchors
    for (let h = 0; h < this.fnlist.length; h++) {
      if (this.fnlist[h] instanceof FN3DExtended) {
        this.fnlist[h].scanParentRotations()
        this.furniturePlacer.createSelector(this.fnlist[h], this.selectors)
        if (!this.fnlist[h].noAnchor) {
          this.furniturePlacer.addAnchors(this.fnlist[h], this.anchors, this.anchorLines)
        }
      }
    }

    this.allowedDragAreas = []
    let sideSeparatorActive = false
    let faceSeparatorActive = false
    let crossSeparatorArea = null


    for (let h = 0; h < this.selectStage1.children.length; h++) {

      if (this.selectStage1.children[h] instanceof Room) {

        if (this.selectStage1.children[h].patternData.active) {

          if (this.selectStage1.children[h].patternData.unitType === 'HSEPARATOR') sideSeparatorActive = true
          if (this.selectStage1.children[h].patternData.unitType === 'VSEPARATOR') faceSeparatorActive = true

          if (this.selectStage1.children[h].visible) {
            this.buildDropoutArea(this.selectStage1.children[h])
          }

        }
        if (this.selectStage1.children[h].patternData.unitType === 'HSEPARATORCROSS') crossSeparatorArea = this.selectStage1.children[h]
      }
    }
    if (sideSeparatorActive && faceSeparatorActive) {

      this.buildDropoutArea(crossSeparatorArea, 0)
    }

  }

  buildDropoutArea (element, opacity = 0) {

    element.createSelectorPlaneWithAnchors(this.selectStage2, this.anchors, this.anchorLines, element.type)

    let oversize = 0.1
    let area = element
    let catcherGeo = new THREE.BoxBufferGeometry(parseFloat(area.actualWidth) + 2 * oversize, 0.01, parseFloat(area.actualDepth) + 2 * oversize)
    let catcherMat = new THREE.MeshBasicMaterial({ color: color.catcher, transparent: true, opacity: opacity })

    let catcher = new THREE.Mesh(catcherGeo, catcherMat)
    catcher.position.set(area.position.x + parseFloat(area.actualWidth) / 2, area.position.y, area.position.z - area.actualDepth / 2)
    this.allowedDragAreas.push(catcher)
    this.sceneShifter.add(catcher)
  }


  centerCamera () {
    let vector = this.patternManager.getCenterPosition()
    this.controls.target.x = 0
    this.controls.target.z = -vector.z * 2

    this.camera.position.x = 0
    this.camera.position.z = -vector.z * 2

    this.camera.position.y = 2 * this.height * vector.z / 400

  }

  setupMaterials () {
    // Dashed Areas

    this.dashTexture = this.textureLoader.load(dashPattern, function (texture) {
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping
      texture.offset.set(0, 0)
      texture.repeat.set(.1, .1)
    })

  }

  onGeneralMouseMove = (event) => {
    if (this.MOUSEACTION === mouseActionType.dragNewElement) {
      this.SELECTED.position.set(this.INTERSECTIONPOSITION.x, 0.05, this.INTERSECTIONPOSITION.z)
      this.findDockingPoints()
    }
  }

  onDocumentMouseMove = (event) => {

    this.mouse.x = ((event.clientX - this.sidebar) / this.width) * 2 - 1
    this.mouse.y = -((event.clientY - this.topbar) / this.height) * 2 + 1

    let angle
    let axis
    let rotatedVector

    this.distanceLines.children = []

    switch (this.MOUSEACTION) {
      case mouseActionType.furnXPos:
        this.positionDifference = new THREE.Vector3()
        this.positionDifference.add(this.INTERSECTIONPOSITION)
        this.positionDifference.sub(this.sceneCoordsAtMouseBeforeClick)

        angle = this.SELECTED.parentDegrees + this.SELECTED.getAngle(-this.SELECTED.rotationInherited + this.SELECTED.rotationAtParent + this.SELECTED.rotationSelf)

        // Rotate Screen Coordinates against Gizmo and eliminate  unuse values, then rotate back
        axis = new THREE.Vector3(0, 1, 0)
        this.positionDifference.applyAxisAngle(axis, -angle)
        this.positionDifference.z = 0
        this.positionDifference.y = 0
        this.positionDifference.applyAxisAngle(axis, +angle)

        rotatedVector = this.positionDifference

        this.gizmo.position.set(this.detachedGizmoPositionBeforeClick.x + rotatedVector.x, this.detachedGizmoPositionBeforeClick.y + rotatedVector.y,
          this.detachedGizmoPositionBeforeClick.z + rotatedVector.z)
        this.SELECTED.position.set(this.detachedObjectPositionBeforeClick.x + rotatedVector.x, this.detachedObjectPositionBeforeClick.y + rotatedVector.y,
          this.detachedObjectPositionBeforeClick.z + rotatedVector.z)
        this.selection.position.set(this.selectionPositionBeforeClick.x + this.positionDifference.x, this.selectionPositionBeforeClick.y,
          this.selectionPositionBeforeClick.z + this.positionDifference.z)

        drawDistanceLines(
          this.SELECTED,
          this.positionDifference,
          this.anchors,
          this.distanceLines,
        )
        break
      case mouseActionType.furnZPos:
        this.positionDifference = new THREE.Vector3()
        this.positionDifference.add(this.INTERSECTIONPOSITION)
        this.positionDifference.sub(this.sceneCoordsAtMouseBeforeClick)

        angle = this.SELECTED.parentDegrees + this.SELECTED.getAngle(-this.SELECTED.rotationInherited + this.SELECTED.rotationAtParent + this.SELECTED.rotationSelf)
        axis = new THREE.Vector3(0, 1, 0)

        this.positionDifference.applyAxisAngle(axis, -angle)
        this.positionDifference.x = 0
        this.positionDifference.y = 0
        this.positionDifference.applyAxisAngle(axis, +angle)

        rotatedVector = this.positionDifference

        this.gizmo.position.set(this.detachedGizmoPositionBeforeClick.x + rotatedVector.x, this.detachedGizmoPositionBeforeClick.y + rotatedVector.y,
          this.detachedGizmoPositionBeforeClick.z + rotatedVector.z)
        this.SELECTED.position.set(this.detachedObjectPositionBeforeClick.x + rotatedVector.x, this.detachedObjectPositionBeforeClick.y + rotatedVector.y,
          this.detachedObjectPositionBeforeClick.z + rotatedVector.z)
        this.selection.position.set(this.selectionPositionBeforeClick.x + this.positionDifference.x, this.selectionPositionBeforeClick.y,
          this.selectionPositionBeforeClick.z + this.positionDifference.z)

        drawDistanceLines(
          this.SELECTED,
          this.positionDifference,
          this.anchors,
          this.distanceLines,
        )
        break
      case mouseActionType.furnFreePos:
        this.positionDifference = new THREE.Vector3()
        this.positionDifference.add(this.INTERSECTIONPOSITION)
        this.positionDifference.sub(this.sceneCoordsAtMouseBeforeClick)
        let newvec = this.detachedObjectPositionBeforeClick.clone()
        newvec.add(this.positionDifference)

        //this.gizmo.position.set(this.INTERSECTIONPOSITION.x, this.gizmoPositionBeforeClick.y - this.positionDifference.y, this.INTERSECTIONPOSITION.z)
        this.gizmo.position.set(newvec.x, newvec.y, newvec.z)
        this.SELECTED.position.set(newvec.x, newvec.y, newvec.z)
        this.selection.position.set(this.selectionPositionBeforeClick.x + this.positionDifference.x, this.selectionPositionBeforeClick.y,
          this.selectionPositionBeforeClick.z + this.positionDifference.z)
        this.findDockingPoints()
        break
      default:
        break
    }
  }

  findDockingPoints = () => {
    //hide all Anchors
    for (let p = 0; p < this.anchors.length; p++) {
      this.anchors[p].visible = false
      this.anchors[p].myLine.visible = false
    }

    let searchpoints = [this.INTERSECTIONPOSITION]

    let nearAnchors = findNearest(searchpoints, this.anchors, this.SELECTED.selectorPlane)

    let dockTo
    if (nearAnchors.length > 1) {
      let lines = []
      for (let z = 0; z < nearAnchors.length; z++) {
        if (nearAnchors[z].refAnchor != null) {
          lines.push(nearAnchors[z].refAnchor)
        } else {
          dockTo = nearAnchors[0]
        }
      }

      let temp = findNearest([this.INTERSECTIONPOSITION], lines, null)[0]

      for (let z = 0; z < nearAnchors.length; z++) {
        if (nearAnchors[z].refAnchor === temp) {
          dockTo = nearAnchors[z]
        }
      }

    } else {
      dockTo = nearAnchors[0]
    }

    //highlight anchors
    dockTo.visible = true
    dockTo.myLine.visible = true
    this.dockToAnchor = dockTo
  }

  onDocumentMouseUp = (event) => {
    this.positionDifference = new THREE.Vector3()
    this.positionDifference.add(this.INTERSECTIONPOSITION)

    if (this.sceneCoordsAtMouseBeforeClick instanceof THREE.Vector3) {
      this.positionDifference.sub(this.sceneCoordsAtMouseBeforeClick)
    }

    if ((this.MOUSEACTION === mouseActionType.furnXPos) || (this.MOUSEACTION === mouseActionType.furnZPos)) {
      this.props.storePatternBuilderUndo()

      let angle = this.SELECTED.parentDegrees + this.SELECTED.getAngle(-this.SELECTED.rotationInherited + this.SELECTED.rotationAtParent + this.SELECTED.rotationSelf)
      let axis = new THREE.Vector3(0, 1, 0)
      if (this.MOUSEACTION === mouseActionType.furnZPos) {

        this.positionDifference.applyAxisAngle(axis, -angle)
        this.positionDifference.x = 0
        this.positionDifference.y = 0
        this.positionDifference.applyAxisAngle(axis, +angle)
      }

      if (this.MOUSEACTION === mouseActionType.furnXPos) {
        this.positionDifference.applyAxisAngle(axis, -angle)
        this.positionDifference.z = 0
        this.positionDifference.y = 0
        this.positionDifference.applyAxisAngle(axis, +angle)
      }

      this.SELECTED.attach(this.scene, this.parentKeeper)
      let rotatedVector = this.SELECTED.rotateVectorForPlacement(this.positionDifference)

      let pos = {
        'posX': -rotatedVector.x,
        'posY': rotatedVector.y,
        'posZ': rotatedVector.z,
      }

      this.deleteOnDropOut()
      if (this.positionDifference.length() > 0.01) {
        this.props.onChangeFurniturePosition(pos, this.SELECTED.source.uuid, this.SELECTED.patternArea.type)
      }
      this.props.showFurnitureMenu(this.SELECTED.source.uuid)
    }

    if (this.MOUSEACTION === mouseActionType.furnFreePos) {

      this.props.storePatternBuilderUndo()
      this.props.getUndoAvailable()
      this.selection.visible = false

      if (this.deleteOnDropOut()) {
        this.dockToAnchor = null
      } else {

        if (this.positionDifference.length() > 0.1) {
          this.scene.remove(this.SELECTED)
          let newParent
          let area

          if (this.dockToAnchor instanceof Anchor) {
            if (!this.dockToAnchor.root) {
              newParent = this.dockToAnchor.parent.linkedObject.source.uuid
              area = this.dockToAnchor.parent.linkedObject.patternArea.type
            } else {
              area = this.dockToAnchor.parent.linkedObject.type
            }

            this.props.onChangeFurnitureParent(newParent, this.SELECTED.source.uuid, this.dockToAnchor.anchorSide, this.dockToAnchor.anchorPosition, this.dockToAnchor.root, area)
            this.dockToAnchor = null
            this.areasToUpdateWPCount.push(area)
            this.areaWPCountDirty = true
          }
        } else {
          this.patternIsDirty = true
        }

        this.props.showFurnitureMenu(this.SELECTED.source.uuid)
      }
    }

    if (this.MOUSEACTION === mouseActionType.dragNewElement) {
      this.props.storePatternBuilderUndo()
      this.scene.remove(this.SELECTED)
      let newParent
      let area

      if (this.deleteOnDropOut()) {
        this.dockToAnchor = null
      } else {

        if (this.dockToAnchor instanceof Anchor) {
          if (!this.dockToAnchor.root) {
            newParent = this.dockToAnchor.parent.linkedObject.source.uuid
            area = this.dockToAnchor.parent.linkedObject.patternArea.type
          } else {
            area = this.dockToAnchor.parent.linkedObject.type
          }

          this.props.onAddNewFurniture(newParent,
            this.SELECTED.source.type,
            this.SELECTED.source.uuid,
            this.dockToAnchor.anchorSide,
            this.dockToAnchor.anchorPosition,
            this.dockToAnchor.root,
            this.SELECTED.source.constructor, area)

          this.dockToAnchor = null
          this.areasToUpdateWPCount.push(area)
          this.areaWPCountDirty = true

        }
      }

      this.props.updateFurnitureMenuItemDragId(null)
    }

    this.MOUSEACTION = null
    this.controls.enabled = true
    this.gizmo.visible = false
  }

  findRoomByType (type) {
    for (let i = 0; i < this.selectStage1.children.length; i++) {
      let room = this.selectStage1.children[i]
      if (room.type === type) return room
    }
  }

  updateWorkplaceAreas () {
    for (let l = 0; l < this.areasToUpdateWPCount.length; l++) {
      this.updateWorkplaceCount(this.areasToUpdateWPCount[l])
    }
  }

  getWorkplaceCount = () => {
    let count = 0
    for (let l = 0; l < this.areasToUpdateWPCount.length; l++) {
      count += this.areasToUpdateWPCount[l]
    }
    return count
  }

  updateWorkplaceCount = (area) => {
    let room = this.findRoomByType(area)
    if (room instanceof Room) {
      let workplaceCount = room.countWorkplaces()
      this.props.updatePatternAreaWPCount(area, workplaceCount)
    }
  }


  onDocumentMouseDown = (event) => {

    this.mousePositionBeforeClick.x = this.mouse.x
    this.mousePositionBeforeClick.y = this.mouse.y

    if (event.button === 0) {

      if (this.HOVER && this.HOVER.linkedObject) {
        this.selectObject(this.HOVER.linkedObject)
      } else {
        this.selectObject(null)
      }

      if (this.INTERSECTED instanceof Room) {
        this.props.showAreaSettings(this.INTERSECTED.type, this.INTERSECTED.actualWidth, this.INTERSECTED.actualDepth)
      } else if (this.INTERSECTED === null) {
        this.props.hideAreaSettings()
      }

    }

    if (this.ACTIVE != null) {

      if (event.button === 0) {

        if (this.MOUSEACTION === null) {
          this.sceneCoordsAtMouseBeforeClick = this.INTERSECTIONPOSITION.clone()
        }

        if (this.ACTIVE === this.gizmo && !this.state.furniturePopperVisible && !this.state.areaPopperVisible) {

          if (this.MOUSEACTION === null) {

            this.sceneCoordsAtMouseBeforeClick = this.INTERSECTIONPOSITION.clone()

            if (this.ACTIVE.linkedObject) {
              this.objectPositionBeforeClick = this.ACTIVE.linkedObject.position.clone()
              this.gizmoPositionBeforeClick = this.gizmo.position.clone()
              this.selectionPositionBeforeClick = this.selection.position.clone()
            }

            let ag = this.gizmo.getNearest(this.INTERSECTIONPOSITION)
            if ((ag === this.gizmo.ScalerTop) || (ag === this.gizmo.ScalerBottom)) {
              this.MOUSEACTION = mouseActionType.furnZPos
              this.controls.enabled = false
              this.parentKeeper = this.SELECTED.parent

              this.SELECTED.detach(this.SELECTED.parent, this.scene)
              this.gizmo.detach(this.gizmo.parent, this.scene)
              this.detachedObjectPositionBeforeClick = this.SELECTED.position.clone()
              this.detachedGizmoPositionBeforeClick = this.gizmo.position.clone()

            }
            if ((ag === this.gizmo.ScalerLeft) || (ag === this.gizmo.ScalerRight)) {
              this.MOUSEACTION = mouseActionType.furnXPos
              this.controls.enabled = false
              this.parentKeeper = this.SELECTED.parent

              this.SELECTED.detach(this.SELECTED.parent, this.scene)
              this.gizmo.detach(this.gizmo.parent, this.scene)
              this.detachedObjectPositionBeforeClick = this.SELECTED.position.clone()
              this.detachedGizmoPositionBeforeClick = this.gizmo.position.clone()
            }

            if ((ag === this.gizmo.mover) || (ag === this.gizmo.mover)) {
              this.MOUSEACTION = mouseActionType.furnFreePos
              this.SELECTED.noAnchor = true
              this.SELECTED.selectorPlane.removeAnchors(this.anchors)

              this.setOpacityOfMovingFurnitureByGizmo(this.gizmo)

              this.SELECTED.detach(this.SELECTED.parent, this.selectStage3)
              this.gizmo.detach(this.gizmo.parent, this.selectStage3)
              this.gizmo.position.x = this.SELECTED.position.x
              this.gizmo.position.z = this.SELECTED.position.z
              this.detachedObjectPositionBeforeClick = this.SELECTED.position.clone()
              this.detachedGizmoPositionBeforeClick = this.gizmo.position.clone()
              this.controls.enabled = false

            }
            if (this.INTERSECTED) this.setHover(this.INTERSECTED.linkedObject)

          }
        }
      }

    }
    if (event.button === 2) {
      this.hidePoppers()

      if (this.INTERSECTED !== null) {

        this.hidePoppers()

        if (this.INTERSECTED instanceof Selector) {
          this.setState({
            furniturePopperVisible: true,
            popperPosX: event.clientX,
            popperPosY: event.clientY,
            selectedFurniture: this.INTERSECTED.linkedObject,
          })
        }
      } else {
        this.hidePoppers()
      }
    }

  }

  setOpacityOfMovingFurnitureByGizmo (gizmoObj) {
    this.furniturePlacer.setOpacityOfFurniture(gizmoObj.linkedObject)
    gizmoObj.visible = false
    this.selection.visible = false
  }

  moveFurnitureWithChildsToLayer (furnObject, layer) {

  }

  hidePoppers () {
    if (this.state.furniturePopperVisible || this.state.areaPopperVisible) {
      this.setState({
        furniturePopperVisible: false,
        areaPopperVisible: false,

      })
    }

  }

  deleteOnDropOut () {
    if (!this.SELECTED instanceof FN3DExtended) {
      return
    }

    let vector = new THREE.Vector3()

    vector.setFromMatrixPosition(this.SELECTED.matrixWorld)
    vector.y = 1

    let dir = new THREE.Vector3(0, -1, 0)
    let rc = new THREE.Raycaster()
    rc.set(vector, dir)

    let intersectsx = rc.intersectObjects(this.allowedDragAreas, false)

    if (intersectsx.length > 0) {
      return false
    } else {

      for (let p = 0; p < this.SELECTED.children.length; p++) {
        if (this.SELECTED.children[p] instanceof FN3DExtended) {
          this.props.deleteFurniture(this.SELECTED.children[p].source.uuid)
        }
      }

      this.props.deleteFurniture(this.SELECTED.source.uuid)
      this.selection.visible = false

      if (this.gizmo)
        this.gizmo.visible = false

      return true
    }
  }

  selectObject = (select) => {

    if (select instanceof FN3DExtended) {

      let vector = new THREE.Vector3()
      vector.setFromMatrixPosition(select.matrixWorld)
      let angle = select.getAngle(select.rotationAtParent + select.rotationSelf + select.rotationInherited) + select.parentDegrees

      this.selection.rotation.set(0, 0, 0)

      this.selection.rotateY(angle)

      this.selection.position.set(vector.x, 0.01, vector.z)

      this.selection.bbox.setFromObject(select)
      //select.parent.add(this.selection)

      if (select.bbox) this.selection.scale.x = select.bbox.max.x * 2 + 0.05
      if (select.bbox) this.selection.scale.z = select.bbox.max.z * 2 + 0.05

      this.SELECTED = select
      this.selection.visible = true

    } else {
      this.selection.visible = false
    }
  }

  updateSelection = () => {

    if (this.SELECTED instanceof FN3DExtended) {

      let vector = new THREE.Vector3()

      let nobj = this.getObjectByName(this.SELECTED.name)

      if (nobj instanceof FN3DExtended) {

        nobj.updateMatrixWorld()
        vector.setFromMatrixPosition(nobj.matrixWorld)

        this.selection.position.set(vector.x, 0.01, vector.z)

        let angle = nobj.getAngle(nobj.rotationAtParent + nobj.rotationSelf + nobj.rotationInherited) + nobj.parentDegrees
        this.selection.rotation.set(0, 0, 0)

        this.selection.rotateY(angle)
        this.selectionDirty = false
      }
    }
  }

  getObjectByName (name) {

    let retval = null
    for (let c = 0; c < this.fnlist.length; c++) {
      if (this.fnlist[c].name === name) retval = this.fnlist[c]
    }
    return retval
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  }

  stop = () => {
    cancelAnimationFrame(this.frameId)
  }

  animate = () => {

    this.renderScene()
    this.frameId = window.requestAnimationFrame(this.animate)
  }

  renderScene = () => {
    this.controls.update()

    this.raycaster.setFromCamera(this.mouse, this.camera)
    this.INTERSECTED = null

    // Create Coords over Catchplane
    let intersects = this.raycaster.intersectObjects(this.catchers.children, false)
    if (intersects.length > 0) {
      this.INTERSECTIONPOSITION = intersects[0].point

    }

    if (this.MOUSEACTION === null) {

      // Stage 1 Intersection - the Room
      intersects = this.raycaster.intersectObjects(this.selectStage1.children, false)
      if (intersects.length > 0) {
        this.INTERSECTED = intersects[0].object

      }

      // Stage 2 Intersection - Furniture
      intersects = this.raycaster.intersectObjects(this.selectors, false)
      if (intersects.length > 0) {
        this.INTERSECTED = intersects[0].object
      }

      // Setup Hovers
      if (this.INTERSECTED !== this.LASTINTERSECTED) {
        this.setHover(this.INTERSECTED)
      }
    }

    this.LASTINTERSECTED = this.INTERSECTED
    // Process Active
    this.processActiveElements()

    this.renderer.clear('#fff')
    //this.renderer.autoClear = false
    this.renderer.render(this.scene, this.camera)
    if (this.selectionDirty) this.updateSelection()

    if (this.patternIsDirty) {
      this.patternIsDirty = false
      this.selectStage3.children = []
      this.setupPatternManager(this.props.pattern)
      this.updateSelection()

    }
    if (this.areaWPCountDirty) {
      this.updateWorkplaceAreas()
      this.areaWPCountDirty = false
    }

  }

  processActiveElements = () => {
    if (this.HOVER instanceof Selector) {
      let over = this.gizmo.getNearest(this.INTERSECTIONPOSITION)
      this.ACTIVE = this.gizmo
      this.gizmo.hideElements()
      this.gizmo.showElement(over)
      this.LASTINTERSECTED = this.INTERSECTED
    }
  }

  setHover (object) {

    // handle Room

    if (this.HOVER instanceof Room) {
      this.HOVER.material = this.HOVER.initialColor
      //this.ACTIVE = null
    }
    if (object instanceof Room) {
      object.material = new THREE.MeshBasicMaterial({ color: color.gizmo, transparent: true, opacity: 0.3 })
      //this.ACTIVE = this.gizmo
    }
    // Handle Selector

    if (this.HOVER instanceof Selector) {
      this.gizmo.visible = false
      this.ACTIVE = null
    }
    if (object instanceof Selector) {
      this.gizmo.appendToFN(object.linkedObject)
      this.gizmo.visible = true
      this.ACTIVE = this.gizmo
    }

    this.HOVER = object
  }

  tryAddFurniture (area, furniture, placeRootElements = 'true') {

    const furniture3DObject = this.getFurnitureItem(furniture.type)

    if (furniture3DObject) {

      let furniture3DObjectCopy = furniture3DObject.duplicate()
      furniture3DObjectCopy.applyData(furniture)

      if (furniture.categoryName === 'desk') {

        let distance = this.props.applicationSettings.workingDepth
        let bbox = new THREE.Box3().setFromObject(furniture3DObjectCopy)
        let width = bbox.max.x - bbox.min.x
        let depth = bbox.max.z - bbox.min.z
        let spaceKeeperGeometry = new THREE.BoxBufferGeometry(width, 0.01, distance)
        let spaceKeeperMaterial = new THREE.MeshBasicMaterial({ color: color.spaceKeeper, opacity: 0, transparent: true })
        let skGeo = new THREE.Mesh(spaceKeeperGeometry, spaceKeeperMaterial)
        skGeo.position.z = depth / 2 + distance / 2
        furniture3DObjectCopy.add(skGeo)
      }

      furniture3DObjectCopy.selectorType = 'selectorPlane'
      furniture3DObjectCopy.patternArea = area

      return this.furniturePlacer.connectFurniture(furniture3DObjectCopy, area, area.tempFurnitureList)

    }
  }

  placeAllFurniture = () => {
    this.fnlist = []
    for (let m = 0; m < this.selectStage1.children.length; m++) {
      if (this.selectStage1.children[m] instanceof Room) {
        this.placeFurniture(this.selectStage1.children[m])
      }
    }
  }

  moveFurnitureRoots = () => {
    for (let m = 0; m < this.selectStage1.children.length; m++) {
      if (this.selectStage1.children[m] instanceof Room) {
        this.placeFurnitureRoot(this.selectStage1.children[m])
      }
    }
  }

  placeFurnitureRoot (area) {
    for (let h = 0; h < area.tempFurnitureList.length; h++) {
      if (area.tempFurnitureList[h] instanceof FN3DExtended) {
        if (area.tempFurnitureList[h].parent instanceof Room) {

          this.furniturePlacer._setPosition(area.tempFurnitureList[h])
        }
      }
    }
  }

  placeFurniture (area) {

    this.tryLater = []
    area.tempFurnitureList = []

    if (!area.patternData || area.patternData.furnitureList === null || this.furniture3DObjects.length === 0) {
      return
    }

    for (let i = 0; i < area.patternData.furnitureList.length; i++) {
      if (!this.tryAddFurniture(area, area.patternData.furnitureList[i], 'false')) {
        this.tryLater.push(area.patternData.furnitureList[i])
      }
    }

    this.fnlist = this.fnlist.concat(area.tempFurnitureList)

    let tries = 0
    while ((this.tryLater.length > 0) && (tries < 20)) {
      for (let t = 0; t < this.tryLater.length; t++) {
        if (this.tryAddFurniture(area, this.tryLater[t], 'false')) this.tryLater.splice(t, 1)
        tries++
      }
    }

    for (let h = 0; h < area.tempFurnitureList.length; h++) {
      if (area.tempFurnitureList[h] instanceof FN3DExtended) {
        if (area.tempFurnitureList[h].parent instanceof FN3DExtended) {
          this.furniturePlacer.placeFurniture(area.tempFurnitureList[h])
          this.furniturePlacer._setPosition(area.tempFurnitureList[h], false)
        } else {
          this.furniturePlacer.placeFurniture(area.tempFurnitureList[h])
        }
      }
    }

  }

  makeOriginFurnitureBlack = () => {
    for (let i = 0; i < this.fnlist.length; i++) {
      this.fnlist[i].children[0].children[1].material = new THREE.LineBasicMaterial({ color: color.black })
      this.fnlist[i].children[0].children[0].material = new THREE.LineBasicMaterial({ color: color.black })
    }
  }

  getFurnitureItem = (id) => {
    return this.furniture3DObjects
      .find(furniture => furniture.nid === id)
  }

  getFurnitureItemFromLib = (id) => {
    return this.props.furnitureLibrary
      .find(furniture => furniture.id === id)
  }
  createNewFurniture (fnuuid) {
    const furniture3DObject = this.getFurnitureItem(fnuuid)
    if (furniture3DObject) {

      this.MOUSEACTION = mouseActionType.dragNewElement
      this.sceneCoordsAtMouseBeforeClick = this.INTERSECTIONPOSITION.clone()

      let constructor = this.getFurnitureItemFromLib(fnuuid)

      let source = {
        'type': fnuuid,
        'mySide': 'BOTTOM',
        'rotationRad': 0,
        'posOffset': [0, 0],
        'constructor': constructor,
        'uuid': uuidv4()
          .toUpperCase(),
      }

      let furniture3DObjectCopy = furniture3DObject.duplicate()

      furniture3DObjectCopy.applyData(source)

      furniture3DObjectCopy.prepareSelectorPlane()
      furniture3DObjectCopy.updateSelectorPlane()
      furniture3DObjectCopy.selectorType = 'selectorPlane'
      this.furniturePlacer.setOpacityOfFurniture(furniture3DObjectCopy)
      furniture3DObjectCopy.position.set(this.INTERSECTIONPOSITION.x, this.INTERSECTIONPOSITION.y, this.INTERSECTIONPOSITION.z)

      furniture3DObjectCopy.moveSelectorPlane()
      this.selectStage2.add(furniture3DObjectCopy)
      this.selectObject(furniture3DObjectCopy)

      //this.furniturePlacer.addAnchors(furniture3DObjectCopy, this.anchors, this.anchorLines)
    }
  }

  resize () {

    this.width = this.mount.clientWidth
    this.height = this.mount.clientHeight
    this.topbar = document.getElementById('PatternBuilderEditor').offsetTop
    this.sidebar = document.getElementById('PatternBuilderEditor').offsetLeft

    if (this.camera) {
      this.camera.aspect = this.width / this.height
      this.camera.updateProjectionMatrix()
    }
  }

  rotateFurniture () {
    console.log("changeFurnitureRotation90")
    let uuid = this.state.selectedFurniture.source.uuid
    let area = this.state.selectedFurniture.patternArea
    this.props.changeFurnitureRotation90(uuid, area.type)
  }

  render () {

    return (

      <Measure
        bounds
        onResize={contentRect => {
          if (this.renderer) {
            this.renderer.setSize(contentRect.bounds.width, contentRect.bounds.height)
            this.resize()
          }
        }}>

        {({ measureRef }) => (
          <div ref={measureRef} id={'PatternBuilderEditor'} className={'plan-panel'}>
            <div className={'pattern-editor-container'} ref={(mount) => {this.mount = mount}}/>
          </div>
        )}
      </Measure>
    )
  }

  /* Unnecessary ???


              <PlanContextMenu placement={'left'} x={this.state.popperPosX} y={this.state.popperPosY}
                             onClose={() => this.hidePoppers()}
                             show={this.state.furniturePopperVisible}>
              <IconButton icon={'rotate'} inverted
                          onClick={() => {
                            this.rotateFurniture()
                            this.hidePoppers()
                          }}
                          activeOnHover={true}/>


            </PlanContextMenu>

   */
}

let mapStateToProps = (state) => {
  return {
    pattern: state.pattern.patternPlan,
    patternCount: state.pattern.patternCount,
  }
}

let mapDispatchToProps = {
  updatePatternPlan: updatePatternPlan,
  updatePatternAreaWPCount: updatePatternAreaWPCount,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatternBuilderPlan)



