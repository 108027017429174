import { FULFILLED, PENDING, REJECTED } from '../../common/globalConstants'
import { httpClient } from '../../common/httpClient'

const FETCH_COUNTRY = 'FETCH_COUNTRY'

export const countryActionTypes = {

  FETCH_COUNTRY: FETCH_COUNTRY,
  FETCH_COUNTRY_PENDING: FETCH_COUNTRY + PENDING,
  FETCH_COUNTRY_FULFILLED: FETCH_COUNTRY + FULFILLED,
  FETCH_COUNTRY_REJECTED: FETCH_COUNTRY + REJECTED,
}

export function fetchCountries () {
  return {
    type: countryActionTypes.FETCH_COUNTRY,
    payload: httpClient.get(`/api/countries`),
  }
}
