import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PatternBuilder from './components/editor/PatternBuilder';
import PatternBuilderOverview from './components/overview/PatternBuilderOverview';
import { fetchProjectData } from '../floorplan/actions/actions';
import { viewNames } from '../common/globalConstants';
import { useLocation } from 'react-router-dom'

const PatternBuilderApp = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch();
  const advancedSettings = useSelector(state => state.settings.appSettings.advancedSettings);
  const dataLoaded = useSelector(state => state.dataLoaded.dataLoaded);

  useEffect(() => {
    if (!dataLoaded) {
      dispatch(fetchProjectData(projectId));
    }
  }, [projectId, dataLoaded, dispatch]);

  useEffect(() => {
    if (advancedSettings === false) {

        const pathname = location.pathname
        const p = pathname.replace('/patterns', '');

        navigate('..' + p + '/floorplan' )
    }
  }, [advancedSettings, navigate]);

  return (
    <div id={'pattern-builder'}>
      <Routes>
        <Route path={`new`} element={<PatternBuilder/>}/>
        <Route path={`:patternId`} element={<PatternBuilder/>}/>
        <Route index element={<PatternBuilderOverview/>}/>
      </Routes>
    </div>
  );
};

export default PatternBuilderApp;
