import * as React from 'react'

const SvgDrawScaleActive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32">
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g stroke="#D14720" strokeLinejoin="round">
        <path
          fill="url(#prefix__a)"
          d="M.5 11.5h18v6H.5z"
          transform="translate(6.5 7)"
        />
        <path d="M7 24.285V15.5m18 8.785V15.5m-18-5V8m18 2.5V8M7 14.5V12m18 2.5V12M9 9.5h14"/>
        <path
          strokeLinecap="round"
          d="M10.207 10.914L8.793 9.5l1.414-1.414M22 10.914L23.414 9.5 22 8.086"
        />
      </g>
    </g>
  </svg>

export default SvgDrawScaleActive