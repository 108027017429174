import React from 'react'

const SvgDrawNavbarNumber1Active = props =>
  <svg
    viewBox="0 0 20 20"
    width={20} height={20}
    className={props.className}
  >
    <title>{'Group 7'}</title>
    <g fill="none" fillRule="evenodd">
      <circle fill="#C4C6D2" cx={10} cy={10} r={10}/>
      <path
        d="M10.717 13.808V6.2h-.684c-.276.792-.72 1.152-1.776 1.152v.672h1.416v5.784h1.044z"
        fill="#00293B"
        fillRule="nonzero"
      />
    </g>
  </svg>

export default SvgDrawNavbarNumber1Active