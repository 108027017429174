import React from 'react'

const SvgCafeIndicator = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M12.64 16.523a5.712 5.712 0 01-2.241-.454 5.754 5.754 0 01-3.518-5.306H18.4a5.745 5.745 0 01-1.688 4.072 5.723 5.723 0 01-1.83 1.234 5.705 5.705 0 01-2.242.454z"
        stroke={props.color}
        fill={props.fillColor}
        fillRule="nonzero"
      />
      <path
        d="M6.314 14.436a1.838 1.838 0 110-3.676h1.21M16.6 18.68H8.68M11.66 5c.285.257.45.619.46 1.002 0 .576-.92 1-.92 1.599.015.38.181.74.46 1M13.621 5c.284.257.45.619.459 1.002 0 .576-.92 1-.92 1.599.016.38.182.74.461 1"
        stroke={props.color}
      />
    </g>
  </svg>
)

export default SvgCafeIndicator
