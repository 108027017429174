import React from 'react'

const SvgDeleteIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M9.496 9.606c.309 0 .564.21.612.485l.008.094v6.945c0 .32-.277.578-.62.578-.309 0-.564-.209-.612-.484l-.008-.094v-6.945c0-.32.277-.579.62-.579zm2.454 0c.305 0 .563.21.612.485l.008.094v6.945c0 .32-.28.578-.62.578-.308 0-.563-.209-.611-.484l-.008-.094v-6.945c0-.32.276-.579.62-.579zm2.45 0c.344 0 .62.259.62.58v6.944c0 .32-.276.578-.62.578-.338 0-.619-.258-.619-.578v-6.945c0-.32.281-.579.62-.579zm1.964 9.044c0 .104-.091.193-.207.193H7.789c-.111 0-.206-.09-.206-.193V8.28h8.78v10.37zm-6.248-12.3c0-.104.095-.193.206-.193h3.252c.116 0 .207.09.207.193v.772h-3.665V6.35zm8.264.772h-3.36V6.35c0-.744-.648-1.35-1.446-1.35h-3.252c-.797 0-1.446.606-1.446 1.35v.772H5.62c-.339 0-.62.258-.62.579 0 .32.281.578.62.578h.723v10.37c0 .745.649 1.351 1.446 1.351h8.368c.798 0 1.446-.606 1.446-1.35V8.28h.777c.343 0 .62-.26.62-.58 0-.32-.277-.578-.62-.578z"
    />
  </svg>
)

export default SvgDeleteIcon
