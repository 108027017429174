import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { getAvailableLayoutStyles } from '../../../../common/redux/styles/styles'
import { updateOrganisationalUnitLayoutStyle } from '../../../actions/actions'

import NumberInput from '../../../../common/components/NumberInput'

import Button from '../../../../common/components/Button'
import MenuSection from '../../../../common/components/MenuSection'
import SelectButton from '../../../../common/components/SelectButton'
import DataField from './DataField'

import './LayoutStyle.scss'
import ResetButton from './ResetButton'
import { getSelectedOrganisationalUnit } from '../../../reducers/organisationalUnits'

const corridorOptions = [
  {
    value: 'SINGLE',
    label: 'Single',
  },
  {
    value: 'DOUBLE',
    label: 'Double',
  },
]

class LayoutStyle extends Component {

  constructor (context) {
    super(context)

    this.state = {
      customize: false,
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.unit && prevProps.unit.id !== this.props.unit.id) {
      this.setState({ customize: false })
    }
  }

  render () {

    const isCustomize = this.props.unit.layoutStyle.custom

    let options = [];

    [
      {
        value: 'SINGLE',
        label: 'Single',
      },
      {
        value: 'DOUBLE',
        label: 'Double',
      },
    ].forEach(option => {
      if (this.props.unit.layoutStyle.corridorType === option.value && isCustomize) {
        option.label = option.label + ' (Custom)'
      }
      options.push(option)
    })

    return (
      <MenuSection className={'layout-style'}>
        <MenuSection className={'layout-style-picker'}>
          <SelectButton label={'Circulation'} value={this.props.unit.layoutStyle.corridorType} options={options} onChange={(event) => this.handleLayoutStyleSelection(event.target.value)}/>
        </MenuSection>
        <Formik initialValues={{ layoutStyle: this.props.unit.layoutStyle }}
                onSubmit={(values) => this.handleUpdateLayoutStyle(values.layoutStyle)}
                enableReinitialize>
          <Form>
            <ResetButton label={'Settings'} disabled={!isCustomize} onClick={(event) => this.handleResetCustomize(event)}/>
            <MenuSection>
              {
                this.state.customize ?
                  <Field label={'Corridor Width'}
                         name={'layoutStyle.corridorWidth'}
                         component={NumberInput}
                         step={0.1}/> :
                  <DataField label={'Corridor Width'}
                             data={this.props.unit.layoutStyle.corridorWidth}/>
              }
              {this.props.unit.layoutStyle.corridorType === corridorOptions[1].value ?
                this.state.customize ?
                  <Field label={'Double Corridor Depth'}
                         name={'layoutStyle.centralZoneDepth'}
                         component={NumberInput}
                         step={0.1}/> :
                  <DataField label={'Double Corridor Depth'}
                             data={this.props.unit.layoutStyle.centralZoneDepth}/> : null
              }
            </MenuSection>
            {this.state.customize ?
              <div className={'layout-style-form-buttons'}>
                <Button type="reset" onClick={() => this.handleDiscard()}>Discard</Button>
                <Button type="submit" variant={'contained'}>Confirm</Button>
              </div> :
              <div className={'layout-style-form-buttons'}>
                <Button className={'layout-style-customized-button'} onClick={() => this.setState({ customize: true })} variant={'contained'}>Customize</Button>
              </div>
            }
          </Form>
        </Formik>
      </MenuSection>
    )
  }

  handleDiscard () {
    setTimeout(() => this.setState({ customize: false }), 100)
  }

  handleLayoutStyleSelection (corridorType) {
    const layoutStyle = this.props.unit.layoutStyle
    layoutStyle.corridorType = corridorType

    this.updateLayoutStyle(layoutStyle)
  }

  handleResetCustomize (event) {
    event.preventDefault()

    let resetLayoutStyle = {
      ...this.props.unit.layoutStyle,
      custom: false,
    }

    this.updateLayoutStyle(resetLayoutStyle)
  }

  handleUpdateLayoutStyle (layoutStyle) {
    let customLayoutStyle = {
      ...layoutStyle,
      custom: true,
    }

    this.updateLayoutStyle(customLayoutStyle)
    this.handleDiscard()
  }

  updateLayoutStyle (layoutStyle) {
    const unit = this.props.unit

    this.props.updateLayoutStyle(unit.buildingId, unit.floorId, unit.id, layoutStyle)
  }
}

let mapStateToProps = (state) => {
  return {
    unit: getSelectedOrganisationalUnit(state),
    layoutStyles: getAvailableLayoutStyles(state),
  }
}

let mapDispatchToProps = {
  updateLayoutStyle: updateOrganisationalUnitLayoutStyle,
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutStyle)