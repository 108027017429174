import { industryAttributesActionTypes } from './industry-attributes-actions'

let initialState = {
  industryAttributes: [],
}

function industryAttributesReducer (state = initialState, action) {
  if (action.type === industryAttributesActionTypes.FETCH_INDUSTRY_ATTRIBUTES_FULFILLED) {
    return {
      ...state,
      industryAttributes: action.payload.data,
    }
  }
  return state
}

export default industryAttributesReducer