import React from 'react'

import NavigationMenu from './NavigationMenu'
import VariationsNavigation from './VariationsNavigation'

import './NavigationBar.scss'
import { Route, Routes } from 'react-router'
import ProjectLoader from '../../ProjectLoader'

export function NavigationBar ({ onboarding = false }) {
  return (
    <div className={'navigation-bar' + (onboarding ? ' onboarding' : '')}>
      <Routes>
          <Route path={`/projects/:projectId/*`} element={<NavigationBarContent/>} />
      </Routes>
    </div>
  )
}

function NavigationBarContent () {
  return (
    <React.Fragment>
      <NavigationMenu/>
      <VariationsNavigation/>
    </React.Fragment>
  )
}
