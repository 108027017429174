import React from 'react'

const SvgDrawNavbarNumber7 = props =>
  <svg
    viewBox="0 0 20 20"
    width={20} height={20}
    className={props.className}
  >
    <g fill="none" fillRule="evenodd">
      <circle fill="#E8E9EE" cx={10} cy={10} r={10}/>
      <path
        d="M10.208 13.76c.024-2.568.636-4.956 2.52-6.924V6.2H7.4v.936h4.152C10.04 8.732 9.116 11.084 9.08 13.76h1.128z"
        fill="#00293B"
        fillRule="nonzero"
      />
    </g>
  </svg>

export default SvgDrawNavbarNumber7