import React from 'react'
import Text from '../../common/components/Text'
import onClickOutside from 'react-onclickoutside'
import spacing_description_image from '../../assets/images/png/Clearance_Spacing.png'

import './SpacingInfoImage.scss'

class SpacingInfo extends React.Component {

  // Needed for OnClickOutside HOC
  handleClickOutside (event) {
    this.props.onClose()
  }

  render () {

    return (
      <div className={'info-image-popper'}>
        <Text className={'info-image-title'}>Explanation of terms</Text>
        <div className={'info-image'}>
          <img src={spacing_description_image} alt={'spacing-info'} height={169}/>
        </div>
        <div className={'info-image-legend'}>
          <div className={'info-legend'}>
            <div className={'info-legend-color working-depth'}/>
            <Text className={'legend-working-depth-title'}>Working depth</Text>
          </div>
          <div className={'info-legend'}>
            <div className={'info-legend-color walking-distance'}/>
            <Text className={'legend-walking-distance-title'}>Walk Distance</Text>
          </div>
          <div className={'info-legend'}>
            <div className={'info-legend-color walking-distance-back-to-back'}/>
            <Text className={'legend-walking-distance-back-to-back-title'}>Walk Distance back to back</Text>
          </div>
          <div className={'info-legend'}>
            <div className={'info-legend-color clearance-to-facade'}/>
            <Text className={'legend-walking-distance-back-to-back-title'}>Clearance to facade</Text>
          </div>
        </div>

      </div>
    )

  }
}

export default onClickOutside(SpacingInfo)