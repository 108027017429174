import React from 'react'

const SvgHeatmapNoiseInactive = props => (
  <svg className={props.className} width={36} height={36}>
    <defs>
      <filter
        id="heatmap-noise-inactive_svg__b"
        width="105.6%"
        height="106.3%"
        x="-2.8%"
        y="-3.1%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur
          in="SourceAlpha"
          result="shadowBlurInner1"
          stdDeviation={0.5}
        />
        <feOffset in="shadowBlurInner1" result="shadowOffsetInner1"/>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.129971591 0"
        />
      </filter>
      <path
        id="heatmap-noise-inactive_svg__a"
        d="M19.23 10.005c.305.031.58.195.755.447.17.232.261.512.26.8v13.49a1.2 1.2 0 01-1.122 1.257 1.056 1.056 0 01-.72-.291L13.5 21.141H9.563A.6.6 0 019 20.513v-5.028a.6.6 0 01.562-.628H13.5l4.9-4.566c.22-.212.524-.317.829-.286zm4.963 1.078c.154.001.3.071.398.191A9.994 9.994 0 0127 17.879a9.994 9.994 0 01-2.417 6.6.521.521 0 01-.8.008.682.682 0 01-.007-.889 8.658 8.658 0 002.094-5.724 8.658 8.658 0 00-2.09-5.719h.003a.682.682 0 01.007-.889.521.521 0 01.402-.183zM21.9 13.6a.522.522 0 01.409.158 5.952 5.952 0 011.7 4.229 5.952 5.952 0 01-1.7 4.229.522.522 0 01-.794-.052.681.681 0 01.047-.887 4.626 4.626 0 001.323-3.29 4.626 4.626 0 00-1.324-3.289v-.001a.681.681 0 01-.046-.887.522.522 0 01.385-.21z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#E8E9EB" xlinkHref="#heatmap-noise-inactive_svg__a"/>
      <use
        fill="#000"
        filter="url(#heatmap-noise-inactive_svg__b)"
        xlinkHref="#heatmap-noise-inactive_svg__a"
      />
    </g>
  </svg>
);

export default SvgHeatmapNoiseInactive
