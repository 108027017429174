import React from 'react'

const SvgDrawNavbarNumber2Active = props =>
  <svg
    viewBox="0 0 20 20"
    width={20} height={20}
    className={props.className}
  >
    <title>{'Group 6'}</title>
    <g fill="none" fillRule="evenodd">
      <circle fill="#C4C6D2" cx={10} cy={10} r={10}/>
      <path
        d="M12.806 13.904v-.9H8.858l1.428-1.176c1.092-.9 2.256-1.944 2.256-3.312 0-1.284-.804-2.316-2.568-2.316-1.608 0-2.652.996-2.652 2.544 0 .228.036.468.084.708l1.032.048a2.67 2.67 0 01-.084-.684c0-1.104.576-1.764 1.608-1.764.996 0 1.5.624 1.5 1.512 0 1.104-.888 1.872-2.076 2.904L7.25 13.304v.6h5.556z"
        fill="#00293B"
        fillRule="nonzero"
      />
    </g>
  </svg>

export default SvgDrawNavbarNumber2Active