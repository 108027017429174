import React from 'react'

const SvgDrawLinkActive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32">
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle
        cx={19.104}
        cy={10.324}
        r={3.6}
        fill="url(#prefix__a)"
        stroke="#D14720"
        strokeLinejoin="round"
        transform="rotate(30 19.104 10.324)"
      />
      <circle
        cx={12.504}
        cy={21.756}
        r={3.6}
        fill="url(#prefix__a)"
        stroke="#D14720"
        strokeLinejoin="round"
        transform="rotate(30 12.504 21.756)"
      />
      <path
        stroke="#D14720"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.773 10.895L12.645 21.51"
      />
    </g>
  </svg>

export default SvgDrawLinkActive