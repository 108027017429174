import React from 'react'
import ConfirmationDialog from './ConfirmationDialog'

const messageBase = 'Are you sure you want to delete'

function getDeletionText (entity, entityName) {

  if (entity && entityName) {
    return `${messageBase} the ${entity} '${entityName}'?`
  } else if (entity) {
    return `${messageBase} the current ${entity}?`
  } else if (entityName) {
    return `${messageBase} '${entityName}'?`
  }

  return `${messageBase} this object?`
}

function DeletionDialog ({ onCancel, onConfirm, entity, entityName, title, text, ...rest }) {

  const deletionTitle = entity ? `Delete ${entity}` : 'Deletion'
  const deletionText = getDeletionText(entity, entityName)

  return (
    <ConfirmationDialog {...rest}
                        onCancel={onCancel}
                        onConfirm={onConfirm}
                        title={title ? title : deletionTitle}
                        text={text ? text : deletionText}/>
  )
}

export default DeletionDialog