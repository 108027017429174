import { MathUtils, Mesh, PlaneBufferGeometry, Vector2 } from 'three'
import { materialTypes } from '../common/Materials'
import { representationTypes } from '../config/RepresentationTypes'
import { geometryTypes } from '../config/GeometryTypes'
import { outlineConfig } from '../config/OutlineConfig'
import { wallConfig } from '../config/WallConfig'
import  { axisConfig } from '../config/AxisConfig'
import { defaultConfig } from '../config/DefaultConfig'
import { magicGuideLineConfig } from '../config/MagicGuideLineConfig'
import { scaleConfig } from '../config/ScaleConfig'
import { previewAxisConfig } from '../config/PreviewAxisConfig'

const edgeGeometryStore = new Map()

function getEdgeGeometry(innerSize, outerSize) {
    let innerGeometry

    if (edgeGeometryStore.has(innerSize)) {
        innerGeometry = edgeGeometryStore.get(innerSize)
    } else {
        innerGeometry = new PlaneBufferGeometry(innerSize, 1)
        edgeGeometryStore.set(innerSize, innerGeometry)
    }

    let outerGeometry

    if (edgeGeometryStore.has(outerSize)) {
        outerGeometry = edgeGeometryStore.get(outerSize)
    } else {
        outerGeometry = new PlaneBufferGeometry(outerSize, 1)
        edgeGeometryStore.set(outerSize, outerGeometry)
    }

    return {innerGeometry: innerGeometry, outerGeometry: outerGeometry}
}

export class Edge extends Mesh {

    #vertices = []
    #callbacks = []

    constructor(startVertex, endVertex, scale, representationType, uuid = null) {
        //console.log("RPT EdgeFactory:"+representationType+" start:"+startVertex.position.x+":"+startVertex.y+" end:"+endVertex.x+":"+endVertex.y)
        //console.log("scale: "+scale)
        scale = scale ? scale : 1
        const config = getEdgeConfigByRepresentationType(representationType)

        const {innerGeometry, outerGeometry} = getEdgeGeometry(config.innerSize, config.outerSize)

        super(outerGeometry, config.outerInactiveMaterial)

        const innerMesh = new Mesh(innerGeometry, config.innerInactiveMaterial)
        innerMesh.name = 'innerEdge'
        innerMesh.position.z -= 0.001

        //console.log("constuctor update")
        //console.log(startVertex)
        //console.log(endVertex)
        this.update(startVertex.position, endVertex.position)

        this.add(innerMesh)

        this.uuid = uuid ? uuid : this.uuid

        startVertex.addEdge(this)
        endVertex.addEdge(this)

        this.scale.x = scale
        this.geometryType = geometryTypes.edge
        this.config = config
        this.#vertices = [startVertex, endVertex]
        this.name = 'outerEdge'

    }

    update(startPosition, endPosition) {
        //console.log("update:"+startPosition.x+":"+startPosition.y+"  "+endPosition.x+":"+endPosition.y)

        const startV2 = new Vector2(startPosition.x, startPosition.y)
        const endV2 = new Vector2(endPosition.x, endPosition.y)

        const direction = endV2.clone().sub(startV2)

        const length = startV2.distanceTo(endV2)

        const center = startV2.clone().addScaledVector(direction, .5)

        this.position.set(center.x, center.y)
        this.scale.y = length

        const angle = direction.angle()
        this.rotation.z = 0
        this.rotateZ(angle + Math.PI / 2)
        /*
        console.log(
          "position z: "+this.position.z
        )*/
        this.#callbacks.forEach(callback => callback(this))

        //let resultVertices=[]
        //resultvertices[0]=startVertex
        //resultvertices[1]=endVertex
        //return resultVertices

    }

    updateEdgeCallback() {
        this.#callbacks.forEach(callback => callback(this))
    }

    setActive(active) {
        this.material = active ? this.config.outerActiveMaterial : this.config.outerInactiveMaterial
        this.children[0].material = active ? this.config.innerActiveMaterial : this.config.innerInactiveMaterial
    }

    updateScale(scale) {
        this.scale.x = scale
    }

    getVertices() {
        return this.#vertices
    }


    getAngle() {

        if (!this.parent) {
          return 0
        }


        const vertices = this.#vertices

        if (vertices.length !== 2) {
            return 0
        }

        const direction = vertices[0].position.clone().sub(vertices[1].position)

        return new Vector2(direction.x, direction.y).angle()
    }

    getLength() {
        const vertices = this.#vertices

        if (vertices.length !== 2)
            return

        return vertices[0].position.distanceTo(vertices[1].position)
    }

    getNearestPositionOnEdge(position, noteOffset = true) {
        if (!this.parent || !this.parent['findVerticesByUuids']) {
            return position
        }

        const vertices = this.#vertices

        if (vertices.length !== 2) {
            //console.log("nearest")
            return position
        }

        const {x: startX, y: startY} = vertices[0].position
        const {x: endX, y: endY} = vertices[1].position

        const ap = new Vector2(position.x - startX, position.y - startY)
        const ab = new Vector2(endX - startX, endY - startY)

        const length = ab.length()

        let distance = ap.dot(ab) / length

        if (distance > 0 && distance > length)
            distance = length
        else if (distance < 0 && distance < length)
            distance = 0

        ab.normalize()

        let point = new Vector2(startX + ab.x * distance, startY + ab.y * distance)

        if (noteOffset && this.parent.parent && this.parent.parent['getOffsets']) {
            const {offsetLeft, offsetRight} = this.parent.parent.getOffsets()

            let offsetMiddle = this.parent.parent['isClockwise'] ? (offsetRight - offsetLeft) / 2 : (offsetLeft - offsetRight) / 2

            point = ab.multiplyScalar(offsetMiddle).add(point).rotateAround(point, MathUtils.degToRad(90))
        }

        return point
    }

    getDistanceToEdge(position) {
        //console.log("nearest distance")
        if (!this.parent || !this.parent['findVerticesByUuids']) {
            return Number.MAX_VALUE
        }

        return this.getNearestPositionOnEdge(position, false).distanceTo(position)
    }

    getDirectionFromVertex(startVertex) {
        const vertices = this.#vertices

        if (vertices.length !== 2)
            return new Vector2()

        const endVertex = vertices.find(vertex => vertex !== startVertex)

        return new Vector2(endVertex.position.x - startVertex.position.x,
            endVertex.position.y - startVertex.position.y)
    }

    isConnectToVertex(vertex) {
        return this.getVertices().includes(vertex)
    }

    getGeometry() {
        let currentGeometry = this

        while (currentGeometry.parent) {
            if (currentGeometry.rootGeometry) {
                return currentGeometry
            }
            currentGeometry = currentGeometry.parent
        }

        return null
    }

    dispose() {
        this.#callbacks.forEach(callback => callback())
    }

    subscribe(callback) {
        if (!this.#callbacks.includes(callback))
            this.#callbacks.push(callback)
    }

    unsubscribe(callback) {
        this.#callbacks = this.#callbacks.filter(c => c !== callback)
    }

    toJSON () {
      const vertices = this.getVertices()

      return {
        uuid: this.uuid,
        startVertex: vertices[0].uuid,
        endVertex: vertices[1].uuid,
      }
    }
}

export function getEdgeConfigByRepresentationType(representationType) {
  //
  // console.log("get Outline config")
    switch (representationType) {
        case representationTypes.outline:
            return outlineConfig.edge
        case representationTypes.magicGuideLine:
            return magicGuideLineConfig.edge
        case representationTypes.wall:
            return wallConfig.edge
        case representationTypes.axis:
            return axisConfig.edge
        case representationTypes.scale:
            return scaleConfig.edge
        case representationTypes.previewAxis:
            return previewAxisConfig.edge
        default:
            return defaultConfig.edge
    }
}