import React from 'react'

const SvgDrawNavbarNumber7Focus = props =>
  <svg
    viewBox="0 0 20 20"
    width={20} height={20}
    className={props.className}
  >
    <g fill="none" fillRule="evenodd">
      <circle stroke="#FFF" strokeWidth={2} cx={10} cy={10} r={9}/>
      <path
        d="M10.54 13.76c.012-2.46.372-4.512 2.292-6.468V6.2H7.3v1.356h3.876c-1.512 1.62-2.244 3.624-2.28 6.204h1.644z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>

export default SvgDrawNavbarNumber7Focus