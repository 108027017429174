import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalConsumer from '../modal/ModalConsumer'
import DropdownMenu from '../DropdownMenu'

import { addFloorAndFetchBuildings, deleteBuildingAndFetch, renameBuildingAndFetch } from '../../../floorplanner/actions/actions'

import { ListMenuItem } from '../ListMenuItem'
import ListMenu from '../ListMenu'

import DeletionDialog from '../DeletionDialog'
import { viewNames } from '../../globalConstants'

import { autoGenerateAssignmentsInBuilding, deleteOrganisationalUnitAssignmentsInBuilding } from '../../../floorplan/actions/actions'
import { iconTypes } from '../icons/IconFactory'
import { useTranslation, withTranslation } from 'react-i18next'
import RenameDialog from '../../../buildings/components/dialogs/RenameDialog'
import { useLocation } from 'react-router-dom'

function BuildingMenu({onClose}) {
  const buildings = useSelector(state => state.buildings.buildings)
  const location = useLocation()
  const currentBuildingId = useSelector(state => state.appState.selectedBuildingId)
  const dispatch = useDispatch()
  const isFloorsView = location.pathname.endsWith(viewNames.FLOORS)
  const { t } = useTranslation()
  const showRenameDialog = (showModal) => {
    const building = buildings.find(building => building.id === currentBuildingId)

    showModal(RenameDialog, {
      name: building.name,
      type: 'building',
      rename: (buildingName) => handleRenameBuilding(buildingName),
    })
  }

  function showDeleteDialog (showModal) {
    showModal(DeletionDialog, {
      onConfirm: () => handleDeleteBuilding(),
    })
  }






    return (
      <DropdownMenu
        onClose={() => onClose()}>

        <ModalConsumer>
          {({ showModal }) => (
            <ListMenu>
              <ListMenuItem onClick={() => showRenameDialog(showModal)} secondIcon={iconTypes.rename}>
                {t('rename')}
              </ListMenuItem>
              {
                isFloorsView ?
                  <React.Fragment>
                    <ListMenuItem onClick={() => handleCleanUpBuilding()} secondIcon={iconTypes.cleaning}>
                      {t('clean_up')}
                    </ListMenuItem>
                    <ListMenuItem onClick={() => handleAssistant()} secondIcon={iconTypes.assistant}>
                      {t('assistant')}
                    </ListMenuItem>
                  </React.Fragment>
                  : null
              }
              <ListMenuItem disabled={buildings.length === 1} onClick={() => showDeleteDialog(showModal)} secondIcon={iconTypes.delete}>
                {t('delete')}
              </ListMenuItem>
              <ListMenuItem className={'list-menu-add-item'} onClick={() => handleAddFloorToBuilding()} icon={iconTypes.addFloor}>
                {t('add_new_floor')}
              </ListMenuItem>
            </ListMenu>
          )}
        </ModalConsumer>
      </DropdownMenu>
    )


  function handleOpenModalClick (showModal, component, data) {
    this.props.onClose()
    showModal(component, data)
  }

  function handleRenameBuilding (buildingName) {
    dispatch(renameBuildingAndFetch(currentBuildingId, buildingName))
  }

  function handleCleanUpBuilding () {
    onClose()
    dispatch(deleteOrganisationalUnitAssignmentsInBuilding(currentBuildingId))
  }

  function handleAssistant () {
    onClose()
    dispatch(autoGenerateAssignmentsInBuilding(currentBuildingId))
  }

  function handleDeleteBuilding () {
    dispatch(deleteBuildingAndFetch(currentBuildingId))
  }

  function handleAddFloorToBuilding () {
    onClose()
    dispatch(addFloorAndFetchBuildings(currentBuildingId))
  }

}
export default BuildingMenu

