import React from 'react'

const SvgSettingsIconActive = props => (
  <svg className={props.className} viewBox="0 0 60 54">
    <defs>
      <linearGradient
        id="settings-icon-active_svg__a"
        x1={1}
        x2={0}
        y1={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#7396d8"/>
        <stop offset={1} stopColor="#2a8fd9"/>
      </linearGradient>
    </defs>
    <path d="M0 0h60v54H0z" data-name="Rechteck 216" fill="none"/>
    <path
      d="M-1264.5-10555h-3a1 1 0 0 1-1-1v-1.4a8.04 8.04 0 0 1-1.107-.46l-.99.987a.989.989 0 0 1-.707.295.989.989 0 0 1-.707-.295l-2.121-2.12a1 1 0 0 1-.292-.707 1 1 0 0 1 .292-.708l.987-.987a8.225 8.225 0 0 1-.459-1.109h-1.4a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h1.4a8.5 8.5 0 0 1 .459-1.107l-.987-.986a1 1 0 0 1 0-1.415l2.121-2.124a.993.993 0 0 1 .707-.292.993.993 0 0 1 .707.292l.99.991a7.7 7.7 0 0 1 1.107-.459v-1.4a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v1.4a8.033 8.033 0 0 1 1.107.459l.99-.991a.991.991 0 0 1 .706-.292.992.992 0 0 1 .706.292l2.123 2.124a1 1 0 0 1 0 1.411l-.99.99a7.863 7.863 0 0 1 .459 1.107h1.4a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1.4a7.639 7.639 0 0 1-.459 1.109l.99.987a1 1 0 0 1 .292.708 1 1 0 0 1-.292.707l-2.123 2.12a.988.988 0 0 1-.706.295.988.988 0 0 1-.706-.295l-.99-.987a8.375 8.375 0 0 1-1.107.46v1.4a1 1 0 0 1-1 1zm-1.5-14a4 4 0 0 0-4 4 4.006 4.006 0 0 0 4 4 4.006 4.006 0 0 0 4-4 4 4 0 0 0-4-4z"
      data-name="Pfad 873"
      transform="translate(1295.999 10592.001)"
      fill="url(#settings-icon-active_svg__a)"
    />
  </svg>
)

export default SvgSettingsIconActive
