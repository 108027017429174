import * as React from 'react'

const ReplaceFurnitureSetIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path stroke="#001D34" strokeLinecap="round" d="M12.212 16.738l4.526-4.526a.3.3 0 000-.424l-4.526-4.526a.3.3 0 00-.424 0l-4.526 4.526a.3.3 0 000 .424l4.526 4.526a.3.3 0 00.424 0z"/>
      <rect width={3.5} height={3.5} x={15.5} y={5} stroke="#001D34" strokeLinecap="round" rx={1.75} transform="matrix(1 0 0 -1 0 13.5)"/>
      <rect width={3.5} height={3.5} x={15.5} y={15.5} stroke="#001D34" strokeLinecap="round" rx={1.75} transform="matrix(1 0 0 -1 0 34.5)"/>
      <rect width={3.5} height={3.5} x={5} y={5} stroke="#001D34" strokeLinecap="round" rx={1.75} transform="matrix(1 0 0 -1 0 13.5)"/>
      <rect width={3.5} height={3.5} x={5} y={15.5} stroke="#001D34" strokeLinecap="round" rx={1.75} transform="matrix(1 0 0 -1 0 34.5)"/>
      <circle cx={16} cy={16} r={6} fill="#FFF"/>
      <g stroke="#00293B" strokeLinecap="round" strokeLinejoin="round">
        <path d="M18 20.75l2-2-2-2M16 18.75h4"/>
      </g>
      <g stroke="#00293B" strokeLinecap="round">
        <path strokeLinejoin="round" d="M15 12.75l-2 2 2 2"/>
        <path d="M17 14.75h-4"/>
      </g>
    </g>
  </svg>
)

export default ReplaceFurnitureSetIcon