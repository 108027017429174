import React, { Component } from 'react'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import IconButton from '../IconButton'
import './BuilderNavBar.scss'
import Button from '../Button'
import RouteLeavingGuard from '../RouteLeavingGuard'
import RouteLeavingDialog from '../RouteLeavingDialog'
import { iconTypes } from '../icons/IconFactory'
import { withTranslation } from 'react-i18next'

const initialState = {
  dialog: {
    open: false,
    function: null,
  },
}

function TitleComponent ({ item, onChange }) {
  return (
    <div className={'builder-nav-bar-title-container'}>

      <Formik onSubmit={onChange}
              initialValues={item}
              enableReinitialize
              render={props =>
                <Form>
                  <Field className={'builder-nav-bar-title-input'}
                         autoFocus={false}
                         type={'text'}
                         name={'displayName'}
                         onBlur={props.handleSubmit}
                         onKeyDown={(e) => {
                           if (e.key === 'Enter') {
                             e.target.blur()
                           }
                         }}
                         autoComplete={'off'}
                  />
                </Form>
              }/>
    </div>

  )
}

/**
 *
 * Navigation Button to navigate through an array of NavigationItems
 * Example NavigationItems data:
 *
 *[
 * {
 *    id: 'some-uuid',
 *    displayName: 'Item 1',
 *  },
 *]
 */
class BuilderNavBar extends Component {

  constructor (props, context) {
    super(props, context)

    this.state = initialState
  }

  componentDidMount () {
    this.setupCloseListener()
  }

  getSaveButton () {
    const {t} = this.props

    if (this.props.dirtyFlag) {
      return (
        <div className={'builder-nav-changes-buttons'}>
          <Button variant={'text'} icon={iconTypes.close} onClick={() => this.discardChanges()}>
            {t('discard')}
          </Button>
          <Button variant={'text'} icon={iconTypes.check} onClick={() => this.props.saveChanges()}>
            {t('finish')}
          </Button>
        </div>
      )
    }
  }

  discardChanges () {
    this.props.discardChanges()
    this.props.discardUndo()

    if (this.props.current.id === null) {
      this.props.backToOverview()
    }
  }

  checkForChangesDialog () {
    if (this.state.dialog.open) {
      return (
        <RouteLeavingDialog onDiscard={() => this.handleDialogDiscard()}
                            onConfirm={() => this.handleDialogConfirm()}
                            onCancel={() => this.closeDialog()}
                            lines={this.props.dialogMessage}/>
      )
    }
  }


  render () {
    let item = this.props.current
    const {t} = this.props

    return (
      <div className={'builder-nav-bar'}>
        <div className={'builder-nav-bar-content'}>

          <div className={'builder-nav-back-container'}>
            {this.props.backToOverview && <Button variant={'text'} icon={iconTypes.back} onClick={() => this.handleBackButton()}>{t('overview')}</Button>}
          </div>

          <div className={'builder-nav-navigation-container'}>
            {item.id ? <IconButton icon={iconTypes.arrowLeft} onClick={() => this.handleLeftClick()}/> : <div/>}
            <TitleComponent item={item} onChange={values => this.handleTitleBlur(values)}/>
            {item.id ? <IconButton icon={iconTypes.arrow} onClick={() => this.handleRightClick()}/> : <div/>}
          </div>


          <div className={'builder-nav-changes-buttons-container'}>
            <div className={'undo_container'}>
              <IconButton className={'app_builder_undo'} icon={this.props.undoAvailable ? iconTypes.undoActive : iconTypes.undoActive} onClick={() => this.handleUndo()}
                          disabled={this.props.undoAvailable ? false : true}></IconButton>
              <IconButton className={'app_builder_redo'} icon={this.props.redoAvailable ? iconTypes.redoActive : iconTypes.redoActive} onClick={() => this.handleRedo()}
                          disabled={this.props.redoAvailable ? false : true}></IconButton>
            </div>
            {this.getSaveButton()}
          </div>

        </div>

        <div className={'builder-changes-dialog-container'}>

        {this.checkForChangesDialog()}
        </div>

        <RouteLeavingGuard
          when={this.props.dirtyFlag && !this.state.dialog.open}
          confirm={() => this.props.saveChanges()}
          lines={this.props.dialogMessage}/>

      </div>
    )
  }

  handleRedo () {
    console.log('WHAM')
    this.props.redoApplicationBuilder()
  }

  handleUndo () {
    console.log('sup')
    this.props.undoApplicationBuilder()
  }

  // Navigate

  handleRightClick (forceExecution) {
    if (!forceExecution && this.props && this.props.dirtyFlag) {
      this.openDialog(() => this.handleRightClick(true))
      return
    }

    let nextItem = this.next()
    this.navigate(nextItem)
  }

  handleLeftClick (forceExecution) {
    if (!forceExecution && this.props && this.props.dirtyFlag) {
      this.openDialog(() => this.handleLeftClick(true))
      return
    }

    let previousItem = this.previous()
    this.navigate(previousItem)
  }

  getCurrentIndex () {
    return this.props.navItems.findIndex(item => item.id === this.props.current.id)
  }

  next () {
    let index = this.getCurrentIndex() + 1

    if (index >= this.props.navItems.length) {
      index = 0
    }

    return this.props.navItems[index]
  }

  previous () {
    let index = this.getCurrentIndex() - 1

    if (index < 0) {
      index = this.props.navItems.length - 1
    }

    return this.props.navItems[index]
  }

  navigate (item) {
    if (this.props.onNavigation) {
      this.props.onNavigation(item.id)
    }
  }

  // Rename
  handleTitleDoubleClick () {
    this.setState({ doubleClicked: true })
  }

  handleTitleBlur (item) {
    this.props.renameTitle(item)
    this.setState({ doubleClicked: false })
  }

  //changes Dialog
  openDialog (functionToCall) {
    this.setState({
      dialog: {
        open: true,
        function: functionToCall,
      },
    })
  }

  closeDialog () {
    this.setState({
      dialog: {
        open: false,
        function: null,
      },
    })
  }

  handleDialogDiscard () {
    this.props.discardChanges()
    this.props.resetDirtyFlag()
    this.closeDialog()
    this.state.dialog.function()
  }

  handleDialogConfirm () {
    this.props.saveChanges()
    this.closeDialog()
    this.state.dialog.function()
  }

  //Back to overview
  handleBackButton (forceExecution) {
    if (!forceExecution && this.props && this.props.dirtyFlag) {
      this.openDialog(() => this.handleBackButton(true))
      return
    }

    if (this.props.backToOverview)
      this.props.backToOverview()
  }

  //Close Window
  setupCloseListener () {
    window.addEventListener('beforeunload', (event) => this.handleWindowClose(event))
  }

  handleWindowClose (event) {
    if (this.props && this.props.dirtyFlag) {
      event.preventDefault()
      event.returnValue = ''
    }
  }

}

BuilderNavBar.propTypes = {
  navItems: PropTypes.array.isRequired,
  index: PropTypes.number,
  dirtyFlag: PropTypes.bool,
  onNavigation: PropTypes.func,
  renameTitle: PropTypes.func,
  backToOverview: PropTypes.func,
  saveChanges: PropTypes.func,
  discardChanges: PropTypes.func,
  resetDirtyFlag: PropTypes.func,
  undoApplicationBuilder: PropTypes.func,
  redoApplicationBuilder: PropTypes.func,
}

export default withTranslation() (BuilderNavBar)