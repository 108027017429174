import * as React from 'react'

function SocialiseIconActive(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={37}
      height={36}
      viewBox="0 0 37 36"
    >
      <defs>
        <linearGradient
          id="prefix_socialise_active_a"
          x1="41.255%"
          x2="84.427%"
          y1="-30.9%"
          y2="101.7%"
        >
          <stop offset="0%" stopColor="#9089EA" />
          <stop offset="100%" stopColor="#2F97E4" />
        </linearGradient>
      </defs>
      <path
        d="M24.5 13a1 1 0 011 1v.531a4 4 0 010 7.938V26a3 3 0 01-3 3h-10a3 3 0 01-3-3V14a1 1 0 011-1h14zm1.001 3.563v3.874a2.001 2.001 0 000-3.874zM14 5.5a1 1 0 01.993.883L15 6.5c0 .559.31 1.067.798 1.325l.125.06.923.384A3.501 3.501 0 0119 11.5a1 1 0 01-1.993.117L17 11.5c0-.559-.31-1.067-.798-1.325l-.125-.06-.923-.384A3.501 3.501 0 0113 6.5a1 1 0 011-1z"
        transform="translate(.5)"
        fill="url(#prefix_socialise_active_a)"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SocialiseIconActive
