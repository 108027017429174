import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Scrollbar } from 'react-scrollbars-custom'

import BuilderOverviewNavBar from '../../../common/components/builder/BuilderOverviewNavBar'
import BuilderOverviewGroup from '../../../common/components/builder/BuilderOverviewGroup'
import ModalConsumer from '../../../common/components/modal/ModalConsumer'
import UploadDialog from '../../../header/components/UploadDialog'

import { addNewApplication, renameApplication } from '../../actions/frontendActions'
import { deleteFurnitureEnsemble, duplicateApplication, patchFurnitureEnsemble } from '../../actions/backendActions'
import { deleteApplications, exportApplications, importApplications } from '../../../common/redux/applications/applications-actions'

import Button from '../../../common/components/Button'
import ButtonToolbar from '../../../common/components/ButtonToolbar'
import Inspector from '../../../common/components/Inspector'
import TreeList from '../../../common/components/TreeList'

import './ApplicationBuilderOverview.scss'
import { iconTypes } from '../../../common/components/icons/IconFactory'
import { useTranslation, withTranslation } from 'react-i18next'
import { viewNames } from '../../../common/globalConstants'

const ApplicationBuilderOverview = () => {
  const [selectedCategory, setSelectedCategory] = useState('all')
  const navigate = useNavigate()
  const location = useLocation()
  const { projectId, applicationId } = useParams() // Wenn die URL :projectId enthält
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // Redux-Store-Zustände über useSelector
  const {
    furnitureEnsembles,
    furnitureEnsembleCategories,
    furnitureEnsemblesPolygons,
  } = useSelector(state => ({
    furnitureEnsembles: state.furnitureEnsembles.furnitureEnsembles,
    furnitureEnsembleCategories: state.furnitureEnsembles.furnitureEnsembleCategories,
    furnitureEnsemblesPolygons: state.furnitureEnsemblesPolygons,
  }))

  const handleImportApplications = (file, replace) => {
    dispatch(importApplications(file, replace))
  }

  const handleExportApplications = () => {
    dispatch(exportApplications())
  }

  const handleDeleteApplications = () => {
    dispatch(deleteApplications())
  }

  const mapCategoriesToTreeList = (categoryList) => {
    let sorted = categoryList.length ? categoryList.slice(0) : []
    sorted.sort((a, b) => {
      const x = a.displayName.toLowerCase()
      const y = b.displayName.toLowerCase()
      return x < y ? -1 : x > y ? 1 : 0
    })

    sorted.unshift({
      id: 'all',
      name: 'all',
      displayName: 'All',
      color: {
        red: 255,
        green: 255,
        blue: 255,
        alpha: 1,
      },
    })

    return sorted.map(cat => {
      return {
        key: cat.id,
        label: cat.displayName + ' (' + filterApplicationsByCategoryId(cat.id).length + ')',
        level: 0,
        color: {
          red: cat.color.red,
          green: cat.color.green,
          blue: cat.color.blue,
          alpha: 1,
        },
      }
    })
  }

  const getSelectedTitle = () => {
    let title

    if (selectedCategory === 'all')
      title = 'All ('
    else
      title = furnitureEnsembleCategories.find(cat => cat.id === selectedCategory).displayName + ' ('

    return title + filterApplicationsByCategoryId(selectedCategory).length + ')'
  }

  const filterApplicationsByCategoryId = (categoryId) => {
    if (categoryId.toLowerCase() === 'all')
      return furnitureEnsembles

    return furnitureEnsembles.filter(ensemble => ensemble.categoryId === categoryId)
  }

  const createNewApplication = () => {
    dispatch(addNewApplication())
    const pathname = location.pathname
    const p = pathname.replace('/sets', '');

    navigate('..' + p + '/' + viewNames.SETS + '/new' + location.search)
  }

  const setCurrentApplicationId = (applicationId) => {
    const pathname = location.pathname
    const p = pathname.replace('/sets', '');

    navigate('..' + p + '/' + viewNames.SETS + '/' + applicationId + location.search)

  }

  const handleRenameApplication = (ensemble) => {
    dispatch(renameApplication(ensemble.id, ensemble.displayName))
    dispatch(patchFurnitureEnsemble(projectId, ensemble.id, ensemble))
  }

  const handleDuplicateApplication = (application) => {
    dispatch(duplicateApplication(projectId, application.id))
  }

  const handleDeleteApplication = (application, appId) => {
    dispatch(deleteFurnitureEnsemble(projectId, appId))
  }

  return (
    <div className={'application-builder-overview'}>

      <BuilderOverviewNavBar icon={iconTypes.furnitureSetsBlue} text={`Furniture-Sets (${furnitureEnsembles.length})`}>

        <ModalConsumer>
          {({ showModal }) => (
            <ButtonToolbar className={'button-container'}>
              <Button className={'button'} variant={'text'} icon={'import-furniture-set'} onClick={() => showModal(UploadDialog, {
                title: 'Import applications file',
                upload: handleImportApplications,
                replace: false,
                fileEndings: '.coa',
              })}>
                {t('import')}
              </Button>
              <Button className={'button'} variant={'text'} icon={'replace-furniture-sets'} onClick={() => showModal(UploadDialog, {
                title: 'Replace applications file',
                upload: handleImportApplications,
                replace: true,
                fileEndings: '.coa',
              })}>
                {t('replace')}
              </Button>
              <Button className={'button'} variant={'text'} icon={'export-furniture-sets'} onClick={() => handleExportApplications()}>
                {t('export')}
              </Button>
              <Button className={'button'} variant={'text'} icon={'delete'} onClick={() => handleDeleteApplications()}>
                {t('delete_all')}
              </Button>
            </ButtonToolbar>
          )}
        </ModalConsumer>

      </BuilderOverviewNavBar>

      <div className={'content'}>
        <Inspector>
          <Scrollbar autoHide>
            <TreeList className={'furniture-sets-category-tree-list'}
                      nodes={mapCategoriesToTreeList(furnitureEnsembleCategories)}
                      onLevelItemClick={[(item) => setSelectedCategory( item )]}
                      selected={selectedCategory}
                      maxLevel={0}/>
          </Scrollbar>
        </Inspector>

        <BuilderOverviewGroup projectId={projectId}
                              title={getSelectedTitle()}
                              overviewContent={filterApplicationsByCategoryId(selectedCategory)}
                              onElementClicked={(applicationId) => setCurrentApplicationId(applicationId)}
                              onCreateClicked={() => createNewApplication()}
                              onDeleteClicked={(projectId, appId) => handleDeleteApplication(projectId, appId)}
                              onDuplicateClicked={(appId) => handleDuplicateApplication(appId)}
                              renameContent={(ensemble) => handleRenameApplication(ensemble)}
                              imagesPolygons={furnitureEnsemblesPolygons}/>
      </div>
    </div>
  )

}

export default ApplicationBuilderOverview