import React from 'react'

import Text from '../../../../common/components/Text'
import { IconWrapper } from '../../../../common/components/IconWrapper'

import './SidebarTitle.scss'

export function SidebarTitle ({ title, icon }) {

  return (
    <div className={'side-bar-title'}>
      <IconWrapper styleClass={'side-bar-title-icon-wrapper'} name={icon}/>
      <Text>{title}</Text>
    </div>
  )
}

