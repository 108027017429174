import React from 'react'

const SvgDrawNavbarNumber6Active = props =>
  <svg
    viewBox="0 0 20 20"
    width={20} height={20}
    className={props.className}
  >
    <title>{'Group 2'}</title>
    <g fill="none" fillRule="evenodd">
      <circle fill="#C4C6D2" cx={10} cy={10} r={10}/>
      <path
        d="M10.1 6.2c1.437 0 2.423.793 2.618 2.106l.021.174-.996.036c-.132-.888-.636-1.488-1.656-1.488-1.536 0-2.196 1.404-2.184 3.384.264-.876 1.032-1.548 2.256-1.548 1.632 0 2.652 1.068 2.652 2.556 0 1.56-1.068 2.628-2.76 2.628-2.016 0-3.072-1.464-3.072-3.78 0-2.364.996-4.068 3.12-4.068zm-.097 3.492c-1.14 0-1.8.696-1.8 1.764 0 1.044.708 1.752 1.836 1.752 1.116 0 1.776-.696 1.776-1.752 0-1.068-.66-1.764-1.812-1.764z"
        fill="#00293B"
        fillRule="nonzero"
      />
    </g>
  </svg>

export default SvgDrawNavbarNumber6Active