import React from 'react'

const SvgDrawPolygonActive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32">
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
      <linearGradient
        id="prefix__b"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="url(#prefix__a)"
        d="M15.356 10.356l7.288 7.288a.492.492 0 00-.144.351l-.001 1.38-13 3.25.001-.63a.492.492 0 00-.495-.495h-.362l4.714-11h1.648a.49.49 0 00.35-.144z"
      />
      <path
        fill="url(#prefix__b)"
        stroke="#D14720"
        d="M8.64 21.507l4.732-11.042m1.974-.12l7.272 7.273"
      />
      <path
        stroke="#D14720"
        strokeLinejoin="round"
        d="M6.995 21.5a.492.492 0 00-.495.495v2.01c0 .273.216.495.495.495h2.01a.492.492 0 00.495-.495v-2.01a.492.492 0 00-.495-.495h-2.01zm16-4a.492.492 0 00-.495.495v2.01c0 .273.216.495.495.495h2.01a.492.492 0 00.495-.495v-2.01a.492.492 0 00-.495-.495h-2.01zm-10-10a.492.492 0 00-.495.495v2.01c0 .273.216.495.495.495h2.01a.492.492 0 00.495-.495v-2.01a.492.492 0 00-.495-.495h-2.01z"
      />
    </g>
  </svg>

export default SvgDrawPolygonActive