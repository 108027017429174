import React from 'react'

const SvgMidActive = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <circle cx={12} cy={12} r={11.5} fill="#E5E6EC" stroke="#DEE0E5"/>
      <path
        fill="#373B51"
        fillRule="nonzero"
        d="M10.395 14.09l.21-7.2h-1.44l.21 7.2h1.02zm-.51 3.075c-.525 0-.885-.405-.885-.93 0-.54.375-.93.885-.93.54 0 .885.39.885.93 0 .525-.345.93-.885.93zm4.51-3.075l.21-7.2h-1.44l.21 7.2h1.02zm-.51 3.075c-.525 0-.885-.405-.885-.93 0-.54.375-.93.885-.93.54 0 .885.39.885.93 0 .525-.345.93-.885.93z"
      />
    </g>
  </svg>
)

export default SvgMidActive
