import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getAvailableApplicationProfiles } from '../../../common/redux/styles/styles'

import StyleSelectButton from '../../../common/components/StyleSelectButton'
import { Field, Form, Formik } from 'formik'

class TableApplicationProfileSelectButton extends Component {

  render () {
    //console.log("state")
    //console.log(this.state)
    const applicationStyles = [...this.props.applicationProfiles]
    console.log("applicationStyles")
    console.log(this.props)
    const currentApplicationStyle = applicationStyles.find(applicationStyle => applicationStyle.id === this.props.applicationProfile.id)
    console.log(currentApplicationStyle)

    if (this.props.applicationProfile.custom) {
      currentApplicationStyle.name = this.props.applicationProfile.name + ' (Custom)'
    }

    const initialValue = { value: this.props.value }
    console.log("value")
    console.log(this.props.value)

    return (

      <Formik initialValues={initialValue} enableReinitialize>
        <Form onChange={(event) => this.handleApplicationProfileSelection(event.target.value)}>
          <Field component={StyleSelectButton}
                 styles={applicationStyles}
                 name={'value'}/>
        </Form>
      </Formik>
    )
  }

  handleApplicationProfileSelection (selection) {
    console.log("selection!")
    let selectedProfile = this.props.applicationProfiles.find(style => style.id === selection)

    if (selectedProfile.id === '___') //fix for json marshalling on backend
      selectedProfile.id = null

    console.log("APPLICTIONPROFILE")
    console.log(selectedProfile.id)

    this.props.onChange(this.props.unitId, { applicationProfile: { id: selectedProfile.id } })

  }

}

let mapStateToProps = (state, ownProps) => {
  return {
    applicationProfiles: getAvailableApplicationProfiles(state, ownProps.unitId),
  }
}

export default connect(mapStateToProps)(TableApplicationProfileSelectButton)