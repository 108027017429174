import * as React from 'react'

const SvgDrawWindowActive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32">
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="url(#prefix__a)"
        stroke="#D14720"
        strokeLinejoin="round"
        d="M0 19.505C0 19.226.225 19 .494 19h19.012c.273 0 .494.214.494.505v.99a.502.502 0 01-.494.505H.494A.492.492 0 010 20.495v-.99z"
        transform="translate(6 6)"
      />
      <path
        stroke="#D14720"
        strokeLinejoin="round"
        d="M8 24.492c0 .28.226.508.496.508h15.008a.502.502 0 00.496-.508V6.508A.505.505 0 0023.504 6H8.496A.502.502 0 008 6.508v17.984zM16 6v19m8.5-9.5h-17"
      />
      <path
        fill="#D14720"
        d="M9.427 10.005a.252.252 0 010-.353l2.475-2.475a.253.253 0 01.353 0 .252.252 0 010 .353L9.78 10.005a.253.253 0 01-.353 0zM9.379 11.371a.247.247 0 01.005-.359l3.878-3.878a.256.256 0 01.36-.005.247.247 0 01-.006.359l-3.878 3.878a.256.256 0 01-.36.005z"
      />
    </g>
  </svg>

export default SvgDrawWindowActive