import * as React from 'react'

const DrawWindowNew = props =>
  <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <title>{'Icon / Drawmode / windows / inactive'}</title>
    <g stroke={props.color} fill="none" fillRule="evenodd" strokeLinejoin="round">
      <path d="M8.5 23.505c0-.279.224-.505.5-.505h14c.277 0 .5.214.5.505v.99A.502.502 0 0 1 23 25H9a.494.494 0 0 1-.5-.505v-.99Z"/>
      <path d="M10 22.504c0 .274.229.496.5.496h11a.5.5 0 0 0 .5-.496V7.496A.502.502 0 0 0 21.5 7h-11a.5.5 0 0 0-.5.496v15.008ZM16 7v16.167M22 15H10"/>
    </g>
  </svg>
export default DrawWindowNew