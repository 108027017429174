import * as React from 'react'

const SvgDepartment = props =>
  (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        d="M7.437 7.438h2.247A1.747 1.747 0 0013.125 7a1.748 1.748 0 00-3.441-.438H7.437v-3.5h2.247a1.75 1.75 0 001.913 1.3 1.752 1.752 0 000-3.473 1.748 1.748 0 00-1.913 1.298H7.437a.875.875 0 00-.874.876v3.5H4.316a1.75 1.75 0 00-1.913-1.3 1.75 1.75 0 000 3.473 1.748 1.748 0 001.913-1.299h2.247v3.5c0 .484.391.875.874.876h2.247a1.747 1.747 0 003.441-.438 1.748 1.748 0 00-3.441-.437H7.437v-3.5zm3.933-1.313a.874.874 0 110 1.749.874.874 0 010-1.749zm0-4.375a.874.874 0 110 1.749.874.874 0 010-1.749zM2.63 7.875a.874.874 0 110-1.749.874.874 0 010 1.749zm8.74 2.625a.874.874 0 110 1.749.874.874 0 010-1.749z"
        fill="#00293B"
        fillRule="evenodd"
      />
    </svg>
  )

export default SvgDepartment
