import React from 'react'

import EditableTable from '../../common/components/EditableTable'
import { getDepartmentListItemStyle, getRgbaColor } from '../../common/utils'

import IconButton from '../../common/components/IconButton'

import TableApplicationProfileSelectButton from '../../settings/components/selects/TableApplicationProfileSelectButton'
import TablePatternStyleSelectButton from '../../settings/components/selects/TablePatternStyleSelectButton'
import TableColorSelectButton from '../../settings/components/selects/TableColorSelectButton'

import './CorporateTable.scss'

import TableAssignedFloorSelectButton from '../../settings/components/selects/TableAssignedFloorSelectButton'
import TableColumnInfoHeader from '../../common/components/TableColumnInfoHeader'
import { iconTypes } from '../../common/components/icons/IconFactory'
import { Field, Form, Formik } from 'formik'
import NumberInput from '../../common/components/NumberInput'
import table from '../../common/components/Table'

function isNotTeamRow ({ row: { depth, id } })
{
  return depth < 2
}

function isDepartmentRow ({ row: { depth } }) { return depth === 1 }

function isTeamRow (data) { return !isNotTeamRow(data) }

function isNotSuperDepartmentRow ({ row: { depth } }) { return depth > 0 }

function getIconName (data) {
  switch (data.type) {
    case 'SUPER_DEPARTMENT':
      return iconTypes.superDepartment
    case 'DEPARTMENT':
      return iconTypes.department
    case 'TEAM':
    default:
      return iconTypes.team
  }
}

function getTableStructure (useEmployee, showStyles, assignUnitToFloor, changeLockDialog, setSelectedOrganisationalUnit, onUnitChange, expandRow) {
  const nameColumn =
    {
      Header: <TableColumnInfoHeader title={'Name'} headerStyle={'name-header'} infoText={'Name corresponds to the real name of the department, subdepartment or team of your company.'}/>,
      id: 'icon',
      className: 'corporate-expander-icon-column',
      Cell: (props) => {
        console.log("getTableStructure:")
        console.log(props)
        console.log("END")

        const { row, onCellValueChange, cell } = props

        console.log(row)
        console.log(cell)

        return (
          <div className={'expander-name-column-cell-container' + (row.getCanExpand() ? ' expandable' : '')}
               style={{
                 // Use the row.depth property and paddingLeft to
                 // indicate the depth of the row
                 paddingLeft: `${row.depth * 1.8}rem`,
               }}>
            <div className={'expander-name-column-content-container'}>
              <div className={'expander-name-column-content'}
                   style={getDepartmentListItemStyle(row.original.color)}>
                {row.getCanExpand() ? <IconButton icon={row.getIsExpanded() ? iconTypes.triangleDown : iconTypes.triangle}
                  onClick={() => {
                      row.toggleExpanded(!row.getIsExpanded())
                  }}  /> : <div/>}
                <IconButton className={'unit-icon'} icon={getIconName(row.original)} onClick={() => setSelectedOrganisationalUnit(row.original)}/>
                <Formik initialValues={row.original} enableReinitialize onSubmit={(value) => onUnitChange(row.original.id, { name: value.name })}>
                  <Form>
                    <Field name={'name'} type={'text'} onBlur={(event) => onUnitChange(row.original.id, { name: event.target.value })}/>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        )
      },
    }
  const codeColumn =
    {
      Header: <TableColumnInfoHeader title={'Code'} infoText={'The code is a 4-digit abbreviation of the name and is generated automatically or can be entered by you.'}/>,
      accessor: 'fourLetterName',
      id: 'fourLetterName',
      className: 'corporate-code-column',
      Cell: ({ row, cell }) => <input
        type="text"
        defaultValue={row.original.fourLetterName}
        onBlur={(event) => {
          const updatedValue = event.target.value
          const unitId = row.original.id

          onUnitChange(unitId, { fourLetterName: updatedValue })
        }}
      />,
    }
  const colorColumn =
    {

      Header: <TableColumnInfoHeader title={'Color'} infoText={'The color you set here will be reflected in the sidebar and your floorplan.'}/>,
      className: ' corporate-color-column',
      id: 'color',
      accessor: (row) => row.color ? getRgbaColor(row.color) : null,
      showCell: (data) => isNotSuperDepartmentRow(data),
      Cell: ({ row, cell: { value }, onCellValueChange }) => {
        if (row.depth === 0) return null
        return <TableColorSelectButton value={getRgbaColor(row.original.color)}
                                       unitId={row.original.id}
                                       onChange={(unitId, unit) => onUnitChange(unitId, unit)}/>

      },
    }
  const deskSharingRatioColumn =
    {
      Header: <TableColumnInfoHeader title={'Desk Sharing Ratio'}
                                     infoText={'Desk Sharing Ratio indicates how many workstations are available in relation to employees. For eg.: a Desk Sharing Ratio of 2 equals 10 workstations for 20 employees.'}/>,
      accessor: 'requirement.deskSharingRatio',
      id: 'deskSharingRatio',
      className: 'corporate-number-column',
      Cell: ({ row, cell, getValue }) => {
        // Hinzufügen des console.log hier, um die cell bei jedem Rendering der Zelle auszugeben



        return isTeamRow({ row }) ?
          <div className={'corporate-desk-sharing-ratio'}>
            <div className={'relation-representation'}>
              1 :
            </div>
            <NumberInput
              value={row.original.requirement.deskSharingRatio}
              step={.1}
              digits={2}
              disabled={row.original.requirement.deskSharingRatioLocked}
              onDisabledClick={() => changeLockDialog(row.original.id, 'deskSharingRatio')}
              onBlur={(value) => (value && Number(value) !== 0) ? onUnitChange(row.original.id, { requirement: { deskSharingRatio: value } }) : {}}
              onChange={(value) => (value && Number(value) !== 0) ? onUnitChange(row.original.id, { requirement: { deskSharingRatio: value } }) : {}}/>
          </div> :
          <span className={'corporate-number-text-column'}>1 : {row.original.requirement.deskSharingRatio}</span>;
      }
    }
    console.log(deskSharingRatioColumn)

  const workplaces =
    {
      Header: <TableColumnInfoHeader title={'Workplaces'} infoText={'The number in Workplaces indicates how many workplaces should be scheduled for that team. '}/>,
      accessor: 'requirement.numberOfWorkplaces',
      id: 'numberOfWorkplaces',
      className: 'corporate-number-column',
      Cell: ({ row }) => <span className={'corporate-number-text-column'}>{row.original.requirement.numberOfWorkplaces}</span>,
    }

  const headcount =
    {
      Header: <TableColumnInfoHeader title={'Headcount'} infoText={'The number at Headcount equals the number of employees within the corresponding team.'}/>,
      accessor: 'requirement.numberOfEmployees',
      id: 'numberOfEmployees',
      className: 'corporate-number-column',
      Cell: ({ row, cell, onCellValueChange }) => isTeamRow({ row }) ?
        <NumberInput value={row.original.requirement.numberOfEmployees}
                     step={1}
                     digits={0}
                     disabled={row.original.requirement.numberOfEmployeesLocked}
                     onDisabledClick={() => changeLockDialog(row.original.id, 'numberOfEmployees')}
                     onBlur={(value) => (value && Number(value) !== 0) ? onUnitChange(row.original.id, { requirement: { numberOfEmployees: value } }) : {}}
                     onChange={(value) => (value && Number(value) !== 0) ? onUnitChange(row.original.id, { requirement: { numberOfEmployees: value } }) : {}}/> :
        <span className={'corporate-number-text-column'}>{row.original.requirement.numberOfEmployees}</span>,
    }

  const squareMeterPerWorkplaceColumn =
    {
      Header: <TableColumnInfoHeader title={'m2/Workplace'}
                                     infoText={'Square meters per workstation describes the area planned for one workstation. This figure is in relation to the number of planned workstations.'}/>,
      accessor: 'requirement.areaForWorkplace',
      id: 'areaForWorkplace',
      className: 'corporate-number-column',
      showCell: isNotTeamRow,
      Cell: ({ row, cell: { value }, onCellValueChange }) => isNotSuperDepartmentRow({ row }) ?
        <NumberInput value={row.original.requirement.areaForWorkplace}
                     step={1}
                     digits={2}
                     disabled={row.original.requirement.areaForWorkplaceLocked}
                     onDisabledClick={() => changeLockDialog(row.original.id, 'areaForWorkplace')}
                     onBlur={(value) => (value && Number(value) !== 0) ? onUnitChange(row.original.id, { requirement: { areaForWorkplace: value } }) : {}}
                     onChange={(value) => (value && Number(value) !== 0) ? onUnitChange(row.original.id, { requirement: { areaForWorkplace: value } }) : {}}/> :
        <span className={'corporate-number-text-column'}>{row.original.requirement.areaForWorkplace}</span>,
    }

  const perEmployee =
    {
      Header: <TableColumnInfoHeader title={'m2/Employee'} infoText={'Square meters per employee describes the area planned for one employee.'}/>,
      accessor: 'requirement.areaForEmployee',
      id: 'areaForEmployee',
      className: 'corporate-number-column',
      showCell: isNotTeamRow,
      Cell: ({ row, cell: { value }, onCellValueChange }) => isNotSuperDepartmentRow({ row }) ?
        <NumberInput value={row.original.requirement.areaForEmployee}
                     step={1}
                     digits={2}
                     onBlur={(value) => (value && Number(value) !== 0) ? onUnitChange(row.original.id, { requirement: { areaForEmployee: value } }) : {}}
                     onChange={(value) => (value && Number(value) !== 0) ? onUnitChange(row.original.id, { requirement: { areaForEmployee: value } }) : {}}
        /> :
        <span className={'corporate-number-text-column'}>{row.original.requirement.areaForEmployee}</span>,
    }

  const usableNetAreaColumn =
    {
      Header: <TableColumnInfoHeader title={'Usable net Area'} infoText={'Usable Net Area is the total usable floor area excluding furniture that is to be planned for a Subdepartment.'}/>,
      accessor: 'requirement.area',
      id: 'area',
      className: 'corporate-number-column',
      showCell: isNotTeamRow,
      Cell: ({ row, cell: { value }, onCellValueChange }) => isNotSuperDepartmentRow({ row }) ?
        <NumberInput value={row.original.requirement.area}
                     step={1}
                     digits={0}
                     disabled={row.original.requirement.areaLocked}
                     onDisabledClick={() => changeLockDialog(row.original.id, 'area')}
                     onBlur={(value) => (value && Number(value) !== 0) ? onUnitChange(row.original.id, { requirement: { area: value } }) : {}}
                     onChange={(value) => (value && Number(value) !== 0) ? onUnitChange(row.original.id, { requirement: { area: value } }) : {}}/> :
        <span className={'corporate-number-text-column'}>{row.original.requirement.area}</span>,
    }

  const applicationStyleColumn =
    {
      Header: <TableColumnInfoHeader title={'Application Style'}
                                     infoText={'Application Styles defines which type of furniture-sets in which amount is being used by CoDesigner in the office layout.'}/>,
      id: 'applicationProfile',
      className: 'corporate-selection-column',
      inputType: 'select',
      showCell: (data) => isNotTeamRow(data) && isNotSuperDepartmentRow(data),
      accessor: (row) => row.applicationProfile ? row.applicationProfile.id : null,
      Cell: ({ row, cell: { value }, onCellValueChange }) => <TableApplicationProfileSelectButton value={row.original.applicationProfile.id}
                                                                                                  applicationProfile={row.original.applicationProfile}
                                                                                                  unitId={row.original.id}
                                                                                                  onChange={(unitId, unit) => onUnitChange(unitId, unit)}/>,
    }

  const patternStyleColumn =
    {
      Header: <TableColumnInfoHeader title={'Pattern Style'} infoText={'The Pattern Styles are used to define how workstations of a team are designed and arranged within the floorplan.'}/>,
      id: 'patternStyle',
      className: 'corporate-selection-column',
      inputType: 'select',
      showCell: isTeamRow,
      accessor: (row) => row.patternStyle ? row.patternStyle.id : null,
      Cell: ({ row, cell: { value }, onCellValueChange }) => <TablePatternStyleSelectButton value={row.original.patternStyle.id}
                                                                                            patternStyle={row.original.patternStyle}
                                                                                            unitId={row.original.id}
                                                                                            onChange={(unitId, unit) => onUnitChange(unitId, unit)}/>,
    }

  const assignedFloorColumn =
    {
      Header: <TableColumnInfoHeader title={'Assigned Floor'} infoText={'Define on which floor (if there are several) the subdepartment should be found.'} placement={'bottom-end'}/>,
      id: 'floorId',
      className: 'corporate-selection-column',
      inputType: 'select',
      showCell: isDepartmentRow,
      Cell: ({ row, cell: { value } }) => <TableAssignedFloorSelectButton value={row.original.floorId}
                                                                          unit={row.original}
                                                                          onChange={(unit, floor) => assignUnitToFloor(unit, floor)}/>,
    }

  let tableStructure = []

  tableStructure.push(nameColumn)
  tableStructure.push(codeColumn)
  tableStructure.push(colorColumn)
  tableStructure.push(headcount)
  tableStructure.push(deskSharingRatioColumn)
  tableStructure.push(workplaces)

  if (useEmployee) {
    tableStructure.push(perEmployee)
  } else {
    tableStructure.push(squareMeterPerWorkplaceColumn)
  }

  tableStructure.push(usableNetAreaColumn)

  if (showStyles) {
    tableStructure.push(applicationStyleColumn)
    tableStructure.push(patternStyleColumn)
  }

  tableStructure.push(assignedFloorColumn)

  return tableStructure
}

function CorporateTable ({ data, onUnitChange, assignUnitToFloor, useEmployee, selectedOrganisationalUnit, setSelectedOrganisationalUnit, getExpandedRows, showStyles, changeLockDialog }) {

  function getClassName (row) {

    return selectedOrganisationalUnit && row.original.id === selectedOrganisationalUnit.id ? 'row-selected' : null
  }

  let columns = React.useMemo(
    () => getTableStructure(useEmployee, showStyles, assignUnitToFloor, changeLockDialog, setSelectedOrganisationalUnit, onUnitChange),
    [useEmployee, showStyles, assignUnitToFloor, changeLockDialog, setSelectedOrganisationalUnit, onUnitChange],
  )


  return (
    <EditableTable className="corporate-units-table"
                   columns={columns}
                   data={data}
                   getSubRows={(row) => row.organisationalUnits || []}
                   onCellValueChange={(unitId, unit) => onUnitChange(unitId, unit)}
                   getRowProps={row => ({
                     className: getClassName(row),
                   })}
                   getExpandedRows={getExpandedRows}

    />
  )
}

export default CorporateTable