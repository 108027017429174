import * as React from 'react'

const SvgDrawBlockedNew = props =>
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#EBEEF0"/>
        <stop offset="100%" stopColor="#EBEEF0"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect width={32} height={32} fill="#EBEEF0" opacity={0.08} rx={2}/>
      <g stroke="#22293b" transform="translate(6 6)">
        <circle cx={10} cy={10} r={10} fill="#EBEEF0"/>
        <path strokeLinecap="round" d="M17.006 2.995L3.004 16.997"/>
      </g>
    </g>
  </svg>

export default SvgDrawBlockedNew