import * as React from 'react'

const SvgDrawWallInactive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32">
    <path
      d="M10 8.995c0-.273.23-.495.5-.495h7c.276 0 .5.216.5.495v2.01c0 .273-.23.495-.5.495h-7a.494.494 0 01-.5-.495v-2.01zm-4 3c0-.273.23-.495.5-.495h7c.276 0 .5.216.5.495v2.01c0 .273-.23.495-.5.495h-7a.494.494 0 01-.5-.495v-2.01zm4 3c0-.273.23-.495.5-.495h7c.276 0 .5.216.5.495v2.01c0 .273-.23.495-.5.495h-7a.494.494 0 01-.5-.495v-2.01zm-4 3c0-.273.23-.495.5-.495h7c.276 0 .5.216.5.495v2.01c0 .273-.23.495-.5.495h-7a.494.494 0 01-.5-.495v-2.01zm4 3c0-.273.23-.495.5-.495h7c.276 0 .5.216.5.495V23.5h-7.5a.494.494 0 01-.5-.495v-2.01zm4-9c0-.273.23-.495.5-.495h7c.276 0 .5.216.5.495v2.01c0 .273-.23.495-.5.495h-7a.494.494 0 01-.5-.495v-2.01zm4 3c0-.273.23-.495.5-.495h7c.276 0 .5.216.5.495v2.01c0 .273-.23.495-.5.495h-7a.494.494 0 01-.5-.495v-2.01zm-4 3c0-.273.23-.495.5-.495h7c.276 0 .5.216.5.495v2.01c0 .273-.23.495-.5.495h-7a.494.494 0 01-.5-.495v-2.01zm4 3c0-.273.23-.495.5-.495h7c.276 0 .5.216.5.495v2.01c0 .273-.23.495-.5.495H18v-2.505z"
      fill="none"
      fillRule="evenodd"
      stroke="#ff5e0f"
      strokeLinejoin="round"
    />
  </svg>

export default SvgDrawWallInactive