import * as React from 'react'

const SvgToggleIconPositive = props =>
  <svg viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={12} cy={12} r={9} fill="#36D399"/>
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 13.559L11.692 16 16 8"
      />
    </g>
  </svg>

export default SvgToggleIconPositive