import { floorPlanActionTypes } from '../constants'

export function openFlyOut (viewName) {
  return {
    type: floorPlanActionTypes.OPEN_FLY_OUT,
    viewName: viewName,
  }
}

export function closeFlyOut () {
  return { type: floorPlanActionTypes.CLOSE_FLY_OUT }
}

export function openDialog (viewName) {
  return {
    type: floorPlanActionTypes.OPEN_DIALOG,
    viewName: viewName,
  }
}

export function closeDialog () {
  return { type: floorPlanActionTypes.CLOSE_DIALOG }
}

export function increaseLoader () {
  return { type: floorPlanActionTypes.INCREASE_LOADER }
}

export function decreaseLoader () {
  return { type: floorPlanActionTypes.DECREASE_LOADER }
}

export function dragBlockingItem (itemId) {
  return {
    type: floorPlanActionTypes.DRAG_BLOCKING_ITEM,
    id: itemId,
  }
}

export function dropBlockingItem (value) {
  return {
    type: floorPlanActionTypes.DROP_BLOCKING_ITEM,
    value: value,
  }
}