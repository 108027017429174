import * as React from 'react'

const SvgDrawSelectActive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="url(#prefix__a)"
        stroke="#D14720"
        strokeLinejoin="round"
        d="M15.467 7.014l5.68 12.754-4.647-2.26v7.509c0 .25-.173.454-.402.493l-.088.006-1.135-.022a.51.51 0 01-.485-.518h0l.086-7.41-4.77 2.353 5.76-12.905z"
        transform="rotate(-20 15.426 16.265)"
      />
    </g>
  </svg>

export default SvgDrawSelectActive
