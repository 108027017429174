import React from 'react'

import './PlanAppContainer.scss'

export function PlanAppContainer ({ className, children }) {

  const styleClass = className ? ' ' + className : ''

  return (
    <div className={'plan-app-container' + styleClass}>
      {children}
    </div>
  )

}