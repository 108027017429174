import * as React from 'react'

const SvgToggleIconNegative = props =>
  <svg viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={12} cy={12} r={9} fill="#E8E9EE"/>
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 12h10"
      />
    </g>
  </svg>

export default SvgToggleIconNegative
