import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Sidebar from '../../common/components/buildingsFloorsInspector/Sidebar';
import { fetchProjectData, setSelectedFloor } from '../../floorplan/actions/actions';
import { PlanAppContainer } from '../../common/components/layout/PlanAppContainer';
import { useTranslation } from 'react-i18next';

import './DrawingToolApp.scss';
import DrawingToolContent from './DrawingToolContent';

function DrawingToolApp() {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { buildings, selectedFloorId, dataLoaded } = useSelector((state) => ({
    buildings: state.buildings.buildings,
    selectedFloorId: state.appState.selectedFloorId,
    dataLoaded: state.dataLoaded.dataLoaded,
  }));

  useEffect(() => {
    if (!dataLoaded) {
      dispatch(fetchProjectData(projectId));
    }
  }, [projectId, dataLoaded, dispatch]);

  useEffect(() => {
    const floor = buildings.flatMap(building => building.floors).find(floor => floor.id === selectedFloorId);
    if (floor) {
      dispatch(setSelectedFloor(floor.buildingId, selectedFloorId));
    }
  }, [selectedFloorId, buildings, dispatch]);

  return (
    <PlanAppContainer id="test" className={'buildings-app'}>
      <Sidebar title={t('edit_outline')} />
      <DrawingToolContent />
    </PlanAppContainer>
  );
}

export default DrawingToolApp;
