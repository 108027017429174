import * as THREE from 'three'
import { FN3DExtended, Room } from './classes'

function PatternManager () {
  THREE.Group.apply(this, arguments)

  this.editorScene = new THREE.Group()
  this.copyScene = new THREE.Group()
  this.geo = new THREE.BoxBufferGeometry(1, 0.001, 1)
  this.planeRotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2)
  this.spaceKeeperEnd = 0.8

  this.patternElements = []
  this.tempZPosition = 0
  this.tempXPosition = 0

  this.mirrorX = false
  this.mirrorY = false

  this.connect = function (editorScene, copyScene, applicationSettings) {
    this.editorScene = editorScene
    this.copyScene = copyScene
    this.spaceKeeperEnd = applicationSettings.walkDistance
    if (this.spaceKeeperEnd === 0) this.spaceKeeperEnd = 1
  }

  this.setupElements = function (patterndata) {

    this.mirrorX = patterndata.mirrorX
    this.mirrorY = patterndata.mirrorY

    this.patternElements = []

    this.editorScene.children = []
    this.mainArea = this.buildArea(1.56, 1.4, 'main', patterndata.main)
    this.corridor = this.buildArea(1.56, .8, 'corridor', patterndata.corridor)
    this.workBenchFront = this.buildArea(1.56, 0.8, 'workBenchFront', patterndata.workBenchFront)
    this.hSeparator = this.buildArea(1.56, 0.3, 'horizontalSeparator', patterndata.horizontalSeparator)
    this.fill = this.buildArea(1.56, 1.67, 'fill', patterndata.fill)
    this.workBenchBack = this.buildArea(1.56, 0.8, 'workBenchBack', patterndata.workBenchBack)
    this.facade = this.buildArea(1.56, 0.8, 'facade', patterndata.facade)
    this.vEndSeparatorFill = this.buildArea(.6, 1.67, 'verticalBackSeparatorFill', patterndata.verticalBackSeparatorFill)
    this.vEndSeparator = this.buildArea(.6, 1.4, 'verticalBackSeparator', patterndata.verticalBackSeparator)
    this.vSeparatorFill = this.buildArea(.3, 1.67, 'verticalFaceSeparatorFill', patterndata.verticalFaceSeparatorFill)
    this.vSeparator = this.buildArea(.3, 1.4, 'verticalFaceSeparator', patterndata.verticalFaceSeparator)
    this.crossSeparator = this.buildArea(.3, .3, 'crossSeparator', patterndata.crossSeparator)

  }

  this.checkOversize = function () {
    for (let i = 0; i < this.patternElements.length; i++) {
      let area = this.patternElements[i]
      area.actualWidth = parseFloat(area.patternData.defaultWidth)
      area.actualDepth = parseFloat(area.patternData.defaultDepth)
      if (area.type === 'crossSeparator') {
        area.actualWidth = 0
        area.actualDepth = 0
      }

      for (let h = 0; h < area.tempFurnitureList.length; h++) {
        if (area.tempFurnitureList[h] instanceof FN3DExtended) {
        }
      }

      if (area instanceof Room) {

        for (let k = 0; k < area.tempFurnitureList.length; k++) {
          let furniture = area.tempFurnitureList[k]
          let maxWidth = area.actualWidth
          let maxDepth = area.actualDepth

          if ((furniture instanceof FN3DExtended)) {
            furniture.bboxComplete.setFromObject(furniture)

            if ((furniture.bboxComplete.max.x - furniture.bboxComplete.min.x) > area.actualWidth) {
              maxWidth = furniture.bboxComplete.max.x - furniture.bboxComplete.min.x
            }
            if ((furniture.bboxComplete.max.z - furniture.bboxComplete.min.z) > area.actualDepth) {
              maxDepth = furniture.bboxComplete.max.z - furniture.bboxComplete.min.z
            }

            if (!area.patternData.fixedWidth) {
              let newWidth = parseFloat(area.patternData.defaultWidth)
              if (newWidth < maxWidth) newWidth = maxWidth
              area.actualWidth = newWidth

            }

            if (!area.patternData.fixedDepth) {

              let newDepth = parseFloat(area.patternData.defaultDepth)
              if (newDepth < maxDepth) newDepth = maxDepth
              area.actualDepth = maxDepth

            }

          }
        }
      }
    }

  }

  this.rearrangeRootFurniture = function () {

    for (let i = 0; i < this.patternElements.length; i++) {
      let area = this.patternElements[i]

      for (let h = 0; h < area.children.length; h++) {

        if (area.children[h] instanceof FN3DExtended) {
          let boxObject = new THREE.Box3().setFromObject(area.children[h])

          let tempObject = new THREE.Mesh(area.geometry, new THREE.MeshBasicMaterial({ color: 0x000000 }))
          tempObject.position.set(area.position.x, area.position.y, area.position.z)

          let areaMinX = area.position.x
          let areaMaxZ = area.position.z
          let areaMinZ = area.position.z - area.actualDepth

          if (boxObject.max.z > areaMaxZ) {
            let shiftup = areaMinZ - boxObject.min.z
            area.tempFurnitureList[h].position.z += shiftup
            area.tempFurnitureList[h].selectorPlane.position.z += shiftup

          } else if (boxObject.min.z < areaMinZ) {
            let shiftdown = areaMaxZ - boxObject.max.z
            area.tempFurnitureList[h].position.z += shiftdown
            area.tempFurnitureList[h].selectorPlane.position.z += shiftdown
          }

          if (boxObject.min.x < areaMinX) {
            let shiftleft = areaMinX - boxObject.min.x
            area.tempFurnitureList[h].position.x += shiftleft
            area.tempFurnitureList[h].selectorPlane.position.x += shiftleft

          }

        }
      }
    }

  }

  this.adjustSizes = function () {
    this.mainArea.updateSize()
    this.corridor.updateSize()
    this.workBenchFront.updateSize()
    this.hSeparator.updateSize()
    this.fill.updateSize()
    this.workBenchBack.updateSize()
    this.facade.updateSize()
    this.vEndSeparatorFill.updateSize()
    this.vEndSeparator.updateSize()
    this.vSeparatorFill.updateSize()
    this.vSeparator.updateSize()
    this.crossSeparator.updateSize()

    //now check connected sizes
    this.connectSizes()

    for (let i = 0; i < this.editorScene.children.length; i++) {
      let target = this.editorScene.children[i]
      if (target instanceof Room) {
        target.createLine()
      }
    }

  }

  this.connectSizes = function () {
    this.mainArea.forceSize(this.fill.actualWidth, null)

    if ((!this.mirrorX) || (this.mirrorX && this.corridor.patternData.mirrored)) {
      this.corridor.forceSize(this.mainArea.actualWidth, null)
    }
    if ((!this.mirrorX) || (this.mirrorX && this.workBenchFront.patternData.mirrored)) {
      this.workBenchFront.forceSize(this.mainArea.actualWidth, null)
    }
    if ((!this.mirrorX) || (this.mirrorX && this.workBenchBack.patternData.mirrored)) {
      this.workBenchBack.forceSize(this.mainArea.actualWidth, null)
    }
    if ((!this.mirrorX) || (this.mirrorX && this.facade.patternData.mirrored)) {
      this.facade.forceSize(this.mainArea.actualWidth, null)
    }

    this.vEndSeparator.forceSize(null, this.mainArea.actualDepth)
    this.vSeparator.forceSize(null, this.mainArea.actualDepth)
    this.vSeparator.forceSize(this.crossSeparator.actualWidth, null)
    this.hSeparator.forceSize(this.mainArea.actualWidth, null)
    this.hSeparator.forceSize(null, this.crossSeparator.actualDepth)
    this.crossSeparator.forceSize(this.vSeparator.actualWidth, this.hSeparator.actualDepth)
    this.crossSeparator.forceSize(this.vSeparatorFill.actualWidth, null)
    this.fill.forceSize(this.mainArea.actualWidth, null)
    this.vSeparatorFill.forceSize(this.crossSeparator.actualWidth, null)
    this.vEndSeparatorFill.forceSize(this.vEndSeparator.actualWidth, this.fill.actualDepth)
  }

  this.buildArea = function (stdWidth, stdDepth, type, data) {

    let area = new Room(this.geo.clone(), new THREE.MeshBasicMaterial({ color: 0xeeeeee }))
    area.initialColor = area.material
    area.patternData = data
    area.type = type
    //area.geometry.applyMatrix(new THREE.Matrix4().makeTranslation(0.5, 0, -0.5))
    area.setStdSize(stdWidth, stdDepth)
    area.visible = false
    this.editorScene.add(area)
    area.position.y = 0.002

    this.patternElements.push(area)

    return area
  }

  this.isOdd = function (num) { return num % 2}

  this.drawScene = function (patterndata, count) {

    this.tempZPosition = 0
    this.tempXPosition = 0
    this.count = count
    this.copyScene.children = []
    this.stdPatternCounter = 0
    for (let k = 0; k < this.editorScene.children.length; k++) {
      this.editorScene.children[k].drawWalls()
    }

    if (patterndata.verticalFaceSeparator.active) {
      this.tempXPosition = this.vSeparator.actualWidth / 2
    }

    // Draw Corridor
    if (patterndata.corridor.active) {
      this.corridor.position.z = 0
      this.corridor.position.x = this.tempXPosition
      this.corridor.visible = true

      if (this.mirrorX && patterndata.corridor.mirrored) {
        this.copyScene.add(this.copyAndMirrorX(this.corridor))

      }
      if (this.mirrorX && !patterndata.corridor.mirrored) {
        this.corridor.position.x = -this.corridor.actualWidth / 2
      }
      this.tempZPosition += this.corridor.actualDepth

    } else {
      this.corridor.visible = false
    }

    // Draw Workbench Front
    if (patterndata.workBenchFront.active) {
      this.workBenchFront.position.z = -this.tempZPosition
      this.workBenchFront.position.x = this.tempXPosition
      this.workBenchFront.visible = true

      if (this.mirrorX && patterndata.workBenchFront.mirrored) {
        this.copyScene.add(this.copyAndMirrorX(this.workBenchFront))
      }
      if (this.mirrorX && !patterndata.workBenchFront.mirrored) {
        this.workBenchFront.position.x = -this.workBenchFront.actualWidth / 2
      }

      this.tempZPosition += this.workBenchFront.actualDepth

    } else {
      this.workBenchFront.visible = false
    }

    //Draw Main Area
    if (1) {
      this.mainArea.position.z = -this.tempZPosition
      this.mainArea.position.x = this.tempXPosition
      this.mainArea.visible = true
      if (this.mirrorX) {
        this.copyScene.add(this.copyAndMirrorX(this.mainArea))
      }
      this.stdPatternCounter++
      //Draw Separator
      if (patterndata.verticalFaceSeparator.active) {
        this.vSeparator.position.z = -this.tempZPosition
        this.vSeparator.position.x = -this.tempXPosition
        this.vSeparator.visible = true
      } else {
        this.vSeparator.visible = false
      }

      //Draw Back Separator
      if (patterndata.verticalBackSeparator.active) {
        this.vEndSeparator.position.z = -this.tempZPosition
        this.vEndSeparator.position.x = this.tempXPosition + this.mainArea.actualWidth + this.spaceKeeperEnd
        this.vEndSeparator.visible = true
        if (this.mirrorX && this.vEndSeparator.patternData.mirrored) {

          this.copyScene.add(this.copyAndMirrorX(this.vEndSeparator))
        }
      } else {
        this.vEndSeparator.visible = false
      }

      this.tempZPosition += this.mainArea.actualDepth

    } else {
      this.mainArea.visible = false
    }

    //Draw first Side Separator
    if (patterndata.horizontalSeparator.active) {
      this.hSeparator.position.z = -this.tempZPosition
      this.hSeparator.position.x = this.tempXPosition
      this.hSeparator.visible = true
      if (this.mirrorX) {
        this.copyScene.add(this.copyAndMirrorX(this.hSeparator))
      }
      this.tempZPosition += this.hSeparator.actualDepth

    } else {
      this.hSeparator.visible = false
    }

    // Draw first Cross Separator
    if (patterndata.horizontalSeparator.active && patterndata.verticalFaceSeparator.active) {
      this.crossSeparator.position.z = -this.tempZPosition + this.hSeparator.actualDepth
      this.crossSeparator.position.x = -this.tempXPosition
      this.crossSeparator.visible = true
    } else {
      this.crossSeparator.visible = false
    }

    // Center zone or Repeating Patterns
    for (let j = 0; j < this.count - 2; j++) {
      if (patterndata.fill.active) {
        // for the first, create the "true" Center Zone

        if (j === 0) {
          this.fill.position.z = -this.tempZPosition
          this.fill.position.x = this.tempXPosition
          this.fill.visible = true
          if (this.mirrorX) {
            this.copyScene.add(this.copyAndMirrorX(this.fill))
          }

          //Draw Separator
          if (patterndata.verticalFaceSeparator.active) {
            this.vSeparatorFill.position.z = -this.tempZPosition
            this.vSeparatorFill.position.x = -this.tempXPosition
            this.vSeparatorFill.visible = true

          } else {
            this.vSeparatorFill.visible = false
            this.vSeparatorFill.visible = false
          }

          // Draw Back Separator
          if (patterndata.verticalBackSeparator.active) {
            this.vEndSeparatorFill.position.z = -this.tempZPosition
            this.vEndSeparatorFill.position.x = this.tempXPosition + this.mainArea.actualWidth + this.spaceKeeperEnd
            this.vEndSeparatorFill.visible = true
            if (this.mirrorX && this.vEndSeparator.patternData.mirrored) {
              this.copyScene.add(this.copyAndMirrorX(this.vEndSeparatorFill))
            }
          } else {
            this.vEndSeparatorFill.visible = false
          }

          this.tempZPosition += this.fill.actualDepth

        } else {

          let copy = this.fill.duplicate()
          this.copyScene.add(copy)
          if (this.mirrorX) {
            this.copyScene.add(this.copyAndMirrorX(this.fill))
          }

          if (patterndata.verticalFaceSeparator.active) {
            let copy2 = this.vSeparatorFill.duplicate()
            this.copyScene.add(copy2)
            copy2.position.z = -this.tempZPosition
            copy2.position.x = -this.tempXPosition
          }

          if (patterndata.verticalBackSeparator.active) {
            let copy2 = this.vEndSeparatorFill.duplicate()
            this.copyScene.add(copy2)
            copy2.position.z = -this.tempZPosition
            copy2.position.x = this.tempXPosition + this.mainArea.actualWidth + this.spaceKeeperEnd
            this.vEndSeparatorFill.visible = true
            if (this.mirrorX && this.vEndSeparator.patternData.mirrored) {
              this.copyScene.add(this.copyAndMirrorX(copy2))
            }
          }
          copy.position.z = -this.tempZPosition
          this.tempZPosition += copy.actualDepth
        }

        if (patterndata.horizontalSeparator.active) {
          let copy = this.hSeparator.duplicate()
          this.copyScene.add(copy)
          copy.position.z = -this.tempZPosition
          this.tempZPosition += copy.actualDepth
          if (this.mirrorX) {
            this.copyScene.add(this.copyAndMirrorX(copy))
          }
        }

        // Draw Fill Cross Separator
        if (patterndata.horizontalSeparator.active && patterndata.verticalFaceSeparator.active) {
          let copy = this.crossSeparator.duplicate()
          this.copyScene.add(copy)
          copy.position.z = -this.tempZPosition + this.hSeparator.actualDepth

          this.crossSeparator.visible = true
        }

      } else {
        this.fill.visible = false
        this.vEndSeparatorFill.visible = false
        this.vSeparatorFill.visible = false

        let copy = this.mainArea.duplicate()
        this.copyScene.add(copy)

        if (patterndata.verticalBackSeparator.active) {
          let copy2 = this.vEndSeparator.duplicate()
          this.copyScene.add(copy2)
          copy2.position.x = this.tempXPosition + this.mainArea.actualWidth + this.spaceKeeperEnd
          copy2.position.z = -this.tempZPosition
          if (this.mirrorX && this.vEndSeparator.patternData.mirrored) {
            this.copyScene.add(this.copyAndMirrorX(copy2))
          }
        }

        copy.position.z = -this.tempZPosition
        if (patterndata.mirrorY && this.isOdd(this.stdPatternCounter)) {
          copy.mirrorY()
        }
        this.stdPatternCounter++
        if (this.mirrorX) {
          this.copyScene.add(this.copyAndMirrorX(copy))
        }
        if (patterndata.verticalFaceSeparator.active) {
          let copy2 = this.vSeparator.duplicate()
          this.copyScene.add(copy2)
          copy2.position.x = -this.tempXPosition
          copy2.position.z = -this.tempZPosition

        }
        this.tempZPosition += copy.actualDepth

        if (patterndata.horizontalSeparator.active) {
          let copy = this.hSeparator.duplicate()
          this.copyScene.add(copy)
          copy.position.z = -this.tempZPosition
          if (this.mirrorX) {
            this.copyScene.add(this.copyAndMirrorX(copy))
          }
          this.tempZPosition += copy.actualDepth
        }

        // Draw Fill Cross Separator
        if (patterndata.horizontalSeparator.active && patterndata.verticalFaceSeparator.active) {
          let copy = this.crossSeparator.duplicate()
          this.copyScene.add(copy)
          copy.position.z = -this.tempZPosition + this.hSeparator.actualDepth

          this.crossSeparator.visible = true
        }
      }
    }

    // Last Workspace Row
    if (this.count > 1) {
      let copy = this.mainArea.duplicate()
      this.copyScene.add(copy)

      if (patterndata.verticalBackSeparator.active) {
        let copy2 = this.vEndSeparator.duplicate()
        this.copyScene.add(copy2)
        copy2.position.x = this.tempXPosition + this.mainArea.actualWidth + this.spaceKeeperEnd
        copy2.position.z = -this.tempZPosition

        if (this.mirrorX && this.vEndSeparator.patternData.mirrored) {
          this.copyScene.add(this.copyAndMirrorX(copy2))
        }
      }

      copy.position.z = -this.tempZPosition
      if (patterndata.mirrorY && this.isOdd(this.stdPatternCounter)) {
        copy.mirrorY()
      }
      this.stdPatternCounter++
      if (this.mirrorX) {
        this.copyScene.add(this.copyAndMirrorX(copy))
      }
      if (patterndata.verticalFaceSeparator.active) {
        let copy2 = this.vSeparator.duplicate()
        this.copyScene.add(copy2)
        copy2.position.x = -this.tempXPosition
        copy2.position.z = -this.tempZPosition

      }
      this.tempZPosition += copy.actualDepth

    }

    //Workbench Back
    if (patterndata.workBenchBack.active) {

      this.workBenchBack.position.z = -this.tempZPosition
      this.workBenchBack.position.x = this.tempXPosition
      this.tempZPosition += this.workBenchBack.actualDepth

      this.workBenchBack.visible = true

      if (this.mirrorX && this.workBenchBack.patternData.mirrored) {
        this.copyScene.add(this.copyAndMirrorX(this.workBenchBack))
      }
      if (this.mirrorX && !this.workBenchBack.patternData.mirrored) {
        this.workBenchBack.position.x = -this.workBenchBack.actualWidth / 2
      }
    } else {
      this.workBenchBack.visible = false
    }

    // now grab all
    let tempElement = []
    for (let i = 0; i < this.copyScene.children.length; i++) {
      if (this.copyScene.children[i] instanceof Room) {
        tempElement.push(this.copyScene.children[i])
      }
    }
    for (let i = 0; i < this.editorScene.children.length; i++) {
      if (this.editorScene.children[i] instanceof Room) {
        tempElement.push(this.editorScene.children[i])
      }
    }

    let bboxEditor = new THREE.Box3().setFromObject(this.editorScene)
    let bboxCopy = new THREE.Box3().setFromObject(this.copyScene)
    let minX = Math.min(bboxEditor.min.x, bboxCopy.min.x)
    let minY = Math.min(bboxEditor.min.z, bboxCopy.min.z)
    let maxX = Math.max(bboxEditor.max.x, bboxCopy.max.x)
    let maxY = Math.max(bboxEditor.max.z, bboxCopy.max.z)
    let width = maxX - minX
    let depth = maxY - minY
    let shiftIfNoBackSeparator = 0
    if (!patterndata.verticalBackSeparator.active || !patterndata.verticalBackSeparator.mirrored) {
      shiftIfNoBackSeparator = this.spaceKeeperEnd

    }

    if (1) {
      for (let i = 0; i < tempElement.length; i++) {

        this.copyScene.add(tempElement[i].duplicate()
          .translateX(width + shiftIfNoBackSeparator))
        this.copyScene.add(tempElement[i].duplicate()
          .translateX(-width - shiftIfNoBackSeparator))
        this.copyScene.add(tempElement[i].duplicate()
          .translateX(-width - shiftIfNoBackSeparator)
          .translateZ(-depth - 1))
        this.copyScene.add(tempElement[i].duplicate()
          .translateZ(-depth - 1))
        this.copyScene.add(tempElement[i].duplicate()
          .translateX(width + shiftIfNoBackSeparator)
          .translateZ(-depth - 1))
      }

      if (patterndata.facade.active) {

        this.facade.position.z = -this.tempZPosition * 2 - 1
        this.facade.position.x = this.tempXPosition

        this.facade.visible = true

        if (this.mirrorX && this.facade.patternData.mirrored) {
          this.copyScene.add(this.copyAndMirrorX(this.facade))
        }
        if (this.mirrorX && !this.facade.patternData.mirrored) {
          this.facade.position.x = -this.facade.actualWidth / 2
        }

        this.copyScene.add(this.facade.duplicate()
          .translateX(width))
        if (this.facade.patternData.mirrored) this.copyScene.add(this.facade.duplicate()
          .translateX(width - this.facade.actualWidth))

        this.copyScene.add(this.facade.duplicate()
          .translateX(-width))
        if (this.facade.patternData.mirrored) this.copyScene.add(this.facade.duplicate()
          .translateX(-width - this.facade.actualWidth))

      } else {
        this.facade.visible = false
      }

    }

  }

  this.getCenterPosition = function () {
    return new THREE.Vector3(0, 0, this.tempZPosition / 2 + 0.2)
  }

  this.copyAndMirrorX = function (element) {
    let copy = element.duplicate()

    copy.contentDirectionX = -1
    copy.scale.x = -1
    copy.position.x *= -1

    return copy
  }

}

PatternManager.prototype = Object.create(THREE.Group.prototype)
PatternManager.prototype.constructor = Room

export { PatternManager }