import React, { Component } from 'react'
import Dialog, { DialogButtonGroup, DialogContent } from '../../../common/components/Dialog'
import { Field, Form, Formik } from 'formik'
import { connect } from 'react-redux'
import './MarkerDialog.scss'
import { createMarker, updateMarker } from '../../actions/actions'
import ToggleSwitch from '../../../common/components/ToggleSwitch'
import SelectButton from '../../../common/components/SelectButton'
import Button from '../../../common/components/Button'

const markerType = {
  STAIRCASE: 'STAIRCASE',
  TOILET: 'TOILET',
  ENTRANCE: 'ENTRANCE',
  CAFETERIA: 'CAFETERIA',
  CUSTOM: 'CUSTOM',
}

const markerTypes = [
  {
    value: 'STAIRCASE',
    label: 'Staircase',
  },
  {
    value: 'TOILET',
    label: 'Toilet',
  },
  {
    value: 'ENTRANCE',
    label: 'Entrance',
  },
  {
    value: 'CAFETERIA',
    label: 'Cafeteria',
  },
  {
    value: 'CUSTOM',
    label: 'Custom',
  },
]

const defaultMarker = {
  id: null,
  displayName: '',
  type: markerType.STAIRCASE,
  floorId: null,
  position: [],
  isOutsideZone: true,
}

const formikMarker = {
  id: null,
  displayName: '',
  type: markerType.STAIRCASE,
  allFloors: false,
  floorId: null,
  position: [],
}

class MarkerDialog extends Component {

  render () {

    let defaultValues = this.createFormikMarker(this.props.marker)

    return (
      <Dialog onCancel={this.close}
              title={'Marker'}
              className={'marker-dialog'}
              innerClassName={'marker-dialog-inner'}>
        <DialogContent>
          <Formik initialValues={defaultValues} onSubmit={(values) => this.confirm(values)}>
            <Form id={'marker-form'}>

              <Field name={'displayName'} className={'marker-name'} type={'text'} autoComplete={'off'} placeholder={'Markername'}/>

              <Field name={'type'} component={SelectButton} options={markerTypes}/>

              <Field name={'allFloors'} component={ToggleSwitch} label={'All floors'}/>

            </Form>
          </Formik>
        </DialogContent>
        <DialogButtonGroup className={'dialog-buttons'}>
          <Button onClick={this.close}>Cancel</Button>
          <Button form={'marker-form'} type={'submit'} variant={'contained'}>Confirm</Button>
        </DialogButtonGroup>
      </Dialog>
    )
  }

  close = () => {
    this.props.onClose()
  }

  confirm = (values) => {
    let marker = this.createBackendMarker(values)

    if (!marker.id)
      this.props.createMarker(marker)
    else
      this.props.updateMarker(marker)

    this.close()
  }

  createFormikMarker (marker) {
    let markerToReturn = { ...formikMarker }

    if (!marker)
      return markerToReturn

    markerToReturn.id = marker.id ? marker.id : null
    markerToReturn.displayName = marker.displayName ? marker.displayName : markerToReturn.displayName
    markerToReturn.type = marker.type ? marker.type : markerToReturn.type
    markerToReturn.allFloors = marker.floorId === null
    markerToReturn.floorId = marker.floorId ? marker.floorId : ''
    markerToReturn.position = marker.position

    return markerToReturn
  }

  createBackendMarker (marker) {
    let backendMarker = { ...defaultMarker }

    backendMarker.id = marker.id ? marker.id : backendMarker.id
    backendMarker.displayName = marker.displayName
    backendMarker.type = markerType[marker.type]
    backendMarker.floorId = marker.allFloors ? null : this.props.selectedFloorId
    backendMarker.position = marker.position

    return backendMarker
  }

}

let mapStateToProps = (state) => {
  return {
    selectedFloorId: state.appState.selectedFloorId,
  }
}

let mapDispatchToProps = {
  createMarker: createMarker,
  updateMarker: updateMarker,
}

export default connect(mapStateToProps, mapDispatchToProps)(MarkerDialog)