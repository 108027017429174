import * as THREE from 'three'

import door_active from '../../../assets/images/png/icon_doors_active.png'
import door_inactive from '../../../assets/images/png/icon_doors_inactive.png'
import window_active from '../../../assets/images/png/icon_windows_active.png'
import window_inactive from '../../../assets/images/png/icon_windows_inactive.png'
import pillar_active from '../../../assets/images/png/icon_pillars_active.png'
import pillar_inactive from '../../../assets/images/png/icon_pillars_inactive.png'
import link_active from '../../../assets/images/png/icon_links_active.png'
import link_inactive from '../../../assets/images/png/icon_links_inactive.png'

export const drawingColor = {
  transparent: '#ffffff',

  scale: '#f58233',
  scaleHandler: '#f58233',

  outline: '#4a4a4a',
  outlineHandler: '#4a4a4a',

  vertex: '#f58233',
  edge: '#f58233',
  vertexHandler: '#f58233',
  resizeHandler: '#f58233',
  face: '#ffffff',

  door: '#00da0d',
  window: '#24dad7',
  wall: '#0f0faa',
  wallHandler: '#0f0faa',
  pillar: '#dccfa7',
  pillarHandler: '#dccfa7',

  link: '#55864a',
  linkHandler: '#55864a',

  zone: '#ff6f00',
  team: '#0a37b5',
  infrastructure: '#dddddd',
  blocked: '#2b2b2b',

  axes: '#ff003d',
  axesHandler: '#ff003d',

  debug: '#ff00ff',
  disabled: '#404040',
  magicGuideLine: '#9e0fdb',
}

export const drawingSegments = {
  pillar: 32,
  vertex: 32,
  marker: 32,
}

export const drawingOpacity = {
  scale: .5,

  outlineHandler: .6,

  vertex: .6,
  face: .1,
  resizeHandler: .6,

  wall: 1,
  wallHandler: .6,
  marker: .4,
  markerInner: .7,

  link: 1,
  linkHandler: .6,

  infrastructure: .6,
  zone: .2,
  zoneSelected: .8,
  team: .2,
  teamSelected: .8,
  blocked: .6,
  blockedSelected: .8,

  axesFine: .4,
  axesHandler: .6,

  transparent: 0,
  magicGuideLine: .7,
}

export const drawingSize = {
  markerMinSize: .4,
  iconMarkerSize: .6,
  edgeThickness: .02,
  edgeCatchThickness: 1,
  handlerSize: .6,
  handlerSizeSelected: .8,
  handleSizeDeselected: .4,
  door: .7,
  window: .45,
  pillar: .5,
  magicGuideLineThickness: .02,
  magicGuideLineMax: 999999,
}

export const drawingTypes = {
  outline: 'outline',
  edge: 'edge',
  scale: 'scale',
  polygon: 'polygon',
  rectangle: 'rectangle',
  door: 'door',
  window: 'window',
  wall: 'wall',
  pillar: 'pillar',
  link: 'link',
  axes: 'axes',
}

export const orientation = {
  horizontal: 'horizontal',
  vertical: 'vertical',
  horVer: 'horVer',
}

export const threeMaterial = {
  scale: new THREE.MeshBasicMaterial({ color: drawingColor.scale, transparent: true }),
  scaleHandler: new THREE.MeshBasicMaterial({ color: drawingColor.scaleHandler, transparent: true, opacity: drawingOpacity.scale }),

  outline: new THREE.MeshBasicMaterial({ color: drawingColor.outline }),
  outlineHandler: new THREE.MeshBasicMaterial({ color: drawingColor.outlineHandler, transparent: true, opacity: drawingOpacity.outlineHandler }),

  vertex: new THREE.MeshBasicMaterial({ color: drawingColor.vertex }),
  vertexHandler: new THREE.MeshBasicMaterial({ color: drawingColor.vertexHandler, transparent: true, opacity: drawingOpacity.vertex }),
  edge: new THREE.MeshBasicMaterial({ color: drawingColor.edge }),
  face: new THREE.MeshBasicMaterial({ color: drawingColor.face, transparent: true, opacity: drawingOpacity.face }),
  resizeHandler: new THREE.MeshBasicMaterial({ color: drawingColor.resizeHandler, transparent: true, opacity: drawingOpacity.resizeHandler }),

  door: new THREE.MeshBasicMaterial({ color: drawingColor.door }),
  doorHandler: new THREE.MeshBasicMaterial({ color: drawingColor.door, transparent: true, opacity: drawingOpacity.marker }),
  window: new THREE.MeshBasicMaterial({ color: drawingColor.window }),
  windowHandler: new THREE.MeshBasicMaterial({ color: drawingColor.window, transparent: true, opacity: drawingOpacity.marker }),
  pillar: new THREE.MeshBasicMaterial({ color: drawingColor.pillar }),
  pillarHandler: new THREE.MeshBasicMaterial({ color: drawingColor.pillarHandler, transparent: true, opacity: drawingOpacity.marker }),
  disabledMarker: new THREE.MeshBasicMaterial({ color: drawingColor.disabled, transparent: true, opacity: drawingOpacity.marker }),
  disabledMarkerInner: new THREE.MeshBasicMaterial({ color: drawingColor.disabled, transparent: true, opacity: drawingOpacity.markerInner }),

  wall: new THREE.MeshBasicMaterial({ color: drawingColor.wall, transparent: true, opacity: drawingOpacity.wall }),
  wallHandler: new THREE.MeshBasicMaterial({ color: drawingColor.wallHandler, transparent: true, opacity: drawingOpacity.wallHandler }),

  link: new THREE.MeshBasicMaterial({ color: drawingColor.link, transparent: true, opacity: drawingOpacity.link }),
  linkHandler: new THREE.MeshBasicMaterial({ color: drawingColor.linkHandler, transparent: true, opacity: drawingOpacity.linkHandler }),

  infrastructure: new THREE.MeshBasicMaterial({ color: drawingColor.infrastructure, transparent: true, opacity: drawingOpacity.infrastructure }),
  zone: new THREE.MeshBasicMaterial({ color: drawingColor.zone, transparent: true, opacity: drawingOpacity.zone }),
  zoneSelected: new THREE.MeshBasicMaterial({ color: drawingColor.zone, transparent: true, opacity: drawingOpacity.zoneSelected }),
  team: new THREE.MeshBasicMaterial({ color: drawingColor.team, transparent: true, opacity: drawingOpacity.team }),
  teamSelected: new THREE.MeshBasicMaterial({ color: drawingColor.team, transparent: true, opacity: drawingOpacity.teamSelected }),
  blocked: new THREE.MeshBasicMaterial({ color: drawingColor.blocked, transparent: true, opacity: drawingOpacity.blocked }),
  blockedSelected: new THREE.MeshBasicMaterial({ color: drawingColor.blocked, transparent: true, opacity: drawingOpacity.blockedSelected }),

  axes: new THREE.MeshBasicMaterial({ color: drawingColor.axes, transparent: true }),
  axesFine: new THREE.MeshBasicMaterial({ color: drawingColor.axes, transparent: true, opacity: drawingOpacity.axesFine }),
  axesHandler: new THREE.MeshBasicMaterial({ color: drawingColor.axesHandler, transparent: true, opacity: drawingOpacity.axesHandler }),

  debug: new THREE.MeshBasicMaterial({ color: drawingColor.debug }),
  transparent: new THREE.MeshBasicMaterial({ color: drawingColor.transparent, transparent: true, opacity: drawingOpacity.transparent }),

  magicGuideLine: new THREE.MeshBasicMaterial({ color: drawingColor.magicGuideLine, transparent: true, opacity: drawingOpacity.magicGuideLine }),
}

export function getDrawingTypesMaterial (type) {
  let innerMaterial = threeMaterial.transparent
  let material = threeMaterial.transparent

  switch (type) {
    case drawingTypes.outline:
      innerMaterial = threeMaterial.outline
      material = threeMaterial.outlineHandler
      break
    case drawingTypes.rectangle:
    case drawingTypes.polygon:
      innerMaterial = threeMaterial.vertex
      material = threeMaterial.vertexHandler
      break
    case drawingTypes.door:
      innerMaterial = threeMaterial.door
      material = threeMaterial.doorHandler
      break
    case drawingTypes.window:
      innerMaterial = threeMaterial.window
      material = threeMaterial.windowHandler
      break
    case drawingTypes.pillar:
      innerMaterial = threeMaterial.pillar
      material = threeMaterial.pillarHandler
      break
    case drawingTypes.wall:
      innerMaterial = threeMaterial.wall
      material = threeMaterial.wallHandler
      break
    case drawingTypes.link:
      innerMaterial = threeMaterial.link
      material = threeMaterial.linkHandler
      break
    case drawingTypes.scale:
      innerMaterial = threeMaterial.scale
      material = threeMaterial.scaleHandler
      break
    case drawingTypes.axes:
      innerMaterial = threeMaterial.axes
      material = threeMaterial.axesHandler
      break
    default:
      break
  }

  return { innerMaterial: innerMaterial, material: material, inactive: threeMaterial.transparent }
}

const iconMaterialTypes = {
  door: drawingTypes.door,
  door_inactive: drawingTypes.door + '_inactive',
  window: drawingTypes.window,
  window_inactive: drawingTypes.window + '_inactive',
  pillar: drawingTypes.pillar,
  pillar_inactive: drawingTypes.pillar + '_inactive',
  link: drawingTypes.link,
  link_inactive: drawingTypes.link + '_inactive',
}

const doorMaterial = new THREE.MeshBasicMaterial({ map: getIconTexture(iconMaterialTypes.door), transparent: true, opacity: 1, depthWrite: false })
const doorInactiveMaterial = new THREE.MeshBasicMaterial({ map: getIconTexture(iconMaterialTypes.door_inactive), transparent: true, opacity: 1, depthWrite: false })
const windowMaterial = new THREE.MeshBasicMaterial({ map: getIconTexture(iconMaterialTypes.window), transparent: true, opacity: 1 })
const windowInactiveMaterial = new THREE.MeshBasicMaterial({ map: getIconTexture(iconMaterialTypes.window_inactive), transparent: true, opacity: 1 })
const pillarMaterial = new THREE.MeshBasicMaterial({ map: getIconTexture(iconMaterialTypes.pillar), transparent: true, opacity: 1 })
const pillarInactiveMaterial = new THREE.MeshBasicMaterial({ map: getIconTexture(iconMaterialTypes.pillar_inactive), transparent: true, opacity: 1 })
const linkMaterial = new THREE.MeshBasicMaterial({ map: getIconTexture(iconMaterialTypes.link), transparent: true, opacity: 1 })
const linkInactiveMaterial = new THREE.MeshBasicMaterial({ map: getIconTexture(iconMaterialTypes.link_inactive), transparent: true, opacity: 1 })

const iconMaterials = {
  door: doorMaterial.clone(),
  door_inactive: doorInactiveMaterial.clone(),
  window: windowMaterial.clone(),
  window_inactive: windowInactiveMaterial.clone(),
  pillar: pillarMaterial.clone(),
  pillar_inactive: pillarInactiveMaterial.clone(),
  link: linkMaterial.clone(),
  link_inactive: linkInactiveMaterial.clone(),
}

export function getDrawingTypesIconMaterial (iconMaterialType) {
  return { activeMaterial: iconMaterials[iconMaterialType], inactiveMaterial: iconMaterialType[iconMaterialType + '_inactive'] }
}

function getIconTexture (iconMaterialType) {
  const textureLoader = new THREE.TextureLoader()

  switch (iconMaterialType) {
    case iconMaterialTypes.door:
      return textureLoader.load(door_active, function (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    case iconMaterialTypes.door_inactive:
      return textureLoader.load(door_inactive, function (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(50, 30)
      })
    case iconMaterialTypes.window:
      return textureLoader.load(window_active, function (texture) {
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    case iconMaterialTypes.window_inactive:
      return textureLoader.load(window_inactive, function (texture) {
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    case iconMaterialTypes.pillar:
      return textureLoader.load(pillar_active, function (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    case iconMaterialTypes.pillar_inactive:
      return textureLoader.load(pillar_inactive, function (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    case iconMaterialTypes.link:
      return textureLoader.load(link_active, function (texture) {
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    case iconMaterialTypes.link_inactive:
      return textureLoader.load(link_inactive, function (texture) {
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    default:
      return
  }
}

export function createMarker (radius, material, innerMaterial, segments = drawingSegments.marker) {
  const markerGeometry = new THREE.CircleGeometry(radius, segments)
  const markerMesh = new THREE.Mesh(markerGeometry, material)
  markerMesh.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI / 2))
  markerMesh.name = 'marker'

  const innerMarkerGeometry = getCircleGeometry(drawingSize.markerMinSize, segments)
  const innerMarkerMesh = new THREE.Mesh(innerMarkerGeometry, innerMaterial)

  markerMesh.add(innerMarkerMesh)

  return markerMesh
}

export function getCircleGeometry (radius, segments = drawingSegments.vertex) {
  return new THREE.CircleBufferGeometry(radius, segments)
}

export function getIcon (material, width, height) {
  const iconGeometry = new THREE.PlaneBufferGeometry(width, height, 1, 1)
  const iconMesh = new THREE.Mesh(iconGeometry, material)
  iconMesh.name = 'icon'

  iconMesh.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI / 2))

  return iconMesh
}

// 2D
export function createVertex (material = threeMaterial.transparent, innerMaterial = threeMaterial.vertex, handlerScale = 1) {
  const vertexGeometry = getCircleGeometry(drawingSize.handlerSize * handlerScale, drawingSegments.vertex)
  const vertexMesh = new THREE.Mesh(vertexGeometry, material)
  vertexMesh.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI / 2))
  vertexMesh.edges = []
  vertexMesh.name = 'vertex'

  const innerVertexGeometry = getCircleGeometry(drawingSize.edgeThickness, drawingSegments.vertex)
  const innerVertexMesh = new THREE.Mesh(innerVertexGeometry, innerMaterial)

  vertexMesh.add(innerVertexMesh)

  return vertexMesh
}

// 3D
export function createEdge (vertexA, vertexB, material = threeMaterial.edge, scale = 1) {
  const path = new THREE.LineCurve(vertexA.position, vertexB.position)
  const tubeGeometry = new THREE.TubeBufferGeometry(path, 0, drawingSize.edgeThickness)
  const tubeCatchGeometry = new THREE.TubeBufferGeometry(path, 0, drawingSize.edgeCatchThickness * scale)
  const edgeMesh = new THREE.Mesh(tubeGeometry, material)
  edgeMesh.name = 'edge'
  const edgeCatchMesh = new THREE.Mesh(tubeCatchGeometry, threeMaterial.transparent)
  edgeCatchMesh.name = 'edgeCatch'

  edgeMesh.add(edgeCatchMesh)
  edgeCatchMesh.position.set(0, -2, 0)
  edgeMesh.vertices = [vertexA.uuid, vertexB.uuid]

  if (!vertexA.edges.includes(edgeMesh.uuid))
    vertexA.edges.push(edgeMesh.uuid)
  if (!vertexB.edges.includes(edgeMesh.uuid))
    vertexB.edges.push(edgeMesh.uuid)

  return edgeMesh
}

export function createTubeGeometry (positionA, positionB, thickness) {
  const path = new THREE.LineCurve(positionA, positionB)
  return new THREE.TubeBufferGeometry(path, 0, thickness)
}

// 2D
export function createFaceGeometry (vertices) {
  const geometry = new THREE.Geometry()
  const face = new THREE.Shape()
  face.moveTo(vertices[0].position.x, -vertices[0].position.z)

  for (let i = 1; i < vertices.length; i++) {
    face.lineTo(vertices[i].position.x, -vertices[i].position.z)
  }

  const faceGeometry = new THREE.ShapeGeometry(face)
  geometry.merge(faceGeometry, new THREE.Matrix4().makeRotationX(-Math.PI / 2))

  return new THREE.BufferGeometry().fromGeometry(geometry)
}

export function insertChildIntoGroup (group, child, index) {
  const children = group.children

  group.children = []

  children.splice(index, 0, child)

  children.forEach(child => group.add(child))

  return group
}

export function removeItemFromArray (array, item) {
  let index = array.findIndex(i => i === item)
  if (index >= 0)
    array.splice(index, 1)
}

export function reorderGroup (group) {

  let list = []

  for (let i = group.children.length - 1; i >= 0; i--) {
    let element = group.children[i]
    group.remove(element)
    list.push(element)
  }

  list.forEach(element => group.add(element))
}

export function removeAllChildren (group) {
  for (let i = group.children.length; i >= 0; i--) {
    const children = group.children[i]
    if (children && children['dispose']) {
      children.dispose()
    }
    group.remove(children)
  }
}

// 2D
export function getBoundingBoxPositions (positions) {

  let left = Number.MAX_VALUE
  let right = -Number.MAX_VALUE
  let bottom = Number.MAX_VALUE
  let top = -Number.MAX_VALUE

  positions.forEach(position => {
    if (position.x < left) {
      left = position.x
    }
    if (position.x > right) {
      right = position.x
    }
    if (position.z < bottom) {
      bottom = position.z
    }
    if (position.z > top) {
      top = position.z
    }
  })

  return [
    new THREE.Vector3(left, 0.05, top),
    new THREE.Vector3(right, 0.05, top),
    new THREE.Vector3(right, 0.05, bottom),
    new THREE.Vector3(left, 0.05, bottom),
  ]

}

export function compareJSONGeometries (jsonObject0, jsonObject1) {
  if (jsonObject0.uuid !== jsonObject1.uuid) {
    return false
  }

  if (jsonObject0.type !== jsonObject1.type) {
    return false
  }

  if (jsonObject0.isValidate !== jsonObject1.isValidate) {
    return false
  }

  if (jsonObject0.error.length !== jsonObject1.error.length) {
    return false
  }

  if (jsonObject0.radius !== jsonObject1.radius) {
    return false
  }

  if ((jsonObject0.center && jsonObject1.center) && (!compareVertices(jsonObject0.center, jsonObject1.center))) {
    return false
  }

  if (!compareVertices(jsonObject0.vertices, jsonObject1.vertices)) {
    return false
  }

  return !((jsonObject0.edges && jsonObject1.edges) && (!compareVertices(jsonObject0.edges, jsonObject1.edges)))
}

export function compareVertices (vertices0, vertices1) {
  if (vertices0.length !== vertices1.length)
    return false

  let result = true

  vertices0.forEach((vertex, index) => {
    if (vertex[0] !== vertices1[index][0] || vertex[1] !== vertices1[index][1])
      result = false
  })

  return result
}