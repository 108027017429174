import React from 'react'

const SvgDrawRectangleInactive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd" stroke="#ff5e0f" strokeLinejoin="round">
      <path d="M7.5 8a.5.5 0 00-.5.496v15.008a.5.5 0 00.5.496h17a.5.5 0 00.5-.496V8.496A.5.5 0 0024.5 8h-17z"/>
      <path
        fill="#FFF"
        d="M14.499 6.5C13.67 6.5 13 7.166 13 8h0c0 .828.665 1.5 1.499 1.5H17.5c.83 0 1.5-.666 1.5-1.5h0c0-.828-.665-1.5-1.499-1.5H14.5zm0 16C13.67 22.5 13 23.166 13 24h0c0 .828.665 1.5 1.499 1.5H17.5c.83 0 1.5-.666 1.5-1.5h0c0-.828-.665-1.5-1.499-1.5H14.5zM26.5 14.499C26.5 13.67 25.834 13 25 13h0c-.828 0-1.5.665-1.5 1.499V17.5c0 .828.666 1.499 1.5 1.499h0c.828 0 1.5-.665 1.5-1.499v-3zm-18 0C8.5 13.67 7.834 13 7 13h0c-.828 0-1.5.665-1.5 1.499V17.5c0 .83.666 1.5 1.5 1.5h0c.828 0 1.5-.665 1.5-1.499V14.5z"
      />
    </g>
  </svg>

export default SvgDrawRectangleInactive