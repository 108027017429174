import React from 'react'

const SvgPlusButton = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <defs>
      <style>
        {
          '.plus-button_svg__cls-2{fill:none;stroke-linecap:round;stroke-width:1.5px}'
        }
      </style>
    </defs>
    <g id="plus-button_svg__plus_button" transform="translate(-1111 -279)">
      <path
        id="plus-button_svg__Rechteck_710"
        d="M0 0h24v24H0z"
        data-name="Rechteck 710"
        transform="translate(1111 279)"
        fill="none"
      />
      <g
        id="plus-button_svg__Gruppe_1280"
        data-name="Gruppe 1280"
        transform="translate(.863 -1)"
      >
        <path
          id="plus-button_svg__Linie_8"
          d="M0 0v13.725"
          className="plus-button_svg__cls-2"
          stroke={props.color}
          data-name="Linie 8"
          transform="rotate(90 418.5 710.363)"
        />
        <path
          id="plus-button_svg__Linie_9"
          d="M0 0v13.725"
          className="plus-button_svg__cls-2"
          stroke={props.color}
          data-name="Linie 9"
          transform="translate(1122 285)"
        />
      </g>
    </g>
  </svg>
)

export default SvgPlusButton
