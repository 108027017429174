import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dialog, { DialogButtonGroup } from '../../../common/components/Dialog'
import { fetchDepartmentSummaryFile, fetchFloorDepartmentsSummaryFile } from '../../../floorplan/actions/actions'
import Button from '../../../common/components/Button'

class SummaryFileDownloadDialog extends Component {

  render () {

    return (

      <Dialog onCancel={this.close}
              title={'Export PDF file'}
              text={'You can export the active department only or the complete floor'}
              className={'summary-file-download-dialog'}>
        <DialogButtonGroup>
          <Button onClick={this.close}>Cancel</Button>
          <Button onClick={() => this.downloadSingle(this.props.buildingId, this.props.floorId, this.props.unitId)}
                  disabled={!this.props.unitId} variant={'contained'}>
            Active
          </Button>
          <Button onClick={() => this.downloadAll(this.props.buildingId, this.props.floorId)} variant={'contained'}>All</Button>
        </DialogButtonGroup>
      </Dialog>
    )
  }

  close = () => {
    this.props.onClose()
  }

  downloadSingle = (building, floor, unit) => {
    this.close()
    this.props.fetchDepartmentSummaryFile(building, floor, unit)
  }

  downloadAll = (building, floor) => {
    this.close()
    this.props.fetchFloorDepartmentsSummaryFile(building, floor)
  }
}

let mapStateToProps = (state) => {
  return {
    buildingId: state.appState.selectedBuildingId,
    floorId: state.appState.selectedFloorId,
    unitId: state.appState.selectedOrganisationalUnitId,
  }
}

let mapDispatchToProps = {
  fetchDepartmentSummaryFile: fetchDepartmentSummaryFile,
  fetchFloorDepartmentsSummaryFile: fetchFloorDepartmentsSummaryFile,
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryFileDownloadDialog)