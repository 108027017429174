import { httpClient } from '../../common/httpClient'
import { patternBuilderActionTypes } from '../constants'
import store from './../../store'
import { projectApi } from '../../common/utils'
import { getUndoInformation, undoActionTypes } from '../../common/redux/undo/undo-actions'
import { fetchFloorData, fetchPatternTypes } from '../../floorplan/actions/actions'

function projectsApi (projectId) {
  return `/api/projects/${projectId}`
}

//---------- Frontend actions ----------

export function updatePatternPlan (plan) {
  return {
    type: patternBuilderActionTypes.UPDATE_PATTERN_PLAN,
    plan: plan,
  }
}

export function updatePatternCount (patternCount) {
  if (patternCount.workplaces < 1) patternCount.workplaces = 1
  if (patternCount.workplaces > 10) patternCount.workplaces = 10
  return {
    type: patternBuilderActionTypes.UPDATE_PATTERN_COUNT,
    patternCount: patternCount,
  }
}

export function updatePatternArea (area, areaId, width, depth) {
  return {
    type: patternBuilderActionTypes.UPDATE_PATTERN_AREA,
    area: area,
    areaId: areaId,
    width: width,
    depth: depth,
  }
}

export function updatePatternAreaWPCount (areaType, wpCount) {
  return {
    type: patternBuilderActionTypes.UPDATE_PATTERN_WORKPLACE_COUNT,
    areaType: areaType,
    wpCount: wpCount,
  }
}

export function resetDirtyFlag () {
  return {
    type: patternBuilderActionTypes.RESET_DIRTY_FLAG,
  }
}

export function addNewPattern () {
  return {
    type: patternBuilderActionTypes.ADD_NEW_PATTERN,
  }
}

export function saveNewPattern (projectId, pattern) {
  return (dispatch) => {
    dispatch(createPattern(projectId, pattern))
      .then(({ value }) => {
        return `/patterns/${value.data.id}`;
      })
      .then(() => {
        dispatch(fetchPatternList())
        dispatch(fetchPatternTypes())
        dispatch(getUndoInformation(projectId))
      })
  }
}

export function discardNewPattern () {
  return {
    type: patternBuilderActionTypes.DISCARD_NEW_PATTERN,
  }
}

export function renamePattern (id, name) {
  return {
    type: patternBuilderActionTypes.RENAME_PATTERN,
    id: id,
    name: name,
  }
}

export function storePatternBuilderUndo () {
  return {
    type: undoActionTypes.STORE_PATTERN,
  }
}

export function undoPatternBuilder () {
  return {
    type: undoActionTypes.UNDO_PATTERN,
  }
}

export function redoPatternBuilder () {
  return {
    type: undoActionTypes.REDO_PATTERN,
  }
}

export function getUndoAvailable () {
  return {
    type: undoActionTypes.UNDO_PATTERN_AVAILABLE,
  }
}

export function discardUndo () {
  return {
    type: undoActionTypes.DISCARD_UNDO,
  }
}

//---------- Backend actions ----------

export function fetchPatternList () {
  return {
    type: patternBuilderActionTypes.FETCH_PATTERN_LIST,
    payload: httpClient.get(`${projectApi()}/patterns`),
  }
}

export function updatePattern (projectId, pattern) {
  return (dispatch) => {
    dispatch(updatePatternApiCall(projectId, pattern))
      .then(() => {
        dispatch(fetchPatternList(projectId))
        dispatch(fetchFloorData(null, null, true))
        dispatch(getUndoInformation(projectId))
      })
  }
}

export function deletePattern (projectId, patternId) {
  return (dispatch) => {
    dispatch(deletePatternApiCall(projectId, patternId))
      .then(() => {
        dispatch(fetchPatternList(projectId))
        dispatch(getUndoInformation(projectId))
      })
  }
}

function deletePatternApiCall (projectId, patternId) {
  return {
    type: patternBuilderActionTypes.DELETE_PATTERN,
    payload: httpClient.delete(`${projectsApi(projectId)}/patterns/${patternId}`),
  }
}

export function createPattern (projectId, pattern) {
  return {
    type: patternBuilderActionTypes.CREATE_PATTERN,
    payload: httpClient.post(`${projectsApi(projectId)}/patterns`, pattern),
  }
}

export function duplicatePattern (projectId, pattern) {

  pattern.uuid = null
  pattern.name = null
  pattern.displayName = pattern.displayName + ' (Copy)'

  return (dispatch) => {
    dispatch(createPattern(projectId, pattern))
      .then(() => {
        dispatch(fetchPatternList(projectId))
        dispatch(getUndoInformation(projectId))
      })
  }
}

export function fetchPattern (projectId, patternId) {
  return {
    type: patternBuilderActionTypes.FETCH_PATTERN,
    payload: httpClient.get(`${projectsApi(projectId)}/patterns/${patternId}`),
  }
}

function updatePatternApiCall (projectId, pattern) {
  let patternId = pattern.id

  return {
    type: patternBuilderActionTypes.UPDATE_PATTERN,
    payload: httpClient.patch(`${projectsApi(projectId)}/patterns/${patternId}`, pattern),
  }
}

// ------------------- Furniture Settings -------------------

export function changeFurniturePosition (offset, uuid, area) {
  return {
    type: patternBuilderActionTypes.CHANGE_PATTERN_FURNITURE_POSITION,
    offset: offset,
    uuid: uuid,
    area: area,
  }
}

export function changeFurnitureParent (parent, uuid, connectorSide, posOnConnectorSide, root, area) {
  return {
    type: patternBuilderActionTypes.CHANGE_PATTERN_FURNITURE_PARENT,
    parent: parent,
    uuid: uuid,
    connectorSide: connectorSide,
    posOnConnectorSide: posOnConnectorSide,
    root: root,
    area: area,
  }
}

export function addNewFurniture (parent, uuid, newId, connectorSide, posOnConnectorSide, root, constructor, area) {
  return {
    type: patternBuilderActionTypes.ADD_PATTERN_FURNITURE,
    parent: parent,
    uuid: uuid,
    connectorSide: connectorSide,
    newId: newId,
    posOnConnectorSide: posOnConnectorSide,
    root: root,
    constructor: constructor,
    area: area,
  }
}

export function deleteFurniture (id) {
  return {
    type: patternBuilderActionTypes.DELETE_FURNITURE,
    id: id,
  }
}

export function replacePatternData (pattern) {

  return {
    type: patternBuilderActionTypes.REPLACE_PATTERN_DATA,
    pattern: pattern,
  }
}

export function changeFurnitureRotation90 (uuid, areaType) {
  return {
    type: patternBuilderActionTypes.CHANGE_PATTERN_FURNITURE_ROTATION_90,
    areaType: areaType,
    uuid: uuid,
  }
}

export function changeFurnitureRotation (uuid, rotation) {
  return {
    type: patternBuilderActionTypes.ROTATE_FURNITURE,
    furnId: uuid,
    rotation: rotation,
  }
}

export function changeFurnitureAnchorSide (uuid, anchorSide) {
  return {
    type: patternBuilderActionTypes.FURNITURE_ANCHOR_SIDE,
    furnId: uuid,
    anchorSide: anchorSide,
  }
}
