import React, { Component } from 'react'
import DataFlyOut from './corporate/DataFlyOut'
import ApplicationProfile from './corporate/ApplicationProfile'
import ApplicationsList from './corporate/ApplicationsList'
import Tags from './corporate/Tags'
import LayoutStyle from './corporate/LayoutStyle'
import PatternStyle from './corporate/PatternStyle'
import DepartmentLensToolsFlyOut from './corporate/DepartmentLensToolsFlyOut'
import TeamLensToolsFlyOut from './corporate/TeamLensToolsFlyOut'
import AdjacencyList from './corporate/AdjacencyList'
import { connect } from 'react-redux'

import CollapsibleMenuSection from '../../../common/components/CollapsibleMenuSection'
import { getOrganisationalUnit } from '../../../common/redux/organisationunits/organisational-units-reducer'
import { iconTypes } from '../../../common/components/icons/IconFactory'

/**
 * @return {null}
 */
function CorporateFlyOutMenuItem ({ component, icon, title, visible = true }) {
  return visible ? <CollapsibleMenuSection icon={icon} title={title}
                                           lazyRender={true}
                                           Component={component}/> : null
}

class CorporateFlyOutMenu extends Component {

  render () {

    const useLensActive = this.props.useLens
    const advancedSettings = this.props.advancedSettings
    const isTeam = this.props.selectedOrganisationalUnit.hierarchicalLevel >= 2
    const isDepartment = this.props.selectedOrganisationalUnit.hierarchicalLevel >= 1 && this.props.selectedOrganisationalUnit.hierarchicalLevel < 2

    return (
      <div>
        <CorporateFlyOutMenuItem icon={iconTypes.data} title={'People'}
                                 component={DataFlyOut}/>
        <CorporateFlyOutMenuItem icon={iconTypes.applicationProfile} title={'Application Style'}
                                 visible={!useLensActive && isDepartment}
                                 component={ApplicationProfile}/>
        <CorporateFlyOutMenuItem icon={iconTypes.applications} title={'List of Applications'}
                                 visible={isDepartment}
                                 component={ApplicationsList}/>
        <CorporateFlyOutMenuItem icon={iconTypes.pattern} title={'Pattern Style'}
                                 visible={!useLensActive && isTeam}
                                 component={PatternStyle}/>
        <CorporateFlyOutMenuItem icon={iconTypes.layout} title={'Layout Style'}
                                 visible={!useLensActive && isDepartment}
                                 component={LayoutStyle}/>
        <CorporateFlyOutMenuItem icon={iconTypes.tags} title={'Tags'}
                                 visible={advancedSettings}
                                 component={Tags}/>
        <CorporateFlyOutMenuItem icon={iconTypes.lensTools} title={'LENS-Tools'}
                                 visible={useLensActive && isDepartment}
                                 component={DepartmentLensToolsFlyOut}/>
        <CorporateFlyOutMenuItem icon={iconTypes.lensTools} title={'LENS-Tools'}
                                 visible={useLensActive && isTeam}
                                 component={TeamLensToolsFlyOut}/>
        <CorporateFlyOutMenuItem icon={iconTypes.adjacency} title={'Adjacencies'}
                                 visible={isDepartment && advancedSettings}
                                 component={AdjacencyList}/>
      </div>
    )
  }

}

const mapStateToProps = (state) => {

  return {
    advancedSettings: state.settings.appSettings.advancedSettings,
    useLens: state.settings.appSettings.useLens,
    selectedOrganisationalUnit: getOrganisationalUnit(state),
  }
}

export default connect(mapStateToProps)(CorporateFlyOutMenu)