import * as THREE from 'three'
import { FN3DExtended } from './classes'

function FurniturePlacer () {

  this.baseLineColor = 0x555555
  this.posList = [-1, 0, 1]

  this.connectFurniture = function (furnitureSceneObject, placingTarget, furnitureList) {
    let hasParent = true
    let parentFurniture = null
    let parentMissing = false
    if (placingTarget.static) furnitureSceneObject.inheritedStatic = true

    if (furnitureSceneObject.source.connectorFurniture === null) hasParent = false
    if (hasParent) {
      parentFurniture = placingTarget.getObjectByName(furnitureSceneObject.source.connectorFurniture, true)

      if (!(parentFurniture instanceof FN3DExtended)) {
        parentMissing = true
      }
    }

    if (!parentMissing) {
      if (hasParent) {
        parentFurniture.add(furnitureSceneObject)
        furnitureList.push(furnitureSceneObject)
      } else {
        placingTarget.add(furnitureSceneObject)
        furnitureList.push(furnitureSceneObject)
      }
    }

    return !parentMissing

  }

  this._getSize = function (furnitureSceneObject) {
    let isChild = false
    if (furnitureSceneObject.parent instanceof FN3DExtended) isChild = true
    let width = 0
    let depth = 0
    let unshiftArea = new THREE.Vector3()

    // do this for Child Furniture
    if (isChild) {
      let parentBbox = furnitureSceneObject.parent.children[0].geometry.boundingBox
      width = (-parentBbox.min.x + parentBbox.max.x) / 2
      depth = (-parentBbox.min.z + parentBbox.max.z) / 2
    } else {

      if (furnitureSceneObject.parent.hasOffset) {
        width = furnitureSceneObject.parent.actualWidth / 2
        depth = furnitureSceneObject.parent.actualDepth / 2
        unshiftArea = new THREE.Vector3(furnitureSceneObject.parent.actualWidth / 2, 0, -furnitureSceneObject.parent.actualDepth / 2)
      } else {
        width = furnitureSceneObject.parent.actualWidth
        depth = furnitureSceneObject.parent.actualDepth
      }

    }

    furnitureSceneObject.width = width
    furnitureSceneObject.depth = depth
    furnitureSceneObject.unshiftArea = unshiftArea

  }

  this._getPositionAtConnector = function (furnitureSceneObject) {
    let parentWidth = furnitureSceneObject.width
    let parentDepth = furnitureSceneObject.depth
    let pos = furnitureSceneObject.posOnConnectorSideValue
    let posAtConnector = new THREE.Vector3()
    let posList = [-1, 0, 1]

    switch (this.limitValue(furnitureSceneObject.rotationAtParent)) {
      case 0:
      default:
        posAtConnector.x = parentWidth * posList[pos]
        posAtConnector.z = -parentDepth
        break
      case 1:
        posAtConnector.x = parentWidth
        posAtConnector.z = parentDepth * posList[pos]
        break
      case 2:
        posAtConnector.x = -parentWidth * posList[pos]
        posAtConnector.z = parentDepth
        break
      case 3:
        posAtConnector.x = -parentWidth
        posAtConnector.z = -parentDepth * posList[pos]
        break
    }
    if (furnitureSceneObject.connectorSide === 'CENTER') posAtConnector = new THREE.Vector3()
    return posAtConnector
  }

  this._getPosOnOwnConnectorBasic = function (furnitureSceneObject, bounds) {
    let posOwnConnector = new THREE.Vector3()
    let pos = furnitureSceneObject.posOnConnectorSideValue
    let posList = [-1, 0, 1]
    let valx = bounds[0]
    let valz = bounds[1]
    let neg = furnitureSceneObject.neg

    switch (this.limitValue(furnitureSceneObject.rotationAtParent + furnitureSceneObject.rotationInherited)) {
      case 0:
      default:

        posOwnConnector.x = -valx * posList[pos]
        posOwnConnector.z = -valz * neg

        break
      case 1:
        posOwnConnector.x = valx * neg
        posOwnConnector.z = -valz * posList[pos]

        break
      case 2:
        posOwnConnector.x = valx * posList[pos]
        posOwnConnector.z = valz * neg

        break
      case 3:
        posOwnConnector.x = -valx * neg
        posOwnConnector.z = valz * posList[pos]

        break
    }
    if (furnitureSceneObject.connectorSide === 'CENTER') {
      posOwnConnector = new THREE.Vector3()
    }

    return posOwnConnector
  }

  this._getPosOnOwnConnectorWholeBounds = function (furnitureSceneObject, bounds) {
    let posOwnConnector = new THREE.Vector3()
    let pos = furnitureSceneObject.posOnConnectorSideValue
    let posList = [-1, 0, 1]
    let neg = furnitureSceneObject.neg

    let valx = bounds[0]
    let valz = bounds[1]
    let plx = bounds[2]
    let plz = bounds[3]
    switch (this.limitValue(furnitureSceneObject.rotationAtParent + furnitureSceneObject.rotationInherited)) {
      case 0:
      default:

        posOwnConnector.x = plx[pos]
        posOwnConnector.z = -valz * neg

        break
      case 1:
        posOwnConnector.x = valx * neg
        posOwnConnector.z = -valz * posList[pos]

        break
      case 2:
        posOwnConnector.x = plx[pos]
        posOwnConnector.z = valz * neg

        break
      case 3:
        posOwnConnector.x = -valx * neg
        posOwnConnector.z = plz[pos]

        break
    }
    if (furnitureSceneObject.connectorSide === 'CENTER') {
      posOwnConnector = new THREE.Vector3()
    }
    return posOwnConnector
  }

  this._getPosOffset = function (furnitureSceneObject) {
    let posOffset = new THREE.Vector3()
    let neg = furnitureSceneObject.neg
    let offsetx = furnitureSceneObject.source.posOffset[0]
    let offsetz = furnitureSceneObject.source.posOffset[1]
    switch (this.limitValue(furnitureSceneObject.rotationAtParent + furnitureSceneObject.rotationInherited)) {
      case 0:
      default:

        posOffset.x = offsetx * neg
        posOffset.z = -offsetz * neg
        break
      case 1:

        posOffset.x = offsetz * neg
        posOffset.z = offsetx * neg
        break
      case 2:

        posOffset.x = -offsetx * neg
        posOffset.z = offsetz * neg
        break
      case 3:

        posOffset.x = -offsetz * neg
        posOffset.z = -offsetx * neg
        break
    }
    return posOffset
  }

  this._getFurnitureRotation = function (furnitureSceneObject) {
    let add = furnitureSceneObject.addToSidecode
    let furnitureRotation
    switch (this.limitValue(furnitureSceneObject.rotationAtParent - furnitureSceneObject.rotationSelf + add)) {
      case 0:
        furnitureRotation = Math.PI
        break
      case 1:
        furnitureRotation = Math.PI / 2
        break
      case 2:
      default:
        furnitureRotation = 0
        break
      case 3:
        furnitureRotation = -Math.PI / 2
        break
    }
    return furnitureRotation
  }

  this._getBoundsBasic = function (furnitureSceneObject) {
    let values = []
    let bbox = furnitureSceneObject.bboxS
    let rval = this.limitValue(-furnitureSceneObject.rotationAtParent + furnitureSceneObject.rotationSelf + furnitureSceneObject.rotationInherited)
    let valx, valz
    switch (rval) {
      case 0:
      case 2:
      default:

        valx = bbox.max.x
        valz = bbox.max.z

        break
      case 1:
      case 3:
        valx = bbox.max.z
        valz = bbox.max.x
        break
    }
    values.push(valx, valz, null, null)
    return values
  }

  this._getWholeBounds = function (furnitureSceneObject) {
    let values = []
    let rval = this.limitValue(-furnitureSceneObject.rotationAtParent + furnitureSceneObject.rotationSelf + furnitureSceneObject.rotationInherited)
    let valx, valz, plx, plz
    let bboxM = furnitureSceneObject.bboxM
    let posListX = furnitureSceneObject.posListX
    let posListZ = furnitureSceneObject.posListZ
    switch (rval) {
      case 0:
        valx = bboxM.max.x
        valz = bboxM.max.z
        plx = posListX
        plz = posListZ
        break
      case 2:
      default:

        valx = bboxM.max.x
        valz = -bboxM.min.z
        plx = posListX
        plz = posListZ

        break
      case 1:
        valx = -bboxM.min.z
        valz = -bboxM.min.x
        plx = posListZ
        plz = posListX
        break
      case 3:
        valx = bboxM.max.z
        valz = -bboxM.max.x
        plx = posListZ
        plz = posListX
        break
    }
    values.push(valx, valz, plx, plz)
  }

  this._doRotation = function (furnitureSceneObject) {
    let furnitureRotation = this._getFurnitureRotation(furnitureSceneObject)

    furnitureSceneObject.furnitureRotationDeg = furnitureSceneObject.source.rotationRad * (Math.PI / 180)
    furnitureSceneObject.rotateY(furnitureSceneObject.furnitureRotationDeg)
    furnitureSceneObject.prepareSelectorPlane()
    furnitureSceneObject.updateSelectorPlane()
    furnitureSceneObject.furnRotation = furnitureRotation
    furnitureSceneObject.rotateY(furnitureSceneObject.furnRotation)
  }

  this._setPosition = function (furnitureSceneObject, wholeBounds) {
    this._getSize(furnitureSceneObject)
    let posOwnConnector
    let posAtConnector = this._getPositionAtConnector(furnitureSceneObject)
    let unshiftArea = furnitureSceneObject.unshiftArea
    let bounds
    if (wholeBounds) {
      bounds = this._getWholeBounds(furnitureSceneObject)
      posOwnConnector = this._getPosOnOwnConnectorWholeBounds(furnitureSceneObject, bounds)
    } else {
      bounds = this._getBoundsBasic(furnitureSceneObject)
      posOwnConnector = this._getPosOnOwnConnectorBasic(furnitureSceneObject, bounds)
    }
    let posOffset = this._getPosOffset(furnitureSceneObject)
    let resultVector = new THREE.Vector3()
    resultVector.add(unshiftArea)
    resultVector.add(posAtConnector)
    resultVector.add(posOwnConnector)
    resultVector.add(posOffset)

    furnitureSceneObject.position.set(resultVector.x, resultVector.y, resultVector.z)
    furnitureSceneObject.moveSelectorPlane()
    furnitureSceneObject.rotateSelectorPlane(furnitureSceneObject.furnRotation)
  }

  this.placeFurniture = function (furnitureSceneObject, wholeBounds) {

    furnitureSceneObject.setupBoundingBoxWithoutChildren()
    furnitureSceneObject.setupBoundingBoxWithChildren()

    furnitureSceneObject.checkAdditions()

    this._getSize(furnitureSceneObject)

    this._doRotation(furnitureSceneObject)

    furnitureSceneObject.children[0].children[1].material = new THREE.LineBasicMaterial({ color: this.baseLineColor })
    furnitureSceneObject.children[0].children[0].material = new THREE.LineBasicMaterial({ color: this.baseLineColor })

  }

  this.createSelector = function (furnitureSceneObject, selectors) {
    if (!furnitureSceneObject.inheritedStatic) {

      furnitureSceneObject.addSelectorPlane(furnitureSceneObject.parent, selectors)

    }
  }

  this.addAnchors = function (furnitureSceneObject, anchors, anchorLines) {
    if (!furnitureSceneObject.inheritedStatic) {

      let ancs = furnitureSceneObject.selectorPlane.getAnchors()
      for (let i = 0; i < ancs.length; i++) {
        anchors.push(ancs[i])
      }
      anchorLines.push(furnitureSceneObject.selectorPlane.topLine)
      anchorLines.push(furnitureSceneObject.selectorPlane.bottomLine)
      anchorLines.push(furnitureSceneObject.selectorPlane.rightLine)
      anchorLines.push(furnitureSceneObject.selectorPlane.leftLine)

    }
  }

  this.limitValue = function (value) {

    while (value > 3) {
      value -= 4
    }
    while (value < 0) {
      value += 4
    }
    return value

  }

  this.setOpacityOfFurniture = function (furnObject) {
    if (furnObject instanceof THREE.Mesh || furnObject instanceof THREE.LineSegments) {
      furnObject.material.opacity = furnObject.material.opacity < 0.4 ? furnObject.material.opacity : 0.4
      furnObject.material.transparent = true
      if (furnObject instanceof THREE.LineSegments)
        furnObject.position.y = 0.08
      else
        furnObject.position.y = 0.06
    }

    furnObject.children.forEach((child) => {
      this.setOpacityOfFurniture(child)
    })
  }
}

export { FurniturePlacer }