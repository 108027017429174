import * as React from 'react'

const SvgCleaning = props =>
  (
    <svg viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <rect width={24} height={24} fill="#00293B" opacity={0} rx={2}/>
        <g stroke="#00293B" strokeLinecap="round" strokeLinejoin="round">
          <path
            d="M12.5 10.134L16 4.072M4.17 14.562c1.953-.787 3.683-2.079 5.19-3.875a2.479 2.479 0 012.989-.633l.15.08a2.48 2.48 0 011.091 2.995c-.8 2.204-1.055 4.348-.76 6.433-.799.116-2.367-.5-4.706-1.85s-3.656-2.399-3.954-3.15zm6.853 1.648a13.904 13.904 0 00-.285 2.457m-2.761-4.216a13.904 13.904 0 01-1.986 1.475m3.227-4.881l4.112 2.374m-5.216 4.288l1.816-3.145"/>
        </g>
        <circle cx={19.75} cy={19.75} r={1} fill="#00293B"/>
        <circle cx={17.25} cy={17.25} r={1} fill="#00293B"/>
        <circle cx={15.75} cy={19.75} r={1} fill="#00293B"/>
      </g>
    </svg>
  )

export default SvgCleaning
