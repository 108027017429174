import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { Scrollbar } from 'react-scrollbars-custom'

import {
  addTagFurnitureEnsemblePlan,
  changeFurnitureEnsembleCategory,
  changeFurnitureEnsembleSize,
  getUndoAvailable,
  lockRoomSize,
  removeTagFurnitureEnsemblePlan,
  storeApplicationBuilderUndo,
} from '../../../actions/frontendActions'
import { createTag, deleteTag } from '../../../actions/backendActions'

import AutoSave from '../../../../common/components/AutoSave'
import NumberInput from '../../../../common/components/NumberInput'
import InputGroup from '../../../../common/components/InputGroup'
import MenuSection from '../../../../common/components/MenuSection'
import SelectButton from '../../../../common/components/SelectButton'
import Button from '../../../../common/components/Button'
import Chip from '../../../../common/components/Chip'
import ModalConsumer from '../../../../common/components/modal/ModalConsumer'
import DeletionDialog from '../../../../common/components/DeletionDialog'
import IconButton from '../../../../common/components/IconButton'

import './SettingsApplication.scss'
import { iconTypes } from '../../../../common/components/icons/IconFactory'
import { withTranslation } from 'react-i18next'

class SettingsApplication extends Component {

  constructor (props) {
    super(props)

    this.state = {
      newTag: '',
    }
  }

  render () {
    let options = this.props.furnitureEnsembleCategories.map(cat => ({
      label: cat.displayName ? cat.displayName : cat.displayName,
      value: cat.name,
    }))

    const roomSizeLocked = this.props.roomSizeLocked
    const actionButton = <IconButton icon={roomSizeLocked ? iconTypes.locked : iconTypes.unlocked} onClick={() => this.props.lockRoomSize(!roomSizeLocked)}/>
    const {t} = this.props

    return (
      <div className={'settings-application'}>
        <Scrollbar autoHide>
          <MenuSection title={t('type_of_workspace')} icon={'info'}>
            <Formik initialValues={{ ...this.props.furnitureEnsemblePlan }} enableReinitialize>
              <Form>
                <AutoSave onSave={(values) => this.void()}/>
                <SelectButton
                  value={this.props.furnitureEnsemblePlan ? this.props.furnitureEnsemblePlan.categoryId : ''}
                  name={'applicationProfile.id'}
                  options={options}
                  onChange={(event) => this.switchCategory(event)}
                  forceUpdate={true}/>
              </Form>
            </Formik>

          </MenuSection>
          <MenuSection title={t('application_size')} icon={'size'} actionButton={actionButton}>
            <Formik initialValues={{ ...this.props.furnitureEnsemblePlan }} enableReinitialize>
              <Form>
                <AutoSave onSave={(values) => this.handleUpdateFurnitureEnsemblePlanSizes(values)}/>
                <MenuSection>
                  <Field label={t('min_width')}
                         name={'minWidth'}
                         component={NumberInput}
                         disabled={roomSizeLocked}
                         step={0.1}
                  />
                  <Field label={t('min_depth')}
                         name={'minDepth'}
                         component={NumberInput}
                         disabled={roomSizeLocked}
                         step={0.1}
                  />
                  <Field label={t('max_width')}
                         name={'maxWidth'}
                         component={NumberInput}
                         disabled={roomSizeLocked}
                         step={0.1}
                  />
                  <Field label={t('max_depth')}
                         name={'maxDepth'}
                         component={NumberInput}
                         disabled={roomSizeLocked}
                         step={0.1}
                  />
                </MenuSection>
              </Form>
            </Formik>
          </MenuSection>
          <MenuSection title={t('tags')} icon={'tags'}>
            <InputGroup>
              <input type={'text'} placeholder={t('add_tag')} onChange={(event) => this.setState({ newTag: event.target.value })} value={this.state.newTag}/>
              <Button className={'settings-application-add-button'} variant={'contained'} onClick={() => this.addNewTag(this.state.newTag)}>{('add')}</Button>
            </InputGroup>
            <Formik onSubmit={() => {}} initialValues={{ tags: this.props.furnitureEnsemblePlan.tags.map(tag => tag.id) }} enableReinitialize>
              <Form>
                <ModalConsumer>
                  {({ showModal }) => (
                    <div className={'tag-form-container'}>
                      {this.props.tags.map((tag, index) => (
                        <Field key={index}
                               name={'tags'}>
                          {({ field, form }) => (
                            <Chip
                              className={'settings-application-chip'}
                              label={tag.displayName}
                              value={field.value.includes(tag.id)}
                              name={field.name}
                              onChange={() => {
                                if (field.value.includes(tag.id)) {
                                  const nextValue = field.value.filter(
                                    value => value !== tag.id,
                                  )
                                  form.setFieldValue('tags', nextValue)
                                  this.props.removeTagFurnitureEnsemblePlan(tag)
                                } else {
                                  const nextValue = field.value.concat(tag.id)
                                  form.setFieldValue('tags', nextValue)
                                  this.props.addTagFurnitureEnsemblePlan(tag)

                                }
                              }}
                              onDelete={() => showModal(DeletionDialog, {
                                title: t('delete_tag'),
                                onConfirm: () => this.deleteTag(tag.id),
                                text: t('sure_to_delete_tag') + ` '${tag.displayName}'` + t('sure_to_delete_tag_part_2'),
                              })}
                            />
                          )}
                        </Field>
                      ))}
                    </div>
                  )}
                </ModalConsumer>
              </Form>
            </Formik>
          </MenuSection>
        </Scrollbar>
      </div>
    )
  }

  addNewTag (tag) {

    if (tag && tag !== '') {
      this.props.storeApplicationBuilderUndo()
      this.props.getUndoAvailable()
      this.props.createTag(tag)
      this.setState({ newTag: '' })
    }
  }

  deleteTag (tagId) {
    this.props.storeApplicationBuilderUndo()
    this.props.getUndoAvailable()
    this.props.deleteTag(tagId)
  }

  switchCategory (event) {
    this.props.storeApplicationBuilderUndo()
    this.props.getUndoAvailable()
    this.props.changeFurnitureEnsembleCategory(event.target.value)
  }

  handleUpdateFurnitureEnsemblePlanSizes (values) {
    this.props.storeApplicationBuilderUndo()
    this.props.getUndoAvailable()
    this.props.changeFurnitureEnsembleSize(values)

  }
}

let mapStateToProps = (state) => {
  return {
    furnitureEnsemblePlan: state.furnitureEnsembles.furnitureEnsemblePlan,
    furnitureEnsembleCategories: state.furnitureEnsembles.furnitureEnsembleCategories,
    tags: state.tags.tags,
    roomSizeLocked: state.applicationBuilderUiState.roomSizeLocked,
  }
}

let mapDispatchToProps = {
  createTag: createTag,
  deleteTag: deleteTag,
  addTagFurnitureEnsemblePlan: addTagFurnitureEnsemblePlan,
  removeTagFurnitureEnsemblePlan: removeTagFurnitureEnsemblePlan,
  changeFurnitureEnsembleCategory: changeFurnitureEnsembleCategory,
  changeFurnitureEnsembleSize: changeFurnitureEnsembleSize,
  lockRoomSize: lockRoomSize,
  storeApplicationBuilderUndo: storeApplicationBuilderUndo,
  getUndoAvailable: getUndoAvailable,
}

let SettingsApplicationContainer = connect(mapStateToProps, mapDispatchToProps)(SettingsApplication)

export default withTranslation() (SettingsApplicationContainer)