import React from 'react'
import { Droppable } from 'react-beautiful-dnd'

import Block from './Block'

import './Floor.scss'

export function Floor ({ floor, blocks, maxArea }) {

  const proportionalWidth = floor.area * 100 / maxArea
  const blockHTML = renderBlocks(blocks, maxArea)
  const available = Math.round(floor.area)
  const used = calculateUsage(blocks)
  const dimension = 'm²'

  let remainingClass = 'remain '
  let floorNameClass = ''
  if (available - used < 0) {
    remainingClass += 'warning'
    floorNameClass += 'warning'
  }

  return (
    <div className={'floor-container'}>
      <h3 className={floorNameClass} title={available - used < 0 ? 'Remove some departments' : ''}>{floor.name}</h3>
      <div className={'floor-usage'}>
        <div className={'planned-cleaning'}>
          <span className={'planned'}>Planned: {used} {dimension}</span>
        </div>
        <span className={remainingClass} title={available - used < 0 ? 'Remove some departments' : ''}>Remaining: {available - used} / {available} {dimension}</span>
      </div>
      <div className={'floor'} style={{ width: 100 + '%' }}>
        <div id={'fb_' + floor.id} className="rowback" data-id={floor.id} style={{ width: proportionalWidth + '%' }}/>
        <Droppable droppableId={floor.id} direction="horizontal">
          {(provided) => (
            <div className={'blocks-row'} data-floorid={floor.id} data-buildingid={floor.buildingId}
                 ref={provided.innerRef}
                 {...provided.droppableProps} >
              {blockHTML}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  )
}

function calculateUsage (blocks) {
  let netSize = 0
  for (let i = 0; i < blocks.length; i++) {
    netSize += blocks[i].area
  }
  return Math.round(netSize)
}

function renderBlocks (blocks, maxArea) {
  return blocks.map((department, index) => {
    let proportionalWidth = department.area * 100 / maxArea

    return (
      <Block key={department.id} department={department} proportionalWidth={proportionalWidth} index={index}/>
    )
  })
}

export default Floor