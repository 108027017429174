export function start(renderer, scene, camera) {

    const frameId = {
        frameId: null
    }

    frameId.frameId = requestAnimationFrame(() => animate(renderer, scene, camera, frameId))

    return frameId
}

export function stop({frameId}) {
    return cancelAnimationFrame(frameId)
}

const renderLoopCallBacks = {
  afterUpdate: []
}

export function addAfterUpdate(afterUpdate = () => {}) {
  renderLoopCallBacks.afterUpdate.push(afterUpdate)
}

function animate(renderer, scene, camera, frameId) {
    renderer.clear('#ddd')
    renderer.render(scene, camera)

    renderLoopCallBacks.afterUpdate.forEach(callback => callback())

    frameId.frameId = requestAnimationFrame(() => {
      animate(renderer, scene, camera, frameId)

    })
}
