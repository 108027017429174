import * as React from 'react'

const SvgBlockedAreaActive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="9" fill="url(#paint0_linear_12_4324)" stroke="#DA5B2B"/>
    <path d="M9.63634 22.3637L22.3643 9.63574" stroke="#DA5B2B"/>
    <defs>
      <linearGradient id="paint0_linear_12_4324" x1="24.9997" y1="25.0018" x2="24.9997" y2="7.0018" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
    </defs>
  </svg>
)

export default SvgBlockedAreaActive