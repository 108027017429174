import { patternBuilderActionTypes } from '../constants'
import { cloneDeep } from 'lodash'
import { undoActionTypes } from '../../common/redux/undo/undo-actions'

const initState = {
  patterns: [],
  patternCount: {
    workplaces: 3,
  },
  patternPlan: {
    'id': null,
    'name': null,
    'displayName': 'init',
    'mirrorX': true,
    'mirrorY': false,
    'main': {
      'unitType': 'MAIN',
      'active': true,
      'mirrored': true,
      'numberWorkplaces': 1,
      'furnitureList': [],
    },
    'fill': {
      'unitType': 'FILL',
      'active': false,
            'mirrored': true,
            'numberWorkplaces': 0,
            'furnitureList': [],
        },
        'workBenchFront': {
            'unitType': 'WBFRONT',
            'active': true,
            'mirrored': true,
            'numberWorkplaces': 0,
            'furnitureList': [],
        },
        'workBenchBack': {
            'unitType': 'WBBACK',
            'active': false,
            'mirrored': true,
            'numberWorkplaces': 0,
            'furnitureList': [],
        },
        'corridor': {
            'unitType': 'CORRIDOR',
            'active': false,
            'mirrored': true,
            'numberWorkplaces': 0,
            'furnitureList': [],
        },
        'facade': {
            'unitType': 'FACADE',
            'active': false,
            'mirrored': true,
            'numberWorkplaces': 0,
            'furnitureList': [],
        },
        'horizontalSeparator': {
            'unitType': 'HSEPARATOR',
            'active': true,
            'mirrored': true,
            'numberWorkplaces': 0,
            'furnitureList': [],
        },
        'verticalFaceSeparator': {
            'unitType': 'VSEPARATOR',
            'active': true,
            'mirrored': true,
            'numberWorkplaces': 0,
            'furnitureList': [],
        },
        'verticalFaceSeparatorFill': {
            'unitType': 'VSEPARATORFILL',
            'active': true,
            'mirrored': true,
            'numberWorkplaces': 0,
            'furnitureList': [],
        },
    'verticalBackSeparator': {
      'unitType': 'VENDSEPARATOR',
      'active': true,
      'mirrored': true,
      'numberWorkplaces': 0,
      'furnitureList': [],
    },
    'verticalBackSeparatorFill': {
      'unitType': 'VENDSEPARATORFILL',
      'active': true,
      'mirrored': true,
      'numberWorkplaces': 0,
      'furnitureList': [],
    },
    'crossSeparator': {
      'unitType': 'HSEPARATORCROSS',
      'active': true,
      'mirrored': true,
      'numberWorkplaces': 0,
      'furnitureList': [],
    },
  },
  dirtyFlag: false,
  patternUndoStore: [],
  patternUndoPosition: 0,
  undoAvailable: false,
  redoAvailable: false,
  undoRandom: 0,
}


const newPattern = {
  id: null,
  name: null,
  displayName: 'New Pattern',
  mirrorX: true,
  mirrorY: true,
  main: {
    unitType: 'MAIN',
    active: true,
    mirrored: true,
    width: 1.4,
    depth: 1.4,
    defaultWidth: 1.4,
    defaultDepth: 1.4,
    fixedWidth: false,
    fixedDepth: false,
    numberWorkplaces: 0,
    hasVerticalWall: false,
    hasHorizontalWall: false,
    furnitureList: [],
  },
  fill: {
    unitType: 'FILL',
    active: false,
    mirrored: true,
    width: 1.4,
    depth: 1.4,
    defaultWidth: 1.4,
    defaultDepth: 1.4,
    fixedWidth: false,
    fixedDepth: false,
    numberWorkplaces: 0,
    hasVerticalWall: false,
    hasHorizontalWall: false,
    furnitureList: [],
  },
  workBenchFront: {
    unitType: 'WBFRONT',
    active: false,
    mirrored: true,
    width: 1.4,
    depth: 0.8,
    defaultWidth: 1.4,
    defaultDepth: 0.8,
    fixedWidth: false,
    fixedDepth: false,
    numberWorkplaces: 0,
    hasVerticalWall: false,
    hasHorizontalWall: false,
    furnitureList: [],
  },
  workBenchBack: {
    unitType: 'WBBACK',
    active: false,
    mirrored: true,
    width: 1.4,
    depth: 0.8,
    defaultWidth: 1.4,
    defaultDepth: 0.8,
    fixedWidth: false,
    fixedDepth: false,
    numberWorkplaces: 0,
    hasVerticalWall: false,
    hasHorizontalWall: false,
    furnitureList: [],
  },
  corridor: {
    unitType: 'CORRIDOR',
    active: false,
    mirrored: true,
    width: 1.4,
    depth: 0.8,
    defaultWidth: 1.4,
    defaultDepth: 0.8,
    fixedWidth: false,
    fixedDepth: false,
    numberWorkplaces: 0,
    hasVerticalWall: false,
    hasHorizontalWall: false,
    furnitureList: [],
  },
  facade: {
    unitType: 'FACADE',
    active: false,
    mirrored: true,
    width: 1.4,
    depth: 0.8,
    defaultWidth: 1.4,
    defaultDepth: 0.8,
    fixedWidth: false,
    fixedDepth: false,
    numberWorkplaces: 0,
    hasVerticalWall: false,
    hasHorizontalWall: false,
    furnitureList: [],
  },
  horizontalSeparator: {
    unitType: 'HSEPARATOR',
    active: false,
    mirrored: true,
    width: 1.4,
    depth: 0.3,
    defaultWidth: 1.4,
    defaultDepth: 0.3,
    fixedWidth: false,
    fixedDepth: false,
    numberWorkplaces: 0,
    hasVerticalWall: false,
    hasHorizontalWall: false,
    furnitureList: [],
  },
  verticalFaceSeparator: {
    unitType: 'VSEPARATOR',
    active: false,
    mirrored: true,
    width: 0.3,
    depth: 1.4,
    defaultWidth: 0.3,
    defaultDepth: 1.4,
    fixedWidth: false,
    fixedDepth: false,
    numberWorkplaces: 0,
    hasVerticalWall: false,
    hasHorizontalWall: false,
    furnitureList: [],
  },
  verticalFaceSeparatorFill: {
    unitType: 'VSEPARATORFILL',
    active: false,
    mirrored: true,
    width: 0.3,
    depth: 1.4,
    defaultWidth: 0.3,
    defaultDepth: 1.4,
    fixedWidth: false,
    fixedDepth: false,
    numberWorkplaces: 0,
    hasVerticalWall: false,
    hasHorizontalWall: false,
    furnitureList: [],
  },
  verticalBackSeparator: {
    unitType: 'VENDSEPARATOR',
    active: false,
    mirrored: true,
    width: 0.3,
    depth: 1.4,
    defaultWidth: 0.3,
    defaultDepth: 1.4,
    fixedWidth: false,
    fixedDepth: false,
    numberWorkplaces: 0,
    hasVerticalWall: false,
    hasHorizontalWall: false,
    furnitureList: [],
  },
  verticalBackSeparatorFill: {
    unitType: 'VENDSEPARATORFILL',
    active: false,
    mirrored: true,
    width: 0.3,
    depth: 1.4,
    defaultWidth: 0.3,
    defaultDepth: 1.4,
    fixedWidth: false,
    fixedDepth: false,
    numberWorkplaces: 0,
    hasVerticalWall: false,
    hasHorizontalWall: false,
    furnitureList: [],
  },
  crossSeparator: {
    unitType: 'HSEPARATORCROSS',
    active: true,
    mirrored: true,
    width: 0.3,
    depth: 0.3,
    defaultWidth: 0.3,
    defaultDepth: 0.3,
    fixedWidth: false,
    fixedDepth: false,
    numberWorkplaces: 0,
    hasVerticalWall: false,
    hasHorizontalWall: false,
    furnitureList: [],
  },
}

function patternReducer (state = initState, action) {

  let pplan = null
  let patterns = null
  let scenes = []

  switch (action.type) {

    case undoActionTypes.DISCARD_UNDO:

      return {
        ...state,
        patternUndoStore: [],
        patternUndoPosition: 0,
        undoAvailable: false,
        redoAvailable: false,
      }

    case undoActionTypes.STORE_PATTERN:
      let store = [...state.patternUndoStore]

      if (state.patternUndoPosition < state.patternUndoStore.length) {
        for (let i = state.patternUndoStore.length; i > state.patternUndoPosition; i--) {
          store.pop(i - 1)
        }
      }

      store.push(JSON.stringify(state.patternPlan))
      let pos = state.patternUndoPosition
      pos++

      if (store.length > 25) {
        pos--
        store.shift()
      }

      return {
        ...state,
        patternUndoStore: store,
        patternUndoPosition: pos,
        undoAvailable: true,
        redoAvailable: false,
      }

    case undoActionTypes.UNDO_PROJECT_FULFILLED:

      return {
        ...state,
        undoRandom: Math.round(Math.random() * 9999999 + 10000000),
        patternUndoPosition: 0,
        patternUndoStore: [],
        undoAvailable: false,
        redoAvailable: false,
      }
    case undoActionTypes.UNDO_PATTERN:

      let store2 = [...state.patternUndoStore]
      if (state.patternUndoStore.length === state.patternUndoPosition) {
        store2.push(JSON.stringify(state.patternPlan))
      }

      let x = JSON.parse(state.patternUndoStore[state.patternUndoPosition - 1])

      let _undo = false

      if (state.patternUndoStore.length > 0 && state.patternUndoPosition - 1 > 0) {
        _undo = true
      }

      return {
        ...state,
        patternPlan: x,
        undoRandom: Math.round(Math.random() * 9999999 + 10000000),
        patternUndoPosition: state.patternUndoPosition - 1,
        patternUndoStore: store2,
        undoAvailable: _undo,
        redoAvailable: true,
      }

    case undoActionTypes.REDO_PATTERN:
      let x2 = JSON.parse(state.patternUndoStore[state.patternUndoPosition + 1])

      let __redo = false

      if (state.patternUndoStore.length > 0 && state.patternUndoPosition < state.patternUndoStore.length - 2) {
        __redo = true
      }

      return {
        ...state,
        patternPlan: x2,
        undoRandom: Math.round(Math.random() * 9999999 + 10000000),
        patternUndoPosition: state.patternUndoPosition + 1,
        undoAvailable: true,
        redoAvailable: __redo,
      }

    case undoActionTypes.UNDO_PATTERN_AVAILABLE:

      let undo = false
      let redo = false

      if (state.patternUndoStore.length > 0 && state.patternUndoPosition > 0) {
        undo = true
      }

      if (state.patternUndoStore.length > 0 && state.patternUndoPosition < state.patternUndoStore.length - 1) {
        redo = true
      }

      return {
        ...state,
        undoAvailable: undo,
        redoAvailable: redo,
      }

    case patternBuilderActionTypes.FETCH_PATTERN_LIST_FULFILLED:
      return { ...state, patterns: action.payload.data }

    case patternBuilderActionTypes.FETCH_PATTERN_FULFILLED:
      return {
        ...state,
        patternPlan: action.payload.data,
        dirtyFlag: false,
      }

    case patternBuilderActionTypes.UPDATE_PATTERN_PLAN:
      return { ...state, patternPlan: action.plan, dirtyFlag: true }

    case patternBuilderActionTypes.UPDATE_PATTERN_COUNT:
      return { ...state, patternCount: action.patternCount, dirtyFlag: true }

    case patternBuilderActionTypes.UPDATE_PATTERN_AREA:
      pplan = { ...state.patternPlan }
      pplan[action.areaId] = action.area
      // Keep actual Sizes when switch from Atuo to Fixed
      if (!state.patternPlan[action.areaId].fixedWidth && action.area.fixedWidth) action.area.defaultWidth = action.width
      if (!state.patternPlan[action.areaId].fixedDepth && action.area.fixedDepth) action.area.defaultDepth = action.depth
      return { ...state, patternPlan: pplan, dirtyFlag: true }

    case patternBuilderActionTypes.UPDATE_PATTERN_FULFILLED:

      return {
        ...state,
        patternPlan: action.payload.data,
        dirtyFlag: false,
      }

    case patternBuilderActionTypes.REPLACE_PATTERN_DATA:
      pplan = { ...action.pattern }

      pplan.random = Math.floor(Math.random() * 10000)
      return { ...state, patternPlan: pplan }

    case patternBuilderActionTypes.CHANGE_PATTERN_FURNITURE_POSITION:

      pplan = { ...state.patternPlan }

      let furnitureList = pplan[action.area].furnitureList

      for (let p = 0; p < furnitureList.length; p++) {
        if (furnitureList[p].uuid === action.uuid) {

          furnitureList[p].posOffset[0] = action.offset.posX + furnitureList[p].posOffset[0]
          furnitureList[p].posOffset[1] += action.offset.posZ
          break
        }
      }

      pplan.furnitureList = furnitureList
      pplan.random = Math.floor(Math.random() * 10000)

      return { ...state, patternPlan: pplan, dirtyFlag: true }

    case patternBuilderActionTypes.ADD_PATTERN_FURNITURE:
      pplan = { ...state.patternPlan }
      let list = pplan[action.area].furnitureList
      let newElement = {
        'type': action.uuid,
        'id': null,
        'displayName': action.constructor.name,
        'description': action.constructor.displayName,
        'categoryName': action.constructor.category,
        'uuid': action.newId,
        'rotationRad': 0.0,
        'mySide': 'BOTTOM',
        'posOnMySide': 'MID',
        'posOffset': [
          0,
          0,
        ],
        'connectorFurniture': null,
        'connectorSide': action.connectorSide,
        'posOnConnectorSide': action.posOnConnectorSide,
        'connectorObjectIndex': 0,
        'localBBox': [],
        'tempPolygon': [],
        'ofmlType': null,
      }
      if (action.root === true) {
        newElement.mySide = 'TOP'
        newElement.connectorFurniture = null

      }
      if (action.root !== true) {
        newElement.connectorFurniture = action.parent
      }
      if (action.posOnConnectorSide === 'START') newElement.posOnMySide = 'START'
      else if (action.posOnConnectorSide === 'END') newElement.posOnMySide = 'END'
      else newElement.posOnMySide = 'MID'

      list.push(newElement)
      pplan[action.area].furnitureList = list
      pplan.random = Math.floor(Math.random() * 10000)

      return { ...state, patternPlan: pplan, dirtyFlag: true }

    case patternBuilderActionTypes.CHANGE_PATTERN_FURNITURE_ROTATION_90:
      pplan = { ...state.patternPlan }

      let copyList = pplan[action.areaType].furnitureList
      for (let i = 0; i < copyList.length; i++) {
        if (copyList[i].uuid === action.uuid) {
          switch (copyList[i].mySide) {
            case 'RIGHT':
              copyList[i].mySide = 'TOP'
              break
            case 'TOP':
              copyList[i].mySide = 'LEFT'
              break
            case 'LEFT':
              copyList[i].mySide = 'BOTTOM'
              break
            case 'BOTTOM':
            default:
              copyList[i].mySide = 'RIGHT'
              break
          }
        }
      }

      return { ...state, patternPlan: pplan, dirtyFlag: true }

    case patternBuilderActionTypes.UPDATE_PATTERN_WORKPLACE_COUNT:
      pplan = { ...state.patternPlan }

      pplan[action.areaType].numberWorkplaces = action.wpCount

      return { ...state, patternPlan: pplan }

    case patternBuilderActionTypes.CHANGE_PATTERN_FURNITURE_PARENT:
      let plan = { ...state.patternPlan }

      scenes = [
        plan.main,
        plan.fill,
        plan.workBenchFront,
        plan.workBenchBack,
        plan.corridor,
        plan.facade,
        plan.horizontalSeparator,
        plan.verticalFaceSeparator,
        plan.verticalFaceSeparatorFill,
        plan.verticalBackSeparator,
        plan.verticalFaceSeparatorFill,
        plan.crossSeparator,
      ]

      // Loop the scenes and grab selected Item
      let selected = null
      let originArea = null
      for (let i = 0; i < scenes.length; i++) {
        let newList = []
        for (let k = 0; k < scenes[i].furnitureList.length; k++) {
          if (scenes[i].furnitureList[k].uuid === action.uuid) {
            selected = scenes[i].furnitureList[k]
            originArea = scenes[i]
          } else newList.push(scenes[i].furnitureList[k])
        }
        scenes[i].furnitureList = newList
      }

      selected.posOffset[0] = 0
      selected.posOffset[1] = 0
      selected.rotationRad = 0
      selected.connectorFurniture = action.parent
      selected.connectorSide = action.connectorSide
      selected.posOnConnectorSide = action.posOnConnectorSide
      selected.posOnMySide = action.posOnConnectorSide
      selected.mySide = 'BOTTOM'

      if (action.root) {
        selected.mySide = 'TOP'
        selected.connectorFurniture = null
      } else {
        selected.connectorFurniture = action.parent
      }

      // Put Object to selected Area
      plan[action.area].furnitureList.push(selected)

      let childs = []
      //look for Child objects
      for (let a = 0; a < originArea.furnitureList.length; a++) {
        if (originArea.furnitureList[a].connectorFurniture === selected.uuid) {
          childs.push(originArea.furnitureList[a].uuid)
        }
      }

      //Look for Child Connections
      for (let o = 0; o < 10; o++) {
        for (let a = 0; a < originArea.furnitureList.length; a++) {
          if (childs.includes(originArea.furnitureList[a].connectorFurniture)) {
            if (!childs.includes(originArea.furnitureList[a].uuid)) {
              childs.push(originArea.furnitureList[a].uuid)
            }
          }
        }
      }

      // Now Remove Childs from Origin Area
      let moveList = []
      let keepList = []
      for (let a = 0; a < originArea.furnitureList.length; a++) {
        if (childs.includes(originArea.furnitureList[a].uuid)) {
          moveList.push(originArea.furnitureList[a])
        } else {
          keepList.push(originArea.furnitureList[a])
        }
      }

      originArea.furnitureList = keepList
      for (let a = 0; a < moveList.length; a++) {
        plan[action.area].furnitureList.push(moveList[a])
      }

      plan.random = Math.floor(Math.random() * 10000)

      return {
        ...state,
        patternPlan: plan,
        dirtyFlag: true,
      }

    case patternBuilderActionTypes.DELETE_FURNITURE:

      pplan = { ...state.patternPlan }

      scenes = [
        pplan.main,
        pplan.fill,
        pplan.workBenchFront,
        pplan.workBenchBack,
        pplan.corridor,
        pplan.facade,
        pplan.horizontalSeparator,
        pplan.verticalFaceSeparator,
        pplan.verticalFaceSeparatorFill,
        pplan.verticalBackSeparator,
        pplan.verticalFaceSeparatorFill,
        pplan.crossSeparator,
      ]

      // Loop the scenes and grab selected Item

      for (let i = 0; i < scenes.length; i++) {
        let newList = []
        let counter = 0
        let updateWpcount = false
        for (let k = 0; k < scenes[i].furnitureList.length; k++) {

          if (scenes[i].furnitureList[k].uuid === action.id) {
            if (scenes[i].furnitureList[k].categoryName === 'chair') {
              updateWpcount = true
            }
          } else {
            newList.push(scenes[i].furnitureList[k])
            if (scenes[i].furnitureList[k].categoryName === 'chair') {
              counter++
            }
          }

        }
        if (updateWpcount) {
          scenes[i].numberWorkplaces = counter
        }

        scenes[i].furnitureList = newList
      }

      pplan.random = Math.floor(Math.random() * 10000)

      return {
        ...state,
        patternPlan: pplan,
        dirtyFlag: true,
      }

    case patternBuilderActionTypes.RESET_DIRTY_FLAG:
      return { ...state, dirtyFlag: false }

    case patternBuilderActionTypes.ADD_NEW_PATTERN:

      return {
        ...state,
        dirtyFlag: true,
        patternPlan: cloneDeep(newPattern),
      }

    case patternBuilderActionTypes.CREATE_PATTERN_FULFILLED:
      patterns = [...state.patterns]

      patterns[0] = action.payload.data

      return {
        ...state,
        dirtyFlag: false,
        patterns: patterns,
        patternPlan: action.payload.data,
      }

    case patternBuilderActionTypes.RENAME_PATTERN:
      let updatedList = []
      patterns = [...state.patterns]

      Array.from(patterns)
        .forEach(
          (item) => {
            if (item.id === action.id) {
              item.displayName = action.name
            }
            updatedList.push(item)
          },
        )

      patterns = updatedList

      pplan = { ...state.patternPlan }
      pplan.displayName = action.name

      return {
        ...state,
        dirtyFlag: true,
        patterns: patterns,
        patternPlan: pplan,
      }

    case patternBuilderActionTypes.DUPLICATE_PATTERN:
      patterns = [...state.patterns]

      let duplicatedPattern = cloneDeep(patterns.find(pattern => pattern.id === action.patternId))

      duplicatedPattern.id = null
      duplicatedPattern.name = null
      duplicatedPattern.displayName = duplicatedPattern.displayName + ' (Copy)'

      patterns.unshift(duplicatedPattern)

      return {
        ...state,
        patterns: patterns,
        patternPlan: duplicatedPattern,
      }

    case patternBuilderActionTypes.DISCARD_NEW_PATTERN:
      return {
        ...state,
        dirtyFlag: false,
        patternPlan: cloneDeep(newPattern),
      }

    case patternBuilderActionTypes.ROTATE_FURNITURE:
      pplan = { ...state.patternPlan }

      Object.values(pplan)
        .forEach(child => {
          if (child && child.furnitureList) {
            child.furnitureList.forEach((furn) => {
              if (furn.uuid === action.furnId) {
                furn.rotationRad = action.rotation
              }
            })
          }
        })

      return {
        ...state,
        dirtyFlag: true,
        patternPlan: pplan,
      }

    case patternBuilderActionTypes.FURNITURE_ANCHOR_SIDE:
      pplan = { ...state.patternPlan }

      Object.values(pplan)
        .forEach(child => {
          if (child && child.furnitureList) {
            child.furnitureList.forEach((furn) => {
              if (furn.uuid === action.furnId) {
                furn.mySide = action.anchorSide
              }
            })
          }
        })

      return {
        ...state,
        dirtyFlag: true,
        patternPlan: pplan,
      }



    default:
      return { ...state }
  }

}

export default patternReducer