import { fetchFloorData } from '../../../floorplan/actions/actions'
import { httpClient } from '../../../common/httpClient'
import { getBuildingId, getFloorId, projectVariationApi } from '../../../common/utils'
import { fetchBuildings } from '../../../buildings/actions/actions'
import { getGeometryObjectsToSave } from '../../reducer/drawingToolReducer'
import { floorPlanActionTypes } from '../../../floorplan/constants'

//import { }  from '../../controller/drawingToolcontroller'
export function uploadPicture (file) {
  const buildingId = getBuildingId()
  const floorId = getFloorId()
  console.log("upload picture")
  return function (dispatch) {
    dispatch(uploadPictureAPI(buildingId, floorId, file))
      .then(() => {
        //dispatch(setCurrentDrawingTabAPI(buildingId, floorId, tabsNames.SCALE))
        //  .then(() => {
            dispatch(fetchBuildings())
            dispatch(fetchFloorData(buildingId, floorId))
        //  })
      })
  }
}

function uploadPictureAPI (buildingId, floorId, file) {
  console.log("uploade picture: "+file.name)
  let form = new FormData()
  form.append('file', file)
  form.append('fileName', file.name)

  return {
    payload: httpClient.post(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/image`, form),
  }
}

export function fetchGeometries(){
  const buildingId = getBuildingId()
  const floorId = getFloorId()
  return function (dispatch){
    dispatch(fetchBuildings)
    dispatch(fetchFloorData(buildingId, floorId))
  }

}

export function updateGeometries() {
  console.log("updateGeometries")
  const buildingId = getBuildingId()
  const floorId = getFloorId()
  const { geometries } = getGeometryObjectsToSave()

  return function (dispatch) {
    dispatch(updateGeometriesAPI(buildingId, floorId, geometries))
      .then(() => {
        //dispatch(setCurrentDrawingTabAPI(buildingId, floorId, tabsNames.SCALE))
        //  .then(() => {
        dispatch(fetchBuildings())
        dispatch(fetchFloorData(buildingId, floorId))
        //  })
      })
  }
}

export function updateSpaceGeometriesWithPromise() {

  console.log("updateSpaceGeometries")
  const buildingId = getBuildingId()
  const floorId = getFloorId()
  const { geometries } = getGeometryObjectsToSave()
  const spaceGeometries = []
  //console.log(geometries.length)
  for (let i=0; i<geometries.length; i++){
    //console.log("GEOMETRY: "+i+": "+geometries[i].representationType)
    if (geometries[i].representationType==="space"){
      spaceGeometries.push(geometries[i])
    }
  }
  console.log("spaceGeometries")
  console.log(spaceGeometries)
  return function (dispatch) {
    return dispatch(updateGeometriesAPI(buildingId, floorId, spaceGeometries))
      .then(() => {
        return Promise.all([
          //dispatch(fetchBuildings()),
          //dispatch(fetchFloorData(buildingId, floorId))
        ]);
      });
  };
}

export function updateGeometriesWithPromise() {
  console.log("updateGeometriesWithPromise")
  const buildingId = getBuildingId();
  const floorId = getFloorId();
  const { geometries } = getGeometryObjectsToSave();

  console.log("drawingToolActions updateGeometries: " + geometries.length);
  console.log(geometries)

  let newGeometries=[]
  for(let iGeo=0; iGeo<geometries.length; iGeo++){
    if(geometries[iGeo].representationType){
      newGeometries.push(geometries[iGeo])
      //console.log(geometries[iGeo].toJSON())
    }
  }

  return function (dispatch) {
    return dispatch(updateGeometriesAPI(buildingId, floorId, newGeometries))
      .then(() => {
        return Promise.all([
          //dispatch(fetchBuildings()),
          //dispatch(fetchFloorData(buildingId, floorId))
        ]);
      });
  };
}

function updateGeometriesAPI(buildingId, floorId, geometries) {
  console.log("updateGeometriesAPI")
  console.log(geometries)
  return {
    type: floorPlanActionTypes.FETCH_GEOMETRIES,
    payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/geometries`, geometries)
  }
}

export function fetchFloorPlanAnalyticsLight () {
  console.log("FETCH ANALYTICS LIGHT")
  const buildingId = getBuildingId()
  const floorId = getFloorId()

  console.log("BID: "+ buildingId +"  FID: "+floorId)
  return {
    type: floorPlanActionTypes.FETCH_FLOOR_PLAN_ANALYTICS_LIGHT,
    payload: httpClient.get(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/type/${1}/analytics`),
  }
}

export function fetchFloorPlanAnalytics() {
  console.log("FETCH FLOORPLANANALYTICS")
  return async (dispatch) => {
    const buildingId = getBuildingId()
    const floorId = getFloorId()

    try {
      const response = await httpClient.get(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/analytics`)
      dispatch({
        type: floorPlanActionTypes.FETCH_FLOOR_PLAN_FULFILLED_ANALYTICS,
        payload: response
      });
      console.log("Response:")
      console.log(response)
      return response;
    } catch (error) {
      dispatch({
        type: floorPlanActionTypes.FETCH_FLOOR_PLAN_REJECTED_ANALYTICS,
        payload: error
      });
      throw error;
    }
  }
}

export function fetchFloorPlanAnalyticsWithResponse() {
  console.log("FETCH FLOORPLANALYTICS WITH RESPONSE")
  return async (dispatch) => {
    const buildingId = getBuildingId()
    const floorId = getFloorId()

    try {
      console.log("FETCH")
      const response = await httpClient.get(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/analytics`)
      const data = response.data;  // Stellen Sie sicher, dass "data" der richtige Weg ist, um die Daten zu extrahieren.
      dispatch({
        type: floorPlanActionTypes.FETCH_FLOOR_PLAN_FULFILLED_ANALYTICS,
        payload: data
      });
      console.log("DATA:")
      console.log(data)
      return data;  // Geben Sie die Daten zurück, nicht die gesamte Antwort.
    } catch (error) {
      dispatch({
        type: floorPlanActionTypes.FETCH_FLOOR_PLAN_REJECTED_ANALYTICS,
        payload: error
      });
      throw error;
    }
  }
}
