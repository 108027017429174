import * as React from 'react'
const SvgDeleteAxesDisabled = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 10H26" stroke="#C4C6D2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7998 10H8.7998V25H22.7998V10Z" stroke="#C4C6D2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 10H11.5V6H20.5V10Z" stroke="#C4C6D2"/>
    <path d="M16 13V22" stroke="#C4C6D2"/>
    <path d="M12.4004 13V22" stroke="#C4C6D2"/>
    <path d="M19.4004 13V22" stroke="#C4C6D2"/>
  </svg>
)

export default SvgDeleteAxesDisabled