const mouseEvent = {
  mouseDown: 'mousedown',
  mouseMouse: 'mousemove',
  mouseUp: 'mouseup',
  wheel: 'wheel',
}

export function addMouseEvents (element, onMouseDown, onMouseMove, onMouseUp, onMouseWheel) {
  if (onMouseDown) {
    element.addEventListener(mouseEvent.mouseDown, onMouseDown, true)
  }
  if (onMouseMove) {
    element.addEventListener(mouseEvent.mouseMouse, onMouseMove, true)
  }
  if (onMouseUp) {
    element.addEventListener(mouseEvent.mouseUp, onMouseUp, true)
  }
  if (onMouseWheel) {
    element.addEventListener(mouseEvent.wheel, onMouseWheel, true)
  }
}

export function removeMouseEvents (element, onMouseDown, onMouseMove, onMouseUp, onMouseWheel) {
  if (onMouseDown) {
    element.removeEventListener(mouseEvent.mouseDown, onMouseDown, true)
  }
  if (onMouseMove) {
    element.removeEventListener(mouseEvent.mouseMouse, onMouseMove, true)
  }
  if (onMouseUp) {
    element.removeEventListener(mouseEvent.mouseUp, onMouseUp, true)
  }
  if (onMouseWheel) {
    element.removeEventListener(mouseEvent.wheel, onMouseWheel, true)
  }
}