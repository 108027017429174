import React from 'react'

const SvgStaircaseIndicator = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      d="M6 17h3.004v-3.305h3.014v-3.367h2.99V7H18v10H8.67"
      fill={props.fillColor}
      fillRule="nonzero"
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgStaircaseIndicator
