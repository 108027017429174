import React from 'react'

const SvgDrawLinkInactive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g
      stroke="#FF8042"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18.773 10.895L12.645 21.51"/>
      <circle
        cx={19.104}
        cy={10.324}
        r={3.6}
        transform="rotate(30 19.104 10.324)"
      />
      <circle
        cx={12.504}
        cy={21.756}
        r={3.6}
        transform="rotate(30 12.504 21.756)"
      />
    </g>
  </svg>

export default SvgDrawLinkInactive