import {Raycaster, Scene, Vector3} from 'three'
import {getRenderer, resize} from './Renderer'
import {getOrthographicCamera} from './Camera'
import {start, stop} from './RenderLoop'
import {getOrbitControls} from './OrbitControls'
import {addBackground} from './Background'
import {addCatchPlane} from './CatchPlane'

export function setUp(mount) {
    const scene = new Scene()

    const renderer = getRenderer()
    mount.current.appendChild(renderer.domElement)

    const camera = getOrthographicCamera(mount.current)
    scene.add(camera)

    const raycaster = new Raycaster(new Vector3(0, 0, 20), new Vector3(0, 0, -1), 0, 100)

    const orbitControls = getOrbitControls(camera, renderer)

    addBackground(scene)
    addCatchPlane(scene)

    resize(mount, renderer, camera)
    const frameId = start(renderer, scene, camera)

    return {
        scene: scene,
        renderer: renderer,
        camera: camera,
        frameId: frameId,
        raycaster: raycaster,
        orbitControls: orbitControls,
    }
}

export function cleanUp(mount, renderer, frameId) {
    stop(frameId.frameId)
    if (mount.current !== null) {
      mount.current.removeChild(renderer.domElement)
    }
}