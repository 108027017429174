import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ModalConsumer from '../modal/ModalConsumer';
import DropdownMenu from '../DropdownMenu';
import { ListMenuItem } from '../ListMenuItem';
import ListMenu from '../ListMenu';
import UploadDialog from '../../../header/components/UploadDialog';
import DeletionDialog from '../DeletionDialog';
import RenameDialog from '../../../buildings/components/dialogs/RenameDialog';

import {
  deleteFloorAndFetchBuildings,
  duplicateFloorAndFetchBuildings,
  fetchDownloadFloor,
  fetchGeometriesFileFloor,
  loadDemoFloor,
  moveFloorDownAndFetchBuildings,
  moveFloorUpAndFetchBuildings,
  renameFloorAndFetch,
  uploadGeometriesFloor,
} from '../../../floorplanner/actions/actions';

import {
  autoGenerateAssigmentInFloor,
  deleteOrganisationalUnitAssignmentInFloor,
} from '../../../floorplan/actions/actions';

import { getFloorsOfSelectedBuilding } from '../../../floorplan/reducers/buildings';
import { viewNames } from '../../globalConstants';
import { iconTypes } from '../icons/IconFactory'

function FloorMenu({ onClose, floorId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const floors = useSelector(getFloorsOfSelectedBuilding);
  const currentBuildingId = useSelector((state) => state.appState.selectedBuildingId);

  const advancedSettings = useSelector((state) => state.settings.appSettings.advancedSettings);

  const showRenameDialog = (showModal) => {
    const floor = floors.find((floor) => floor.id === floorId);

    showModal(RenameDialog, {
      name: floor.name,
      type: 'floor',
      rename: (floorName) => handleRenameFloor(floorName),
    });
  }

  const showUploadGeometriesDialog = (showModal) => {
    showModal(UploadDialog, {
      title: t('upload_geometries'),
      upload: (file) => handleUploadGeometriesFloor(file),
    });
  }

  const showDeleteDialog = (showModal) => {
    showModal(DeletionDialog, {
      onConfirm: () => handleDeleteFloor(),
    });
  }


    const isFloorsView = location.pathname.endsWith(viewNames.FLOORS)
    const isDrawingView = location.pathname.endsWith(viewNames.EDIT)
    const development = false

  const closeMenu = ()=> {
    onClose()
  }

  const handleOpenModalClick = (showModal, component, data)=> {
    closeMenu()
    showModal(component, data)
  }

  const handleRenameFloor = (floorName) => {
    dispatch(renameFloorAndFetch(currentBuildingId, floorId, floorName))
  }

  const handleDuplicateFloor = ()=> {
    closeMenu()
    dispatch(duplicateFloorAndFetchBuildings(currentBuildingId, floorId))
  }

  const handleMoveUpFloor = ()=> {
    closeMenu()
    dispatch(moveFloorUpAndFetchBuildings(currentBuildingId, floorId))
  }

  const handleMoveDownFloor = () => {
    closeMenu()
    dispatch(moveFloorDownAndFetchBuildings(currentBuildingId, floorId))
  }

  const handleUploadGeometriesFloor = (file)=> {
    closeMenu()
    dispatch(uploadGeometriesFloor(currentBuildingId, floorId, file))
  }

  const handleDownloadGeometriesFloor = ()=> {
    closeMenu()
    dispatch(fetchGeometriesFileFloor(currentBuildingId, floorId))
  }

  const handleDownloadFloor = ()=> {
    closeMenu()
    dispatch(fetchDownloadFloor(floorId))
  }

  const handleLoadDemoFloor = ()=> {
    closeMenu()
    loadDemoFloor()
  }

  const handleCleanUp = () => {
    closeMenu()
    dispatch(deleteOrganisationalUnitAssignmentInFloor(currentBuildingId, floorId))
  }

  const handleAssistant = () => {
    closeMenu()
    dispatch(autoGenerateAssigmentInFloor(currentBuildingId, floorId))
  }

  const handleEditFloor = ()=> {
    navigate('./' + viewNames.EDIT)
  }

  const handleDeleteFloor = () => {
    dispatch(deleteFloorAndFetchBuildings(currentBuildingId, floorId))
  }

    return (
      <DropdownMenu onClose={() => closeMenu()}>

        <ModalConsumer>
          {({ showModal }) => (
            <ListMenu>
              <ListMenuItem onClick={() => showRenameDialog(showModal)} secondIcon={iconTypes.rename}>
                {t('rename')}
              </ListMenuItem>
              {
                advancedSettings ?
                  <React.Fragment>
                    <ListMenuItem onClick={() => handleDuplicateFloor()} secondIcon={iconTypes.duplicate}>
                      {t('duplicate')}
                    </ListMenuItem>
                    <ListMenuItem onClick={() => handleMoveUpFloor()} secondIcon={iconTypes.triangleUp}>
                      {t('move_up')}
                    </ListMenuItem>
                    <ListMenuItem onClick={() => handleMoveDownFloor()} secondIcon={iconTypes.triangleDown}>
                      {t('move_down')}
                    </ListMenuItem>
                    {
                      isDrawingView && development ?
                        <React.Fragment>
                          <ListMenuItem onClick={() => showUploadGeometriesDialog(showModal)} secondIcon={iconTypes.import}>
                            {t('upload_geometries')}
                          </ListMenuItem>
                          <ListMenuItem onClick={() => handleDownloadGeometriesFloor()} secondIcon={iconTypes.folder}>
                            {t('download_geometries')}
                          </ListMenuItem>
                          <ListMenuItem onClick={() => handleDownloadFloor()} secondIcon={iconTypes.folder}>
                            {t('download_floor')}
                          </ListMenuItem>
                          <ListMenuItem onClick={() => handleLoadDemoFloor()} secondIcon={iconTypes.import}>
                            {t('load_demo_floor')}
                          </ListMenuItem>
                        </React.Fragment> :
                        null
                    }
                  </React.Fragment>
                  : null
              }
              {
                isFloorsView ?
                  <React.Fragment>
                    <ListMenuItem onClick={() => handleCleanUp()} secondIcon={iconTypes.cleaning}>
                      {t('clean_up')}
                    </ListMenuItem>
                    <ListMenuItem onClick={() => handleAssistant()} secondIcon={iconTypes.assistant}>
                      {t('assistant')}
                    </ListMenuItem>
                  </React.Fragment>
                  : null
              }
              {!isDrawingView ?
                <ListMenuItem onClick={() => handleEditFloor()} secondIcon={iconTypes.editOutline}>
                  {t('edit_outlines')}
                </ListMenuItem>
                : null
              }
              <ListMenuItem disabled={floors.length === 1} onClick={() => showDeleteDialog(showModal)} secondIcon={iconTypes.delete}>
                {t('delete')}
              </ListMenuItem>
            </ListMenu>
          )}
        </ModalConsumer>
      </DropdownMenu>
    )

}



export default FloorMenu


