import React from 'react'
import { useDispatch, useSelector } from 'react-redux'


import haworthLogo from '../../assets/images/svg/haworth-logo.svg'
import haworthLogoWhite from '../../assets/images/svg/haworth-logo-white.svg'

import Text from '../../common/components/Text'

import './TitleBar.scss'
import { clearProject } from '../../floorplan/actions/actions'
import { useNavigate } from 'react-router'

function TitleBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const advancedSettings = useSelector(state => state.settings.appSettings.advancedSettings);
  const handleHaworthClicked = () => {
    dispatch(clearProject())


    navigate('/projects')
  }


    return (
      <div className={'title-bar' + (advancedSettings ? ' dark-mode' : '')}>
        <div className='application-header-content'>
          <div>
            <img className="application-header-content-logo" src={advancedSettings ? haworthLogoWhite : haworthLogo} alt="HAWORTH" onClick={() => handleHaworthClicked()}/>
          </div>

          <div className={'application-header-version-section'}>
            <Text outerClassName={'version-title' + (advancedSettings ? ' dark-mode' : '')} component={'runningTitle'}>beta</Text>
            <Text outerClassName={'version-number' + (advancedSettings ? ' dark-mode' : '')}>version 0.5.0</Text>
          </div>

        </div>
      </div>
    )



}



export default TitleBar
