import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchProjectData } from '../floorplan/actions/actions';

import ApplicationBuilderContent from './components/ApplicationBuilderContent';
import { viewNames } from '../common/globalConstants'
import { useLocation } from 'react-router'

function ApplicationBuilderApp() {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { advancedSettings, dataLoaded } = useSelector((state) => ({
    advancedSettings: state.settings.appSettings.advancedSettings,
    dataLoaded: state.dataLoaded.dataLoaded,
  }));


  // Ersetzt componentDidMount
  useEffect(() => {
    if (!dataLoaded) {
      dispatch(fetchProjectData(projectId));
    }
  }, [projectId, dataLoaded, dispatch]);

  // Ersetzt componentDidUpdate für advancedSettings
  useEffect(() => {
    if (advancedSettings === false) {
      const pathname = location.pathname
      const p = pathname.replace('/sets', '');

      navigate('..' + p + '/floorplan' )

    }
  }, [advancedSettings, navigate]);

  return (
    <div id={'application-builder'}>
      <ApplicationBuilderContent projectId={projectId} />
    </div>
  );
}


export default ApplicationBuilderApp;