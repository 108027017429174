

export class GraphFace {

  constructor (outline, holes = []) {
    this.outline = outline
    this.holes = holes
    this.parentFace = null
  }

  getOutline() {
    return this.outline
  }

  getHoles() {
    return this.holes
  }

  addHole(hole) {
    this.holes.push(hole)
  }

  setParentFace(parentFace) {
    this.parentFace = parentFace
  }

  getParentFace() {
    return this.parentFace
  }

}