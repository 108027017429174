import * as React from 'react'

const AxesEditNew = props =>
  <svg width={32} height={32} viewBox="0 0 32 32">
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
      <linearGradient
        id="prefix__b"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g stroke="#22293b">
        <path
          d="M8.885 19.372l.398-.397 4.242 4.243-.398.398-5.476 1.581c-.27.078-.428-.072-.348-.347l1.581-5.477zm.398-.397L22.014 6.243a.997.997 0 011.404-.003l2.842 2.842a.996.996 0 01-.003 1.404L13.525 23.218l-4.242-4.243z"/>
        <path
          fill="url(#prefix__a)"
          d="M6 0h15v6H6z"
          transform="scale(-1 1) rotate(45 -17.046 -27.067)"
        />
        <path
          fill="url(#prefix__b)"
          d="M3.202 1.233v3.533L.445 3.246c-.246-.136-.251-.353 0-.492l2.757-1.521z"
          transform="rotate(-45 30.546 5.525)"
        />
        <path d="M18.836 9.422l4.114 4.114"/>
      </g>
    </g>
  </svg>

export default AxesEditNew