import { OrthographicCamera } from 'three'

export function getOrthographicCamera (mount) {
  const { clientWidth, clientHeight } = mount

  const camera = new OrthographicCamera(
    clientWidth / -16,
    clientWidth / 16,
    clientHeight / 16,
    clientHeight / -16,
    -1,
    100,
  )

  camera.enableRotate = false
  camera.position.z = 20

  return camera
}

export function getScreenPositionFromThreePosition (threePosition, mount, camera) {
  const position = threePosition.clone()

  console.log("mount")
  console.log(mount)

  const { clientWidth, clientHeight, offsetLeft, offsetTop } = mount

  position.project(camera)

  position.x = (position.x + 1) / 2
  position.y = (-position.y + 1) / 2

  let x = position.x * clientWidth + offsetLeft
  let y = position.y * clientHeight + offsetTop

  return { x, y }
}