import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router'
import { hotjar } from 'react-hotjar'

import { viewNames } from './common/globalConstants'
import { Provider } from 'react-redux'
import store from './store'
import { BrowserRouter as Router } from 'react-router-dom'

import AppContainer from './common/components/layout/AppContainer'
import ModalProvider from './common/components/modal/ModalProvider'
import AuthProvider from './common/auth/AuthProvider'

import DefaultApp from './default/DefaultApp'
import OnboardingApp from './onboarding/OnboardingApp'
import FloorPlanApp from './floorplan/FloorPlanApp'
import FloorsApp from './floors/FloorsApp'
import PeopleApp from './people/PeopleApp'
import DrawingToolApp from './DrawingTool/components/DrawingToolApp'
import ApplicationBuilderApp from './applicationbuilder/ApplicationBuilderApp'
import PatternBuilderApp from './patternbuilder/PatternBuilderApp'
import SettingsApp from './settings/SettingsApp'

import ModalRoot from './common/components/modal/ModalRoot'
import LoadingScreen from './common/components/layout/LoadingScreen'
import NotificationsContainer from './common/notification/NotificationsContainer'
import ErrorDialogsContainer from './common/error/ErrorDialogsContainer'
import './il8n'
import ProjectLoader from './ProjectLoader'

function RedirectToFloorplan() {
  const { projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (projectId) {
      navigate(`/projects/${projectId}/floorplan`, { replace: true });
    }
  }, [projectId, navigate]);

  return null; // Diese Komponente rendert nichts
}

function App() {

  useEffect(() => {
    hotjar.initialize(2334192, 6);
  }, []);

  return (
    <Provider store={store}>
      <ModalProvider>
        <AuthProvider>
          <Router>
            <AppContainer>
              <Routes>
                <Route path={`/projects/:projectId/*`} element={<ProjectLoader />} />
              </Routes>

              <Routes>
                <Route path={`/projects/:projectId`} element={<RedirectToFloorplan />} />
                <Route path={`/projects/:projectId/${viewNames.ONBOARDING}/*`} element={<OnboardingApp />} />
                <Route path={`/projects/:projectId/${viewNames.FLOORPLAN}`} element={<FloorPlanApp />} />
                <Route path={`/projects/:projectId/${viewNames.EDIT}`} element={<DrawingToolApp />} />
                <Route path={`/projects/:projectId/${viewNames.FLOORS}`} element={<FloorsApp />} />
                <Route path={`/projects/:projectId/${viewNames.PEOPLE}`} element={<PeopleApp />} />
                <Route path={`/projects/:projectId/${viewNames.SETS}/*`} element={<ApplicationBuilderApp />} />
                <Route path={`/projects/:projectId/${viewNames.PATTERNS}/*`} element={<PatternBuilderApp />} />
                <Route path={`/projects/:projectId/${viewNames.SETTINGS}/*`} element={<SettingsApp />} />
                <Route path="/projects" element={<DefaultApp />} />
                <Route path="*" element={<Navigate replace to="/projects" />} />

              </Routes>
            </AppContainer>
            {/*
              We need the modalRoot twice - once for the project specific modals containing the projectId and once for the global modals.
            */}
            <Routes>
              <Route path={`/projects/:projectId/*`} element={<ModalRoot />} />
            </Routes>
            <Routes>
              <Route path={`/projects`} element={<ModalRoot />} />
            </Routes>




          <LoadingScreen />
          <NotificationsContainer />
          <ErrorDialogsContainer />
          </Router>
        </AuthProvider>
      </ModalProvider>
    </Provider>
  );
}

export default App;
