import React from 'react'
import Icon, { iconTypes } from '../../common/components/icons/IconFactory'

export function ButtonMenuItem ({ active, title, onClick = () => {}, darkMode = false }) {

  const activeClass = active ? ' toolbar-nav-active' : ''

  return (
    <div role={'button'} className={'navigation-menu-item' + activeClass} onClick={() => onClick()}>
      <div className={'navigation-menu-item-content'}>
        <div className={'navigation-menu-item-content-wrapper'}>
          <div className={'navigation-menu-item-text-wrapper' + (darkMode ? ' dark-mode' : '')}>
            <div className={'navigation-menu-item-text'}>
              <span title={title}>{title}</span>
            </div>
          </div>
          <Icon name={darkMode ? iconTypes.triangleDownWhite : iconTypes.triangleDown} className={'navigation-menu-item-icon'}/>
        </div>
      </div>
    </div>
  )

}
