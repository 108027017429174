import { applicationBuilderActionTypes } from '../constants'
import { cloneDeep } from 'lodash'
import { undoActionTypes } from '../../common/redux/undo/undo-actions'

let initialState = {
  furnitureEnsembles: [],
  furnitureEnsemblePlan: {
    displayName: '',
    wallCode: '----',
    sideCode: 'h---',
    tags: [],
    furnitureList: [],
  },
  furnitureEnsembleUndoStore: [],
  furnitureEnsembleUndoPosition: 0,
  furnitureEnsembleCategories: [],
  undoAvailable: false,
  redoAvailable: false,
  undoRandom: 0,
  error: null,
  dirtyFlag: false,
}

export const newApplication = {
  id: null,
  displayName: 'New Application',
  typeId: null,
  minDepth: 5,
  maxDepth: 5,
  minWidth: 5,
  maxWidth: 5,
  furnitureList: [],
}

const defaultFurnitureEnsemble = {
  categoryId: 'undefined',
  date: '',
  displayName: newApplication.displayName,
  id: newApplication.id,
  minDepth: newApplication.minDepth,
  maxDepth: newApplication.maxDepth,
  minWidth: newApplication.minWidth,
  maxWidth: newApplication.maxWidth,
  placingMode: 'FULL',
  preferredCode: '----',
  sideCode: 'h---',
  tags: [],
  typeId: newApplication.typeId,
  wallCode: '----',
  furnitureList: [],
}

const defaultFurnitureEnsemblePlan = {
  placingMode: 'FULL',
  displayName: 'New Application',
  minDepth: newApplication.minDepth,
  maxDepth: newApplication.maxDepth,
  minWidth: newApplication.minWidth,
  maxWidth: newApplication.maxWidth,
  categoryId: '',
  sideCode: 'h---',
  wallCode: '----',
  preferredCode: '----',
  tags: [],
  furnitureList: [],
  availableTags: null,
  furnitureEnsemblesCategories: [],
  error: null,
  dirtyFlag: false,
}

function furnitureEnsemblesReducer (state = initialState, action) {
  let furnitureEnsembles = null
  let furnitureEnsemblePlan = null
  let filtered = null
  let furnitureList = null
  let updatedList = []
  let newFurnitureEnsembles

  switch (action.type) {

    // ------------------- Frontend actions -------------------
    case undoActionTypes.DISCARD_UNDO:

      return {
        ...state,
        furnitureEnsembleUndoStoreUndoStore: [],
        furnitureEnsembleUndoPosition: 0,
        undoAvailable: false,
        redoAvailable: false,
      }

    case undoActionTypes.STORE_APPLICATION:
      //let store = [...state.furnitureEnsembleUndoStore]

      let store = [...state.furnitureEnsembleUndoStore]

      if (state.furnitureEnsembleUndoPosition < state.furnitureEnsembleUndoStore.length) {
        for (let i = state.furnitureEnsembleUndoStore.length; i > state.furnitureEnsembleUndoPosition; i--) {
          store.pop(i - 1)
        }
      }

      store.push(JSON.stringify(state.furnitureEnsemblePlan))
      let pos = state.furnitureEnsembleUndoPosition
      pos++

      if (store.length > 25) {
        pos--
        store.shift()
      }

      return {
        ...state,
        furnitureEnsembleUndoStore: store,
        furnitureEnsembleUndoPosition: pos,
        undoAvailable: true,
        redoAvailable: false,
      }

    case undoActionTypes.UNDO_PROJECT_FULFILLED:
      return {
        ...state,
        undoRandom: Math.round(Math.random() * 9999999 + 10000000),
        furnitureEnsembleUndoPosition: 0,
        furnitureEnsembleUndoStore: [],
        undoAvailable: false,
        redoAvailable: false,
      }

    case undoActionTypes.UNDO_APPLICATION:

      let store2 = [...state.furnitureEnsembleUndoStore]
      if (state.furnitureEnsembleUndoStore.length === state.furnitureEnsembleUndoPosition) {
        store2.push(JSON.stringify(state.furnitureEnsemblePlan))
      }

      let x = JSON.parse(state.furnitureEnsembleUndoStore[state.furnitureEnsembleUndoPosition - 1])

      let _undo = false

      if (state.furnitureEnsembleUndoStore.length > 0 && state.furnitureEnsembleUndoPosition - 1 > 0) {
        _undo = true
      }

      return {
        ...state,
        furnitureEnsemblePlan: x,
        undoRandom: Math.round(Math.random() * 9999999 + 10000000),
        furnitureEnsembleUndoPosition: state.furnitureEnsembleUndoPosition - 1,
        furnitureEnsembleUndoStore: store2,
        undoAvailable: _undo,
        redoAvailable: true,
      }

    case undoActionTypes.REDO_APPLICATION:
      let x2 = JSON.parse(state.furnitureEnsembleUndoStore[state.furnitureEnsembleUndoPosition + 1])

      let __redo = false


      if (state.furnitureEnsembleUndoStore.length > 0 && state.furnitureEnsembleUndoPosition < state.furnitureEnsembleUndoStore.length - 2) {
        __redo = true
      }

      return {
        ...state,
        furnitureEnsemblePlan: x2,
        undoRandom: Math.round(Math.random() * 9999999 + 10000000),
        furnitureEnsembleUndoPosition: state.furnitureEnsembleUndoPosition + 1,
        undoAvailable: true,
        redoAvailable: __redo,
      }

    case undoActionTypes.UNDO_APPLICATION_AVAILABLE:

      let undo = false
      let redo = false

      if (state.furnitureEnsembleUndoStore.length > 0 && state.furnitureEnsembleUndoPosition > 0) {
        undo = true
      }

      if (state.furnitureEnsembleUndoStore.length > 0 && state.furnitureEnsembleUndoPosition < state.furnitureEnsembleUndoStore.length - 1) {
        redo = true
      }

      return {
        ...state,
        undoAvailable: undo,
        redoAvailable: redo,
      }

    case applicationBuilderActionTypes.RENAME_APPLICATION:
      furnitureEnsembles = [...state.furnitureEnsembles]

      Array.from(furnitureEnsembles)
        .forEach(
          (item) => {
            if (item.id === action.id) {
              item.displayName = action.name
            }
            updatedList.push(item)
          },
        )

      furnitureEnsembles = updatedList

      furnitureEnsemblePlan = { ...state.furnitureEnsemblePlan }
      furnitureEnsemblePlan.displayName = action.name

      return {
        ...state,
        dirtyFlag: true,
        furnitureEnsembles: furnitureEnsembles,
        furnitureEnsemblePlan: furnitureEnsemblePlan,
      }

    case applicationBuilderActionTypes.RENAME_CURRENT_FURNITURE_ENSEMBLE_PLAN:
      let renamed_furnitureEnsemblePlan = state.furnitureEnsemblePlan

      renamed_furnitureEnsemblePlan.displayName = action.name

      return {
        ...state,
        dirtyFlag: true,
        furnitureEnsemblePlan: renamed_furnitureEnsemblePlan,
      }

    case applicationBuilderActionTypes.SAVE_CHANGES:

      return {
        ...state,
        dirtyFlag: false,
      }

    case applicationBuilderActionTypes.DISCARD_NEW_APPLICATION:

      let discardFurnitureEnsembles = [...state.furnitureEnsembles]

      for (let i = discardFurnitureEnsembles.length - 1; i >= 0; i--) {
        if (discardFurnitureEnsembles[i].id === newApplication.id) {
          discardFurnitureEnsembles.splice(i, 1)
          break
        }
      }

      furnitureEnsemblePlan = cloneDeep(defaultFurnitureEnsemblePlan)

      return {
        ...state,
        furnitureEnsembles: discardFurnitureEnsembles,
        furnitureEnsemblePlan: furnitureEnsemblePlan,
        dirtyFlag: false,
      }

    case applicationBuilderActionTypes.RESET_DIRTY_FLAG:
      return {
        ...state,
        dirtyFlag: false,
      }

    case applicationBuilderActionTypes.ADD_APPLICATION:
      furnitureEnsembles = [...state.furnitureEnsembles]

      newFurnitureEnsembles = Array.from(furnitureEnsembles)

      newFurnitureEnsembles.unshift(cloneDeep(defaultFurnitureEnsemble))

      let newFurnitureEnsemblesPlan = cloneDeep(defaultFurnitureEnsemblePlan)

      newFurnitureEnsemblesPlan.furnitureEnsemblesCategories = state.furnitureEnsembleCategories

      return {
        ...state,
        dirtyFlag: true,
        furnitureEnsembles: newFurnitureEnsembles,
        furnitureEnsemblePlan: newFurnitureEnsemblesPlan,
      }

    case applicationBuilderActionTypes.DUPLICATE_APPLICATION:
      furnitureEnsembles = [...state.furnitureEnsembles]

      newFurnitureEnsembles = Array.from(furnitureEnsembles)

      let duplicatedEnsemble = cloneDeep(furnitureEnsembles.find(ensemble => ensemble.id === action.appId))

      duplicatedEnsemble.id = null
      duplicatedEnsemble.typeId = null
      duplicatedEnsemble.displayName = duplicatedEnsemble.displayName + ' (Copy)'
      duplicatedEnsemble.furnitureList = action.payload.action.payload.data.furnitureList

      newFurnitureEnsembles.unshift(duplicatedEnsemble)

      return {
        ...state,
        dirtyFlag: true,
        furnitureEnsembles: newFurnitureEnsembles,
        furnitureEnsemblePlan: duplicatedEnsemble,
      }



    // ---- Ground Plan Settings ----

    case applicationBuilderActionTypes.ADD_TAG_FURNITURE_ENSEMBLE_PLAN:

      furnitureEnsemblePlan = { ...state.furnitureEnsemblePlan }
      filtered = furnitureEnsemblePlan.tags.filter(function (value, index, arr) {
        return value
      })

      filtered.push(action.tag)

      furnitureEnsemblePlan.tags = filtered
      return {
        ...state,
        dirtyFlag: true,
        furnitureEnsemblePlan: furnitureEnsemblePlan,
      }

    case applicationBuilderActionTypes.REMOVE_TAG_FURNITURE_ENSEMBLE_PLAN:

      furnitureEnsemblePlan = { ...state.furnitureEnsemblePlan }
      filtered = furnitureEnsemblePlan.tags.filter(function (value, index, arr) {
        return value.id !== action.tag.id ? value : ''
      })
      furnitureEnsemblePlan.tags = filtered

      return {
        ...state,
        dirtyFlag: true,
        furnitureEnsemblePlan: furnitureEnsemblePlan,
      }

    case applicationBuilderActionTypes.CHANGE_FURNITURE_ENSEMBLE_PLAN_CATEGORY:

      furnitureEnsemblePlan = { ...state.furnitureEnsemblePlan }
      furnitureEnsemblePlan.categoryId = action.category

      return {
        ...state,
        dirtyFlag: true,
        furnitureEnsemblePlan: furnitureEnsemblePlan,
      }

    case applicationBuilderActionTypes.CHANGE_FURNITURE_ENSEMBLE_PLAN_SIZE:

      furnitureEnsemblePlan = { ...state.furnitureEnsemblePlan }

      let maxDepth = action.sizes.maxDepth
      let maxWidth = action.sizes.maxWidth
      let minDepth = action.sizes.minDepth
      let minWidth = action.sizes.minWidth

      if (minWidth > maxWidth) maxWidth = minWidth
      if (minDepth > maxDepth) maxDepth = minDepth

      furnitureEnsemblePlan.maxDepth = maxDepth
      furnitureEnsemblePlan.minDepth = minDepth
      furnitureEnsemblePlan.maxWidth = maxWidth
      furnitureEnsemblePlan.minWidth = minWidth

      return {
        ...state,
        dirtyFlag: true,
        furnitureEnsemblePlan: furnitureEnsemblePlan,

      }

    // ---- Furniture Settings ----

    case applicationBuilderActionTypes.CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_POSITION:

      furnitureEnsemblePlan = { ...state.furnitureEnsemblePlan }
      furnitureList = furnitureEnsemblePlan.furnitureList

      for (let p = 0; p < furnitureList.length; p++) {
        if (furnitureList[p].uuid === action.uuid) {

          furnitureList[p].posOffset[0] = action.offset.posX + furnitureList[p].posOffset[0]
          furnitureList[p].posOffset[1] += action.offset.posZ
          break
        }
      }
      furnitureEnsemblePlan.furnitureList = furnitureList

      return {
        ...state,
        dirtyFlag: true,
        furnitureEnsemblePlan: furnitureEnsemblePlan,
      }

    case applicationBuilderActionTypes.CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_ROTATION:

      furnitureEnsemblePlan = { ...state.furnitureEnsemblePlan }
      furnitureList = furnitureEnsemblePlan.furnitureList

      updatedList = []

      furnitureList.forEach(
        (furniture) => {
          if (furniture.uuid === action.uuid) {
            furniture.rotationRad = action.rotation
          }
          updatedList.push(furniture)
        })

      furnitureEnsemblePlan.furnitureList = updatedList

      return {
        ...state,
        dirtyFlag: true,
        furnitureEnsemblePlan: furnitureEnsemblePlan,
      }

    case applicationBuilderActionTypes.CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_PARENT:

      furnitureEnsemblePlan = { ...state.furnitureEnsemblePlan }
      furnitureList = furnitureEnsemblePlan.furnitureList

      for (let p = 0; p < furnitureList.length; p++) {
        if (furnitureList[p].uuid === action.uuid) {

          furnitureList[p].posOffset[0] = 0
          furnitureList[p].posOffset[1] = 0
          furnitureList[p].rotationRad = 0
          if (action.root !== true) furnitureList[p].connectorFurniture = action.parent
          furnitureList[p].connectorSide = action.connectorSide
          furnitureList[p].posOnConnectorSide = action.posOnConnectorSide
          furnitureList[p].mySide = 'BOTTOM'

          if (action.root === true) {
            furnitureList[p].mySide = 'TOP'
            furnitureList[p].connectorFurniture = null

          }
          if (action.posOnConnectorSide === 'START') furnitureList[p].posOnMySide = 'START'
          else if (action.posOnConnectorSide === 'END') furnitureList[p].posOnMySide = 'END'
          else furnitureList[p].posOnMySide = 'MID'

        }
      }

      furnitureEnsemblePlan.furnitureList = furnitureList

      return {
        ...state,
        dirtyFlag: true,
        furnitureEnsemblePlan: furnitureEnsemblePlan,

      }

    case applicationBuilderActionTypes.ADD_FURNITURE_ENSEMBLE_PLAN_FURNITURE:

      furnitureEnsemblePlan = { ...state.furnitureEnsemblePlan }
      furnitureList = furnitureEnsemblePlan.furnitureList

      let newElement = {
        'type': action.uuid,
        'id': null,
        'displayName': action.constructor.name,
        'description': action.constructor.displayName,
        'categoryName': action.constructor.category,
        'uuid': action.newId,
        'rotationRad': 0.0,
        'mySide': 'BOTTOM',
        'posOnMySide': 'MID',
        'posOffset': [
          0,
          0,
        ],
        'connectorFurniture': null,
        'connectorSide': action.connectorSide,
        'posOnConnectorSide': action.posOnConnectorSide,
        'connectorObjectIndex': 0,
        'localBBox': [],
        'tempPolygon': [],
        'ofmlType': null,
      }
      if (action.root === true) {
        newElement.mySide = 'TOP'
        newElement.connectorFurniture = null

      }
      if (action.root !== true) {
        newElement.connectorFurniture = action.parent
      }
      if (action.posOnConnectorSide === 'START') newElement.posOnMySide = 'START'
      else if (action.posOnConnectorSide === 'END') newElement.posOnMySide = 'END'
      else newElement.posOnMySide = 'MID'

      furnitureList.push(newElement)

      furnitureEnsemblePlan.furnitureList = furnitureList

      return {
        ...state,
        dirtyFlag: true,
        furnitureEnsemblePlan: furnitureEnsemblePlan,

      }

    case applicationBuilderActionTypes.CHANGE_FURNITURE_ANCHOR_SIDE:
      console.log("CHANGE_ANCHOR_SIDE")
      furnitureEnsemblePlan = { ...state.furnitureEnsemblePlan }
      furnitureList = furnitureEnsemblePlan.furnitureList
      console.log("action uuid: "+action.uuid)
      console.log(furnitureList)
      updatedList = []

      furnitureList.forEach(
        (furniture) => {
          if (furniture.uuid === action.uuid) {
            furniture.mySide = action.anchorSide
          }
          updatedList.push(furniture)
        })

      console.log(updatedList)
      furnitureEnsemblePlan.furnitureList = updatedList

      return {
        ...state,
        dirtyFlag: true,
        furnitureEnsemblePlan: furnitureEnsemblePlan,
      }

    case applicationBuilderActionTypes.DELETE_FURNITURE:
      furnitureEnsemblePlan = { ...state.furnitureEnsemblePlan }
      furnitureList = furnitureEnsemblePlan.furnitureList

      furnitureList = furnitureList.filter(
        furniture => furniture.uuid !== action.id,
      )

      furnitureEnsemblePlan.furnitureList = furnitureList

      return {
        ...state,
        dirtyFlag: true,
        furnitureEnsemblePlan: furnitureEnsemblePlan,
      }

    // ---- Wall  Settings ----

    case applicationBuilderActionTypes.CHANGE_WALL_CODE:
      furnitureEnsemblePlan = { ...state.furnitureEnsemblePlan }

      furnitureEnsemblePlan.wallCode = action.code

      return {
        ...state,
        dirtyFlag: true,
        furnitureEnsemblePlan: furnitureEnsemblePlan,
      }

    case applicationBuilderActionTypes.CHANGE_SIDE_CODE:
      furnitureEnsemblePlan = { ...state.furnitureEnsemblePlan }

      furnitureEnsemblePlan.sideCode = action.code

      return {
        ...state,
        dirtyFlag: true,
        furnitureEnsemblePlan: furnitureEnsemblePlan,
      }

    // ------------------- Backend actions -------------------

    case applicationBuilderActionTypes.CREATE_FURNITURE_ENSEMBLE_FULFILLED:
      furnitureEnsembles = [...state.furnitureEnsembles]

      let newEnsemble = action.payload.data
      furnitureEnsembles[0] = cloneDeep(newEnsemble)

      return {
        ...state,
        dirtyFlag: false,
        furnitureEnsembles: furnitureEnsembles,
        furnitureEnsemblePlan: newEnsemble,
      }

    case applicationBuilderActionTypes.FETCH_FURNITURE_ENSEMBLES_FULFILLED:

      return {
        ...state,
        furnitureEnsembles: action.payload.data,
      }

    case applicationBuilderActionTypes.FETCH_FURNITURE_ENSEMBLE_PLAN_FULFILLED:
      return {
        ...state,
        dirtyFlag: false,
        furnitureEnsemblePlan: action.payload.data,
      }

    case applicationBuilderActionTypes.FETCH_FURNITURE_ENSEMBLE_CATEGORIES_FULFILLED:

      return {
        ...state,
        furnitureEnsembleCategories: action.payload.data,
      }

    case applicationBuilderActionTypes.SAVE_APPLICATION_PLAN_FULFILLED:
      return {
        ...state,
        furnitureEnsemblePlan: action.payload.data,
        dirtyFlag: false,
      }

    default:
      return { ...state }
  }

}

export default furnitureEnsemblesReducer