import React from 'react'

const SvgFloorPlanIcon = props => (
  <svg
    width={14}
    height={15}
    viewBox="0 0 14 15"
    {...props}
  >
    <path
      d="M1.75 12.75a.875.875 0 01-.875-.875v-8.75c0-.483.392-.875.875-.875h10.5c.483 0 .875.392.875.875v8.75a.875.875 0 01-.875.875H1.75zm10.5-9.625H1.75V5.75H3.5v.875H1.75v5.25h7v-1.75h.875v1.75h2.625v-5.25H9.625v1.75H8.75v-1.75h-3.5V5.75h7V3.125z"
      fill={props.color}
      fillRule="evenodd"
    />
  </svg>
)

export default SvgFloorPlanIcon
