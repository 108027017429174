import React from 'react'

const SvgTransform = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M13.65 3.75c.451 0 .839.309 1.009.75H18a1.5 1.5 0 011.493 1.356L19.5 6v3.341c.401.155.693.49.742.888l.008.121v3.3c0 .451-.309.839-.75 1.009V18a1.5 1.5 0 01-1.356 1.493L18 19.5h-3.342c-.154.402-.489.693-.887.742l-.121.008h-3.3c-.451 0-.839-.309-1.009-.75H6a1.5 1.5 0 01-1.493-1.356L4.5 18v-3.341c-.441-.17-.75-.558-.75-1.009v-3.3c0-.451.309-.839.75-1.009V6a1.5 1.5 0 011.356-1.493L6 4.5h3.341c.155-.401.49-.693.888-.742l.121-.008h3.3zM9.342 5.5H6a.5.5 0 00-.492.41L5.5 6v3.342c.442.17.75.557.75 1.008v3.3c0 .45-.308.838-.75 1.008V18a.5.5 0 00.41.492L6 18.5h3.342c.154-.402.489-.693.887-.742l.121-.008h3.3c.451 0 .839.309 1.009.75H18a.5.5 0 00.492-.41L18.5 18v-3.341c-.401-.155-.693-.49-.742-.888l-.008-.121v-3.3c0-.45.308-.838.75-1.008V6a.5.5 0 00-.41-.492L18 5.5h-3.342c-.154.402-.489.693-.887.742l-.121.008h-3.3c-.45 0-.838-.308-1.008-.75z"
    />
  </svg>
)

export default SvgTransform
