import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'
import Icon from '../icons/IconFactory'

import './BuilderOverviewNavBar.scss'

class BuilderOverviewNavBar extends Component {
  render () {

    return (
      <div className={'builder-overview-navBar'}>
        <div className={'builder-overview-navBar-content-header'}>
          <Icon className={'builder-overview-navBar-icon'} name={this.props.icon}/>
          <div className={'builder-overview-text'}>
            <Text>{this.props.text}</Text>
          </div>
        </div>
        <div>
          {this.props.children}
        </div>
      </div>
    )
  }
}

BuilderOverviewNavBar.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
}

export default BuilderOverviewNavBar
