import React, { Component } from 'react'
import CultureLensController from './CultureLensController'
import { updateOrganisationalUnitCulture } from '../../../actions/actions'
import { connect } from 'react-redux'
import MenuSection from '../../../../common/components/MenuSection'
import { getSelectedOrganisationalUnit } from '../../../reducers/organisationalUnits'

class CultureLens extends Component {
  render () {
    return (
      <MenuSection title={'CultureLENS'}>
        <CultureLensController lens={this.props.unit.culture}
                               id={this.props.unit.id}
                               onChange={(culture) => this.props.updateCulture(this.props.unit.buildingId, this.props.unit.floorId, this.props.unit.id, culture)}/>
      </MenuSection>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    unit: getSelectedOrganisationalUnit(state),
  }
}

let mapDispatchToProps = {
  updateCulture: updateOrganisationalUnitCulture,
}

export default connect(mapStateToProps, mapDispatchToProps)(CultureLens)