import React, { Component } from 'react'
import { Popper } from 'react-popper'
import PropTypes from 'prop-types'
import onClickOutSide from 'react-onclickoutside'

import './PlanContextMenu.scss'

class VirtualReference {

  constructor (top, right, bottom, left) {
    this.boundingClientRect = {
      top: top,
      left: left,
      bottom: bottom,
      right: right,
      width: 0,
      height: 0,
    }
  }

  getBoundingClientRect () {
    return this.boundingClientRect
  }

  get clientWidth () {
    return this.getBoundingClientRect().width
  }

  get clientHeight () {
    return this.getBoundingClientRect().height
  }
}

/**
 * Context menu container, that will be positioned relatively to the given coordinates
 * You can add IconButton elements as children in the body of this Component to add items to the context menu
 *
 * Example how to use:
 *
 * <PlanContextMenu x={this.state.myX} y={this.state.myY} show={this.state.showContextMenu} placement={'right'}>
 *    <IconButton icon={'pencil'} inverted activeOnHover={true}/>
 *    <IconButton icon={'delete'} inverted activeOnHover={true}/>
 * </PlanContextMenu>
 *
 *
 *
 */
class PlanContextMenu extends Component {

  // Needed for OnClickOutside HOC
  handleClickOutside (event) {
    if (this.props.onClose && event.button === 0)
      this.props.onClose()
  }

  render () {
    // This creates a virtual reference element to position
    // the tooltip on the given coordinates
    const virtualReferenceElement = this.createVirtualReferenceElement()

    const childElements = React.Children.toArray(this.props.children)
      .map(child => React.cloneElement(child, { className: 'plan-context-menu-item ' + child.props.className }))

    let onMouseEnter = this.props.onMouseEnter ? (event) => this.props.onMouseEnter(event) : () => {}
    let onMouseLeave = this.props.onMouseLeave ? (event) => this.props.onMouseLeave(event) : () => {}

    return (
      <Popper
        placement={this.props.placement ? this.props.placement : 'left'}
        referenceElement={virtualReferenceElement}>
        {({ ref, style, placement, arrowProps }) => {
          const arrowDirectionStyleClass = placement ? ' plan-context-menu-' + placement + '-arrow' : ''

          return (
            <div className={'plan-context-menu'} ref={ref} style={style} data-placement={placement}
                 onContextMenu={e => e.preventDefault()}
                 onMouseEnter={onMouseEnter}
                 onMouseLeave={onMouseLeave}>
              {childElements}
              <div className={'plan-context-menu-arrow' + arrowDirectionStyleClass} ref={arrowProps.ref} style={arrowProps.style}/>
            </div>
          )
        }}
      </Popper>
    )
  }

  createVirtualReferenceElement () {
    const { x, y } = this.props
    return new VirtualReference(y, x, y, x)
  }
}

PlanContextMenu.protoType = {
  x: PropTypes.number,
  y: PropTypes.number,
  show: PropTypes.bool,
  placement: Popper.placements,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
}

export default onClickOutSide(PlanContextMenu)