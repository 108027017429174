import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModalConsumer from '../../common/components/modal/ModalConsumer'
import DropdownMenu from '../../common/components/DropdownMenu'
import { ListMenuItem } from '../../common/components/ListMenuItem'
import ListMenu from '../../common/components/ListMenu'
import { getVariation } from '../../common/redux/variations/variations-reducer'
import VariationsRenameDialog from './VariationsRenameDialog'
import VariationsDeleteDialog from './VariationsDeleteDialog'

class VariationsMenu extends Component {

  render () {

    const header = this.props.variation && this.props.variation.name ? this.props.variation.name : null

    return (
      <DropdownMenu
        onClose={() => this.closeMenu()}
        header={header}
        className={'projects-menu' + (this.props.advancedSettings ? ' dark-mode' : '')}>

        <ModalConsumer>
          {({ showModal }) => (
            <ListMenu className={(this.props.advancedSettings ? ' dark-mode' : '')}>
              <ListMenuItem disabled={!this.props.projectId || !this.props.variation}
                            onClick={() => this.handleOpenRenameDialogClick(showModal)}>
                Rename variation...
              </ListMenuItem>
              <ListMenuItem disabled={!this.props.projectId || !this.props.variation}
                            onClick={() => this.handleOpenDeleteDialogClick(showModal)}>
                Delete variation...
              </ListMenuItem>


            </ListMenu>
          )}
        </ModalConsumer>
      </DropdownMenu>
    )
  }

  closeMenu () {
    this.props.onClose()
  }

  handleOpenModalClick (showModal, component, data) {
    this.closeMenu()
    showModal(component, data)
  }

  handleOpenRenameDialogClick (showModal) {
    let data = { variation: this.props.variation, projectId: this.props.projectId }
    this.handleOpenModalClick(showModal, VariationsRenameDialog, data)
  }

  handleOpenDeleteDialogClick (showModal) {
    let data = { variation: this.props.variation, projectId: this.props.projectId }
    this.handleOpenModalClick(showModal, VariationsDeleteDialog, data)
  }
}

let mapStateToProps = (state, ownProps) => {
  return {
    project: state.projects.project,
    variation: getVariation(state, ownProps.variationId),
    advancedSettings: state.settings.appSettings.advancedSettings,
  }
}

export default connect(mapStateToProps, null)(VariationsMenu)


