import React from 'react'

import { ListMenuItem } from './ListMenuItem'

import './ListMenu.scss'

function EmptyListMessage (props) {
  return (<ListMenuItem key={'none'}>{props.text}</ListMenuItem>)
}

function ListMenu ({ children, className, emptyListMessage }) {

  const styleClass = className ? ' ' + className : ''

  return (
    <ul className={'list-menu' + styleClass}>
      {!children || children.length < 1 ? (emptyListMessage ? <EmptyListMessage text={emptyListMessage}/> : null) : children}
    </ul>
  )
}

export default ListMenu