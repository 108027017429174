import React from 'react'

const SvgDrawPillarActive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
      <linearGradient
        id="prefix__b"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g stroke="#FF5E0F" strokeLinejoin="round">
        <path
          fill="#FF5E0F"
          d="M0 18c0 .552.895 1 2 1s2-.448 2-1V1H0v17z"
          transform="translate(14 5)"
        />
        <path
          fill="url(#prefix__b)"
          d="M2 2c1.105 0 2-.448 2-1s-.895-1-2-1-2 .448-2 1 .895 1 2 1z"
          transform="translate(14 5)"
        />
      </g>
      <path
        stroke="#FF5E0F"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.459 18.065c-4.38.564-7.616 2.552-7.616 4.918 0 2.805 4.547 5.079 10.157 5.079s10.157-2.274 10.157-5.079c0-2.37-3.248-4.361-7.64-4.921"
      />
    </g>
  </svg>

export default SvgDrawPillarActive