import React, { Component } from 'react'
import { connect } from 'react-redux'
import registry from '../notification/NotificationHandlerRegistry'
import './NotificationsContainer.scss'
import Notification from './../components/Notification'
import { resolveNotification } from '../redux/notifications/notifications-actions'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { getNotifications } from '../redux/notifications/notifications-reducer'

/*
* Export NotificationsDisplay presentational component to be able to add this in Storybook
*
* NOTE: Don't use this directly, use NotificationsContainer instead
*/
export function NotificationsDisplay ({ notifications, onDismiss, ...rest }) {
  return (
    <TransitionGroup {...rest} className={'notifications-container'}>
      {notifications
        .map((notification) => {return { notification: notification, component: registry.getNotificationComponent(notification.type) }})
        .map(({ component: Component, notification }) => {
          return (
            <CSSTransition timeout={1000} key={notification.count} classNames={'notifications-item'} className={'notifications-item'}>
              {Component ? <Component notification={notification}/> : <Notification
                onDismiss={onDismiss}
                notification={notification}/>}
            </CSSTransition>
          )
        })}
    </TransitionGroup>
  )
}

class NotificationsContainer extends Component {

  render () {
    return (
      <NotificationsDisplay notifications={this.props.notifications}
                            onDismiss={(notification) => this.props.resolveNotification(notification)}/>
    )
  }

}

let mapStateToProps = (state) => {
  return {
    notifications: getNotifications(state),
  }
}

let mapDispatchToProps = {
  resolveNotification,
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer)