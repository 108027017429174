import floorPlanReducer from './floorPlan'
import floorPlanState from './floorPlanState'
import dropAction from './dropAction'
import placedOrganisationalUnitsReducer from './organisationalUnits'
import uiStateReducer from './uiState'
import buildingsReducer from './buildings'
import stylesReducer from '../../common/redux/styles/styles'
import license from '../../common/reducers/license'
import furnitureEnsembleSummaryReducer from './furnitureEnsembleSummary'
import markersReducer from './markers'
import connectionsReducer from './connections'
import connectablesReducer from './connectables'
import undoDataUpdate from '../../common/reducers/undoReducer'

let reducers = {
  floorPlanState: floorPlanState,
  floorPlanUiState: uiStateReducer,
  floorPlan: floorPlanReducer,
  dropAction: dropAction,
  placedOrganisationalUnits: placedOrganisationalUnitsReducer,
  buildings: buildingsReducer,
  styles: stylesReducer,
  furnitureEnsembleSummary: furnitureEnsembleSummaryReducer,
  markers: markersReducer,
  connections: connectionsReducer,
  connectables: connectablesReducer,
  license: license,
  undoDataUpdate: undoDataUpdate,
}

export default reducers