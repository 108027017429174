import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog, { DialogButtonGroup, DialogContent } from '../../../common/components/Dialog'
import Button from '../../../common/components/Button'
import FileInput from '../../../common/components/FileInput'

class XlsxUploadDialog extends Component {

  constructor (props) {
    super(props)
    this.state = {
      file: null,
    }
  }

  setFile = file => {
    this.setState({ file: file })
  }

  upload = () => {

    if (!this.props.replace) {
      this.props.upload(this.state.file, false)
    } else {
      this.props.upload(this.state.file, true)
    }

    this.close()
  }

  close = () => {
    this.props.onClose()
  }

  render () {

    return (
      <Dialog onCancel={this.close}
              title={'Upload excel file'}
              className={'uploader'}>
        <DialogContent>
          <FileInput name="Upload .xlsx" accept={'.xlsx'} onChange={file => this.setFile(file)}/>
        </DialogContent>
        <DialogButtonGroup>
          <Button onClick={this.close}>Cancel</Button>
          <Button onClick={this.upload} disabled={!this.state.file} variant={'contained'}>Confirm</Button>
        </DialogButtonGroup>
      </Dialog>
    )
  }
}

XlsxUploadDialog.propTypes = {
  upload: PropTypes.func.isRequired,
}

export default XlsxUploadDialog