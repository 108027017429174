import * as React from 'react'

const SvgBuildingIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M15.5 3.75v2h2v14.5h-1V6.75h-9v13.5h-1V5.75h2v-2h7zM14 16.25v4h-1v-3h-2v3h-1v-4h4zm-4-3v1.5H9v-1.5h1zm2.5 0v1.5h-1v-1.5h1zm2.5 0v1.5h-1v-1.5h1zm-5-2.5v1.5H9v-1.5h1zm2.5 0v1.5h-1v-1.5h1zm2.5 0v1.5h-1v-1.5h1zm-5-2.5v1.5H9v-1.5h1zm2.5 0v1.5h-1v-1.5h1zm2.5 0v1.5h-1v-1.5h1zm-.5-3.5h-5v1h5v-1z"
    />
  </svg>
)

export default SvgBuildingIcon
