import React from 'react'

const SvgDrawNavbarNumber2Focus = props =>
  <svg
    viewBox="0 0 20 20"
    width={20} height={20}
    className={props.className}
  >
    <g fill="none" fillRule="evenodd">
      <circle stroke="#FFF" strokeWidth={2} cx={10} cy={10} r={9}/>
      <path
        d="M12.8 13.904v-1.272H9.33l1.2-.96c1.14-.912 2.076-1.764 2.076-3.096 0-1.344-.912-2.376-2.712-2.376-1.668 0-2.784 1.02-2.784 2.604 0 .228.036.468.084.708l1.488.06a2.258 2.258 0 01-.084-.66c0-.876.42-1.452 1.26-1.452.816 0 1.176.504 1.176 1.2 0 .912-.648 1.512-1.824 2.496L7.1 12.896v1.008h5.7z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>

export default SvgDrawNavbarNumber2Focus