import React, { Component } from 'react'
import { Manager, Popper, Reference } from 'react-popper'

import { DropdownButton } from './DropdownButton'
import DropdownMenu from './DropdownMenu'
import ListMenu from './ListMenu'
import { ListMenuItem } from './ListMenuItem'
import { iconTypes } from './icons/IconFactory'

class Dropdown extends Component {

  constructor (props, context) {
    super(props, context)
    this.state = {
      show: false,
    }
  }

  getMenuItems () {
    return this.props.items.map((item, index) => {
      return (
        <ListMenuItem key={index} icon={item.icon} onClick={() => this.handleItemClick(item.id)}>{item.name}</ListMenuItem>
      )
    })
  }

  render () {

    const dropdownMenuStyleClass = 'dropdown-drop-menu' + (this.props.menuStyleClass ? ' ' + this.props.menuStyleClass : '')

    let listMenuItems = this.getMenuItems()

    if (this.props.onClickAddItem) {
      const addItemText = this.props.addItemText ? this.props.addItemText : 'Add item'
      const addIcon = this.props.addIcon ? this.props.addIcon : iconTypes.plus

      listMenuItems.push(<ListMenuItem className={'list-menu-add-item'} key={listMenuItems} icon={addIcon} onClick={() => this.handleAddItem()}>{addItemText}</ListMenuItem>)
    }

    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <div className={'drop-down'} ref={ref}>
              <DropdownButton title={this.props.title}
                              icon={this.props.icon}
                              active={this.state.show}
                              onClick={() => this.handleMenuItemClick()}
                              classeName={this.props.buttonStyleClass}/>
            </div>
          )}
        </Reference>
        {this.state.show &&
        <Popper placement="bottom-start">
          {({ ref, style, placement }) => (
            <div ref={ref} className={dropdownMenuStyleClass} style={{ ...style }} data-placement={placement}>
              <DropdownMenu onClose={() => this.handleClose()}>
                <ListMenu>
                  {listMenuItems}
                </ListMenu>
              </DropdownMenu>
            </div>
          )}
        </Popper>}
      </Manager>
    )
  }

  handleItemClick (id) {
    this.props.onClick(id)
    this.handleClose()
  }

  handleMenuItemClick () {
    let newValue = !this.state.show
    this.setMenuState(newValue)
  }

  handleAddItem () {
    this.props.onClickAddItem()
    this.handleClose()
  }

  handleClose () {
    this.setMenuState(false)
  }

  setMenuState (show) {
    this.setState({
      show: show,
    })
  }

}

export default Dropdown