import { Vector3 } from 'three'

function getCorners (positions) {
  let left = Number.MAX_VALUE
  let right = -Number.MAX_VALUE
  let bottom = Number.MAX_VALUE
  let top = -Number.MAX_VALUE

  positions.forEach(position => {
    if (position.x < left) {
      left = position.x
    }
    if (position.x > right) {
      right = position.x
    }
    if (position.y < bottom) {
      bottom = position.y
    }
    if (position.y > top) {
      top = position.y
    }
  })

  return {
    left: left,
    top: top,
    right: right,
    bottom: bottom,
  }
}

export function getBoundingBox (positions) {
  const { left, top, right, bottom } = getCorners(positions)

  return [
    new Vector3(left, top, 0),
    new Vector3(right, top, 0),
    new Vector3(right, bottom, 0),
    new Vector3(left, bottom, 0),
  ]
}

export function getBoundingBoxByRadius(radius) {
  return [
    new Vector3(-radius, radius, 0),
    new Vector3(radius, radius, 0),
    new Vector3(radius, -radius, 0),
    new Vector3(-radius, -radius, 0),
  ]
}