import {Geometry} from './Geometry'
import {
    addVertex,
    addVertexToEdge,
    getLastEdge,
    getLastVertex,
    getVerticesCount,
    removeLastVertex,
    setDraggedVertex,
    updateVertex
} from './utils/GeometryBasicFunctionsUtils'
import {addAllChildrenToOtherGroup} from '../helper/GroupHelper'

export const polygraphActions = {
    drag: 'drag',

    addVertex: 'addVertex',
    dragVertex: 'dragVertex',
}

export class PolygraphGeometry extends Geometry {
    constructor(parent, representationType) {
        super(parent, representationType, polygraphActions)

        this.addVertex = (position) => addVertex(position, this)
        this.addVertexToEdge = (edge, position) => addVertexToEdge(edge, position, this)
        this.updateVertex = (position, vertex = null) => updateVertex(position, this, vertex)
        this.removeLastVertex = () => removeLastVertex(this)
        this.getLastVertex = () => getLastVertex(this)
        this.getLastEdge = () => getLastEdge(this)
        this.setDraggedVertex = (vertex) => setDraggedVertex(vertex, this)
        this.getVerticesCount = () => getVerticesCount(this)
    }

    extendAtVertex(vertex, position) {
        this.vertexGroup.setVertexOrder(vertex, -1)
        this.addVertex(position)
        this.setActive(true)
        this.setAction(this.actions.addVertex)
    }

    extendAtEdge(edge, position) {
        this.addVertexToEdge(edge, position)
        this.addVertex(position)
        this.setActive(true)
        this.setAction(this.actions.addVertex)
    }

    mergeToOwnVertex(ownVertex, polygraph) {
        polygraph.setActive(false)

        const lastVertexFromOtherPolygraph = polygraph.getLastVertex()
        const lastEdgeFromOtherPolygraph = lastVertexFromOtherPolygraph.getEdges()[0]
        const previousVertexFromOtherPolygraph = lastEdgeFromOtherPolygraph.getVertices().find(v => v !== lastVertexFromOtherPolygraph)

        polygraph.removeLastVertex()

        addAllChildrenToOtherGroup(polygraph.vertexGroup, this.vertexGroup)
        addAllChildrenToOtherGroup(polygraph.edgeGroup, this.edgeGroup)

        this.edgeGroup.addEdge(previousVertexFromOtherPolygraph, ownVertex)
    }

    mergeToOwnEdge(ownEdge, polygraph) {
        polygraph.setActive(false)

        const lastVertexFromOtherPolygraph = polygraph.getLastVertex()
        const lastEdgeFromOtherPolygraph = lastVertexFromOtherPolygraph.getEdges()[0]
        const previousVertexFromOtherPolygraph = lastEdgeFromOtherPolygraph.getVertices().find(v => v !== lastVertexFromOtherPolygraph)

        polygraph.removeLastVertex()

        addAllChildrenToOtherGroup(polygraph.vertexGroup, this.vertexGroup)
        addAllChildrenToOtherGroup(polygraph.edgeGroup, this.edgeGroup)

        const newVertex = this.edgeGroup.addVertexToEdge(ownEdge, lastVertexFromOtherPolygraph.position)
        this.edgeGroup.addEdge(previousVertexFromOtherPolygraph, newVertex)

        this.setActive(false)
    }

    connectLastVertexToOwnVertex(ownVertex) {
        const lastVertex = this.getLastVertex()
        const lastEdge = lastVertex.getEdges()[0]
        const previousVertex = lastEdge.getVertices().find(vertex => vertex !== lastVertex)

        this.removeLastVertex()

        this.edgeGroup.addEdge(previousVertex, ownVertex)

        this.setActive(false)
    }

    connectLastVertexToOwnEdge(ownEdge) {
        const lastVertex = this.getLastVertex()
        const lastEdge = lastVertex.getEdges()[0]
        const previousVertex = lastEdge.getVertices().find(vertex => vertex !== lastVertex)

        this.removeLastVertex()

        const newVertex = this.edgeGroup.addVertexToEdge(ownEdge, lastVertex.position)

        this.edgeGroup.addEdge(previousVertex, newVertex)

        this.setActive(false)
    }

    close() {
        const lastVertex = this.vertexGroup.getVertexByIndex(-1)
        this.edgeGroup.removeEdges(lastVertex.getEdges())
        this.vertexGroup.remove(lastVertex)
        this.setActive(false)
    }
}