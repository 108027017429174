import * as React from 'react'

const SvgEditOutline = props =>
  (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
    >
      <g
        transform="translate(0 .5)"
        stroke="#00293B"
        fill="none"
        fillRule="evenodd"
      >
        <rect width={13} height={11} x={5.5} y={6.5} rx={1}/>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 10.5h2m6.5 0v2m0 3v2m-4-7H18"
        />
        <g strokeLinecap="round" strokeLinejoin="round">
          <path
            fill="#FFF"
            d="M11.904 17.475l8.485-8.486 2.122 2.122-8.486 8.485-2.474.354z"
          />
          <path d="M18.622 10.757l2.121 2.122"/>
        </g>
      </g>
    </svg>
  )

export default SvgEditOutline
