import React from 'react'

const SvgHeatmap = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g
      fill="none"
      fillRule="evenodd"
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 4a1.5 1.5 0 011.5 1.5l.001 5.756A4.502 4.502 0 0112 20a4.5 4.5 0 01-1.5-8.744V5.5A1.5 1.5 0 0112 4z"/>
      <circle cx={12} cy={15.5} r={2.5} fill={props.fillColor}/>
    </g>
  </svg>
)

export default SvgHeatmap
