import React from 'react'

const SvgDrawNavbarNumber4Focus = props =>
  <svg
    viewBox="0 0 20 20"
    width={20} height={20}
    className={props.className}
  >
    <g fill="none" fillRule="evenodd">
      <circle stroke="#FFF" strokeWidth={2} cx={10} cy={10} r={9}/>
      <path
        d="M11.866 13.76v-1.824h1.152v-1.248h-1.152V6.2h-1.812L6.55 10.544v1.392h3.84v1.824h1.476zm-1.476-3.072H7.846L10.39 7.52v3.168z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>

export default SvgDrawNavbarNumber4Focus