import React from 'react'

const SvgHeatmapTeamsActive = props => (
  <svg className={props.className} width={36} height={36}>
    <defs>
      <linearGradient
        id="heatmap-teams-active_svg__a"
        x1="37.3%"
        y1="-30.9%"
        y2="101.7%"
      >
        <stop offset="0%" stopColor="#9089EA"/>
        <stop offset="100%" stopColor="#2F97E4"/>
      </linearGradient>
    </defs>
    <path
      fill="url(#heatmap-teams-active_svg__a)"
      fillRule="evenodd"
      d="M20.95 15.046a1.08 1.08 0 011.076 1.078v5.621a1.08 1.08 0 01-1.076 1.077h-.412v5.1A1.08 1.08 0 0119.461 29h-2.886a1.08 1.08 0 01-1.075-1.078V22.84h-.414a1.08 1.08 0 01-1.075-1.078v-5.639h-.003a1.068 1.068 0 011.078-1.077h5.864zm-8.033-2.892a1.08 1.08 0 011.076 1.078v.431a2.688 2.688 0 00-1.614 2.46v5.639c.004.44.114.87.321 1.258v.251a1.08 1.08 0 01-1.074 1.078H9.367a1.08 1.08 0 01-1.076-1.078v-4.31h-.215A1.068 1.068 0 017 17.883v-4.651a1.08 1.08 0 011.076-1.078h4.841zm15.008-.018A1.08 1.08 0 0129 13.214v4.634a1.08 1.08 0 01-1.076 1.078h-.215v4.31a1.08 1.08 0 01-1.076 1.077h-2.259a1.08 1.08 0 01-1.074-1.078v-.251c.215-.384.327-.817.323-1.257v-5.622a2.687 2.687 0 00-1.614-2.46v-.431a1.08 1.08 0 011.075-1.078h4.841zm-9.917-2.988a2.494 2.494 0 012.492 2.5 2.494 2.494 0 01-2.49 2.5h-.002a2.494 2.494 0 01-2.492-2.5 2.494 2.494 0 012.492-2.5zM10.5 7a2.171 2.171 0 012.17 2.173 2.171 2.171 0 11-3.705-1.536A2.17 2.17 0 0110.5 7zm15.022 0a2.171 2.171 0 012.17 2.173 2.171 2.171 0 11-3.705-1.536A2.17 2.17 0 0125.522 7z"
    />
  </svg>
);

export default SvgHeatmapTeamsActive
