import { httpClient } from '../../../httpClient'
import { getProjectId, projectApi } from '../../../utils'
import { FULFILLED, PENDING, REJECTED } from '../../../globalConstants'
import { getUndoInformation } from '../../undo/undo-actions'

const FETCH_FILE = 'FETCH_FILE'
const FETCH_LAYOUT_STYLES = 'FETCH_LAYOUT_STYLES'
const UPDATE_LAYOUT_STYLES = 'UPDATE_LAYOUT_STYLES'
const UPLOAD_LAYOUT_STYLES = 'UPLOAD_LAYOUT_STYLES'

export const layoutStylesActionTypes = {

  FETCH_FILE: FETCH_FILE,

  FETCH_LAYOUT_STYLES: FETCH_LAYOUT_STYLES,
  FETCH_LAYOUT_STYLES_PENDING: FETCH_LAYOUT_STYLES + PENDING,
  FETCH_LAYOUT_STYLES_FULFILLED: FETCH_LAYOUT_STYLES + FULFILLED,
  FETCH_LAYOUT_STYLES_REJECTED: FETCH_LAYOUT_STYLES + REJECTED,

  UPDATE_LAYOUT_STYLES: UPDATE_LAYOUT_STYLES,
  UPDATE_LAYOUT_STYLES_PENDING: UPDATE_LAYOUT_STYLES + PENDING,
  UPDATE_LAYOUT_STYLES_FULFILLED: UPDATE_LAYOUT_STYLES + FULFILLED,
  UPDATE_LAYOUT_STYLES_REJECTED: UPDATE_LAYOUT_STYLES + REJECTED,

  UPLOAD_LAYOUT_STYLES: UPLOAD_LAYOUT_STYLES,
  UPLOAD_LAYOUT_STYLES_PENDING: UPLOAD_LAYOUT_STYLES + PENDING,
  UPLOAD_LAYOUT_STYLES_FULFILLED: UPLOAD_LAYOUT_STYLES + FULFILLED,
  UPLOAD_LAYOUT_STYLES_REJECTED: UPLOAD_LAYOUT_STYLES + REJECTED,

}

export function fetchLayoutStyles () {
  return {
    type: layoutStylesActionTypes.FETCH_LAYOUT_STYLES,
    payload: httpClient.get(encodeURI(`${projectApi()}/layout-styles`)),
  }

}

//TODO: CHANGE TO PUT or update so it patches
export function updateLayoutStyle (unitId, unit) {
  return (dispatch) => {
    dispatch({
      type: layoutStylesActionTypes.UPDATE_LAYOUT_STYLES,
      payload: httpClient.patch(encodeURI(`${projectApi()}/layout-styles/${unitId}`), unit),
    })
      .then(() => {
        dispatch(fetchLayoutStyles())
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function downloadLayoutStylesFile () {
  return {
    type: layoutStylesActionTypes.FETCH_FILE,
    payload: httpClient.getFile(encodeURI(`${projectApi()}/layout-styles/xlsx`)),
  }
}

export function uploadLayoutStylesFile (file, replace) {
  let form = new FormData()
  form.append('replace', replace)
  form.append('file', file)

  return (dispatch) => {
    dispatch({
      type: UPLOAD_LAYOUT_STYLES,
      payload: httpClient.post(encodeURI(`${projectApi()}/layout-styles/xlsx`), form),
    })
      .then(() => dispatch(fetchLayoutStyles()))
  }
}

