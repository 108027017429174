import React from 'react'

import './NonLockableField.scss'

function NonLockableField ({ children }) {

  return (
    <div className={'non-lockable-field'}>
      {children}
    </div>
  )
}

export default NonLockableField