import * as THREE from 'three'
import { CCD3D } from './classes'
import * as geomath from './GeoMath'

export function keepCoordinatesOnAxis (coordinates, direction, orthogonal = 0) {

  const angle = direction.angle()

  let tempVector = coordinates.rotateAround(new THREE.Vector2(0, 0), -angle)
  if (orthogonal) tempVector.x = 0
  else tempVector.y = 0
  return tempVector.rotateAround(new THREE.Vector2(0, 0), angle)

}

export function drawFaces (list, material, height = 0) {
  let geometry = new THREE.Geometry()
  let counter = 0

  for (let i = 0; i < list.length; i++) {
    geometry.vertices.push(new THREE.Vector3(list[i][0], height, -list[i][1]))
    geometry.vertices.push(new THREE.Vector3(list[i][2], height, -list[i][3]))
    geometry.vertices.push(new THREE.Vector3(list[i][4], height, -list[i][5]))

    let p1 = new THREE.Vector2(list[i][0], -(list[i][1]))
    let p2 = new THREE.Vector2(list[i][2], -(list[i][3]))
    let p3 = new THREE.Vector2(list[i][4], -(list[i][5]))

    geometry.faceVertexUvs[0][i] = [p1, p2, p3]

    geometry.uvsNeedUpdate = true

    let face = new THREE.Face3(counter, counter + 1, counter + 2)

    counter += 3

    geometry.faces.push(face)
  }

  let mesh = new THREE.Mesh(geometry, material)
  mesh.uvsNeedUpdate = true
  mesh.castShadow = true
  return mesh
}

export function drawFacesGeometryOnly (list) {
  let faces = drawFaces(list, new THREE.MeshBasicMaterial({ color: 0x000000 }))
  return faces.geometry
}

export function drawPolygons (list, fillMaterial, lineMaterial, closeShape) {

  let sections = new CCD3D() //THREE.Object3D();
  for (let i = 1; i < list.length; i++) {
    let shape, border
    if (fillMaterial != null) shape = new THREE.Shape()
    if (lineMaterial != null) border = new THREE.Geometry()

    if (fillMaterial != null) shape.moveTo(list[i][0], list[i][1])
    if (lineMaterial != null) border.vertices.push(new THREE.Vector3(list[i][0], list[i][1], 0))

    for (let j = 2; j < list[i].length; j += 2) {
      if (fillMaterial != null) shape.lineTo(list[i][j], list[i][j + 1])
      if (lineMaterial != null) border.vertices.push(new THREE.Vector3(list[i][j], list[i][j + 1], 0))

    }

    //if (closeShape) shape.lineTo(list[i][0], list[i][1]);

    if (fillMaterial != null) {
      let geometry = new THREE.ShapeGeometry(shape)
      let mesh = new THREE.Mesh(geometry, fillMaterial)
      //let mesh = new CCD3D(geometry, fillMaterial);
      sections.add(mesh)
      //this.departments.add(mesh);
    }
    if (lineMaterial != null) {
      let pgeometry = new THREE.Geometry()
      pgeometry.setFromPoints(border.vertices)
      sections.add(new THREE.Line(pgeometry, lineMaterial))
    }

  }
  sections.aname = 'DepartmentName'
  let rotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2)
  sections.applyMatrix4(rotation)
  return sections
}

//Output: Geometry; Input List<List<Double>> (where List<Double> represents x & y Coords)
export function createPolygonGeometryFromDoublePairList (list) {
  let outgeo = new THREE.Geometry()
  let rotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2)
  let shape = new THREE.Shape()

  shape.moveTo(list[0][0], list[0][1])
  for (let i = 1; i < list.length; i++) {

    shape.lineTo(list[i][0], list[i][1])
    let geometry = new THREE.ShapeGeometry(shape)
    outgeo.merge(geometry, rotation)
  }
  return outgeo
}

function mergeToOneList (array) {
  let out = []
  for (let i = 0; i < array.length; i++) {
    out.push(array[i][0])
    out.push(array[i][1])
  }
  return out
}

export function createShapeFromList (shapePointList, offset = 0, offsetY = 0, autoOffset = false, offsetSize = 0.01) {
  // Returns THREE Shape from Poly List
  // Create the Output Geometry and rotation
  let outgeo = new THREE.Geometry()
  let rotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2)

  //Loop the Poly List

  for (let i = 0; i < shapePointList.length; i++) {
    let shape = new THREE.Shape()
    let pointList = shapePointList[i]
    if (offset !== 0) pointList = mergeToOneList(geomath.GeoMath_getOffsetPolygon(shapePointList[i], offset))

    shape.moveTo(pointList[0], pointList[1])

    for (let j = 2; j < pointList.length; j += 2) {
      shape.lineTo(pointList[j], pointList[j + 1])
    }

    let geometry = new THREE.ShapeGeometry(shape)
    if (autoOffset) {
      geometry.translate(0, 0, offsetSize * (i + 1))
    }
    outgeo.merge(geometry, rotation)
  }

  return outgeo
}

export function mergeShape (shapeList, offsetX = 0, offsetY = 0) {

  let outGeo = new THREE.Geometry()
  let rotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2)

  shapeList.forEach((shape) => {
    let geometry = new THREE.ShapeGeometry(shape)
    outGeo.merge(geometry, rotation)
  })

  return outGeo
}

export function createShape (pointList, offsetX = 0, offsetY = 0, zDirection = 1) {
  // Returns THREE Shape from Poly List
  // Create the Output Geometry and rotation
  let outgeo = new THREE.Geometry()
  let rotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2)

  //Loop the Poly List

  let shape = new THREE.Shape()
  shape.moveTo(pointList[0], pointList[1] * zDirection)

  for (let j = 2; j < pointList.length; j += 2) {
    shape.lineTo(pointList[j], pointList[j + 1] * zDirection)
  }

  let geometry = new THREE.ShapeGeometry(shape)
  outgeo.merge(geometry, rotation)

  return new THREE.BufferGeometry().fromGeometry(outgeo)
}

export function createRoundRectangle (pointList, radius = .2) {
  // Returns THREE Shape from Poly List
  // Create the Output Geometry and rotation
  let outgeo = new THREE.Geometry()
  let rotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2)

  if (pointList.length !== 8)
    return outgeo

  //Loop the Poly List

  let upperPart = new THREE.Shape()
  let lowerPart = new THREE.Shape()
  let middlePart = new THREE.Shape()

  upperPart.moveTo(pointList[0], pointList[1])
  lowerPart.moveTo(pointList[0], pointList[1])
  middlePart.moveTo(pointList[0], pointList[1] - radius)

  upperPart.absarc(pointList[0] + radius, pointList[1] - radius, radius, Math.PI / 2, Math.PI)
  upperPart.absarc(pointList[2] - radius, pointList[3] - radius, radius, 0, Math.PI / 2)

  lowerPart.absarc(pointList[4] - radius, pointList[5] + radius, radius, -Math.PI / 2, 0)
  lowerPart.absarc(pointList[6] + radius, pointList[7] + radius, radius, Math.PI, -Math.PI / 2)

  middlePart.lineTo(pointList[2], pointList[3] - radius)
  middlePart.lineTo(pointList[4], pointList[5] + radius)
  middlePart.lineTo(pointList[6], pointList[7] + radius)

  let geometry = new THREE.ShapeGeometry(upperPart)
  let geometry2 = new THREE.ShapeGeometry(lowerPart)
  let geometry3 = new THREE.ShapeGeometry(middlePart)
  outgeo.merge(geometry, rotation)
  outgeo.merge(geometry2, rotation)
  outgeo.merge(geometry3, rotation)

  return new THREE.BufferGeometry().fromGeometry(outgeo)
}


/*
Structure:
[
  [1,2,3,4],
  [1,2,3,4]
  .....
]
 */
export function createLinesFromLineList (list, buffer) {
  let shape = new THREE.Geometry()
  //Loop the Poly List
  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < list[i].length; j += 1) {

      shape.vertices.push(new THREE.Vector3(list[i][j][0], 0.01, -list[i][j][1]))
      shape.vertices.push(new THREE.Vector3(list[i][j][2], 0.01, -list[i][j][3]))

    }

  }
  let geometry = new THREE.Geometry()

  geometry.setFromPoints(shape.vertices)
  if (buffer) {
    return new THREE.BufferGeometry().fromGeometry(geometry)
  } else {
    return geometry
  }
}

export function shiftPointsFromList (list, offset) {

  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < list[i].length; j += 2) {
      list[i][j] += offset.x
      list[i][j + 1] += offset.z

    }

  }
}

export function getCenterOffsetFromList (list, list2) {
  // Calculate a Vector for Moving Points of the list
  let minx = 1000
  let maxx = -1000
  let minz = 1000
  let maxz = -1000

  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < list[i].length; j += 2) {
      if (list[i][j] > maxx) maxx = list[i][j]
      if (list[i][j] < minx) minx = list[i][j]
      if (list[i][j + 1] > maxz) maxz = list[i][j + 1]
      if (list[i][j + 1] < minz) minz = list[i][j + 1]

    }
  }
  for (let i = 0; i < list2.length; i++) {
    for (let j = 0; j < list2[i].length; j += 2) {
      if (list2[i][j] > maxx) maxx = list2[i][j]
      if (list2[i][j] < minx) minx = list2[i][j]
      if (list2[i][j + 1] > maxz) maxz = list2[i][j + 1]
      if (list2[i][j + 1] < minz) minz = list2[i][j + 1]

    }
  }

  let lx = (maxx - minx) / 2
  let lz = (maxz - minz) / 2
  let diffx = lx - maxx
  let diffz = lz - maxz
  return new THREE.Vector3(diffx, 0, diffz)
}

export function createBufferLinesFromList (list, autoShift = false, offsetSize = 0.01, beginOffsetAt = 0, autoClosePolygon = true) {
  /*
  Creating the Lines:
   Build a Geometry with the Points
   Set Geometry from Points
   create Line Object
   */

  let geometry = new THREE.BufferGeometry()
  let positions = []
  let heightShift = 0.01
  if (autoShift) heightShift = 0

  for (let i = 0; i < list.length; i++) {
    if (autoShift) {
      heightShift = ((i + 1 + beginOffsetAt) * offsetSize + 0.001)
    }

    if (autoClosePolygon) {

      for (let j = 0; j < list[i].length; j += 2) {
        positions.push(list[i][j], heightShift, -list[i][j + 1])
        if (j < list[i].length - 2) {
          positions.push(list[i][j + 2], heightShift, -list[i][j + 3])
        } else {
          positions.push(list[i][0], heightShift, -list[i][1])
        }

      }
    } else {
      for (let j = 0; j < list[i].length - 2; j += 2) {
        positions.push(list[i][j], heightShift, -list[i][j + 1])
        positions.push(list[i][j + 2], heightShift, -list[i][j + 3])
      }
    }

  }

  geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3))

  return geometry
}

export function createLinesFromList (list) {
  /*
  Creating the Lines:
   Build a Geometry with the Points
   Set Geometry from Points
   create Line Object
   */

  let shape = new THREE.Geometry()
  //Loop the Poly List
  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < list[i].length; j += 2) {
      shape.vertices.push(new THREE.Vector3(list[i][j], 0.01, -list[i][j + 1]))
      if (j < list[i].length - 2) {
        shape.vertices.push(new THREE.Vector3(list[i][j + 2], 0.01, -list[i][j + 3]))
      } else {
        shape.vertices.push(new THREE.Vector3(list[i][0], 0.01, -list[i][1]))
      }

    }

  }
  let geometry = new THREE.Geometry()

  geometry.setFromPoints(shape.vertices)

  return geometry
}

export function createSplineFromLineList (list, thickness, segments) {
  let vertices = []

  //Loop the Poly List

  for (let i = 0; i < list.length; i++) {
    vertices.push(new THREE.Vector3(list[i][0], 0.01, -list[i][1]))
  }

  //Draw Cylindes on Vertices
  let single = new THREE.Geometry()
  let m = new THREE.Matrix4()

  let path = new THREE.CatmullRomCurve3(vertices, true)
  let tube = new THREE.TubeGeometry(path, segments, thickness)

  single.merge(tube, m)

  //let tm = new THREE.Mesh(tube, new THREE.MeshBasicMaterial({color:0x90ff00}))

  return single
}

export function createShapeFromeLineList (list, thickness) {

  let vertices = []

  //Loop the Poly List

  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < list[i].length; j += 2) {
      vertices.push(new THREE.Vector3(list[i][j], 0.01, -list[i][j + 1]))
      if (j < list[i].length - 2) {
        vertices.push(new THREE.Vector3(list[i][j + 2], 0.01, -list[i][j + 3]))
      } else {
        vertices.push(new THREE.Vector3(list[i][0], 0.01, -list[i][1]))
      }
    }
  }

  //Draw Cylindes on Vertices
  let single = new THREE.Geometry()
  let m = new THREE.Matrix4()
  for (let i = 0; i < vertices.length; i += 2) {
    let path = new THREE.CatmullRomCurve3([vertices[i], vertices[i + 1]])
    let tube = new THREE.TubeGeometry(path, 4, thickness)

    single.merge(tube, m)

    //let tm = new THREE.Mesh(tube, new THREE.MeshBasicMaterial({color:0x90ff00}))

  }
  return single
}

export function createConvexGeometryFromLineList (list, thickness) {

  let vertices = []

  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < list[i].length; j += 2) {
      vertices.push(new THREE.Vector3(list[i][j], 0.01, -list[i][j + 1]))
      if (j < list[i].length - 2)
        vertices.push(new THREE.Vector3(list[i][j + 2], 0.01, -list[i][j + 3]))
      else
        vertices.push(new THREE.Vector3(list[i][0], 0.01, -list[i][1]))
    }
  }

  //return new ConvexHull(vertices)

}

export function createLinesGroupedFromList (list, material) {
  /*
  Creating the Lines:
   Build a Geometry with the Points
   Set Geometry from Points
   create Line Object
   */

  let lines = new CCD3D()
  //Loop the Poly List

  for (let i = 0; i < list.length; i++) {
    let shape = new THREE.Geometry()
    for (let j = 0; j < list[i].length; j += 2) {
      shape.vertices.push(new THREE.Vector3(list[i][j], 0.01, -list[i][j + 1]))
      shape.setFromPoints(shape.vertices)
      lines.add(new THREE.Line(shape, material))

    }

  }
  //let geometry = new THREE.Geometry();
  //geometry.setFromPoints(shape.vertices);
  return lines
}

export function makePolygonsFromPolys (inner, outer) {
  var result = []
  if (inner.length === outer.length)
    for (var i = 0; i < outer.length; i++)
      result = result.concat(makePolygonsFromPoly(inner[i], outer[i]))
  return result
}

export function makePolygonsFromPoly (inner, outer) {
  var result = []
  if (inner.length === outer.length)
    for (var i = 0; i < outer.length; i += geomath.getSteps()) {
      var j = i + geomath.getSteps()
      if (j >= outer.length) j = 0
      result.push([
        outer[i], outer[i + 1],
        outer[j], outer[j + 1],
        inner[j], inner[j + 1],
        inner[i], inner[i + 1]])
    }
  return result
}

function makeLineFromQuad (quad, factor) {
  if (quad.length === (4 * geomath.getSteps())) {
    let v0 = geomath.GeoMath_get(quad, 0)
    let v1 = geomath.GeoMath_get(quad, 1)
    let v2 = geomath.GeoMath_get(quad, 2)
    let v3 = geomath.GeoMath_get(quad, 3)
    let vA = geomath.GeoMath_getAddition(v1, geomath.GeoMath_getScaled(geomath.GeoMath_getSubtraction(v2, v1), factor))
    let vB = geomath.GeoMath_getAddition(v0, geomath.GeoMath_getScaled(geomath.GeoMath_getSubtraction(v3, v0), factor))
    return [vA[0], vA[1], vB[0], vB[1]]
  }
  return []
}

export function makeLinesFromQuads (quads, factor) {
  let result = []
  for (let i = 0; i < quads.length; i++)
    result.push(makeLineFromQuad(quads[i], factor))
  return result
}

export function getWhiterColor (col, whitePercent) {
  let newCol = new THREE.Color()
  newCol.red = col.red + ((1.0 - col.red) * whitePercent)
  newCol.green = col.green + ((1.0 - col.green) * whitePercent)
  newCol.blue = col.blue + ((1.0 - col.blue) * whitePercent)
  return newCol
}

export function findNearest (pointArray, targetObjects) {
  //find nearest Anchor
  let adist = 10000
  let result = []

  for (let q = 0; q < pointArray.length; q++) {

    for (let p = 0; p < targetObjects.length; p++) {

      let position = new THREE.Vector3()
      position.setFromMatrixPosition(targetObjects[p].matrixWorld)
      if (pointArray[q].distanceTo(position) < adist) {
        adist = pointArray[q].distanceTo(position)
        result = []
        result.push(targetObjects[p])
      } else if (pointArray[q].distanceTo(position) === adist) {
        //if (result.indexOf(targetObjects[p]) < 0)
        result.push(targetObjects[p])
      }

    }
  }
  return result
}

export function createShapeFromeLineListThickness (list, thickness) {

  let vertices = []

  //Loop the Poly List

  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < list[i].length; j += 2) {
      vertices.push(new THREE.Vector3(list[i][j], 0.01, -list[i][j + 1]))
      if (j < list[i].length - 2) {
        vertices.push(new THREE.Vector3(list[i][j + 2], 0.01, -list[i][j + 3]))
      } else {
        vertices.push(new THREE.Vector3(list[i][0], 0.01, -list[i][1]))
      }

    }

  }

  //Draw Cylindes on Vertices
  let single = new THREE.Geometry()
  let m = new THREE.Matrix4()
  for (let i = 0; i < vertices.length; i += 2) {
    let path = new THREE.CatmullRomCurve3([vertices[i], vertices[i + 1]])
    let tube = new THREE.TubeGeometry(path, 4, thickness)

    single.merge(tube, m)

    //let tm = new THREE.Mesh(tube, new THREE.MeshBasicMaterial({color:0x90ff00}))

  }
  return single
}

export function createRectMapping (object) {

  let min = new THREE.Vector3(9000000, 0, 9000000)
  let max = new THREE.Vector3(-9000000, 0, -9000000)

  // get size
  for (let i = 0; i < object.geometry.vertices.length; i++) {
    let p = object.geometry.vertices[i]

    if (p.x < min.x) min.x = p.x
    if (p.z < min.z) min.z = p.z
    if (p.x > max.x) max.x = p.x
    if (p.z > max.z) max.z = p.z
  }

  let width = max.x - min.x
  let depth = max.z - min.z

  for (let i = 0; i < object.geometry.faceVertexUvs.length; i++) {
    for (let k = 0; k < object.geometry.faceVertexUvs[i].length; k++) {

      let point1 = object.geometry.faceVertexUvs[i][k][0]
      let point2 = object.geometry.faceVertexUvs[i][k][1]
      let point3 = object.geometry.faceVertexUvs[i][k][2]
      let a = object.geometry.faces[k].a
      let b = object.geometry.faces[k].b
      let c = object.geometry.faces[k].c
      let va = object.geometry.vertices[a]
      let vb = object.geometry.vertices[b]
      let vc = object.geometry.vertices[c]

      let scalex = 1 / width
      let scalez = 1 / depth

      point1.set(va.x * scalex - min.x * scalex, -va.z * scalez + min.z * scalez)
      point2.set(vb.x * scalex - min.x * scalex, -vb.z * scalez + min.z * scalez)
      point3.set(vc.x * scalex - min.x * scalex, -vc.z * scalez + min.z * scalez)

    }
  }
  object.geometry.uvsNeedUpdate = true
}