import React from 'react'

const SvgCheckIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g
      fill="none"
      fillRule="evenodd"
      stroke={props.color}
      transform="translate(6 6)"
    >
      <circle cx={6} cy={6} r={6}/>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.75 6.417l1.918 1.716L8.225 3.5"
      />
    </g>
  </svg>
)

export default SvgCheckIcon