import React from 'react'

const SvgUndoIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#383B52"
        fillRule="nonzero"
        d="M9 14.214L4.286 9.5 9 4.786z"
      />
      <path
        stroke="#393B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.5 18.5h1a4.5 4.5 0 100-9H8"
      />
    </g>
  </svg>
)

export default SvgUndoIcon
