import * as React from 'react'

const SvgDrawWindowInactive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke={props.color}
        strokeLinejoin="round"
        d="M6 25.505c0-.279.225-.505.494-.505h19.012c.273 0 .494.214.494.505v.99a.502.502 0 01-.494.505H6.494A.492.492 0 016 26.495v-.99zm2-1.013c0 .28.226.508.496.508h15.008a.502.502 0 00.496-.508V6.508A.505.505 0 0023.504 6H8.496A.502.502 0 008 6.508v17.984zM16 6v19m8.5-9.5h-17"
      />
      <path
        fill="#ff5e0f"
        d="M13.569 7.09l.052.039a.247.247 0 01-.005.359l-3.878 3.878c-.1.1-.265.1-.36.005a.248.248 0 01.006-.359l3.878-3.878a.261.261 0 01.307-.045zm-1.366.047l.052.04a.251.251 0 010 .353L9.78 10.005a.251.251 0 01-.353 0 .253.253 0 010-.354L11.9 7.177a.255.255 0 01.302-.04z"
      />
    </g>
  </svg>

export default SvgDrawWindowInactive