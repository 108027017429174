import { Vector2 } from 'three'
import { subtractVector } from '../common/VectorConverter'
import { Vertex } from '../geometries/VertexFactory'

export function isPolygonClockwise (vertices) {
  let result = 0

  for (let i = 0; i < vertices.length - 1; i++) {
    result += isPolygonClockwiseHelper(vertices[i], vertices[i + 1])
  }

  result += isPolygonClockwiseHelper(vertices[vertices.length - 1], vertices[0])

  return result >= 0
}

function isPolygonClockwiseHelper (startVertex, endVertex) {
  return (endVertex.x - startVertex.x) * (endVertex.y + startVertex.y)
}

export function getVerticesByWidthAndHeight (width, height) {
  const vertices = []

  vertices.push({ x: -width / 2, y: height / 2 })
  vertices.push({ x: width / 2, y: height / 2 })
  vertices.push({ x: width / 2, y: -height / 2 })
  vertices.push({ x: -width / 2, y: -height / 2 })

  return vertices
}

export function getVerticesByRadiusAndSegments (radius, segments, startAngle = 0, offset = {x: 0, y: 0}) {
  const vertices = []
  if (segments < 3) {
    segments = 3
  }

  const angleSteps = 2 * Math.PI / segments
  startAngle *= (Math.PI / 180)

  for (let i = 0; i < segments; i++) {
    const angle = i * angleSteps
    vertices.push(new Vector2(
      radius * Math.cos(angle + startAngle) + offset.x,
      radius * Math.sin(angle + startAngle) + offset.y,
    ))
  }

  return vertices
}

export function isRectangleContainsVertex (leftTop, rightBottom, vertex) {
  return leftTop.x <= vertex.x && vertex.x <= rightBottom.x &&
    rightBottom.y <= vertex.y && vertex.y <= leftTop.y
}

export function isVerticesContainsVertex (vertices, point) {
  //console.log("V: "+vertices+"  P:"+point)
  if (!vertices || !point){
    //console.log("Doesnt exist")
    return false
  }

  let verticesCount = vertices.length
  let intersections = 0

  let x = point.x
  let y = point.y

  //console.log("X:"+x+" Y:"+y)
  for (let i = 0; i < verticesCount; i++) {
    let x1 = vertices[i].x
    let x2 = vertices[(i + 1) % verticesCount].x
    let y1 = vertices[i].y
    let y2 = vertices[(i + 1) % verticesCount].y

    if (y < y1 !== y < y2 &&
      x < (x2 - x1) * (y - y1) / (y2 - y1) + x1) {
    //if ((y < y1) !== (y < y2) &&
    //  x < ((x2 - x1) * (y - y1)) / ((y2 - y1) + x1)) {
      intersections++
    }
    //console.log("intersections: "+intersections)
  }

  return intersections % 2 !== 0

}

export function isVerticesContainsAllVertices (outerVertices, innerVertices) {
  //console.log("outer: "+outerVertices.length)


  let containingVertices=0;
  for (let i=0; i<innerVertices.length; i++){
    let vertex=innerVertices[i]
    //console.log("V:"+vertex.position)
    //console.log("PosX:"+vertex.x)
    //let position=vertex.position
    if(isVerticesContainsVertex(outerVertices, vertex)) {
      containingVertices++
    }
  }

  //console.log("inner: "+innerVertices.length+"  containgVertices: "+containingVertices)
  return containingVertices === innerVertices.length

    /*

    +innerVertices.filter(vertex => isVerticesContainsVertex(outerVertices, vertex)).length)

  return innerVertices.filter(vertex => isVerticesContainsVertex(outerVertices, vertex)).length === innerVertices.length
  */
}


export function isEdgesIntersection (edge_0, edge_1) {
  const vertices_0 = edge_0.getVertices()
  const vertices_1 = edge_1.getVertices()

  const startVertex_0 = vertices_0[0]
  const endVertex_0 = vertices_0[1]
  const startVertex_1 = vertices_1[0]
  const endVertex_1 = vertices_1[1]

  return isLinesIntersecting(startVertex_0.position.x, startVertex_0.position.y,
    endVertex_0.position.x, endVertex_0.position.y,
    startVertex_1.position.x, startVertex_1.position.y,
    endVertex_1.position.x, endVertex_1.position.y)
}

export function isLinesIntersecting (x1_1, y1_1, x1_2, y1_2, x2_1, y2_1, x2_2, y2_2) {
  const uA = ((x2_2 - x2_1) * (y1_1 - y2_1) - (y2_2 - y2_1) * (x1_1 - x2_1)) / ((y2_2 - y2_1) * (x1_2 - x1_1) - (x2_2 - x2_1) * (y1_2 - y1_1))
  const uB = ((x1_2 - x1_1) * (y1_1 - y2_1) - (y1_2 - y1_1) * (x1_1 - x2_1)) / ((y2_2 - y2_1) * (x1_2 - x1_1) - (x2_2 - x2_1) * (y1_2 - y1_1))

  return 0 <= uA && uA <= 1 && 0 <= uB && uB <= 1
}

export function getEdgeIntersection (edge_0, edge_1) {
  const vertices_0 = edge_0.getVertices()
  const vertices_1 = edge_1.getVertices()

  const startVertex_0 = vertices_0[0]
  const endVertex_0 = vertices_0[1]
  const startVertex_1 = vertices_1[0]
  const endVertex_1 = vertices_1[1]

  return getLinesIntersection(startVertex_0.position.x, startVertex_0.position.y,
    endVertex_0.position.x, endVertex_0.position.y,
    startVertex_1.position.x, startVertex_1.position.y,
    endVertex_1.position.x, endVertex_1.position.y)
}

export function getAngle (positionA, positionB) {
  return subtractVector(positionA, positionB)
    .angle()
}

export function getLinesIntersection (x1_1, y1_1, x1_2, y1_2, x2_1, y2_1, x2_2, y2_2) {
  const l1_start = new Vector2(x1_1, y1_1)
  const l1_end = new Vector2(x1_2, y1_2)
  const l2_start = new Vector2(x2_1, y2_1)
  const l2_end = new Vector2(x2_2, y2_2)

  const l1_dir = (l1_end.clone()
    .sub(l1_start)).normalize()
  const l2_dir = (l2_end.clone()
    .sub(l2_start)).normalize()

  const l1_normal = new Vector2(-l1_dir.y, l1_dir.x)
  const l2_normal = new Vector2(-l2_dir.y, l2_dir.x)

  if (isParallel(l1_normal, l2_normal)) {
    return null
  }

  if (isOrthogonal((l1_start.clone()
    .sub(l2_start)), l1_normal)) {
    return null
  }

  const a = l1_normal.x
  const b = l1_normal.y

  const c = l2_normal.x
  const d = l2_normal.y

  const k1 = (a * l1_start.x) + (b * l1_start.y)
  const k2 = (c * l2_start.x) + (d * l2_start.y)

  const x_intersect = (d * k1 - b * k2) / (a * d - b * c)
  const y_intersect = (-c * k1 + a * k2) / (a * d - b * c)

  const intersectPoint = new Vector2(x_intersect, y_intersect)

  if (isBetween(l1_start, l1_end, intersectPoint) && isBetween(l2_start, l2_end, intersectPoint)) {
    return intersectPoint
  }

  return null
}

export function vector3ToVector2(v3){
  return new Vector2(v3.x,v3.y)
}
export function vector2ToVector3(v2){
  return new Vector2(v2.x,v2.y,0)
}
export function isParallel (v1, v2) {
  const angle = Math.atan2(v2.y - v1.y, v2.x - v1.x) * (180 / Math.PI)
  return angle === 0 || angle === 180
}

export function isParallelBidirectional (v1, v2) {
  return Math.abs(v1.angle() % Math.PI - v2.angle() % Math.PI) < 0.001
}

export function isOrthogonal (v1, v2) {
  return Math.abs(v1.clone()
    .dot(v2)) < 0.00001
}

export function isPointBetween (positionA, positionB, point) {
  //console.log("nearest point to line")
  if (getNearestPointToLine(positionA, positionB, point)
    .distanceTo(point) > 0.001)
    return false

  return isBetween(positionA, positionB, point)
}

export function isBetween (a, b, c) {
  const ab = (b.clone()
    .sub(a))
  const ac = (c.clone()
    .sub(a))

  return ab.clone()
    .dot(ac) > 0 && ab.lengthSq() >= ac.lengthSq()
}

export function findNearestVertices (vertex, vertices) {
  const startPosition = vertex.position
  vertices = vertices.sort((v0, v1) =>
    startPosition.distanceToSquared(v0.position) < startPosition.distanceToSquared(v1.position) ? -1 : 1)
  return vertices.filter(v => v !== vertex)
}

export function isLinesOverlapping (edgeA, edgeB) {
  const verticesA = edgeA.getVertices()
  const verticesB = edgeB.getVertices()

  const directionA = subtractVector(verticesA[0].position, verticesA[1].position)
    .normalize()
  const directionB = subtractVector(verticesB[0].position, verticesB[1].position)
    .normalize()

  if (!isParallelBidirectional(directionA, directionB)) {
    return false
  }

  const startPositionA = verticesA[0].position
  const endPositionA = verticesA[1].position
  const startPositionB = verticesB[0].position
  const endPositionB = verticesB[1].position

  if (getNearestPointToLine(startPositionA, endPositionA, startPositionB)
    .distanceTo(startPositionB) > 0.001)
    return false

  return isBetween(startPositionA, endPositionA, startPositionB) ||
    isBetween(startPositionA, endPositionA, endPositionB) ||
    isBetween(endPositionA, startPositionA, startPositionB) ||
    isBetween(endPositionA, startPositionA, endPositionB)
}

export function getNearestPointToLine (startPosition, endPosition, position) {
  const { x: startX, y: startY } = startPosition
  const { x: endX, y: endY } = endPosition

  const ap = new Vector2(position.x - startX, position.y - startY)
  const ab = new Vector2(endX - startX, endY - startY)

  const length = ab.length()

  let distance = ap.dot(ab) / length

  ab.normalize()

  return new Vector2(startX + ab.x * distance, startY + ab.y * distance)
}

export function getAreaWithSign(positions) {
  let area = 0

  let j = positions.length - 1
  for (let i = 0; i < positions.length; i++) {
    area += (positions[j].x + positions[i].x) * (positions[j].y - positions[i].y)
    j = i
  }

  return area / 2
}

export function getArea(positions) {
  let area = 0

  let j = positions.length - 1
  //console.log("pl:"+(j+1))

  for (let i = 0; i < positions.length; i++) {
    area += (positions[j].x + positions[i].x) * (positions[j].y - positions[i].y)
    j = i
  }

  return Math.abs(area / 2)
}
export function getAreaWithHoles(positions, holes) {
  let area = getArea(positions)
  holes.map(hole => getArea(hole))
    .forEach(holeArea => area -= holeArea)

  return area
}

export function getWithOffset(positions, offset){
    let signedArea= getAreaWithSign(positions)

    let multiplicator = -1.0;
    if(signedArea<0) multiplicator=1.0

    let newPositions = []
    let k=0
    let j=0
    let l=positions.length
    for(let i=0; i<l; i++){
        j=i+1
        if(j>=l) j=0;
        k=j+1;
        if(k>=l) k=0;
        //console.log("i:"+i+" j:"+j+" k:"+k+" o:"+offset+" m:"+multiplicator)
        let bsVector = getOffsetBisector(positions[k],positions[j],positions[i]
          , offset*multiplicator)
        //console.log("bsX:"+bsVector.x+" bsY:"+bsVector.y+" l:"+bsVector.length())
        let newPosition = new Vector2(positions[j].x +bsVector.x,positions[j].y +bsVector.y)

        newPositions.push(newPosition)
    }
    //console.log("signed area:"+signedArea)
    //console.log("offset area:"+getArea(newPositions))

    return newPositions
}

export function getOffsetBisector(prePos ,cPos ,nextPos , offset){
    if(prePos == null)
        return getPerp(getWithLength(getSubtraction(nextPos,cPos),offset))
    if(nextPos == null)
        return getPerp(getWithLength(getSubtraction(cPos,prePos),offset))

    //console.log("PPx:"+prePos.x+"PPy:"+prePos.y)
    //console.log("CPx:"+cPos.x+"CPy:"+cPos.y)
    //console.log("NPx:"+nextPos.x+"NPy:"+nextPos.y)

    let vPrev = getPerp(getWithLength(getSubtraction(cPos, prePos), offset));
    let vNext = getPerp(getWithLength(getSubtraction(nextPos, cPos), offset));
    //console.log("vPrev"+vPrev.x+"::"+vPrev.y)
    //console.log("vNext"+vNext.x+"::"+vNext.y)

    /*
    let vPrevSub=getSubtraction(cPos,prePos)
    console.log("vPrevSub:"+vPrevSub.x+"::"+vPrevSub.y)
    if(vPrevSub!=null){
        let vPrevl =  getWithLength(vPrevSub,offset)
        console.log("vPrevl:"+vPrevl.x+"::"+vPrevl.y)
    }
    */
    let pRes = new Vector2((vPrev.x + vNext.x) * 0.5, (vPrev.y + vNext.y) * 0.5);

    let deltaResToPrev = pRes.distanceTo(vNext)  //??
    let deltaResToO = pRes.length()

    //console.log("pres:"+pRes.x+"::"+pRes.y)
    //console.log("drp:"+deltaResToPrev+" drd:"+deltaResToO)
    if ((deltaResToPrev > 0.0000001) && (deltaResToO > 0.00000001)) {

        let resLength = (offset * offset) / deltaResToO;

        pRes = getWithLength(pRes, resLength);
    }
    //console.log("pres:"+pRes.x+"::"+pRes.y)

    //let pRes=new Vector2(0,0)
    return pRes;
}

export function geometryToPositionArray(geometry){
  let posArray=[]
  for(let i=0; i<geometry.getVertices().length; i++){
    posArray.push(geometry.getVertices()[i].position)
  }
  return posArray;
}

//export function getAtX(pa,dira,x){return 0.0}

export function getCrossPoint(pa, dira, pb, dirb){

  //console.log("getCrossPoint")
  //console.log(dira)
  //console.log(dirb)
  //console.log(pa)
  //console.log(pb)

  if((dira.x===0)&&(dirb.x===0)){
    console.log("ab same")
    return null
  }

  // Steigung
  let sta = dira.y / dira.x;
  let stb = dirb.y / dirb.x;

  // Addition
  let aa = pa.y - pa.x * sta;
  let ab = pb.y - pb.x * stb;

  // SchnittPunkt


  //if (Math.abs(sta) == Math.abs(stb))
  if (sta === stb)
  {
    //console.log("same direction")
    //LOG.debug("SAME DIRECTION - - - - - "+sta+" "+stb);
    return null
  }

  let pCross=[]
  if(dira.x == 0) {
    //console.log("da 0")
    pCross[0]=pa.x
    pCross[1]=(stb * pa.x) + ab
  } else if (dirb.x == 0) {
    //console.log("db 0")
    pCross[0]=pb.x
    pCross[1]=(sta * pb.x) + aa
  } else {
    if (Math.abs(dira.x) > Math.abs(dirb.x)) {
      let x = (ab - aa) / (sta - stb);
      pCross[0]=x
      pCross[1]=(sta * x) + aa
    } else {
      let x = (aa - ab) / (stb - sta);
      pCross[0]=x
      pCross[1]=(stb * x) + ab
    }
  }


  return new Vector2(pCross[0],pCross[1]);
}

export function getOrtoProjectionOnLine( pos,  segPosA, segPosB) {
  let vab = getSubtraction(segPosB, segPosA);

  if (vab.length() < 0.0000000001) {
    // System.out.println("ZERO");
    return segPosA
  }

  let vabc = getPerp(vab)
  let pOrto = getCrossPoint(segPosA, vab, pos, vabc);
  //console.log("porto: "+pOrto)
  if(pOrto===null){
    //LOG.debug("NULL ORTO");
    return segPosA;
  }else{
    return pOrto
  }
}

export function getOrtoProjectionOnSegment( pos,  segPosA, segPosB) {
   let vab = getSubtraction(segPosB, segPosA);

  if (vab.length() < 0.0000000001) {
    // System.out.println("ZERO");
    return segPosA
  }

  let vabc = getPerp(vab)
  let pOrto = getCrossPoint(segPosA, vab, pos, vabc);
  //console.log("porto: "+pOrto)
  if(pOrto===null){
    //LOG.debug("NULL ORTO");
    return segPosA;
  }

  if (Math.abs(vab.x) > Math.abs(vab.y)) {
    if (segPosA.x < segPosB.x) {
      if (pOrto.x <= segPosA.x)
        return segPosA;
      if (pOrto.x >= segPosB.x)
        return segPosB;
    } else {
      if (pOrto.x >= segPosA.x)
        return segPosA;
      if (pOrto.x <= segPosB.x)
        return segPosB;
    }
  } else {
    if (segPosA.y < segPosB.y) {
      if (pOrto.y <= segPosA.y)
        return segPosA;
      if (pOrto.y >= segPosB.y)
        return segPosB;
    } else {
      if (pOrto.y >= segPosA.y)
        return segPosA;
      if (pOrto.y <= segPosB.y)
        return segPosB;
    }
  }

  return pOrto;
}
export function getDistanceToEdge(position, edgeA,edgeB){
  //console.log("edgeA:"+edgeA+"   edgeB:"+edgeB+"   pos:"+position)
  let op=getOrtoProjectionOnSegment(position,edgeA,edgeB)
  if(op!=null){
    //alles nan
    //console.log("op:"+op+" opx: "+op.x+"   opy: "+op.y)
    let opv=new Vector2(Math.abs(op.x-position.x),Math.abs(op.y-position.y))
    //console.log("OPV: "+opv.length()+" OP: "+op);
    return opv.length()
  }else{
    //console.log("no OPV")
    return null
  }
}

export function getDistanceToLine(position, edgePos,edgeDir){
  //console.log("edgePos:"+edgePos.x+":"+edgePos.y+"   edgedir:"+edgeDir.x+":"+edgeDir.y+"   pos:"+position.x+":"+position.y)
  let edgePosB=getAddition(edgePos,edgeDir)
  let op=getOrtoProjectionOnLine(position,edgePos,edgePosB)
  if(op!=null){
    //alles nan
    //console.log("op:"+op+" opx: "+op.x+"   opy: "+op.y)
    let opv=new Vector2(Math.abs(op.x-position.x),Math.abs(op.y-position.y))
    //console.log("OPV: "+opv.length()+" OP: "+op);
    return opv.length()
  }else{
    //console.log("no OPV")
    return null
  }
}

export function getAddition(original, addition){
  return new Vector2(original.x + addition.x, original.y + addition.y)
}
export function getSubtraction(original, destination){
  return new Vector2 (original.x - destination.x, original.y - destination.y)
}
export function getWithLength(vector,wishLength){
  let oldLength = vector.length();
  let x=vector.x/oldLength
  let y=vector.y/oldLength
  return new Vector2(wishLength*x, wishLength*y);
}
export function getPerp(vector){
  return new Vector2(-vector.y,vector.x)
}
export function getScaled(vector, scale){
  return new Vector2(vector.x*scale, vector.y*scale)
}

export function getScaledAddition(posA, posB, scale){
  let v=new Vector2((posB.x-posA.x)*scale, (posB.y-posA.y)*scale)
  return getAddition(posA, v)
}

export function getUnitVector(vector){
  if(vector!=null){
    let length = vector.length()
    if(length!==0)
      return getWithLength(vector,1.0)
  }
  return new Vector2(1,0)
}

export function getLocalPosition(globalPosition, localOrigin, localDirX){
  const rotDirX=localDirX.x
  const rotDirY=-localDirX.y
  let unRotPosition = getSubtraction(globalPosition, localOrigin)
  let locX = unRotPosition.x * rotDirX - unRotPosition.y * rotDirY
  let locY = unRotPosition.x * rotDirY + unRotPosition.y * rotDirX;
  return new Vector2(locX,locY)
}

export function  getLocalPosLists( globalLists, localOrigin, localDirX) {
  const localPosLists = []
  //console.log("getLocalPosLists")

  //console.log(globalLists)
  //console.log(localOrigin)
  //console.log(localDirX)

  if(!globalLists) return localPosLists

  const rotDirX = localDirX.x
  const rotDirY = -localDirX.y

  for (let iList = 0; iList < globalLists.length; iList++) {
    let localPosList = []
    localPosLists.push(localPosList)
    let globalPositions = globalLists[iList]
    for (let i = 0; i < globalPositions.length; i++) {
      let unRotPosition = getSubtraction(globalPositions[i], localOrigin)
      let locX = unRotPosition.x * rotDirX - unRotPosition.y * rotDirY
      let locY = unRotPosition.x * rotDirY + unRotPosition.y * rotDirX
      localPosList.push(new Vector2(locX, locY))
    }
  }
  return localPosLists
}

export function getGlobalPosition(localPosition, localOrigin, localDirX) {
  let globX = localOrigin.x + localPosition.x * localDirX.x - localPosition.y * localDirX.y
  let globY = localOrigin.y + localPosition.x * localDirX.y + localPosition.y * localDirX.x
   return new Vector2(globX, globY)
}

export function getPositionAtY(y, posA, posB) {
  let newX = posA.x + ((y - posA.y) * ((posB.x - posA.x) / (posB.y - posA.y)));

  return new Vector2(newX, y);
}
