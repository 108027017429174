import React from 'react'

import './PlanButtonGroup.scss'

export function PlanButtonGroup ({ children }) {
  return (
    <div className={'plan-button-group'}>
      <div className={'plan-button-group-container'}>
        {children}
      </div>
    </div>
  )
}
