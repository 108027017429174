import * as React from 'react'

const SvgDrawRectangleActive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32">
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="url(#prefix__a)"
        d="M13 8c0 .828.665 1.5 1.499 1.5H17.5c.78 0 1.42-.59 1.492-1.355L19 8h5.5a.5.5 0 01.5.496V13c-.828 0-1.5.665-1.5 1.499V17.5c0 .78.59 1.42 1.355 1.492L25 19v4.504a.5.5 0 01-.5.496H19c0-.828-.665-1.5-1.499-1.5H14.5c-.78 0-1.42.59-1.492 1.355L13 24H7.5a.5.5 0 01-.5-.496V19c.828 0 1.5-.665 1.5-1.499V14.5c0-.78-.59-1.42-1.355-1.492L7 13V8.496A.5.5 0 017.5 8H13z"
      />
      <path
        stroke="#D14720"
        d="M25 13V8.5a.5.5 0 00-.5-.5H19h0m-6 0H7.5a.5.5 0 00-.5.5V13h0m0 6v4.5a.5.5 0 00.5.5H13m6 0h5.5a.5.5 0 00.5-.5V19h0"
      />
      <path
        stroke="#D14720"
        strokeLinejoin="round"
        d="M14.499 6.5C13.67 6.5 13 7.166 13 8h0c0 .828.665 1.5 1.499 1.5H17.5c.83 0 1.5-.666 1.5-1.5h0c0-.828-.665-1.5-1.499-1.5H14.5zm0 16C13.67 22.5 13 23.166 13 24h0c0 .828.665 1.5 1.499 1.5H17.5c.83 0 1.5-.666 1.5-1.5h0c0-.828-.665-1.5-1.499-1.5H14.5zM26.5 14.499C26.5 13.67 25.834 13 25 13h0c-.828 0-1.5.665-1.5 1.499V17.5c0 .828.666 1.499 1.5 1.499h0c.828 0 1.5-.665 1.5-1.499v-3zm-18 0C8.5 13.67 7.834 13 7 13h0c-.828 0-1.5.665-1.5 1.499V17.5c0 .83.666 1.5 1.5 1.5h0c.828 0 1.5-.665 1.5-1.499V14.5z"
      />
    </g>
  </svg>

export default SvgDrawRectangleActive