import * as React from 'react'

const SvgImportIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      fill="#00293B"
      fillRule="evenodd"
      d="M15 14.496a.5.5 0 01.5-.5h.261a2.258 2.258 0 002.347-2.163 2.256 2.256 0 00-2.167-2.342h-.43l-.05-.41c-.231-1.743-1.72-3.046-3.481-3.046S8.73 7.338 8.5 9.08l-.01.41h-.431a2.256 2.256 0 00-2.167 2.342 2.256 2.256 0 002.347 2.162h.26a.5.5 0 11.001 1.001h-.261a3.253 3.253 0 01-.652-6.436C8.033 6.483 9.872 5 12 5s3.967 1.483 4.413 3.56a3.253 3.253 0 01-.652 6.436h-.26a.5.5 0 01-.501-.5zm-2.499-2.587v6.59a.501.501 0 01-1.002 0v-6.59l-.945.94a.502.502 0 01-.708-.001.498.498 0 010-.705v-.001l1.448-1.445a.998.998 0 011.412 0l1.448 1.445a.5.5 0 01-.708.706l-.945-.939z"
    />
  </svg>
)

export default SvgImportIcon