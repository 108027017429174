import React from 'react'
import { IconWrapper } from './IconWrapper'
import Text from './Text'

import './DropdownButton.scss'
import { iconTypes } from './icons/IconFactory'

export function DropdownButton (props) {

  const styleClass = props.className ? ' ' + props.className : ''

  return (
    <div role={'button'} className={'drop-down-item'} onClick={() => props.onClick()}>
      <div className={'drop-down-container' + styleClass}>
        <div className={'drop-down-content'}>
          {props.icon && <IconWrapper styleClass={'drop-down-icon'} name={props.icon}/>}
          <Text>{props.title}</Text>
        </div>
        <IconWrapper name={iconTypes.triangleDown} styleClass={'drop-down-arrow'}/>
      </div>
    </div>
  )
}
