import React from 'react'

import { iconTypes } from './icons/IconFactory'
import Text from './Text'

import './ListMenuItem.scss'
import { IconWrapper } from './IconWrapper'

function handleOnClick (props, event) {
  if (!props.disabled) {
    props.onClick(event)
  }
}

export function ListMenuItem (props) {

  return (
    <li className={'list-menu-item'}
        role={'button'}
        onMouseEnter={() => props.onMouseEnter ? props.onMouseEnter(props.children) : {}}
        onClick={(event) => handleOnClick(props, event)}>
      <div className={'list-menu-item-container'}>
        <div style={{ ...props.style }}
             className={'list-menu-item-content' + (props.className ? ' ' + props.className : '') + (props.disabled ? ' disabled' : '') + (props.bordered ? ' bordered' : '')}>
          {props.icon ?
            <div className={'list-menu-item-icon'}>
              <IconWrapper name={props.icon} color={props.iconColor} active={props.iconActive}/>
            </div> : null
          }
          <Text children={props.children}/>
          {props.secondIcon &&
          <div className={'list-menu-item-icon second'}>
            <IconWrapper name={props.secondIcon} disabled={props.disabled} color={props.iconColor} active={props.iconActive}/>
          </div>
          }
          {props.arrow &&
          <div className={'list-menu-item-arrow'}>
            <IconWrapper name={iconTypes.arrow}/>
          </div>}
        </div>
      </div>
    </li>
  )

}