import React, { Component } from 'react'
import { connect } from 'react-redux'
import BuilderFurnitureSettings from '../../../../common/components/builder/BuilderFurnitureSettings'
import { changeFurnitureAnchorSide, changeFurnitureRotation, changeSelectedElement, deleteFurniture, getUndoAvailable, storeApplicationBuilderUndo } from '../../../actions/frontendActions'

class SettingsFurniture extends Component {

  render () {
    return (
      <BuilderFurnitureSettings furnitureList={this.props.furnitureList}
                                selectedFurnitureId={this.props.furnitureId}
                                changeSelectedElement={this.props.changeSelectedElement}
                                onRotateFurniture={this.props.changeFurnitureRotation}
                                onFurnitureChangeAnchorSide={this.props.changeFurnitureAnchorSide}
                                onDeleteFurniture={this.props.deleteFurniture}
                                storeApplicationBuilderUndo={this.props.storeApplicationBuilderUndo}
                                getUndoAvailable={this.props.getUndoAvailable}
      />
    )
  }
}

let mapStateToProps = (state) => {
  return {
    selectedElement: state.applicationBuilderUiState.selectedElement,
    furnitureList: state.furnitureEnsembles.furnitureEnsemblePlan.furnitureList,
    furnitureId: state.applicationBuilderUiState.selectedElement.data,
  }
}

let mapDispatchToProps = {
  changeFurnitureRotation: changeFurnitureRotation,
  changeFurnitureAnchorSide: changeFurnitureAnchorSide,
  deleteFurniture: deleteFurniture,
  changeSelectedElement: changeSelectedElement,
  storeApplicationBuilderUndo: storeApplicationBuilderUndo,
  getUndoAvailable: getUndoAvailable,
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsFurniture)