import React from 'react'

const SvgBack = props => (

  <svg className={props.className} viewBox="0 0 24 24">
    <g id="Artboard" stroke="none" fill="none">
      <polygon id="Rechteck_540" points="0 0 24 0 24 24 0 24"/>
      <path d="M5.55890757,11.9587159 L18.4379076,12.0427159" id="Linie_146" stroke={props.color} strokeWidth="1.5" strokeLinejoin="round"/>
      <polyline id="Path" stroke={props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                transform="translate(9.878680, 12.001068) rotate(-45.000000) translate(-9.878680, -12.001068) "
                points="6.37867966 15.5010678 6.37867966 8.50106781 6.37867966 8.50106781 13.3786797 8.50106781"/>
    </g>
  </svg>
)

export default SvgBack