import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { fetchProject } from './common/redux/projects/projects-actions'
import { fetchVariationsAPI, updateVariationId } from './common/redux/variations/variations-actions'
import { getCurrentProjectId, getCurrentVariationId } from './common/utils'
import { useParams } from 'react-router'
import { getVariationFromVariations } from './common/redux/variations/variations-reducer'

function ProjectLoader () {

  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { projectId } = useParams()
  const project = useSelector(state => state.projects.project)
  //const projectId = useSelector(getCurrentProjectId);
  //replace use Selector with useState

  //const currentVariationId = useSelector(getCurrentVariationId(location))

  const dataLoaded = useSelector((state) => state.dataLoaded.dataLoaded)

  /*
  Was soll der Projektloader machen?
  Bei änderung einer ProjektID oder einer Variation ID oder

  - Projektdaten laden
  - Variationen laden

   */

  useEffect(() => {

    let loadProjectId = projectId
    //check if newer project is requested than in url
    if (projectId && project && projectId !== project.id) {
      loadProjectId = project.id
      console.error('Project ID in URL does not match project ID in store. Using project ID from store instead.')
    }

    if (loadProjectId) {

      //setCurrentVariationId(getCurrentVariationId(location))
      dispatch(fetchProject(loadProjectId))
      dispatch(fetchVariationsAPI(loadProjectId))
        .then(({ value }) => {

          let variation
          const currentVariationId = getCurrentVariationId(location)
          //check if variation exists

          if (value.data.find(variation => variation.id === currentVariationId)) {
            variation = currentVariationId
          } else {
            variation = value.data[0].id
            variation = value.data[0].id
          }

          // update query parameters
          const newSearch = new URLSearchParams(location.search)
          newSearch.set('variation', variation)

          // Perform navigation only if necessary
          const updatedSearchString = newSearch.toString()
          dispatch(updateVariationId(variation))

          if (location.search !== `?${updatedSearchString}`) {
            navigate(`?${updatedSearchString}`, { replace: true })
          }
        })

    }
  }, [projectId, location.search, navigate, dataLoaded])


  return null
}

export default ProjectLoader