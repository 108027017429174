import * as React from 'react'

const SvgDrawAxesAutoGenerateInactive = props =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke={props.color}>
      <path
        fill="#FF8042"
        d="M20.117 28l.592-1.895h2.534L23.854 28H25l-2.31-7h-1.38L19 28h1.117zm2.854-2.868h-2l.98-3.089H22l.97 3.089z"
      />
      <path
        d="M21.341 4.585L9.158 16.221a.45.45 0 00.22.766l5.372 1.094h0l-4.91 8.85a.3.3 0 00.468.365l12.59-11.773a.45.45 0 00-.219-.769l-5.493-1.119h0l4.628-8.692a.3.3 0 00-.473-.358z"
      />
    </g>
  </svg>

export default SvgDrawAxesAutoGenerateInactive