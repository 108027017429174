export function getOffset(offsetGeometry) {
  console.log("getOffset")
    return offsetGeometry.offsetLeft + offsetGeometry.offsetRight
}

export function getOffsets(offsetGeometry) {
    console.log("getOffsets")
    return {
        offsetLeft: offsetGeometry.offsetLeft,
        offsetRight: offsetGeometry.offsetRight
    }
}

export function setOffset(offsetGeometry, offset) {
  console.log("set Offset:"+ offset)
    if (!offsetGeometry.hasOwnProperty('offsetLeft') ||
        !offsetGeometry.hasOwnProperty('offsetRight'))
        return

    if (offsetGeometry.offsetLeft > 0 && offsetGeometry.offsetRight > 0) {
        offsetGeometry.offsetLeft = offset / 2
        offsetGeometry.offsetRight = offset / 2
    } else if (offsetGeometry.offsetLeft > 0) {
        offsetGeometry.offsetLeft = offset
    } else {
        offsetGeometry.offsetRight = offset
    }
}

export function shiftOffsetToLeft(offsetGeometry) {
    console.log("shiftOffsetToLeft")

    if (!offsetGeometry.hasOwnProperty('offsetLeft') ||
        !offsetGeometry.hasOwnProperty('offsetRight'))
        return

    if (offsetGeometry.offsetLeft === 0) {
        const offsetHalf = offsetGeometry.offsetRight / 2
        offsetGeometry.offsetLeft = offsetHalf
        offsetGeometry.offsetRight = offsetHalf
    } else if (offsetGeometry.offsetLeft > 0 && offsetGeometry.offsetRight > 0) {
        offsetGeometry.offsetLeft = offsetGeometry.offsetLeft + offsetGeometry.offsetRight
        offsetGeometry.offsetRight = 0
    }
}

export function shiftOffsetToRight(offsetGeometry) {
    console.log("shiftOffsetToRight()")
    if (!offsetGeometry.hasOwnProperty('offsetLeft') ||
        !offsetGeometry.hasOwnProperty('offsetRight'))
        return

    if (offsetGeometry.offsetRight === 0) {
        const offsetHalf = offsetGeometry.offsetLeft / 2
        offsetGeometry.offsetLeft = offsetHalf
        offsetGeometry.offsetRight = offsetHalf
    } else if (offsetGeometry.offsetLeft > 0 && offsetGeometry.offsetRight > 0) {
        offsetGeometry.offsetRight = offsetGeometry.offsetLeft + offsetGeometry.offsetRight
        offsetGeometry.offsetLeft = 0
    }
}

export function addToOffset(offsetGeometry, step) {
    console.log("addToOffset")

    if (!offsetGeometry.hasOwnProperty('offsetLeft') ||
        !offsetGeometry.hasOwnProperty('offsetRight') ||
        !offsetGeometry.hasOwnProperty('setOffset'))
        return

    let offset = offsetGeometry.offsetLeft + offsetGeometry.offsetRight

    if (offset + step > 0.2) {
        offsetGeometry.setOffset(offset + step)
    }
}