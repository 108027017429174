export const representationTypes = {
    select: 'select',
    scale: 'scale',
    outline: 'outline',
    wall: 'wall',
    door: 'door',
    window: 'window',
    pillar: 'pillar',
    space: 'space',
    axis: 'axis',
    magicGuideLine: 'magicGuideLine',
    previewAxis: 'previewAxis',
}
