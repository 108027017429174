import { settingsActionTypes } from './settings-actions'

let initialState = {
  appSettings: {
    advancedSettings: false,
    useLens: false,
    employeeBasedCalculation: false,
    allowWallsInWindows: false,
    corridorWidth: 1,
    distanceToFacade: 0.6,
    walkDistance: 0.9,
    walkDistanceBtB: 0.9,
    wallDistance: 0,
    workingDepth: 1,
    workingDepthH: 1,
  },

}

function settingsReducer (state = initialState, action) {

  switch (action.type) {

    case settingsActionTypes.FETCH_APPLICATION_SETTINGS_FULFILLED:
      return {
        ...state,
        appSettings: action.payload.data,
      }
    case settingsActionTypes.SET_ADVANCED_SETTING:
      const appSettings = state.appSettings

      appSettings.advancedSettings = action.payload

      return {
        ...state,
        appSettings: appSettings,
      }
    case settingsActionTypes.CLEAR_PROJECT:
      return { ...initialState }
    default:
      return state
  }

}

export default settingsReducer
