import * as React from 'react'

const CollaborateIconActive = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={37}
    height={36}
    viewBox="0 0 37 36"
  >
    <defs>
      <linearGradient
        id="prefix_collaborate_active_a"
        x1="37.3%"
        x2="100%"
        y1="-3.407%"
        y2="84.13%"
      >
        <stop offset="0%" stopColor="#9089EA"/>
        <stop offset="100%" stopColor="#2F97E4"/>
      </linearGradient>
    </defs>
    <path
      d="M13 17.5a7 7 0 017 7v3H6v-3a7 7 0 017-7zm10 0a7 7 0 017 7v3h-8v-3l-.006-.323a8.963 8.963 0 00-2.395-5.796A6.957 6.957 0 0123 17.5zm.022-9.5a4.01 4.01 0 012.833 1.173c.75.75 1.17 1.768 1.167 2.827.003 1.06-.417 2.076-1.167 2.826S24.087 16 23.025 16h-.003a4.01 4.01 0 01-2.832-1.173A3.988 3.988 0 0119.022 12a3.988 3.988 0 011.168-2.827A4.012 4.012 0 0123.022 8zm-10 0a4.01 4.01 0 012.833 1.173c.75.75 1.17 1.768 1.167 2.827.003 1.06-.417 2.076-1.167 2.826S14.087 16 13.025 16h-.003a4.01 4.01 0 01-2.832-1.173A3.988 3.988 0 019.022 12a3.988 3.988 0 011.168-2.827A4.012 4.012 0 0113.022 8z"
      transform="translate(.5)"
      fill="url(#prefix_collaborate_active_a)"
      fillRule="evenodd"
    />
  </svg>
)

export default CollaborateIconActive