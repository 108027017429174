import React from 'react'

const SvgAppCreatorDeskIconActive = props => (
  <svg className={props.className} viewBox="0 0 54 54">
    <path data-name="Rechteck 426" fill="none" d="M0 0h54v54H0z"/>
    <path
      data-name="Pfad 911"
      d="M37.462 26.836v-.977h-7.813v.977zm0 5.86h-7.813v.977h-.977v-12.7h-12.7v12.7h-.973V20h23.439v13.673h-.977zM32.09 22.93h2.93a.488.488 0 0 0 .488-.488.488.488 0 0 0-.488-.488h-2.93a.488.488 0 0 0-.488.488.488.488 0 0 0 .488.488zm0 5.86h2.93a.488.488 0 0 0 .488-.488.488.488 0 0 0-.488-.488h-2.93a.488.488 0 0 0-.488.488.488.488 0 0 0 .488.488z"
      fill="#454b5a"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgAppCreatorDeskIconActive
