import React, { Component } from 'react'
import { Field, Form, Formik } from 'formik'
import { connect } from 'react-redux'

import equal from 'fast-deep-equal'

import Section from '../../common/components/layout/Section'
import SectionHeader from '../../common/components/layout/SectionHeader'
import Button from '../../common/components/Button'
import { updateApplicationSettings } from '../../common/redux/settings/settings-actions'
import { getCurrentProjectId } from '../../common/utils'

import './GeneralSettings.scss'
import ToggleSwitch from '../../common/components/ToggleSwitch'
import { withTranslation } from 'react-i18next'
import { instanceOf } from 'prop-types'
import { Cookies, withCookies } from 'react-cookie'

class GeneralSettings extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }

  constructor (props) {
    super(props)

    this.state = {
      changes: false,
    }
  }

  /*
  languages = [
    { label: 'Deutsch', value: 'de', code: 'de-DE' },
    { label: 'English', value: 'en', code: 'en' },
  ]

  changeLanguage = (event) => {
    const { cookies } = this.props
    this.props.i18n.changeLanguage(event.target.value)
    cookies.set('language', event.target.value, { path: '/' })
  }

  getSelectedLanguage = () => {
    const { cookies } = this.props
    let selectedLanguage = cookies.get('language')
    if (selectedLanguage)
      return selectedLanguage

    this.props.i18n.changeLanguage('en')
    cookies.set('language', 'en', { path: '/' })
    return 'en'
  }
  */

  render () {
    //const { t } = this.props

    let initialValue = {
      useLens: this.props.settings.useLens,
      usePricing: this.props.settings.usePricing,
      employeeBasedCalculation: this.props.settings.employeeBasedCalculation,
      advancedSettings: this.props.settings.advancedSettings,
      uom: this.props.settings.uom,
    }

    return (
      <Section className={'general-settings'}>
        <SectionHeader title={'General'}/>
        <div className={'general-settings-content'}>
          <Formik initialValues={initialValue}
                  onSubmit={(values) => this.handleSettingsChange(values)}
                  validate={(values) => this.checkChanges(initialValue, values)}
                  enableReinitialize>
            <Form>
              <div className={'use-toggle-components'}>
                <Field className={'calc-with-employees-toggle'}
                       label={'Advanced Settings'}
                       name={'advancedSettings'}
                       component={ToggleSwitch}
                       disabled={!this.props.projectId}/>
                {this.props.settings.advancedSettings ?
                  <React.Fragment>
                    <Field className={'use-lens-toggle'}
                           label={'LENS'}
                           name={'useLens'}
                           component={ToggleSwitch}
                           disabled={!this.props.projectId || !this.props.settings.advancedSettings}/>
                    <Field className={'use-pricing-toggle'}
                           label={'Pricing Calculation'}
                           name={'usePricing'}
                           component={ToggleSwitch}
                           disabled={!this.props.projectId}/>
                  </React.Fragment>
                  : null}
              </div>


              <div className="general-settings-form-buttons">
                <Button type="reset" disabled={!this.state.changes}>Discard</Button>
                <Button type="submit" variant={'contained'} disabled={!this.state.changes}>Confirm</Button>
              </div>
            </Form>
          </Formik>
        </div>
      </Section>
    )
  }

  /*
    <Field className={'calc-with-employees-toggle'}
           label={'Space per Employee Calculation'}
           name={'employeeBasedCalculation'}
           component={ToggleSwitch}
           disabled={!this.props.projectId}/>
   */

  /*
    <SelectButton label={t('language')}
                  options={this.languages}
                  field={{onChange: this.changeLanguage, value: this.getSelectedLanguage()}}
                  onChange={this.changeLanguage}/>
   */

  checkChanges (initialValue, values) {
    this.setState({ changes: !equal(initialValue, values) })
  }

  handleSettingsChange (values) {
    this.props.updateApplicationSettings(values)
  }
}

let mapStateToProps = (state) => {
  return {
    projectId: getCurrentProjectId(state),
    settings: state.settings.appSettings,
  }
}

let mapDispatchToProps = {
  updateApplicationSettings: updateApplicationSettings,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withCookies(GeneralSettings)))