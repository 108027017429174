import React, { Component } from 'react'
import Text, { textTypes } from './Text'
import IconButton from './IconButton'

import './TableColumnInfoHeader.scss'
import { Manager, Popper, Reference } from 'react-popper'
import onClickOutSide from 'react-onclickoutside'
import { iconTypes } from './icons/IconFactory'

class TableColumnInfoHeader extends Component {

  constructor (props) {
    super(props)

    this.state = {
      showInfo: false,
    }
  }

  handleClickOutside (event) {
    this.setState({ showInfo: false })
  }

  render () {
    const headerStyle = this.props.headerStyle ? ' ' + this.props.headerStyle : ''
    const title = this.props.title ? this.props.title : 'Default Column Title'
    const infoText = this.props.infoText ? this.props.infoText : 'Default info text'
    const disabled = this.props.disabled ? this.props.disabled : null

    return (
      <Manager>
        <div>
          <Reference>
            {({ ref }) => (
              <div ref={ref} className={'table-column-info-header' + headerStyle}>
                <Text outerClassName={'header-title'}>{title}</Text>
                {!disabled ? <IconButton className={'info-button'} icon={iconTypes.info} onClick={() => this.toggleShowInfo()}/> : null}
              </div>
            )}
          </Reference>
        </div>

        {this.state.showInfo ?
          <Popper placement={this.props.placement ? this.props.placement : 'bottom-start'}>
            {({ ref, style, placement }) => (
              <div ref={ref} style={{ ...style, zIndex: 3 }} data-placement={placement}>
                <div className={'info-body'}>
                  <Text component={textTypes.infoTitle} outerClassName={'info-body-header'}>{title}</Text>
                  <Text component={textTypes.infoParagraph} multiline>{infoText}</Text>
                </div>
              </div>
            )}
          </Popper>
          : null}
      </Manager>
    )
  }

  toggleShowInfo () {
    this.setState({ showInfo: !this.state.showInfo })
  }
}

export default onClickOutSide(TableColumnInfoHeader)