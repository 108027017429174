import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FlyOut from '../../../common/components/FlyOut';
import CorporateFlyOutMenu from './CorporateFlyOutMenu';
import { closeFlyOut, openFlyOut } from '../../actions/ui-actions';
import { updateOrganisationalUnitPlaced } from '../../../common/redux/organisationunits/organisational-units-actions';

import './FloorPlanFlyOut.scss';


function FloorPlanFlyOut() {
  const dispatch = useDispatch();

  const {
    open,
    selectedOrganisationalUnitId,
    selectedContextMenuId,
    selectedRowId,
    selectedApplicationId,
    organisationalUnits,
  } = useSelector(state => ({
    open: state.floorPlanUiState.flyOut.open,
    selectedOrganisationalUnitId: state.appState.selectedOrganisationalUnitId,
    selectedContextMenuId: state.floorPlanState.selectedContextMenuId,
    selectedRowId: state.floorPlanState.selectedRowId,
    selectedApplicationId: state.floorPlanState.selectedApplicationId,
    organisationalUnits: state.organisationalUnits.units,
  }));

  useEffect(() => {
    if (selectedOrganisationalUnitId && selectedRowId !== selectedOrganisationalUnitId && selectedContextMenuId === null) {
      dispatch(openFlyOut());
    } else if (selectedOrganisationalUnitId === selectedContextMenuId || (selectedContextMenuId === selectedApplicationId && selectedContextMenuId !== null)) {
      dispatch(closeFlyOut());
    }
  }, [selectedOrganisationalUnitId, selectedContextMenuId, selectedRowId, selectedApplicationId, dispatch]);

  const getOrganisationalUnit = (unitId) => organisationalUnits.find(unit => unit.id === unitId);

  const handleColorChange = (color, unitId) => {
    const unit = {
      color: {
        red: color.rgb.r / 255,
        green: color.rgb.g / 255,
        blue: color.rgb.b / 255,
        alpha: color.rgb.a,
      },
    };

    dispatch(updateOrganisationalUnitPlaced(unitId, unit));
  };

  const unit = getOrganisationalUnit(selectedOrganisationalUnitId);

  return (
    <FlyOut className='floor-plan-fly-out' open={open}
            onCloseClicked={() => dispatch(closeFlyOut())}
            headerStyle={'floor-plan-fly-out-header'}
            headerTitle={unit ? unit.name : ''}
            color={unit ? unit.color : ''}
            onColorChange={(colorAsHex) => handleColorChange(colorAsHex, selectedOrganisationalUnitId)}>
      <CorporateFlyOutMenu />
    </FlyOut>
  );
}

export default FloorPlanFlyOut;
