import React, { Component } from 'react'
import { Popper } from 'react-popper'

import './ScaleInputPopup.scss'
import { Field, Form, Formik } from 'formik'
import Button from '../../../common/components/Button'
import Text from '../../../common/components/Text'
import { withTranslation } from 'react-i18next'

class VirtualReference {

  constructor (top, right, bottom, left) {
    this.boundingClientRect = {
      top: top,
      left: left,
      bottom: bottom,
      right: right,
      width: 0,
      height: 0,
    }
  }

  getBoundingClientRect () {
    return this.boundingClientRect
  }

  get clientWidth () {
    return this.getBoundingClientRect().width
  }

  get clientHeight () {
    return this.getBoundingClientRect().height
  }
}

/**
 * Context menu container, that will be positioned relatively to the given coordinates
 * You can add IconButton elements as children in the body of this Component to add items to the context menu
 *
 * Example how to use:
 *
 * <PlanContextMenu x={this.state.myX} y={this.state.myY} show={this.state.showContextMenu} placement={'right'}>
 *    <IconButton icon={'pencil'} inverted activeOnHover={true}/>
 *    <IconButton icon={'delete'} inverted activeOnHover={true}/>
 * </PlanContextMenu>
 *
 *
 *
 */
class ScaleInputPopup extends Component {

  render () {
    // This creates a virtual reference element to position
    // the tooltip on the given coordinates
    console.log("scaleInput")
    console.log(this.props)
    const virtualReferenceElement = this.createVirtualReferenceElement()
    const { t, scale } = this.props
    const values = { scale: scale.distance.toFixed(2) }

    return (
      <Popper
        referenceElement={virtualReferenceElement}>
        {({ ref, style, placement, arrowProps }) => {
          return (
            <div className={'scale-input-popup'} ref={ref} style={style} data-placement={placement}>
              <Formik initialValues={values} enableReinitialize onSubmit={(values) => this.handleSaveScale(values.scale)}>
                {({ values }) => (
                  <Form className={'scale-input-container'}>
                    <Field className={'scale-input'} type={'number'} name={'scale'} onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault()
                        this.handleSaveScale(values.scale)
                      }
                    }}/>
                    <Text className={'scale-text-wrapper'}>{this.props.unit}</Text>
                    <Button className={'scale-button'} variant={'orange-filled'} type={'submit'}>{t('save')}</Button>
                  </Form>
                )}
              </Formik>
              <div className={'scale-input-arrow plan-context-menu-right-arrow'} style={arrowProps.style}/>
            </div>
          )
        }}
      </Popper>
    )

  }

  handleSaveScale (scale) {
    console.log("save scale")
    this.props.onValueChanged(scale)
  }

  createVirtualReferenceElement () {
    let { x, y } = this.props.scale
    x += 2
    y += 10
    return new VirtualReference(y, x, y, x)
  }
}

export default withTranslation()(ScaleInputPopup)