import * as React from 'react'

const SvgDrawServiceInactive = props =>
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <g stroke={props.color} fill="none" fillRule="evenodd">
      <path
        strokeLinejoin="round"
        d="M16.337 17.75L7 22.06h0V10.614c0-.143.083-.272.212-.332l9.125-4.211a.364.364 0 01.306 0l9.125 4.211c.13.06.212.19.212.332V22.06h0l-9.337-4.31a.371.371 0 00-.306 0z"
      />
      <path d="M16.49 17.68V6"/>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 22.06l9.49 4.38 9.49-4.38"
      />
      <path
        strokeLinejoin="round"
        d="M10.285 20.289v-7.064c0-.12.068-.228.173-.277l2.336-1.082a.289.289 0 01.386.154.327.327 0 01.025.124v6.878"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.38 17.198v-.883"
      />
    </g>
  </svg>

export default SvgDrawServiceInactive