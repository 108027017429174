import React from 'react'
import './ToggleIcon.scss'
import { uniqueId } from 'lodash'

import Icon from './icons/IconFactory'

function ToggleIcon ({ id, name, activeIcon, disabledIcon, value, onChange, className, field }) {

  const elementId = (field ? 'icon-' + field.name : id) || uniqueId('icon-')
  const styleClasses = 'toggle-icon' + (className ? ' ' + className : '')

  return (
    <div className={styleClasses}>
      <div className={'toggle-icon-button'}>
        <input type={'checkbox'}
               id={elementId}
               name={(field ? field.name : name) || null}
               checked={(field ? field.value : value) || false}
               onChange={field ? field.onChange : onChange}/>
        <label className={'icon'} htmlFor={elementId}>
          <Icon name={(field ? field.value : value) ? activeIcon : disabledIcon}/>
        </label>
      </div>
    </div>
  )
}

export default ToggleIcon