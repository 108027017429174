import * as React from 'react'

const SvgDrawServiceActive = props =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
      <linearGradient
        id="prefix__b"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect width={32} height={32} fill="#00293B" opacity={0.08} rx={2}/>
      <path
        stroke="#DA5B2B"
        strokeLinejoin="round"
        d="M16.337 17.75L7 22.06h0V10.614c0-.143.083-.272.212-.332l9.125-4.211a.364.364 0 01.306 0l9.125 4.211c.13.06.212.19.212.332V22.06h0l-9.337-4.31a.371.371 0 00-.306 0z"
      />
      <path
        fill="url(#prefix__a)"
        stroke="#DA5B2B"
        strokeLinejoin="round"
        d="M16.49 17.68V6"
      />
      <path
        fill="url(#prefix__b)"
        stroke="#DA5B2B"
        strokeLinejoin="round"
        d="M7 22.06l9.49-4.38 9.49 4.38-9.49 4.38z"
        transform="matrix(1 0 0 -1 0 44.12)"
      />
      <path
        fill="#EBEEF0"
        stroke="#DA5B2B"
        strokeLinejoin="round"
        d="M10.285 20.542v-7.317c0-.12.068-.228.173-.277l2.336-1.082a.289.289 0 01.386.154.327.327 0 01.025.124v7.049l-2.92 1.35z"
      />
      <path
        stroke="#DA5B2B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.38 17.198v-.883"
      />
    </g>
  </svg>

export default SvgDrawServiceActive