import React from 'react'

const SvgArea = props => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="area_a"
        d="M6.75 5h10.5L19 6.75v10.5L17.25 19H6.75L5 17.25V6.75z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="area_b" fill="#fff">
        <use xlinkHref="#area_a"/>
      </mask>
      <use
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        xlinkHref="#area_a"
      />
      <g stroke={props.color} strokeLinecap="round" mask="url(#area_b)">
        <path d="M1.113 14.652L15.255.51M5.002 19.248L19.144 5.106M2.881 17.126L17.023 2.985m-9.9 18.385L21.266 7.227M9.245 23.49L23.387 9.348"/>
      </g>
    </g>
  </svg>
)

export default SvgArea
