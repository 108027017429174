import React from 'react'

const SvgELearning = props => (
  <svg width={24} height={24} {...props}>
    <path
      d="M19.56 15.685c.43 0 .859.951 1.289 2.852a.58.58 0 01-.566.708h-1.448a.58.58 0 01-.565-.707c.43-1.902.86-2.853 1.29-2.853zm-7.514-9.683l.09.017 9 2.55c.485.137.485.824 0 .961L18 10.418v2.536a3.5 3.5 0 01-3.308 3.495l-.192.005h-5a3.5 3.5 0 01-3.5-3.5v-2.536L2.864 9.53c-.453-.128-.483-.735-.09-.927l.09-.035 9-2.55a.5.5 0 01.272 0zm4.954 4.7l-4.864 1.378-.09.017h-.092l-.09-.017L7 10.702v2.252a2.5 2.5 0 002.174 2.479l.162.016.164.005h5a2.5 2.5 0 002.5-2.5v-2.253zm2.56-.017a.5.5 0 01.491.41l.008.09v3.25a.5.5 0 01-.992.09l-.008-.09v-3.25a.5.5 0 01.5-.5zM12 7.02L4.833 9.05 12 11.079l7.167-2.03L12 7.019z"
      fill={props.color}
      fillRule="evenodd"
    />
  </svg>
)

export default SvgELearning
