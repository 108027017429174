import Dialog, { DialogButtonGroup } from '../../common/components/Dialog'
import Button from '../../common/components/Button'
import React from 'react'

import './ManualLockingDialog.scss'

export default function ManualLockingDialog ({ lockingButtons, onClick, onClose }) {
  return (
    <Dialog title={'Manual Locking'} className={'manual-locking-dialog'} onCancel={() => onClose()}>
      <DialogButtonGroup className={'manual-locking-dialog-button-group'}>
        {
          lockingButtons.map((lockingButton, index) => {
            return <Button key={index} onClick={() => {
              onClick(lockingButton)
              onClose()
            }}>
              {lockingButton}
            </Button>
          })
        }
      </DialogButtonGroup>
    </Dialog>
  )
}

//<Text component={textTypes.paragraph} multiline>{text}</Text>