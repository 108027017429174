import * as React from 'react'

const SvgDrawSelectInactive = props =>
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{'Icon / Drawmode / select / inactive'}</title>
    <path
      d="M12.3 7.558 22 17.6l-5.14-.535 2.57 7.056a.492.492 0 0 1-.21.602l-.081.036-1.074.367a.509.509 0 0 1-.633-.32l-2.453-6.994-3.679 3.843 1-14.097Z"
      stroke={props.color}
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    />
  </svg>

export default SvgDrawSelectInactive
