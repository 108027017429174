import React from 'react'
import './SectionHeader.scss'
import Text from '../Text'

function SectionHeader ({ children, className, innerClassName, title }) {

  let styleClass = className ? ' ' + className : ''
  let innerStyleClass = innerClassName ? ' ' + innerClassName : ''

  return (
    <div className={'section-header' + styleClass}>
      <div className={'section-header-inner' + innerStyleClass}>
        {title &&
        <Text component={'h4'} className={'menu-section-title'}>{title}</Text>
        }
        {children}
      </div>
    </div>
  )
}

export default SectionHeader