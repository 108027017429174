import React from 'react'

import Text from './Text'
import Icon from './icons/IconFactory'

import './MenuSection.scss'

function MenuSection ({ icon, children, className, titleClassName, title, actionButton }) {

  const styleClass = (className ? ' ' + className : '') + (title ? ' menu-section-with-title' : '')
  const titleStyleClass = titleClassName ? ' ' + titleClassName : ''

  return (
    <div className={'menu-section' + styleClass}>
      {title &&
      <div className={'menu-section-title-container' + titleStyleClass}>
        <div className={'menu-section-title-row'}>
          <div className={'menu-section-title-content'}>
            {icon && <Icon className={'menu-section-icon'} name={icon}/>}
            <Text multiline>{title}</Text>
          </div>
          {actionButton &&
          <div className={'menu-section-action-button'}>
            {actionButton}
          </div>
          }
        </div>
      </div>}
      {children}
    </div>
  )
}

export default MenuSection