import { getMaterial } from '../common/Materials'
import { materialTypes } from '../common/MaterialTypes'

export const scaleConfig = {
  vertex: {
    innerActiveMaterial: getMaterial(materialTypes.blue),
    innerInactiveMaterial: getMaterial(materialTypes.transparent),
    outerActiveMaterial: getMaterial(materialTypes.blue),
    outerInactiveMaterial: getMaterial(materialTypes.transparent),
    innerSize: 0.1,
    outerSize: 0.3,
    innerSegments: 32,
    outerSegments: 32,
  },
  edge: {
    innerActiveMaterial: getMaterial(materialTypes.orange),
    innerInactiveMaterial: getMaterial(materialTypes.orange),
    outerActiveMaterial: getMaterial(materialTypes.transparent),
    outerInactiveMaterial: getMaterial(materialTypes.transparent),
    innerSize: 0.1,
    outerSize: 0.3,
  },
  properties: {}
}