import * as React from 'react'

const SvgHelpIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={props.className}
  >
    <g fill="#00293B" fillRule="evenodd">
      <path d="M12 20a8 8 0 100-16 8 8 0 000 16zm0-1.23a6.77 6.77 0 110-13.54 6.77 6.77 0 010 13.54z"/>
      <path d="M11.85 16.5c-.416 0-.75-.334-.75-.75s.334-.75.75-.75.75.334.75.75-.334.75-.75.75z"/>
      <path
        fillRule="nonzero"
        d="M12.481 14c.016-.576.226-.886.783-1.33 1.068-.84 1.986-1.624 1.986-2.908C15.25 8.27 14.272 7 12.06 7c-2.016 0-3.31 1.226-3.31 3.13 0 .281.03.577.09.872l1.294.06a3.246 3.246 0 01-.105-.843c0-1.358.722-2.17 2.031-2.17 1.234 0 1.836.767 1.836 1.742 0 1.093-.783 1.61-1.49 2.171-.797.635-1.098 1.196-1.098 2.038h1.173z"
      />
    </g>
  </svg>
)

export default SvgHelpIcon
