import React from 'react'

import equal from 'fast-deep-equal'
import './FileInput.scss'

export default class FileInput extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      drop: false,
      file: null,
    }
  }

  componentDidUpdate (prevProps, prevState) {

    if (!equal(this.state.file, prevState.file)) {
      if (this.state.file !== null) {
        this.props.onChange(this.state.file)
      }
    }

  }

  onDragEnter = (event) => {
    event.preventDefault()
    this.setState({
      drop: true,
    })
  }

  onDragLeave = (event) => {
    event.preventDefault()
    this.setState({
      drop: false,
    })
  }
  onDragEnd = (event) => {
    event.preventDefault()

  }
  onDragOver = (event) => {
    event.preventDefault()
    this.setState({
      drop: true,
    })

  }
  onDrop = (event) => {
    event.preventDefault()
    this.setState({
      drop: false,
      file: event.dataTransfer.files[0],
    })

  }

  loadFileFromDisk = (event) => {
    this.setState({
      file: event.target.files[0],
    })
  }

  render () {
    return (
      <div className={'file-input' + (this.state.drop ? ' over' : '')}
           onDragEnter={this.onDragEnter}
           onDragLeave={this.onDragLeave}
           onDragEnd={this.onDragEnd}
           onDragOver={this.onDragOver}
           onDrop={this.onDrop}>
        <input type="file" name={this.props.name} accept={this.props.accept} onChange={this.loadFileFromDisk}/>
      </div>
    )
  }
}