import React, { Component } from 'react'

import IconButton from './IconButton.js'

import './IconMenu.scss'
import { iconTypes } from './icons/IconFactory'

const categoryOrder = [
  iconTypes.chair,
  iconTypes.desk,
  iconTypes.storage,
  iconTypes.table,
  iconTypes.lounge,
  iconTypes.plant,
  iconTypes.electronic,
  iconTypes.other]

class IconMenu extends Component {

  render () {

    const icons = this.renderIcons()
    const anySelected = this.props.selectedCategory && this.props.selectedCategory.length > 0
    const styleClass = anySelected ? ' selected' : ''

    return (
      <div className={'side-bar-icon-menu' + styleClass}>
        <ul className={'side-bar-icon-menu-list'}>
          {icons}
        </ul>
      </div>
    )
  }

  renderIcons () {
    return categoryOrder.map((category) => {

      const active = category === this.props.selectedCategory
      const styleClass = active ? ' selected' : ''

      return (
        <div className={'icon-menu-button-container' + styleClass}
             key={category}>
          <IconButton
            className={'icon-menu-button'}
            icon={category}
            active={active}
            onClick={() => this.props.onIconClicked(category)}/>
          <div className={'icon-menu-button-indicator'}/>
        </div>
      )
    })
  }
}

export default IconMenu
