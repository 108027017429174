export const tools = {
  none: 'none',

  select: 'select',
  pan: 'pan',

  upload: 'upload',
  scale: 'scale',

  outline: 'outline',
  wall: 'wall',

  door: 'door',
  window: 'window',
  pillar: 'pillar',

  slicing: 'slicing',

  markzone: 'markzone',
  markblocked: 'markblocked',
  markservice: 'markservice',
  markroom: 'markroom',
  markinfrastructure: 'markinfrastructure',

  axesautogenerate: 'axesautogenerate',
  axesdelete: 'axesdelete',
  axesedit: 'axesedit',
}

export const shortcuts = {
  esc: 'Esc = Abort process',
  ctrlF: 'Ctrl + F = Convert shapes',
  ctrlD: 'Ctrl + D = Duplicate with offset',
  space: 'Space = Switch selection / tool',
  shift: 'Hold Shift = Straight line',
  ctrl: 'Hold Ctrl = Magic Guidelines',
  doubleClickPolygon: 'Double Click (while drawing polygon) = Closing pathpoint',
  clickToSelect: 'Click = Select object',
  doubleClickPolyline: 'Double Click = Create endpoint',
  backspace: 'Backspace = Delete object',
  pan: 'Hold Right Click = Pan plan',
  enter: 'Enter = Finish drawing',
  enterPolygon: 'Enter = Close path',
  enterAxes: 'Enter = Finish line',
  alt: 'Alt + Move mouse = Change size',
}