import React, { useState } from 'react'
import { Manager, Popper, Reference } from 'react-popper'
import { ButtonMenuItem } from './ButtonMenuItem'
import ProjectsMenu from './ProjectsMenu'
import { useSelector } from 'react-redux'

function ProjectMenuItem() {

  const [show, setShow] = useState(false);
  const advancedSettings = useSelector(state => state.settings.appSettings.advancedSettings);


    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <li ref={ref}>
              <ButtonMenuItem className={'navigation-menu-separate'}
                              title={'Project'}
                              active={show}
                              darkMode={advancedSettings}
                              onClick={() => handleMenuItemClick()}/>
            </li>
          )}
        </Reference>
        {show &&
        <Popper placement="bottom-start">
          {({ ref, style, placement }) => (
            <div ref={ref} style={{ ...style, zIndex: 3 }} data-placement={placement}>
              <ProjectsMenu onClose={() => handleClose()}/>
            </div>
          )}
        </Popper>}

      </Manager>
    )



  function handleMenuItemClick () {
    let newValue = !show
    setShow(newValue)
  }

  function handleClose () {
    setShow(false)
  }


}



export default ProjectMenuItem