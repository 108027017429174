import { applicationBuilderActionTypes } from '../constants'

export const selectedElementType = {
  FLOOR: 1,
  WALL: 2,
  FURNITURE: 3,
}

let initialState = {
  selectedElement: {
    type: selectedElementType.FLOOR,
    data: '',
  },
  roomSizeLocked: false,
}

function uiStateReducer (state = initialState, action) {

  switch (action.type) {
    case applicationBuilderActionTypes.CHANGE_SELECTED_ITEM:
      return { ...state, selectedElement: action.selectedElement }

    case applicationBuilderActionTypes.LOCK_ROOM_SIZE:
      return { ...state, roomSizeLocked: action.locked }

    default:
      return { ...state }
  }
}

export default uiStateReducer