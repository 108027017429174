import React, { Component } from 'react'
import { getSelectedOrganisationalUnitSummary } from '../../reducers/organisationalUnits'
import { connect } from 'react-redux'
import Dashboard from './Dashboard'

class DashboardContainer extends Component {

  render () {
    console.log("summary")
    console.log(this.props.summary)
    return (
      <React.Fragment>
        <Dashboard summary={this.props.summary}
                   viewMode={this.props.viewMode}
                   onViewModeChange={this.props.onViewModeChange}
                   floorplan={this.props.floorplan}/>
      </React.Fragment>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    summary: getSelectedOrganisationalUnitSummary(state),
    floorplan: state.floorPlan.floorPlan,
  }
}

export default connect(mapStateToProps)(DashboardContainer)