import React from 'react'

const SvgDrawNavbarNumber3Active = props =>
  <svg
    viewBox="0 0 20 20"
    width={20} height={20}
    className={props.className}
  >
    <title>{'Group 5'}</title>
    <g fill="none" fillRule="evenodd">
      <circle fill="#C4C6D2" cx={10} cy={10} r={10}/>
      <path
        d="M10.154 14.048c1.668 0 2.724-.888 2.724-2.148 0-.984-.696-1.68-1.62-1.812a1.76 1.76 0 001.464-1.752c0-1.272-.924-2.136-2.592-2.136-1.704 0-2.748 1.14-2.748 2.7l1.008.036c0-1.2.66-1.896 1.728-1.896 1.044 0 1.572.564 1.572 1.38 0 .972-.732 1.296-1.524 1.296-.336 0-.552-.012-.72-.036v.852a5.7 5.7 0 01.72-.036c.948 0 1.668.468 1.668 1.332 0 .9-.684 1.38-1.668 1.38-1.14 0-1.872-.612-1.896-1.812l-1.02-.048c0 1.68 1.104 2.7 2.904 2.7z"
        fill="#00293B"
        fillRule="nonzero"
      />
    </g>
  </svg>

export default SvgDrawNavbarNumber3Active