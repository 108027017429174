import {Geometry} from './Geometry'
import {geometryTypes} from '../config/GeometryTypes'
import {
    addVertex,
    addVertexToEdge,
    getLastVertex,
    getVerticesCount,
    moveDelta,
    removeLastVertex,
    setDraggedVertex,
    setDraggedVertex1,
    setDraggedVertex2,
    updateVertex
} from './utils/GeometryBasicFunctionsUtils'

export const polylineActions = {
    drag: 'drag',

    addVertex: 'addVertex',
}

export class PolylineGeometry extends Geometry {

    constructor(parent, representationType) {
        super(parent, representationType, polylineActions)

        this.geometryType = geometryTypes.polyline

        this.addVertex = (position) => addVertex(position, this)
        this.addVertexToEdge = (edge, position) => addVertexToEdge(edge, position, this)
        this.updateVertex = (position, vertex = null) => updateVertex(position, this, vertex)
        this.removeLastVertex = () => removeLastVertex(this)
        this.moveDelta = (movement) => moveDelta(movement, this)
        this.setDraggedVertex = (vertex) => setDraggedVertex(vertex, this)
        this.setDraggedVertex1 = (vertex) => setDraggedVertex1(vertex, this)
        this.setDraggedVertex2 = (vertex) => setDraggedVertex2(vertex, this)
        this.getLastVertex = () => getLastVertex(this)
        this.getVerticesCount = () => getVerticesCount(this)

    }

    close() {
        console.log("close Polyline")
        const lastVertex = this.vertexGroup.getVertexByIndex(-1)
        this.edgeGroup.removeEdges(lastVertex.getEdges())
        this.vertexGroup.remove(lastVertex)
    }

}