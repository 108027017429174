import * as React from 'react'
const SvgSelectActive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0008 17.5998L12.3008 7.55762L11.3008 21.6547L14.9796 17.8123L17.6003 25.2836L19.6037 24.5995L16.8614 17.0649L22.0008 17.5998Z" fill="url(#paint0_linear_12_4312)"/>
    <path d="M12.3008 7.55762L12.6604 7.21024L11.8814 6.40373L11.802 7.52224L12.3008 7.55762ZM22.0008 17.5998L21.949 18.0971L23.3135 18.2391L22.3604 17.2524L22.0008 17.5998ZM11.3008 21.6547L10.802 21.6194L10.704 23.001L11.6619 22.0005L11.3008 21.6547ZM14.9796 17.8123L15.4514 17.6468L15.1818 16.8781L14.6184 17.4665L14.9796 17.8123ZM17.6003 25.2836L17.1285 25.4491L17.2927 25.917L17.7619 25.7568L17.6003 25.2836ZM19.6037 24.5995L19.7653 25.0727L20.248 24.9079L20.0736 24.4285L19.6037 24.5995ZM16.8614 17.0649L16.9131 16.5676L16.1181 16.4849L16.3915 17.2359L16.8614 17.0649ZM11.9412 7.90499L21.6412 17.9471L22.3604 17.2524L12.6604 7.21024L11.9412 7.90499ZM11.7995 21.6901L12.7995 7.593L11.802 7.52224L10.802 21.6194L11.7995 21.6901ZM14.6184 17.4665L10.9396 21.309L11.6619 22.0005L15.3408 18.158L14.6184 17.4665ZM14.5078 17.9778L17.1285 25.4491L18.0722 25.1181L15.4514 17.6468L14.5078 17.9778ZM17.7619 25.7568L19.7653 25.0727L19.4422 24.1264L17.4388 24.8104L17.7619 25.7568ZM20.0736 24.4285L17.3312 16.8939L16.3915 17.2359L19.1339 24.7705L20.0736 24.4285ZM22.0525 17.1025L16.9131 16.5676L16.8096 17.5622L21.949 18.0971L22.0525 17.1025Z" fill="#DA5B2B"/>
    <defs>
      <linearGradient id="paint0_linear_12_4312" x1="22.0006" y1="25.2853" x2="22.0006" y2="7.55939" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
    </defs>
  </svg>
)

export default SvgSelectActive