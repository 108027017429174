import * as React from 'react'

const DrawPillarNew = props =>
  <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <g fillRule="nonzero" stroke={props.color} fill="none">
      <path d="M9 25h14M9 7h14"/>
      <rect x={13} y={7} width={6} height={18} rx={1}/>
    </g>
  </svg>
export default DrawPillarNew
