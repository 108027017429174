import React from 'react'
import PropTypes from 'prop-types'

import SvgDoorsActive from './CCD_Icons_Zeichentool_Doors_active.js'
import SvgDoorsDisabled from './CCD_Icons_Zeichentool_Doors_disabled.js'
import SvgDoorsInactive from './CCD_Icons_Zeichentool_Doors_inactive.js'

import SvgAutoAxesActive from './CCD_Icons_Zeichentool_Auto_Axes_active.js'
import SvgAutoAxesDisabled from './CCD_Icons_Zeichentool_Auto_Axes_disabled.js'
import SvgAutoAxesInactive from './CCD_Icons_Zeichentool_Auto_Axes_inactive.js'

import SvgBlockedAreaActive from "./CCD_Icons_Zeichentool_Blocked_Area_active.js"
import SvgBlockedAreaDisabled from "./CCD_Icons_Zeichentool_Blocked_Area_disabled.js"
import SvgBlockedAreaInactive from "./CCD_Icons_Zeichentool_Blocked_Area_inactive.js"

import SvgDeleteAxesActive from './CCD_Icons_Zeichentool_Delete_Axes_active.js'
import SvgDeleteAxesDisabled from './CCD_Icons_Zeichentool_Delete_Axes_disabled.js'
import SvgDeleteAxesInactive from './CCD_Icons_Zeichentool_Delete_Axes_inactive.js'

import SvgDrawAxesActive from  "./CCD_Icons_Zeichentool_Draw_Axes_active.js"
import SvgDrawAxesDisabled from  "./CCD_Icons_Zeichentool_Draw_Axes_disabled.js"
import SvgDrawAxesInactive from  "./CCD_Icons_Zeichentool_Draw_Axes_inactive.js"

import SvgInfrastructureAreaActive from "./CCD_Icons_Zeichentool_Infrastructure_Area_active.js"
import SvgInfrastructureAreaDisabled from "./CCD_Icons_Zeichentool_Infrastructure_Area_disabled.js"
import SvgInfrastructureAreaInactive from "./CCD_Icons_Zeichentool_Infrastructure_Area_inactive.js"

import SvgInnerWallsActive from  "./CCD_Icons_Zeichentool_Inner_Walls_active.js"
import SvgInnerWallsDisabled from  "./CCD_Icons_Zeichentool_Inner_Walls_disabled.js"
import SvgInnerWallsInactive from  "./CCD_Icons_Zeichentool_Inner_Walls_inactive.js"

import SvgOuterWallsActive from  "./CCD_Icons_Zeichentool_Outer_Walls_active.js"
import SvgOuterWallsDisabled from  "./CCD_Icons_Zeichentool_Outer_Walls_disabled.js"
import SvgOuterWallsInactive from  "./CCD_Icons_Zeichentool_Outer_Walls_inactive.js"

import SvgRoomAreaActive from  "./CCD_Icons_Zeichentool_Room_Area_active.js"
import SvgRoomAreaDisabled from  "./CCD_Icons_Zeichentool_Room_Area_disabled.js"
import SvgRoomAreaInactive from  "./CCD_Icons_Zeichentool_Room_Area_inactive.js"

import SvgServiceAreaActive from  "./CCD_Icons_Zeichentool_Service_Area_active.js"
import SvgServiceAreaDisabled from  "./CCD_Icons_Zeichentool_Service_Area_disabled.js"
import SvgServiceAreaInactive from  "./CCD_Icons_Zeichentool_Service_Area_inactive.js"

import SvgStuetzenActive from  "./CCD_Icons_Zeichentool_Stuetzen_active.js"
import SvgStuetzenDisabled from  "./CCD_Icons_Zeichentool_Stuetzen_disabled.js"
import SvgStuetzenInactive from  "./CCD_Icons_Zeichentool_Stuetzen_inactive.js"

import SvgWindowsActive from  "./CCD_Icons_Zeichentool_Windows_active.js"
import SvgWindowsDisabled from  "./CCD_Icons_Zeichentool_Windows_disabled.js"
import SvgWindowsInactive from  "./CCD_Icons_Zeichentool_Windows_inactive.js"

import SvgWorkAreaActive from  "./CCD_Icons_Zeichentool_Work_Area_active.js"
import SvgWorkAreaDisabled from  "./CCD_Icons_Zeichentool_Work_Area_disabled.js"
import SvgWorkAreaInactive from  "./CCD_Icons_Zeichentool_Work_Area_inactive.js"

import SvgPanActive from  "./CCD_Icons_Zeichentool_Pan_active.js"
import SvgPanInactive from  "./CCD_Icons_Zeichentool_Pan_inactive.js"

import SvgScaleActive from  "./CCD_Icons_Zeichentool_Scale_active.js"
import SvgScaleInactive from  "./CCD_Icons_Zeichentool_Scale_inactive.js"

import SvgSelectActive from  "./CCD_Icons_Zeichentool_select_active.js"
import SvgSelectInactive from  "./CCD_Icons_Zeichentool_select_inactive.js"

import SvgUploadActive from  "./CCD_Icons_Zeichentool_upload_active.js"
import SvgUploadInactive from  "./CCD_Icons_Zeichentool_upload_inactive.js"

import Divider from  "./Divider.js"
import DividerProcess from  "./Divider_Process.js"
/*
import SvgServiceAreaInactive from './CCD_Icons_Zeichentool_Service_Area_inactive.js'
import SvgBlockedAreaActive from './CCD_Icons_Zeichentool_Blocked_Area_active.js'
import SvgDrawAxesInactive from './CCD_Icons_Zeichentool_Draw_Axes_inactive.js'
*/


/**
 * Common component to integrate icons.
 * You can define the following props:
 *
 * name: the name of the icon
 * active: if the icon should be shown in active version. Note: not all icons have an active version
 * inverted: if the icon should be shown in inverted version. This will change the icon color to work on a dark background
 * color: define a color string to change the icon stroke color. This will override the above changes to the color of the icon stroke.
 *
 * @param props
 * @returns {*} the icon svg element
 * @constructor
 */

const defaultSvgPrimaryColor = '#00293b'
const defaultSvgInvertedColor = '#ffffff'
const defaultSvgDisabledColor = '#c4c6d2'
const defaultSvgHighlightColor = '#f5854e'
const SvgIcon = props => {

  const { disabledColor = defaultSvgDisabledColor, invertedColor = defaultSvgInvertedColor, primaryColor = defaultSvgPrimaryColor, highlightColor = defaultSvgHighlightColor } = props

  let color = props.disabled ? disabledColor : props.color ? props.color : props.highlight ? highlightColor : props.inverted ? invertedColor : primaryColor

  //console.log("ACTIVE: "+props.active+" disabled: "+props.disabled)
  return <NEWSVG className={props.className}
              active={props.active}
              focus={props.focus}
              name={props.name}
              disabled={props.disabled}
              color={color}
              />
}

SvgIcon.propTypes = {
  name: PropTypes.string,
  active: PropTypes.bool,
  inverted: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
}
export const svgIconTypes = {
    pan:			"pan",
    select:			"select",

    upload:			"upload",
    scale:			"scale",

    outerWalls:		"outer-walls",
    innerWalls:		"inner-walls",

    doors:			"doors",
    windows:		"windows",
    pillars:			"pillars",

    autoAxes:		"auto-axes",
    deleteAxes:		"delete-axes",
    drawAxes:		"draw-axes",

    workArea:		"work-area",
    roomArea:		"room-area",
    serviceArea:		"service-area",
    infrastructureArea:	"infrastructure-area",
    blockedArea:		"blocked-area",

    divider:    'divider',
    dividerProcess:    'divider-process',
}

SvgIcon.propTypes = {
  name: PropTypes.string,
  active: PropTypes.bool,
  inverted: PropTypes.bool,
  color: PropTypes.string,
}

const NEWSVG = props => {

  const { active, name, disabled } = props
  //console.log("ICON NAME: "+name+"  active: "+active+"  disabled: "+disabled)

  switch (name) {
    case svgIconTypes.select:
      if(active) return  <SvgSelectActive {...props}/>
      return <SvgSelectInactive {...props}/>

    case svgIconTypes.pan:
      if(active) return  <SvgPanActive {...props}/>
      return <SvgPanInactive {...props}/>


    case svgIconTypes.upload:
      if(active) return  <SvgUploadActive {...props}/>
      return <SvgUploadInactive {...props}/>

    case svgIconTypes.scale:
      if(active) return  <SvgScaleActive {...props}/>
      return <SvgScaleInactive {...props}/>


    case svgIconTypes.outerWalls:
      if(active) return  <SvgOuterWallsActive {...props}/>
      if(disabled) return  <SvgOuterWallsDisabled {...props}/>
      return <SvgOuterWallsInactive {...props}/>

    case svgIconTypes.innerWalls:
      if(active) return  <SvgInnerWallsActive {...props}/>
      if(disabled) return  <SvgInnerWallsDisabled {...props}/>
      return <SvgInnerWallsInactive {...props}/>



    case svgIconTypes.windows:
      if(active) return  <SvgWindowsActive {...props}/>
      if(disabled) return  <SvgWindowsDisabled {...props}/>
      return <SvgWindowsInactive {...props}/>

    case svgIconTypes.doors:
      if(active) return  <SvgDoorsActive {...props}/>
      if(disabled) return  <SvgDoorsDisabled {...props}/>
      return <SvgDoorsInactive {...props}/>

    case svgIconTypes.pillars:
      if(active) return  <SvgStuetzenActive {...props}/>
      if(disabled) return  <SvgStuetzenDisabled {...props}/>
      return <SvgStuetzenInactive {...props}/>


    case svgIconTypes.workArea:
      if(active) return  <SvgWorkAreaActive {...props}/>
      if(disabled) return  <SvgWorkAreaDisabled {...props}/>
      return <SvgWorkAreaInactive {...props}/>

    case svgIconTypes.roomArea:
      if(active) return  <SvgRoomAreaActive {...props}/>
      if(disabled) return  <SvgRoomAreaDisabled {...props}/>
      return <SvgRoomAreaInactive {...props}/>

    case svgIconTypes.serviceArea:
      if(active) return  <SvgServiceAreaActive {...props}/>
      if(disabled) return  <SvgServiceAreaDisabled {...props}/>
      return <SvgServiceAreaInactive {...props}/>

    case svgIconTypes.infrastructureArea:
      if(active) return  <SvgInfrastructureAreaActive {...props}/>
      if(disabled) return  <SvgInfrastructureAreaDisabled {...props}/>
      return <SvgInfrastructureAreaInactive {...props}/>

    case svgIconTypes.blockedArea:
      if(active) return  <SvgBlockedAreaActive {...props}/>
      if(disabled) return  <SvgBlockedAreaDisabled {...props}/>
      return <SvgBlockedAreaInactive {...props}/>


    case svgIconTypes.autoAxes:
      if(active) return  <SvgAutoAxesActive {...props}/>
      if(disabled) return  <SvgAutoAxesDisabled {...props}/>
      return <SvgAutoAxesInactive {...props}/>

    case svgIconTypes.deleteAxes:
      if(active) return  <SvgDeleteAxesActive {...props}/>
      if(disabled) return  <SvgDeleteAxesDisabled {...props}/>
      return <SvgDeleteAxesInactive {...props}/>

    case svgIconTypes.drawAxes:
      if(active) return  <SvgDrawAxesActive {...props}/>
      if(disabled) return  <SvgDrawAxesDisabled {...props}/>
      return <SvgDrawAxesInactive {...props}/>


    case svgIconTypes.divider:
      if (disabled) return <DividerProcess {...props}/>
      return <Divider {...props}/>

    default:
      return <SvgScaleInactive {...props}/>
  }

}
export default SvgIcon