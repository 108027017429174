import React, { Children, Component, Fragment } from 'react'

class RadioGroup extends Component {

  render () {

    let options = this.renderOptions()

    return (
      <Fragment>
        {options}
      </Fragment>
    )
  }

  renderOptions () {

    return Children.map(this.props.children, (child, index) => {

      if (!React.isValidElement(child)) {
        return null
      }

      return React.cloneElement(child, {
        id: `radio-option-${this.props.name}-${index}`,
        checked: this.props.value === child.props.value,
        name: this.props.name,
        onChange: this.props.onChange,
      })

    })
  }
}

export default RadioGroup