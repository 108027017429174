
import { Vertex } from '../geometries/VertexFactory'
import { geometryToPositionArray,isVerticesContainsVertex,isVerticesContainsAllVertices } from '../helper/GeometryHelper'
export function jsonPositionToVertex (x, y) {
  let pos = new Object()
  pos.x = x
  pos.y = y
  //console.log(x+" "+y)
  let vertex = new Vertex()
  vertex.position.x = pos.x
  vertex.position.y = pos.y
  //console.log("XY:"+pos.x+":"+pos.y)
  return vertex
}

export function vertexToJSONPosition(vertex){
  let x=vertex.position.x
  let y=vertex.position.y
  let jsonPos=[2]
  jsonPos[0]=x
  jsonPos[1]=y
  return jsonPos;
}

export function findAxes(space,edgeGroup){
  let spacePos=geometryToPositionArray(space)
  let foundAxes=[]
  let edgeNumber=edgeGroup.children.length;
  console.log("nEDGES: "+edgeNumber)
  for(let i=edgeNumber-1; i>=0; i--){
    console.log("iEdge: "+i)
    let edge=edgeGroup.children[i]
    let vertices=edge.getVertices()
    let positions=[]
    for(let iVertex=0; iVertex<vertices.length; iVertex++){
      let vertex=vertices[iVertex]
      let position=vertex.position
      positions.push(position)
      console.log(position.x+":::"+position.y)
    }

    if(isVerticesContainsAllVertices(spacePos,positions)) {
      foundAxes.push(edge)
      console.log("container found!!")
    }else{
      console.log("no container found!!")
    }
  }
  return foundAxes
}

export function getStartAndEndPosition(edge){
  let vertices=edge.getVertices()
  let positions=[]
  for(let iVertex=0; iVertex<vertices.length; iVertex++){
    let vertex=vertices[iVertex]
    let position=vertex.position
    positions.push(position)
    console.log(position.x+":::"+position.y)
  }
  return positions
}
export function findSpaceAtPos(spaceGeometries,position){
  let nSpaces=spaceGeometries.length
  for(let iSpace=0; iSpace<nSpaces; iSpace++){
    let space=spaceGeometries[iSpace]
    let spacePos=geometryToPositionArray(space)
    if(isVerticesContainsVertex(spacePos,position)) {
      //console.log("inside")
      return space
    }
    else
      console.log("outside")
  }
  return null
}

export function edgeToJSON(edge){
  let verts = edge.getVertices()
  let uuid = edge.uuid
  console.log("edge: "+uuid+": " + verts) //+" :: "+edge.startVertex+" :: "+edge.endVertex)
  let startVertexUUID = "" + verts[0].uuid
  let endVertexUUID = "" + verts[1].uuid
  let startPosition = verts[0].position
  let endPosition = verts[1].position
  console.log("without extra vectors")
  console.log("S: " + startVertexUUID + " :: " + endVertexUUID)
  console.log("S: " + startPosition.x + ":" + startPosition.y + " :: " + endPosition.x + ":" + endPosition.y)
  console.log("uuid:" + uuid)

  console.log(verts[0].toJSON())
  console.log(verts[1].toJSON())


  let axisJSON = {
    uuid: uuid,
    startVertex: verts[0].toJSON(),
    endVertex: verts[1].toJSON(),
  }
  console.log("axisJSON:")
  console.log(axisJSON)

  return axisJSON
}

export function edgeAndVerticesToJSON(edge,vectors){
  let verts=edge.getVertices()
  let uuid = edge.uuid
  //console.log("edge: "+uuid) //+" :: "+edge.startVertex+" :: "+edge.endVertex)
  //console.log("with extra vectors")
  let startVertexUUID = "" + verts[0].uuid
  let endVertexUUID = "" + verts[1].uuid
  verts[0].setPosition(vectors[0])
  verts[1].setPosition(vectors[1])

  //console.log(verts[0].toJSON())
  //console.log(verts[1].toJSON())

  let axisJSON = {
    uuid: uuid,
    startVertex: verts[0].toJSON(),
    endVertex: verts[1].toJSON(),
  }
  console.log("axisEVJSON:")
  console.log(axisJSON)

  return axisJSON
}

