import React from 'react'
import SelectButton from './SelectButton'

function AssignedFloorSelectButton ({ disabled, field, name, onChange, floors, value }) {

  let mappedOptions = [
    {
      label: 'Unassigned',
      value: 'Unassigned',
    },
  ]

  mappedOptions = mappedOptions.concat(floors.map(style => ({
    label: style.name,
    value: style.id,
  })))

  return (
    <SelectButton disabled={disabled}
                  field={field}
                  name={name}
                  onChange={onChange}
                  value={value}
                  options={mappedOptions}/>
  )
}

export default AssignedFloorSelectButton