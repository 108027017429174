import React, { Component } from 'react'
import { connect } from 'react-redux'

import { dragBlockingItem } from '../../../actions/ui-actions'

import InspectorContainer from '../../../../common/components/InspectorContainer'
import TreeList from '../../../../common/components/TreeList'

import { SidebarTitle } from './SidebarTitle'
import { parseToTreeListNodes } from '../../../../common/components/buildingsFloorsInspector/parseTreeList'

import './Blocking.scss'
import { iconTypes } from '../../../../common/components/icons/IconFactory'

class Blocking extends Component {

  render () {
    let nodes = parseToTreeListNodes(this.props.units, false, this.props.shortVersion)

    const handleLevelItemDragged = [
      (id) => this.handleListItemDragged(id),
      (id) => this.handleListItemDragged(id),
    ]

    return (
      <InspectorContainer className={'blocking'} scrollable>
        <SidebarTitle title={'Not assigned people'} icon={iconTypes.movingCar}/>
        <TreeList className={'blocking-building-tree-list-container'}
                  nodes={nodes}
                  onLevelItemDragged={handleLevelItemDragged}
                  maxLevel={1}/>
      </InspectorContainer>
    )
  }

  handleListItemDragged (node) {
    if (!node.disabled)
      this.props.dragBlockingItem(node.key)
    else
      this.props.dragBlockingItem(null)
  }

}

let mapStateToProps = (state) => {
  return {
    units: state.placedOrganisationalUnits.unassignedDepartments,
  }
}

let mapDispatchToProps = {
  dragBlockingItem: dragBlockingItem,
}

export default connect(mapStateToProps, mapDispatchToProps)(Blocking)