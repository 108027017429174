import React from 'react'

const SvgRedoIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#383B52"
        fillRule="nonzero"
        d="M15 14.214L19.714 9.5 15 4.786z"
      />
      <path
        stroke="#393B53"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.5 18.5h-1a4.5 4.5 0 110-9H16"
      />
    </g>
  </svg>
)

export default SvgRedoIcon
