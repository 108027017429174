import React from 'react'
import Dialog, { DialogButtonGroup } from './Dialog'
import Button from './Button'
import './ConfirmationDialog.scss'
import { useTranslation } from 'react-i18next'

export default function ConfirmationDialog ({ onCancel = () => {}, hideModal = () => {}, onConfirm = () => {}, text = 'Are you sure?', title = 'Please confirm your action', ...rest }) {
  const { t } = useTranslation()
  return (
    <Dialog {...rest} className={'confirmation-dialog'} hideModal={hideModal} onCancel={onCancel} text={text} title={title}>
      <DialogButtonGroup className={'confirmation-dialog-button-group'}>
        <Button variant={'outlined'}
                onClick={() => {
                  onCancel()
                  hideModal()
                }}>
          {t('cancel')}
        </Button>
        <Button variant={'contained'}
                onClick={() => {
                  onConfirm()
                  hideModal()
                }}>
          {t('confirm')}
        </Button>
      </DialogButtonGroup>
    </Dialog>
  )
}