import { PolygonController } from './PolygonController'

export class OffsetPolygonController extends PolygonController {

  onArrowLeftDown (event, offsetPolygon) {
    if (!offsetPolygon)
      return

    offsetPolygon.shiftOffsetToLeft()
    this.floorPlanerController.saveChanges()
  }

  onArrowRightDown (event, offsetPolygon) {
    if (!offsetPolygon)
      return

    offsetPolygon.shiftOffsetToRight()
    this.floorPlanerController.saveChanges()
  }

  onArrowUpDown (event, offsetPolygon) {
    if (!offsetPolygon)
      return

    offsetPolygon.addToOffset(.2)

    this.floorPlanerController.saveChanges()
  }

  onArrowDownDown (event, offsetPolygon) {
    if (!offsetPolygon)
      return

    offsetPolygon.addToOffset(-.2)
    this.floorPlanerController.saveChanges()
  }

}