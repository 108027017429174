import React from 'react'

const SvgDrawNavbarNumber5 = props =>
  <svg
    viewBox="0 0 20 20"
    width={20} height={20}
    className={props.className}
  >
    <title>{'Group 5'}</title>
    <g fill="none" fillRule="evenodd">
      <circle fill="#E8E9EE" cx={10} cy={10} r={10}/>
      <path
        d="M9.95 13.904c1.643 0 2.747-1.08 2.747-2.652 0-1.392-.888-2.544-2.604-2.544-.84 0-1.452.348-1.8.768.084-.384.264-1.932.276-2.34h3.624V6.2H7.765c-.036 1.248-.288 3.18-.48 4.224l1.032.072c.276-.516.816-.96 1.656-.96 1.068 0 1.716.732 1.716 1.728 0 1.104-.648 1.812-1.716 1.812-1.188 0-1.8-.768-1.8-1.908l-1.008-.084c-.084 1.68.96 2.82 2.784 2.82z"
        fill="#00293B"
        fillRule="nonzero"
      />
    </g>
  </svg>

export default SvgDrawNavbarNumber5