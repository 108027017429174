import { GeometryController } from './GeometryController'
import { addRadiusToPreviewStamp, addSegmentToPreviewStamp, getPreviewStampConfig, rotatePreviewStamp, setPreviewStamp } from '../helper/PreviewStamp'
import { StampGeometry } from '../geometries/StampGeometry'
import { getSnappingPosition } from '../helper/MagicGuideLine'

export class StampController extends GeometryController {

  setActive (active) {
    if (!active) {
      setPreviewStamp()
    } else {
      const position = this.getCurrentPosition()
      setPreviewStamp(position, this.representationType)
    }
  }

  onLeftMouseDown (event, geometry) {
    console.log("add stamp")
    console.log(geometry)

    if (!geometry) {
      return this.onLeftMouseDownNew(event)
    }

    return false
  }

  onLeftMouseDownNew (event) {
    const config = getPreviewStampConfig()
    const position = this.getCurrentPosition(event)
    const stampGeometry = new StampGeometry(this.group, this.representationType, config)

    console.log("STAMP update positioen:  "+this.representationType)
    console.log(config)
    stampGeometry.updatePosition(position)

    this.floorPlanerController.saveChanges()

    return true
  }

  onBackspaceDown (event, geometry, currentGeometries) {
    console.log("stampController");
    if (!geometry)
      return false

    switch (geometry.getAction()) {
      case geometry.actions.select: {

        this.removeGeometryFromGroup(geometry, currentGeometries)
        this.floorPlanerController.saveChanges()
        return true
      }
      default:
        return false
    }
  }

  onArrowUpDown (event, geometry) {
    if (!geometry) {
      addSegmentToPreviewStamp(1)
    } else {
      geometry.addSegment(1)
    }
  }

  onArrowDownDown (event, geometry) {
    if (!geometry) {
      addSegmentToPreviewStamp(-1)
    } else {
      geometry.addSegment(-1)
    }
  }

  onArrowLeftDown (event, geometry) {
    if (!geometry) {
      rotatePreviewStamp(5)
    } else {
      geometry.rotate(5)
    }
  }

  onArrowRightDown (event, geometry) {
    if (!geometry) {
      rotatePreviewStamp(-5)
    } else {
      geometry.rotate(-5)
    }
  }

  onMouseWheel (event, geometry) {
    if (!event.altKey) {
      return
    }


    const step = -Math.sign(event.deltaY) * 0.05

    if (!geometry) {
      addRadiusToPreviewStamp(step)
    } else {
      geometry.addRadius(step)
    }
  }

  onMouseMove (event, geometry, currentGeometries) {
    if (!geometry && !currentGeometries.length) {
      const position = this.getCurrentPosition(event)

      setPreviewStamp(position, this.representationType)
      return false
    }

    return false
  }

  onAltDown () {
    this.floorPlanerController.setEnableZoom(false)
  }

  onAltUp () {
    this.floorPlanerController.setEnableZoom(true)
  }

  getCurrentPosition (event, ignoreVertex = null) {
    const otherPositions = this.floorPlanerController.getAllVertices()
      .filter(vertex => vertex !== ignoreVertex)
      .map(vertex => vertex.position)

    return getSnappingPosition(event, this.floorPlanerController.getCurrentPosition(), otherPositions)
  }

}