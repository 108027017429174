import axios from 'axios'
import { configureInterceptors } from './httpClientInterceptors'

class HttpClient {

  constructor () {
    if (this.client) {
      return
    }

    this.client = axios.create()

    configureInterceptors(this.client)
  }

  async get (url, queryParams = {}) {
    return await this.client.get(url, { params: queryParams })
  }

  async getFile (url, queryParams = {}) {
    return await this.client.get(url, { params: queryParams, responseType: 'blob' })
  }

  async postFile (url, body) {
    return await this.client.post(url, body, { responseType: 'blob' })
  }

  async post (url, body) {
    return await this.client.post(url, this.parseBody(body))
  }

  async put (url, body) {
    return await this.client.put(url, this.parseBody(body))
  }

  async patch (url, body) {
    return await this.client.patch(url, this.parseBody(body))
  }

  async delete (url) {
    return await this.client.delete(url)
  }

  parseBody (body) {
    return typeof body === 'string' ? '"' + body + '"' : body
  }
}

export const httpClient = new HttpClient()
