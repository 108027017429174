import * as React from 'react'

const SvgRename = props =>
  (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
    >
      <g stroke="#00293B" fill="none" fillRule="evenodd" strokeLinejoin="round">
        <path strokeLinecap="round" d="M15.5 5v14"/>
        <path d="M17 8h2a1 1 0 011 1v6a1 1 0 01-1 1h-1 0-1m-3 0H5a1 1 0 01-1-1V9a1 1 0 011-1h9"/>
        <path strokeLinecap="round" d="M17.5 5h-4m4 14h-4"/>
      </g>
    </svg>
  )

export default SvgRename
