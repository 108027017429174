import * as React from 'react'

const ArchTargetIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={36}
    height={36}
    viewBox="0 0 36 36"
  >
    <defs>
      <filter
        id="prefix_arch_target_b"
        width="103.8%"
        height="103.8%"
        x="-1.9%"
        y="-1.9%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur
          in="SourceAlpha"
          result="shadowBlurInner1"
          stdDeviation={0.5}
        />
        <feOffset in="shadowBlurInner1" result="shadowOffsetInner1"/>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.129971591 0"
        />
      </filter>
      <path
        id="prefix_arch_target_a"
        d="M27.192 8.808c3.794 3.793 4.753 9.347 2.878 14.03a2.993 2.993 0 00-1.731-1.076 11 11 0 00-2.56-11.54c-4.297-4.296-11.261-4.296-15.557 0-4.296 4.296-4.296 11.26 0 15.556a11 11 0 0011.563 2.553c.148.696.54 1.303 1.081 1.728-4.689 1.89-10.258.934-14.058-2.867-5.077-5.076-5.077-13.308 0-18.384 5.076-5.077 13.308-5.077 18.384 0zm-8.49 8.47l.088.078 6.34 6.34 2.586.001a1 1 0 01.117 1.993l-.117.007h-.586l.122.121h2.586a1 1 0 01.116 1.994l-.116.006h-.586l.144.145a1 1 0 01-1.327 1.492l-.087-.078-.144-.145v.586a1 1 0 01-.884.994l-.116.006a1 1 0 01-.994-.883l-.006-.117v-2.586l-.122-.122v.587a1 1 0 01-.883.993l-.117.007a1 1 0 01-.993-.883l-.007-.117V25.11l-6.34-6.34a.999.999 0 011.327-1.491zm5.662-5.642a9.003 9.003 0 011.844 10.06h-.25L24.6 20.337a7.002 7.002 0 00-11.55-7.287 7.002 7.002 0 007.316 11.54l1.35 1.348v.262a9.003 9.003 0 01-10.08-1.836 9 9 0 010-12.728 9 9 0 0112.728 0zm-2.828 2.828a4.994 4.994 0 011.416 4.227l-2.748-2.749-.128-.12a2.7 2.7 0 00-.206-.168 3 3 0 00-3.786 4.654l2.64 2.64a5.002 5.002 0 01-4.26-8.484 5.002 5.002 0 017.072 0z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#E8E9EB" xlinkHref="#prefix_arch_target_a"/>
      <use fill="#000" filter="url(#prefix_arch_target_b)" xlinkHref="#prefix_arch_target_a"/>
    </g>
  </svg>
)

export default ArchTargetIcon
