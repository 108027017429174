import * as THREE from 'three'

import backgroundPatternImage from '../../../assets/images/jpg/CCD_Background_Pattern.jpg'
import departmentLockedImage from '../../../assets/images/png/department_locked.png'

import iconRoom from '../../../assets/images/png/icon_room.png'

import cafeteriaMarkerImage from '../../../assets/images/png/marker_cafeteria.png'
import customMarkerImage from '../../../assets/images/png/marker_custom.png'
import entranceMarkerImage from '../../../assets/images/png/marker_entrance.png'
import staircaseMarkerImage from '../../../assets/images/png/marker_staircase.png'
import toiletMarkerImage from '../../../assets/images/png/marker_toilet.png'

import scalePatternLowImage from '../../../assets/images/png/Scale_pattern_Low.png'
import scalePatternHighImage from '../../../assets/images/png/Scale_Pattern_High.png'

import areaTypePatternOpenSpace from '../../../assets/images/png/Areatypes_pattern_openspace.png'
import areaTypePatternService from '../../../assets/images/png/Areatypes_pattern_service.png'
import areaTypePatternRooms from '../../../assets/images/png/Areatypes_pattern_rooms.png'
import areaTypePatternInfrastructure from '../../../assets/images/png/Areatypes_pattern_infrastructure.png'
import areaTypePatternBlocked from '../../../assets/images/png/Areatypes_pattern_blocked.png'

import areaTypeIconOpenSpace from '../../../assets/images/png/OpenspaceIcon.png'
import areaTypeIconRooms from '../../../assets/images/png/RoomsIcon.png'
import areaTypeIconInfrastructure from '../../../assets/images/png/InfrastructureIcon.png'
import areaTypeIconBlocked from '../../../assets/images/png/BlockedIcon.png'
import areaTypeIconService from '../../../assets/images/png/RoomsIcon.png'



export const materialTypes = {
  // Drawing
  background: 'background',
  placeholder: 'placeholder',
  departmentLocked: 'departmentLocked',

  cafeteriaMarker: 'cafeteriaMarker',
  customMarker: 'customMarker',
  entranceMarker: 'entranceMarker',
  staircaseMarker: 'staircaseMarker',
  toiletMarker: 'toiletMarker',

  openSpacePattern: 'openSpacePattern',
  roomsPattern: 'roomsPattern',
  servicePattern: 'servicePattern',
  infrastructurePattern: 'infrastructurePattern',
  blockedPattern: 'blockedPattern',

  openSpaceIcon: 'openSpaceIcon',
  roomsIcon: 'roomsIcon',
  serviceIcon: 'roomsicon',
  infrastructureIcon: 'infrastructureIcon',
  blockedIcon: 'blockedIcon',

  // Floorplan
  default: 'default',
  defaultShadow: 'defaultShadow',
  defaultLine: 'defaultLine',

  black: 'black',
  turquoise: 'turquoise',
  grey: 'grey',
  white: 'white',
  transparent: 'transparent',

  scalePatternLow: 'scalePatternLow',
  scalePatternHigh: 'scalePatternHigh',
}

const materialColors = {
  default: '#ffffff',
  defaultShadow: '#ffffff',
  defaultLine: '#000000',

  black: '#000000',
  turquoise: '#a9ecea',
  grey: '#666666',
  white: '#ffffff',

}

const opacity = {
  departmentLocked: 0.05,
}

const materials = {


  background: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.background) }).clone(),
  placeholder: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.placeholder) }).clone(),
  departmentLocked: new THREE.MeshBasicMaterial(
    { map: getTexture(materialTypes.departmentLocked), depthWrite: true, color: materialColors.black, transparent: true, opacity: opacity.departmentLocked }).clone(),

  cafeteriaMarker: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.cafeteriaMarker), depthWrite: true, transparent: true }).clone(),
  customMarker: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.customMarker), depthWrite: true, transparent: true }).clone(),
  entranceMarker: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.entranceMarker), depthWrite: true, transparent: true }).clone(),
  staircaseMarker: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.staircaseMarker), depthWrite: true, transparent: true }).clone(),
  toiletMarker: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.toiletMarker), depthWrite: true, transparent: true }).clone(),

  openSpacePattern: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.openSpacePattern), depthWrite: true, transparent: true }).clone(),
  roomsPattern: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.roomsPattern), depthWrite: true, transparent: true }).clone(),
  servicePattern: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.servicePattern), depthWrite: true, transparent: true }).clone(),
  infrastructurePattern: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.infrastructurePattern), depthWrite: true, transparent: true }).clone(),
  blockedPattern: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.blockedPattern), depthWrite: true, transparent: true }).clone(),

  openSpaceIcon: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.openSpaceIcon), depthWrite: true, transparent: true }).clone(),
  roomsIcon: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.roomsIcon), depthWrite: true, transparent: true }).clone(),
  infrastructureIcon: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.infrastructureIcon), depthWrite: true, transparent: true }).clone(),
  blockedIcon: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.blockedIcon), depthWrite: true, transparent: true }).clone(),

  scalePatternLow: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.scalePatternLow), depthWrite: true, transparent: true }).clone(),
  scalePatternHigh: new THREE.MeshBasicMaterial({ map: getTexture(materialTypes.scalePatternHigh), depthWrite: true, transparent: true }).clone(),

  default: new THREE.MeshBasicMaterial({ color: materialColors.default }),
  defaultShadow: new THREE.MeshBasicMaterial({ color: materialColors.defaultShadow, depthWrite: true, shadowSide: THREE.FrontSide, side: THREE.BackSide }),
  defaultLine: new THREE.LineBasicMaterial({ color: materialColors.defaultLine }),

  black: new THREE.MeshBasicMaterial({ name: materialTypes.black, color: materialColors.black, depthWrite: true }),
  turquoise: new THREE.MeshBasicMaterial({ name: materialTypes.turquoise, color: materialColors.turquoise, depthWrite: true }),
  grey: new THREE.MeshBasicMaterial({ name: materialTypes.grey, color: materialColors.grey, depthWrite: true }),
  white: new THREE.MeshBasicMaterial({ name: materialTypes.white, color: materialColors.white, depthWrite: true }),
  transparent: new THREE.MeshBasicMaterial({ name: materialTypes.transparent, visible: false }),
}

export function getMaterial (materialType) {
  return materials[materialType]
}

function getTexture (materialType) {
  const textureLoader = new THREE.TextureLoader()

  switch (materialType) {
    case materialTypes.background:
      return textureLoader.load(backgroundPatternImage, function (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(75, 75)
      })
    case materialTypes.placeholder:
      return textureLoader.load(iconRoom, function (texture) {
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    case materialTypes.departmentLocked:
      return textureLoader.load(departmentLockedImage, function (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(.5, .5)
      })
    case materialTypes.cafeteriaMarker:
      return textureLoader.load(cafeteriaMarkerImage, function (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    case materialTypes.customMarker:
      return textureLoader.load(customMarkerImage, function (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    case materialTypes.entranceMarker:
      return textureLoader.load(entranceMarkerImage, function (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    case materialTypes.staircaseMarker:
      return textureLoader.load(staircaseMarkerImage, function (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    case materialTypes.toiletMarker:
      return textureLoader.load(toiletMarkerImage, function (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    case materialTypes.openSpacePattern: //PATTERN MATERIALS
      return textureLoader.load(areaTypePatternOpenSpace, function (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(.05, .05)
      })
    case materialTypes.roomsPattern:
      return textureLoader.load(areaTypePatternRooms, function (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(.05, .05)
      })
    case materialTypes.infrastructurePattern:
      return textureLoader.load(areaTypePatternInfrastructure, function (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(.05, .05)
      })
    case materialTypes.blockedPattern:
      return textureLoader.load(areaTypePatternBlocked, function (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(.05, .05)
      })
    case materialTypes.servicePattern:
      return textureLoader.load(areaTypePatternService, function (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(.05, .05)
      })
    case materialTypes.scalePatternLow:
      return textureLoader.load(scalePatternLowImage, function (texture) {
        texture.wrapT = texture.wrapS = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(1, 3.9)
      })
    case materialTypes.scalePatternHigh:
      return textureLoader.load(scalePatternHighImage, function (texture) {
        texture.wrapT = texture.wrapS = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(1, 3.9)
      })
    case materialTypes.openSpaceIcon:
      return textureLoader.load(areaTypeIconOpenSpace, function (texture) {
        texture.wrapT = texture.wrapS = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    case materialTypes.roomsIcon:
      return textureLoader.load(areaTypeIconService, function (texture) {
        texture.wrapT = texture.wrapS = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    case materialTypes.serviceIcon:
      return textureLoader.load(areaTypeIconRooms, function (texture) {
        texture.wrapT = texture.wrapS = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    case materialTypes.infrastructureIcon:
      return textureLoader.load(areaTypeIconInfrastructure, function (texture) {
        texture.wrapT = texture.wrapS = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    case materialTypes.blockedIcon:
      return textureLoader.load(areaTypeIconBlocked, function (texture) {
        texture.wrapT = texture.wrapS = THREE.RepeatWrapping
        texture.offset.set(0, 0)
        texture.repeat.set(1, 1)
      })
    default:
      return
  }
}

