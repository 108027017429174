import React from 'react'

const SvgAppCreatorPlantsIconInactive = props => (
  <svg className={props.className} viewBox="0 0 54 54">
    <path data-name="Rechteck 426" fill="none" d="M0 0h54v54H0z"/>
    <path
      data-name="Pfad 919"
      d="M22.389 18.113a4.393 4.393 0 0 1 3.826 3.682 4.392 4.392 0 0 1-3.826-3.682m8.848 0a4.391 4.391 0 0 1-3.826 3.682 4.394 4.394 0 0 1 3.826-3.682m-11.679 9.63a.549.549 0 0 1-.558-.538v-2.148a.548.548 0 0 1 .558-.537h6.7v-1.637a5.462 5.462 0 0 1-5.022-5.345.548.548 0 0 1 .554-.538 5.611 5.611 0 0 1 5.023 3.032A5.609 5.609 0 0 1 31.835 17a.549.549 0 0 1 .559.537 5.462 5.462 0 0 1-5.023 5.345v1.637h6.7a.549.549 0 0 1 .559.537v2.148a.549.549 0 0 1-.559.538H19.558zm1.335 8.291l-.916-7.314H33.65l-.916 7.314a.586.586 0 0 1-.589.5H21.48a.585.585 0 0 1-.587-.5z"
      fill="#8f939d"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgAppCreatorPlantsIconInactive
