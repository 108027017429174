import React from 'react'

const SvgListArrow = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill="none">
      <path
        data-name="Pfad 956"
        d="M10.156 17.813l5.657-5.657L10.156 6.5"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </g>
  </svg>
)

export default SvgListArrow
