import { CircleBufferGeometry, Mesh } from 'three'

import { representationTypes } from '../config/RepresentationTypes'
import { geometryTypes } from '../config/GeometryTypes'
import { outlineConfig } from '../config/OutlineConfig'
import { defaultConfig } from '../config/DefaultConfig'
import { scaleConfig } from '../config/ScaleConfig'
import { referenceEdgeConfig } from '../config/ReferenceEdgeConfig'
import { wallConfig } from '../config/WallConfig'
import { pillarConfig } from '../config/PillarConfig'
import { spaceConfig } from '../config/SpaceConfig'
import { axisConfig } from '../config/AxisConfig'

const vertexGeometryStore = new Map()

export function getVertexGeometryFromStore (size, segments = 32) {
  const radius = (size / 2).toFixed(2)

  if (vertexGeometryStore.has(radius))
    return vertexGeometryStore.get(radius)

  const geometry = new CircleBufferGeometry(radius, segments)
  vertexGeometryStore.set(radius, geometry)
  return geometry
}

export class Vertex extends Mesh {

  #callbacks = []

  constructor (scale, representationType = null, properties) {
    scale = scale ? scale : 1


    const config = getVertexConfigByRepresentationType(representationType)

    const innerGeometry = getVertexGeometryFromStore(config.innerSize * scale, config.innerSegments)
    const outerGeometry = getVertexGeometryFromStore(config.outerSize * scale, config.outerSegments)

    super(outerGeometry, config.outerInactiveMaterial)

    const innerMesh = new Mesh(innerGeometry, config.innerInactiveMaterial)
    innerMesh.name = 'innerVertex'
    innerMesh.position.z -= 0.001 // 0.001

    this.add(innerMesh)

    if (properties) {

      this.position.setX(properties.x)
      this.position.setY(properties.y)
    }
    this.railEdge=null
    this.geometryType = geometryTypes.vertex
    this.config = config
    this.name = 'outerVertex'
    this.edges = []
  }

  setPosition (position) {
    this.position.setX(position.x)
    this.position.setY(position.y)
    console.log("PV: x:"+this.position.x+" y:"+this.position.y+" z:"+this.position.z);
  }

  addEdge (edge) {
    if (!this.edges.includes(edge))
      this.edges.push(edge)
  }

  removeEdge (edge) {
    const edgeIndex = this.edges.indexOf(edge)
    this.edges.splice(edgeIndex, 1)
  }

  getEdges () {
    return this.edges
  }

  updateScale (scale) {
    this.scale.set(scale, scale, 1)
  }

  moveDelta (movement) {
    this.position.x += movement.x
    this.position.y += movement.y
  }

  setActive (active) {
    this.material = active ? this.config.outerActiveMaterial : this.config.outerInactiveMaterial
    if (this.children.length > 0) {
      this.children[0].material = active ? this.config.innerActiveMaterial : this.config.innerInactiveMaterial
    }
  }

  getGeometry () {
    let currentGeometry = this

    while (currentGeometry.parent) {
      if (currentGeometry.rootGeometry) {
        return currentGeometry
      }
      currentGeometry = currentGeometry.parent
    }

    return null
  }

  dispose () {
    this.#callbacks.forEach(callback => callback())
  }

  subscribe (callback) {
    if (!this.#callbacks.includes(callback))
      this.#callbacks.push(callback)
  }

  unsubscribe (callback) {
    this.#callbacks = this.#callbacks.filter(c => c !== callback)
  }

  toJSON () {
    const { x, y, z } = this.position
    return {
      uuid: this.uuid,
      x: x,
      y: y,
      z: z,
    }
  }

  fromJSON (json) {
    this.uuid = json.uuid
    this.position.setX(json.x)
    this.position.setY(json.y)
  }
}

export function getVertexConfigByRepresentationType (representationType) {
  switch (representationType) {
    case representationTypes.outline:
      return outlineConfig.vertex
    case representationTypes.scale:
      return scaleConfig.vertex
    case representationTypes.wall:
      return wallConfig.vertex
    case representationTypes.pillar:
      return pillarConfig.vertex
    case representationTypes.space:
      return spaceConfig.vertex
    case representationTypes.door:
      return referenceEdgeConfig.vertex
    case representationTypes.window:
      return referenceEdgeConfig.vertex
    default:
      return defaultConfig.vertex
  }
}