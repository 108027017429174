import React from 'react'

const SvgDrawPillarInactive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <g stroke={props.color} fill="none" fillRule="evenodd" strokeLinejoin="round">
      <path
        strokeLinecap="round"
        d="M13.616 18.003C9.236 18.567 6 20.555 6 22.921 6 25.726 10.547 28 16.157 28s10.157-2.274 10.157-5.079c0-2.37-3.248-4.361-7.64-4.921"
      />
      <path d="M14 6v17c0 .552.895 1 2 1s2-.448 2-1V6"/>
      <path d="M16 7c1.105 0 2-.448 2-1s-.895-1-2-1-2 .448-2 1 .895 1 2 1z"/>
    </g>
  </svg>

export default SvgDrawPillarInactive