export const mouseActionType = {
  dragNewElement: 'dragNewElement',
  roomDepth: 'roomDepth',
  roomWidth: 'roomWidth',
  furnXPos: 'furnXPos',
  furnZPos: 'furnZPos',
  furnFreePos: 'furnFreePos',

  mousedown: 'mousedown',
  mousemove: 'mousemove',
  mouseup: 'mouseup',
}

export const direction = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
  MID: 'MID',
  CENTER: 'CENTER',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  START: 'START',
  END: 'END',
}

export const color = {
  lineDash: '#ff0000',
  measureLineLabelBackground: '#fdfdfd',
  spaceKeeper: '#ff0000',
  gizmo: '#00A1E2',
  anchor: '#00A1E2',
  selectionPlan: '#00A1E2',
  selectionPlanHex: 0x00A1E2,
  catcher: '#007fff',
  roomCatcher: '#0000ff',
  furniturePlacerBaseLine: '#aaaaaa',
  roomMinBorder: '#888888',
  way: '#454b5a',
  moveableAxis: '#aa0000',
  moveableAxisSelection: '#aaff00',
  axisConnector: '#ff0000',
  axisConnectorSide: '#00ff00',
  axisConnectorFree: '#ff00ff',
  axisConnectorCrop: '#ff00ff',
  axisConnectorContinous: '#0000ff',
  black: '#000000',
  gray: '#777777',
  yellow: '#ffff00',
  white: '#ffffff',
  whiteHex: 0xffffff,
}