import React, { Component, useState } from 'react'
import { Manager, Popper, Reference } from 'react-popper'

import { ButtonMenuItem } from './ButtonMenuItem'

import FurnitureMenu from './FurnitureMenu'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

function FurnitureMenuItem({active}) {

  const [show, setShow] = useState(false)
  const advancedSettings = useSelector(state => state.settings.appSettings.advancedSettings)
  const { projectId } = useParams()





    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <li ref={ref}>
              <ButtonMenuItem title={'Furniture'}
                              active={show || active}
                              darkMode={advancedSettings}
                              onClick={() => handleMenuItemClick()}/>
            </li>
          )}
        </Reference>
        {show &&
        <Popper placement="bottom-start">
          {({ ref, style, placement }) => (
            <div ref={ref} style={{ ...style, zIndex: 3 }} data-placement={placement}>
              <FurnitureMenu projectId={projectId} onClose={() => handleClose()}/>
            </div>
          )}
        </Popper>}

      </Manager>
    )



  function handleMenuItemClick () {
    let newValue = !show
    setMenuState(newValue)
  }

  function handleClose () {
    setMenuState(false)
  }

  function setMenuState (show) {
    setShow(show)
  }

}



export default FurnitureMenuItem