import React, { Component } from 'react'
import { updateOrganisationalUnitTags } from '../../../actions/actions'
import { connect } from 'react-redux'
import MenuSection from '../../../../common/components/MenuSection'
import { Field, Form, Formik } from 'formik'
import AutoSave from '../../../../common/components/AutoSave'
import Chip from '../../../../common/components/Chip'
import './Tags.scss'
import { getSelectedOrganisationalUnit } from '../../../reducers/organisationalUnits'

class Tags extends Component {

  render () {

    return (
      <MenuSection className={'tags-menu'}>
        <Formik initialValues={{ tags: (this.props.unit && this.props.unit.tags) ? this.props.unit.tags.map(tag => tag.id) : [], unit: this.props.unit }} enableReinitialize>
          <Form>
            <AutoSave onSave={(values) => this.updateTags(values.unit, values.tags)}/>
            <div className={'tag-form-container'}>
              {this.props.availableTags.map((tag, index) => (
                <Field key={index}
                       name={'tags'}>
                  {({ field, form }) => (

                    <Chip className={'tag'}
                          label={tag.displayName}
                          value={field.value.includes(tag.id)}
                          name={field.name}
                          onChange={() => {
                            if (field.value.includes(tag.id)) {
                              const nextValue = field.value.filter(
                                value => value !== tag.id,
                              )
                              form.setFieldValue('tags', nextValue)
                            } else {
                              const nextValue = field.value.concat(tag.id)
                              form.setFieldValue('tags', nextValue)
                            }
                          }}/>
                  )}
                </Field>
              ))}
            </div>
          </Form>
        </Formik>
      </MenuSection>
    )
  }

  updateTags (unit, tagIds) {
    let tags = tagIds.map(tag => ({ id: tag }))
    this.props.updateTags(unit.buildingId, unit.floorId, unit.id, tags)
  }
}

let mapStateToProps = (state) => {

  return {
    unit: getSelectedOrganisationalUnit(state),
    availableTags: state.styles.tags,
  }
}

let mapDispatchToProps = {
  updateTags: updateOrganisationalUnitTags,
}

export default connect(mapStateToProps, mapDispatchToProps)(Tags)