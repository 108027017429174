import React, { Component } from 'react'
import MenuSection from '../../../../common/components/MenuSection'
import { fetchOrganisationalUnitFurnitureEnsemblesSummary, updateOrganisationalUnitApplicationProfile } from '../../../actions/actions'
import { connect } from 'react-redux'
import './ApplicationsList.scss'
import { groupBy } from 'lodash'
import { createSVG } from '../../../../common/three/svgActions'
import Text from '../../../../common/components/Text'
import { getSelectedOrganisationalUnit } from '../../../reducers/organisationalUnits'

class ApplicationsList extends Component {

  componentDidMount () {
    if (this.props.unit && this.props.unit.id) {
      this.props.fetchOrganisationalUnitFurnitureEnsemblesSummary(this.props.unit.id)
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.unit && ((prevProps.unit && this.props.unit.id !== prevProps.unit.id) || !prevProps.unit)) {
      this.props.fetchOrganisationalUnitFurnitureEnsemblesSummary(this.props.unit.id)
    }
  }

  render () {

    const categories = groupBy(this.props.summaries, summary => summary.ensembleCategory.displayName)
    const categoriesElements = []

    for (const category in categories) {
      let summaries = categories[category]

      let summaryElements = summaries.map((summary, index) => {
        let name = summary.ensembleName ? summary.ensembleName.replace(/[^a-zA-Z0-9]/g, ' ') : ''

        return (
          <li className={'application-list-item'} key={index}>
            <div className={'ensemble-plan'}>
              {createSVG(55, 55, summary.polys, summary.lines)}
            </div>
            <div className={'application-description'}>
              <Text>{name}</Text>
              <Text component={'small'}>{summary.usages}x</Text>
            </div>
          </li>
        )
      })

      categoriesElements.push((
        <MenuSection key={category}
                     title={category}
                     titleClassName={'application-category-item-title'}
                     className={'application-category-item'}>
          <ul>
            {summaryElements}
          </ul>
        </MenuSection>
      ))

    }

    return (
      <MenuSection className={'applications-list'}>
        {categoriesElements}
      </MenuSection>
    )
  }
}

let mapStateToProps = (state) => {

  return {
    unit: getSelectedOrganisationalUnit(state),
    summaries: state.furnitureEnsembleSummary.summaries,
  }
}

let mapDispatchToProps = {
  updateApplicationProfile: updateOrganisationalUnitApplicationProfile,
  fetchOrganisationalUnitFurnitureEnsemblesSummary: fetchOrganisationalUnitFurnitureEnsemblesSummary,
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsList)

