import React, { useEffect, useRef, useState } from 'react'
import Measure from 'react-measure'
import PlanThreeScene from './PlanThreeScene'
import './PlanPanel.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedOrganisationalUnit } from '../../../common/redux/appstate/appstate-actions'
import { getFurniture3DObjects } from '../../../common/reducers/furniture'
import {
  deleteApplication,
  deleteMarker,
  deleteOrganisationalUnitAssignment,
  deleteRoom,
  dropApplication,
  dropDepartment,
  dropRow,
  getFloorLightmap,
  lockDepartment,
  optimizeDepartment,
  resizeDepartment,
  rotateApplication,
} from '../../actions/actions'
import { openFlyOut } from '../../actions/ui-actions'
import ModalConsumer from '../../../common/components/modal/ModalConsumer'
import PlanContextMenu from '../../../common/components/PlanContextMenu'
import IconButton from '../../../common/components/IconButton'
import { iconTypes } from '../../../common/components/icons/IconFactory'
import MarkerDialog from './MarkerDialog'
import SelectApplicationDialog from '../SelectApplicationDialog'
import EditRowDialog from './EditRowDialog'
import RoomCategoryDialog from './RoomCategoryDialog'
import { viewNames } from '../../../common/globalConstants'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import Legend from '../../../common/components/Legend'
import { getHexColorFromRGB } from '../../../common/utils'
import { PlanButtonGroup } from './PlanButtonGroup'
import Button, { buttonVariants } from '../../../common/components/Button'
import { freeMemory } from '../../../common/three/common/memory'

export const viewModes = {
  activity: 'activity',
  light: 'light',
  noise: 'noise',
  team: 'team',
}

const mouseActionTypes = {
  mouseDown: 'mousedown',
  mouseMove: 'mousemove',
  mouseUp: 'mouseup',
}

const popperState = {
  popperType: null,
  popperMousePosition: null,
  popperPlanPosition: null,
  popperUuid: null,
  popperParentUuid: null,
  popperTypeUuid: null,
}

const popperTypes = {
  addMarkerZoomOut: 'addMarkerZoomOut',
  addMarkerZoomIn: 'addMarkerZoomIn',
  surrounding: 'surrounding',
  editMarker: 'editMarker',
  editRow: 'editRow',
  addApplicationToRoom: 'addApplicationToRoom',
  editRoom: 'editRoom',

  detailedDepartment: 'detailedDepartment',

  departmentLocked: 'departmentLocked',
  departmentUnlocked: 'departmentUnlocked',
  detailedFloor: 'detailedFloor',
  editApplication: 'editApplication',
  editDeleteApplication: 'editDeleteApplication',
  editWorkplace: 'editWorkplace',
  detailedRoom: 'detailedRoom',
  detailedWorkplace: 'detailedWorkplace',
  detailedRow: 'detailedRow',
}

function FloorplanPanel ({viewMode, disableInteractions, disableLegend}) {
  const [mouseIcon, setMouseIcon] = useState(null)
  const [showScreenshotButton, setShowScreenshotButton] = useState(false)
  const [popper, setPopper] = useState(popperState)
  const planThreeScene = useRef(null)
  const mount = useRef(null) // Verwendet, um auf den plan-container div zuzugreifen
  const dispatch = useDispatch()

  const floorPlan = useSelector(state => state.floorPlan.floorPlan)
  const analyticsAreas = useSelector(state => state.floorPlan.analyticsAreas)
  const applications = useSelector(state => state.furnitureEnsembles.furnitureEnsembles)
  const rooms = useSelector(state => state.floorPlan.rooms)
  const organisationalUnits = useSelector(state => state.placedOrganisationalUnits.placedUnits)
  const furniture3DObjectsFromState = useSelector(state => state.furniture.furnitureLibrary)
  const ensembleCategories = useSelector(state => state.furnitureEnsembles.furnitureEnsembleCategories)
  const markers = useSelector(state => state.markers.markers)
  const ui = useSelector(state => state.floorPlanUiState)
  const bgImage = null // useSelector(state => state.drawing.image.base64Image)
  const selectedOrganisationalUnitId = useSelector(state => state.appState.selectedOrganisationalUnitId)
  const lightmap = useSelector(state => state.floorPlan.lightmap)
  const dirtyFlag = useSelector(state => state.placedOrganisationalUnits.dirtyFlag)
  let legend = null

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (planThreeScene.current) {
      planThreeScene.current.organisationalUnits = organisationalUnits
    }
  } , [organisationalUnits])

  useEffect(() => {
    clearDepartments()
    drawDepartments()

    if (planThreeScene.current)
      planThreeScene.current.setViewMode(viewMode)
  } , [dirtyFlag])

  function furniture3DObjects() {
    return getFurniture3DObjects(furniture3DObjectsFromState)
  }

  function getViewMode() {
    return viewMode
  }

  const getOrganisationalUnits = () => {
    //const data = useSelector(state => state.placedOrganisationalUnits.placedUnits)
    //return data;
  }



  function getSelectedOrganisationalUnitId() {
    return selectedOrganisationalUnitId
  }


  function handleSetScreenshotButton(value)
  {
    setShowScreenshotButton(value)
  }

  function clearFloor() {
    if (planThreeScene.current)
      planThreeScene.current.clearFloor()
  }

  function drawFloor() {
    if (planThreeScene.current)
      planThreeScene.current.drawFloor()
  }

  function clearRooms() {
    if (planThreeScene.current)
      planThreeScene.current.clearRooms()
  }

  function drawRooms() {
    if (planThreeScene.current)
      planThreeScene.current.drawRooms()
  }

  function clearDepartments() {
    if (planThreeScene.current)
      planThreeScene.current.clearDepartments()
  }

  function drawDepartments() {
    if (planThreeScene.current)
      planThreeScene.current.drawDepartments()
  }

  function centerCamera() {
    if (planThreeScene.current && floorPlan)
      planThreeScene.current.centerCamera(floorPlan.facadeLinesAsPosLists, mount.current)
  }
  useEffect(() => {
    // Stelle sicher, dass planThreeScene nur einmal initialisiert wird

    if (!planThreeScene.current && mount.current) {
      planThreeScene.current = new PlanThreeScene(mount)

      planThreeScene.current.popperTypes = popperTypes

      // Assign Data
      planThreeScene.current.viewMode = viewMode
      planThreeScene.current.organisationalUnits = organisationalUnits
      planThreeScene.current.markers = markers
      planThreeScene.current.ui = ui
      planThreeScene.current.showScreenshotButton = showScreenshotButton
      planThreeScene.current.popper = popper
      planThreeScene.current.ensembleCategories = ensembleCategories
      planThreeScene.current.furniture3DObjects = furniture3DObjects()
      planThreeScene.current.roomData = rooms
      planThreeScene.current.applications = applications
      planThreeScene.current.floorPlan = floorPlan
      planThreeScene.current.legend = document.getElementsByClassName('legend-window')[0]
      planThreeScene.analyticsAreas = analyticsAreas

      // Assign Functions
      planThreeScene.current.openFlyOut = openFlyOut
      planThreeScene.current.setSelectedOrganisationalUnitHandler = setSelectedOrganisationalUnitHandler
      planThreeScene.current.handleSetScreenshotButton = handleSetScreenshotButton
      planThreeScene.current.handleRotateApplication = handleRotateApplication
      planThreeScene.current.handleDropApplication = handleDropApplication
      planThreeScene.current.handleDropRow = handleDropRow
      planThreeScene.current.handleDropDepartment = handleDropDepartment
      planThreeScene.current.handleResizeDepartment = handleResizeDepartment
      planThreeScene.current.setContextMenu = setContextMenu
      planThreeScene.current.handleSetScreenshotButton = handleSetScreenshotButton
      planThreeScene.current.hidePoppers = hidePoppers
      planThreeScene.current.getFloorLightmap = handleGetFloorLightmap

      mount.current.appendChild(planThreeScene.current.renderer.domElement)

      planThreeScene.current.updateLightmap(lightmap)

    }
    resize(mount, planThreeScene.current.renderer, planThreeScene.current.camera)

    planThreeScene.current.updateBackgroundImage(bgImage)
    mountMouseAndKeyboardActions()

    clearFloor()
    drawFloor()

    clearRooms()
    drawRooms()

    clearDepartments()
    drawDepartments()

    if (disableInteractions) {
      setMouseIcon({ mouseIcon: 'cursor-pan-grab' })
    }

    // Optionale Bereinigungsfunktion
    return () => {
      if (mount.current) {
        unmountMouseAndKeyboardActions()

        planThreeScene.current.stop()

        freeMemory(planThreeScene.current.scene)
        mount.current.removeChild(planThreeScene.current.renderer.domElement)
      }
    }
  }, [])

  function unmountMouseAndKeyboardActions () {
    const planPanel = document.getElementById("plan-panel")

    planPanel.removeEventListener(mouseActionTypes.mouseMove, planThreeScene.current.onDocumentMouseMove, true)
    planPanel.removeEventListener(mouseActionTypes.mouseDown, planThreeScene.current.onDocumentMouseDown, true)
    planPanel.removeEventListener(mouseActionTypes.mouseUp, planThreeScene.current.onDocumentMouseUp, true)
  }

  function handleGetFloorLightmap() {
    dispatch(getFloorLightmap())
  }

  useEffect(() => {
    planThreeScene.current.updateBackgroundImage(bgImage)
  } , [bgImage])

  useEffect(() => {
    //planThreeScene.current.organisationalUnits = organisationalUnits
  } , [organisationalUnits])

  useEffect(() => {
    planThreeScene.current.viewMode = viewMode
  } , [viewMode])

  useEffect(() => {
    planThreeScene.current.markers = markers
  } , [markers])

  useEffect(() => {
    planThreeScene.current.ui = ui
  }, [ui])

  useEffect(() => {
    planThreeScene.current.showScreenshotButton = showScreenshotButton
  } , [showScreenshotButton])

  useEffect(() => {
    planThreeScene.current.popper = popper
  } , [popper])

  useEffect(() => {
    planThreeScene.current.disableInteractions = disableInteractions
  } , [disableInteractions])

  useEffect(() => {
    planThreeScene.current.furniture3DObjects = furniture3DObjects()
  } , [furniture3DObjectsFromState])

  useEffect(() => {
    planThreeScene.current.analyticsAreas = analyticsAreas
  } , [analyticsAreas])


  useEffect(() => {
    planThreeScene.current.applications = applications
  } , [applications])

  useEffect(() => {
    planThreeScene.current.floorPlan = floorPlan
    clearFloor()
    drawFloor()
    centerCamera()
    planThreeScene.current.updateLODs()
  } , [floorPlan])


  useEffect(() => {
    planThreeScene.current.updateLightmap(lightmap)
    planThreeScene.current.setLightView()
  } , [lightmap])

  useEffect(() => {
    planThreeScene.current.ensembleCategories = ensembleCategories
  } , [ensembleCategories])




  useEffect(() => {
    planThreeScene.current.organisationalUnits = organisationalUnits

    clearRooms()
    clearDepartments()

    if (organisationalUnits.length > 0 && furniture3DObjectsFromState.length > 0 && applications.length > 0) {


      drawRooms()
      drawDepartments()

      // Reactivate active Department
      if (selectedOrganisationalUnitId) {
        let newDptId = null

        for (let i = 0; i < organisationalUnits.length; i++) {
          if (organisationalUnits[i].id === selectedOrganisationalUnitId) {
            newDptId = organisationalUnits[i].id
          }
        }

        planThreeScene.current.setSelectedOrganisationalUnitId(newDptId)
      }

      planThreeScene.current.setViewMode(viewMode)
      planThreeScene.current.updateLODs()
    }


  } , [organisationalUnits, furniture3DObjectsFromState, applications])

  useEffect(() => {
    if (organisationalUnits.length > 0 && furniture3DObjectsFromState.length > 0 && applications.length > 0) {
      planThreeScene.current.roomData = rooms
      clearRooms();
      drawRooms();
      planThreeScene.current.updateLODs();
    }
  }, [rooms, furniture3DObjectsFromState, applications]);

  useEffect(() => {
    planThreeScene.current.setViewMode(viewMode)
    planThreeScene.current.updateLODs()
  },  [viewMode])

  useEffect(() => {
      planThreeScene.current.selectItem(selectedOrganisationalUnitId)
  } , [selectedOrganisationalUnitId, organisationalUnits])

  useEffect(() => {

    function handleResize () {
      resize(mount, planThreeScene.current.renderer, planThreeScene.current.camera)

    }



    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function resize (mount, renderer, camera) {
    if (!mount || !renderer || !camera)
      return

    const { clientWidth, clientHeight } = mount.current

    if (camera) {
      camera.left = clientWidth / -16
      camera.right = clientWidth / 16
      camera.top = clientHeight / 16
      camera.bottom = clientHeight / -16
      camera.updateProjectionMatrix()
    }
    planThreeScene.current.width = clientWidth
    planThreeScene.current.height = clientHeight
    planThreeScene.current.topbar = document.getElementsByClassName('plan-container')[0].offsetTop
    planThreeScene.current.sidebar = document.getElementsByClassName('plan-container')[0].offsetLeft
    planThreeScene.current.flyout = document.getElementsByClassName('fly-out').length ? document.getElementsByClassName('fly-out')[0].offsetWidth : 0


    renderer.setSize(clientWidth, clientHeight)
  }

  function setSelectedOrganisationalUnitHandler (organisationalUnit) {
    dispatch(setSelectedOrganisationalUnit(organisationalUnit))
  }

  function mountMouseAndKeyboardActions () {
    const planPanel = document.getElementById('plan-panel')

    if (!disableInteractions) {

      planPanel.addEventListener(mouseActionTypes.mouseDown, planThreeScene.current.onDocumentMouseDown, true)
      planPanel.addEventListener(mouseActionTypes.mouseMove, planThreeScene.current.onDocumentMouseMove, true)
      planPanel.addEventListener(mouseActionTypes.mouseUp, planThreeScene.current.onDocumentMouseUp, true)
    }
  }



  function handleRotateApplication (applicationId, applicationUuid, angle) {
    dispatch(rotateApplication(applicationId, applicationUuid, angle))
  }

  function handleDropApplication (parentUuid, uuid, x, y) {
    dispatch(dropApplication(parentUuid, uuid, x, y))
  }

  function handleDropRow (parentUuid, uuid, x, y) {
    dispatch(dropRow(parentUuid, uuid, x, y))
  }

  function handleDropDepartment (uuid, x, y) {
    dispatch(dropDepartment(uuid, x, y))
  }

  function handleResizeDepartment (handlerId, index, x, y) {
    dispatch(resizeDepartment(handlerId, index , x, y))
  }

  function setContextMenu (popperType, position, popperUuid = null, popperParentUuid = null, popperTypeUuid = null) {
    setPopper({
      popperType: popperType,
      popperPlanPosition: planThreeScene.current.currentIntersectionPosition,
      popperMousePosition: position,
      popperUuid: popperUuid,
      popperParentUuid: popperParentUuid,
      popperTypeUuid: popperTypeUuid,
    })
  }

  function renderPopper () {
    if (!popper.popperType)
      return null

    return <ModalConsumer>
      {({ showModal }) => (
        <div>
          {
            popper.popperType ?
              <PlanContextMenu placement={'left'} x={popper.popperMousePosition.x} y={popper.popperMousePosition.y} onClose={() => hidePoppers()}>
                {getPopperContent(showModal)}
              </PlanContextMenu> : null
          }
        </div>
      )}
    </ModalConsumer>
  }

  function hidePoppers() {
    setPopper({popperType: null})
  }

  function getPopperContent (showModal) {
    switch (popper.popperType) {
      case popperTypes.addMarkerZoomOut:
        return [
          <IconButton key={0} icon={iconTypes.zoomAll} inverted onClick={() => handleZoomOut()}/>,
          <IconButton key={1} icon={iconTypes.pin} inverted onClick={() => handleCreateMarker(showModal, MarkerDialog)}/>]
      case popperTypes.addMarkerZoomIn:
        return [

          <IconButton key={0} icon={iconTypes.zoomAll} inverted onClick={() => handleZoomInSubdepartment(popper.popperUuid)}/>,
          <IconButton key={1} icon={iconTypes.pin} inverted onClick={() => handleCreateMarker(showModal, MarkerDialog)}/>]
      case popperTypes.editMarker:
        return [
          <IconButton key={0} icon={iconTypes.edit} inverted onClick={() => handleEditMarker(showModal, MarkerDialog, popper.popperUuid)}/>,
          <IconButton key={1} icon={iconTypes.delete} inverted onClick={() => handleDeleteMarker(popper.popperUuid)}/>]
      case popperTypes.editApplication:
        return [<IconButton key={0} icon={iconTypes.edit} inverted onClick={() => handleEditApplication(popper.popperTypeUuid)}/>]
      case popperTypes.editDeleteApplication:
        return [
          <IconButton key={0} icon={iconTypes.edit} inverted onClick={() => handleEditApplication(popper.popperTypeUuid)}/>,
          <IconButton key={1} icon={iconTypes.delete} inverted onClick={() => handleDeleteApplication(popper.popperParentUuid, this.state.popperUuid)}/>]
      case popperTypes.editWorkplace:
        return [<IconButton key={0} icon={iconTypes.edit} inverted onClick={() => handleEditWorkplace(popper.popperUuid)}/>]
      case popperTypes.departmentLocked:
        return [
          <IconButton key={0} icon={iconTypes.zoomAll} inverted onClick={() => handleZoomInSubdepartment(popper.popperUuid)}/>,
          <IconButton key={1} icon={iconTypes.optimize} inverted onClick={() => handleOptimizeDepartment(popper.popperUuid)}/>,
          <IconButton key={2} icon={iconTypes.unlocked} inverted onClick={() => handleLockDepartment(popper.popperUuid, false)}/>,
          <IconButton key={3} icon={iconTypes.plus} inverted onClick={() => handleAddApplication(showModal, SelectApplicationDialog, popper.popperUuid)}/>]
      case popperTypes.departmentUnlocked:
        return [
          <IconButton key={0} icon={iconTypes.zoomAll} inverted onClick={() => handleZoomInSubdepartment(popper.popperUuid)}/>,
          <IconButton key={1} icon={iconTypes.optimize} inverted onClick={() => handleOptimizeDepartment(popper.popperUuid)}/>,
          <IconButton key={2} icon={iconTypes.locked} inverted onClick={() => handleLockDepartment(popper.popperUuid, true)}/>,
          <IconButton key={3} icon={iconTypes.delete} inverted onClick={() => handleDeleteDepartment(popper.popperUuid)}/>,
        ]
      case popperTypes.editRow:
        return [<IconButton key={0} icon={iconTypes.edit} inverted onClick={() => handleEditRow(showModal, EditRowDialog, popper.popperUuid, popper.popperParentUuid)}/>]
      case popperTypes.addApplicationToRoom:
        return [<IconButton key={0} icon={iconTypes.plus} inverted onClick={() => handleEmptyRoom(showModal, RoomCategoryDialog, popper.popperUuid)}/>]
      case popperTypes.editRoom:
        return [
          <IconButton key={0} icon={iconTypes.edit} inverted onClick={() => handleEmptyRoom(showModal, RoomCategoryDialog, popper.popperUuid)}/>,
          <IconButton key={1} icon={iconTypes.delete} inverted onClick={() => handleDeleteRoom(popper.popperUuid)}/>]
      default:
        return null
    }
  }

  function handleCreateMarker (showModal, component) {
    let marker = {
      displayName: null,
      position: [planThreeScene.current.currentIntersectionPosition.x, -planThreeScene.current.currentIntersectionPosition.z],
    }

    hidePoppers()
    showModal(component, { marker: marker })
  }

  function handleEditMarker (showModal, component, markerId) {
    const marker = markers.find(marker => marker.id === markerId)

    hidePoppers()
    showModal(component, { marker: marker })
  }

  function handleDeleteMarker (markerId) {
    dispatch(deleteMarker(markerId))
    hidePoppers()
  }

  //region Room

  function handleEmptyRoom (showModal, component, zoneIndex) {
    let emptyRoom = {
      zoneIndex: zoneIndex,
    }

    hidePoppers()
    showModal(component, { emptyRoom: emptyRoom })
  }

  function handleDeleteRoom (zoneIndex) {
    dispatch(deleteRoom(zoneIndex))

    hidePoppers()
  }

  //endregion

  //region Application

  function handleEditApplication (id) {
    const pathname = location.pathname
    const p = pathname.replace('/floorplan', '');

    navigate('..' + p + '/' + viewNames.SETS + '/' + id + location.search)

    hidePoppers()
  }

  function handleDeleteApplication (departmentUuid, applicationId) {
    dispatch(deleteApplication(departmentUuid, applicationId))

    hidePoppers()
  }

  function handleAddApplication (showModal, dialog, departmentUuid) {
    const position = {
      x: popper.popperPlanPosition.x,
      y: -popper.popperPlanPosition.z,
    }

    const data = {
      unitId: departmentUuid,
      position: position,
    }

    showModal(dialog, data)
  }

  //endregion

  //region Department

  function handleOptimizeDepartment (departmentUuid) {
    dispatch(optimizeDepartment(departmentUuid))

    hidePoppers()
  }

  function handleLockDepartment (departmentUuid, isLocked) {
    dispatch(lockDepartment(departmentUuid, isLocked))

    hidePoppers()
  }

  function handleDeleteDepartment (departmentUuid) {
    dispatch(deleteOrganisationalUnitAssignment(null, null, departmentUuid))

    hidePoppers()
  }

  function handleZoomInSubdepartment (id) {
    let department = planThreeScene.current.getOrganisationalUnitById(id)

    if (department !== null) {
      centerCamera(department.polygons)
    }

    hidePoppers()
    setShowScreenshotButton(true)
  }

  function handleZoomOut () {
    console.log("zoom all")
    if (floorPlan) {
      console.log("zoom on: "+floorPlan.facadeLinesAsPosLists)
      centerCamera(floorPlan.facadeLinesAsPosLists)
    }

    hidePoppers()
    setShowScreenshotButton(true)
  }

  //endregion

  //region Workplace

  function handleEditWorkplace (id) {

    const pathname = location.pathname
    const p = pathname.replace('/floorplan', '');

    navigate('..' + p + '/' + viewNames.PATTERNS + '/' + id + location.search)

    hidePoppers()
  }

  //endregion

  //region Row

  function handleEditRow (showModal, component, rowUuid, departmentUuid) {
    const row = organisationalUnits.flatMap(org => org.rows)
      .find(row => row.uuid === rowUuid)

    hidePoppers()
    showModal(component, { row: row, departmentUuid: departmentUuid })
  }



  //endregion

  return (
    <Measure bounds>
      {({ measureRef }) => (
        <div ref={measureRef} id="plan-panel" className={`plan-panel${mouseIcon ? ` ${mouseIcon}` : ''}`}>
          <div className="plan-container" ref={mount}/>
          {renderPopper()}
          {!disableLegend ? renderLegend() : null}
          {renderScreenShotButton()}
        </div>
      )}
    </Measure>
  )

  function renderLegend () {
    return viewMode === viewModes.activity ?
      <Legend planPanel={'plan-panel'} editorArea={'content'} sideBar={'dashboard-bar'} dashboard>
        <h3>Explanation of Terms</h3>
        <h4>Application Type</h4><br/>

        <div className={'legend-box conf-normal'}>
          <div className={'dot'} style={{ backgroundColor: '#c3cec3' }}/>
          Workplace
        </div>

        {
          ensembleCategories ?
            ensembleCategories
              .sort((a, b) => {
                  const x = a.displayName.toLowerCase()
                  const y = b.displayName.toLowerCase()
                  return x < y ? -1 : x > y ? 1 : 0
                },
              )
              .map(cat =>
                <div className={'legend-box conf-normal'} key={cat.id}>
                  <div className={'dot'}
                       style={{ backgroundColor: `${getHexColorFromRGB(cat.color, false)}` }}/>
                  {cat.displayName}
                </div>,
              ) : null
        }

      </Legend>
      : null
  }

  function renderScreenShotButton () {
    return showScreenshotButton ?
      <PlanButtonGroup>
        <Button variant={buttonVariants.orangeFilledRound} secondIcon={iconTypes.screenshot} onClick={() => planThreeScene.current.handleScreenshot()}>Take Screenshot</Button>
      </PlanButtonGroup>
      : null
  }
}

export default FloorplanPanel