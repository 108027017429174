import { frontendLocationActionTypes } from './frontend-location-action'

let initApp = {
  globalLocation: null,
  onboardingLocation: null,
}

function frontendLocationReducer (state = initApp, action) {

  switch (action.type) {
    case frontendLocationActionTypes.FETCH_FRONTEND_LOCATION_FULFILLED:
      const { globalLocation, onboardingLocation } = action.payload.data

      return {
        ...state,
        globalLocation: globalLocation,
        onboardingLocation: onboardingLocation,
      }

    default:
      return state
  }

}

export function getGlobalLocation (state) {
  return state.frontendLocation.globalLocation
}

export default frontendLocationReducer
