import * as React from 'react'

const SvgOpenFolderIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd" stroke={props.color ? props.color : '#FFF'} strokeLinecap="square">
      <path d="M6 16V8a1 1 0 01.883-.993L7 7h1a1 1 0 01.993.883L9 8h7a1 1 0 011 1v.688"/>
      <path d="M16.09 17H7.018a1 1 0 01-.92-1.394l2.143-5c.158-.368.52-.606.92-.606h9.073a1 1 0 01.92 1.394l-2.143 5c-.158.368-.52.606-.92.606z"/>
    </g>
  </svg>
)

export default SvgOpenFolderIcon