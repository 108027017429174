import {Geometry} from './Geometry'
import {Face} from './FaceFactory'
import {geometryTypes} from '../config/GeometryTypes'
import {
  addVertex,
  addVertexToEdge,
  getLastVertex,
  getVerticesCount,
  moveDelta,
  removeLastVertex,
  setDraggedEdge,
  setDraggedVertex,
  //setDraggedVertex1,
  //setDraggedVertex2,
  updateVertex,
} from './utils/GeometryBasicFunctionsUtils'

export const polygonActions = {
    drag: 'drag',
    addVertex: 'addVertex',

    selectVertex: 'selectVertex',
    dragVertex: 'dragVertex',
}

export class PolygonGeometry extends Geometry {

    constructor(parent, representationType) {
        super(parent, representationType, polygonActions)

        this.geometryType = geometryTypes.polygon

        this.addVertex = (position) => addVertex(position, this)
        this.addVertexToEdge = (edge, position) => addVertexToEdge(edge, position, this)
        this.updateVertex = (position, vertex = null) => updateVertex(position, this, vertex)
        this.removeLastVertex = () => removeLastVertex(this)
        this.moveDelta = (movement) => {
          moveDelta(movement, this)
          this.updateGeometryCallback()
        }
        this.getLastVertex = () => getLastVertex(this)
        this.setDraggedVertex = (vertex) => setDraggedVertex(vertex, this)
        this.setDraggedEdge = (edge) => setDraggedEdge(edge, this)
        //this.setDraggedVertex1 = (vertex) => setDraggedVertex1(vertex, this)
        //this.setDraggedVertex2 = (vertex) => setDraggedVertex2(vertex, this)
        this.getVerticesCount = () => getVerticesCount(this)
    }

    close() {
        console.log("CLOoSE")
        //console.log(getVerticesCount(this))

        this.removeLastVertex()

        const startVertex = this.vertexGroup.getVertexByIndex(-1)
        const endVertex = this.vertexGroup.getVertexByIndex(0)

        this.edgeGroup.addEdge(startVertex, endVertex)

        this.setAction(polygonActions.idle)
    }

    getFirstVertex() {
        return this.vertexGroup.children.length ? this.vertexGroup.children[0] : null
    }

    isClockwise() {
        let result = 0

        const vertices = this.vertexGroup.getPositions()
        for (let i = 0; i < vertices.length - 1; i++) {
            result += PolygonGeometry.isClockwiseHelper(vertices[i], vertices[i + 1])
        }
        result += PolygonGeometry.isClockwiseHelper(vertices[vertices.length - 1], vertices[0])

        return result >= 0
    }


    static isClockwiseHelper(startVertex, endVertex) {
        return (endVertex.x - startVertex.x) * (endVertex.y + startVertex.y)
    }
}

export default PolygonGeometry