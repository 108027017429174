import React, { Component } from 'react'
import { connect } from 'react-redux'

import { svgToolsListNew } from '../constants/SvgTools'
import { getToolHelpInfo } from '../constants/drawingInfoConstants'
import SvgIconButton from '../../common/components/SvgIconButton'

import { uploadDFXForFloor, uploadIFC } from '../../buildings/actions/actions'
import { uploadPicture } from '../actions/Backend/drawingToolActions'

import { setCurrentTool } from '../../buildings/actions/drawingActions'
import { setDrawingHelp, setMaxOnboardingIndex } from '../actions/UI/drawingToolUIActions'
import './DrawingToolMenuBar.scss'
import SvgUploadButton from '../../common/components/SvgUploadButton'
import SvgDivider from '../../common/components/SvgDivider'

import { getCurrentGeometryObjects } from '../reducer/drawingToolReducer'
import { representationTypes } from '../three/config/RepresentationTypes'

import '../../onboarding/components/StepSidebarComponents/EditOutlineDescription.scss'

export const endings = {
  dxf: '.dxf',
  ifc: '.ifc',
  jpg: '.jpg',
  jpeg: '.jpeg',
  png: '.png',
  pdf: '.pdf',
}

const acceptedEndings = [
  endings.jpg,
  endings.jpeg,
  endings.png,
  endings.pdf,
]


class DrawingToolMenuBar extends Component {

  ///region Upload

  uploadPlan (event) {
    if (event && event.target && event.target.files && event.target.files.length) {
      this.uploadFile(event.target.files[0])
    }
  }

  uploadFile (uploadFile) {
    const filename = uploadFile.name.toLowerCase()

    if (filename.endsWith(endings.jpg) ||
      filename.endsWith(endings.jpeg) ||
      filename.endsWith(endings.png) ||
      filename.endsWith(endings.pdf)) {
      this.props.uploadPicture(uploadFile)
    }
  }

  getAcceptedFileTypes () {
    let result = ''
    acceptedEndings.forEach(ending => result += ending + ',')
    return result
  }

  ///endregion

  getSvgToolsNew () {

    //console.log("SVGTOOLS:")
    //console.log(this.props)
    //console.log(this.state)

    const { currentTool, onboarding, maxOnboardingIndex} = this.props

    //console.log("toolc: "+currentTool+"  ...")
    //setCurrentTool(null)

    if(onboarding===false){
      //setTool(this.props,currentTool)
      //this.props.setMaxOnboardingIndex(2)
      this.props.setMaxOnboardingIndex(10)
    }else{
      //console.log("checkStep getTools")
      checkStep(this.props)
    }

    //console.log("moib: "+maxOnboardingIndex)

    return svgToolsListNew.map((tools, index) => {
      //console.log("index: "+index+"   max:" +maxOnboardingIndex )
      let disabled = false;

      let style = {}
      if (index===0){
        style= { display: 'none' }
      }

      let color="black"
      let lastIndex=10

      if(onboarding===true){
        lastIndex=5
        if(index>maxOnboardingIndex){
          disabled=true
          color="#c1c1c1"
        }
      }
      //console.log("lastIndex")
      //console.log(lastIndex)

      if(index<lastIndex)
      return (
        <div className={'tool-group-with-divider'} >
          <div className={'tool-divider'} style={style} >
            <SvgDivider disabled={disabled} />
          </div>
          <div className={'tool-group'} disabled={disabled} >
            <div className={'tool-area'} key={index} disabled={disabled}>

              {tools.table.map((tool, toolindex) => {

                const isActive = currentTool === tool.tool
                const isDisabled = disabled
                const icon = tool.icon
                const helpInfo= getToolHelpInfo(tool.tool)
                //console.log(helpInfo.textTitle+" _ "+helpInfo.text)
                //console.log(tool.tooltip+" : "+tool.tooldescription+" : "+tool.toolname+" : "+tool.tool)

                if(tool.tool==='upload'){
                  return (
                    <SvgUploadButton buttonClassName={'tool-icon-button'}
                                     variant={'text'}
                                     onChange={(event) => this.uploadPlan(event)}
                                     supportedFileFormatText={'JPEG, PNG & PDF'}
                                     accept={this.getAcceptedFileTypes()}
                                     text={""}
                                     tooltip={tool.tooltip}
                                     icon={icon}/>

                  )
                }else
                  return (
                    <SvgIconButton className={'tool-icon-button'}
                                   key={toolindex}
                                   selected={isActive}
                                   icon={icon}
                                   disabled={isDisabled}
                                   //onMouseEnter={() => !this.state.popupY ? this.handleInfo(tool.tool) : {}}
                                   onMouseEnter={() => handleInfo(this.props,tool.tool,helpInfo)}
                                   onMouseLeave={() => handleInfo(this.props,tool.tool,null)}
                                   onDoubleClick={() => handleDoubleClick(tool.tool)}
                                   onClick={() => setTool(this.props,tool.tool)}/>
                  )
              })}

            </div>
            <div className={'tool-area-label-container'} style={{color:color}}>
              {tools.name}
            </div>

          </div>
        </div>
      )
    })

  }

  render () {
    //console.log("render")
   return (
      <div className={'drawing-tool-menubar'}>

        <div className={'tool-area-container'}>
          {this.getSvgToolsNew()}
        </div>

      </div>
    )

    if(this.props.onboarding){
      return(<div></div>) //
    }
  }
}

export function getGeometriesOfRepresentationType(props,representationType) {
  //console.log("rep:"+representationType)

  let originalGeoms=props.geometries.geometries
  //console.log(originalGeoms)

  let geoms=[]
  if (originalGeoms){
    for(let i=0;i<originalGeoms.length; i++){
      let rType=originalGeoms[i].representationType
      if(representationType===rType){
        geoms.push(originalGeoms[i])
      }
    }
  }

  return geoms
}

export function checkStep(props){

  //console.log("checkstep")

  switch (props.maxOnboardingIndex) {
    case 0:
      break
    case 1:
      let scaleOK=false
      //if (Math.abs(props.image.base64Image.scale- 1.0)>= 0.001) scaleOK=true;
      //else
      //if(
        let scaleGeoms=getGeometriesOfRepresentationType(props,representationTypes.scale)
        //console.log(scaleGeoms)
        if(scaleGeoms!=null) {
          if (scaleGeoms.length > 0) {
            scaleOK=true
            //console.log(scaleGeoms.length)
            if (props.image.base64Image.name !== "") setStep(props, 2)
          }
        }


    case 2:

      let spaceGeoms=getGeometriesOfRepresentationType(props,representationTypes.space)

      if(spaceGeoms!=null){

        if(spaceGeoms.length>0){
          setStep(props,5)
        }
      }

      break


    default: //console.log("default")

  }
}

export function setStep(props,step){
  props.setMaxOnboardingIndex(step)
}

export function getProps(){
  return this.props
}
export function setStepNull(){

  this.props.setCurrentTool("none")
}


export function setTool(props,tool){

  if(tool==="upload"){

    props.setCurrentTool(tool)
  }else{
    props.setCurrentTool(tool)
    if(props.onboarding===true){
      checkStep(props)
    }
  }
}

export function handleInfo (props,tool,drawingHelp){
  console.log("handleInfo")
  //console.log(props)
  //console.log("tool")
  //console.log(tool)
  //console.log(drawingHelp)
  if (tool!=null){
    //const helpinfo=getToolHelpInfo(tool)
    //console.log("helpinfo")
    //console.log(helpinfo)
    //console.log("drawingHelp")
    //console.log(drawingHelp)
    props.setDrawingHelp(drawingHelp)
  }else{
    props.setDrawingHelp(null)
    //console.log("null")
  }
}

export function handleDoubleClick (tool){
  console.log("handleDouble")
  if (tool!=null){

  }else{
    console.log("null")
  }
}


let mapStateToProps = (state) => {
  //console.log("stateToProps")
  return {
    currentTool: state.drawingTool.currentTool,
    floorPlan: state.floorPlan.floorPlan,
    maxOnboardingIndex: state.drawingTool.currentMaxOnboardingIndex,
    popupX: state.drawingTool.popupX,
    geometries: getCurrentGeometryObjects(state),
    image: state.drawingTool.image,
    drawingHelp: state.drawingTool.drawingHelp
  }
}


let mapDispatchToProps = {
  uploadPicture: uploadPicture,
  uploadDXFForFloor: uploadDFXForFloor,
  uploadIFCForBuilding: uploadIFC,
  setCurrentTool: setCurrentTool,
  setMaxOnboardingIndex: setMaxOnboardingIndex,
  setDrawingHelp: setDrawingHelp,
}
export default connect(mapStateToProps, mapDispatchToProps)(DrawingToolMenuBar)