import TagManager from 'react-gtm-module'
import { settings } from '../config.service'

class GoogleTagManagerService {
  tracking = false;
  tagManagerArgs = {};

  async init() {
    const trackingId = settings.googleTagManagerTrackingId;
    if(trackingId) {
      this.tracking = true;
      this.tagManagerArgs = { gtmId: trackingId };
      TagManager.initialize(this.tagManagerArgs);
    }
  }

  async pageView(pagePath, pageTitle) {
    if (this.tracking) {
      const data = {
        dataLayer:
        {
          event: 'Pageview',
          pagePath: pagePath,
          pageTitle: pageTitle
        }
      };
      TagManager.dataLayer(data);
    }
  }
}

export const googleTagManagerService = new GoogleTagManagerService()