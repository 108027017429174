import React from 'react'

const SvgDrawNavbarNumber5Focus = props =>
  <svg
    viewBox="0 0 20 20"
    width={20} height={20}
    className={props.className}
  >
    <g fill="none" fillRule="evenodd">
      <circle stroke="#FFF" strokeWidth={2} cx={10} cy={10} r={9}/>
      <path
        d="M9.95 13.904c1.655 0 2.82-1.044 2.82-2.664 0-1.428-.877-2.58-2.617-2.58-.756 0-1.272.276-1.584.588.072-.336.204-1.344.216-1.716h3.456V6.2H7.67c-.036 1.248-.3 3.204-.492 4.248l1.464.108c.24-.408.66-.744 1.332-.744.876 0 1.38.624 1.38 1.44 0 .888-.54 1.44-1.392 1.44-.936 0-1.44-.576-1.44-1.488l-1.464-.108c-.06 1.704.984 2.808 2.892 2.808z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>

export default SvgDrawNavbarNumber5Focus