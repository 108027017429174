import { undoActionTypes } from '../redux/undo/undo-actions'

const initialState = {
  undoId: getInt(),
}

function undoDataUpdate (state = initialState, action) {
  switch (action.type) {

    case undoActionTypes.UNDO_DATA_UPDATED:

      return {

        undoId: getInt(),

      }

    default:
      return initialState
  }
}

function getInt () {
  return Math.floor(Math.random() * 999999999999 + 100000000000)
}

export default undoDataUpdate