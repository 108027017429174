import * as THREE from 'three'
import { createLinesFromList, createShapeFromList } from './threefunctions'

function PlaceholderFurniture (color) {

  /*

  PlaceholderFurniture Structure

  FN3D Children
  |- [0] Lod Medium, Mesh, Furniture Geometry
  |   |- [0] Shadow Plane Geometry
  |   |- [1] Line Drawings
  |   |- [2] Line Drawings
  |- [1] LOD Low
  |- [n] Child Elements


   */
  THREE.LOD.apply(this, arguments)

  this.nid = ''
  this.saved = null // JSON Furniture Object aus Furniture Library

  this.build = function (description) {

    this.nid = description.id
    this.selectorType = null
    this.name = null
    this.saved = description
    this.furnRotation = null
    this.referenceId = null
    this.planeRotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2)

    this.buildGeometry(description)
  }

  this.tablePolygonList = [[0, 0, 1.6, 0, 1.6, .8, 0, .8]]
  this.chairPolygonList = [[0, 0, .5, 0, .5, .5, 0, .5]]

  this.buildGeometry = function (description) {


    let simplePolyList = this.chairPolygonList
    if (description.name === '_empty_desk') {
      simplePolyList = this.tablePolygonList
    } else if (description.name.indexOf('_empty_custom') > -1) {
      let x = description.name.split(':')[1] / 100
      let y = description.name.split(':')[2] / 100
      simplePolyList = [[0, 0, -x, 0, -x, -y, 0, -y]]
    }

    let material = new THREE.MeshBasicMaterial({ color: '#ffffff', depthWrite: true })
    let whiteMaterial = new THREE.MeshBasicMaterial({ color: '#f8f8f8', depthWrite: true })

    let smaterial = new THREE.MeshBasicMaterial({ color: '#000000', depthWrite: false, transparent: true, opacity: 0.1, shadowSide: THREE.FrontSide, side: THREE.BackSide })
    let lineMaterial = new THREE.LineBasicMaterial({ color: '#555' })
    let shape = createShapeFromList(simplePolyList)
    let shapeSimple = createShapeFromList(simplePolyList)
    let lines = createLinesFromList(simplePolyList)
    let shapeLines = createLinesFromList(simplePolyList)
    let linesSimple = createLinesFromList(simplePolyList)

    let objectMedium = new THREE.Mesh(shape, material)
    let objectMediumShadow = new THREE.Mesh(shape, smaterial)
    objectMediumShadow.position.set(0, .05, 0)
    objectMedium.position.set(0, .01, 0)
    objectMedium.add(objectMediumShadow)
    let lines1 = new THREE.LineSegments(lines, lineMaterial)
    let lines2 = new THREE.LineSegments(shapeLines, lineMaterial)
    let lines3 = new THREE.LineSegments(linesSimple, lineMaterial)
    objectMedium.add(lines1)
    objectMedium.add(lines2)

    let objectLow = new THREE.Mesh(shapeSimple, whiteMaterial)

    objectLow.position.set(0, .000125, 0)
    objectMediumShadow.castShadow = true
    lines3.visible = false
    lines3.type = 'lod1Lines'
    //objectLow.add(lines3)
    objectLow.type = 'lowLod'

    this.addLevel(objectMedium, 0)
    this.addLevel(objectLow, 200)

  }

  this.duplicate = function () {
    let d = new PlaceholderFurniture()
    d.build(this.saved)

    return d
  }

}

PlaceholderFurniture.prototype = Object.create(THREE.LOD.prototype)
PlaceholderFurniture.prototype.constructor = PlaceholderFurniture

export { PlaceholderFurniture }