import Text, { textTypes } from '../../../common/components/Text'
import Button from '../../../common/components/Button'
import { iconTypes } from '../../../common/components/icons/IconFactory'
import React from 'react'

import './AssistantButton.scss'

export default function AssistantButton ({ onClick = () => {} }) {
  return (
    <div className={'step-sidebar-assistant'}>
      <Text outerClassName={'assistant-text-wrapper'} component={textTypes.paragraph}>Or ask your Assistant to help you</Text>
      <Button className={'step-sidebar-assistant-button'} variant={'round'} secondIcon={iconTypes.assistant}
              onClick={() => onClick()}>Assistant</Button>
    </div>
  )
}

