import * as React from 'react'

const SocialiseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={37}
    height={36}
    viewBox="0 0 37 36"
  >
    <defs>
      <filter
        id="prefix_socialise_b"
        width="105.1%"
        height="104.3%"
        x="-2.6%"
        y="-2.1%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur
          in="SourceAlpha"
          result="shadowBlurInner1"
          stdDeviation={0.5}
        />
        <feOffset in="shadowBlurInner1" result="shadowOffsetInner1"/>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.129971591 0"
        />
      </filter>
      <path
        id="prefix_socialise_a"
        d="M24.5 13a1 1 0 011 1v.531a4 4 0 010 7.938V26a3 3 0 01-3 3h-10a3 3 0 01-3-3V14a1 1 0 011-1h14zm1.001 3.563v3.874a2.001 2.001 0 000-3.874zM14 5.5a1 1 0 01.993.883L15 6.5c0 .559.31 1.067.798 1.325l.125.06.923.384A3.501 3.501 0 0119 11.5a1 1 0 01-1.993.117L17 11.5c0-.559-.31-1.067-.798-1.325l-.125-.06-.923-.384A3.501 3.501 0 0113 6.5a1 1 0 011-1z"
      />
    </defs>
    <g transform="translate(.5)" fill="none" fillRule="evenodd">
      <use fill="#E8E9EB" xlinkHref="#prefix_socialise_a"/>
      <use fill="#000" filter="url(#prefix_socialise_b)" xlinkHref="#prefix_socialise_a"/>
    </g>
  </svg>
)

export default SocialiseIcon
