import { applicationBuilderActionTypes } from '../constants'

let initialState = {
  elements: [],
  category: null,
  displayName: '',
  error: null,

}

function selectedFurnitureTypeReducer (state = initialState, action) {

  switch (action.type) {

    case applicationBuilderActionTypes.SELECT_FURNITURE_TYPE:

      return {
        ...state,
        elements: action.payload,
        category: action.category,
        displayName: action.displayName,
      }

    default:
      return { ...state }
  }

}

export default selectedFurnitureTypeReducer