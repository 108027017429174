import React from 'react'

const SvgHomeIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M11.5 4.208a.707.707 0 011 0l8.309 8.444a.657.657 0 01-.023.95.708.708 0 01-.98-.023l-1.093-1.111v6.815a.686.686 0 01-.572.685l-.1.008H15.58a.682.682 0 01-.671-.693v-3.461h-.976v3.461c0 .383-.3.693-.671.693H5.958a.682.682 0 01-.671-.693l-.001-6.815-1.093 1.111a.709.709 0 01-.891.091l-.088-.069a.657.657 0 01-.023-.949zm.5 1.436l-5.371 5.458v7.49h5.96V15.13c0-.383.3-.693.672-.693h2.318c.371 0 .672.31.672.693v3.461h1.12l-.001-7.488L12 5.643z"
    />
  </svg>
)

export default SvgHomeIcon
