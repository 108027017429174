import {geometryTypes} from '../config/GeometryTypes'
import {PolyEdgeController} from './PolyEdgeController'
import {createGeometryByRepresentationType} from '../factories/GeometryFactory'

export class PolylineController extends PolyEdgeController {

    onLeftMouseDown(event, geometry, currentGeometries) {
        if (!geometry) {
            return this.onLeftMouseDownNew(event, currentGeometries)
        } else {
            return this.onLeftMouseDownEdit(event, geometry, currentGeometries)
        }
    }

    onLeftMouseDownNew(event, currentGeometries) {
      console.log("rep Polyline:"+this.representationType)
        const polyline = createGeometryByRepresentationType(this.representationType, this.group)
        return super.onLeftMouseDownNew(event, polyline, currentGeometries)
    }

    onLeftMouseDownEdit(event, polyline, currentGeometries) {
        if (!polyline)
            return false

        switch (polyline.getAction()) {
            case polyline.actions.addVertex:
                if (currentGeometries.length !== 1)
                    return false

                polyline.addVertex(this.getCurrentPosition(event, polyline.getLastVertex().position))
                return true
            case polyline.actions.select:
                const raycastHitObject = this.floorPlanerController.getRaycastHitObjectForGeometryByGeometryTypes(polyline,
                    [geometryTypes.vertex, geometryTypes.edge])

                if (!raycastHitObject)
                    return false

                switch (raycastHitObject.geometryType) {
                    case geometryTypes.vertex:
                        polyline.setDraggedVertex(raycastHitObject)
                        polyline.setAction(polyline.actions.dragVertex)
                        return true
                    case geometryTypes.edge:
                      if (event.altKey){
                        polyline.addVertexToEdge(raycastHitObject, this.floorPlanerController.getCurrentPosition())
                        polyline.setAction(polyline.actions.dragVertex)
                        return true
                      }

                    default:
                        return false
                }
            default:
                return false
        }
    }

    onLeftMouseDoubleClick(event, polygon, currentGeometries) {
        return super.onLeftMouseDoubleClick(event, polygon, currentGeometries, 2)
    }

    onEnterDown(event, polyline, currentGeometries) {
        return super.onEnterDown(event, polyline, currentGeometries, 2)
    }

}