import { googleTagManagerService } from './googleTagManager.service'
import { azureInsightsService } from './azureInsights.service'
import { history } from '../../store'

class AnalyticsService {

    async init() {
        await googleTagManagerService.init()
        await azureInsightsService.init()

        history.listen(location => {
            googleTagManagerService.pageView(location.pathname);
            azureInsightsService.pageView(location.pathname);
        });
    }

    trackEvent(eventName){
        googleTagManagerService.trackEvent(eventName);
        azureInsightsService.trackEvent(eventName);
    }

}

export const analyticsService = new AnalyticsService()