// Action Type suffixes used by Redux Promise Middleware
const PENDING = '_PENDING'
const FULFILLED = '_FULFILLED'
const REJECTED = '_REJECTED'

const SELECT_FURNITURE_TYPE = 'SELECT_FURNITURE_TYPE'
const SAVE_APPLICATION_PLAN = 'SAVE_APPLICATION_PLAN'
const PATCH_FURNITURE_ENSEMBLE_PLAN = 'PATCH_FURNITURE_ENSEMBLE_PLAN'

const FETCH_FURNITURE_ENSEMBLES = 'FETCH_FURNITURE_ENSEMBLES'
const FETCH_FURNITURE_ENSEMBLE_PLAN = 'FETCH_FURNITURE_ENSEMBLE_PLAN'
const FETCH_FURNITURE_ENSEMBLES_POLYGONS = 'FETCH_FURNITURE_ENSEMBLES_POLYGONS'
const FETCH_FURNITURE_LIBRARY = 'FETCH_FURNITURE_LIBRARY'
const FETCH_TAGS = 'FETCH_TAGS'
const FETCH_FURNITURE_ENSEMBLE_CATEGORIES = 'FETCH_FURNITURE_ENSEMBLE_CATEGORY'
const SWAP_FURNITURE_ENSEMBLE_PLAN = 'SWAP_FURNITURE_ENSEMBLE_PLAN'

const CREATE_FURNITURE_ENSEMBLE = 'CREATE_FURNITURE_ENSEMBLE'
const DELETE_FURNITURE_ENSEMBLE = 'DELETE_FURNITURE_ENSEMBLE'
const ADD_FURNITURE_ENSEMBLE = 'ADD_FURNITURE_ENSEMBLE'

const ADD_TAG_FURNITURE_ENSEMBLE_PLAN = 'ADD_TAG_FURNITURE_ENSEMBLE_PLAN'
const REMOVE_TAG_FURNITURE_ENSEMBLE_PLAN = 'REMOVE_TAG_FURNITURE_ENSEMBLE_PLAN'
const CHANGE_FURNITURE_ENSEMBLE_PLAN_CATEGORY = 'CHANGE_FURNITURE_ENSEMBLE_PLAN_CATEGORY'
const CHANGE_FURNITURE_ENSEMBLE_PLAN_SIZE = 'CHANGE_FURNITURE_ENSEMBLE_PLAN_SIZE'
const CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_POSITION= 'CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_POSITION'
const CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_ROTATION= 'CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_ROTATION'
const CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_PARENT= 'CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_PARENT'
const ADD_FURNITURE_ENSEMBLE_PLAN_FURNITURE= 'ADD_FURNITURE_ENSEMBLE_PLAN_FURNITURE'

const DUPLICATE_APPLICATION = 'DUPLICATE_APPLICATION'

export const applicationBuilderActionTypes = {

  // ---------- App state changes ----------

  SET_CURRENT_APPLICATION: 'SET_CURRENT_APPLICATION',

  // ---------- Frontend communication ----------

  LOCK_ROOM_SIZE: 'LOCK_ROOM_SIZE',
  ADD_APPLICATION: 'ADD_APPLICATION',
  RENAME_APPLICATION: 'RENAME_APPLICATION',
  RENAME_CURRENT_FURNITURE_ENSEMBLE_PLAN: 'RENAME_CURRENT_FURNITURE_ENSEMBLE_PLAN',
  CHANGE_SELECTED_ITEM: 'CHANGE_SELECTED_ITEM',
  SAVE_CHANGES: 'SAVE_CHANGES',
  DISCARD_CHANGES: 'DISCARD_CHANGES',
  RESET_DIRTY_FLAG: 'RESET_DIRTY_FLAG',
  // ---- Furniture ----

  CHANGE_FURNITURE_ANCHOR_SIDE: 'CHANGE_FURNITURE_ANCHOR_SIDE',
  DELETE_FURNITURE: 'DELETE_FURNITURE',


  // ---- Wall ----

  CHANGE_WALL_CODE: 'CHANGE_WALL_CODE',
  CHANGE_SIDE_CODE: 'CHANGE_SIDE_CODE',

  // ---- Save application ----------

  DISCARD_NEW_APPLICATION: 'DISCARD_NEW_APPLICATION',

  // ---------- Backend communication ----------

  DUPLICATE_APPLICATION: DUPLICATE_APPLICATION,
  DUPLICATE_APPLICATION_PENDING: DUPLICATE_APPLICATION + PENDING,
  DUPLICATE_APPLICATION_FULFILLED: DUPLICATE_APPLICATION + FULFILLED,
  DUPLICATE_APPLICATION_REJECTED: DUPLICATE_APPLICATION + REJECTED,

  FETCH_FURNITURE_ENSEMBLES: FETCH_FURNITURE_ENSEMBLES,
  FETCH_FURNITURE_ENSEMBLES_PENDING: FETCH_FURNITURE_ENSEMBLES + PENDING,
  FETCH_FURNITURE_ENSEMBLES_FULFILLED: FETCH_FURNITURE_ENSEMBLES + FULFILLED,
  FETCH_FURNITURE_ENSEMBLES_REJECTED: FETCH_FURNITURE_ENSEMBLES + REJECTED,

  CREATE_FURNITURE_ENSEMBLE: CREATE_FURNITURE_ENSEMBLE,
  CREATE_FURNITURE_ENSEMBLE_PENDING: CREATE_FURNITURE_ENSEMBLE + PENDING,
  CREATE_FURNITURE_ENSEMBLE_FULFILLED: CREATE_FURNITURE_ENSEMBLE + FULFILLED,
  CREATE_FURNITURE_ENSEMBLE_REJECTED: CREATE_FURNITURE_ENSEMBLE + REJECTED,

  SAVE_APPLICATION_PLAN: SAVE_APPLICATION_PLAN,
  SAVE_APPLICATION_PLAN_PENDING: SAVE_APPLICATION_PLAN + PENDING,
  SAVE_APPLICATION_PLAN_FULFILLED: SAVE_APPLICATION_PLAN + FULFILLED,
  SAVE_APPLICATION_PLAN_REJECTED: SAVE_APPLICATION_PLAN + REJECTED,

  DELETE_FURNITURE_ENSEMBLE: DELETE_FURNITURE_ENSEMBLE,
  DELETE_FURNITURE_ENSEMBLE_PENDING: DELETE_FURNITURE_ENSEMBLE + PENDING,
  DELETE_FURNITURE_ENSEMBLE_FULFILLED: DELETE_FURNITURE_ENSEMBLE + FULFILLED,
  DELETE_FURNITURE_ENSEMBLE_REJECTED: DELETE_FURNITURE_ENSEMBLE + REJECTED,

  ADD_FURNITURE_ENSEMBLE: ADD_FURNITURE_ENSEMBLE,
  ADD_FURNITURE_ENSEMBLE_PENDING: ADD_FURNITURE_ENSEMBLE + PENDING,
  ADD_FURNITURE_ENSEMBLE_FULFILLED: ADD_FURNITURE_ENSEMBLE + FULFILLED,
  ADD_FURNITURE_ENSEMBLE_REJECTED: ADD_FURNITURE_ENSEMBLE + REJECTED,

  FETCH_FURNITURE_ENSEMBLE_PLAN: FETCH_FURNITURE_ENSEMBLE_PLAN,
  FETCH_FURNITURE_ENSEMBLE_PLAN_PENDING: FETCH_FURNITURE_ENSEMBLE_PLAN + PENDING,
  FETCH_FURNITURE_ENSEMBLE_PLAN_FULFILLED: FETCH_FURNITURE_ENSEMBLE_PLAN + FULFILLED,
  FETCH_FURNITURE_ENSEMBLE_PLAN_REJECTED: FETCH_FURNITURE_ENSEMBLE_PLAN + REJECTED,

  FETCH_FURNITURE_ENSEMBLES_POLYGONS: FETCH_FURNITURE_ENSEMBLES_POLYGONS,
  FETCH_FURNITURE_ENSEMBLES_POLYGONS_PENDING: FETCH_FURNITURE_ENSEMBLES_POLYGONS + PENDING,
  FETCH_FURNITURE_ENSEMBLES_POLYGONS_FULFILLED: FETCH_FURNITURE_ENSEMBLES_POLYGONS + FULFILLED,
  FETCH_FURNITURE_ENSEMBLES_POLYGONS_REJECTED: FETCH_FURNITURE_ENSEMBLES_POLYGONS + REJECTED,

  FETCH_FURNITURE_LIBRARY: FETCH_FURNITURE_LIBRARY,
  FETCH_FURNITURE_LIBRARY_PENDING: FETCH_FURNITURE_LIBRARY + PENDING,
  FETCH_FURNITURE_LIBRARY_FULFILLED: FETCH_FURNITURE_LIBRARY + FULFILLED,
  FETCH_FURNITURE_LIBRARY_REJECTED: FETCH_FURNITURE_LIBRARY + REJECTED,

  FETCH_TAGS: FETCH_TAGS,
  FETCH_TAGS_PENDING: FETCH_TAGS + PENDING,
  FETCH_TAGS_FULFILLED: FETCH_TAGS + FULFILLED,
  FETCH_TAGS_REJECTED: FETCH_TAGS + REJECTED,

  SWAP_FURNITURE_ENSEMBLE_PLAN: SWAP_FURNITURE_ENSEMBLE_PLAN,
  ADD_TAG_FURNITURE_ENSEMBLE_PLAN: ADD_TAG_FURNITURE_ENSEMBLE_PLAN,
  REMOVE_TAG_FURNITURE_ENSEMBLE_PLAN: REMOVE_TAG_FURNITURE_ENSEMBLE_PLAN,
  CHANGE_FURNITURE_ENSEMBLE_PLAN_CATEGORY: CHANGE_FURNITURE_ENSEMBLE_PLAN_CATEGORY,
  CHANGE_FURNITURE_ENSEMBLE_PLAN_SIZE: CHANGE_FURNITURE_ENSEMBLE_PLAN_SIZE,
  CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_POSITION: CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_POSITION,
  CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_ROTATION: CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_ROTATION,
  CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_PARENT: CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_PARENT,
  ADD_FURNITURE_ENSEMBLE_PLAN_FURNITURE: ADD_FURNITURE_ENSEMBLE_PLAN_FURNITURE,

  SELECT_FURNITURE_TYPE: SELECT_FURNITURE_TYPE,
  FETCH_FURNITURE_ENSEMBLE_CATEGORIES: FETCH_FURNITURE_ENSEMBLE_CATEGORIES,
  FETCH_FURNITURE_ENSEMBLE_CATEGORIES_FULFILLED: FETCH_FURNITURE_ENSEMBLE_CATEGORIES + FULFILLED,

  PATCH_FURNITURE_ENSEMBLE_PLAN: PATCH_FURNITURE_ENSEMBLE_PLAN,
  PATCH_FURNITURE_ENSEMBLE_PLAN_PENDING: PATCH_FURNITURE_ENSEMBLE_PLAN + PENDING,
  PATCH_FURNITURE_ENSEMBLE_PLAN_FULFILLED: PATCH_FURNITURE_ENSEMBLE_PLAN + FULFILLED,
  PATCH_FURNITURE_ENSEMBLE_PLAN_REJECTED: PATCH_FURNITURE_ENSEMBLE_PLAN + REJECTED,
}