import React, { Component } from 'react'
import ListMenu from '../../../../common/components/ListMenu'
import { ListMenuItem } from '../../../../common/components/ListMenuItem'
import ModalConsumer from '../../../../common/components/modal/ModalConsumer'
import './AdjacencyList.scss'
import AdjacencyCreateDialog from './AdjacencyCreateDialog'
import { connect } from 'react-redux'
import Connection from './Connection'
import { getConnectionsOfDepartment } from '../../../reducers/connections'
import { getSelectedBuildingId, getSelectedFloorId, getSelectedOrganisationalUnitId } from '../../../../common/redux/appstate/appstate-reducer'
import { deleteConnectionAndFetch, fetchConnectables, fetchConnections, updateConnectionAndFetch } from '../../../actions/actions'
import MenuSection from '../../../../common/components/MenuSection'

class AdjacencyList extends Component {

  componentDidMount () {
    this.props.fetchConnections(this.props.buildingId, this.props.floorId)
    this.props.fetchConnectables(this.props.floorId)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.floorId !== this.props.floorId) {
      this.props.fetchConnections(this.props.buildingId, this.props.floorId)
      this.props.fetchConnectables(this.props.floorId)
    }
  }

  mapAdjacencyList () {
    return this.props.connections.map((connection) => {
      return <Connection key={connection.id}
                         connection={connection}
                         currentDepartment={this.props.currentDepartmentId}
                         onPriorityClicked={(clickedConnection) => this.handlePriorityClicked(clickedConnection)}
                         onDeleteClicked={(clickedConnection) => this.handleDeleteClicked(clickedConnection)}/>
    })

  }

  render () {

    const adjacencyList = this.mapAdjacencyList()

    return (
      <MenuSection className={'adjacencies-menu'}>
        <ListMenu>
          {adjacencyList}
          <ModalConsumer>
            {({ showModal }) => (
              <ListMenuItem icon={'plus'}
                            iconColor={'#FF6C4C'}
                            onClick={() => this.handleAddAdjacencyClicked(showModal, AdjacencyCreateDialog)}>
                <span className={'adjacencies-menu-add'}>Add Adjacency</span>
              </ListMenuItem>
            )}
          </ModalConsumer>
        </ListMenu>
      </MenuSection>
    )
  }

  handleAddAdjacencyClicked (showModal, component) {
    const data = {
      departmentId: this.props.currentDepartmentId,
    }
    showModal(component, data)
  }

  handlePriorityClicked (connection) {
    this.props.updateConnection(this.props.buildingId, this.props.floorId, connection.id, connection)
  }

  handleDeleteClicked (connection) {
    this.props.deleteConnection(this.props.buildingId, this.props.floorId, connection.id)
  }
}

let mapStateToProps = (state) => {

  return {
    connections: getConnectionsOfDepartment(state),
    currentDepartmentId: getSelectedOrganisationalUnitId(state),
    buildingId: getSelectedBuildingId(state),
    floorId: getSelectedFloorId(state),
  }
}

let mapDispatchToProps = {
  fetchConnections: fetchConnections,
  fetchConnectables: fetchConnectables,
  updateConnection: updateConnectionAndFetch,
  deleteConnection: deleteConnectionAndFetch,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdjacencyList)