import React from 'react'

import './SliderInput.scss'

function SliderInput ({ disabled, field, inputElementRef, max, min, onChange, onChangeEnd, value, name }) {

  const minInput = min || 0
  const maxInput = max || 100
  const valueInput = value ? Math.round(value) : 0

  return (
    <input className={'slider-input'}
           ref={inputElementRef ? inputElementRef : null}
           type="range"
           min={minInput}
           max={maxInput}
           disabled={disabled}
           value={valueInput}
           name={field ? field.name : name}
           onChange={field ? field.onChange : onChange}
           onTouchEnd={onChangeEnd ? onChangeEnd : () => {}}
           onMouseUp={onChangeEnd ? onChangeEnd : () => {}}

    />
  )
}

export default SliderInput