import React, { useEffect } from 'react'
import Dialog, { DialogContent } from '../components/Dialog'
import { findProjectInProjectsList } from '../redux/projects/projects-reducer'
import { activateAndLoadProject, fetchUserProjectsList, loadProject } from '../redux/projects/projects-actions'
import { resolveNotification } from '../redux/notifications/notifications-actions'
import { useDispatch, useSelector } from 'react-redux'
import Text from '../components/Text'
import './InactiveProjectErrorDialog.scss'
import Button from '../components/Button'
import moment from 'moment'
import { getCurrentProjectId } from '../utils'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next';
import { useNavigate } from 'react-router'


function getDurationText (lastModifiedDate) {

  let modified = moment(lastModifiedDate)
  let now = moment()

  if (modified.diff(now, 'hours') > 24) {
    return  i18n.t('last_edited_on') + ` ${modified.format('MMMM Do YYYY, h:mm:ss a')}`
  }

  let duration = moment.duration(modified.diff(now))
  return i18n.t('last_edited_about') + ` ${duration.humanize(true)}`
}

function Project ({ project, onClick }) {

  return (
    <div className={'project'}>
      {project &&
      <div className={'project-description'}>
        <div className={'project-description-title-row'}>
          <div className={'project-description-title-container'}>
            <Text outerClassName={'project-description-name'} multiline>{project.name}</Text>
            <Text outerClassName={'project-description-client'}>{project.companyName}</Text>
          </div>
          <Button onClick={onClick} variant={'contained'}>{i18n.t('continue')}</Button>
        </div>
        <Text outerClassName={'project-description-date'}>{getDurationText(project.lastModifiedDate)}</Text>
      </div>
      }
    </div>
  )

}

function InactiveProjectErrorDialog({notification, props}) {
  const navigate = useNavigate();
  const { data } = notification;
  const currentProjectId = useSelector(getCurrentProjectId);
  const currentProject = useSelector(state =>
    currentProjectId ? findProjectInProjectsList(state, currentProjectId) : null
  );
  const otherProject = useSelector(state =>
    data ? findProjectInProjectsList(state, notification.data.currentActiveProject) : null
  );
  const dispatch = useDispatch()

  useEffect(() => {
    fetchUserProjectsList()
  }, [])



    const { t } = useTranslation()
    const message = t('already_opened_another_project')

    return (
      <Dialog className={'inactive-project-error-dialog'}
              innerClassName={'inactive-project-error-dialog-inner'}
              titleClassName={'inactive-project-error-dialog-title'}
              onCancel={() => handleCancel()}
              title={t('conflict')}>
        <DialogContent className={'inactive-project-error-dialog-content'}>
          <div className={'error-dialog-message'}>
            <Text multiline>{message}</Text>
          </div>
          <div className={'projects-list'}>
            <Text outerClassName={'label'}>{t('currently_active_projects')}</Text>
            <Project project={currentProject} onClick={() => handleActivateCurrent()}/>
            <Project project={otherProject} onClick={() => handleLoadActive()}/>
          </div>
        </DialogContent>
      </Dialog>
    )


  function handleCancel  () {
    resolveInactiveProjectError()
    navigate('/')
  }

  function handleLoadActive  ()  {
    const { notification: { data } } = props
    resolveInactiveProjectError()
    dispatch(loadProject(data.currentActiveProject)).then(url => {
      navigate(url)
    })
  }

  function handleActivateCurrent  ()  {
    const projectId = currentProject.id

    resolveInactiveProjectError()
    dispatch(activateAndLoadProject(projectId)).then(url => {
      navigate(url)
    })
  }

  function resolveInactiveProjectError ()  {
    dispatch(resolveNotification(notification))
  }

}

export default InactiveProjectErrorDialog