import * as React from 'react'

function SvgScreenshot (props) {
  return (
    <svg width={24} height={24}>
      <path
        d="M17.5 11.5a1 1 0 011 1 .5.5 0 00.5.5h1.5a1 1 0 011 1v5a1 1 0 01-1 1h-9a1 1 0 01-1-1v-5a1 1 0 011-1H13a.5.5 0 00.492-.41l.008-.09a1 1 0 011-1h3zM16 13.75a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm0 1a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM9.25 16.5v1H7v-1h2.25zm-4.75-1l.001 1H5.5v1h-.999a1 1 0 01-1-1l-.001-1h1zm0-3.751V14h-1v-2.251h1zM16.5 8v2.25h-1V8h1zm-12 0v2.249h-1V8h1zm1.001-3.5l-.001 1h-.999l-.001 1h-1l.001-1a1 1 0 011-1h1zm10 0a1 1 0 011 1l-.001 1h-1l.001-1H14.5l.001-1h1zm-2.5 0L13 5.5h-2.25l.001-1h2.25zm-3.75 0l-.001 1H7l.001-1h2.25z"
        fill={props.color}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgScreenshot
