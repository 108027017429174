import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deleteAndFetchVariations } from '../../common/redux/variations/variations-actions'
import Dialog, { DialogButtonGroup } from '../../common/components/Dialog'
import Button from '../../common/components/Button'

class VariationsRenameDialog extends Component {

  close = () => {
    this.props.onClose()
  }

  render () {

    const { variation, projectId } = this.props

    let disabled = !projectId || !variation

    return (
      <Dialog title={'Delete variation'}
              onCancel={this.close}
              text={`Are you sure you want to delete ${variation.name ? 'variation "' + variation.name + '"' : 'the current variation'}?`}
              className={'variation-delete-dialog'}>

        <DialogButtonGroup>
          <Button onClick={this.close}>Cancel</Button>
          <Button disabled={disabled} onClick={() => this.handleDeleteButtonClick(projectId, variation)} variant={'contained'}>Confirm</Button>
        </DialogButtonGroup>
      </Dialog>
    )
  }

  handleDeleteButtonClick (projectId, variation) {
    this.props.deleteVariation(projectId, variation.id)
    this.close()
  }
}

let mapDispatchToProps = {
  deleteVariation: deleteAndFetchVariations,
}

export default connect(null, mapDispatchToProps)(VariationsRenameDialog)