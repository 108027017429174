import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ColorInput from '../../../common/components/ColorInput'
import { getHexColor } from '../../../common/utils'

class TableColorSelectButton extends Component {

  render () {
    return (
      <ColorInput color={getHexColor(this.props.value)} onChange={(colorAsHex) => this.handleColorChange(colorAsHex)}/>
    )
  }

  handleColorChange (color) {
    let rgb = color.rgb

    this.props.onChange(this.props.unitId,
      {
        color:
          {
            red: rgb.r / 255,
            green: rgb.g / 255,
            blue: rgb.b / 255,
            alpha: rgb.a,
          },
      })
  }
}

TableColorSelectButton.propTypes = {
  value: PropTypes.array.isRequired,
  unitId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default TableColorSelectButton