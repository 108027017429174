import * as React from 'react'

const SvgSizeIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill={props.fillColor} fillRule="evenodd">
      <path
        d="M7.3 4a.7.7 0 01.7.7v.8h8v-.8c0-.354.263-.647.605-.694L16.7 4h2.6a.7.7 0 01.7.7v2.6a.7.7 0 01-.7.7h-.8v8h.8c.354 0 .647.263.694.605L20 16.7v2.6a.7.7 0 01-.7.7h-2.6a.7.7 0 01-.7-.7v-.8H8v.8a.701.701 0 01-.605.694L7.3 20H4.7a.7.7 0 01-.7-.7v-2.6a.7.7 0 01.7-.7h.8V8h-.8a.701.701 0 01-.694-.605L4 7.3V4.7a.7.7 0 01.7-.7zM7 17H5v2h2v-2zm12 0h-2v2h2v-2zm-3-9.7v-.8H8v.8a.701.701 0 01-.605.694L7.3 8h-.8v8h.8c.354 0 .647.263.694.605L8 16.7v.8h8v-.8c0-.354.263-.647.605-.694L16.7 16h.8V8h-.8a.701.701 0 01-.694-.605L16 7.3zM7 5H5v2h2V5zm12 0h-2v2h2V5z"/>
    </g>
  </svg>
)

export default SvgSizeIcon
