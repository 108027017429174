import React, { useEffect } from 'react'

import BlockingEditor from './BlockingEditor'
import DraggedListItemRenderContainer from './DraggedListItemRenderContainer'
import Blocking from '../../floorplan/components/inspector/blocking/Blocking'

import './FloorsContent.scss'
import { fetchProjectData } from '../../floorplan/actions/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

export default function FloorsContent ({ shortVersion, onboarding = false }) {
  const dispatch = useDispatch()
  const {projectId} = useParams()
  const dataLoaded = useSelector(state => state.dataLoaded.dataLoaded)

  useEffect(() => {
    if (dataLoaded) {
      dispatch(fetchProjectData(projectId))
    }
  }, [])

  return (
    <div className={'floors-container'}>
      <div className={'floors-content' + (onboarding ? ' onboarding' : '')}>
        <BlockingEditor header={onboarding ? 'Floors' : ''}/>
        <DraggedListItemRenderContainer/>
      </div>
      <Blocking shortVersion={shortVersion}/>
    </div>
  )
}
