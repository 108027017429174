import React from 'react'

import './Text.scss'

export const textTypes = {
  paragraph: 'paragraph',
  paragraphBold: 'paragraphBold',
  runningTitle: 'runningTitle',
  subTitle: 'subTitle',
  title: 'title',
  numberTitle: 'numberTitle',
  label: 'label',
  hero: 'hero',
  infoTitle: 'infoTitle',
  infoParagraph: 'infoParagraph',
  link: 'link',
}

export default function Text ({ children, center, multiline, toolTip, outerClassName = '', className = '', component: Component = 'span', darkMode, ...rest }) {

  let textInner
  className = (className ? ' ' + className : '') + (multiline ? ' multiline' : '') + (center ? ' center' : '')

  switch (Component) {
    case textTypes.link:
      textInner = <div className={'text-inner link' + className}
                       title={(toolTip && children ? children : null)} {...rest}>{children}</div>
      break
    case textTypes.paragraph:
      textInner = <div className={'text-inner paragraph' + className}
                       title={(toolTip && children ? children : null)} {...rest}>{children}</div>
      break
    case textTypes.paragraphBold:
      textInner = <div className={'text-inner paragraph-bold' + className}
                       title={(toolTip && children ? children : null)} {...rest}>{children}</div>
      break
    case textTypes.runningTitle:
      textInner = <div className={'text-inner running-title' + className}
                       title={(toolTip && children ? children : null)} {...rest}>{children}</div>
      break
    case textTypes.subTitle:
      textInner = <div className={'text-inner sub-title' + className}
                       title={(toolTip && children ? children : null)} {...rest}>{children}</div>
      break
    case textTypes.title:
      textInner = <div className={'text-inner title' + className}
                       title={(toolTip && children ? children : null)} {...rest}>{children}</div>
      break
    case textTypes.numberTitle:
      textInner = <div className={'text-inner number-title' + className}
                       title={(toolTip && children ? children : null)} {...rest}>{children}</div>
      break
    case textTypes.hero:
      textInner = <div className={'text-inner hero' + className}
                       title={(toolTip && children ? children : null)} {...rest}>{children}</div>
      break
    case textTypes.infoTitle:
      textInner = <div className={'text-inner info-title' + className}
                       title={(toolTip && children ? children : null)} {...rest}>{children}</div>
      break
    case textTypes.infoParagraph:
      textInner = <div className={'text-inner info-paragraph' + className}
                       title={(toolTip && children ? children : null)} {...rest}>{children}</div>
      break
    default:
      textInner =
        <Component className={'text-inner ' + className} t
                   itle={(toolTip && children ? children : null)} {...rest}>{children}</Component>
      break
  }

  return (
    <div className={'text-wrapper ' + outerClassName + (multiline ? ' multiline' : '') + (darkMode ? ' dark-mode' : '')}>
      {textInner}
    </div>
  )
}