import { Mesh, Vector3 } from 'three'
import { Geometry } from './Geometry'
import { geometryTypes } from '../config/GeometryTypes'
import { addVertex, getLastVertex, getVerticesCount, setDraggedVertex, updateVertex } from './utils/GeometryBasicFunctionsUtils'
import { convertVector3ToVector2 } from '../common/VectorConverter'
import { createFaceGeometry } from '../factories/GeometryFactory'
import { getMaterial, materialTypes } from '../../../common/three/common/materials'

export const scaleActions = {
  drag: 'drag',
  addVertex: 'addVertex',
  dragVertex: 'dragVertex',
}

export class TapeMeasureGeometry extends Geometry {

  constructor (parent, representationType) {
    super(parent, representationType, scaleActions)

    this.geometryType = geometryTypes.line

    this.addVertex = (position) => addVertex(position, this)
    this.updateVertex = (position, vertex = null) => {
      updateVertex(position, this, vertex)

      this.updateTapeMeasureFace()
    }
    this.setDraggedVertex = (vertex) => setDraggedVertex(vertex, this)
    this.getLastVertex = () => getLastVertex(this)
    this.getVerticesCount = () => getVerticesCount(this)
  }

  updateTapeMeasureFace () {
    const verticesPositions = this.getVerticesPositions()
    if (verticesPositions.length !== 2) {
      return
    }

    let startPosition = convertVector3ToVector2(verticesPositions[0])
    let endPosition = convertVector3ToVector2(verticesPositions[1])

    const distance = startPosition.distanceTo(endPosition)

    if (endPosition.x < startPosition.x) {
      const temp = startPosition
      startPosition = endPosition
      endPosition = temp
    }

    const direction = endPosition.clone()
      .add(startPosition.clone()
        .multiplyScalar(-1))
      .normalize()

    const size = .5

    const vertices = [
      { position: new Vector3(0, 0, 0) },
      { position: new Vector3(0, -size, 0) },
      { position: new Vector3(distance, -size, 0) },
      { position: new Vector3(distance, 0, 0) },
    ]
    const geometry = createFaceGeometry(vertices)
    geometry.rotateZ(Math.atan2(direction.x, -direction.y))

    if (this.tapeMeasureFace) {
      this.tapeMeasureFace.geometry.dispose()
      this.tapeMeasureFace.geometry = geometry
    } else {
      this.tapeMeasureFace = new Mesh(geometry, getMaterial(materialTypes.scalePatternLow))
      this.add(this.tapeMeasureFace)
    }

    this.tapeMeasureFace.position.setX(startPosition.x)
    this.tapeMeasureFace.position.setY(startPosition.y)
  }

  dispose () {
    super.dispose()
    if (this.tapeMeasureFace) {
      this.tapeMeasureFace.geometry.dispose()
    }
  }

  fromJSON (json) {
    super.fromJSON(json)
    this.updateTapeMeasureFace()
    this.setActive(false)
  }

}