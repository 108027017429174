import React from 'react'

const SvgCorporateDataIconInactive = props => (
  <svg className={props.className} viewBox="0 0 60 54">
    <g data-name="BuildingsFloorsInspector Data_Icon_inactive">
      <path d="M0 0h60v54H0z" data-name="Rechteck 216" fill="none"/>
      <path
        d="M33.004 34.574h-14.5a7.261 7.261 0 0 1 7.251-7.251 7.254 7.254 0 0 1 6.088 3.315 5.642 5.642 0 0 1 4.087-1.75 5.665 5.665 0 0 1 5.658 5.657h-8.584v.027zm-.309-9.727a3.233 3.233 0 0 1 3.234-3.233 3.233 3.233 0 0 1 3.234 3.233 3.233 3.233 0 0 1-3.234 3.234 3.234 3.234 0 0 1-3.232-3.234zm-11.085-2.7a4.144 4.144 0 0 1 4.145-4.144 4.143 4.143 0 0 1 4.144 4.144 4.143 4.143 0 0 1-4.144 4.144 4.144 4.144 0 0 1-4.144-4.147z"
        data-name="Pfad 1039"
        fill="#8f939d"
      />
    </g>
  </svg>
)

export default SvgCorporateDataIconInactive
