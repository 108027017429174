import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import AuthService from '../auth/auth.service'
import { settings } from '../config.service'

class AzureInsightsService {
    tracking = false
    async init() {
        const trackingId = settings.appInsightsTrackingId
        if (trackingId) {
            const user = await AuthService.getInstance().getUser()

            this.appInsights = new ApplicationInsights({
                config: { instrumentationKey: trackingId }
            })

            this.appInsights.loadAppInsights()
            this.tracking = true
            if (user) {
                this.appInsights.setAuthenticatedUserContext(user.sub, user.organizationId)
            }
        }
    }

    pageView(pathName) {
        if (this.tracking) {
            this.appInsights.trackPageView({
                name: pathName,
                uri: pathName
            })
        }
    }

    trackEvent(eventName){
        if(this.tracking) {
            this.appInsights.trackEvent({
                name: eventName
              })
        }
    }
}

export const azureInsightsService = new AzureInsightsService()