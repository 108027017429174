import React, { Component } from 'react'
import './LoadingScreen.scss'
import { connect } from 'react-redux'
import loaderImage from '../../../assets/gif/loading.gif'

class LoadingScreen extends Component {

  render () {

    return (
      <div id="LoadingScreen" className={this.props.active > 0 ? 'active' : 'inactive'}>
        <div className="LoadingScreen-Panel">
          <img src={loaderImage} id="loaderImage" alt={'loading...'}/>
        </div>
      </div>
    )
  }
}

let mapStateToProps = (state, ownProps) => {
  return {
    active: state.loaderState.active || ownProps.active,
  }
}

export default connect(mapStateToProps, null)(LoadingScreen)