import React from 'react'
import Text, { textTypes } from '../../../common/components/Text'
import './DrawingToolPlaceholder.scss'
import { withTranslation } from 'react-i18next'
import UploadPicture from '../../../common/components/icons/UploadPicture'
import DrawScaleInactive from '../../../common/components/icons/DrawScaleInactive'
import DrawOuterWall from '../../../common/components/icons/DrawOuterWall'
import DrawInnerWall from '../../../common/components/icons/DrawInnerWall'
import SvgDrawWindowInactive from '../../../common/components/icons/DrawWindowInactive'
import DrawDoor from '../../../common/components/icons/DrawDoorNew'
import SvgDrawPillarInactive from '../../../common/components/icons/DrawPillarInactive'

function DrawingToolPlaceholder ({ t, onboarding }) {
  return (
    <div className={'drawing-editor-upload-placeholder' + (onboarding ? ' onboarding' : '')}>
      <div className={'prepare-floorplan-container'}>
        <Text component={textTypes.subTitle}>{t('how_to_prepare_floorplan')}</Text><br/>

        <div className={'prepare-floorplan-wrapper'}>
          <UploadPicture/>
          <Text component={textTypes.paragraph} multiline>{t('upload_floorplan')}</Text>
        </div>
        <div className={'prepare-floorplan-wrapper'}>
          <DrawScaleInactive color={'#001D34'} width={24} height={24}/>
          <Text component={textTypes.paragraph}>{t('define_scaling')}</Text>
        </div>
        <div className={'prepare-floorplan-wrapper'}>
          <DrawOuterWall color={'#001D34'} width={24} height={24}/>
          <DrawInnerWall color={'#001D34'} width={24} height={24}/>
          <Text component={textTypes.paragraph} multiline>{t('redraw_walls')}</Text>
        </div>
        <div className={'prepare-floorplan-wrapper'}>
          <DrawDoor color={'#001D34'} width={24} height={24}/>
          <SvgDrawWindowInactive color={'#001D34'} width={24} height={24}/>
          <SvgDrawPillarInactive color={'#001D34'} width={24} height={24}/>
          <Text component={textTypes.paragraph} multiline>{t('redraw_doors_windows')}</Text>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(DrawingToolPlaceholder)