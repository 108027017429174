import React from 'react'

const SvgCollapsedIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z"/>
      <path fill="#373B51" d="M10.243 16.485l4.242-4.242L10.243 8z"/>
    </g>
  </svg>
)

export default SvgCollapsedIcon
