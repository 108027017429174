import React from 'react'

const SvgHeatmapLightActive = props => (
  <svg className={props.className} width={36} height={36}>
    <defs>
      <linearGradient
        id="heatmap-light-active_svg__a"
        x1="18.4%"
        x2="87.2%"
        y1="0%"
        y2="105.3%"
      >
        <stop offset="0%" stopColor="#FC7E1B"/>
        <stop offset="100%" stopColor="#FBB700"/>
      </linearGradient>
    </defs>
    <path
      fill="url(#heatmap-light-active_svg__a)"
      fillRule="evenodd"
      d="M18 26a1 1 0 011 1v3a1 1 0 01-2 0v-3a1 1 0 011-1zm7.071-2.343l2.121 2.121a1 1 0 11-1.414 1.414l-2.121-2.121a1 1 0 011.414-1.414zm-12.728 0a1 1 0 010 1.414l-2.121 2.121a1 1 0 01-1.414-1.414l2.121-2.121a1 1 0 011.414 0zM18 11a7 7 0 110 14 7 7 0 010-14zm12 6a1 1 0 010 2h-3a1 1 0 010-2h3zM9 17a1 1 0 010 2H6a1 1 0 010-2h3zm18.192-8.192a1 1 0 010 1.414l-2.121 2.121a1 1 0 01-1.414-1.414l2.121-2.121a1 1 0 011.414 0zm-16.97 0l2.121 2.121a1 1 0 01-1.414 1.414l-2.121-2.121a1 1 0 011.414-1.414zM18 5a1 1 0 011 1v3a1 1 0 01-2 0V6a1 1 0 011-1z"
    />
  </svg>
);

export default SvgHeatmapLightActive
