export function checkCameraPosition (camera, orbit, minVector, maxVector) {

  let y = camera.position.y
  let x = camera.position.x
  let z = camera.position.z

  if (y < minVector.y || y > maxVector.y) {
    camera.position.setY(y < minVector.y ? minVector.y : maxVector.y)
  }

  if (x < minVector.x || x > maxVector.x) {
    orbit.target.x = x < minVector.x ? minVector.x : maxVector.x
    camera.position.setX(x < minVector.x ? minVector.x : maxVector.x)
  }

  if (z < minVector.z || z > maxVector.z) {
    orbit.target.z = z < minVector.z ? minVector.z : maxVector.z
    camera.position.setZ(z < minVector.z ? minVector.z : maxVector.z)
  }
}