import React, { Component } from 'react'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { createSVGApplication } from '../../three/svgActions'
import IconButton from '../IconButton'
import ModalConsumer from '../modal/ModalConsumer'
import DeletionDialog from '../DeletionDialog'
import { iconTypes } from '../icons/IconFactory'

import './BuilderOverviewElement.scss'

class BuilderOverviewElement extends Component {

  nameInput

  render () {

    let image = this.props.imagePolys ? createSVGApplication(200, 100, this.props.imagePolys.polys, this.props.imagePolys.lines, 'svgImage', this.props.imagePolys.bounds,
      this.props.imagePolys.wallcode) : <div/>

    return (
      <div className={'builder-overview-element'}
           onClick={() => this.props.onElementClicked(this.props.overviewContent.id)}>
        <div className={'builder-overview-element-content'}>
          <div className={'element-content-header'}>

            <div className={'builder-overview-element-title'} onClick={(event) => event.stopPropagation()}>
              <Formik onSubmit={(values) => this.handleEditSubmit(values)}
                      initialValues={this.props.overviewContent}
                      enableReinitialize
                      render={props =>
                        <Form>
                          <Field
                            innerRef={(input) => { this.nameInput = input }}
                            className={'title-edit'}
                            type={'text'}
                            name={'displayName'}
                            autoFocus={false}
                            autoComplete={'off'}
                            onBlur={props.handleSubmit}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.target.blur()
                              }
                            }}
                          />
                        </Form>
                      }/>
            </div>

            <div>
              <IconButton icon={iconTypes.editLine} tooltip={'Rename'} onClick={(event) => this.handleEditClicked(event)}/>
              <IconButton icon={iconTypes.duplicate} tooltip={'Duplicate'} onClick={(event) => this.handleDuplicateClicked(event)}/>
              <ModalConsumer>
                {({ showModal }) => (
                  <IconButton icon={iconTypes.delete} tooltip={'Delete'} onClick={(event) => {
                    event.stopPropagation()
                    showModal(DeletionDialog, {
                      onConfirm: this.handleDeleteClicked,
                      entityName: this.props.displayName,
                    })
                  }}/>
                )}
              </ModalConsumer>
            </div>

          </div>

          <div className={'element-content-subheader'}>
            {this.props.subtitle}
          </div>
          {image}
        </div>
      </div>
    )
  }

  handleEditClicked (event) {
    event.stopPropagation()
    this.nameInput.focus()
  }

  handleEditSubmit (values) {
    this.props.renameContent(values)
  }

  handleDeleteClicked = () => {
    this.props.onDeleteClicked(this.props.projectId, this.props.overviewContent.id)
  }

  handleDuplicateClicked (event) {
    event.stopPropagation()
    this.props.onDuplicateClicked(this.props.overviewContent)
  }

}

BuilderOverviewElement.propTypes = {
  displayName: PropTypes.string,
  overviewContent: PropTypes.object,
  onElementClicked: PropTypes.func.isRequired,
  renameContent: PropTypes.func,
  onDeleteClicked: PropTypes.func.isRequired,
  onDuplicateClicked: PropTypes.func.isRequired,
  imagePolys: PropTypes.object,
}

export default BuilderOverviewElement
