import { httpClient } from '../../common/httpClient'
import store from '../../store'
import { floorPlanActionTypes } from '../constants'
import { getPlacedOrganisationalUnit } from '../reducers/organisationalUnits'
import { getBuildingId, getFloorId, getProjectId, projectApi, projectVariationApi } from '../../common/utils'
import { fetchOrganisationalUnits, organisationalUnitsActionTypes } from '../../common/redux/organisationunits/organisational-units-actions'
import { fetchApplicationSettings, settingsActionTypes } from '../../common/redux/settings/settings-actions'
import { fetchApplicationProfiles } from '../../common/redux/styles/application-profiles/application-profiles-actions'
import { appStateActionTypes, fetchAppState } from '../../common/redux/appstate/appstate-actions'
import { fetchFurnitureEnsembleCategories, fetchFurnitureEnsembles, fetchFurnitureEnsemblesPolygons } from '../../applicationbuilder/actions/backendActions'
import { getUndoInformation } from '../../common/redux/undo/undo-actions'
import { fetchBase64ImageForFloorAPI, fetchCurrentDrawingTab, fetchDrawing, fetchFloorPlanAnalytics, fetchGeometries } from '../../floorplanner/actions/actions'
import { setDataLoaded } from '../../common/redux/dataLoader/dataLoader-actions'
import { fetchPatternList } from '../../patternbuilder/actions/actions'
import { fetchIndustries } from '../../settings/redux/industry-attributes-actions'
import { fetchCountries } from '../../settings/redux/country-actions'
import { fetchVariationsAPI } from '../../common/redux/variations/variations-actions'
import { fetchFrontendLocation } from '../../common/redux/frontendLocation/frontend-location-action'
import { drawingActionTypes } from '../../floorplanner/actions/drawingActions'
import { deactivateProject } from '../../common/redux/project-activation/project-activation-action'

//export imported actions
export { fetchOrganisationalUnits }

export function fetchBuildings () {
  return {
    type: floorPlanActionTypes.FETCH_BUILDINGS,
    payload: httpClient.get(projectVariationApi() + '/buildings'),
  }
}

export function setSelectedApplicationId (applicationId) {
  return {
    type: floorPlanActionTypes.SET_SELECTED_APPLICATION_ID,
    id: applicationId,
  }
}

export function getFloorLightmap () {
  const buildingId = getBuildingId()
  const floorId = getFloorId()

  return (dispatch) => {
    dispatch(getFloorLightmapAPICall(buildingId, floorId))
  }
}

export function deleteRoom (roomId) {
  const buildingId = getBuildingId()
  const floorId = getFloorId()

  return (dispatch) => {
    dispatch(deleteRoomAPICall(buildingId, floorId, roomId))
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(buildingId, floorId))
        dispatch(fetchRooms(buildingId, floorId))
        dispatch(fetchFloorSummary(floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function getFloorLightmapAPICall (buildingId, floorId) {
  return {
    type: floorPlanActionTypes.FETCH_FLOOR_LIGHTMAP,
    payload: httpClient.getFile(encodeURI(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/lightmap`)),
  }
}

function deleteRoomAPICall (buildingId, floorId, roomId) {
  return {
    type: floorPlanActionTypes.DELETE_EXTRA,
    payload: httpClient.delete(encodeURI(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/rooms/${roomId}`)),
  }
}

export function updateRoom (roomId, room) {
  const buildingId = getBuildingId()
  const floorId = getFloorId()

  return (dispatch) => {
    dispatch(updateRoomAPICall(buildingId, floorId, roomId, room))
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(buildingId, floorId))
        dispatch(fetchRooms(buildingId, floorId))
        dispatch(fetchFloorSummary(floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function updateRoomAPICall (buildingId, floorId, roomId, room) {

  return {
    type: floorPlanActionTypes.UPDATE_EXTRA,
    payload: httpClient.put(encodeURI(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/rooms/${roomId}`), room),
  }
}

export function setSelectedRowId (rowId) {
  return {
    type: floorPlanActionTypes.SET_SELECT_ROW,
    id: rowId,
  }
}

export function setSelectedContextMenuId (contextMenuId, organisationalUnitId) {
  return {
    type: floorPlanActionTypes.SET_SELECT_CONTEXT_MENU,
    id: contextMenuId,
    organisationalUnitId: organisationalUnitId ? organisationalUnitId : contextMenuId,
  }

}

export function fetchOrganisationalUnitFurnitureEnsemblesSummary (unitId) {
  return {
    type: floorPlanActionTypes.FETCH_PLACED_ORGANISATIONAL_UNIT_FURNITURE_ENSEMBLE_SUMMARY,
    payload: httpClient.get(encodeURI(`${projectVariationApi()}/organisational-units/${unitId}/furniture-ensembles/summary`)),
  }
}

export function optimiseFloorPlacing () {
  const buildingId = getBuildingId()
  const floorId = getFloorId()

  return (dispatch) => {
    dispatch(updateFloorPlacing(buildingId, floorId))
      .then(() => {
        dispatch(fetchFloorData(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function generateAll (distributionIndex = 0) {
  const buildingId = getBuildingId()
  const floorId = getFloorId()
  console.log("GenerateAll Status:")
  console.log(buildingId)
  console.log(floorId)

  return (dispatch) => {
    dispatch(generateAllAPI(buildingId, floorId, distributionIndex))
      .then(() => {
        dispatch(fetchBuildings())
        dispatch(fetchOrganisationalUnits())
        dispatch(fetchPlacedOrganisationalUnits())
        dispatch(fetchDepartmentAssignments())
        dispatch(fetchFloorData(buildingId, floorId))
      })
  }
}

export function setSelectedFloor (buildingId, floorId) {
  return (dispatch) => {
    dispatch(setSelectedFloorAPI(buildingId, floorId))
      .then(() => {
        dispatch(fetchFloorData(buildingId, floorId))
      })
  }
}

function setSelectedFloorAPI (buildingId, floorId) {
  const body = {
    buildingId: buildingId,
    floorId: floorId,
  }

  return {
    type: appStateActionTypes.FETCH_APP_STATE,
    payload: httpClient.post(`${projectApi()}/appState`, body),
  }
}

export function fetchFloorData (buildingId, floorId, floorplanOnly = false) {
  console.log("fetchFloorData: "+floorplanOnly)
  return function (dispatch) {
    if (!buildingId || !floorId) {
      dispatch(fetchAppState())
        .then(({ value }) => {
          ({ buildingId, floorId } = value.data)
          if (floorplanOnly) {
            fetchSelectedFloorplanPlacementAPI(dispatch, buildingId, floorId)
          } else {
            fetchSelectedFloorplanPlacementAPI(dispatch, buildingId, floorId)
            fetchSelectedFloorGeometriesAPI(dispatch, buildingId, floorId)
            fetchSelectedFloorGeometriesAPI2(dispatch, buildingId, floorId)
          }
        })
    } else {
      if (floorplanOnly) {
        fetchSelectedFloorplanPlacementAPI(dispatch, buildingId, floorId)
      } else {
        fetchSelectedFloorplanPlacementAPI(dispatch, buildingId, floorId)
        fetchSelectedFloorGeometriesAPI(dispatch, buildingId, floorId)
        fetchSelectedFloorGeometriesAPI2(dispatch, buildingId, floorId)
      }
    }
  }
}

function fetchSelectedFloorplanPlacementAPI (dispatch, buildingId, floorId) {
  console.log("fetchSelectedFloorplanPlacementAPI")
  dispatch(fetchPlacedOrganisationalUnits(buildingId, floorId))
  dispatch(fetchDepartmentAssignments(buildingId))
  dispatch(fetchRooms(buildingId, floorId))
  dispatch(fetchMarkers(buildingId, floorId))
  dispatch(fetchConnections(buildingId, floorId))
  dispatch(fetchConnectables(floorId))
  dispatch(fetchFloorSummary(floorId))
}

function fetchSelectedFloorGeometriesAPI (dispatch, buildingId, floorId) {
  console.log("fetchSelectedFloorGeometriesAPI")
  dispatch(fetchFloorPlan(buildingId, floorId))
  dispatch(fetchFloorPlanAnalytics(buildingId, floorId))
  dispatch(fetchDrawing(buildingId, floorId))
  dispatch(fetchCurrentDrawingTab(buildingId, floorId))
  dispatch(fetchBase64ImageForFloorAPI(buildingId, floorId))
}

function fetchSelectedFloorGeometriesAPI2 (dispatch, buildingId, floorId) {
  console.log("fetchSelectedFloorGeometriesAPI2")
  dispatch(fetchGeometries(buildingId, floorId))
}

export function fetchProjectFile (projectId) {
  return {
    type: floorPlanActionTypes.FETCH_FILE,
    payload: httpClient.getFile(`${projectApi(projectId)}/file`),
  }
}

export function fetchFloorPlanFile (buildingId, floorId) {
  return {
    type: floorPlanActionTypes.FETCH_FILE,
    payload: httpClient.getFile(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/dxf`),
  }
}

export function fetchFloorPlanPdf () {
  const buildingId = getBuildingId()
  const floorId = getFloorId()

  return {
    type: floorPlanActionTypes.FETCH_FILE,
    payload: httpClient.getFile(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/pdf`),
  }
}

export function fetchPConExportFile (buildingId, floorId) {
  return {
    type: floorPlanActionTypes.FETCH_FILE,
    payload: httpClient.getFile(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/pcon`),
  }
}

export function fetchFloorDepartmentsSummaryFile (buildingId, floorId) {
  return {
    type: floorPlanActionTypes.FETCH_FILE,
    payload: httpClient.getFile(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/pdf`),
  }
}

export function fetchDepartmentSummaryFile (buildingId, floorId, unitId) {
  return {
    type: floorPlanActionTypes.FETCH_FILE,
    payload: httpClient.getFile(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/${unitId}/pdf`),
  }
}

export function fetchXSLXFileSingleFloor (buildingId, floorId, type) {
  return {
    type: floorPlanActionTypes.FETCH_FILE,
    payload: httpClient.getFile(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/xlsx`, { type: type }),
  }
}

export function fetchXSLXFileAllFloors (buildingId, type) {
  return {
    type: floorPlanActionTypes.FETCH_FILE,
    payload: httpClient.getFile(`${projectVariationApi()}/buildings/${buildingId}/floors/xlsx`, { type: type }),
  }
}

export function fetchProjectData (projectId) {

  return function (dispatch) {
    dispatch(fetchFrontendLocation(projectId))
    dispatch(fetchVariationsAPI(projectId))
      .then(() => {
        dispatch(fetchApplicationSettings())
          .then(() => {
            dispatch(fetchAppState())
              .then(({ value }) => {
                const { buildingId, floorId } = value.data
                dispatch(fetchFloorData(buildingId, floorId))
                dispatch(fetchFloorSummary(floorId))

                dispatch(fetchFurnitureLibrary())
                dispatch(fetchBuildings())

                dispatch(fetchOrganisationalUnits())

                dispatch(fetchApplicationProfiles())
                dispatch(fetchPatternStyles())
                dispatch(fetchLayoutStyles())

                dispatch(fetchWorkplaceTypes())
                dispatch(fetchPatternTypes())

                dispatch(fetchTags())
                dispatch(fetchFurnitureEnsembleCategories())

                dispatch(fetchPatternList())
                dispatch(fetchFurnitureEnsembles())
                dispatch(fetchFurnitureEnsemblesPolygons())

                dispatch(fetchIndustries())
                dispatch(fetchCountries())

                dispatch(getUndoInformation(projectId))
                dispatch(setDataLoaded(true))
              })
          })
      })
  }
}

export function fetchFloorPlan (buildingId, floorId) {
  return {
    type: floorPlanActionTypes.FETCH_FLOOR_PLAN,
    payload: httpClient.get(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}`),
  }
}

export function fetchFloorSummary (floorId) {
  let param = { floor: floorId }

  return {
    type: floorPlanActionTypes.FETCH_FLOOR_SUMMARY,
    payload: httpClient.get(`${projectVariationApi()}/summary`, param),
  }
}

export function clearProject () {
  return function (dispatch) {
    dispatch(deactivateProject())
    dispatch(clearFloorplanData())
    dispatch(clearDrawingData())
    dispatch(clearSettings())
  }
}

function clearFloorplanData () {
  return {
    type: floorPlanActionTypes.CLEAR_PROJECT,
  }
}

function clearDrawingData () {
  return {
    type: drawingActionTypes.CLEAR_PROJECT,
  }
}

function clearSettings () {
  return {
    type: settingsActionTypes.CLEAR_PROJECT,
  }
}

export function setPlanDirtyFlag () {
  return {
    type: floorPlanActionTypes.SET_PLAN_DIRTY,
  }
}

function updateFloorPlacing (buildingId, floorId) {
  return {
    type: floorPlanActionTypes.UPDATE_FLOOR_PLACING,
    payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/placing`),
  }
}

function generateAllAPI (buildingId, floorId, distributionIndex) {
  const body = { value: distributionIndex }

  return {
    type: floorPlanActionTypes.UPDATE_FLOOR_PLACING,
    payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/generate`, body),
  }
}

export function fetchFurnitureLibrary () {
  return {
    type: floorPlanActionTypes.FETCH_FURNITURE_LIBRARY,
    payload: httpClient.get(projectApi() + '/furniture'),
  }
}

export function setDropObject (type, id) {
  return {
    type: 'SET_DROP_OBJECT',
    objectType: type,
    objectId: id,
  }
}

export function dropDepartment (unitId, x, y) {
  const organisationalUnit = getPlacedOrganisationalUnit(store.getState(), unitId)
  const position = {
    x: x,
    y: y,
    z: 0,
  }

  return function (dispatch) {
    dispatch(updateOrganisationalUnitPosition(organisationalUnit.buildingId, organisationalUnit.floorId, unitId, position))
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(organisationalUnit.buildingId, organisationalUnit.floorId))
        dispatch(fetchFloorData(organisationalUnit.buildingId, organisationalUnit.floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function updateOrganisationalUnitPosition (buildingId, floorId, unitId, position) {
  return {
    type: floorPlanActionTypes.UPDATE_ORGANISATIONAL_UNITS,
    payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/${unitId}/position`, position),
  }
}

export function resizeDepartment (unitId, index, x, y) {
  const organisationalUnit = getPlacedOrganisationalUnit(store.getState(), unitId)
  const projectId = store.getState().projects.project.id
  const position = {
    x: x,
    y: y,
    z: 0,
  }

  return function (dispatch) {
    dispatch(updateOrganisationalUnitDimension(organisationalUnit.buildingId, organisationalUnit.floorId, unitId, index, position))
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(organisationalUnit.buildingId, organisationalUnit.floorId))
        dispatch(fetchFloorData(organisationalUnit.buildingId, organisationalUnit.floorId))
        dispatch(getUndoInformation(projectId))
      })
  }
}

function updateOrganisationalUnitDimension (buildingId, floorId, unitId, index, position) {
  return {
    type: floorPlanActionTypes.UPDATE_ORGANISATIONAL_UNITS,
    payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/${unitId}/dimension/${index}`, position),
  }
}

export function dropApplication (unitId, applicationId, x, y) {
  const organisationalUnit = getPlacedOrganisationalUnit(store.getState(), unitId)

  const position = {
    x: x,
    y: y,
    z: 0,
  }

  return function (dispatch) {
    dispatch(updateApplicationPosition(organisationalUnit.buildingId, organisationalUnit.floorId, unitId, applicationId, position))
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(organisationalUnit.buildingId, organisationalUnit.floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function updateApplicationPosition (buildingId, floorId, unitId, applicationId, position) {
  return {
    type: floorPlanActionTypes.UPDATE_ORGANISATIONAL_UNITS,
    payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/${unitId}/applications/${applicationId}/position`, position),
  }
}

export function rotateApplication (unitId, applicationId, rot) {
  const organisationalUnit = getPlacedOrganisationalUnit(store.getState(), unitId)

  return function (dispatch) {
    dispatch(updateApplicationRotation(organisationalUnit.buildingId, organisationalUnit.floorId, unitId, applicationId, { value: rot }))
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(organisationalUnit.buildingId, organisationalUnit.floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function updateApplicationRotation (buildingId, floorId, unitId, applicationId, value) {
  return {
    type: floorPlanActionTypes.UPDATE_ORGANISATIONAL_UNITS,
    payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/${unitId}/applications/${applicationId}/rotate`, value),
  }
}

export function dropRow(unitId, rowId, x, y) {
  let organisationalUnit = getPlacedOrganisationalUnit(store.getState(), unitId)

  const position = {
    x: x,
    y: y,
    z: 0,
  }

  return (dispatch) => {
    dispatch(updateOrganisationalUnitRowPosition(organisationalUnit.buildingId, organisationalUnit.floorId, unitId, rowId, position))
        .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(organisationalUnit.buildingId, organisationalUnit.floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function updateOrganisationalUnitRowPosition (buildingId, floorId, unitId, rowId, position) {
  return {
    type: floorPlanActionTypes.UPDATE_ORGANISATIONAL_UNITS,
    payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/${unitId}/rows/${rowId}/position`, position),
  }
}

export function fetchPlacedOrganisationalUnits (buildingId, floorId) {
  if (!buildingId)
    buildingId = getBuildingId()
  if (!floorId)
    floorId = getFloorId()

  return {
    type: floorPlanActionTypes.FETCH_PLACED_ORGANISATIONAL_UNITS,
    payload: httpClient.get(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units`),
  }
}

export function fetchDepartmentAssignments (buildingId) {
  if (!buildingId)
    buildingId = getBuildingId()

  return {
    type: floorPlanActionTypes.FETCH_DEPARTMENT_ASSIGNMENTS,
    payload: httpClient.get(`${projectVariationApi()}/buildings/${buildingId}/organisational-units`),
  }
}

export function fetchRooms (buildingId, floorId) {
  return {
    type: floorPlanActionTypes.FETCH_ROOMS,
    payload: httpClient.get(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/rooms`),
  }
}

export function createOrganisationalUnitAssignment (unitId, assignment) {
  return (dispatch) => {
    dispatch({
      type: floorPlanActionTypes.UPDATE_ORGANISATIONAL_UNITS,
      payload: httpClient.post(`${projectVariationApi()}/organisational-units/${unitId}/assignment`, assignment),
    })
      .then(() => {
        dispatch(fetchFloorData(assignment.buildingId, assignment.floorId))
        dispatch(fetchOrganisationalUnits())
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function updateOrganisationalUnitAssignment (buildingId, floorId, unitId, assignment) {
  return (dispatch) => {
    dispatch({
      type: floorPlanActionTypes.UPDATE_ORGANISATIONAL_UNITS,
      payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/${unitId}/assignment`,
        assignment),
    })
      .then(() => {
        dispatch(fetchFloorData(assignment.buildingId, assignment.floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function deleteOrganisationalUnitAssignment (buildingId, floorId, unitId) {
  if (!buildingId || !floorId) {
    buildingId = getBuildingId()
    floorId = getFloorId()
  }

  return (dispatch) => {
    dispatch({
      type: floorPlanActionTypes.UPDATE_ORGANISATIONAL_UNITS,
      payload: httpClient.delete(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/${unitId}/assignment`),
    })
      .then(() => {
        dispatch(fetchFloorData(buildingId, floorId))
        dispatch(fetchOrganisationalUnits())
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function deleteOrganisationalUnitAssignmentInFloor (buildingId, floorId) {
  return (dispatch) => {
    dispatch({
      type: floorPlanActionTypes.UPDATE_ORGANISATIONAL_UNITS,
      payload: httpClient.delete(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/assignment`),
    })
      .then(() => {
        dispatch(fetchFloorData(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function autoGenerateAssigmentInFloor (buildingId, floorId) {
  if (!buildingId)
    buildingId = getBuildingId()
  if (!floorId)
    floorId = getFloorId()

  return (dispatch) => {
    dispatch({
      payload: httpClient.post(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/autoGenerateAssignment`),
    })
      .then(() => {
        dispatch(fetchFloorData(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function deleteOrganisationalUnitAssignmentsInBuilding (buildingId, floorId) {
  return (dispatch) => {
    dispatch({
      type: floorPlanActionTypes.UPDATE_ORGANISATIONAL_UNITS,
      payload: httpClient.delete(`${projectVariationApi()}/buildings/${buildingId}/assignment`),
    })
      .then(() => {
        dispatch(fetchFloorData(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function autoGenerateAssignmentsInBuilding (buildingId) {
  return (dispatch) => {
    dispatch({
      type: floorPlanActionTypes.UPDATE_ORGANISATIONAL_UNITS,
      payload: httpClient.post(`${projectVariationApi()}/buildings/${buildingId}/assignment`),
    })
      .then(() => {
        dispatch(fetchFloorData())
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function updateOrganisationalUnitCulture (buildingId, floorId, unitId, culture) {
  return (dispatch) => {
    dispatch({
      type: floorPlanActionTypes.UPDATE_PLACED_ORGANISATIONAL_UNITS,
      payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/${unitId}/culture-lens`, culture),
    })
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function updateOrganisationalUnitWorkStyle (buildingId, floorId, unitId, workStyle) {
  return (dispatch) => {
    dispatch({
      type: floorPlanActionTypes.UPDATE_PLACED_ORGANISATIONAL_UNITS,
      payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/${unitId}/work-style-lens`, workStyle),
    })
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function updateOrganisationalUnitOfficeLens (buildingId, floorId, unitId, values) {
  return (dispatch) => {
    dispatch({
      type: floorPlanActionTypes.UPDATE_PLACED_ORGANISATIONAL_UNITS,
      payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/${unitId}/office-lens`, values),
    })
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function updateOrganisationalUnitRequirements (buildingId, floorId, unitId, requirement) {

  return (dispatch) => {
    dispatch({
      type: organisationalUnitsActionTypes.UPDATE_ORGANISATIONAL_UNIT,
      payload: httpClient.patch(`${projectVariationApi()}/organisational-units/${unitId}/requirement`, requirement),
    })
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function updateOrganisationalUnitLayoutStyle (buildingId, floorId, unitId, layoutStyle) {
  return (dispatch) => {
    dispatch({
      type: floorPlanActionTypes.UPDATE_PLACED_ORGANISATIONAL_UNITS,
      payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/${unitId}/layout-style`, layoutStyle),
    })
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function updateOrganisationalUnitPatternStyle (buildingId, floorId, unitId, patternStyle) {
  return (dispatch) => {
    dispatch({
      type: floorPlanActionTypes.UPDATE_PLACED_ORGANISATIONAL_UNITS,
      payload: httpClient.put(encodeURI(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/${unitId}/pattern-style`),
        patternStyle),
    })
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function updateOrganisationalUnitApplicationProfile (buildingId, floorId, unitId, profile) {
  return (dispatch) => {
    dispatch({
      type: floorPlanActionTypes.UPDATE_PLACED_ORGANISATIONAL_UNITS,
      payload: httpClient.put(encodeURI(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/${unitId}/application-profile`),
        profile),
    })
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function updateOrganisationalUnitTags (buildingId, floorId, unitId, tags) {
  return (dispatch) => {
    dispatch({
      type: floorPlanActionTypes.UPDATE_ORGANISATIONAL_UNITS,
      payload: httpClient.put(encodeURI(`${projectVariationApi()}/organisational-units/${unitId}/tags`), tags),
    })
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function fetchLayoutStyles () {
  return {
    type: floorPlanActionTypes.FETCH_LAYOUT_STYLES,
    payload: httpClient.get(encodeURI(`${projectApi()}/layout-styles`)),
  }

}

export function fetchPatternStyles () {
  return {
    type: floorPlanActionTypes.FETCH_PATTERN_STYLES,
    payload: httpClient.get(encodeURI(`${projectApi()}/pattern-styles`)),
  }

}

export function fetchWorkplaceTypes () {
  return {
    type: floorPlanActionTypes.FETCH_WORKPLACE_TYPES,
    payload: httpClient.get(encodeURI(`${projectApi()}/workplace-types`)),
  }

}

export function fetchPatternTypes () {
  return {
    type: floorPlanActionTypes.FETCH_PATTERN_TYPES,
    payload: httpClient.get(encodeURI(`${projectApi()}/pattern-types`)),
  }

}

export function fetchTags () {
  return {
    type: floorPlanActionTypes.FETCH_TAGS,
    payload: httpClient.get(encodeURI(`${projectApi()}/tags`)),
  }

}

export function fetchMarkers (buildingId, floorId = null) {
  return {
    type: floorPlanActionTypes.FETCH_MARKERS,
    payload: httpClient.get(`${projectVariationApi()}/buildings/${buildingId}/markers?floor=${floorId}`),
  }
}

export function createMarker (marker) {
  const buildingId = getBuildingId()
  const floorId = getFloorId()

  return function (dispatch) {
    dispatch(createMarkerAPICall(buildingId, marker))
      .then(() => {
        dispatch(fetchMarkers(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function createMarkerAPICall (buildingId, marker) {
  return {
    type: floorPlanActionTypes.CREATE_MARKER,
    payload: httpClient.post(`${projectVariationApi()}/buildings/${buildingId}/markers`, marker),
  }
}

export function fetchConnections (buildingId, floorId) {
  return {
    type: floorPlanActionTypes.FETCH_CONNECTIONS,
    payload: httpClient.get(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/connections`),
  }
}

export function createConnection (buildingId, floorId, connection) {
  return {
    type: floorPlanActionTypes.CREATE_CONNECTION,
    payload: httpClient.post(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/connections`, connection),
  }
}

export function updateConnectionAndFetch (buildingId, floorId, connectionId, connection) {
  return function (dispatch) {
    dispatch(updateConnection(buildingId, floorId, connectionId, connection))
      .then(() => {
        dispatch(fetchConnections(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function updateConnection (buildingId, floorId, connectionId, connection) {
  return {
    type: floorPlanActionTypes.UPDATE_CONNECTION,
    payload: httpClient.patch(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/connections/${connectionId}`, connection),
  }
}

export function deleteConnectionAndFetch (buildingId, floorId, connectionId) {
  return function (dispatch) {
    dispatch(deleteConnection(buildingId, floorId, connectionId))
      .then(() => {
        dispatch(fetchConnections(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function deleteConnection (buildingId, floorId, connectionId) {
  return {
    type: floorPlanActionTypes.DELETE_CONNECTION,
    payload: httpClient.delete(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/connections/${connectionId}`),
  }
}

export function fetchConnectables (floorId) {
  return {
    type: floorPlanActionTypes.FETCH_CONNECTABLES,
    payload: httpClient.get(`${projectVariationApi()}/connectables`, { floor: floorId }),
  }

}

export function deleteMarker (markerId) {
  const buildingId = getBuildingId()
  const floorId = getFloorId()

  return function (dispatch) {
    dispatch(deleteMarkerAPICall(buildingId, markerId))
      .then(() => {
        dispatch(fetchMarkers(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function deleteMarkerAPICall (buildingId, markerId) {
  return {
    type: floorPlanActionTypes.DELETE_MARKER,
    payload: httpClient.delete(`${projectVariationApi()}/buildings/${buildingId}/markers/${markerId}`),
  }
}

export function updateMarker (marker) {
  const buildingId = getBuildingId()
  const floorId = getFloorId()

  return function (dispatch) {
    dispatch(updateMarkerAPICall(buildingId, marker.id, marker))
      .then(() => {
        dispatch(fetchMarkers(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function updateMarkerAPICall (buildingId, markerId, marker) {
  return {
    type: floorPlanActionTypes.UPDATE_MARKER,
    payload: httpClient.patch(`${projectVariationApi()}/buildings/${buildingId}/markers/${markerId}`, marker),
  }
}

export function lockApplication (unitId, applicationId, lockState) {

  const organisationalUnit = getPlacedOrganisationalUnit(store.getState(), unitId)
  let boolWrapper = { state: lockState }

  return {
    type: floorPlanActionTypes.LOCK_APPLICATION,
    payload:
      httpClient.put(`${projectVariationApi()}/buildings/${organisationalUnit.buildingId}/floors/${organisationalUnit.floorId}/organisational-units/${unitId}/applications/${applicationId}/lock`,
        boolWrapper),
  }
}

export function optimizeDepartment (unitId) {
  const organisationalUnit = getPlacedOrganisationalUnit(store.getState(), unitId)

  return function (dispatch) {
    dispatch(optimizeDepartmentAPICall(organisationalUnit, unitId))
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(organisationalUnit.buildingId, organisationalUnit.floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }

}

export function optimizeDepartmentAPICall (organisationalUnit, unitId) {
  return {
    type: floorPlanActionTypes.OPTIMIZE_DEPARTMENT,
    payload:
      httpClient.put(`${projectVariationApi()}/buildings/${organisationalUnit.buildingId}/floors/${organisationalUnit.floorId}/organisational-units/${unitId}/placing`),
  }
}

export function lockDepartment (unitId, lockState) {
  const organisationalUnit = getPlacedOrganisationalUnit(store.getState(), unitId)
  let boolWrapper = { state: lockState }

  return function (dispatch) {
    dispatch(lockDepartmentAPICall(organisationalUnit, unitId, boolWrapper))
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(organisationalUnit.buildingId, organisationalUnit.floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function lockDepartmentAPICall (organisationalUnit, unitId, boolWrapper) {
  return {
    type: floorPlanActionTypes.LOCK_DEPARTMENT,
    payload: httpClient.put(`${projectVariationApi()}/buildings/${organisationalUnit.buildingId}/floors/${organisationalUnit.floorId}/organisational-units/${unitId}/lock`,
      boolWrapper),
  }
}

export function deleteApplication (unitId, applicationId) {
  const buildingId = getBuildingId()
  const floorId = getFloorId()

  return function (dispatch) {
    dispatch(deleteApplicationAPICall(buildingId, floorId, unitId, applicationId))
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }

}

function deleteApplicationAPICall (buildingId, floorId, unitId, applicationId) {
  return {
    type: floorPlanActionTypes.DELETE_APPLICATION,
    payload:
      httpClient.delete(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/${unitId}/applications/${applicationId}/assignment`),
  }
}

export function addApplication (unitId, applicationId, position) {
  const organisationalUnit = getPlacedOrganisationalUnit(store.getState(), unitId)

  return function (dispatch) {
    dispatch(addApplicationAPICall(organisationalUnit.buildingId, organisationalUnit.floorId, unitId, applicationId, position))
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(organisationalUnit.buildingId, organisationalUnit.floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }

}

function addApplicationAPICall (buildingId, floorId, unitId, applicationId, position) {
  return {
    type: floorPlanActionTypes.ADD_APPLICATION,
    payload:
      httpClient.post(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/${unitId}/applications/${applicationId}`, position),
  }
}

export function updateRowDepth (unitId, row) {
  const buildingId = getBuildingId()
  const floorId = getFloorId()

  return (dispatch) => {
    dispatch({
      type: floorPlanActionTypes.UPDATE_PLACED_ORGANISATIONAL_UNITS,
      payload: httpClient.patch(
        `${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/organisational-units/${unitId}/rows/${row.uuid}/depth`,
        { value: row.depth }),
    })
      .then(() => {
        dispatch(fetchPlacedOrganisationalUnits(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function fetchFloorIFCFile (buildingId, floorId) {
  return {
    type: floorPlanActionTypes.FETCH_FILE,
    payload: httpClient.getFile(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/ifc`),
  }
}
