//////////////////////////////////////////////////////////////////

let GeoMath_xyStep= 2;	// [x, y, x, y, ...]

export function getSteps() {
  return GeoMath_xyStep
}

export function GeoMath_getStepped(posList, i) {
	return [posList[i+0], posList[i+1]];
}

export function GeoMath_get(posList, i) {
	return GeoMath_getStepped(posList, i*GeoMath_xyStep);
}

export function GeoMath_getAddition(a, b) {
	return [a[0]+b[0], a[1]+b[1]];
}

export function GeoMath_getSubtraction(a, b) {
	return [a[0]-b[0], a[1]-b[1]];
}

export function GeoMath_getMid(a, b) {
	return [(a[0]+b[0])*0.5, (a[1]+b[1])*0.5];
}


export function GeoMath_getScaled(a, b) {
	return [a[0]*b, a[1]*b];
}

export function GeoMath_getDistanceSq(a, b) {
	let dx= a[0]-b[0], dy= a[1]-b[1];
	return (dx*dx)+(dy*dy);
}

export function GeoMath_getDistance(a, b) {
	return Math.sqrt(GeoMath_getDistanceSq(a,b));
}

export function GeoMath_getLengthSq(a) {
	return (a[0]*a[0])+(a[1]*a[1]);
}

export function GeoMath_getLength(a) {
	return Math.sqrt(GeoMath_getLengthSq(a));
}

export function GeoMath_getPerp(v) {
		return [-v[1], v[0]];
}

export function GeoMath_getUnitVec(v) {
		var length = GeoMath_getLength(v);
  if (length === 0)
			length = 1;
		return [v[0]/length, v[1]/length];
}

export function GeoMath_getWithLength(v, length) {
		return GeoMath_getScaled(GeoMath_getUnitVec(v), length);
}

//////////////////////////////////////////////////////////////////

export function GeoMath_getOffsetBisector(prePos, cPos, nextPos, offset) {
		if (!prePos)
			return GeoMath_getPerp(GeoMath_getWithLength(GeoMath_getSubtraction(nextPos, cPos), offset));
		if (!nextPos)
			return GeoMath_getPerp(GeoMath_getWithLength(GeoMath_getSubtraction(cPos, prePos), offset));

		var vPrev = GeoMath_getPerp(GeoMath_getWithLength(GeoMath_getSubtraction(cPos, prePos), offset));
		var vNext = GeoMath_getPerp(GeoMath_getWithLength(GeoMath_getSubtraction(nextPos, cPos), offset));

		var pRes = GeoMath_getMid(vPrev,vNext);
		var deltaResToPrev = GeoMath_getDistance(pRes, vNext);
		var deltaResToO = GeoMath_getLength(pRes);

		if ((deltaResToPrev > 0.0000001) && (deltaResToO > 0.00000001)) {
			var resLength = (offset * offset) / deltaResToO;
			pRes = GeoMath_getWithLength(pRes, resLength);
		}

		return pRes;
}

export function GeoMath_getScaledBisector(prePos, cPos, nextPos) {
		if (!prePos)
			return GeoMath_getPerp(GeoMath_getUnitVec(GeoMath_getSubtraction(nextPos, cPos)));
		if (!nextPos)
			return GeoMath_getPerp(GeoMath_getUnitVec(GeoMath_getSubtraction(cPos, prePos)));

		var vPrev = GeoMath_getPerp(GeoMath_getUnitVec(GeoMath_getSubtraction(cPos, prePos)));
		var vNext = GeoMath_getPerp(GeoMath_getUnitVec(GeoMath_getSubtraction(nextPos, cPos)));

		var pRes = GeoMath_getMid(vPrev,vNext);
		var deltaHSquare = GeoMath_getDistanceSq(pRes,vPrev);
		var deltaDA = GeoMath_getLength(pRes);

  if (deltaDA !== 0) {
			var deltaDB = deltaHSquare / deltaDA;
			pRes = GeoMath_getScaled(pRes, (deltaDA + deltaDB) / deltaDA);
		}

		return pRes;
}

//////////////////////////////////////////////////////////////////

export function GeoMath_getOffsetPolygon(posList, offset) {
		var nPosList= [];
		for (var i = 0; i < posList.length; i+= GeoMath_xyStep) {
			var iPrev = i - GeoMath_xyStep;
			if (iPrev < 0)
				iPrev = posList.length - GeoMath_xyStep;
			var iNext = i + GeoMath_xyStep;
			if (iNext >= posList.length)
				iNext = 0;

			var offPos = GeoMath_getOffsetBisector(GeoMath_getStepped(posList,iPrev), GeoMath_getStepped(posList,i), GeoMath_getStepped(posList,iNext), offset);
			nPosList.push(GeoMath_getAddition(offPos, GeoMath_getStepped(posList,i)));
		}

		return nPosList;
}

//////////////////////////////////////////////////////////////////
