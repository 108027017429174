import React from 'react'

const SvgEditApplicationIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M6.306 15.844l7.259-7.276 1.862 1.865-7.268 7.263H6.306v-1.852zm9.247-9.27l1.872 1.868-1.07 1.07-1.868-1.865 1.066-1.074zM5.653 19h2.776c.174 0 .34-.07.462-.191l9.918-9.906a.65.65 0 000-.921l-2.794-2.79a.653.653 0 00-.923 0l-9.9 9.922a.666.666 0 00-.192.461v2.773c0 .36.292.652.653.652z"
    />
  </svg>
)

export default SvgEditApplicationIcon
