import React, { Component } from 'react'

import Dialog, { DialogButtonGroup, DialogContent } from '../../common/components/Dialog'
import SelectButton from '../../common/components/SelectButton'
import Button from '../../common/components/Button'
import { connect } from 'react-redux'
import { createDemoProject } from '../../common/redux/projects/projects-actions'
import { addApplication } from '../actions/actions'

class SelectApplicationDialog extends Component {

  constructor (props) {
    super(props)
    this.state = {
      selectedApplication: '',
    }
  }


  render () {
    this.apps = []
    for (let i = 0; i<this.props.applications.length; i++ ) {
      this.apps.push({'label': this.props.applications[i].displayName,'value':this.props.applications[i].id})
    }

    return (
      <Dialog
        onCancel={this.close}
        title={'Select Application to place'} className={'load-demo-dialog'}>
        <DialogContent>
          <SelectButton value={this.state.selectedDemoProject}
                        options={this.apps}
                        onChange={event => this.selectApplication(event)}
                        selectOption/>
        </DialogContent>
        <DialogButtonGroup>
          <Button onClick={this.close}>Cancel</Button>
          <Button className={'button'} onClick={this.load} disabled={!this.state.selectedApplication} variant={'contained'}>Confirm</Button>
        </DialogButtonGroup>
      </Dialog>
    )
  }

  selectApplication = event => {
    this.setState({ ...this.state, selectedApplication: event.target.value })
  }

  load = () => {
    this.close()
    this.props.addApplication(this.props.unitId, this.state.selectedApplication, this.props.position)

  }

  close = () => {
    this.props.onClose()
  }

}

let mapDispatchToProps = {
  addApplication: addApplication,
}

let mapStateToProps = (state) => {
  return {
    applications: state.furnitureEnsembles.furnitureEnsembles,
  }}

export default connect(mapStateToProps, mapDispatchToProps)(SelectApplicationDialog)
