import * as React from 'react'

const DrawSlice = props =>
  <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <g
      stroke={props.color}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m17.314 12.071 5.657-5.657a2 2 0 1 1 2.828 2.829l-5.657 5.656h0l-2.828-2.828Z"/>
      <path d="M6 23.385 17.314 12.07l4.408 4.408-1.933 1.933A17.232 17.232 0 0 1 6 23.385h0Z"/>
    </g>
  </svg>
export default DrawSlice