import * as React from 'react'

const SvgAutoAxesActive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1165 28L20.7087 26.1046H23.2427L23.8544 28H25L22.6893 21H21.3107L19 28H20.1165ZM22.9709 25.1318H20.9709L21.9515 22.043H22L22.9709 25.1318Z" fill="#DA5B2B"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9164 6.83781L11.0373 16.272L15.3749 17.155L11.3044 24.4942L21.5062 14.9546L17.0759 14.0527L20.9164 6.83781Z" fill="url(#paint0_linear_12_4326)" stroke="#DA5B2B" stroke-miterlimit="16"/>
    <defs>
      <linearGradient id="paint0_linear_12_4326" x1="18.4373" y1="26.4074" x2="23.4953" y2="7.53083" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
    </defs>
  </svg>
)

export default SvgAutoAxesActive