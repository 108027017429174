import { Group } from 'three'
import { VertexGroup } from './VertexGroup'
import { EdgeGroup } from './EdgeGroup'
import { geometryTypes } from '../config/GeometryTypes'
import { getBoundingBox } from '../common/BoundingBox'

const defaultActions = {
  idle: 'idle',
  select: 'select',
}

export class Geometry extends Group {
  #parentGeometry
  nestedGeometries = []
  #callbacks = []

  constructor (parent, representationType, geometryActions = {}) {
    super()

    this.representationType = representationType

    this.vertexGroup = new VertexGroup(this)
    this.edgeGroup = new EdgeGroup(this, this.vertexGroup)
    this.vertexGroup.setEdgeGroup(this.edgeGroup)

    this.currentScale = 1
    this.active = false
    this.geometryType = geometryTypes.geometry
    this.rootGeometry = true

    this.actions = Object.assign(defaultActions, geometryActions)

    //Add Geometry to parent
    parent.add(this)
  }

  addChildGeometry (childGeometry) {
    childGeometry.setParentGeometry(this)
    if (!this.nestedGeometries.includes(childGeometry)) {
      this.nestedGeometries.push(childGeometry)
    }

    childGeometry.subscribe(() => this.onChildUpdates())
    this.subscribe(() => childGeometry.onParentUpdates())

    this.afterAddChild()
  }

  removeChildGeometry (childGeometry) {
    childGeometry.setParentGeometry(null)
    this.nestedGeometries = this.nestedGeometries.filter(geometry => geometry !== childGeometry)
  }

  reconnectNestedGeometries () {
    this.nestedGeometries.forEach(nestedGeometry => nestedGeometry.setParentGeometry(this))
  }

  setParentGeometry (parentGeometry) {
    if (this.#parentGeometry) {
      this.#parentGeometry.unsubscribe(this.onParentUpdates)
    }

    this.#parentGeometry = parentGeometry

    if (this.#parentGeometry) {
      this.#parentGeometry.subscribe(this.onParentUpdates)
    }
  }

  getParentGeometry () {
    return this.#parentGeometry
  }

  afterAddChild () {}

  onChildUpdates () {
  }

  onParentUpdates () {
  }

  updateScale (scale) {
    console.log("update scale: "+scale)
    this.vertexGroup.updateScale(scale)
    this.edgeGroup.updateScale(scale)

    this.currentScale = scale
  }

  setAction (currentAction) {
    this.currentAction = currentAction
  }

  getAction () {
    return this.currentAction
  }

  setActive (active) {
    this.active = active
    this.vertexGroup.setActive(active)
    if (this.edgeGroup) {
      this.edgeGroup.setActive(active)
    }

    this.setAction(active ? this.actions.select : this.actions.idle)
  }

  setVisible (visible) {
    this.visible = visible
  }

  isActive () {
    return this.active
  }

  getVertices () {
    return this.vertexGroup.getVertices()
  }

  getVerticesPositions () {
    return this.vertexGroup.children.map(vertex => vertex.position)
  }

  getEdges () {
    return this.edgeGroup.getEdges()
  }

  updateGeometryCallback () {
    this.#callbacks.forEach(callback => callback(this))
  }

  subscribe (callback) {
    if (!this.#callbacks.includes(callback))
      this.#callbacks.push(callback)
  }

  unsubscribe (callback) {
    this.#callbacks = this.#callbacks.filter(c => c !== callback)
  }

  isSingleDraggable() {
    return true
  }

  dispose () {
    this.vertexGroup.dispose()
    this.edgeGroup.dispose()
  }

  getBoundingBox () {
    return getBoundingBox(this.vertexGroup.getPositions())
  }

  toJSON () {
    const geometryJSON = {
      uuid: this.uuid,
      geometryType: this.geometryType,
      representationType: this.representationType,
      isValidate: false,
      errors: [],
      properties: this.properties ? JSON.parse(JSON.stringify(this.properties)) : this.properties,
    }

    const vertices = this.vertexGroup.toJSON()
    if (vertices.length) {
      geometryJSON.vertices = vertices
    }

    const edges = this.edgeGroup.toJSON()
    if (edges.length) {
      geometryJSON.edges = edges
    }

    return geometryJSON
  }

  fromJSON (json) {
    this.uuid = json.uuid
    this.geometryType = json.geometryType
    this.representationType = json.representationType
    this.properties = json.properties

    if (json.vertices) {
      this.vertexGroup.fromJSON(json.vertices)
    }
    if (json.edges) {
      this.edgeGroup.fromJSON(json.edges)
    }

  }

}
