import { floorPlanActionTypes } from '../../floorplan/constants'

const initialState = {
  tags: [],
  error: null,
}

function tagsReducer (state = initialState, action) {

  switch (action.type) {

    case floorPlanActionTypes.FETCH_TAGS_PENDING:
      return { ...state }

    case floorPlanActionTypes.FETCH_TAGS_FULFILLED:
      return {
        ...state,
        tags: action.payload.data,
      }
    case floorPlanActionTypes.CREATE_TAG_PENDING:
      return { ...state }

    case floorPlanActionTypes.CREATE_TAG_FULFILLED:
      const tags = state.tags
      const newTag = action.payload.data

      if (tags.filter((tag) => tag.displayName === newTag.displayName).length === 0)
        tags.push(newTag)

      return {
        ...state,
        tags: tags,
      }

    case floorPlanActionTypes.FETCH_TAGS_REJECTED:
      return { ...state, error: action.payload }

    default:
      return { ...state }
  }

}

export default tagsReducer
