import { floorPlanActionTypes } from '../constants'

let initialState = {
  buildings: [],
  error: null,
}

function buildingsReducer (state = initialState, action) {

  switch (action.type) {

    case floorPlanActionTypes.FETCH_BUILDINGS_PENDING:
      return { ...state }

    case floorPlanActionTypes.FETCH_BUILDINGS_FULFILLED:
      return {
        ...state,
        buildings: action.payload.data,
      }

    case floorPlanActionTypes.FETCH_BUILDINGS_REJECTED:
      return { ...state, error: action.payload }
    default:
      return { ...state }
  }

}

export function getFloorsOfSelectedBuilding (state) {
  let floors = getSelectedBuilding(state).floors

  floors.sort((floor0, floor1) => {return floor1.index - floor0.index})

  return floors
}

export function getAllFloors (state) {
  let floors = []

  state.buildings.buildings.forEach(building => floors = floors.concat(building.floors))

  return floors
}

export function getSelectedBuilding (state) {
  return state.buildings.buildings.find(building => building.id === state.appState.selectedBuildingId) || { floors: [] }
}

export function getSelectedFloor (state) {
  return state.buildings.buildings.flatMap(building => building.floors)
    .find(floor => floor.id === state.appState.selectedFloorId) || {}
}

export function isFloorOldVersion (state) {
  const floor = state.buildings.buildings.flatMap(building => building.floors)
    .find(floor => floor.id === state.appState.selectedFloorId)
  return floor ? floor.oldVersion : false
}

export default buildingsReducer



