import React from 'react'

const SvgDataIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      fill="#373B51"
      fillRule="evenodd"
      d="M11.5 6.02v6.242l4.344 4.345A6 6 0 1111.5 6.02zm1.707 6.535h4.768a5.974 5.974 0 01-1.418 3.348l-3.35-3.348zm4.777-1H12.5V6.02a6.001 6.001 0 015.484 5.534z"
    />
  </svg>
);

export default SvgDataIcon
