import * as React from 'react'

const SvgTransformFurnitureIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill={props.color} fillRule="evenodd">
      <path
        d="M6 13.5c.245 0 .45.07.492.164l.008.036v2.8h.8c.354 0 .647.263.694.605L8 17.2v.8h2.8c.11 0 .2.224.2.5 0 .245-.07.45-.164.492L10.8 19H8v.8a.701.701 0 01-.605.694L7.3 20.5H4.7a.7.7 0 01-.7-.7v-2.6a.7.7 0 01.7-.7h.8v-2.8c0-.11.224-.2.5-.2zm12 0c.245 0 .45.07.492.164l.008.036v2.8h.8c.354 0 .647.263.694.605L20 17.2v2.6a.7.7 0 01-.7.7h-2.6a.7.7 0 01-.7-.7V19h-2.8c-.11 0-.2-.224-.2-.5 0-.245.07-.45.164-.492L13.2 18H16v-.8c0-.354.263-.647.605-.694l.095-.006h.8v-2.8c0-.11.224-.2.5-.2zm-11 4H5v2h2v-2zm12 0h-2v2h2v-2zm.3-13a.7.7 0 01.7.7v2.6a.7.7 0 01-.7.7h-.8v2.8c0 .11-.224.2-.5.2-.245 0-.45-.07-.492-.164L17.5 11.3V8.5h-.8a.701.701 0 01-.694-.605L16 7.8V7h-2.8c-.11 0-.2-.224-.2-.5 0-.245.07-.45.164-.492L13.2 6H16v-.8c0-.354.263-.647.605-.694L16.7 4.5zm-12 0a.7.7 0 01.7.7V6h2.8c.11 0 .2.224.2.5 0 .245-.07.45-.164.492L10.8 7H8v.8a.701.701 0 01-.605.694L7.3 8.5h-.8v2.8c0 .11-.224.2-.5.2-.245 0-.45-.07-.492-.164L5.5 11.3V8.5h-.8a.701.701 0 01-.694-.605L4 7.8V5.2a.7.7 0 01.7-.7zm-.3 1H5v2h2v-2zm12 0h-2v2h2v-2z"/>
      <path
        fillRule="nonzero"
        d="M13.3 10.5h-2.6a.7.7 0 00-.7.7v2.6a.7.7 0 00.7.7h2.6a.7.7 0 00.7-.7v-2.6a.7.7 0 00-.7-.7zm-6 0H4.7a.7.7 0 00-.7.7v2.6a.7.7 0 00.7.7h2.6a.7.7 0 00.7-.7v-2.6a.7.7 0 00-.7-.7zm-.3 1v2H5v-2h2zm6.3-7h-2.6a.7.7 0 00-.7.7v2.6a.7.7 0 00.7.7h2.6a.7.7 0 00.7-.7V5.2a.7.7 0 00-.7-.7zm-.3 1v2h-2v-2h2zm6.3 5h-2.6a.7.7 0 00-.7.7v2.6a.7.7 0 00.7.7h2.6a.7.7 0 00.7-.7v-2.6a.7.7 0 00-.7-.7zm-.3 1v2h-2v-2h2zm-5.7 5h-2.6a.7.7 0 00-.7.7v2.6a.7.7 0 00.7.7h2.6a.7.7 0 00.7-.7v-2.6a.7.7 0 00-.7-.7zm-.3 1v2h-2v-2h2z"
      />
    </g>
  </svg>
)

export default SvgTransformFurnitureIcon