import React from 'react'

const SvgAppCreatorTablesIconInactive = props => (
  <svg className={props.className} viewBox="0 0 54 54">
    <path data-name="Rechteck 426" fill="none" d="M0 0h54v54H0z"/>
    <path
      data-name="Pfad 917"
      d="M37.485 34.185V21.977a.977.977 0 0 0-.977-.977H16.976a.977.977 0 0 0-.977.977v12.208a.488.488 0 0 0 .488.488h.977a.488.488 0 0 0 .488-.488V23.93h17.58v10.255a.488.488 0 0 0 .488.488h.979a.488.488 0 0 0 .486-.488z"
      fill="#8f939d"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgAppCreatorTablesIconInactive
