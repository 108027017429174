import {materialTypes} from '../common/MaterialTypes'

import {getMaterial} from '../common/Materials'

export const wallConfig = {
    vertex: {
        innerActiveMaterial: getMaterial(materialTypes.blue),
        innerInactiveMaterial: getMaterial(materialTypes.transparent),
        outerActiveMaterial: getMaterial(materialTypes.blue),
        outerInactiveMaterial: getMaterial(materialTypes.transparent),
        innerSize: 0.1,
        outerSize: 0.3,
        innerSegments: 32,
        outerSegments: 32,
    },
    edge: {
        innerActiveMaterial: getMaterial(materialTypes.blue),
        innerInactiveMaterial: getMaterial(materialTypes.transparent),
        outerActiveMaterial: getMaterial(materialTypes.transparent),
        outerInactiveMaterial: getMaterial(materialTypes.transparent),
        innerSize: 0.1,
        outerSize: 0.3,
    },
    properties: {
        offsetLeft:  .1,
        offsetRight: .1,
    },
}