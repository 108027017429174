import React from 'react'
import InspectorHeader from './InspectorHeader'
import { Scrollbar } from 'react-scrollbars-custom'

import './InspectorContainer.scss'

function InspectorContainer (props) {
  return (
    <div className={'inspector-container ' + props.className}>
      <InspectorHeader title={props.headerTitle} buttonText={props.buttonText} buttonIcon={props.buttonIcon} onButtonClick={props.onButtonClick}/>
      {props.scrollable ?
        <div className={'inspector-content'}>
          <Scrollbar autoHide autoHideTimeout={50}>
            {props.children}
          </Scrollbar>
        </div> :
        <div className={'inspector-content'}>{props.children}</div>
      }
    </div>
  )
}

export default InspectorContainer