export function freeMemory (root) {
  if (root && root.children && root.children.length) {

    for (let i = root.children.length - 1; i >= 0; i--) {
      const child = root.children[i]

      freeMemory(child)

      dispose(child, 'material')
      dispose(child, 'geometry')
      dispose(child, 'texture')

      root.remove(child)
    }
  }
}

function dispose (threeObject, type) {
  if (threeObject.hasOwnProperty(type) && typeof (threeObject[type].dispose) === 'function') {
    threeObject[type].dispose()
  }
}