import * as React from 'react'

const SvgAddBuilding = props =>
  (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#00293B"
          d="M15.5 3.75v2h2v14.5h-1V6.75h-9v13.5h-1V5.75h2v-2h7zM14 16.25v4l-1-.543V17.25h-2v3h-1v-4h4zm-4-3v1.5H9v-1.5h1zm2.5 0v1.5h-1l.5-.936.5-.564zm2.5 0v1.5h-1v-1.5h1zm-5-2.5v1.5H9v-1.5h1zm2.5 0v1.5h-1v-1.5h1zM10 8.25v1.5H9v-1.5h1zm2.5 0v1.5h-1v-1.5h1zm2.5 0v1.5h-1v-1.5h1zm-.5-3.5h-5v1h5v-1z"
        />
        <g transform="translate(11 11)">
          <circle cx={5} cy={5} r={5} fill="#00293B"/>
          <path d="M7.5 5h-5M5 2.5v5" stroke="#FFF" strokeLinecap="round"/>
        </g>
      </g>
    </svg>
  )

export default SvgAddBuilding
