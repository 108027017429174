import { floorPlanActionTypes } from '../constants'
import { undoActionTypes } from '../../common/redux/undo/undo-actions'

const initialState = {
  flyOut: {
    open: false,
    view: '',
  },
  blockingItemDragged: null,
  blockingItemDirtyFlag: false,
  undoInformation: {
    undoAvailable: false,
    redoAvailable: false,
  },
}

function uiStateReducer (state = initialState, action) {
  
  switch (action.type) {

    case undoActionTypes.UNDO_INFORMATION_FULFILLED:

      return {
        ...state,
        undoInformation: action.payload.data,
      }

    case floorPlanActionTypes.OPEN_FLY_OUT:
      return {
        ...state,
        flyOut: {
          open: true,
          view: action.viewName,
        },
      }

    case floorPlanActionTypes.CLOSE_FLY_OUT:
      return {
        ...state,
        flyOut: {
          open: false,
          view: '',
        },
      }

    case floorPlanActionTypes.OPEN_DIALOG:
      return {
        ...state,
        dialog: {
          open: true,
          view: action.viewName,
        },
      }

    case floorPlanActionTypes.CLOSE_DIALOG:
      return {
        ...state,
        dialog: {
          open: false,
          view: '',
        },
      }

    case floorPlanActionTypes.DRAG_BLOCKING_ITEM:
      return {
        ...state,
        blockingItemDragged: action.id,
      }

      case floorPlanActionTypes.DROP_BLOCKING_ITEM:

      return {
        ...state,
        blockingItemDirtyFlag: action.value,
      }

    default:
      return state
  }
}

export default uiStateReducer