import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchProjectData } from '../floorplan/actions/actions'

import Sidebar from '../common/components/buildingsFloorsInspector/Sidebar'
import FloorsContent from './components/FloorsContent'
import { PlanAppContainer } from '../common/components/layout/PlanAppContainer'
import { useParams } from 'react-router-dom'

function FloorsApp() {
  const dispatch = useDispatch()
  const [shortVersion, setShortVersion] = React.useState(true)
  const {projectId} = useParams()
  const dataLoaded = useSelector(state => state.dataLoaded.dataLoaded)


  useEffect(() => {
    if (dataLoaded) {
      dispatch(fetchProjectData(projectId))
    }

    document.addEventListener('keydown', (event) => {
      if (event.code === 'AltLeft') {
        setShortVersion(false)
        event.preventDefault()
      }
    })
    document.addEventListener('keyup', (event) => {
      if (event.code === 'AltLeft') {
        setShortVersion(true)
        event.preventDefault()
      }
    })
  } , [])


    return (
      <PlanAppContainer className={'floors'}>
        <Sidebar title={'Floors'} shortVersion={shortVersion}/>
        <FloorsContent shortVersion={shortVersion}/>
      </PlanAppContainer>
    )

}

export default FloorsApp