import React from 'react'

const SvgBlockingIconInactive = props => (
  <svg className={props.className} viewBox="0 0 60 54">
    <path d="M0 0h60v54H0z" data-name="Rechteck 216" fill="none"/>
    <path
      d="M19.031 33.197a3.574 3.574 0 0 1 3.574-3.574h.147l2.064-6.351a3.565 3.565 0 0 1-1.112-2.591 3.573 3.573 0 0 1 3.574-3.574 3.574 3.574 0 0 1 3.574 3.574 3.589 3.589 0 0 1-.059.649l3.932 2.753a3.555 3.555 0 0 1 2.107-.688 3.574 3.574 0 0 1 3.574 3.574 3.573 3.573 0 0 1-3.574 3.574 3.559 3.559 0 0 1-2.5-1.017l-8.16 3.634v.035a3.572 3.572 0 0 1-3.572 3.574 3.572 3.572 0 0 1-3.569-3.572zm6.443-2.129l7.838-3.489a3.6 3.6 0 0 1-.051-.607 3.567 3.567 0 0 1 .174-1.1l-3.7-2.59a3.561 3.561 0 0 1-2.458.98 3.654 3.654 0 0 1-.459-.029l-2 6.162a3.607 3.607 0 0 1 .657.673z"
      data-name="Pfad 1040"
      fill="#8f939d"
    />
  </svg>
)

export default SvgBlockingIconInactive
