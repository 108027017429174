import React from 'react'

const SvgPatternStylesIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      stroke={props.color || '#65C296'}
      transform="translate(4.5 4.5)"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <path d="M10.875 12h.675a.45.45 0 00.45-.45v-8.1a.45.45 0 00-.45-.45h-8.1a.45.45 0 00-.45.45v.675h0"/>
      <rect
        width={9}
        height={9}
        y={6}
        rx={0.3}
        transform="matrix(1 0 0 -1 0 21)"
      />
      <path d="M13.875 9h.675a.45.45 0 00.45-.45V.45a.45.45 0 00-.45-.45h-8.1A.45.45 0 006 .45v.675h0"/>
    </g>
  </svg>
)

export default SvgPatternStylesIcon
