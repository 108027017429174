import React from 'react'

const SvgCollapse = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd" opacity={0.501}>
      <rect
        width={12}
        height={16}
        x={6}
        y={4}
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        rx={1}
      />
      <rect width={2} height={14} x={15} y={5} fill={props.fillColor} rx={0.25}/>
    </g>
  </svg>
)

export default SvgCollapse
