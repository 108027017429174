import React, { Component } from 'react'
import { connect } from 'react-redux'

import { iconTypes } from '../../../common/components/icons/IconFactory'
import LabeledIconButton from '../../../common/components/LabeledIconButton'
import Text, { textTypes } from '../../../common/components/Text'

import { generateAll } from '../../../floorplan/actions/actions'
import { getFloorSummary } from '../../../floorplan/reducers/organisationalUnits'

import './TestFit.scss'
import { getSelectedFloor } from '../../../floorplan/reducers/buildings'
import i18n from 'i18next'
import NumberInput from '../../../common/components/NumberInput'
import { updateOrganisationalUnits } from '../../../common/redux/organisationunits/organisational-units-actions'

const individualWorkTypes = ['focused', 'manager']
const groupWorkTypes = ['conferencing_formal', 'meeting_formal', 'conferencing_informal', 'meeting_informal']
const restoreTypes = ['lounge']

class TestFit extends Component {

  delayTimer
  highlightClassNameTimer

  constructor (props) {
    super(props)

    this.state = {
      highlightClassName: '',
    }
  }

  getApplicationTypes () {
    if (!this.props.placedUnits.length) {
      return {}
    }

    const departments = this.props.placedUnits.filter(unit => unit.type === 'DEPARTMENT')

    const mappedDepartments = {
      individualWork: {
        displayName: 'Individual Work',
        count: 0,
        percentage: 0,
        color: '#a3a4ae',
      },
      groupWork: {
        displayName: 'Group Work',
        count: 0,
        percentage: 0,
        color: '#60bf82',
      },
      restore: {
        displayName: 'Restore',
        count: 0,
        percentage: 0,
        color: '#e7dec1',
      },
    }

    let counter = 0
    let tmp = 0

    departments.forEach(department => {
      tmp = department.furnitureEnsemblePlacements.filter(ensemble => individualWorkTypes.includes(ensemble.categoryId)).length
      counter += tmp
      mappedDepartments.individualWork.count += tmp
      tmp = department.furnitureEnsemblePlacements.filter(ensemble => groupWorkTypes.includes(ensemble.categoryId)).length
      counter += tmp
      mappedDepartments.groupWork.count += tmp
      tmp = department.furnitureEnsemblePlacements.filter(ensemble => restoreTypes.includes(ensemble.categoryId)).length
      counter += tmp
      mappedDepartments.restore.count += tmp

      mappedDepartments.individualWork.percentage = mappedDepartments.individualWork.count / counter
      mappedDepartments.groupWork.percentage = mappedDepartments.groupWork.count / counter
      mappedDepartments.restore.percentage = mappedDepartments.restore.count / counter
    })

    return mappedDepartments
  }

  render () {
    const mappedApplicationTypes = this.getApplicationTypes()

    const placedAreaForWorkplace = this.props.summary.placedAreaForWorkplace !== 0 ? this.props.summary.placedAreaForWorkplace : 9.4
    const showInput = true

    const { headCount, deskSharingRatio, workplaces, areaPerWorkplace } = this.getValues()

    return (
      <div className={'test-fit-container'}>
        <div>
          <Text>Let's try different styles</Text>
          <div className={'test-fit-styles'}>
            <div className={'test-fit-style-row'}>
              <LabeledIconButton icon={iconTypes.focussed} text={i18n.t('focussed')} id={'focussed'} selected={this.props.floor.presetIndex === 0} onClick={() => this.handleGenerateAll(0)}/>
              <LabeledIconButton icon={iconTypes.collaborate} text={i18n.t('collaborate')} id={'collaborate'} selected={this.props.floor.presetIndex === 1} onClick={() => this.handleGenerateAll(1)}/>
              <LabeledIconButton icon={iconTypes.agile} text={i18n.t('agile')} id={'agile'}  selected={this.props.floor.presetIndex === 2} onClick={() => this.handleGenerateAll(2)}/>
              <LabeledIconButton icon={iconTypes.activity} text={i18n.t('activity')} id={'activity'} selected={this.props.floor.presetIndex === 5} onClick={() => this.handleGenerateAll(5)}/>
            </div>
          </div>
          {
            showInput ?
              <div className={'test-fit-input'}>
                <div className={'test-fit-input-header'}>
                  <Text component={'label'}>Target</Text>
                </div>
                <div className={'test-fit-input-content'}>
                  <NumberInput verticalStyle label={'Headcount'} value={headCount} step={1} digits={0} onChange={(value) => this.handleSetValue(value, deskSharingRatio)}
                               idButtonUp={'increase-headcount'} idButtonDown={'decrease-headcount'}/>
                  <NumberInput verticalStyle label={'Desk Sharing Ratio'} value={deskSharingRatio} step={.1} digits={2} onChange={(value) => this.handleSetValue(headCount, value)}
                               idButtonUp={'increase-desk-sharing-ratio'} idButtonDown={'decrease-desk-sharing-ratio'}/>
                </div>
              </div>
              : null
          }
          <div className={'test-fit-separator'}/>
          <div className={'test-fit-dashboard-container'}>
            <Text component={'label'} outerClassName={'test-fit-dashboard-info'}>Info</Text>
            <div className={'test-fit-dashboard'}>
              <div className={'test-fit-dashboard-values'}>
                <div className={'test-fit-dashboard-label-value'}>
                  <Text component={'label'}>Workplaces</Text>
                  <div className={'test-fit-dashboard-value'}>
                    <Text component={textTypes.numberTitle}>{this.props.summary.placedNumberOfWorkplaces ? this.props.summary.placedNumberOfWorkplaces : 0}</Text>
                    <Text component={textTypes.paragraph}>/</Text>
                    <Text component={textTypes.paragraph} outerClassName={this.state.highlightClassName}>{workplaces}</Text>
                  </div>
                </div>
                <div className={'test-fit-dashboard-label-value'}>
                  <Text component={'label'}>m2 per Workplace</Text>
                  <div className={'test-fit-dashboard-value'}>
                    <Text component={textTypes.numberTitle}>{placedAreaForWorkplace ? placedAreaForWorkplace : 0}</Text>
                    <Text component={textTypes.paragraph}>/</Text>
                    <Text component={textTypes.paragraph} outerClassName={this.state.highlightClassName}>{areaPerWorkplace}</Text>
                  </div>
                </div>
                <div className={'test-fit-dashboard-label-value'}>
                  <Text component={'label'}>Available area</Text>
                  <Text component={textTypes.numberTitle}>{this.props.floor.area ? this.props.floor.area.toFixed(0) : 0} m²</Text>
                </div>
              </div>
              <div className={'test-fit-dashboard-dots'}>
                <div className={'test-fit-dashboard-distribution'}>
                  {
                    Object.keys(mappedApplicationTypes)
                      .map((key, index) => {
                        const d = mappedApplicationTypes[key]
                        return (<div style={{ width: (d.percentage * 100) + '%', backgroundColor: d.color }} key={index}/>
                        )
                      })
                  }
                </div>
                {
                  Object.keys(mappedApplicationTypes)
                    .map((key, index) => {
                      const d = mappedApplicationTypes[key]
                      return (
                        <div className={'test-fit-dashboard-distribution-value'} key={index}>
                          <div className={'distribution-color'} style={{ backgroundColor: d.color }}/>
                          <Text component={textTypes.paragraph} style={{ width: '3rem' }}>{d.count}</Text>
                          <Text component={textTypes.paragraph}>{d.displayName}</Text>
                        </div>
                      )
                    })
                }
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={'test-fit-separator'}/>
          <div className={'test-fit-concat'}>
            <div className={'haworth-contact-container'} onClick={() => window.open('https://www.haworth.com/eu/en/company/contact.html', '_blank')}>
              <Text className={'haworth-contact-text'} component={textTypes.link} center>Get in contact with Haworth</Text>
              <Text className={'haworth-contact-text'} component={textTypes.link} center>for detailed planning</Text>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getValues () {
    const team = this.props.units.find(unit => unit.type === 'TEAM')

    let headCount = 0
    let deskSharingRatio = 1

    if (team) {
      ({ numberOfEmployees: headCount, deskSharingRatio } = team.requirement)
    }

    headCount = this.state.headCount ? this.state.headCount : headCount
    deskSharingRatio = this.state.deskSharingRatio ? this.state.deskSharingRatio : deskSharingRatio

    let workplaces, areaPerWorkplace

    if (this.state.headCount && this.state.deskSharingRatio) {
      workplaces = Math.round(headCount / deskSharingRatio)
      areaPerWorkplace = this.props.floor.area ? (this.props.floor.area / workplaces).toFixed(2) : 10
    } else {
      workplaces = this.props.summary.goalNumberOfWorkplaces ? this.props.summary.goalNumberOfWorkplaces : 0
      areaPerWorkplace = this.props.summary.goalAreaForWorkplace ? this.props.summary.goalAreaForWorkplace : 0
    }

    return { headCount: headCount, deskSharingRatio: deskSharingRatio, workplaces: workplaces, areaPerWorkplace: areaPerWorkplace }
  }

  handleSetValue (headCount, deskSharingRatio) {
    this.setState({
      headCount: headCount,
      deskSharingRatio: deskSharingRatio,
    })

    this.delay(() => this.updateOrganisationalUnit())

    this.setState({ highlightClassName: 'highlight' })
    clearTimeout(this.highlightClassNameTimer)
    this.highlightClassNameTimer = setTimeout(() => this.setState({ highlightClassName: '' }), 500)
  }

  updateOrganisationalUnit () {
    const units = []

    const departmentOrig = this.props.units.find(unit => unit.type === 'DEPARTMENT')

    const { headCount, deskSharingRatio, workplaces, areaPerWorkplace } = this.getValues()

    const department = { requirement: {} }
    department.id = departmentOrig.id
    department.requirement.areaForWorkplace = parseFloat(areaPerWorkplace)

    units.push(department)

    const teamOrg = this.props.units.find(unit => unit.type === 'TEAM')

    const team = { requirement: {} }
    team.id = teamOrg.id
    team.requirement.numberOfEmployees = headCount
    team.requirement.deskSharingRatio = deskSharingRatio
    team.requirement.numberOfWorkplaces = workplaces
    team.organisationalUnits = []

    units.push(team)

    this.props.updateOrganisationalUnits(units)

    this.setState({
      headCount: null,
      deskSharingRatio: null,
    })
  }

  delay (action) {
    clearTimeout(this.delayTimer)
    this.delayTimer = setTimeout(() => action(), 2000)
  }

  handleGenerateAll (distributionIndex) {
    this.props.generateAll(distributionIndex)
  }
}

let mapStateToProps = state => {
  return {
    floor: getSelectedFloor(state),
    departments: state.placedOrganisationalUnits.placedDepartments,
    placedUnits: state.placedOrganisationalUnits.placedUnits,
    summary: getFloorSummary(state),
    units: state.organisationalUnits.units,
  }
}

let mapDispatchToProps = {
  generateAll: generateAll,
  updateOrganisationalUnits: updateOrganisationalUnits,
}

export default connect(mapStateToProps, mapDispatchToProps)(TestFit)