import React, { Component } from 'react'
import Icon, { iconTypes } from './icons/IconFactory'
import { NumericFormat } from 'react-number-format'
import { add, subtract } from 'lodash'
import './NumberInput.scss'
import { setNativeValue } from '../utils'
import Text from './Text'

class NumberInput extends Component {

  constructor (props, context) {
    super(props, context)
    this.inputElementRef = React.createRef()
  }

  render () {

    const { name, value, field, label, disabled, step = 0.01, digits = 2, defaultValue = 0, minimum = 0, verticalStyle, className } = this.props
    const { idButtonUp = 'id-button-up', idButtonDown='id-button-down' } = this.props

    const verticalStyleClass = verticalStyle ? ' vertical-style' : ''

    const onDisabledClick = this.props.onDisabledClick

    return (
      <div className={'number-input' + (className ? ' ' + className : '') + verticalStyleClass}>
        {label &&
        <Text component={'label'} outerClassName={'number-input-label'} multiline>
          {label}
        </Text>
        }
        <div className={'input-container' + verticalStyleClass} onClick={disabled ? () => onDisabledClick() : () => {}}>
          <NumericFormat className={verticalStyleClass}
                        getInputRef={(el) => this.inputElementRef = el}
                        decimalScale={digits}
                        min={minimum}
                        name={field ? field.name : name}
                        onChange={field ? field.onChange : (event) => this.handleOnChange(event)}
                        onBlur={field ? field.onBlur : (event) => this.handleOnBlur(event)}
                        value={field ? field.value : value}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        allowedDecimalSeparators={['.', ',']}
                        autoComplete={'off'}/>
          <div className={'number-input-button-group' + (disabled ? ' disabled' : '') + verticalStyleClass}>
            <button type={'button'}
                    disabled={disabled}
                    onClick={() => this.handlePlusClick(step, digits)}
                    id={idButtonDown}>
              <Icon name={iconTypes.triangleUp} disabled={disabled}/>
            </button>
            <button type={'button'}
                    disabled={disabled || this.isValueUnderMinimum(field, value, step, minimum)}
                    onClick={() => this.handleMinusClick(step, digits)}
                    id={idButtonUp}>
              <Icon name={iconTypes.triangleDown} disabled={disabled}/>
            </button>
          </div>

        </div>
      </div>
    )
  }

  handleOnChange (event) {
    if (this.props.onBlur)
      return

    if (this.props.field) {
      this.props.onChange(event)
    } else {
      this.props.onChange(event.target.value)
    }
  }

  handleOnBlur (event) {
    if (!this.props.onBlur)
      return

    if (this.props.field) {
      this.props.onBlur(event)
    } else {
      this.props.onBlur(event.target.value)
    }
  }

  isValueUnderMinimum (field, value, step, minimum) {
    return (field !== undefined ? field.value : value) - step < minimum
  }

  handleMinusClick (step, precision) {
    const value = Number(this.props.field ? this.props.field.value : this.props.value)
    if (this.props.field) {
      this.triggerOnChange(subtract(value, step ? step : 1)
        .toFixed(precision))
    } else {
      this.props.onChange(subtract(value, step ? step : 1)
        .toFixed(precision))
    }
  }

  handlePlusClick (step, precision) {
    const value = Number(this.props.field ? this.props.field.value : this.props.value)
    if (this.props.field) {
      this.triggerOnChange(add(value, step ? step : 1)
        .toFixed(precision))
    } else {
      this.props.onChange(add(value, step ? step : 1)
        .toFixed(precision))
    }
  }

  triggerOnChange (value) {
    const event = new Event('input', { bubbles: true })
    const input = this.inputElementRef
    setNativeValue(this.inputElementRef, value)
    input.dispatchEvent(event)
  }
}

export default NumberInput