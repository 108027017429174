import {createFaceBufferGeometry} from '../geometries/FaceFactory'
import {BufferAttribute, MathUtils, Matrix4, Vector2} from 'three'
import {isPolygonClockwise, } from './GeometryHelper'
import {representationTypes} from '../config/RepresentationTypes'

export function createFaceBufferGeometryRingOffset(vertices, offsetOutward = .2, offsetInward = .2) {

    let inwardVertices = getOffsetByRingVertices(vertices, offsetInward)
    let outwardVertices = getOffsetByRingVertices(vertices, -offsetOutward)

    if (isPolygonClockwise(vertices)) {
        const tmp = inwardVertices
        inwardVertices = outwardVertices
        outwardVertices = tmp
    }

    const geometry = createFaceBufferGeometry(outwardVertices, [inwardVertices])

    return {geometry: geometry, innerVertices: inwardVertices, outerVertices: outwardVertices}
}
export function createFaceBufferGeometryLinearOffset(vertices, offsetLeft = .2, offsetRight = .2) {
  /*console.log("createFace")
  let minDist=0.001
  let  isClosed=false
  let result = []
  let faceVertices =[]
  if(vertices.length>=2) {
    const startPos = new Vector2(vertices[0].x, vertices[0].y);
    const endPos = new Vector2(vertices[vertices.length - 1].x, vertices[vertices.length - 1].y);
    const dist = startPos.distanceTo(endPos);
    console.log(dist)
    if(dist<minDist){
      let verticesForRing=[]
      for(let i=1; i<vertices.length; i++){
        verticesForRing.push(vertices[i])
      }
      console.log("outer")
      let outerVertices=getOffsetByRingVertices(verticesForRing, offsetLeft)
      console.log("inner")
      let innerVertices=getOffsetByRingVertices(verticesForRing, -offsetRight).reverse()

      if (isPolygonClockwise(verticesForRing)) {
        const tmp = innerVertices
        innerVertices = outerVertices
        outerVertices = tmp
      }
      console.log("firstOuter")
      const firstOuterVertex=new Vector2(outerVertices[0].x,outerVertices[0].y)
      console.log("firstOuter")
      outerVertices.push(firstOuterVertex)

      console.log("lastInner")
      console.log(innerVertices)
      let lastInnerVertex=innerVertices[innerVertices.length-1]
      console.log("lastInner: "+lastInnerVertex)

      lastInnerVertex=new Vector2(lastInnerVertex.x,lastInnerVertex.y)
      console.log("lastInner")
      const iVertices=[lastInnerVertex].concat(innerVertices)
      console.log("lastInner")

      innerVertices=iVertices
      console.log("outer inner");
      console.log(outerVertices)
      console.log(innerVertices)

      faceVertices = outerVertices.concat(innerVertices)
    }else{
      faceVertices=getOffsetByLinearVertices(vertices,offsetLeft,offsetRight)
    }
  }
  */

  const faceVertices = getOffsetByLinearVertices(vertices, offsetLeft)
    .concat(getOffsetByLinearVertices(vertices, -offsetRight).reverse())
  console.log("faaaceVertices")
  console.log(faceVertices)

  const geometry = createFaceBufferGeometry(faceVertices)
    console.log(geometry)

    return {geometry: geometry, vertices: faceVertices}
}

function getOffsetByLinearVertices(vertices, offset = .5) {
    console.log("Offsetting")
    console.log(vertices)

    let minDist=0.001
    let  isClosed=false
    let result = []
    if(vertices.length>=2) {
      const startPos = new Vector2(vertices[0].x, vertices[0].y);
      const endPos = new Vector2(vertices[vertices.length - 1].x, vertices[vertices.length - 1].y);
      const dist = startPos.distanceTo(endPos);
      console.log(dist)
      if (dist > minDist) {
        console.log("LINEAR")
        const startVertex = new Vector2(vertices[0].x, vertices[0].y)
        const endVertex = new Vector2(vertices[1].x, vertices[1].y)

        console.log("start_end1");
        console.log(startVertex)
        console.log(endVertex)

        let point = startVertex.clone()
          .sub(endVertex)
          .normalize()
          .multiplyScalar(offset)
          .add(startVertex)
          .rotateAround(startVertex, MathUtils.degToRad(-90))

        console.log(point)
        result.push(point)

        const offsetBuffer = new BufferAttribute(new Float32Array([offset, 0, 0]), 3)

        for (let i = 1; i < vertices.length - 1; i++) {
          console.log("pos between")
          result.push(getOffsetPositionBetweenVertices(vertices[i - 1], vertices[i], vertices[i + 1], offsetBuffer))
        }

        const startVertex2 = new Vector2(vertices[vertices.length - 1].x, vertices[vertices.length - 1].y)
        const endVertex2 = new Vector2(vertices[vertices.length - 2].x, vertices[vertices.length - 2].y)
        console.log("start_end2");
        console.log(startVertex2)
        console.log(endVertex2)
        let point2 = startVertex2.clone()
          .sub(endVertex2)
          .normalize()
          .multiplyScalar(offset)
          .add(startVertex2)
          .rotateAround(startVertex2, MathUtils.degToRad(90))

        result.push(point2)
      } else {
        /*
        console.log("RING")

        let verticesForRing = []
        for(let i=1; i<vertices.length;i++){
          verticesForRing.push(vertices[i])
        }
        console.log(verticesForRing)
        const offsetBuffer = new BufferAttribute(new Float32Array([offset, 0, 0]), 3)
        //if(isPolygonClockwise(verticesForRing))
        let outwardVertices=getOffsetByRingVertices(verticesForRing,offset);
        //let result=[]
        result.push(outwardVertices[outwardVertices.length-1])
        for(let j=0;j<outwardVertices.length; j++){
          result.push(outwardVertices[j])
        }
        console.log("result")
        console.log(result)
    */

        /*
        let verticesplus=vertices[0];

        let inwardVertices = getOffsetByRingVertices(vertices, offsetInward)
        let outwardVertices = getOffsetByRingVertices(vertices, -offsetOutward)

        if (isPolygonClockwise(vertices)) {
          const tmp = inwardVertices
          inwardVertices = outwardVertices
          outwardVertices = tmp
        }
        */
      }
    }
    console.log("finished")
    console.log(result)
    return result
}

function getOffsetByRingVertices(vertices, offset) {
    let result = []

    const offsetBuffer = new BufferAttribute(new Float32Array([offset, 0, 0]), 3)

    for (let i = 0; i < vertices.length; i++) {
        result.push(getOffsetPositionBetweenVertices(vertices[i - 1 < 0 ? vertices.length - 1 : i - 1], vertices[i], vertices[i + 1 === vertices.length ? 0 : i + 1], offsetBuffer))
    }

    return result
}

function getOffsetPositionBetweenVertices(previousVertex, currentVertex, nextVertex, offset) {
    let v1 = new Vector2().subVectors(previousVertex, currentVertex);
    let v2 = new Vector2().subVectors(nextVertex, currentVertex);
    let angle = v2.angle() - v1.angle()
    let hA = angle * 0.5
    let tA = v2.angle() + Math.PI * 0.5

    let shift = Math.tan(hA - Math.PI * 0.5)
    let shiftMatrix = new Matrix4().set(
        1, 0, 0, 0,
        -shift, 1, 0, 0,
        0, 0, 1, 0,
        0, 0, 0, 1
    )

    let rotationMatrix = new Matrix4().set(
        Math.cos(tA), -Math.sin(tA), 0, 0,
        Math.sin(tA), Math.cos(tA), 0, 0,
        0, 0, 1, 0,
        0, 0, 0, 1
    )

    let translationMatrix = new Matrix4().set(
        1, 0, 0, currentVertex.x,
        0, 1, 0, currentVertex.y,
        0, 0, 1, 0,
        0, 0, 0, 1,
    )

    let cloneOffset = offset.clone();

    cloneOffset.applyMatrix4(shiftMatrix)
    cloneOffset.applyMatrix4(rotationMatrix)
    cloneOffset.applyMatrix4(translationMatrix)

    return new Vector2(cloneOffset.getX(0), cloneOffset.getY(0))
}

export function getIncreaseOffsetByRepresentationType(currentValue, representationType) {
    let offsetting = getOffsettingByRepresentationType(representationType)
    let newValue = currentValue

    for (let i = 0; i < offsetting.length - 1; i++) {
        if (offsetting[i] > currentValue) {
            newValue = offsetting[i]
            i = offsetting.length
        }
    }

    return newValue
}

export function getDecreaseOffsetByRepresentationType(currentValue, representationType) {
    let offsetting = getOffsettingByRepresentationType(representationType)
    let newValue = currentValue

    for (let i = offsetting.length - 1; i >= 0; i--) {
        if (offsetting[i] < currentValue) {
            newValue = offsetting[i]
            i = -1
        }
    }

    return newValue
}

function getOffsettingByRepresentationType(representationType) {
    let offsetting = []

    switch (representationType) {
        case representationTypes.wall:
            offsetting = offsettingWall
            break
        case representationTypes.outline:
            offsetting = offsettingOutline
            break
        default:
            break
    }

    return offsetting
}

const offsettingWall = [.1, .2, .4, .6, .8, 1]
const offsettingOutline = [.1, .2, .4, .6, .8, 1]