import { floorPlanActionTypes } from '../constants'
import { undoActionTypes } from '../../common/redux/undo/undo-actions'

let initialState = {
  markers: [],
}

function markersReducer (state = initialState, action) {

  switch (action.type) {

    case floorPlanActionTypes.CREATE_MARKER_REJECTED: //fallthrough
    case floorPlanActionTypes.DELETE_MARKER_REJECTED: //fallthrough
    case floorPlanActionTypes.UPDATE_MARKER_REJECTED: //fallthrough
    case floorPlanActionTypes.FETCH_MARKERS_REJECTED:
      return { ...state, error: action.error }

    case floorPlanActionTypes.FETCH_MARKERS_FULFILLED:
    case undoActionTypes.FETCH_ORGANISATIONAL_UNITS_AFTER_UNDO:
      return { ...state, markers: action.payload.data }

    default:
      return { ...state }

  }

}

export function getMarker (state, markerId) {
  return state.markers.markers.find(marker => marker.id === markerId) || {}
}

export default markersReducer