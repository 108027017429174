import React from 'react'

import FloorplanPanel, { viewModes } from './components/plan/FloorplanPanel'

import './FloorplanContent.scss'

export function FloorplanContent ({ viewMode = viewModes.team, onboarding, disableInteractions = false, disableLegend = true }) {
  return (
    <div className={'floorplan-content' + (onboarding ? ' onboarding' : '')}>
      <FloorplanPanel viewMode={viewMode} disableInteractions={disableInteractions} disableLegend={disableLegend}/>
    </div>
  )
}


