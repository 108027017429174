import { FULFILLED, PENDING, REJECTED } from '../../globalConstants'
import { fetchOrganisationalUnits } from '../organisationunits/organisational-units-actions'
import { fetchApplicationSettings } from '../settings/settings-actions'
import { floorPlanActionTypes } from '../../../floorplan/constants'
import { httpClient } from '../../httpClient'
import { projectApi } from '../../utils'
import { fetchBuildings, fetchFloorSummary, fetchLayoutStyles, fetchMarkers, fetchPatternStyles, fetchPlacedOrganisationalUnits } from '../../../floorplan/actions/actions'
import { fetchFloorPlanAnalytics } from '../../../floorplanner/actions/actions'
import { fetchFurnitureEnsemblePlan, fetchFurnitureEnsembles } from '../../../applicationbuilder/actions/backendActions'
import { fetchPattern, fetchPatternList } from '../../../patternbuilder/actions/actions'
import { fetchVariationsAPI } from '../variations/variations-actions'

const FETCH_ORGANISATIONAL_UNITS_AFTER_UNDO = 'FETCH_ORGANISATIONAL_UNITS_AFTER_UNDO'
const UNDO_DATA_UPDATED = 'UNDO_DATA_UPDATED'
const UNDO_PROJECT = 'UNDO_PROJECT'
const REDO_PROJECT = 'REDO_PROJECT'
const UNDO_INFORMATION = 'UNDO_INFORMATION'
const STORE_APPLICATION = 'STORE_APPLICATION'
const UNDO_APPLICATION = 'UNDO_APPLICATION'
const REDO_APPLICATION = 'REDO_APPLICATION'
const UNDO_APPLICATION_AVAILABLE = 'UNDO_APPLICATION_AVAILABLE'
const STORE_PATTERN = 'STORE_PATTERN'
const UNDO_PATTERN = 'UNDO_PATTERN'
const REDO_PATTERN = 'REDO_PATTERN'
const UNDO_PATTERN_AVAILABLE = 'UNDO_PATTERN_AVAILABLE'
const DISCARD_UNDO = 'DISCARD_UNDO'

export const undoActionTypes = {
  FETCH_ORGANISATIONAL_UNITS_AFTER_UNDO: FETCH_ORGANISATIONAL_UNITS_AFTER_UNDO,
  FETCH_ORGANISATIONAL_UNITS_AFTER_UNDO_PENDING: FETCH_ORGANISATIONAL_UNITS_AFTER_UNDO + PENDING,
  FETCH_ORGANISATIONAL_UNITS_AFTER_UNDO_FULFILLED: FETCH_ORGANISATIONAL_UNITS_AFTER_UNDO + FULFILLED,
  FETCH_ORGANISATIONAL_UNITS_AFTER_UNDO_REJECTED: FETCH_ORGANISATIONAL_UNITS_AFTER_UNDO + REJECTED,

  UNDO_DATA_UPDATED: UNDO_DATA_UPDATED,
  UNDO_DATA_UPDATED_PENDING: UNDO_DATA_UPDATED + PENDING,
  UNDO_DATA_UPDATED_FULFILLED: UNDO_DATA_UPDATED + FULFILLED,
  UNDO_DATA_UPDATED_REJECTED: UNDO_DATA_UPDATED + REJECTED,

  UNDO_PROJECT: UNDO_PROJECT,
  UNDO_PROJECT_PENDING: UNDO_PROJECT + PENDING,
  UNDO_PROJECT_FULFILLED: UNDO_PROJECT + FULFILLED,
  UNDO_PROJECT_REJECTED: UNDO_PROJECT + REJECTED,

  REDO_PROJECT: REDO_PROJECT,
  REDO_PROJECT_PENDING: REDO_PROJECT + PENDING,
  REDO_PROJECT_FULFILLED: REDO_PROJECT + FULFILLED,
  REDO_PROJECT_REJECTED: REDO_PROJECT + REJECTED,

  UNDO_INFORMATION: UNDO_INFORMATION,
  UNDO_INFORMATION_PENDING: UNDO_INFORMATION + PENDING,
  UNDO_INFORMATION_FULFILLED: UNDO_INFORMATION + FULFILLED,
  UNDO_INFORMATION_REJECTED: UNDO_INFORMATION + REJECTED,

  STORE_APPLICATION: STORE_APPLICATION,
  UNDO_APPLICATION: UNDO_APPLICATION,
  REDO_APPLICATION: REDO_APPLICATION,
  UNDO_APPLICATION_AVAILABLE: UNDO_APPLICATION_AVAILABLE,

  STORE_PATTERN: STORE_PATTERN,
  UNDO_PATTERN: UNDO_PATTERN,
  REDO_PATTERN: REDO_PATTERN,
  UNDO_PATTERN_AVAILABLE: UNDO_PATTERN_AVAILABLE,

  DISCARD_UNDO: DISCARD_UNDO,

}

export function undoProject (projectId, variationId, buildingId, floorId, furnitureEnsembleId, patternId) {
  return undoProjectManager(projectId, variationId, buildingId, floorId, 'undo', furnitureEnsembleId, patternId)
}

export function redoProject (projectId, variationId, buildingId, floorId, furnitureEnsembleId, patternId) {
  return undoProjectManager(projectId, variationId, buildingId, floorId, 'redo', furnitureEnsembleId, patternId)
}

export function undoProjectManager (projectId, variationId, buildingId, floorId, undoType, furnitureEnsembleId, patternId) {
  return (dispatch) => {

    dispatch(undoProjectCall(projectId, undoType))
      .then(({ value }) => {
        if (value.data.undoType === 'ORGANISATIONAL_UNIT') {
          dispatch(fetchPlacedOrganisationalUnits(buildingId, floorId))
          dispatch(getUndoInformation(projectId))
          dispatch(fetchFloorSummary(floorId))
        } else if (value.data.undoType === 'ORGANISATIONAL_UNIT_ASSIGNMENT') {
          dispatch(fetchOrganisationalUnits())
            .then(({ v2 }) => {
              dispatch(updateStore())
              dispatch(getUndoInformation(projectId))
            })
        } else if (value.data.undoType === 'ORGANISATIONAL_UNIT_ASSIGNMENT_AND_PLAN') {
          dispatch(fetchPlacedOrganisationalUnits(buildingId, floorId))
          dispatch(fetchOrganisationalUnits())
            .then(({ v2 }) => {
              dispatch(updateStore())
              dispatch(getUndoInformation(projectId))
              dispatch(fetchFloorSummary(floorId))
            })
        } else if (value.data.undoType === 'SETTINGS_ORGANISATIONAL_UNIT') {
          dispatch(fetchOrganisationalUnits())
            .then(({ v2 }) => {
              dispatch(getUndoInformation(projectId))
            })
        } else if (value.data.undoType === 'SETTINGS_STYLES') {
          dispatch(fetchPatternStyles())
            .then(({ v2 }) => {
              dispatch(getUndoInformation(projectId))
            })
          dispatch(fetchLayoutStyles())
            .then(({ v2 }) => {
              dispatch(getUndoInformation(projectId))
            })
        } else if (value.data.undoType === 'SETTINGS') {
          dispatch(fetchApplicationSettings())
            .then(({ v2 }) => {
              dispatch(getUndoInformation(projectId))
            })
        } else if (value.data.undoType === 'MARKER') {
          dispatch(fetchMarkers(buildingId, floorId))
            .then(({ v2 }) => {
              dispatch(getUndoInformation(projectId))
            })
        } else if (value.data.undoType === 'BUILDING') {
          dispatch(fetchBuildings())
            .then(({ v2 }) => {
              dispatch(getUndoInformation(projectId))
            })
        } else if (value.data.undoType === 'BUILDING_ANALYTICS') {
          dispatch(fetchFloorPlanAnalytics(buildingId, floorId))
            .then(({ v2 }) => {
              dispatch(getUndoInformation(projectId))
            })
        } else if (value.data.undoType === 'APPLICATION') {
          dispatch(fetchFurnitureEnsembles())
            .then(({ v2 }) => {
              dispatch(getUndoInformation(projectId))
            })
        } else if (value.data.undoType === 'APPLICATION_STORAGE') {
          dispatch(fetchFurnitureEnsemblePlan(projectId, furnitureEnsembleId))
            .then(({ v2 }) => {
              dispatch(getUndoInformation(projectId))
            })

        } else if (value.data.undoType === 'PATTERN') {
          dispatch(fetchPatternList(projectId))
            .then(({ v2 }) => {
              dispatch(getUndoInformation(projectId))
            })
        } else if (value.data.undoType === 'PATTERN_STORAGE') {
          dispatch(fetchPatternList(projectId))
            .then(({ v2 }) => {
              dispatch(fetchPattern(projectId, patternId))
            })
        } else if (value.data.undoType === 'VARIATION') {
          dispatch(fetchVariationsAPI(projectId))
            .then(({ v2 }) => {
              dispatch(getUndoInformation(projectId))
            })
        } else {
          /* reload All  */
          // variations ??

          window.location.reload()
          //dispatch(getUndoInformation(projectId))
        }
      })
  }
}

function updateStore () {
  return {
    type: undoActionTypes.UNDO_DATA_UPDATED,

  }
}

export function getUndoInfo () {
  return {
    type: floorPlanActionTypes.FETCH_FURNITURE_LIBRARY,
    payload: httpClient.get(projectApi() + '/undoinfo'),
  }
}

export function undoProjectCall (projectId, undoType) {
  if (undoType === 'undo') {
    return {
      type: undoActionTypes.UNDO_PROJECT,
      payload: httpClient.post(`/api/projects/${projectId}/undo`),
    }
  } else {
    return {
      type: undoActionTypes.REDO_PROJECT,
      payload: httpClient.post(`/api/projects/${projectId}/redo`),
    }
  }
}

export function getUndoInformation (projectId) {
  return {
    type: undoActionTypes.UNDO_INFORMATION,
    payload: httpClient.get(encodeURI(`/api/projects/${projectId}/undoinfo`)),
  }
}
