import React, { Component } from 'react'
import Text from './Text'

class RadioOption extends Component {
  render () {

    let name = this.props.name
    let id = this.props.id

    return (
      <React.Fragment>
        <input type="radio"
               id={id}
               name={name}
               disabled={this.props.disabled}
               checked={this.props.checked}
               onChange={this.props.onChange}
               value={this.props.value}/>
        <label htmlFor={id} className={this.props.className}>
          <Text>{this.props.children}</Text>
        </label>
      </React.Fragment>
    )

  }
}

export default RadioOption