import { CUSTOM_STYLE_ID, FULFILLED, PENDING, REJECTED } from '../common/globalConstants'

// export imported global constants
export { FULFILLED, PENDING, REJECTED, CUSTOM_STYLE_ID }

// Floorplan constants
export const priorityValues = {
  LOW: 'LOW',
  MIDDLE: 'MIDDLE',
  HIGH: 'HIGH',
}

const FETCH_PROJECT = 'FETCH_PROJECT'
const FETCH_FILE = 'FETCH_FILE'
const FETCH_PDF = 'FETCH_PDF'
const FETCH_FURNITURE_ENSEMBLES_POLYGONS = 'FETCH_FURNITURE_ENSEMBLES_POLYGONS'
const FETCH_FURNITURE_LIBRARY = 'FETCH_FURNITURE_LIBRARY'
const UPDATE_ORGANISATIONAL_UNITS = 'UPDATE_FLOORPLAN_ORGANISATIONAL_UNITS'
const UPDATE_APPLICATION_UNITS = 'UPDATE_APPLICATION_UNITS'
const FETCH_PLACED_ORGANISATIONAL_UNITS = 'FETCH_PLACED_ORGANISATIONAL_UNITS'
const FETCH_DEPARTMENT_ASSIGNMENTS = 'FETCH_ALL_PLACED_ORGANISATIONAL_UNITS'
const CREATE_RANDOM_ORGANISATIONAL_UNITS = 'CREATE_RANDOM_ORGANISATIONAL_UNITS'
const DELETE_ALL_ORGANISATIONAL_UNITS = 'DELETE_ALL_ORGANISATIONAL_UNITS'
const FETCH_ROOMS = 'FETCH_ROOMS'
const UPDATE_PLACED_ORGANISATIONAL_UNIT = 'UPDATE_PLACED_ORGANISATIONAL_UNIT'
const RESET_PLACED_ORGANISATIONAL_UNIT = 'RESET_PLACED_ORGANISATIONAL_UNIT'
const UPDATE_FLOOR_PLACING = 'UPDATE_FLOOR_PLACING'
const FETCH_BASE_64_IMAGE = 'FETCH_BASE_64_IMAGE'
const FETCH_MARKERS = 'FETCH_MARKERS'
const CREATE_MARKER = 'CREATE_MARKER'
const DELETE_MARKER = 'DELETE_MARKER'
const UPDATE_MARKER = 'UPDATE_MARKER'
const LOCK_APPLICATION = 'LOCK_APPLICATION'
const DELETE_APPLICATION = 'DELETE_APPLICATION'
const DELETE_APPLICATIONS = 'DELETE_APPLICATIONS'
const LOCK_DEPARTMENT = 'LOCK_DEPARTMENT'
const OPTIMIZE_DEPARTMENT = 'OPTIMIZE_DEPARTMENT'

const FETCH_BUILDINGS = 'FETCH_BUILDINGS'
const FETCH_FLOOR_PLAN = 'FETCH_FLOOR_PLAN'
const FETCH_CURRENT_DRAWING_TAB = 'FETCH_CURRENT_DRAWING_TAB'
const FETCH_DRAWING = 'FETCH_DRAWING'
const FETCH_FLOOR_PLAN_ANALYTICS = 'FETCH_FLOOR_PLAN_ANALYTICS'
const FETCH_FLOOR_PLAN_ANALYTICS_LIGHT = 'FETCH_FLOOR_PLAN_ANALYTICS_LIGHT'
const FETCH_FLOOR_SUMMARY = 'FETCH_FLOOR_SUMMARY'
const FETCH_FLOOR_LIGHTMAP = 'FETCH_FLOOR_LIGHTMAP'
const FETCH_LAYOUT_STYLES = 'FETCH_FLOORPLAN_LAYOUT_STYLES'
const FETCH_PATTERN_STYLES = 'FETCH_FLOORPLAN_PATTERN_STYLES'
const FETCH_WORKPLACE_TYPES = 'FETCH_FLOORPLAN_WORKPLACE_TYPES'
const FETCH_PATTERN_TYPES = 'FETCH_FLOORPLAN_PATTERN_TYPES'
const FETCH_TAGS = 'FETCH_FLOORPLAN_TAGS'
const CREATE_TAG = 'CREATE_TAG'
const DELETE_TAG = 'DELETE_TAG'
const FETCH_PLACED_ORGANISATIONAL_UNIT_FURNITURE_ENSEMBLE_SUMMARY = 'FETCH_PLACED_ORGANISATIONAL_UNIT_FURNITURE_ENSEMBLE_SUMMARY'
const FETCH_CONNECTIONS = 'FETCH_CONNECTIONS'
const CREATE_CONNECTION = 'CREATE_CONNECTION'
const UPDATE_CONNECTION = 'UPDATE_CONNECTION'
const DELETE_CONNECTION = 'DELETE_CONNECTION'
const FETCH_CONNECTABLES = 'FETCH_CONNECTABLES'

export const floorPlanActionTypes = {

  // App state changes

  SET_SELECTED_APPLICATION_ID: 'SET_SELECTED_APPLICATION_ID',
  SET_SELECT_ROW: 'SET_SELECTED_ROW',
  SET_SELECT_CONTEXT_MENU: 'SET_SELECT_CONTEXT_MENU',

  CLEAR_PROJECT: 'CLEAR_PROJECT',

  // UI changes
  DECREASE_LOADER: 'DECREASE_LOADER',
  INCREASE_LOADER: 'INCREASE_LOADER',
  OPEN_FLY_OUT: 'OPEN_FLY_OUT',
  CLOSE_FLY_OUT: 'CLOSE_FLY_OUT',
  OPEN_DIALOG: 'OPEN_DIALOG',
  CLOSE_DIALOG: 'CLOSE_DIALOG',
  DRAG_BLOCKING_ITEM: 'DRAG_BLOCKING_ITEM',
  DROP_BLOCKING_ITEM: 'DROP_BLOCKING_ITEM',
  SET_PLAN_DIRTY: 'SET_PLAN_DIRTY',


  // Backend communication

  FETCH_PROJECT: FETCH_PROJECT,
  FETCH_PROJECT_PENDING: FETCH_PROJECT + PENDING,
  FETCH_PROJECT_FULFILLED: FETCH_PROJECT + FULFILLED,
  FETCH_PROJECT_REJECTED: FETCH_PROJECT + REJECTED,

  FETCH_FILE: FETCH_FILE,
  FETCH_FILE_PENDING: FETCH_FILE + PENDING,
  FETCH_FILE_FULFILLED: FETCH_FILE + FULFILLED,
  FETCH_FILE_REJECTED: FETCH_FILE + REJECTED,

  FETCH_PDF: FETCH_PDF,
  FETCH_PDF_PENDING: FETCH_PDF + PENDING,
  FETCH_PDF_FULFILLED: FETCH_PDF + FULFILLED,
  FETCH_PDF_REJECTED: FETCH_PDF + REJECTED,

  FETCH_FURNITURE_LIBRARY: FETCH_FURNITURE_LIBRARY,
  FETCH_FURNITURE_LIBRARY_PENDING: FETCH_FURNITURE_LIBRARY + PENDING,
  FETCH_FURNITURE_LIBRARY_FULFILLED: FETCH_FURNITURE_LIBRARY + FULFILLED,
  FETCH_FURNITURE_LIBRARY_REJECTED: FETCH_FURNITURE_LIBRARY + REJECTED,

  UPDATE_ORGANISATIONAL_UNITS: UPDATE_ORGANISATIONAL_UNITS,
  UPDATE_ORGANISATIONAL_UNITS_PENDING: UPDATE_ORGANISATIONAL_UNITS + PENDING,
  UPDATE_ORGANISATIONAL_UNITS_FULFILLED: UPDATE_ORGANISATIONAL_UNITS + FULFILLED,
  UPDATE_ORGANISATIONAL_UNITS_REJECTED: UPDATE_ORGANISATIONAL_UNITS + REJECTED,

  UPDATE_APPLICATION_UNITS: UPDATE_APPLICATION_UNITS,
  UPDATE_APPLICATION_UNITS_PENDING: UPDATE_APPLICATION_UNITS + PENDING,
  UPDATE_APPLICATION_UNITS_FULFILLED: UPDATE_APPLICATION_UNITS + FULFILLED,
  UPDATE_APPLICATION_UNITS_REJECTED: UPDATE_APPLICATION_UNITS + REJECTED,

  FETCH_PLACED_ORGANISATIONAL_UNITS: FETCH_PLACED_ORGANISATIONAL_UNITS,
  FETCH_PLACED_ORGANISATIONAL_UNITS_PENDING: FETCH_PLACED_ORGANISATIONAL_UNITS + PENDING,
  FETCH_PLACED_ORGANISATIONAL_UNITS_FULFILLED: FETCH_PLACED_ORGANISATIONAL_UNITS + FULFILLED,
  FETCH_PLACED_ORGANISATIONAL_UNITS_REJECTED: FETCH_PLACED_ORGANISATIONAL_UNITS + REJECTED,

  FETCH_DEPARTMENT_ASSIGNMENTS: FETCH_DEPARTMENT_ASSIGNMENTS,
  FETCH_DEPARTMENT_ASSIGNMENTS_PENDING: FETCH_DEPARTMENT_ASSIGNMENTS + PENDING,
  FETCH_DEPARTMENT_ASSIGNMENTS_FULFILLED: FETCH_DEPARTMENT_ASSIGNMENTS + FULFILLED,
  FETCH_DEPARTMENT_ASSIGNMENTS_REJECTED: FETCH_DEPARTMENT_ASSIGNMENTS + REJECTED,

  CREATE_RANDOM_ORGANISATIONAL_UNITS: CREATE_RANDOM_ORGANISATIONAL_UNITS,
  CREATE_RANDOM_ORGANISATIONAL_UNITS_PENDING: CREATE_RANDOM_ORGANISATIONAL_UNITS + PENDING,
  CREATE_RANDOM_ORGANISATIONAL_UNITS_FULFILLED: CREATE_RANDOM_ORGANISATIONAL_UNITS + FULFILLED,
  CREATE_RANDOM_ORGANISATIONAL_UNITS_REJECTED: CREATE_RANDOM_ORGANISATIONAL_UNITS + REJECTED,

  DELETE_ALL_ORGANISATIONAL_UNITS: DELETE_ALL_ORGANISATIONAL_UNITS,
  DELETE_ALL_ORGANISATIONAL_UNITS_PENDING: DELETE_ALL_ORGANISATIONAL_UNITS + PENDING,
  DELETE_ALL_ORGANISATIONAL_UNITS_FULFILLED: DELETE_ALL_ORGANISATIONAL_UNITS + FULFILLED,
  DELETE_ALL_ORGANISATIONAL_UNITS_REJECTED: DELETE_ALL_ORGANISATIONAL_UNITS + REJECTED,

  FETCH_ROOMS: FETCH_ROOMS,
  FETCH_ROOMS_PENDING: FETCH_ROOMS + PENDING,
  FETCH_ROOMS_FULFILLED: FETCH_ROOMS + FULFILLED,
  FETCH_ROOMS_REJECTED: FETCH_ROOMS + REJECTED,

  UPDATE_PLACED_ORGANISATIONAL_UNITS: UPDATE_PLACED_ORGANISATIONAL_UNIT,
  UPDATE_PLACED_ORGANISATIONAL_UNITS_PENDING: UPDATE_PLACED_ORGANISATIONAL_UNIT + PENDING,
  UPDATE_PLACED_ORGANISATIONAL_UNITS_FULFILLED: UPDATE_PLACED_ORGANISATIONAL_UNIT + FULFILLED,
  UPDATE_PLACED_ORGANISATIONAL_UNITS_REJECTED: UPDATE_PLACED_ORGANISATIONAL_UNIT + REJECTED,

  RESET_PLACED_ORGANISATIONAL_UNITS: RESET_PLACED_ORGANISATIONAL_UNIT,
  RESET_PLACED_ORGANISATIONAL_UNITS_PENDING: RESET_PLACED_ORGANISATIONAL_UNIT + PENDING,
  RESET_PLACED_ORGANISATIONAL_UNITS_FULFILLED: RESET_PLACED_ORGANISATIONAL_UNIT + FULFILLED,
  RESET_PLACED_ORGANISATIONAL_UNITS_REJECTED: RESET_PLACED_ORGANISATIONAL_UNIT + REJECTED,

  FETCH_BUILDINGS: FETCH_BUILDINGS,
  FETCH_BUILDINGS_PENDING: FETCH_BUILDINGS + PENDING,
  FETCH_BUILDINGS_FULFILLED: FETCH_BUILDINGS + FULFILLED,
  FETCH_BUILDINGS_REJECTED: FETCH_BUILDINGS + REJECTED,

  FETCH_FLOOR_PLAN: FETCH_FLOOR_PLAN,
  FETCH_FLOOR_PLAN_PENDING: FETCH_FLOOR_PLAN + PENDING,
  FETCH_FLOOR_PLAN_FULFILLED: FETCH_FLOOR_PLAN + FULFILLED,
  FETCH_FLOOR_PLAN_REJECTED: FETCH_FLOOR_PLAN + REJECTED,

  FETCH_CURRENT_DRAWING_TAB: FETCH_CURRENT_DRAWING_TAB,
  FETCH_CURRENT_DRAWING_TAB_PENDING: FETCH_CURRENT_DRAWING_TAB + PENDING,
  FETCH_CURRENT_DRAWING_TAB_FULFILLED: FETCH_CURRENT_DRAWING_TAB + FULFILLED,
  FETCH_CURRENT_DRAWING_TAB_REJECTED: FETCH_CURRENT_DRAWING_TAB + REJECTED,

  FETCH_DRAWING: FETCH_DRAWING,
  FETCH_DRAWING_PENDING: FETCH_DRAWING + PENDING,
  FETCH_DRAWING_FULFILLED: FETCH_DRAWING + FULFILLED,
  FETCH_DRAWING_REJECTED: FETCH_DRAWING + REJECTED,

  FETCH_BASE_64_IMAGE: FETCH_BASE_64_IMAGE,
  FETCH_BASE_64_IMAGE_PENDING: FETCH_BASE_64_IMAGE + PENDING,
  FETCH_BASE_64_IMAGE_FULFILLED: FETCH_BASE_64_IMAGE + FULFILLED,
  FETCH_BASE_64_IMAGE_REJECTED: FETCH_BASE_64_IMAGE + REJECTED,

  FETCH_FLOOR_PLAN_ANALYTICS: FETCH_FLOOR_PLAN_ANALYTICS,
  FETCH_FLOOR_PLAN_PENDING_ANALYTICS: FETCH_FLOOR_PLAN_ANALYTICS + PENDING,
  FETCH_FLOOR_PLAN_FULFILLED_ANALYTICS: FETCH_FLOOR_PLAN_ANALYTICS + FULFILLED,
  FETCH_FLOOR_PLAN_REJECTED_ANALYTICS: FETCH_FLOOR_PLAN_ANALYTICS + REJECTED,

  FETCH_FLOOR_PLAN_ANALYTICS_LIGHT: FETCH_FLOOR_PLAN_ANALYTICS_LIGHT,
  FETCH_FLOOR_PLAN_PENDING_ANALYTICS_LIGHT: FETCH_FLOOR_PLAN_ANALYTICS_LIGHT + PENDING,
  FETCH_FLOOR_PLAN_FULFILLED_ANALYTICS_LIGHT: FETCH_FLOOR_PLAN_ANALYTICS_LIGHT + FULFILLED,
  FETCH_FLOOR_PLAN_REJECTED_ANALYTICS_LIGHT: FETCH_FLOOR_PLAN_ANALYTICS_LIGHT + REJECTED,

  FETCH_FLOOR_LIGHTMAP: FETCH_FLOOR_LIGHTMAP,
  FETCH_FLOOR_LIGHTMAP_PENDING: FETCH_FLOOR_LIGHTMAP + PENDING,
  FETCH_FLOOR_LIGHTMAP_FULFILLED: FETCH_FLOOR_LIGHTMAP + FULFILLED,
  FETCH_FLOOR_LIGHTMAP_REJECTED: FETCH_FLOOR_LIGHTMAP + REJECTED,

  FETCH_FLOOR_SUMMARY: FETCH_FLOOR_SUMMARY,
  FETCH_FLOOR_SUMMARY_PENDING: FETCH_FLOOR_SUMMARY + PENDING,
  FETCH_FLOOR_SUMMARY_FULFILLED: FETCH_FLOOR_SUMMARY + FULFILLED,
  FETCH_FLOOR_SUMMARY_REJECTED: FETCH_FLOOR_SUMMARY + REJECTED,

  UPDATE_FLOOR_PLACING: UPDATE_FLOOR_PLACING,
  UPDATE_FLOOR_PLACING_PENDING: UPDATE_FLOOR_PLACING + PENDING,
  UPDATE_FLOOR_PLACING_FULFILLED: UPDATE_FLOOR_PLACING + FULFILLED,
  UPDATE_FLOOR_PLACING_REJECTED: UPDATE_FLOOR_PLACING + REJECTED,

  FETCH_LAYOUT_STYLES: FETCH_LAYOUT_STYLES,
  FETCH_LAYOUT_STYLES_PENDING: FETCH_LAYOUT_STYLES + PENDING,
  FETCH_LAYOUT_STYLES_FULFILLED: FETCH_LAYOUT_STYLES + FULFILLED,
  FETCH_LAYOUT_STYLES_REJECTED: FETCH_LAYOUT_STYLES + REJECTED,

  FETCH_PATTERN_STYLES: FETCH_PATTERN_STYLES,
  FETCH_PATTERN_STYLES_PENDING: FETCH_PATTERN_STYLES + PENDING,
  FETCH_PATTERN_STYLES_FULFILLED: FETCH_PATTERN_STYLES + FULFILLED,
  FETCH_PATTERN_STYLES_REJECTED: FETCH_PATTERN_STYLES + REJECTED,

  FETCH_WORKPLACE_TYPES: FETCH_WORKPLACE_TYPES,
  FETCH_WORKPLACE_TYPES_PENDING: FETCH_WORKPLACE_TYPES + PENDING,
  FETCH_WORKPLACE_TYPES_FULFILLED: FETCH_WORKPLACE_TYPES + FULFILLED,
  FETCH_WORKPLACE_TYPES_REJECTED: FETCH_WORKPLACE_TYPES + REJECTED,

  FETCH_PATTERN_TYPES: FETCH_PATTERN_TYPES,
  FETCH_PATTERN_TYPES_PENDING: FETCH_PATTERN_TYPES + PENDING,
  FETCH_PATTERN_TYPES_FULFILLED: FETCH_PATTERN_TYPES + FULFILLED,
  FETCH_PATTERN_TYPES_REJECTED: FETCH_PATTERN_TYPES + REJECTED,

  FETCH_TAGS: FETCH_TAGS,
  FETCH_TAGS_PENDING: FETCH_TAGS + PENDING,
  FETCH_TAGS_FULFILLED: FETCH_TAGS + FULFILLED,
  FETCH_TAGS_REJECTED: FETCH_TAGS + REJECTED,

  CREATE_TAG: CREATE_TAG,
  CREATE_TAG_PENDING: CREATE_TAG + PENDING,
  CREATE_TAG_FULFILLED: CREATE_TAG + FULFILLED,
  CREATE_TAG_REJECTED: CREATE_TAG + REJECTED,

  DELETE_TAG: DELETE_TAG,
  DELETE_TAG_PENDING: DELETE_TAG + PENDING,
  DELETE_TAG_FULFILLED: DELETE_TAG + FULFILLED,
  DELETE_TAG_REJECTED: DELETE_TAG + REJECTED,

  FETCH_PLACED_ORGANISATIONAL_UNIT_FURNITURE_ENSEMBLE_SUMMARY: FETCH_PLACED_ORGANISATIONAL_UNIT_FURNITURE_ENSEMBLE_SUMMARY,
  FETCH_PLACED_ORGANISATIONAL_UNIT_FURNITURE_ENSEMBLE_SUMMARY_PENDING: FETCH_PLACED_ORGANISATIONAL_UNIT_FURNITURE_ENSEMBLE_SUMMARY + PENDING,
  FETCH_PLACED_ORGANISATIONAL_UNIT_FURNITURE_ENSEMBLE_SUMMARY_FULFILLED: FETCH_PLACED_ORGANISATIONAL_UNIT_FURNITURE_ENSEMBLE_SUMMARY + FULFILLED,
  FETCH_PLACED_ORGANISATIONAL_UNIT_FURNITURE_ENSEMBLE_SUMMARY_REJECTED: FETCH_PLACED_ORGANISATIONAL_UNIT_FURNITURE_ENSEMBLE_SUMMARY + REJECTED,

  FETCH_FURNITURE_ENSEMBLES_POLYGONS: FETCH_FURNITURE_ENSEMBLES_POLYGONS,
  FETCH_FURNITURE_ENSEMBLES_POLYGONS_PENDING: FETCH_FURNITURE_ENSEMBLES_POLYGONS + PENDING,
  FETCH_FURNITURE_ENSEMBLES_POLYGONS_FULFILLED: FETCH_FURNITURE_ENSEMBLES_POLYGONS + FULFILLED,
  FETCH_FURNITURE_ENSEMBLES_POLYGONS_REJECTED: FETCH_FURNITURE_ENSEMBLES_POLYGONS + REJECTED,

  FETCH_MARKERS: FETCH_MARKERS,
  FETCH_MARKERS_PENDING: FETCH_MARKERS + PENDING,
  FETCH_MARKERS_FULFILLED: FETCH_MARKERS + FULFILLED,
  FETCH_MARKERS_REJECTED: FETCH_MARKERS + REJECTED,

  CREATE_MARKER: CREATE_MARKER,
  CREATE_MARKER_PENDING: CREATE_MARKER + PENDING,
  CREATE_MARKER_FULFILLED: CREATE_MARKER + FULFILLED,
  CREATE_MARKER_REJECTED: CREATE_MARKER + REJECTED,

  DELETE_MARKER: DELETE_MARKER,
  DELETE_MARKER_PENDING: DELETE_MARKER + PENDING,
  DELETE_MARKER_FULFILLED: DELETE_MARKER + FULFILLED,
  DELETE_MARKER_REJECTED: DELETE_MARKER + REJECTED,

  UPDATE_MARKER: UPDATE_MARKER,
  UPDATE_MARKER_PENDING: UPDATE_MARKER + PENDING,
  UPDATE_MARKER_FULFILLED: UPDATE_MARKER + FULFILLED,
  UPDATE_MARKER_REJECTED: UPDATE_MARKER + REJECTED,

  LOCK_APPLICATION: LOCK_APPLICATION,
  LOCK_APPLICATION_PENDING: LOCK_APPLICATION + PENDING,
  LOCK_APPLICATION_FULFILLED: LOCK_APPLICATION + FULFILLED,
  LOCK_APPLICATION_REJECTED: LOCK_APPLICATION + REJECTED,

  DELETE_APPLICATION: DELETE_APPLICATION,
  DELETE_APPLICATION_PENDING: DELETE_APPLICATION + PENDING,
  DELETE_APPLICATION_FULFILLED: DELETE_APPLICATION + FULFILLED,
  DELETE_APPLICATION_REJECTED: DELETE_APPLICATION + REJECTED,

  DELETE_APPLICATIONS: DELETE_APPLICATIONS,
  DELETE_APPLICATIONS_PENDING: DELETE_APPLICATIONS + PENDING,
  DELETE_APPLICATIONS_FULFILLED: DELETE_APPLICATIONS + FULFILLED,
  DELETE_APPLICATIONS_REJECTED: DELETE_APPLICATIONS + REJECTED,

  LOCK_DEPARTMENT: LOCK_DEPARTMENT,
  LOCK_DEPARTMENT_PENDING: LOCK_DEPARTMENT + PENDING,
  LOCK_DEPARTMENT_FULFILLED: LOCK_DEPARTMENT + FULFILLED,
  LOCK_DEPARTMENT_REJECTED: LOCK_DEPARTMENT + REJECTED,

  OPTIMIZE_DEPARTMENT: OPTIMIZE_DEPARTMENT,
  OPTIMIZE_DEPARTMENT_PENDING: OPTIMIZE_DEPARTMENT + PENDING,
  OPTIMIZE_DEPARTMENT_FULFILLED: OPTIMIZE_DEPARTMENT + FULFILLED,
  OPTIMIZE_DEPARTMENT_REJECTED: OPTIMIZE_DEPARTMENT + REJECTED,

  FETCH_CONNECTIONS: FETCH_CONNECTIONS,
  FETCH_CONNECTIONS_PENDING: FETCH_CONNECTIONS + PENDING,
  FETCH_CONNECTIONS_FULFILLED: FETCH_CONNECTIONS + FULFILLED,
  FETCH_CONNECTIONS_REJECTED: FETCH_CONNECTIONS + REJECTED,

  CREATE_CONNECTION: CREATE_CONNECTION,
  CREATE_CONNECTION_PENDING: CREATE_CONNECTION + PENDING,
  CREATE_CONNECTION_FULFILLED: CREATE_CONNECTION + FULFILLED,
  CREATE_CONNECTION_REJECTED: CREATE_CONNECTION + REJECTED,

  UPDATE_CONNECTION: UPDATE_CONNECTION,
  UPDATE_CONNECTION_PENDING: UPDATE_CONNECTION + PENDING,
  UPDATE_CONNECTION_FULFILLED: UPDATE_CONNECTION + FULFILLED,
  UPDATE_CONNECTION_REJECTED: UPDATE_CONNECTION + REJECTED,

  DELETE_CONNECTION: DELETE_CONNECTION,
  DELETE_CONNECTION_PENDING: DELETE_CONNECTION + PENDING,
  DELETE_CONNECTION_FULFILLED: DELETE_CONNECTION + FULFILLED,
  DELETE_CONNECTION_REJECTED: DELETE_CONNECTION + REJECTED,

  FETCH_CONNECTABLES: FETCH_CONNECTABLES,
  FETCH_CONNECTABLES_PENDING: FETCH_CONNECTABLES + PENDING,
  FETCH_CONNECTABLES_FULFILLED: FETCH_CONNECTABLES + FULFILLED,
  FETCH_CONNECTABLES_REJECTED: FETCH_CONNECTABLES + REJECTED,
}

export const dialogNames = {
  SUMMARY_FILE_DOWNLOAD: 'SummaryFileDownload',
  XSLX_FURNITURE_FILE_DOWNLOAD: 'XSLX_FURNITURE_FILE_DOWNLOAD',
  XSLX_DEPARTMENT_FILE_DOWNLOAD: 'XSLX_DEPARTMENT_FILE_DOWNLOAD',

}

