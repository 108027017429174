import React, { Component } from 'react'

import Text, { textTypes } from '../../common/components/Text'

import './DrawingHelp.scss'
import { withTranslation } from 'react-i18next'

class DrawingHelp extends Component {

  render () {
    //console.log("Drawing Help")

    const {t} = this.props

    const drawingHelp= this.props.drawingHelp
    //console.log(drawingHelp)

    let {textTitle, shortText, image } = drawingHelp

    image = image ? image : 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='
    textTitle = textTitle ? textTitle : t('title_of_section')
    shortText = shortText ? shortText : t('click_to_add_rectangle')
    //const image=null

    const toolStyleClass = drawingHelp.tool ? drawingHelp.tool.toLowerCase():""
    //console.log("Tsc: "+ toolStyleClass+"   :")
    //console.log("image: "+image+"  textTitle: "+textTitle)

    return (
      <div className={'drawing-help ' + toolStyleClass + (this.props.onboarding ? ' onboarding' : '')}>
        <div className={'drawing-help-content'}>
          <img className={'image'} src={image} alt={'info...'}/>
          <div className={'drawing-help-content-info'}>
            <Text component={textTypes.paragraphBold}>{textTitle}</Text>
            <Text component={textTypes.paragraph} multiline>
              {shortText}
            </Text>
          </div>
        </div>
      </div>
    )
  }

  addStepToInfoIndex (step = 0) {
    if (!this.props.toolInfos)
      return

    const furtherIndex = this.state.infoIndex + step

    if (furtherIndex <= this.props.toolInfos.length && furtherIndex < this.props.toolInfos.length) {
      this.setState({ infoIndex: furtherIndex })
    }
  }
}

export default withTranslation() (DrawingHelp)