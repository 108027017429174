import { getMaterial } from '../common/Materials'
import { materialTypes } from '../common/MaterialTypes'
import { Mesh } from 'three'
import { getBoundingBox } from '../common/BoundingBox'
import { createFaceBufferGeometryRingOffset } from '../helper/Offsetting'
import { createFaceBufferGeometry } from './FaceFactory'

export class SelectionBoundingBox extends Mesh {

  #selectedGeometries = []

  constructor (scene) {
    super()

    scene.add(this)
    this.border = new Mesh()
    this.border.material = getMaterial(materialTypes.blackWhitePattern)
    this.add(this.border)
    this.material = getMaterial(materialTypes.transparent)
    this.visible = true
  }

  setSelectedGeometries(geometries) {
    this.updateBoundingBox(geometries)
    /*
    if (this.#selectedGeometries.length !== geometries.length) {
      this.updateBoundingBox(geometries)
    } else {
      const geometriesCount = geometries.length
      const uuids = this.#selectedGeometries.map(geometry => geometry.uuid)

      if (geometriesCount !== geometries.filter(geometry => uuids.includes(geometry.uuid)).length) {
        this.updateBoundingBox(geometries)
      }
    }
     */
  }

  updateBoundingBox(geometries) {
    this.#selectedGeometries = geometries

    const boundingBox = this.#selectedGeometries.length ? getBoundingBox(this.#selectedGeometries.flatMap(geometry => geometry.getBoundingBox())) : []

    this.updateArea(boundingBox)
  }

  updateArea(boundingBox) {
    if (this.border.geometry) {
      this.border.geometry.dispose()
      this.geometry.dispose()
    }

    if (boundingBox.length === 4) {
      const { geometry, innerVertices } = createFaceBufferGeometryRingOffset(boundingBox, -.1, .2)
      this.border.geometry = geometry
      this.geometry = createFaceBufferGeometry(innerVertices)
      this.position.set(0, 0, 0)
      this.visible = true
    } else {
      this.visible = false
    }
  }

  getDraggedGeometries() {
    return this.#selectedGeometries.find(geometry => geometry.actions["drag"])
  }

  moveDelta(movement) {
    this.position.setX(this.position.x + movement.x)
    this.position.setY(this.position.y + movement.y)
  }

}