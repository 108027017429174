import * as React from 'react'
const SvgWindowsActive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 6H24V25H8V6Z" stroke="#DA5B2B"/>
    <path d="M6 27H26V25H6V27Z" fill="url(#paint0_linear_12_4319)" stroke="#DA5B2B"/>
    <path d="M16 6V25" stroke="#DA5B2B" stroke-linejoin="round"/>
    <path d="M24.5 15.5H7.5" stroke="#DA5B2B" stroke-linejoin="round"/>
    <defs>
      <linearGradient id="paint0_linear_12_4319" x1="25.9997" y1="24.9998" x2="25.9997" y2="26.9998" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
    </defs>
  </svg>
)

export default SvgWindowsActive