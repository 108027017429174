import React from 'react'

const SvgDotsIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      fill="#8F939E"
      fillRule="evenodd"
      d="M12 16a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm0-5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm0-5a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
    />
  </svg>
)

export default SvgDotsIcon
