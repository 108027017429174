import React from 'react'

const SvgOptimizeIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M16.017 3.275l-9.445 8.716a.45.45 0 00.214.771l4.212.868-3.754 6.625a.3.3 0 00.462.37l9.761-8.816a.45.45 0 00-.21-.775l-4.309-.887 3.536-6.508a.3.3 0 00-.467-.364z"
    />
  </svg>
)

export default SvgOptimizeIcon
