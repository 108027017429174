import React from 'react'

const SvgAssignItem = props => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
  >
    <g
      stroke="#00293B"
      strokeWidth={1.043}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M10.54 14.608H6.26a1.043 1.043 0 01-1.043-1.043V8.347c0-.576.467-1.043 1.043-1.043h10.435c.577 0 1.044.467 1.044 1.043v3.087"/>
      <path d="M11.999 15.13V12a1.043 1.043 0 112.087 0v1.043"/>
      <path d="M14.086 13.042v-1.043a1.043 1.043 0 112.087 0v.782M16.174 12.521a1.043 1.043 0 112.086 0v.521"/>
      <path d="M18.26 13.044a1.042 1.042 0 112.087 0v2.609"/>
      <path d="M16.174 19.826a4.173 4.173 0 004.173-4.174m-4.173 4.174h-.705a5.222 5.222 0 01-3.99-1.856l-.574-.681a2.327 2.327 0 01.976-3.682l.114-.042h0"/>
    </g>
  </svg>
)

export default SvgAssignItem