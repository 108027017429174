import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SvgIcon from './svgIcons/SvgIconFactory'

import './IconButton.scss'

class SvgIconButton extends Component {

  constructor (props) {
    super(props)
    this.state = {
      hover: false,
    }
  }

  render () {
    //console.log("render SVG button: "+this.props.icon)

    const styleClass = 'icon-button' +
      (this.props.className ? ' ' + this.props.className : '') +
      (this.props.selected ? ' selected' : '') +
      (this.props.border ? '' : ' no-border') +
      (this.props.disabled ? ' disabled' : '')

    if (this.props.activeOnHover) {
      //console.log("active on hover")
      return (
        <button title={this.props.tooltip ? this.props.tooltip : undefined} disabled={this.props.disabled} style={this.props.style} className={styleClass} onClick={this.props.onClick}
                onMouseEnter={() => this.toggleHover()}
                onMouseLeave={() => this.toggleHover()}>
          <SvgIcon disabled={this.props.disabled} inverted={this.props.inverted} name={this.props.icon} active={this.state.hover} highlight={this.props.highlight}/>
        </button>
      )
    } else {
      //console.log("not active on hover: "+this.props.selected+"  "+this.props.disabled)
      const onMouseEnter = this.props.onMouseEnter ? this.props.onMouseEnter : () => {}
      const onMouseLeave = this.props.onMouseLeave ? this.props.onMouseLeave : () => {}

      return (
        <button title={this.props.tooltip ? this.props.tooltip : undefined}
                disabled={this.props.disabled}
                style={this.props.style}
                className={styleClass}
                onClick={this.props.onClick}
                onDoubleClick={this.props.onDoubleClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}>
          <SvgIcon disabled={this.props.disabled} inverted={this.props.inverted} name={this.props.icon} active={this.props.selected} highlight={this.props.highlight}/>
        </button>
      )
    }

  }

  toggleHover () {
    this.setState({ hover: !this.state.hover })
  }

}

SvgIconButton.protoType = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  active: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
}

export default SvgIconButton
