import React from 'react'

import { IconWrapper } from './IconWrapper'
import Text, { textTypes } from './Text'

import './LabeledIconButton.scss'

export default function LabeledIconButton ({ icon, text, selected = false, onClick = () => {}, id='labeled-icon-button'}) {

  return (
    <div className={'labeled-icon-button' + (selected ? ' selected' : '')} onClick={() => onClick()} id={id}>
      <IconWrapper name={icon} active={selected}/>
      <Text component={textTypes.paragraph}>{text}</Text>
    </div>
  )

}