import { floorPlanActionTypes } from '../constants'

const initialState = {
  floorPlan: null,
  floorSummary: null,
  lightmap: null,
  rooms: [],
}

function floorPlanReducer (state = initialState, action) {

  switch (action.type) {

    case floorPlanActionTypes.FETCH_FLOOR_PLAN_PENDING:
      return { ...state }

    case floorPlanActionTypes.FETCH_FLOOR_PLAN_FULFILLED:
      return {
        ...state,
        floorPlan: action.payload.data.floorPlan,
      }

    case floorPlanActionTypes.FETCH_ROOMS_FULFILLED:
      return {
        ...state,
        rooms: action.payload.data,
      }

    case floorPlanActionTypes.FETCH_FLOOR_LIGHTMAP_FULFILLED:
      return {
        ...state,
        lightmap: action.payload.data,
      }

    case floorPlanActionTypes.FETCH_FLOOR_SUMMARY_FULFILLED:
      return {
        ...state,
        floorSummary: action.payload.data,
      }

    case floorPlanActionTypes.FETCH_FLOOR_SUMMARY_REJECTED: //fall through
    case floorPlanActionTypes.FETCH_FLOOR_PLAN_REJECTED:
      return { ...state, error: action.payload }

    case floorPlanActionTypes.SET_SELECTED_BUILDING_ID_WITHOUT_FLOORS:
    case floorPlanActionTypes.CLEAR_PROJECT:
      return initialState

    default:
      return state
  }
}

export default floorPlanReducer