import { Group, Vector3, Vector2 } from 'three'
import {Vertex} from '../geometries/VertexFactory'
import {Edge} from '../geometries/EdgeFactory'
import {
  getLocalPosLists,
  getGlobalPosition,
  getDistanceToLine,
  getUnitVector,
  getSubtraction,
  getWithLength,
  getAddition,
  getPerp,
  getOrtoProjectionOnLine,
  getOrtoProjectionOnSegment,
  getPositionAtY,
  getCrossPoint

} from './GeometryHelper'
import { representationTypes } from '../config/RepresentationTypes'

let magicGuideLine = null

const orientationTypes = {
  vertical: 'vertical',
  horizontal: 'horizontal',
  verHor: 'verHor',
  none: 'none',
}

export class MagicGuideLine extends Group {

  constructor(scene) {
    super()
    magicGuideLine = this

    this.position.z=0.1 //-0.001
    this.representationType = 'magicGuideLine'
    this.direction =new Vector2(1.0,0.0)


    this.xLength=10000.0
    this.yLength=10000.0

    let perpDirection= getPerp(this.direction)
    let fullDirection = getWithLength(this.direction,this.xLength)
    let fullPerpDirection = getWithLength(perpDirection,this.yLength)

    //console.log(fullDirection)
    //console.log(fullPerpDirection)

    const startVertexVertical = new Vertex(1, this.representationType, {x:fullPerpDirection.x,y:fullPerpDirection.y, z:0.1})
    const endVertexVertical = new Vertex(1, this.representationType, {x:-fullPerpDirection.x,y:-fullPerpDirection.y, z:0.1})
    //console.log(startVertexVertical)
    //console.log(endVertexVertical)

    this.vertical = new Edge(startVertexVertical, endVertexVertical, 1, this.representationType)
    this.vertical.name = 'verticalMagicGuideLine'
    this.vertical.setActive(false)
    this.add(this.vertical)

    const startVertexHorizontal = new Vertex(1, this.representationType, {x:fullDirection.x,y:fullDirection.y, z:0.1})
    const endVertexHorizontal = new Vertex(1, this.representationType, {x:-fullDirection.x,y:-fullDirection.y, z:0.1})
    //console.log(startVertexHorizontal)
    //console.log(endVertexHorizontal)

    this.horizontal = new Edge(startVertexHorizontal, endVertexHorizontal, 1, this.representationType)
    this.horizontal.name = 'horizontalMagicGuideLine'
    this.horizontal.setActive(false)
    this.add(this.horizontal)

    scene.add(this)

    this.name = 'magicGuideLines'

    //console.log("DIRECTION:")
    //console.log(this.direction)
    //this.setDirection(0.3,1.2)
    /*
      console.log("horizontal")
      console.log(this.horizontal)
      console.log("vertical")
      console.log(this.vertical)

     */
  }
  /*
      setDirectionAndUpdate(dirX,dirY){
        setDirection(dirX,dirY)
      }
  */
  setDirection(dirX, dirY){
    this.updateDirectionByVector(getWithLength(new Vector2(dirX,dirY),1.0))
  }

  resetLines(){
    this.direction=new Vector2(1.0,0.0)
  }


  updateDirectionBy2Position(posA,posB){
    //console.log("updateDirectionBy2Position")
    let dx=posA.x-posB.x
    let dy=posA.y-posB.y
    let dir=new Vector2(dx,dy)

    this.updateDirectionByVector(dir)
  }

  updateDirectionByVector(direction){
    //console.log("update direction")

    let dx=direction.x
    let dy=direction.y
    //console.log(dx+":"+dy)
    let length=Math.sqrt((dx*dx)+(dy*dy))
    //console.log("length: "+length)
    dx=dx/length
    dy=dy/length
    //console.log(dx+":"+dy)

    if(Math.abs(dx) > Math.abs(dy)){
      //console.log("keep")
      this.direction.x=dx
      this.direction.y=dy
    }else{
      //console.log("turn")
      //turn 90 degree
      this.direction.x=-dy
      this.direction.y=dx
    }
    //turn to right
    if(this.direction.x<0){
      this.direction.x=-this.direction.x
      this.direction.y=-this.direction.y
    }
    //this.direction=direction
    //console.log("update Dir:")
    //console.log(this.direction)
  }

  updateVertical(position) {
    /*
      console.log("updateV:")
      console.log(this.vertical.getVertices())
      console.log(this.vertical.getAngle())*/

    let perpDirection=getPerp(this.direction)

    let maxPosition=getAddition(position, getWithLength(perpDirection,-this.yLength))
    let minPosition=getAddition(position, getWithLength(perpDirection,this.yLength))
    this.vertical.update({x:maxPosition.x, y: maxPosition.y, z:-0.001}, {x: minPosition.x, y:minPosition.y, z:-0.001})
    this.vertical.setActive(true)
  }

  updateHorizontal(position) {
    /*
      console.log("updateH:")
      console.log(this.horizontal.getVertices())
      console.log(this.horizontal.getAngle())
  */
    let maxPosition=getAddition(position, getWithLength(this.direction,-this.xLength))
    let minPosition=getAddition(position, getWithLength(this.direction,this.xLength))
    this.horizontal.update({x:maxPosition.x, y: maxPosition.y, z:-0.001}, {x: minPosition.x, y:minPosition.y, z:-0.001})
    this.horizontal.setActive(true)
  }

  setOrientation(orientationType, hPosition, vPosition) {
    //console.log("ORIENTIONTYPE")
    //console.log(orientationType)
    switch (orientationType) {
      case orientationTypes.vertical:
        //console.log("v")
        this.updateVertical(vPosition)
        this.horizontal.setActive(false)
        break
      case orientationTypes.horizontal:
        //console.log("h")
        this.vertical.setActive(false)
        this.updateHorizontal(hPosition)
        break
      case orientationTypes.verHor:
        //console.log("vh")
        this.updateVertical(vPosition)
        this.updateHorizontal(hPosition)
        break
      default:
        //console.log("none")
        this.vertical.setActive(false)
        this.horizontal.setActive(false)
        break
    }
  }

  updateScale(scale) {
    this.vertical.updateScale(scale)
    this.horizontal.updateScale(scale)
  }
}

export function getSnappingPosition(event, position, otherPositions, otherEdgesPositions=null,isFirstVertex=false, autoSnap = false, snapDistance = 1.0) {
  /*
  console.log("getSnappingPosition")
  console.log(otherPositions)
  console.log(otherEdgesPositions)
  //console.log(geometry)
  console.log("thats it")
  */
  if (!event) {
    return position
  }
  if(otherEdgesPositions===null){
    otherEdgesPositions=[]
  }

  //console.log("event")
  //console.log(event)

  if (autoSnap && !(event.ctrlKey || event.metaKey)) {
    console.log("autoSnap and not")
    return position
  } else if (autoSnap || !(event.ctrlKey || event.metaKey)) {

    console.log("autoSnap or not")
    return getNearestVerticesValuesAsPosition(position, otherPositions, snapDistance, otherEdgesPositions,isFirstVertex)
  } else {
    console.log("no autosnap")
    return position
  }
}

export function getClosestDirection(dirX,dirY){
  //console.log(dirX+" ** "+dirY)
  //console.log(magicGuideLine.direction)
  if(Math.abs(dirX)>Math.abs(dirY)){
    //console.log("1,0")
    return magicGuideLine.direction
  }else{
    //console.log("0,1")
    return getPerp(magicGuideLine.direction)
  }
}
function getNearestVerticesValuesAsPosition(mousePosition, otherPositions, snapDistance, otherEdgesPositions=null,isFirstVertex=false) {
  console.log("getNearestVerticesValuesAsPosition")
  //console.log("GET NEAREST OF "+otherPositions.length)
  //console.log("repType: "+representationTypes.outline)
  //console.log("isFirstVertex: "+ isFirstVertex)

  let nearestDistanceToVertical = snapDistance
  let nearestVectorToVerticalAxis = null
  let nearestGuidelinePositionVertical =null
  let nearestDistanceToHorizontal = snapDistance
  let nearestVectorToHorizontalAxis = null
  let nearestGuidelinePositionHorizontal =null
  let orientation = null
  let mgs=magicGuideLine
  //let verhor=false
  //console.log("otherPositions: "+otherPositions.length)
  //console.log(otherPositions)

  otherPositions
    .forEach(otherPosition => {

      let hDirection=mgs.direction
      let vDirection=getPerp(hDirection)

      //console.log("distanceToH")
      let projectionOnH=getOrtoProjectionOnLine(mousePosition,otherPosition,getAddition(otherPosition,hDirection))
      let vectorToH=getSubtraction(projectionOnH,mousePosition)
      //console.log(vectorToH)

      //console.log("distanceToV:")
      let projectionOnV=getOrtoProjectionOnLine(mousePosition,otherPosition,getAddition(otherPosition,vDirection))
      let vectorToV=getSubtraction(projectionOnV,mousePosition)
      //console.log(vectorToV)

      //console.log("HOR: "+vectorToH.length()+" VER: "+vectorToV.length())

      if (vectorToV.length() < nearestDistanceToVertical) {
        nearestGuidelinePositionVertical = otherPosition
        nearestDistanceToVertical = vectorToV.length()
        nearestVectorToVerticalAxis = vectorToV
      }

      if (vectorToH.length() < nearestDistanceToHorizontal) {
        nearestGuidelinePositionHorizontal = otherPosition
        nearestDistanceToHorizontal = vectorToH.length()
        nearestVectorToHorizontalAxis = vectorToH
      }

      //console.log("nhd:"+nearestHorizontalDistance);
    })


  console.log("nearestDistancToH: "+nearestDistanceToHorizontal)
  console.log(nearestVectorToHorizontalAxis)
  console.log("nearestDistancToV: "+nearestDistanceToVertical)
  console.log(nearestVectorToVerticalAxis)


  //Test
  /*
  if(nearestDistanceToHorizontal){
    console.log("xxx")
    return mousePosition
  }
   */


  let hasHorizontal=false

  let nearestDistance=Number.MAX_VALUE

  //console.log("UPDATEDIRECTIONLINES")
  let cPosition=mousePosition
  let hPosition=mousePosition
  let vPosition=mousePosition


  if (nearestVectorToHorizontalAxis && nearestDistanceToHorizontal < snapDistance ){

    hPosition=getAddition(mousePosition,nearestVectorToHorizontalAxis)
    mousePosition=hPosition
    orientation=orientationTypes.horizontal
    hasHorizontal=true
    nearestDistance=nearestDistanceToHorizontal
    console.log("HORIZONTAL:" +nearestDistanceToHorizontal)
    //console.log(nearestGuidelinePositionHorizontal)
  }

  if (nearestVectorToVerticalAxis && nearestDistanceToVertical < snapDistance ){

    vPosition=getAddition(cPosition,nearestVectorToVerticalAxis)
    mousePosition=getAddition(mousePosition,nearestVectorToVerticalAxis)
    if(nearestDistanceToVertical<nearestDistance){
      orientation=orientationTypes.vertical
    }
    console.log("Vertical:" +nearestDistanceToVertical)
    if(hasHorizontal===true){
      console.log("both")
      orientation=orientationTypes.verHor
      //verhor=true
    }
  }
  console.log("nearly finished")



  //console.log("otherEdges")
  //console.log("orientation")
  //console.log(orientation)
  //orientation=orientationType.horizontal
  /*
    if (magicGuideLine) {


      if(nearestGuidelinePositionHorizontal===null)
        nearestGuidelinePositionHorizontal=nearestGuidelinePositionVertical
      if(nearestGuidelinePositionVertical===null)
        nearestGuidelinePositionVertical=nearestGuidelinePositionHorizontal

      magicGuideLine.setOrientation(orientation, nearestGuidelinePositionHorizontal, nearestGuidelinePositionVertical)
    }
  */
  if(orientation===orientationTypes.horizontal){
    //console.log("horizontal")
    let dir=mgs.direction
    //console.log("dir")
    //console.log(dir)
    let closestCut = getClosestCutWithGeometries(hPosition,dir,snapDistance, otherEdgesPositions)
    //console.log("closestCut: "+closestCut)
    if(closestCut!=null){
      //console.log(hPosition)
      //console.log(closestCut)
      let cDistance=hPosition.distanceTo(closestCut)
      console.log(cDistance+" ... "+nearestDistanceToVertical)
      //console.log(nearestDistanceToVertical)
      if(cDistance<nearestDistanceToVertical){
        //console.log("mousePosition: ")
        mousePosition=closestCut
        //orientation=orientationTypes.verHor
        nearestGuidelinePositionVertical=closestCut
      }
    }
  }else if(orientation===orientationTypes.vertical){
    //console.log("vertical")
    let dir=getPerp(mgs.direction)
    let closestCut = getClosestCutWithGeometries(vPosition,dir,snapDistance,otherEdgesPositions)
    if(closestCut!=null){
      let cDistance=vPosition.distanceTo(closestCut)
      if(cDistance<nearestDistanceToHorizontal){
        mousePosition=closestCut
        //orientation=orientationTypes.verHor
        nearestGuidelinePositionHorizontal=closestCut
      }
    }
  }else if(orientation===orientationTypes.verHor){
    let dirH=mgs.direction
    let dirV=getPerp(mgs.direction)

    mousePosition=getCrossPoint(vPosition,dirV,hPosition,dirH)
    console.log("verhor")
  }
  //if(verhor)
  //  orientation=orientationTypes.verHor

  if (isFirstVertex){

    let closestCut = getClosestCutWithGeometries(cPosition,null,snapDistance,otherEdgesPositions)
    if(closestCut){
      let cDistance=closestCut.distanceTo(cPosition)
      //console.log("cDistance ** chorizontaldistance ** vcVerticaldistance")
      //console.log(cDistance+" ** "+nearestDistanceToHorizontal+" ** "+nearestDistanceToVertical)
      if((cDistance<nearestDistanceToHorizontal)&&(cDistance<nearestDistanceToVertical)){
        mousePosition=closestCut
        orientation=orientationTypes.verHor
        if (magicGuideLine) {
          nearestGuidelinePositionVertical=closestCut
          nearestGuidelinePositionHorizontal=closestCut
        }
      }
    }
  }


  if(orientation===null){
    //console.log("NO ORIENTATION")
    orientation=orientationTypes.none
  }

  //console.log("magicGuideLine")
  //console.log(magicGuideLine)
  if (magicGuideLine) {
    //console.log("mousePosition")
    //console.log(mousePosition)

    //console.log(nearestGuidelinePositionHorizontal)
    //console.log(nearestGuidelinePositionVertical)
    magicGuideLine.setOrientation(orientation, nearestGuidelinePositionHorizontal, nearestGuidelinePositionVertical)
  }

  //console.log("mousePosition")
  //console.log(mousePosition)
  return mousePosition
}

function getClosestCutWithGeometries(mousePosition, direction, snapDistance, otherPosPairs ) {

  //console.log("getCutWithGeometries")
  //console.log(otherPosPairs)
  //console.log(direction)
  let closestLocalPosition=null
  let closestPosition=null
  let closestLocalDistance=snapDistance

  if(direction===null){
    //console.log("no direction")
    let closestDistance=snapDistance

    otherPosPairs.forEach(otherPosPair =>{
      let lPosA=otherPosPair[0]
      let lPosB=otherPosPair[1]
      let isCut=true

      let cutPos= getOrtoProjectionOnSegment(mousePosition,lPosA,lPosB)
      let distance=cutPos.distanceTo(mousePosition)
      //console.log("distance")
      //console.log(distance)

      if(distance<closestDistance){
        closestDistance=distance
        closestPosition=cutPos
        //console.log("cutPos")
        //console.log(cutPos)
      }

    })
  }else{
    //console.log("Yes direction")
    direction=getUnitVector(direction)
    //console.log(direction)

    let localOtherPosPairs=getLocalPosLists(otherPosPairs,mousePosition,direction)
    //console.log("localPosPairs")/

    //console.log(localOtherPosPairs)
    localOtherPosPairs.forEach(localOtherPosPair =>{
      let lPosA=localOtherPosPair[0]
      let lPosB=localOtherPosPair[1]
      let isCut=true
      //console.log(lPosA)
      //console.log(lPosB)
      if((lPosA.y>=0)&&(lPosB.y>=0))
        isCut=false
      else if((lPosA.y<0)&&(lPosB.y<0))
        isCut=false
      if(isCut){
        let cutPos= getPositionAtY(0,lPosA,lPosB)
        if((cutPos.x>=0)&&(Math.abs(cutPos.x)<closestLocalDistance)){
          closestLocalDistance = Math.abs(cutPos.x)
          closestLocalPosition = cutPos
          //console.log("cutPooos")
          //console.log(cutPos)
        }
        //console.log("closetlocaldistance: "+closestLocalDistance)
        //console.log(closestLocalPosition)
      }
    })
    //console.log("closest: "+closestLocalDistance)
    //console.log(closestLocalPosition)
  }


  //console.log("closetlocaldistance final: "+closestLocalDistance)
  if(closestLocalPosition){
    //console.log("closestLocalPosition")
    //console.log(closestLocalPosition)
    let globalPosition = getGlobalPosition(closestLocalPosition,mousePosition,direction)
    //console.log("globalPosition:")
    //console.log(globalPosition)
    return globalPosition
  }
  if(closestPosition){
    //console.log(closestPosition)
    return closestPosition
  }

  return null
}
export function disableMagicGuideLine() {
  magicGuideLine.setOrientation(orientationTypes.none)
}