import React from 'react'

const SvgDuplicate = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M14.5 7A1.5 1.5 0 0116 8.5v10a1.5 1.5 0 01-1.5 1.5h-8A1.5 1.5 0 015 18.5v-10A1.5 1.5 0 016.5 7zm0 1h-8a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5zm3-4a1.5 1.5 0 011.493 1.356L19 5.5v10a1.5 1.5 0 01-1.5 1.5.5.5 0 01-.09-.992L17.5 16a.5.5 0 00.492-.41L18 15.5v-10a.5.5 0 00-.41-.492L17.5 5h-8a.5.5 0 00-.492.41L9 5.5a.5.5 0 01-1 0 1.5 1.5 0 011.356-1.493L9.5 4h8zm-7 7a.5.5 0 01.492.41l.008.09V13h1.5a.5.5 0 01.09.992L12.5 14H11v1.5a.5.5 0 01-.992.09L10 15.5V14H8.5a.5.5 0 01-.09-.992L8.5 13H10v-1.5a.5.5 0 01.5-.5z"
    />
  </svg>
)

export default SvgDuplicate
