import React, { Component } from 'react'
import Dialog from '../../common/components/Dialog'
import { Field, Form, Formik } from 'formik'
import Text from '../../common/components/Text'
import Button from '../../common/components/Button'
import { updateProject } from '../../common/redux/projects/projects-actions'
import { connect } from 'react-redux'
import { Scrollbar } from 'react-scrollbars-custom'

import './FurtherInfoDialog.scss'

class FurtherInfoDialog extends Component {

  render () {
    const initialValues = { ...this.props.project }

    return (
      <Dialog className={'further-info-dialog'} title={'Project Information'} onCancel={() => this.close()}>
        <Scrollbar className={'further-info-dialog-scrollbar'}>
          <Formik initialValues={initialValues}
                  onSubmit={(values) => this.handleUpdateProject(this.props.project.id, values)}
                  enableReinitialize>

            <Form className='project-information-form'>
              <Text outerClassName={'title'}>Client Name</Text>
              <Field type="text" name="companyName" placeholder="Client Name"/>
              <Text outerClassName={'title'}>Contact Person Haworth</Text>
              <Field type="text" name="projectManager" placeholder="Project Manager"/>
              <Text outerClassName={'title'}>E-Mail</Text>
              <Field type="email" name="mail" placeholder="Email"/>
              <Text outerClassName={'title'}>Phone-Number</Text>
              <Field type="tel" name="phone" placeholder="Phone"/>
              <Text outerClassName={'title'}>Additional Notice</Text>

              <Field name="note" render={({ field }) => (
                <textarea {...field} placeholder="Notes"/>
              )}/>

              <div className="project-information-form-buttons">
                <Button onClick={() => this.close()}>Cancel</Button>
                <Button type="submit" variant={'contained'}>Confirm</Button>
              </div>

            </Form>
          </Formik>
        </Scrollbar>
      </Dialog>
    )
  }

  handleUpdateProject (projectId, values) {
    this.props.updateProject(projectId, values)
    this.close()
  }

  close = () => {
    this.props.onClose()
  }

}

let mapStateToProps = (state) => {
  return {
    project: state.projects.project,
    industryAttributes: state.industryAttributes.industryAttributes,
    countries: state.countries.countries,
  }
}

let mapDispatchToProps = {
  updateProject: updateProject,
}

export default connect(mapStateToProps, mapDispatchToProps)(FurtherInfoDialog)
