import * as React from 'react'
const SvgDoorsActive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 25V5H24V25H19" stroke="#DA5B2B"/>
    <path d="M8 5L19 10V30L8 25V5Z" fill="url(#paint0_linear_12_4318)"/>
    <path d="M8 5L8.2069 4.54482C8.05214 4.47447 7.87224 4.48758 7.72931 4.57961C7.58638 4.67165 7.5 4.83 7.5 5H8ZM19 10H19.5V9.67804L19.2069 9.54482L19 10ZM19 30L18.7931 30.4552L19.5 30.7765V30H19ZM8 25H7.5V25.322L7.7931 25.4552L8 25ZM7.7931 5.45518L18.7931 10.4552L19.2069 9.54482L8.2069 4.54482L7.7931 5.45518ZM18.5 10V30H19.5V10H18.5ZM19.2069 29.5448L8.2069 24.5448L7.7931 25.4552L18.7931 30.4552L19.2069 29.5448ZM8.5 25V5H7.5V25H8.5Z" fill="#DA5B2B"/>
    <path d="M15 16.5L19 18.5" stroke="#DA5B2B"/>
    <defs>
      <linearGradient id="paint0_linear_12_4318" x1="18.9998" y1="30.0025" x2="18.9998" y2="5.0025" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
    </defs>
  </svg>
)

export default SvgDoorsActive