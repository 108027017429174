import * as React from 'react'
const SvgDrawAxesActive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.88448 19.3733L9.01706 19.2408L9.28223 18.9756L13.5249 23.2182L13.2597 23.4834L13.1271 23.616L7.16091 25.3395L8.88448 19.3733Z" fill="url(#paint0_linear_12_4327)" stroke="#DA5B2B"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.28223 18.9756L22.7173 5.54056L26.9599 9.7832L13.5249 23.2182L9.28223 18.9756Z" stroke="#DA5B2B"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.8896 8.36816L9.28305 18.9748L13.5257 23.2174L24.1323 12.6108L19.8896 8.36816Z" fill="url(#paint1_linear_12_4327)" stroke="#DA5B2B"/>
    <defs>
      <linearGradient id="paint0_linear_12_4327" x1="9.28272" y1="27.4612" x2="5.04008" y2="23.2186" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
      <linearGradient id="paint1_linear_12_4327" x1="13.5263" y1="23.2177" x2="9.28365" y2="18.975" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
    </defs>
  </svg>
)

export default SvgDrawAxesActive