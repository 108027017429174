let authService

export default class AuthService {

  static getInstance (serviceBar) {
    if (serviceBar && !authService) {
      authService = new AuthService(serviceBar)
    }
    return authService
  }

  constructor (serviceBar) {
    this.serviceBar = serviceBar
  }

  getUser () {
    return this.serviceBar.getUser()
  }

  getToken () {
    return this.getUser()
      .then(user => {
        if (!user || !user.access_token) {
          return null
        }
        return user.access_token
      })
  }

  login () {
    return this.serviceBar.dispatchEvent(new Event('requestSBUserLogin'))
  }

  logout () {
    return this.serviceBar.dispatchEvent(new Event('requestSBUserLogout'))
  }

  static isLoginRedirect (location) {
    const parts = location.hash.split('&')
    parts[0] = parts[0].replace('#', '')
    return parts.every(part => {
      const key = part.split('=')[0]
      return key === 'code' || key === 'scope' || key === 'state' || key === 'session_state'
    })
  }
  
  static loginFinished (user, location) {
    return user && !AuthService.isLoginRedirect(location)
  }

}