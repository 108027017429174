import React from 'react'

const SvgLayoutStylesIcon = props => (
  <svg className={props.className} viewBox="0 0 32 32">
    <defs>
      <style>
        {
          '.layout-styles-icon_svg__cls-3{fill:none;stroke:#fff;stroke-linejoin:round;stroke-width:2px}'
        }
      </style>
    </defs>
    <g
      id="layout-styles-icon_svg__Layout_Styles_Icon"
      transform="translate(-225 -512)"
    >
      <path
        id="layout-styles-icon_svg__Rechteck_328"
        d="M0 0h32v32H0z"
        data-name="Rechteck 328"
        transform="translate(225 512)"
        fill="none"
      />
      <g
        id="layout-styles-icon_svg__Gruppe_726"
        data-name="Gruppe 726"
        transform="translate(4439 -8381)"
      >
        <rect
          id="layout-styles-icon_svg__Rechteck_237"
          width={18}
          height={18}
          data-name="Rechteck 237"
          rx={2}
          transform="translate(-4208 8900)"
          fill="#454b5a"
        />
        <path
          id="layout-styles-icon_svg__Linie_89"
          d="M0 0h18"
          className="layout-styles-icon_svg__cls-3"
          data-name="Linie 89"
          transform="translate(-4208 8912.648)"
        />
        <path
          id="layout-styles-icon_svg__Linie_90"
          d="M0 0v12"
          className="layout-styles-icon_svg__cls-3"
          data-name="Linie 90"
          transform="translate(-4203.135 8900)"
        />
        <path
          id="layout-styles-icon_svg__Linie_91"
          d="M0 0v5.5"
          className="layout-styles-icon_svg__cls-3"
          data-name="Linie 91"
          transform="translate(-4196.96 8912.5)"
        />
      </g>
    </g>
  </svg>
)

export default SvgLayoutStylesIcon
