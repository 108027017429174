import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';

import InspectorContainer from '../InspectorContainer'
import Dropdown from '../Dropdown'
import VerticalMenu from '../VerticalMenu'
import BuildingMenu from './BuildingMenu'
import { FloorListItem } from './FloorListItem'
import TreeList from '../TreeList'

import { fetchFloorData, lockDepartment, setSelectedFloor } from '../../../floorplan/actions/actions'
import { setSelectedOrganisationalUnit } from '../../redux/appstate/appstate-actions'
import { addBuilding } from '../../../floorplanner/actions/actions'
import { fetchBuildings, updateDrawingAndFetch } from '../../../buildings/actions/actions'
import { updateGeometries, fetchGeometries, updateGeometriesWithPromise } from '../../../DrawingTool/actions/Backend/drawingToolActions'
import { viewNames } from '../../globalConstants';
import { iconTypes } from '../icons/IconFactory';
import { parseToTreeListNodes } from './parseTreeList';
//import { setCurrentTool } from '../../buildings/actions/drawingActions'
import { setCurrentTool } from '../../../buildings/actions/drawingActions'

import './BuildingsFloorsInspector.scss';
import { useTranslation } from 'react-i18next'
import { closeFlyOut, openFlyOut } from '../../../floorplan/actions/ui-actions'
import { useParams } from 'react-router-dom'
import { setMaxOnboardingIndex } from '../../../DrawingTool/actions/UI/drawingToolUIActions'
import { getBuildingId, getFloorId } from '../../utils'


function BuildingsFloorsInspector ({ shortVersion, ...props }) {
  const initState = {
    selectedLevel0: null,
    selectedLevel1: null,
    selectedLevel2: null,
  };

  const [state, setState] = useState(initState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();
  const { selected, title } = props;
  const { t } = useTranslation();

  const placedUnitsTree = useSelector(state => state.placedOrganisationalUnits.placedUnitsTree);
  const assignedFloors = useSelector(state => state.placedOrganisationalUnits.assignedFloors);
  const organisationalUnits = useSelector(state => state.organisationalUnits.units);
  const selectedObjectId = useSelector(state => state.appState.selectedOrganisationalUnitId);
  const buildings = useSelector(state => state.buildings.buildings);
  const selectedBuildingId = useSelector(state => state.appState.selectedBuildingId);
  const selectedFloorId = useSelector(state => state.appState.selectedFloorId);


  useEffect(() => {
    dispatch(closeFlyOut());
  }, [])

  useEffect(() => {
    if (selected !== null) {
      openFlyOut();
    } else {
      closeFlyOut();
    }
  }, [selected, openFlyOut, closeFlyOut]);

  useEffect(() => {
    if (selectedObjectId) {
      updateHierarchicalSelection();
    }
  }, [selectedObjectId]);

  const updateHierarchicalSelection = () => {
    let l0 = null
    let l1 = null
    let l2 = null

    if (selectedObjectId !== null) {
      let unit = getOrganisationalUnit(selectedObjectId)
      if (unit) {
        switch (unit.hierarchicalLevel) {
          case 0:
          default:
            l0 = unit.id
            break
          case 1:
            l1 = unit.id
            l0 = unit.parentId
            break
          case 3:
            l2 = unit.id
            l1 = unit.parentId
            l0 = getOrganisationalUnit(l1).parentId
            break
        }
      }
    }

    setState({
      selectedLevel0: l0,
      selectedLevel1: l1,
      selectedLevel2: l2,
    })

  }

  const getOrganisationalUnit = (uuid) => {
    return organisationalUnits.find(oo => oo.id === uuid)
  }

  const getActiveBuildingName = () => {
    const building = buildings.find((building) => (building.id === selectedBuildingId))

    return building ? building.name : ''
  }



    const activeBuildingName = getActiveBuildingName()

    const isEditOutline = location.pathname.endsWith(viewNames.EDIT)

    const buttonText = isEditOutline ? t('finish_editing') : t('edit_outlines')
    const buttonIcon = isEditOutline ? iconTypes.floorplan : iconTypes.editOutline
    const onButtonClick = isEditOutline ? () => {
      console.log('finish editing')
      dispatch(setCurrentTool("wall"))

      const buildingId = getBuildingId();
      const floorId = getFloorId();
      dispatch(updateGeometriesWithPromise()).then(
        () => {
          dispatch(fetchBuildings()).then(
            () => {
              dispatch(fetchFloorData(buildingId,floorId))
              setTimeout(() => {
                navigate('/projects/' + projectId + "/" + viewNames.FLOORPLAN)
              }   , 1000)
            }
          )
        }
      )

      console.log('finished editing')
    } : () => {

        navigate('/projects/' + projectId + "/" + viewNames.EDIT)
        //fetchGeometries()

        console.log("start editing")
    }




  const getBuildingItems = () => {
    return buildings.map((building) => {
      return {
        id: building.id,
        name: building.name,
        icon: 'building',
      }
    })
  }

  const getFloorListItem = () => {
    let floors = [...assignedFloors].sort((a, b) => a.index < b.index ? 1 : -1)

    const isFloorplanView = location.pathname.endsWith(viewNames.FLOORPLAN)
    const isFloorsView = location.pathname.endsWith(viewNames.FLOORS)
    const isDrawingView = location.pathname.endsWith(viewNames.EDIT)

    const handleLevelItemClick = isFloorplanView ? [
      null,
      (id) => handleListItemClick(id),
      (id) => handleListItemClick(id),
    ] : [null, null, null]

    return floors.map((floor, index) => {

      const organisationalUnits = floor.id === selectedFloorId && isFloorplanView ? placedUnitsTree : floor.organisationalUnits

      const nodes = !isDrawingView ? parseToTreeListNodes(organisationalUnits, true, shortVersion, (unit) => handleLockDepartment(unit)) : []

      return (
        <FloorListItem key={index}
                       floor={floor}
                       selectedFloorId={(!isFloorsView ? selectedFloorId : null)}
                       openFloor={!isFloorsView ? (id) => handleFloorClick(id) : null}>
          {nodes.length ?
            <TreeList className={'building-tree-list-container'}
                      nodes={nodes}
                      activeLevel0={state.selectedLevel0}
                      activeLevel1={state.selectedLevel1}
                      activeLevel2={state.selectedLevel2}
                      selected={floor.id === selectedFloorId ? selectedObjectId : null}
                      onLevelItemClick={selectedFloorId === floor.id ? handleLevelItemClick : null}
            /> : null}
        </FloorListItem>)
    })
  }

  const handleLockDepartment = (unit) => {
    setSelectedOrganisationalUnit(unit.id)
    lockDepartment(unit.id, !unit.locked)
  }

  const handleListItemClick = (id) => {
    console.log("handleListItemClick", id)
    dispatch(setSelectedOrganisationalUnit(id))
    dispatch(openFlyOut())
  }

  const handleFloorClick = (floorId) =>{
    if (floorId !== selectedFloorId)
      dispatch(setSelectedFloor(selectedBuildingId, floorId))
  }

  const handleBuildingClick = (buildingId) =>{
    let floorId = null

    buildings.forEach((building) => {
      if (building.id === buildingId && building.floors.length > 0) {
        floorId = building.floors[0].id
      }
    })

    dispatch(setSelectedFloor(buildingId, floorId))

  }

  return (
    <React.Fragment>
      <InspectorContainer scrollable headerTitle={title}
                          buttonText={buttonText}
                          buttonIcon={buttonIcon}
                          onButtonClick={onButtonClick}>
        <div className={'buildings-drop-down'}>
          <Dropdown title={activeBuildingName}
                    icon={iconTypes.building}
                    items={getBuildingItems()}
                    onClick={(buildingId) => handleBuildingClick(buildingId)}
                    onClickAddItem={() => dispatch(addBuilding())}
                    addIcon={iconTypes.addBuilding}
                    addItemText={t('add_new_building')}
                    menuStyleClass={'buildings-drop-down-menu'}/>
          <VerticalMenu>
            <BuildingMenu/>
          </VerticalMenu>
        </div>
        {getFloorListItem()}
      </InspectorContainer>
    </React.Fragment>
  )
}

export default BuildingsFloorsInspector