import React from 'react'

import './LockableField.scss'

function LockableField ({ children }) {

  return (
    <div className={'lockable-field'}>
      {React.Children.map(children, (child) => {

        return <div className={'lockable-field-item'}>{child}</div>
      })
      }
    </div>
  )
}

export default LockableField