import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Dialog, { DialogButtonGroup, DialogContent } from '../../Dialog'
import Button from '../../Button'
import Text from '../../Text'
import FileInput from '../../FileInput'

import './FurnitureUploadDialog.scss'
import { withTranslation } from 'react-i18next'

class FurnitureUploadDialog extends Component {

  constructor (props) {
    super(props)
    this.state = {
      xlsxFile: null,
      dxfFile: null,
    }
  }

  setXlsxFile = xlsxFile => {
    this.setState({ xlsxFile: xlsxFile })
  }

  setDxfFile = dxfFile => {
    this.setState({ dxfFile: dxfFile })
  }

  upload = () => {

    this.props.uploadXlsxDxf(this.state.xlsxFile, this.state.dxfFile)

    this.close()
  }

  close = () => {
    this.props.onClose()
  }

  render () {
    const {t} = this.props

    return (
      <Dialog onCancel={this.close}
              title={'Import furniture'}
              className={'xlsx-dxf-uploader'}>
        <DialogContent>
          <Text outerClassName={'file-input-title'}>{t('select_xlsx_file')}</Text>
          <FileInput name="Upload .xlsx" accept={'.xlsx'} onChange={file => this.setXlsxFile(file)}/>
          <Text outerClassName={'file-input-title'}>{t('select_dxf_file')}</Text>
          <FileInput name="Upload .dxf" accept={'.dxf'} onChange={file => this.setDxfFile(file)}/>
        </DialogContent>
        <DialogButtonGroup>
          <Button onClick={this.close}>{t('cancel')}</Button>
          <Button onClick={this.upload} disabled={!this.state.xlsxFile || !this.state.dxfFile} variant={'contained'}>{t('confirm')}</Button>
        </DialogButtonGroup>
      </Dialog>
    )
  }
}

FurnitureUploadDialog.propTypes = {
  uploadXlsxDxf: PropTypes.func.isRequired,
}

export default withTranslation() (FurnitureUploadDialog)