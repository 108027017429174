import React, {  useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchProjectData } from './actions/actions'

import Sidebar from '../common/components/buildingsFloorsInspector/Sidebar'
import DashboardContainer from './components/dashboard/DashboardContainer'
import { PlanAppContainer } from '../common/components/layout/PlanAppContainer'
import { FloorplanContent } from './FloorplanContent'
import { useParams } from 'react-router-dom'

function FloorPlanApp() {
  const [viewMode, setViewMode] = useState('team');
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const { projectId } = useParams(); // Angenommen, die projectId kommt aus der URL
  const dataLoaded = useSelector((state) => state.dataLoaded.dataLoaded);
  const project = useSelector(state => state.projects.project)

  useEffect(() => {
    if (!dataLoaded && !loading) {
      setLoading(true);
      let id = projectId;
      if (project && project.id) {
        id = project.id;
      }
      dispatch(fetchProjectData(id));
    }
  }, [dispatch, dataLoaded, projectId, loading]); // Abhängigkeiten für useEffect

  useEffect(() => {
    if (dataLoaded)
      setLoading(false)
  } , [dataLoaded])

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };

  return (
    <PlanAppContainer id="floor-plan" className="floor-plan-app">
      <Sidebar title="Floorplan" />
      <FloorplanContent viewMode={viewMode} />
      <DashboardContainer viewMode={viewMode} onViewModeChange={handleViewModeChange} />
    </PlanAppContainer>
  );
}

export default FloorPlanApp;
