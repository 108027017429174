import * as React from 'react'

const SvgDrawDeleteInactive = props =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <g stroke={props.color} fill="none" fillRule="evenodd">
      <path strokeLinecap="round" d="M6 10h20"/>
      <path d="M22.8 10.2h-14v13.602c0 .772.62 1.398 1.394 1.398h11.212c.77 0 1.394-.625 1.394-1.398V10.2zM20.5 10h-9V7.399c0-.773.619-1.399 1.406-1.399h6.188c.776 0 1.406.625 1.406 1.399V10z"/>
      <path
        strokeLinecap="round"
        d="M16 13.7v8.376M12.4 13.7v8.376m7-8.376v8.376"
      />
    </g>
  </svg>

export default SvgDrawDeleteInactive