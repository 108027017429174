export const drawingActionTypes = {
  SELECTED_DRAWING_TOOL: 'SELECTED_DRAWING_TOOL',
  SET_DRAWING_HELP_LEGEND: 'SET_DRAWING_HELP_LEGEND',
  //SET_DRAWING_HELP: 'SET_DRAWING_HELP',

  SET_MARKER_SIZE: 'SET_MARKER_SIZE',

  STORE_GEOMETRY_CHANGES: 'STORE_GEOMETRY_CHANGES',
  UNDO_GEOMETRY_CHANGE: 'UNDO_GEOMETRY_CHANGE',
  REDO_GEOMETRY_CHANGE: 'REDO_GEOMETRY_CHANGE',

  CLEAR_PROJECT: 'CLEAR_PROJECT',
}

export function setSelectedDrawingTool (tool) {
  return {
    type: drawingActionTypes.SELECTED_DRAWING_TOOL,
    selectedTool: tool,
  }
}

export function setDrawingHelpLegend (drawingHelpLegend) {
  return {
    type: drawingActionTypes.SET_DRAWING_HELP_LEGEND,
    drawingHelpLegend: drawingHelpLegend,
  }
}

export function setMarkerSize (markerType, size) {
  return {
    type: drawingActionTypes.SET_MARKER_SIZE,
    markerType: markerType,
    size: size,
  }
}
/*
export function setDrawingHelp (drawingHelp) {
  return {
    type: drawingActionTypes.SET_DRAWING_HELP,
    payload: drawingHelp,
  }
}
*/
export function storeGeometryChanges (geometries) {
  return {
    type: drawingActionTypes.STORE_GEOMETRY_CHANGES,
    payload: geometries,
  }
}

export function undoGeometryChange () {
  return {
    type: drawingActionTypes.UNDO_GEOMETRY_CHANGE,
  }
}

export function redoGeometryChange () {
  return {
    type: drawingActionTypes.REDO_GEOMETRY_CHANGE,
  }
}