import { GeometryController } from './GeometryController'
import { geometryTypes } from '../config/GeometryTypes'
import { ReferenceEdgeGeometry } from '../geometries/ReferenceEdgeGeometry'
import { addWidthPreviewReferenceEdge, getWidthPreviewReferenceEdge, setPreviewReferenceEdge } from '../helper/PreviewReferenceEdge'
import { representationTypes } from '../config/RepresentationTypes'

export class ReferenceEdgeController extends GeometryController {

  setActive (active) {
    //console.log(active, this)
    if (active) {
      const position = this.floorPlanerController.getCurrentPosition()
      if(position)
        setPreviewReferenceEdge(position)
      else
        setPreviewReferenceEdge()
    } else {
      setPreviewReferenceEdge()
    }
  }

  onLeftMouseDown (event, geometry) {
    console.log("recontroller onLeftMouseDown: "+geometry)

    if (!geometry) {
      console.log("no geometry")
      return this.onLeftMouseDownNew()
    } else {
      console.log("lmd geometry action: "+geometry.getAction())
      console.log(geometry)
      return ReferenceEdgeController.onLeftMouseDownEdit(event, geometry)
    }
  }

  onLeftMouseDownNew () {
    const { edge, position } = this.getEdgeAndPosition()

    console.log("edge:"+edge+"  position:"+position)
    if (!edge || !edge.parent || !edge.parent.parent) {
      return false
    }

    console.log("NEW REFERENECE EDGE")
    const width = getWidthPreviewReferenceEdge()
    const referenceEdgeGeometry = new ReferenceEdgeGeometry(this.group, this.representationType, width)
    referenceEdgeGeometry.updateReferenceEdge(edge, position)
    this.floorPlanerController.saveChanges()

    return true
  }

  static onLeftMouseDownEdit (event, geometry) {
    console.log("onLeftMouseDownEdit")
    if (!geometry)
      return false

    switch (geometry.getAction()) {
      case geometry.actions.select:
        console.log("select")
        if (event.altKey) {
          geometry.setAction(geometry.actions.resize)
          return true
        }
        break
      default:
        return false
    }

    return false
  }

  onArrowLeftDown (event, geometry) {
    return ReferenceEdgeController.move(geometry, -0.01)
  }

  onArrowRightDown (event, geometry) {
    return ReferenceEdgeController.move(geometry, 0.01)
  }

  onArrowUpDown (event, geometry) {
    console.log("arrow UpDown")
    return ReferenceEdgeController.addWidth(geometry, 0.01)
  }

  onArrowDownDown (event, geometry) {
    console.log("arrow DownDown")
    return ReferenceEdgeController.addWidth(geometry, -0.01)
  }

  static addWidth (geometry, delta) {
    console.log(geometry+" try add width "+delta)
    if (!geometry)
      return false

    switch (geometry.getAction()) {
      case geometry.actions.select:
        geometry.addWidth(delta)

        return true
      default:
        return false
    }
  }

  static move (geometry, movement) {
    if (!geometry)
      return false

    switch (geometry.getAction()) {
      case geometry.actions.select:
        geometry.move(movement)
        return true
      default:
        return false
    }
  }

  onMouseMove (event, geometry, currentGeometries) {
    console.log("onMouseMove: ")
    console.log(geometry)
    console.log(currentGeometries)
    const { cPosition, cEdge } = this.getEdgeAndPosition()
    console.log(cPosition)
    console.log(cEdge)

    if (!geometry && !currentGeometries.length) {
      const { position, edge } = this.getEdgeAndPosition()

      setPreviewReferenceEdge(position, edge)
      return true
    }

    if (!geometry)
      return false

    switch (geometry.getAction()) {
      case geometry.actions.drag:
        geometry.moveDelta(this.floorPlanerController.getMouseDeltaMovement())
        return true
      case geometry.actions.resize:
        console.log("resize with mouse")
        if (event.altKey) {
          geometry.addWidth(this.floorPlanerController.getMouseDeltaMovement().x)
          return true
        } else {
          return false
        }
      default:
        return false
    }
  }

  onLeftMouseUp (event, geometry) {
    if (!geometry) {
      return false
    }

    switch (geometry.getAction()) {
      case geometry.actions.drag:
        geometry.setAction(geometry.actions.select)
        return true
      case geometry.actions.resize:
        geometry.setAction(geometry.actions.select)
        return true
      default:
        return false
    }
  }

  onBackspaceDown (event, geometry, currentGeometries) {
    console.log("refernceEdgeController remove")
    if (!geometry) {
      return false
    }

    switch (geometry.getAction()) {
      case geometry.actions.select:
        console.log("select")
        this.removeGeometryFromGroup(geometry, currentGeometries)
        return true
      default:
        return false
    }
  }

  getEdgeAndPosition () {
    console.log("nearest edge!")
    const currentTool =this.floorPlanerController.getCurrentTool()
    let repType=""

    if(currentTool==="door")
      repType="wall"
    else if(currentTool==="window")
      repType="outline"


    const geometry = this.floorPlanerController.getRaycastHitObjectByRepresentationTypes([repType], [geometryTypes.face])
    //const geometry = this.floorPlanerController.getRaycastHitObjectByRepresentationTypes([representationTypes.outline, representationTypes.wall], [geometryTypes.face])

    const edge = geometry ? geometry.getNearestEdgeToPosition(this.floorPlanerController.getCurrentPosition()) : geometry
    const position = edge ? edge.getNearestPositionOnEdge(this.floorPlanerController.getCurrentPosition()) : this.floorPlanerController.getCurrentPosition()

    console.log("edge: "+edge)
    console.log("position: "+position)

    return { edge: edge, position: position }
  }

  onMouseWheel (event, geometry, currentGeometries) {
    if (!event.altKey) {
      return
    }

    const step = -Math.sign(event.deltaY) * 0.05

    addWidthPreviewReferenceEdge(step)
  }

  onAltDown () {
    this.floorPlanerController.setEnableZoom(false)

    return true
  }

  onAltUp () {
    this.floorPlanerController.setEnableZoom(true)

    return false
  }

}