import * as React from 'react'

const SvgDrawPanActive = props =>

  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
  >
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect width={32} height={32} fill="#00293B" opacity={0.08} rx={2}/>
      <path
        fill="url(#prefix__a)"
        stroke="#DA5B2B"
        strokeLinejoin="round"
        d="M14.5 25h5a2 2 0 002-2v-.197c0-1.176.348-2.325 1-3.303a6.764 6.764 0 001.12-3.299l.28-4.2a1.303 1.303 0 00-1.4-1.387c-.846.065-1.5.77-1.5 1.62V15h0V8.502c0-.787-.638-1.426-1.426-1.426-.025 0-.05 0-.074.002-.84.044-1.5.738-1.5 1.58V14 7.5a1.5 1.5 0 00-3 0V14h0V9a1.5 1.5 0 00-3 0v6h0l-.5-1.5a1.582 1.582 0 00-2-1 1.304 1.304 0 00-.725 1.878l2.744 4.879A7.634 7.634 0 0112.5 23a2 2 0 002 2z"
      />
    </g>
  </svg>

export default SvgDrawPanActive
