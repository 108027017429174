import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';
import './StartApp.scss';
import Text, { textTypes } from '../common/components/Text';
import Button from '../common/components/Button';
import IconButton from '../common/components/IconButton';
import { iconTypes } from '../common/components/icons/IconFactory';
import ModalConsumer from '../common/components/modal/ModalConsumer';
import TemplateDialog from '../common/components/TemplateDialog';
import { ImportButton } from './ImportButton';
import UploadDialog from '../header/components/UploadDialog';
import LoadProjectDialog from '../header/components/dialogs/LoadProjectDialog';
import {
  activateAndLoadProject,
  createNewProject,
  deleteProjectAndFetchProjects,
  fetchUserProjectsList,
  uploadProjectFile
} from '../common/redux/projects/projects-actions';
import { startOnBoarding } from '../onboarding/actions/onboarding-actions';
import { setAdvancedSettings } from '../common/redux/settings/settings-actions';
import { useNavigate } from 'react-router'


function StartApp() {
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const projects = useSelector(state => state.projects.userProjects
    ? state.projects.userProjects
      .slice(1)
      .slice(-5)
      .map(project => ({
        label: project.name,
        value: project.id,
        date: project.lastModifiedDate,
      }))
      .reverse()
    : []);

  useEffect(() => {
    dispatch(fetchUserProjectsList());
    dispatch(setAdvancedSettings(false));
  }, [dispatch]);

  const deleteProject = (projectId, projectname, showModal) => {
    showModal(TemplateDialog, {
      className: 'delete-project-dialog',
      text: `Are you sure you want to permanently delete the project "${projectname}"? You can't undo this action.`,
      title: 'Delete project',
      rightButtonText: 'Delete',
      rightButtonVariant: 'delete',
      onCancel: () => {},
      onRightButtonClicked: () => dispatch(deleteProjectAndFetchProjects(projectId)),
    });
  };

  const handleUploadProjectFile = (file) => {
    dispatch(uploadProjectFile(file)).then((path) => {
      navigate(path)
    })

    //navigate(path)

  }

  const handleOpenProject = showModal => {
    showModal(UploadDialog, {
      title: 'Upload project file',
      upload: handleUploadProjectFile,
    });
  };



  const handleOpenViewAllModalClick = (showModal, component, data) => {
    showModal(component, data);
  };

  const renderProjectItem = showModal => (
    <React.Fragment>
      <div className={'recent-project-border'} />
      {projects.map((project, index) => {
        const selectedStyle = selectedProjectId === project.value ? ' selected' : '';
        return (
          <React.Fragment key={index}>
            <div className={`recent-project${selectedStyle}`} onClick={() => setSelectedProjectId(project.value)} onDoubleClick={() => dispatch(activateAndLoadProject(project.value)).then(
              (url) => navigate(url)
            )}>
              <div className={'recent-project-text-container'}>
                <Text outerClassName={'recent-project-text-bold'}>{project.label}</Text>
                <Text outerClassName={'recent-project-date'}>{project.date}</Text>
              </div>
              <div className={'recent-project-icon-container'}>
                {selectedStyle ? <IconButton styleClass={'recent-project-delete'} icon={iconTypes.delete} onClick={() => deleteProject(project.value, project.label, showModal)} /> : null}
                <IconButton className={'recent-project-arrow-icon'} styleClass={'recent-project-open'} icon={iconTypes.arrow} onClick={() => dispatch(activateAndLoadProject(project.value)).then(
                  (url) => navigate(url)
                )} />
              </div>
            </div>
            <div className={'recent-project-border'} />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );

  const oldProjectsAvailable = projects.length;

  const handleStartOnBoarding = (advanced) => {
    const navigateOnSuccess = (path) => {
      console.log("OONBOARING SUCCESFULLY DONE!")
      console.log(path)
      navigate(path);
    };

    dispatch(startOnBoarding(advanced, navigateOnSuccess));
  };



    return (
      <div className={'start-app-container'}>
        <div className={'start-app-assistant'}>
          <div className={'start-app-assistant-text'}>
            <Text darkMode component={textTypes.runningTitle} outerClassName={'start-app-title'}>{t('how_you_want_to_start')}</Text>
            <Text darkMode multiline component={textTypes.hero} outerClassName={'start-app-hero'}>{t('create_floorplan_in_minutes')}</Text>
            <Text darkMode multiline component={textTypes.paragraph} outerClassName={'start-app-copy-text'}>{t('create_floorplan_easy')}</Text>
            <Button className={'start-app-button'} variant={'orange-filled'} onClick={() => handleStartOnBoarding('false')}>{t('start')}</Button>
          </div>
        </div>
        <div className={'start-app-right'}>
          <ModalConsumer>
            {({ showModal }) => (
              <div className={'start-app-advanced' + (oldProjectsAvailable ? '' : ' expand')}>
                <Text multiline component={textTypes.hero} outerClassName={'start-app-hero'}>{t('set_up_deatiled_project')}</Text>
                <Text multiline component={textTypes.paragraph} outerClassName={'start-app-copy-text'}>{t('define_from_ground')}</Text>
                <div className={'start-planing-import-container'}>
                  <Button className={'start-app-button planning'} onClick={() => handleStartOnBoarding('true')}>{t('start_planning')}</Button>
                  <ImportButton component={textTypes.paragraph} outerClassName={'import-button'} onClick={() => handleOpenProject(showModal)}/>
                </div>
              </div>
            )}
          </ModalConsumer>

          <ModalConsumer>
            {({ showModal }) => (
              <div className={'start-app-recent-projects'}>
                <div className={'start-app-recent-projects-scroll-area'}>
                  <div className={'recent-projects-container'}>
                    <Text component={textTypes.runningTitle} outerClassName={'start-app-recent-project'}>{t('recent_projects')}</Text>
                    <Button className={'view-all-button'} secondIcon={iconTypes.openFolder} iconColor={'#000'}
                            onClick={() => handleOpenViewAllModalClick(showModal, LoadProjectDialog)}>{t('view_all')}</Button>
                  </div>
                  <Scrollbar autoHide>
                    {renderProjectItem(showModal)}
                  </Scrollbar>
                </div>
              </div>
            )}
          </ModalConsumer>
        </div>

      </div>
    )

}


export default StartApp