import React from 'react'

const SvgBlackWhiteInactive = props => (
  <svg width={36} height={36} className={props.className}>
    <defs>
      <filter
        id="bw-inactive_svg__b"
        width="105%"
        height="105%"
        x="-2.5%"
        y="-2.5%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur
          in="SourceAlpha"
          result="shadowBlurInner1"
          stdDeviation={0.5}
        />
        <feOffset in="shadowBlurInner1" result="shadowOffsetInner1"/>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.129971591 0"
        />
      </filter>
      <filter
        id="bw-inactive_svg__d"
        width="107.1%"
        height="107.1%"
        x="-3.6%"
        y="-3.6%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur
          in="SourceAlpha"
          result="shadowBlurInner1"
          stdDeviation={0.5}
        />
        <feOffset in="shadowBlurInner1" result="shadowOffsetInner1"/>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.129971591 0"
        />
      </filter>
      <path
        id="bw-inactive_svg__a"
        d="M18 8c5.523 0 10 4.477 10 10s-4.477 10-10 10S8 23.523 8 18 12.477 8 18 8zm0 1.5a8.5 8.5 0 100 17 8.5 8.5 0 000-17z"
      />
      <path
        id="bw-inactive_svg__c"
        d="M18 11a7 7 0 110 14 7 7 0 010-14zm0 12.5a5.5 5.5 0 000-11v11z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#E8E9EB" xlinkHref="#bw-inactive_svg__a"/>
      <use
        fill="#000"
        filter="url(#bw-inactive_svg__b)"
        xlinkHref="#bw-inactive_svg__a"
      />
      <g transform="rotate(30 18 18)">
        <use fill="#E8E9EB" xlinkHref="#bw-inactive_svg__c"/>
        <use
          fill="#000"
          filter="url(#bw-inactive_svg__d)"
          xlinkHref="#bw-inactive_svg__c"
        />
      </g>
    </g>
  </svg>
)

export default SvgBlackWhiteInactive
