import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { deleteProject } from '../../../common/redux/projects/projects-actions'
import DeletionDialog from '../../../common/components/DeletionDialog'

function DeleteProjectsDialog({ onClose }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectId } = useParams();

  const handleDelete = () => {
    navigate(`/`);
    dispatch(deleteProject(projectId));
    onClose();
  };

  console.log("Delete: ", projectId)

  return (
    <DeletionDialog className={'delete-project-dialog'}
                    title={'Delete Project'}
                    text={'Are you sure you want to delete the current project?'}
                    onConfirm={handleDelete}
                    onCancel={onClose}/>
  );
}

export default DeleteProjectsDialog;
