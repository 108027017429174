import React from 'react'

const SvgNext = props => (

  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
  >
    <path
      d="M14.293 6.293L13.586 7l4.5 4.5H4v1h14.086l-4.5 4.5.707.707L20 12l-5.707-5.707z"
      fill="#00293B"
      fillRule="nonzero"
    />
  </svg>
)

export default SvgNext