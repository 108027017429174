import React, { Component } from 'react'
import { Field, Form, Formik } from 'formik'
import { connect } from 'react-redux'

import { getAvailableApplicationProfiles } from '../../../../common/redux/styles/styles'
import { updateOrganisationalUnitApplicationProfile } from '../../../actions/actions'
import { getSelectedOrganisationalUnit } from '../../../reducers/organisationalUnits'

import NumberInput from '../../../../common/components/NumberInput'

import MenuSection from '../../../../common/components/MenuSection'
import ToggleSwitch from '../../../../common/components/ToggleSwitch'
import Button from '../../../../common/components/Button'
import SelectButton from '../../../../common/components/SelectButton'
import ResetButton from './ResetButton'
import SelectField from './SelectField'
import IconField from './IconField'
import DataField from './DataField'
import Text from '../../../../common/components/Text'
import { iconTypes } from '../../../../common/components/icons/IconFactory'

import './ApplicationProfile.scss'

const placingOptions = [
  {
    label: 'headcount per seat',
    value: 'PER_SEAT',
  },

  {
    label: 'headcount per unit',
    value: 'PER_UNIT',
  },

  {
    label: 'number of seats',
    value: 'SEATS',
  },

  {
    label: 'number of units',
    value: 'UNITS',
  },
]

class ApplicationProfile extends Component {

  constructor (context) {
    super(context)

    this.state = {
      customize: false,
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.unit && prevProps.unit.id !== this.props.unit.id) {
      this.setState({ customize: false })
    }
  }

  getLabelByPlacingOptionValue (value) {
    return placingOptions.find(placingOption => placingOption.value === value).label
  }

  render () {

    const isCustom = this.props.unit.applicationProfile.custom

    const applicationProfileOptions = this.props.applicationProfiles
      .map(style => ({
        label: style.name + ((isCustom && this.props.unit.applicationProfile.id === style.id) ? ' (Custom)' : ''),
        value: style.id,
      }))

    return (
      <MenuSection className={'application-profiles-flyout-menu'}>
        <MenuSection>
          <SelectButton label={'Application Style'}
                        value={this.props.unit.applicationProfile.id}
                        forceUpdate={true}
                        options={applicationProfileOptions}
                        onChange={(event) => this.handleApplicationStyle(this.props.unit, event.target.value)}/>
        </MenuSection>

        <MenuSection>
          <ResetButton className={'pattern-style-reset-button'} label={'Setting'} variant={'contained'} disabled={!isCustom} onClick={() => this.handleResetApplicationStyle(this.props.unit)}/>
          <Formik initialValues={{ applicationProfile: { ...this.props.unit.applicationProfile }, unit: this.props.unit }}
                  onSubmit={(values) => this.handleUpdateApplicationProfile(values.unit, values.applicationProfile)}
                  enableReinitialize>
            {({ values }) => (
              <Form>
                {values.applicationProfile.wishes && (
                  values.applicationProfile.wishes.map((wish, index) => (
                    <MenuSection key={index}>
                      <Text outerClassName={'application-profiles-flyout-header-wrapper'} className={'application-profiles-flyout-menu-header'}>{wish.categoryName}</Text>

                      {this.state.customize ?
                        <Field
                          label={'Applied Rule'}
                          name={`applicationProfile.wishes[${index}].placing`}
                          options={placingOptions}
                          component={SelectButton}/>
                        : <SelectField label={'Applied Rule'} data={this.getLabelByPlacingOptionValue(wish.placing)}/>
                      }

                      {this.state.customize ?
                        <Field label={'Value'}
                               name={`applicationProfile.wishes[${index}].value`}
                               component={NumberInput}
                               step={1}
                               digits={0}/> :
                        <DataField label={'Value'} data={wish.value}/>}

                      {this.state.customize ?
                          <Field label={'Exceed if possible'}
                                 name={`applicationProfile.wishes[${index}].exceed`}
                                 component={ToggleSwitch}/>
                          :
                        <IconField label={'Exceed if possible'} icon={wish.exceed ? iconTypes.togglePositive : iconTypes.toggleNegative}/>
                        }
                      </MenuSection>
                    ),
                  )
                )}
                {
                  this.state.customize ?
                    <div className={'application-style-form-buttons'}>
                      <Button type="reset" onClick={() => this.handleDiscard()}>Discard</Button>
                      <Button type="submit" variant={'contained'}>Confirm</Button>
                    </div> :
                    <div className={'application-style-form-buttons'}>
                      <Button className={'application-style-customized-button'} onClick={() => this.setState({ customize: true })} variant={'contained'}>Customize</Button>
                    </div>
                }
              </Form>
            )}
          </Formik>
        </MenuSection>
      </MenuSection>
    )
  }

  handleDiscard () {
    setTimeout(() => this.setState({ customize: false }), 100)
  }

  handleApplicationStyle (unit, applicationStyleId) {
    const applicationStyle = this.props.applicationProfiles.find(applicationStyle => applicationStyle.id === applicationStyleId)
    applicationStyle.isCustom = false

    this.props.updateApplicationProfile(unit.buildingId, unit.floorId, unit.id, applicationStyle)
    this.setState({ customize: false })
  }

  handleResetApplicationStyle (unit) {
    const applicationProfile = this.props.applicationProfiles.find(applicationProfile => applicationProfile.id === unit.applicationProfile.id)
    applicationProfile.isCustom = false

    this.props.updateApplicationProfile(unit.buildingId, unit.floorId, unit.id, applicationProfile)
    this.setState({ customize: false })
  }

  handleUpdateApplicationProfile (unit, profile) {
    let custom = {
      ...profile,
      custom: true,
    }

    console.log(custom)

    this.props.updateApplicationProfile(unit.buildingId, unit.floorId, unit.id, custom)
    this.setState({ customize: false })
  }
}

let mapStateToProps = (state) => {

  return {
    unit: getSelectedOrganisationalUnit(state),
    applicationProfiles: getAvailableApplicationProfiles(state),
  }
}

let mapDispatchToProps = {
  updateApplicationProfile: updateOrganisationalUnitApplicationProfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationProfile)