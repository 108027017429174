import { setDataLoaded } from '../../common/redux/dataLoader/dataLoader-actions'
import { createProject } from '../../common/redux/projects/projects-actions'
import { viewNamesOnBoarding } from '../../common/globalConstants'

export function startOnBoarding(advancedSettings = false, onSuccess) {
  return (dispatch) => {
    dispatch(setDataLoaded(false));
    dispatch(createProject({ advancedSettings }))
      .then(({ value }) => {
        if (onSuccess && typeof onSuccess === 'function') {

          onSuccess(`/projects/${value.data.id}/onboarding/`+viewNamesOnBoarding.START);
        }
      });
  }
}
