import React, { Component } from 'react'
import ToggleSwitch from '../../../common/components/ToggleSwitch'
import './PatternInspectorSwitch.scss'
import Icon, { iconTypes } from '../../../common/components/icons/IconFactory'

class PatternInspectorSwitch extends Component {

  render () {

    let styleClasses = 'pattern-toggle-switch' + (this.props.disabled ? ' disabled' : '') + (this.props.className ? ' ' + this.props.className : '')
    let idButton = 'mirror-' + this.props.field.name + '.mirrored'

    return (
      <div className={styleClasses}>
        {this.props.label && <label className={'toggle-switch-label noselect'}>{this.props.label}</label>}

        <input className={'mirror-button'}
               type={'checkbox'}
               disabled={this.props.disabled}
               id={idButton}
               name={this.props.field.name + '.mirrored'}
               checked={this.props.field.value.mirrored || false}
               onChange={this.props.field.onChange}/>
        <label className={'mirror-label'} htmlFor={idButton}>
          <Icon className={'mirror-icon'} name={iconTypes.mirror} active={this.props.field.value.mirrored}/>
        </label>

        <ToggleSwitch disabled={this.props.disabled}
                      field={{
                        name: this.props.field.name + '.active',
                        value: this.props.field.value.active,
                        onChange: this.props.field.onChange,
                      }}/>

      </div>
    )

  }
}

export default PatternInspectorSwitch