import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { getAvailablePatternStyles } from '../../../../common/redux/styles/styles'
import { updateOrganisationalUnitPatternStyle } from '../../../actions/actions'

import { CUSTOM_STYLE_ID } from '../../../constants'

import NumberInput from '../../../../common/components/NumberInput'

import MenuSection from '../../../../common/components/MenuSection'
import SelectButton from '../../../../common/components/SelectButton'
import ToggleSwitch from '../../../../common/components/ToggleSwitch'
import Button from '../../../../common/components/Button'
import ResetButton from './ResetButton'
import Text from '../../../../common/components/Text'
import DataField from './DataField'
import IconField from './IconField'
import SelectField from './SelectField'

import './PatternStyle.scss'
import { getSelectedOrganisationalUnit } from '../../../reducers/organisationalUnits'
import { iconTypes } from '../../../../common/components/icons/IconFactory'

class PatternStyle extends Component {

  constructor (context) {
    super(context)

    this.state = {
      customize: false,
      selectedPatternTypeId: null,
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.unit && prevProps.unit.id !== this.props.unit.id) {
      this.setState({ customize: false })
    }
  }

  render () {
    const patternTypeOptions = this.props.patternTypes.map(type => ({
      label: type.name,
      value: type.id,
    }))

    const isCustom = false //this.props.unit.patternStyle.custom

    const patternStylesOptions = this.props.patternStyles.filter(style => style.name !== CUSTOM_STYLE_ID)
      .map(style => ({
        label: style.name + ((isCustom && this.props.unit.patternStyle.id === style.id) ? ' (Custom)' : ''),
        value: style.id,
      }))

    const currentPatternType = this.state.selectedPatternTypeId ? this.state.selectedPatternTypeId : this.props.unit.patternStyle.patternType.id

    return (
      <MenuSection className={'pattern-style'}>
        <MenuSection className={'pattern-style-picker'}>
          <SelectButton label={'Pattern Style'}
                        value={this.props.unit.patternStyle.id}
                        options={patternStylesOptions}
                        onChange={(event) => this.handlePatternStyle(this.props.unit, event.target.value)}/>
        </MenuSection>

        <MenuSection className={'pattern-style-pattern-picker'}>
          <ResetButton className={'pattern-style-reset-button'} label={'Setting'} variant={'contained'} disabled={!isCustom} onClick={() => this.handleResetPatternStyle(this.props.unit)}/>
          {this.state.customize ?
            <SelectButton
              label={'Used Pattern'}
              value={currentPatternType}
              options={patternTypeOptions}
              onChange={(event) => this.handlePatternTypeSelection(event.target.value)}/>
            : <SelectField label={'Used Pattern'} data={this.props.unit.patternStyle.patternType.name}/>
          }
        </MenuSection>

        <MenuSection>
          <Formik initialValues={{ patternStyle: { ...this.props.unit.patternStyle }, unit: this.props.unit }}
                  onSubmit={(values) => this.handleUpdatePatternStyle(values.unit, values.patternStyle)}
                  enableReinitialize>
            <Form>
              <MenuSection>
                <Text>Custom Settings</Text>
                {this.state.customize ?
                  <Field label={'Side Separator'}
                         name={'patternStyle.sideSeparator'}
                         component={ToggleSwitch}
                  />
                  :
                  <IconField label={'Side Separator'} icon={this.props.unit.patternStyle.sideSeparator ? iconTypes.togglePositive : iconTypes.toggleNegative}/>
                }

                {this.state.customize ?
                  <Field label={'Face Separator'}
                         name={'patternStyle.faceSeparator'}
                         component={ToggleSwitch}/>
                  :
                  <IconField label={'Face Separator'} icon={this.props.unit.patternStyle.faceSeparator ? iconTypes.togglePositive : iconTypes.toggleNegative}/>
                }

                {this.state.customize ?
                  <Field label={'Back Separator'}
                         name={'patternStyle.backSeparator'}
                         component={ToggleSwitch}/>
                  :
                  <IconField label={'Back Separator'} icon={this.props.unit.patternStyle.backSeparator ? iconTypes.togglePositive : iconTypes.toggleNegative}/>
                }

                {this.state.customize ?
                  <Field label={'Team Separator'}
                         name={'patternStyle.teamSeparator'}
                         component={ToggleSwitch}/>
                  :
                  <IconField label={'Team Separator'} icon={this.props.unit.patternStyle.teamSeparator ? iconTypes.togglePositive : iconTypes.toggleNegative}/>
                }

                {this.state.customize ?
                  <Field label={'Individual Storage'}
                         name={'patternStyle.individualStorage'}
                         component={ToggleSwitch}/>
                  :
                  <IconField label={'Individual Storage'} icon={this.props.unit.patternStyle.individualStorage ? iconTypes.togglePositive : iconTypes.toggleNegative}/>
                }

                {this.state.customize ?
                  <Field label={'Horizontal Fillers allowed'}
                         name={'patternStyle.horizontalFillersAllowed'}
                         component={ToggleSwitch}/>
                  :
                  <IconField label={'Horizontal Fillers allowed'} icon={this.props.unit.patternStyle.horizontalFillersAllowed ? iconTypes.togglePositive : iconTypes.toggleNegative}/>
                }

              </MenuSection>
              <MenuSection>
                {this.state.customize ?
                  <Field label={'Workplaces per row'}
                         name={'patternStyle.maxWorkplacesPerRow'}
                         component={NumberInput}
                         step={1}
                         digits={0}/> :
                  <DataField label={'Workplaces per row'} data={this.props.unit.patternStyle.maxWorkplacesPerRow}/>}

                {
                  this.state.customize ?
                    <Field label={'Workplaces per bench'}
                           name={'patternStyle.maxWorkplacesPerBench'}
                           component={NumberInput}
                           step={1}
                           digits={0}/> :
                    <DataField label={'Workplaces per bench'} data={this.props.unit.patternStyle.maxWorkplacesPerBench}/>
                }
              </MenuSection>
              {
                this.state.customize ?
                  <div className={'pattern-style-form-buttons'}>
                    <Button type="reset" onClick={() => this.handleDiscard()}>Discard</Button>
                    <Button type="submit" variant={'contained'}>Confirm</Button>
                  </div> :
                  <div className={'pattern-style-form-buttons'}>
                    <Button className={'pattern-style-customized-button'} onClick={() => this.setState({ customize: true })} variant={'contained'}>Customize</Button>
                  </div>
              }
            </Form>

          </Formik>
        </MenuSection>
      </MenuSection>
    )
  }

  handleDiscard () {
    setTimeout(() => this.setState({ customize: false, selectedPatternTypeId: null }), 100)
  }

  handlePatternStyle (unit, patternTypeId) {
    const patternStyle = this.props.patternStyles.find(patternStyle => patternStyle.id === patternTypeId)
    patternStyle.isCustom = false

    this.props.updatePatternStyle(unit.buildingId, unit.floorId, unit.id, patternStyle)
    this.setState({ customize: false })
  }

  handleResetPatternStyle (unit) {
    const patternStyle = this.props.patternStyles.find(patternStyle => patternStyle.id === unit.patternStyle.id)

    if (patternStyle) {
      patternStyle.custom = false
      this.props.updatePatternStyle(unit.buildingId, unit.floorId, unit.id, patternStyle)
    }

  }

  handlePatternTypeSelection (value) {
    this.setState({ selectedPatternTypeId: value })
  }

  handleUpdatePatternStyle (unit, patternStyle) {
    let custom = {
      ...patternStyle,
      custom: true,
    }

    if (this.state.selectedPatternTypeId) {
      custom.patternType = this.props.patternTypes.find(patternType => patternType.id === this.state.selectedPatternTypeId)
    }

    this.props.updatePatternStyle(unit.buildingId, unit.floorId, unit.id, custom)
    this.setState({ customize: false, selectedPatternTypeId: null })
  }
}

let mapStateToProps = (state) => {
  return {
    unit: getSelectedOrganisationalUnit(state),
    patternStyles: getAvailablePatternStyles(state),
    workplaceTypes: state.styles.workplaceTypes,
    patternTypes: state.styles.patternTypes,
  }
}

let mapDispatchToProps = {
  updatePatternStyle: updateOrganisationalUnitPatternStyle,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatternStyle)