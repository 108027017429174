import React from 'react'

const SvgAdjacenciesIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      fill="#373B51"
      fillRule="evenodd"
      d="M9.038 10.566a.72.72 0 010 1.018L7.511 13.11a2.88 2.88 0 104.073 4.073l1.527-1.527a.72.72 0 111.018 1.018l-1.527 1.527a4.32 4.32 0 01-6.11-6.11l1.528-1.526a.72.72 0 011.018 0zm5.091-1.019a.72.72 0 011.019 1.019l-4.582 4.582a.72.72 0 11-1.019-1.019zm4.073-3.054a4.32 4.32 0 010 6.109l-1.527 1.527a.72.72 0 11-1.018-1.018l1.527-1.527a2.88 2.88 0 10-4.073-4.073l-1.527 1.527a.72.72 0 01-1.018-1.018l1.527-1.527a4.32 4.32 0 016.11 0z"
    />
  </svg>
)

export default SvgAdjacenciesIcon
