import { httpClient } from '../../httpClient'
import { getProjectId, projectApi } from '../../utils'
import { FULFILLED, PENDING, REJECTED } from '../../globalConstants'
import { getUndoInformation } from '../undo/undo-actions'

const FETCH_APPLICATION_SETTINGS = 'FETCH_APPLICATION_SETTINGS'
const UPDATE_APPLICATION_SETTINGS = 'UPDATE_APPLICATION_SETTINGS'
const SET_ADVANCED_SETTING = 'SET_ADVANCED_SETTING'
const CLEAR_PROJECT = 'CLEAR_PROJECT'

export const settingsActionTypes = {

  FETCH_APPLICATION_SETTINGS: FETCH_APPLICATION_SETTINGS,
  FETCH_APPLICATION_SETTINGS_PENDING: FETCH_APPLICATION_SETTINGS + PENDING,
  FETCH_APPLICATION_SETTINGS_FULFILLED: FETCH_APPLICATION_SETTINGS + FULFILLED,
  FETCH_APPLICATION_SETTINGS_REJECTED: FETCH_APPLICATION_SETTINGS + REJECTED,

  UPDATE_APPLICATION_SETTINGS: UPDATE_APPLICATION_SETTINGS,
  UPDATE_APPLICATION_SETTINGS_PENDING: UPDATE_APPLICATION_SETTINGS + PENDING,
  UPDATE_APPLICATION_SETTINGS_FULFILLED: UPDATE_APPLICATION_SETTINGS + FULFILLED,
  UPDATE_APPLICATION_SETTINGS_REJECTED: UPDATE_APPLICATION_SETTINGS + REJECTED,

  SET_ADVANCED_SETTING: SET_ADVANCED_SETTING,

  CLEAR_PROJECT: CLEAR_PROJECT,
}

export function updateApplicationSettings (settings) {
  return (dispatch) => {
    dispatch(updateApplicationSettingsApiCall(settings))
      .then(() => {
        dispatch(fetchApplicationSettings())
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function updateApplicationSettingsApiCall (settings) {
  return {
    type: settingsActionTypes.UPDATE_APPLICATION_SETTINGS,
    payload: httpClient.patch(encodeURI(`${projectApi()}/settings`), settings),
  }
}

export function fetchApplicationSettings () {
  return {
    type: settingsActionTypes.FETCH_APPLICATION_SETTINGS,
    payload: httpClient.get(encodeURI(`${projectApi()}/settings`)),
  }
}

export function setAdvancedSettings (advancedSettings = false) {
  return {
    type: settingsActionTypes.SET_ADVANCED_SETTING,
    payload: advancedSettings,
  }
}

