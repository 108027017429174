/*
* Registry for Notification components
*
* Use this to register a specialized Notification Component for a notification type.
*
* NOTE: Usually this is not necessary. You can already configure the notification to be presented to the user in different ways. Please have a look on Notifications section in the storybook.
*/
class NotificationHandlerRegistry {

  constructor () {
    this.registry = new Map()
  }

  registerNotificationComponent (type, component) {
    return this.registry.set(type, component)
  }

  getNotificationComponent (type) {
    return this.registry.get(type)
  }
}

export default new NotificationHandlerRegistry()
