import Button from './Button'
import React, { Fragment } from 'react'
import SvgIcon from './svgIcons/SvgIconFactory'

export default function SvgUploadButton ({ onChange = () => {}, buttonClassName, text = 'Upload', variant = 'outlined', icon, disabled, accept = null }) {
  console.log("icon: "+icon)
  const hiddenFileInput = React.useRef(null)

  return (
    <Fragment>
      <Button className="icon-button" variant={variant} disabled={disabled} onClick={() => hiddenFileInput.current.click()}>
        {text}
        <SvgIcon  name={icon} />
      </Button>
      <input type="file"
             ref={hiddenFileInput}
             accept={accept}
             onChange={(event) => onChange(event)}
             style={{ display: 'none' }}
      />

    </Fragment>
  )
}