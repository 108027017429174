import { uuidv4 } from '../../../common/three/utils'
import { getAngle } from '../helper/GeometryHelper'

export class GraphVertex {
  edgeWithAngle = new Map()

  constructor (position, representationType, geometryUuid) {
    this.uuid = uuidv4()
    this.position = position
    this.representationType = representationType
    this.geometryUuid = geometryUuid
  }

  addEdge (edge) {
    if (!this.edgeWithAngle.has(edge)) {
      const otherVertex = edge.getOtherVertex(this)
      const angle = getAngle(this.position, otherVertex.position)
      this.edgeWithAngle.set(edge, angle)
    }
  }

  removeEdge (edge) {
    if (this.edgeWithAngle.has(edge)) {
      this.edgeWithAngle.delete(edge)
    }
  }

  getEdges () {
    return [...this.edgeWithAngle.keys()]
  }

  getRepresentationType () {
    return this.representationType
  }

  setRepresentationType (representationType) {
    this.representationType = representationType
  }

  isRepresentationType (representationType) {
    return this.representationType === representationType
  }

  getNextEdge (previousEdge) {
    if (!this.edgeWithAngle.has(previousEdge))
      return null

    const previousAngle = this.edgeWithAngle.get(previousEdge)

    const edgesWithAngle = [...this.edgeWithAngle.entries()]

    edgesWithAngle.sort((entryA, entryB) => entryA[1] > entryB[1])

    let nextEdge = edgesWithAngle.find(entry => entry !== previousEdge && entry[1] > previousAngle)

    nextEdge = nextEdge ? nextEdge : edgesWithAngle.find(entry => entry !== previousEdge)

    return nextEdge[0]
    //edgesWithAngle.sort((entryA, entryB) => entryA[1] > entryB[1])
  }

  getNeighbors () {
    return this.getEdges()
      .map(edge => edge.getOtherVertex(this))
  }

  getNeighborsWithEdge () {
    return this.getEdges()
      .map(edge => {return { neighbor: edge.getOtherVertex(this), edge: edge }})
  }
}