import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { Scrollbar } from 'react-scrollbars-custom'

import MenuSection from '../../../common/components/MenuSection'
import ToggleSwitch from '../../../common/components/ToggleSwitch'

import { updatePatternCount, updatePatternPlan } from '../../actions/actions'

import PatternInspectorSwitch from './PatternInspectorSwitch'
import NumberInput from '../../../common/components/NumberInput'
import AutoSave from '../../../common/components/AutoSave'

import './PatternInspector.scss'

class PatternInspector extends Component {
  render () {
    return (
      <div className={'pattern-inspector'}>
        <Scrollbar autoHide>
          <Formik initialValues={this.props.pattern} enableReinitialize>
            <Form>
              <AutoSave delay="0" onSave={(values) => this.handleChange(values)}/>
              <MenuSection title={'Workplaces'}>

                <MenuSection>
                  <Field name={'mirrorX'} component={ToggleSwitch} label={'Row Pairs'}/>
                  <Field name={'mirrorY'} component={ToggleSwitch} label={'Mirror Y'}/>
                </MenuSection>

                <MenuSection>
                  <Field name={'facade'} component={PatternInspectorSwitch} label={'Facade'}/>
                  <Field name={'fill.active'} component={ToggleSwitch} label={'Fill'}/>
                </MenuSection>

                <MenuSection>
                  <Field name={'corridor'} component={PatternInspectorSwitch} label={'Corridor'}/>
                  <Field name={'verticalFaceSeparator.active'} component={ToggleSwitch} label={'Face Seperator'}/>
                  <Field name={'verticalBackSeparator'} component={PatternInspectorSwitch} label={'Back Seperator'}/>
                  <Field name={'horizontalSeparator.active'} component={ToggleSwitch} label={'Side Seperator'}/>
                </MenuSection>

                <MenuSection>
                  <Field name={'workBenchFront'} component={PatternInspectorSwitch} label={'Workbench Front'}/>
                  <Field name={'workBenchBack'} component={PatternInspectorSwitch} label={'Workbench Back'}/>
                </MenuSection>


              </MenuSection>
            </Form>
          </Formik>
          <div className={'menu-separator'}></div>

          <Formik initialValues={this.props.patternCount} enableReinitialize>
            <Form>
              <AutoSave delay="0" onSave={(values) => this.updatePatternCount(values)}/>
              <MenuSection>
                <MenuSection>
                  <Field label={'Repeats'}
                         name={'workplaces'}
                         component={NumberInput}
                         step={1}
                         digits={0}
                  />
                </MenuSection>
              </MenuSection>


            </Form>
          </Formik>
        </Scrollbar>
      </div>
    )
  }

  handleChange (values) {
    this.props.updatePatternPlan(values)
  }

  updatePatternCount (values) {
    this.props.updatePatternCount(values)
  }
}

let mapStateToProps = (state) => {
  return {
    pattern: state.pattern.patternPlan,
    patternCount: state.pattern.patternCount,
  }
}

let mapDispatchToProps = {
  updatePatternPlan: updatePatternPlan,
  updatePatternCount: updatePatternCount,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatternInspector)
