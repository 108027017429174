import { svgIconTypes } from '../../common/components/svgIcons/SvgIconFactory'
import { tools,shortcuts } from '../constants/Tools'
import { getToolHelpInfo }  from '../constants/drawingInfoConstants'

export const svgToolsListNew = [
  {
    name: "Select",
    disabled: false,
    tooltip: "selection tools",
    table:
      [
        {
          icon: svgIconTypes.select,
          tool: tools.select,
          toolname: "Select",
          tooldescription: "Move with mouse, depending on tool",
          tooltip: "select (move with mouse, depending on tool)",
          shortcuts: [shortcuts.esc, shortcuts.pan],
        },
        {
          icon: svgIconTypes.pan,
          tool: tools.pan,
          toolname: "Pan",
          tooldescription: "Move camera with mouse",
          tooltip: "move camera with mouse",
          shortcuts: [],
        },
      ]
  },
  {
    name:"Image",
    disabled:false,
    tooltip: "image tools",
    table:
      [
        {
          icon: svgIconTypes.upload,
          tool: tools.upload,
          toolname: "Upload",
          tooldescription: "The first step for preparing your plan is to upload an image-file. CoDesigner supports PDF, PNG and JPEG file-formats.",
          tooltip: "upload plan (.png, .jpg, .pdf)",
          shortcuts: [shortcuts.esc, shortcuts.pan],
        },
        {
          icon: svgIconTypes.scale,
          tool: tools.scale,
          toolname: "Scale",
          tooldescription: 'Click anywhere on the image to set the first point. After setting the second one, enter their distance.',
          tooltip: "set scale",
          shortcuts: [shortcuts.shift, shortcuts.backspace],
        },
      ]
  },
  {
    name:"Walls",
    disabled:false,
    tooltip: "wall tools",
    table:
      [
        {
          icon: svgIconTypes.outerWalls,
          tool: tools.outline,
          toolname: "Outer Wall",
          tooldescription: "Draw outer wall. Only closed shapes are allowed",
          tooltip: "draw outer wall",
          shortcuts: [shortcuts.shift, shortcuts.ctrl, shortcuts.doubleClickPolygon, shortcuts.enterPolygon,shortcuts.backspace ],
        },
        {
          icon: svgIconTypes.innerWalls,
          tool: tools.wall,
          toolname: "Inner Wall",
          tooldescription: "Draw inner walls to separate the rooms and areas. Beginning and end have to touch other walls",
          tooltip: "draw inner wall",
          shortcuts: [shortcuts.shift, shortcuts.enter, shortcuts.backspace]
        },
      ]
  },
  {
    name:"Details",
    disabled:false,
    tooltip: "detail tools",
    table:
      [
        {
          icon: svgIconTypes.doors,
          tool: tools.door,
          toolname: "Door",
          tooldescription: 'Place doors on an inner wall to connect spaces.',
          tooltip: 'Place doors on an inner wall to connect spaces.',
          shortcuts: [shortcuts.alt],
        },
        {
          icon: svgIconTypes.windows,
          tool: tools.window,
          toolname: "Window",
          tooldescription: "Place windows on the outer wall.",
          tooltip: "Place windows on the outer wall.",
          shortcuts: [shortcuts.alt, shortcuts.ctrlD],
        },
        {
          icon: svgIconTypes.pillars,
          tool: tools.pillar,
          toolname: "Pillar",
          tooldescription: "Put pillar in area by mouse click",
          tooltip: "Put pillar in area by mouse click",
          shortcuts: [shortcuts.alt, shortcuts.ctrlD],
        },
      ]
  },
  {
    name:"Areas",
    disabled:false,
    tooltip: "area tools",
    table:
      [
        {
          icon: svgIconTypes.workArea,
          tool: tools.markzone,
          toolname: "Mark as Zone",
          tooldescription: "mark area as workzone",
          tooltip: "mark area as workzone",
          shortcuts: [],
        },
        {
          icon: svgIconTypes.roomArea,
          tool: tools.markroom,
          toolname: "Mark as Room",
          tooldescription: "mark area as room",
          tooltip: "mark area as room",
          shortcuts: [],
        },
        {
          icon: svgIconTypes.serviceArea,
          tool: tools.markservice,
          toolname: "Mark as Service",
          tooldescription: "mark area as service (e.g. kitchen, toilets, staircase)",
          tooltip: "mark area as service",
          shortcuts: [],
        },
        {
          icon: svgIconTypes.infrastructureArea,
          tool: tools.markinfrastructure,
          toolname: "Mark as Infrastructure",
          tooldescription: "mark area as infrastructure",
          tooltip: "mark area as infrastructure",
          shortcuts: [],
        },
        {
          icon: svgIconTypes.blockedArea,
          tool: tools.markblocked,
          toolname: "Mark as blocked",
          tooldescription: "Click in the spaces to make them areas that are blocked",
          tooltip: "Click in the spaces to make them areas that are blocked",
          shortcuts: [],
        },
      ]
  },
  {
    name:"Axes",
    disabled:false,
    tooltip: "axes tools",
    table:
      [
        {
          icon: svgIconTypes.autoAxes,
          tool: tools.axesautogenerate,
          toolname: "Autogenerate axes",
          tooldescription: "Axes will be autogenerated (default)",
          tooltip: "generate axes in area",
          shortcuts: [],
        },
        {
          icon: svgIconTypes.deleteAxes,
          tool: tools.axesdelete,
          toolname: "Delete axes",
          tooldescription: "Click on area and the axes will be deleted",
          tooltip: "delete axes in area",
          shortcuts: [],
        },
        {
          icon: svgIconTypes.drawAxes,
          tool: tools.axesedit,
          toolname: "Draw axes",
          tooldescription: "Draw axes in area",
          tooltip: "draw axes in area",
          shortcuts: [shortcuts.backspace, shortcuts.enter],
        },
      ]
  },
]