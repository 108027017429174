import React from 'react'

import Text from '../../../../common/components/Text'
import Button from '../../../../common/components/Button'

import './ResetButton.scss'

function ResetButton ({ className, label, onClick, disabled = false }) {

  return (
    <div className={'reset-button ' + className}>
      {label && <Text component={'label'} multiline>
        {label}
      </Text>}
      {!disabled && <Button className={'reset-button-button'} onClick={onClick} variant={'contained'}>Reset</Button>}
    </div>
  )
}

export default ResetButton