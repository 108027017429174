import { SET_DATA_LOADED } from './dataLoader-actions'

let initApp = {
  dataLoaded: false,
}

function dataLoaderReducer (state = initApp, action) {
  switch (action.type) {
    case SET_DATA_LOADED:
      return {
        ...state,
        dataLoaded: action.data,
      }

    default:
      return {
        ...state,
      }
  }

}

export default dataLoaderReducer
