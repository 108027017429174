import * as React from 'react'
const SvgUploadActive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.179 20.4585C20.831 20.8044 21.5765 21.0005 22.3684 21.0005C24.9264 21.0005 27 18.9538 27 16.4291C27 13.9044 24.9264 11.8577 22.3684 11.8577L22.2879 11.8597C21.8017 8.86455 19.1716 6.57617 16 6.57617C12.8284 6.57617 10.1983 8.86455 9.71205 11.8584L9.40042 11.8633C6.94986 11.9822 5 13.9809 5 16.4291C5 18.9538 7.07363 21.0005 9.63158 21.0005C10.4236 21.0005 11.1692 20.8043 11.821 20.4586C12.9394 21.4193 14.4008 22.001 15.9999 22.001C17.5991 22.001 19.0606 21.4192 20.179 20.4585Z" fill="url(#paint0_linear_12_4314)"/>
    <path d="M12.1527 20.2647C11.4274 20.7301 10.5615 21.0005 9.63158 21.0005C7.07363 21.0005 5 18.9538 5 16.4291C5 13.9809 6.94986 11.9822 9.40042 11.8633L9.71205 11.8584C10.1983 8.86455 12.8284 6.57617 16 6.57617C19.1716 6.57617 21.8017 8.86455 22.2879 11.8597L22.3684 11.8577C24.9264 11.8577 27 13.9044 27 16.4291C27 18.9538 24.9264 21.0005 22.3684 21.0005C21.4382 21.0005 20.572 20.7299 19.8463 20.264" stroke="#DA5B2B"/>
    <path d="M16 14.9512L16 26.1431" stroke="#DA5B2B" stroke-linejoin="round"/>
    <path d="M19.5355 18.2504L16 14.7148L12.4645 18.2504" stroke="#DA5B2B" stroke-linejoin="round"/>
    <defs>
      <linearGradient id="paint0_linear_12_4314" x1="5.00037" y1="7.57516" x2="5.00037" y2="21.9995" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
    </defs>
  </svg>
)

export default SvgUploadActive