import React, { useState, Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate, useParams } from 'react-router'
import { Manager, Popper, Reference } from 'react-popper';

import { ScrollMenu } from 'react-horizontal-scrolling-menu';

import { getCurrentVariationId } from '../../common/utils';
import { createVariation, updateVariationId } from '../../common/redux/variations/variations-actions'
import { redoProject, undoProject } from '../../common/redux/undo/undo-actions';

import Text from '../../common/components/Text';
import IconButton from '../../common/components/IconButton';
import VariationsMenu from './VariationsMenu';

import './VariationsNavigation.scss';
import { iconTypes } from '../../common/components/icons/IconFactory';
import { getVariation, getVariationFromVariations } from '../../common/redux/variations/variations-reducer'
import { useLocation } from 'react-router-dom'
import { setQueryParam } from '../../common/redux/router/router-actions'
import { fetchProjectData } from '../../floorplan/actions/actions'
import { setDataLoaded } from '../../common/redux/dataLoader/dataLoader-actions'

const VariationNavigationTabContent = ({ variation: { name }, darkMode }) => {
  return (
    <div className={'scenario-navigation-tab-content' + (darkMode ? ' dark-mode' : '')}>
      <div className='scenario-navigation-tab-text'>
        <span title={name}>{name}</span>
      </div>
    </div>
  )
}

const VariationScrollMenu = (props) => {

  const variationTabs = props.variations.map((variation) => (
    <VariationNavigationTabContent key={variation.id} variation={variation} darkMode={props.darkMode}/>
  ))

  return (
    <div className={'variation-scroll-menu-container' + (props.darkMode ? ' dark-mode' : '')}>
      <ScrollMenu
        data={variationTabs}
        transition={0.3}
        selected={props.selected}
        onSelect={(key) => props.onSelect(key)}
        translate={0}
        itemClass={'scenario-navigation-tab' + (props.darkMode ? ' dark-mode' : '')}
        itemStyle={{ display: 'inline-flex' }}
        useButtonRole={true}
        alignCenter={false}
        scrollToSelected={true}
        dragging={true}
        clickWhenDrag={false}
        wheel={true}/>
    </div>
  )
}

const VariationMenu = (props) => {

  const variationTabs = props.variations.map((variation) => {

    let style = variation.id === props.selected ? ' active' : ''

    style += (props.darkMode ? ' dark-mode' : '')

    return (
      <div key={variation.id} className={'scenario-navigation-tab' + style} role={'button'} onClick={() => props.onSelect(variation.id)}>
        <VariationNavigationTabContent variation={variation} darkMode={props.darkMode}/>
      </div>
    )
  })

  return <ul className={'variation-menu-container' + (props.darkMode ? ' dark-mode' : '')}>
    {variationTabs}
  </ul>
}

function VariationsNavigation() {

  const [showMenu, setShowMenu] = useState(false);
  const [menuState, setMenuState] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();
  const [undoButtonColor, setUndoButtonColor] = useState('#393B53')
  const [redoButtonColor, setRedoButtonColor] = useState('#393B53')

  const {
    buildingId,
    floorId,
    project,
    variations,
    undo,
    redo,
    furnitureEnsembleId,
    patternId,
    advancedSettings,
  } = useSelector(state => ({
    buildingId: state.appState.selectedBuildingId,
    floorId: state.appState.selectedFloorId,
    project: state.projects.project,
    variations: state.variations.variations,
    undo: state.floorPlanUiState.undoInformation.undoAvailable,
    redo: state.floorPlanUiState.undoInformation.redoAvailable,
    furnitureEnsembleId: state.furnitureEnsembles.furnitureEnsemblePlan.id,
    patternId: state.pattern.patternPlan.id,
    advancedSettings: state.settings.appSettings.advancedSettings,
  }));



  /*useEffect(() => {
    setSelected(getVariationFromVariations(variations, getCurrentVariationId(location)))
    console.log(selected)
  }, [location])*/

  useEffect(() => {

    advancedSettings ?
      undo ? setUndoButtonColor('#ffffff') : setUndoButtonColor('#ffffff77' ):
      undo ?  setUndoButtonColor('#C4C6D2') : setUndoButtonColor('#393B53')
    advancedSettings ?
      redo ? setRedoButtonColor('#ffffff') : setRedoButtonColor('#ffffff77') :
      redo ? setRedoButtonColor('#C4C6D2') : setRedoButtonColor('#393B53')
  }, [undo,  redo])

  const handleUndo = () => {
    const selected = getVariationFromVariations(variations, getCurrentVariationId(location))
    dispatch(undoProject(project.id, selected.id, buildingId, floorId, furnitureEnsembleId, patternId))
  }

  const handleRedo = () => {
    const selected = getVariationFromVariations(variations, getCurrentVariationId(location))
    dispatch(redoProject(project.id, selected.id, buildingId, floorId, furnitureEnsembleId, patternId))

  }

  const handleMenuButtonClick = () => {
    let newValue = !showMenu
    setMenuState(newValue)
  }

  const handleClose = () => {
    setMenuState(false)
  }



  const handleAddButtonClick = () => {
    const variation = { name: 'New Variation' };
    dispatch(createVariation(projectId, variation));
  };

  const setSelectedVariation =  (id) => {
    //navigate to current url but with new variation id
    //navigate('?variation=' + id)
    const newSearch = new URLSearchParams(location.search)
    newSearch.set('variation', id)

    // Perform navigation only if necessary
    const updatedSearchString = newSearch.toString()
    if (location.search !== `?${updatedSearchString}`) {
      dispatch(updateVariationId(id))
      navigate(`?${updatedSearchString}`, { replace: true })
      dispatch(setDataLoaded(false))


    }

  }

  function ProjectButtons({ advancedSettings }) {
    return (
      <>
        <IconButton disabled className={'scenario-navigation-plus-button' + (advancedSettings ? ' dark-mode' : '')} icon={iconTypes.plus}/>
        <div className='scenario-navigation-more-button-container'>
          <IconButton disabled className={'scenario-navigation-more-button' + (advancedSettings ? ' dark-mode' : '')} icon={iconTypes.moreVertical}/>
        </div>
      </>
    );
  }


  const selected = getVariationFromVariations(variations, getCurrentVariationId(location))
    return (
      <div className={'scenario-navigation' + (advancedSettings ? ' dark-mode' : '')}>

        <div className={'undoRedo' + (advancedSettings ? ' dark-mode' : '')}>
          <IconButton onClick={() => handleUndo()}
                      icon={iconTypes.undoActive}
                      color={undoButtonColor}
                      forceColor={undoButtonColor}
                      disabled={!undo}/>
          <IconButton onClick={() => handleRedo()}
                      icon={iconTypes.redoActive}
                      color={redoButtonColor}
                      forceColor={redoButtonColor}
                      disabled={!redo}/>
        </div>
        <div className={'project-name-container' + (advancedSettings ? ' dark-mode' : '')}>
          {project && <Text outerClassName={'project-name-text'}>{project.name}</Text>}
        </div>


        {advancedSettings ?
          <Fragment>
            {variations.length > 0 && selected && <>

            {variations.length > 3 ?
              <VariationScrollMenu variations={variations} selected={selected.id} onSelect={(key) => setSelectedVariation(key)} darkMode={advancedSettings}/> :
              <VariationMenu variations={variations} selected={selected.id} onSelect={(key) => setSelectedVariation(key)} darkMode={advancedSettings}/>
            }
            </>
            }

                <IconButton className={'scenario-navigation-plus-button' + (advancedSettings ? ' dark-mode' : '')} icon={iconTypes.add} inverted={advancedSettings}
                            onClick={() => handleAddButtonClick()}/>
                <Manager>
                  <Reference>
                    {({ ref }) => (
                      <div ref={ref} className='scenario-navigation-more-button-container'>
                        <IconButton className={'scenario-navigation-more-button' + (showMenu ? ' active' : '') + (advancedSettings ? ' dark-mode' : '')}
                                    icon={iconTypes.moreVertical}
                                    onClick={() => handleMenuButtonClick()}/>
                      </div>
                    )}
                  </Reference>
                  {showMenu &&
                  <Popper placement="bottom-end">
                    {({ ref, style, placement }) => (
                      <div ref={ref} style={{ ...style, zIndex: 3 }} data-placement={placement}>
                        <VariationsMenu projectId={projectId} variationId={selected.id} onClose={() => handleClose()}/>
                      </div>
                    )}
                  </Popper>}
                </Manager>

          </Fragment>
          : null
        }
      </div>
    )


}



export default VariationsNavigation


