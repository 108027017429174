import React from 'react'

const SvgApplicationProfilesIcon = props => (
  <svg className={props.className} viewBox="0 0 32 32">
    <path d="M0 0h32v32H0z" data-name="Rechteck 323" fill="none"/>
    <path
      d="M14.908 25.734a1.2 1.2 0 0 1 .593-1.049v-1.724H13.28a2.093 2.093 0 0 0-2.008 1.742 1.175 1.175 0 0 1 .544 1.013 1.132 1.132 0 0 1-1.087 1.178 1.131 1.131 0 0 1-1.086-1.173 1.2 1.2 0 0 1 .622-1.072 3.143 3.143 0 0 1 3.012-2.791h5.413a3.131 3.131 0 0 1 3.012 2.791 1.151 1.151 0 0 1 .625 1.067 1.089 1.089 0 1 1-2.172 0 1.175 1.175 0 0 1 .544-1.013 2.093 2.093 0 0 0-2.008-1.742h-2.2v1.724a1.2 1.2 0 0 1 .592 1.049 1.131 1.131 0 0 1-1.088 1.173 1.131 1.131 0 0 1-1.087-1.173zm1.087-5.44h-1.844a8.735 8.735 0 0 1-3.225-.6 4.471 4.471 0 0 1-1.415-.818l-.017-.018a1.034 1.034 0 0 1 .642-1.778h11.717a1.037 1.037 0 0 1 .642 1.778l-.017.018a4.476 4.476 0 0 1-1.413.818 8.743 8.743 0 0 1-3.226.6zM9.38 15.371l-.971-3.751a1.073 1.073 0 0 1 .691-1.333.975.975 0 0 1 1.234.747.943.943 0 0 1 .031.213l.246 3.893a.664.664 0 0 1-.592.729h-.031a.634.634 0 0 1-.608-.498zm12.589.48a.676.676 0 0 1-.593-.728l.247-3.894a.941.941 0 0 1 .032-.213.993.993 0 0 1 1.235-.747 1.1 1.1 0 0 1 .707 1.334l-.971 3.751a.652.652 0 0 1-.622.5zm-5.974-.357h-4.361l-.46-8.266a2.065 2.065 0 0 1 1.974-2.134h5.693a2.065 2.065 0 0 1 1.975 2.134l-.46 8.266z"
      data-name="Vereinigungsmenge 12"
      fill="#454b5a"
    />
  </svg>
)

export default SvgApplicationProfilesIcon
