import React, { Component } from 'react'

import Dialog, { DialogContent } from './Dialog'
import Button from './Button'
import forbiddenScreen from '../../assets/images/illustration-forbidden.svg'

import './NoLicenseDialog.scss'
import { withTranslation } from 'react-i18next'

class NoLicenseDialog extends Component {
  render () {
  const {t} = this.props

    return (
      <Dialog className={'license-dialog'} title={'No License'}
              innerClassName={'license-dialog-content'}>

        <DialogContent>
          <div className={'media-container'}>
            <img alt={'Welcome'} src={forbiddenScreen} className="forbidden-screen"/>
          </div>
          <div className="no-license-warning">{t('no_license')}</div>
          <div className="no-license-text">{t('need_a_license')}</div>
        </DialogContent>

        <div className={'license-buttons'}>
          <Button className={'license-button'} variant={'contained'} onClick={() => this.handleSupport()}>{t('request_access')}</Button>
        </div>
      </Dialog>
    )
  }

  handleOpenModalClick (showModal, component, data) {
    showModal(component, data)
  }

  handleSupport () {
    window.location.href = 'mailto:codesigner@haworth.com'
  }
}

export default withTranslation() (NoLicenseDialog)