import React from 'react'

const SvgQuality = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.fillColor}
        d="M14.297 6.713a.3.3 0 01.227.359l-1.476 6.531a1.64 1.64 0 01-2.55.977 1.638 1.638 0 01-.388-2.284l3.876-5.464a.3.3 0 01.311-.12zm-2.648 6.076a.5.5 0 10-.407.913.5.5 0 00.407-.913z"
      />
      <path
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.657 17.657a8 8 0 10-11.314 0"
      />
    </g>
  </svg>
)

export default SvgQuality
