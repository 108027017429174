import React, { Component } from 'react'
import Text from '../Text'
import IconButton from '../IconButton'

import './FurnitureMenuItemDescription.scss'
import { iconTypes } from '../icons/IconFactory'

class FurnitureMenuItemDescription extends Component {

  constructor (props) {
    super(props)

    this.state = {
      expand: false,
    }

  }

  getDescriptionText (description) {

    if (description.length > 40 && !this.state.expand) {
      description = description.substring(0, 37) + '...'
    }

    return description
  }

  render () {

    const expandStatus = this.state.expand ? ' close' : ' open'
    const outerClassName = 'furniture-menu-item-description-text' + (this.state.expand ? '-max' : '-min')

    const descriptionText = this.getDescriptionText(this.props.description)

    return (
      <div className={'furniture-menu-item-description'}>
        <Text multiline outerClassName={outerClassName} className={'description'}>{descriptionText}</Text>
        {this.props.description.length > 40 && <IconButton className={'furniture-menu-item-description-button' + expandStatus} icon={iconTypes.arrow} onClick={() => this.toggleExpand()}/>}
      </div>
    )
  }

  toggleExpand () {
    this.setState(
      {
        expand: !this.state.expand,
      },
    )
  }
}

export default FurnitureMenuItemDescription
