import { drawingActionTypes } from '../../../buildings/actions/drawingActions'

export const drawingToolUIActionTypes = {
  SET_CURRENT_TOOL: 'SET_CURRENT_TOOL',
  SET_SCALE_FACTOR: 'SET_SCALE_FACTOR',

  STORE_GEOMETRIES: 'STORE_GEOMETRY_CHANGES',
  UNDO_GEOMETRIES: 'UNDO_GEOMETRIES',
  REDO_GEOMETRIES: 'REDO_GEOMETRIES',

  SET_MAX_ONBOARDING_INDEX: 'SET_MAX_ONBOARDING_INDEX',
  SET_DRAWING_HELP: 'SET_DRAWING_HELP'

}

export function setCurrentTool (tool) {
  console.log("current toolll: "+tool)
  return {
    type: drawingToolUIActionTypes.SET_CURRENT_TOOL,
    currentTool: tool,
  }
}

export function setScaleFactor (scaleFactor) {
  console.log("set scale factor")
  return {
    type: drawingToolUIActionTypes.SET_SCALE_FACTOR,
    scaleFactor: scaleFactor,
  }
}

export function storeGeometries (geometries) {
  return {
    type: drawingToolUIActionTypes.STORE_GEOMETRIES,
    payload: geometries,
  }
}

export function undoGeometries () {
  return {
    type: drawingToolUIActionTypes.UNDO_GEOMETRIES,
  }
}

export function redoGeometries () {
  return {
    type: drawingToolUIActionTypes.REDO_GEOMETRIES,
  }
}

export function setMaxOnboardingIndex (index) {
  console.log("SETMAXONBOARDINNNNG: "+index)
  return{
    type: drawingToolUIActionTypes.SET_MAX_ONBOARDING_INDEX,
    payload: index,
  }
}

export function setDrawingHelp (drawingHelp) {
  return {
    type: drawingToolUIActionTypes.SET_DRAWING_HELP,
    payload: drawingHelp,
  }
}


