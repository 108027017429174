import React from 'react'
import SelectButton from './SelectButton'

function StyleSelectButton ({ disabled, field, name, onChange, styles, value }) {

  let mappedOptions = styles.map(style => ({
    label: style.name,
    value: style.id,
  }))

  return (
    <SelectButton disabled={disabled}
                  field={field}
                  name={name}
                  onChange={onChange}
                  value={value}
                  options={mappedOptions}/>
  )
}

export default StyleSelectButton