import { representationTypes } from '../config/RepresentationTypes'

import { outlineConfig } from '../config/OutlineConfig'
import { wallConfig } from '../config/WallConfig'
import { defaultConfig } from '../config/DefaultConfig'

export function getPropertiesByRepresentationType (representationType) {

  switch (representationType) {
    case representationTypes.outline:
      return outlineConfig.properties
    case representationTypes.wall:
      return wallConfig.properties
    default:
      return defaultConfig.properties
  }
}