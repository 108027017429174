import React from 'react'

const SvgMinusButton = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill="none">
      <path d="M0 0h24v24H0z" data-name="Rechteck 711"/>
      <path
        d="M18.863 12.001H5.138"
        data-name="Linie 8"
        stroke={props.color}
        strokeLinecap="round"
        strokeWidth={1.5}
      />
    </g>
  </svg>
)

export default SvgMinusButton
