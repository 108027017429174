import React from 'react'
import CultureLens from './CultureLens'
import OfficeLens from './OfficeLens'

function DepartmentLensToolsFlyOut () {

  return (
    <React.Fragment>
      <CultureLens/>
      <OfficeLens/>
    </React.Fragment>
  )
}

export default DepartmentLensToolsFlyOut

