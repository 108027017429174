import React from 'react'
import { connect as connectFormik } from 'formik'

class AutoSave extends React.Component {

  delayTimer

  componentDidUpdate (prevProps) {
    if (prevProps.formik.values !== this.props.formik.values && this.props.formik.dirty) {
      this.save(this.props.formik.values)
    }
  }

  delay (action) {
    clearTimeout(this.delayTimer)
    let delay = this.props.delay ? this.props.delay : 1000
    this.delayTimer = setTimeout(() => action(), delay)
  }

  save (values) {
    if (this.props.delay <= 0) {
      this.props.onSave(values)
    } else {
      this.delay(() => {
        this.props.onSave(values)
      })
    }
  }

  render () {
    return null
  }

}

export default connectFormik(AutoSave)