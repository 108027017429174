import React from 'react'

const SvgMirrorButton = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <defs>
      <clipPath id="Mirror_Button_svg__a">
        <path fill="none" d="M0 0h24v24H0z"/>
      </clipPath>
    </defs>
    <g opacity={0.33} clipPath="url(#Mirror_Button_svg__a)" stroke={props.color}>
      <g data-name="Ellipse 80" fill="#FFF">
        <circle cx={12} cy={12} r={12} stroke="none"/>
        <circle cx={12} cy={12} r={11.5} fill="none"/>
      </g>
      <g data-name="Gruppe 801" strokeLinecap="round">
        <path data-name="Linie 140" fill="none" d="M12.005 7.672v9.156"/>
        <path
          data-name="Pfad 906"
          d="M7.492 9.753l2.354 2.354-2.354 2.354"
          fill="#FFF"
          strokeLinejoin="round"
        />
        <path
          data-name="Pfad 907"
          d="M16.565 9.753l-2.354 2.354 2.354 2.354"
          fill="#FFF"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
)

export default SvgMirrorButton