import React, { Component } from 'react'
import { connect } from 'react-redux'
import NoLicenseDialog from '../common/components/NoLicenseDialog'

import './DefaultApp.scss'
import StartApp from './StartApp'

class DefaultApp extends Component {

  render () {

    const { hasActiveLicense } = this.props.license

    return (
      <React.Fragment>
        {hasActiveLicense ? <StartApp/> : <NoLicenseDialog/>}
      </React.Fragment>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    license: state.license,
  }
}

export default connect(mapStateToProps)(DefaultApp)
