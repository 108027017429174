import React from 'react'

const DrawScale = props =>
  <svg width={32} height={32} {...props}>
    <g stroke={props.color} fill="none" fillRule="evenodd" strokeLinejoin="round">
      <path d="M7 18.5h18v6H7zM7 24.285V15.5M25 24.285V15.5M7 10.5V8M25 10.5V8M7 14.5V12M25 14.5V12M9 9.5h14"/>
      <path
        strokeLinecap="round"
        d="M10.207 10.914 8.793 9.5l1.414-1.414M22 10.914 23.414 9.5 22 8.086"
      />
    </g>
  </svg>

export default DrawScale