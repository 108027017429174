import React from 'react'

import Inspector from '../Inspector'
import BuildingsFloorsInspector from './BuildingsFloorsInspector'

import FlyOut from '../../../floorplan/components/flyout/FloorPlanFlyOut'

import './Sidebar.scss'

export function Sidebar ({ title, shortVersion }) {
  return (

    <div className="sidebar">
      <FlyOut/>
      <Inspector>
        <BuildingsFloorsInspector title={title} shortVersion={shortVersion}/>
      </Inspector>
    </div>
  )
}

export default Sidebar