import fontFile from '../../assets/fonts/helvetiker_regular.typeface'
import { FontLoader, Geometry, Line, Matrix4, Mesh, MeshBasicMaterial, PlaneGeometry, TextGeometry, Vector3 } from 'three'
import { color, direction } from './const'

export function drawDistanceLines (selectedFurniture, positionDifference, allAnchors, threeSceneParent) {
  let anchorsMID = []

  selectedFurniture.selectorPlane.children.forEach((anchor) => {
    if (anchor.anchorPosition === direction.MID &&
      anchorsMID.length < 4) {
      anchorsMID.push(anchor)
    }
  })

  let topAnchor = getAnchorSide(anchorsMID, direction.TOP, positionDifference)
  let bottomAnchor = getAnchorSide(anchorsMID, direction.BOTTOM, positionDifference)
  let leftAnchor = getAnchorSide(anchorsMID, direction.LEFT, positionDifference)
  let rightAnchor = getAnchorSide(anchorsMID, direction.RIGHT, positionDifference)

  let nextTopAnchor = getNearestAnchorInDirection(topAnchor, selectedFurniture.selectorPlane.children, allAnchors, direction.TOP, positionDifference)
  let nextBottomAnchor = getNearestAnchorInDirection(bottomAnchor, selectedFurniture.selectorPlane.children, allAnchors, direction.BOTTOM, positionDifference)
  let nextLeftAnchor = getNearestAnchorInDirection(leftAnchor, selectedFurniture.selectorPlane.children, allAnchors, direction.LEFT, positionDifference)
  let nextRightAnchor = getNearestAnchorInDirection(rightAnchor, selectedFurniture.selectorPlane.children, allAnchors, direction.RIGHT, positionDifference)

  let anchorArray = [
    [topAnchor, nextTopAnchor, direction.TOP],
    [bottomAnchor, nextBottomAnchor, direction.BOTTOM],
    [leftAnchor, nextLeftAnchor, direction.LEFT],
    [rightAnchor, nextRightAnchor, direction.RIGHT]]

  anchorArray.forEach((anchorTuple) => {
    if (anchorTuple[1]) {

      let startPosition = new Vector3()
        .setFromMatrixPosition(anchorTuple[0].matrixWorld)
        .add(positionDifference)
      let endPosition = new Vector3()
        .setFromMatrixPosition(anchorTuple[1].matrixWorld)

      let distanceLineGeometry = new Geometry()

      let startVector, endVector = null

      switch (anchorTuple[2]) {
        case direction.TOP:
        case direction.BOTTOM:
          startVector = new Vector3(startPosition.x, 0.04, startPosition.z)
          endVector = new Vector3(startPosition.x, 0.04, endPosition.z)

          break
        case direction.LEFT:
        case direction.RIGHT:
          startVector = new Vector3(startPosition.x, 0.04, startPosition.z)
          endVector = new Vector3(endPosition.x, 0.04, startPosition.z)
          break
        default:
          break
      }

      distanceLineGeometry.vertices.push(
        startVector,
        endVector,
      )
      let lineMaterial = new MeshBasicMaterial({ color: color.gizmo, depthWrite: true })
      let line = new Line(distanceLineGeometry, lineMaterial)
      threeSceneParent.add(line)

      let distance = startVector.distanceTo(endVector)

      drawDistanceLabels(new Vector3(endVector.x, endVector.y, endVector.z), distance, anchorTuple[2], threeSceneParent)
    }
  })

}

function getAnchorSide (anchors, anchorDirection, positionDifference) {
  let result = null

  let minValue = +1000000
  let maxValue = -1000000

  anchors.forEach((anchor) => {
    let anchorPosition = new Vector3().setFromMatrixPosition(anchor.matrixWorld)
    anchorPosition.add(positionDifference)

    switch (anchorDirection) {
      case direction.TOP:
        if (anchorPosition.z < minValue) {
          minValue = anchorPosition.z
          result = anchor
        }
        break
      case direction.BOTTOM:
        if (anchorPosition.z > maxValue) {
          maxValue = anchorPosition.z
          result = anchor
        }
        break
      case direction.LEFT:
        if (anchorPosition.x < minValue) {
          minValue = anchorPosition.x
          result = anchor
        }
        break
      case direction.RIGHT:
        if (anchorPosition.x > maxValue) {
          maxValue = anchorPosition.x
          result = anchor
        }
        break
      default:
        break
    }
  })

  return result
}

function getNearestAnchorInDirection (anchor, ignoreAnchor, allAnchors, anchorDirection, positionDifference) {
  let result = null

  let minDistance = 1000000

  let anchorPosition = new Vector3()
    .setFromMatrixPosition(anchor.matrixWorld)
    .add(positionDifference)

  allAnchors.forEach((targetAnchor) => {

    if (!ignoreAnchor.includes(targetAnchor)) {

      let targetAnchorPosition = new Vector3()
        .setFromMatrixPosition(targetAnchor.matrixWorld)

      let distance = anchorPosition.distanceTo(targetAnchorPosition)

      switch (anchorDirection) {
        case direction.TOP:
          if (distance < minDistance && targetAnchorPosition.z < anchorPosition.z) {
            minDistance = distance
            result = targetAnchor
          }
          break
        case direction.BOTTOM:
          if (distance < minDistance && targetAnchorPosition.z > anchorPosition.z) {
            minDistance = distance
            result = targetAnchor
          }
          break
        case direction.LEFT:
          if (distance < minDistance && targetAnchorPosition.x < anchorPosition.x) {
            minDistance = distance
            result = targetAnchor
          }
          break
        case direction.RIGHT:
          if (distance < minDistance && targetAnchorPosition.x > anchorPosition.x) {
            minDistance = distance
            result = targetAnchor
          }
          break
        default:
          break
      }
    }

  })

  return result
}

function drawDistanceLabels (endPositionOfLine, distance, anchorDirection, threeSceneParent) {

  let font = new FontLoader().parse(fontFile)
  let planeRotation = new Matrix4().makeRotationX(-Math.PI / 2)

  distance = Math.round(distance * 100)

  let labelText = new TextGeometry(distance.toString(), {
    font: font,
    size: .1,
    height: 0.01,
    curveSegments: 12,
    bevelEnabled: false,
  })

  let labelTextMaterial = new MeshBasicMaterial({ color: color.gizmo, depthWrite: true })
  let labelObject = new Mesh(labelText, labelTextMaterial)

  labelObject.applyMatrix4(planeRotation)

  let shiftInX = (distance.toString().length - 1) * -0.035

  switch (anchorDirection) {
    case direction.TOP:
      endPositionOfLine.x += shiftInX - 0.05
      endPositionOfLine.z -= 0.05
      break
    case direction.BOTTOM:
      endPositionOfLine.x += shiftInX - 0.05
      endPositionOfLine.z += 0.14
      break
    case direction.LEFT:
      endPositionOfLine.x += shiftInX - 0.22
      endPositionOfLine.z += 0.05
      break
    case direction.RIGHT:
      endPositionOfLine.x += shiftInX + 0.15
      endPositionOfLine.z += 0.05
      break
    default:
      break
  }

  labelObject.position.set(endPositionOfLine.x, endPositionOfLine.y, endPositionOfLine.z)

  threeSceneParent.add(labelObject)

  endPositionOfLine.x -= shiftInX + 0.022

  let labelBackgroundGeometry = new PlaneGeometry(.4, .175, 2, 2)

  let labelBackgroundMaterial = new MeshBasicMaterial({ 'color': color.measureLineLabelBackground, 'transparent': true, 'opacity': 0.8 })
  let labelBackgroundObject = new Mesh(labelBackgroundGeometry, labelBackgroundMaterial)

  labelBackgroundObject.applyMatrix4(planeRotation)

  let labelBackgroundPosition = new Vector3(endPositionOfLine.x, endPositionOfLine.y - 0.0001, endPositionOfLine.z)

  labelBackgroundPosition.z -= 0.05

  switch (anchorDirection) {
    case direction.TOP:
    case direction.BOTTOM:
      labelBackgroundPosition.x += 0.07
      break
    case direction.LEFT:
      labelBackgroundPosition.x += 0.05
      break
    case direction.RIGHT:
      labelBackgroundPosition.x += 0.07
      break
    default:
      break
  }

  labelBackgroundObject.position.set(labelBackgroundPosition.x, labelBackgroundPosition.y, labelBackgroundPosition.z)

  threeSceneParent.add(labelBackgroundObject)

}
