import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import RouteLeavingDialog from './RouteLeavingDialog';

const RouteLeavingGuard = ({ when, onCancel, confirm, lines, withSearch }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const showModal = (nextLocation) => {
    setModalVisible(true);
    setLastLocation(nextLocation);
  };

  const closeModal = (callback) => {
    setModalVisible(false);
    if (onCancel) {
      onCancel();
    }
    if (callback) {
      callback();
    }
  };

  const handleConfirmNavigationClick = () => closeModal(() => {
    if (lastLocation) {
      setConfirmedNavigation(true);
    }
  });

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previously blocked location
      navigate(lastLocation.pathname + (withSearch ? lastLocation.search : ''));
    }
  }, [confirmedNavigation, lastLocation, navigate, withSearch]);

  // Hier kannst du deine Logik für die Bestätigung der Navigation hinzufügen,
  // zum Beispiel durch Anzeigen eines Dialogs, wenn der Nutzer die Seite verlassen möchte.
  // Dies ist nur ein Rahmen und muss angepasst werden, um deinen spezifischen Anforderungen zu entsprechen.

  return (
    <React.Fragment>
      {modalVisible && (
        <RouteLeavingDialog
          onDiscard={handleConfirmNavigationClick}
          onConfirm={() => {
            confirm();
            handleConfirmNavigationClick();
          }}
          onCancel={() => closeModal()}
          lines={lines}
        />
      )}
    </React.Fragment>
  );
};

export default RouteLeavingGuard;
