/* eslint-disable */
import React, { Component } from 'react'
import Measure from 'react-measure'
import equal from 'fast-deep-equal'
import shallowEqual from 'shallowequal'
import PropTypes from 'prop-types'

import dashPattern from '../../../assets/images/png/pattern_distance.png'
import * as THREE from 'three'
import { Color } from 'three'
import OrbitControls from '../../../common/three/orbit'

import { Anchor, FN3DExtended, Gizmo, Room, Selection, Selector, Wall, WallSelector } from '../../../common/three/classes'
import { findNearest } from '../../../common/three/threefunctions'
import { FurniturePlacer } from '../../../common/three/FurniturePlacer'
import { drawDistanceLines } from '../../../common/three/measureLines'
import { color, direction, mouseActionType } from '../../../common/three/const'
import { mapFurnitureListTo3DObjectsXT, uuidv4 } from '../../../common/three/utils'
import { checkCameraPosition } from '../../../common/three/CameraControl'
import { selectedElementType } from '../../reducers/uiState'

import './ApplicationBuilderPlan.scss'

class ApplicationBuilderPlan extends Component {

  componentDidUpdate (prevProps, prevState, snapshot) {


    if (!shallowEqual(this.props.furnitureEnsemblePlan, prevProps.furnitureEnsemblePlan)) {

      this.updateRoomFromValues()
      this.placeFurniture()
      this.updateWalls()
      this.updateWallCodes()

      if (this.SELECTED && this.SELECTED instanceof FN3DExtended) {
        this.SELECTED = this.findFN3DExtendedInSceneByUUID(this.SELECTED.source.uuid)
        if (this.SELECTED)
          this.colorizeParentChild(this.SELECTED, new Color(color.selectionPlanHex))
      }
    }

    if (!equal(this.props.undoRandom, prevProps.undoRandom)) {
      this.updateRoomFromValues()
      this.placeFurniture()
      this.updateWalls()
      this.updateWallCodes()

      if (this.SELECTED && this.SELECTED instanceof FN3DExtended) {
        this.SELECTED = this.findFN3DExtendedInSceneByUUID(this.SELECTED.source.uuid)
        if (this.SELECTED)
          this.colorizeParentChild(this.SELECTED, new Color(color.selectionPlanHex))
      }
    }

    if (!equal(this.props.furnitureEnsemblePlan.id, prevProps.furnitureEnsemblePlan.id)) {
      this.selectObject(null)
    }

    if (!equal(this.props.furnitureLibrary, prevProps.furnitureLibrary)) {
      this.furniture3DObjects = this.mapFurnitureLibrary()
      this.placeFurniture()
    }

    if (!equal(this.props.furnitureMenuItemDragId, prevProps.furnitureMenuItemDragId)) {
      this.createNewFurniture(this.props.furnitureMenuItemDragId)
    }

    if (!equal(this.props.selectedElementId, prevProps.selectedElementId)) {
      if (this.props.selectedElementId.data.length === 0 || this.props.selectedElementId.data.length === undefined) {
        this.selection.visible = false
      }
    }

  }

  componentWillUnmount () {
    let editor = document.getElementById('application-builder')
    let plan = document.getElementById('application-builder-editor-plan')

    plan.removeEventListener(mouseActionType.mousedown, this.onDocumentMouseDown, true)
    editor.removeEventListener(mouseActionType.mousemove, this.onDocumentMouseMove, true)
    editor.removeEventListener(mouseActionType.mouseup, this.onDocumentMouseUp, true)

    document.removeEventListener(mouseActionType.mousemove, this.onGeneralMouseMove, true)
    this.stop()
    this.mount.removeChild(this.renderer.domElement)
  }

  componentDidMount () {
    this.furniture3DObjects = this.mapFurnitureLibrary()

    this.props.onChangeSelectElement(selectedElementType.FLOOR, '')

    this.width = this.mount.clientWidth
    this.height = this.mount.clientHeight
    this.planeRotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2)
    this.textureLoader = new THREE.TextureLoader()

    this.allowedDragAreas = []
    this.selectStage1 = new THREE.Group()
    this.selectStage2 = new Room()
    this.selectStage2.hasOffset = false
    this.selectStage3 = new THREE.Group()
    this.selectStage4 = new THREE.Group()
    this.minMoveDistanceForAction = -0.1
    this.placedNewFurniture = 0
    this.furniturePlacer = new FurniturePlacer()

    this.fnlist = []

    this.mousePositionBeforeClick = new THREE.Vector3()
    this.objectPositionBeforeClick = new THREE.Vector3()
    this.lineMat = new THREE.LineBasicMaterial({ color: color.gizmo })

    this.scene = new THREE.Scene()
    this.onGui = 0
    this.raycaster = new THREE.Raycaster()

    /*
    INTERSECTED: what is hit by the mouse
    HOVER: what is highlighted through intersection
    LASTINTERSECTED: hit by the mouse the frame before
    ACTIVE: Object that is causing an Action
    ACTIVE SELECTOR:
    SELECTED: what is selected
     */

    this.INTERSECTED = null
    this.LASTINTERSECTED = null
    this.INTERSECTIONPOSITION = null
    this.ACTIVE = null
    this.MOUSEACTION = null
    this.SCALEDIRECTION = 1
    this.selectors = []
    this.anchors = []
    this.anchorLines = []

    this.HOVER = null
    this.SELECTED = null
    this.mouse = new THREE.Vector2(1000, 1000)
    //ADD CAMERA
    this.camera = new THREE.PerspectiveCamera(
      75,
      this.width / this.height,
      2,
      50,
    )
    this.camera.position.y = 5
    this.renderer = new THREE.WebGLRenderer({ antialias: true })
    this.renderer.setClearColor(color.white)
    this.renderer.setSize(this.width, this.height)
    let pr = window.devicePixelRatio
    this.renderer.setPixelRatio(pr)
    this.setupMaterials()

    this.renderer.autoClearDepth = false
    this.renderer.depth = false
    this.renderer.sortObjects = false

    this.mount.appendChild(this.renderer.domElement)

    this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement)
    this.orbitControls.maxPolarAngle = 1.5
    this.orbitControls.mouseButtons = { ZOOM: THREE.MOUSE.MIDDLE, PAN: THREE.MOUSE.RIGHT }
    this.orbitControls.enableRotate = false
    this.orbitControls.addEventListener('change', () => {
      checkCameraPosition(this.camera, this.orbitControls, new THREE.Vector3(-15, 2.5, -8), new THREE.Vector3(15, 25, 5))
    })

    this.sceneShifter = new THREE.Group()
    this.scene.add(this.sceneShifter)
    this.selection = new Selection()
    this.selection.visible = false

    this.sceneShifter.add(this.selectStage3)
    this.sceneShifter.add(this.selectStage1)

    this.sceneShifter.add(this.selection)
    this.sceneShifter.add(this.selectStage2)

    this.sceneShifter.add(this.selectStage4)
    this.detachedObjectPositionBeforeClick = new THREE.Vector3

    this.distanceLines = new THREE.Group()
    this.scene.add(this.distanceLines)

    /*
        let TS = new Anchor()
        TS.position.set(-w/2,0,-d/2)
        TS.anchorSide = "TOP"
        TS.anchorPosition = "START"
        this.add(TS)
        TS.selectorPlane = this
        TS.myLine = this.topLine
        TS.refAnchor = TM*/

    this.tline = new THREE.Line()
    this.tline.visible = false
    this.rline = new THREE.Line()
    this.rline.visible = false
    this.bline = new THREE.Line()
    this.bline.visible = false
    this.lline = new THREE.Line()
    this.lline.visible = false
    this.noline = new THREE.Line()

    this.r_TM = new Anchor().setAnchorSide(direction.TOP)
      .setAnchorPosition(direction.MID)
      .setMyLine(this.tline)
      .setRoot()
    this.r_TS = new Anchor().setAnchorSide(direction.TOP)
      .setAnchorPosition(direction.START)
      .setMyLine(this.tline)
      .setRoot()
      .setRefAnchor(this.r_TM)
    this.r_TE = new Anchor().setAnchorSide(direction.TOP)
      .setAnchorPosition(direction.END)
      .setMyLine(this.tline)
      .setRoot()
      .setRefAnchor(this.r_TM)

    this.r_RM = new Anchor().setAnchorSide(direction.RIGHT)
      .setAnchorPosition(direction.MID)
      .setMyLine(this.rline)
      .setRoot()
    this.r_RS = new Anchor().setAnchorSide(direction.RIGHT)
      .setAnchorPosition(direction.START)
      .setMyLine(this.rline)
      .setRoot()
      .setRefAnchor(this.r_RM)
    this.r_RE = new Anchor().setAnchorSide(direction.RIGHT)
      .setAnchorPosition(direction.END)
      .setMyLine(this.rline)
      .setRoot()
      .setRefAnchor(this.r_RM)

    this.r_BM = new Anchor().setAnchorSide(direction.BOTTOM)
      .setAnchorPosition(direction.MID)
      .setMyLine(this.bline)
      .setRoot()
    this.r_BS = new Anchor().setAnchorSide(direction.BOTTOM)
      .setAnchorPosition(direction.START)
      .setMyLine(this.bline)
      .setRoot()
      .setRefAnchor(this.r_BM)
    this.r_BE = new Anchor().setAnchorSide(direction.BOTTOM)
      .setAnchorPosition(direction.END)
      .setMyLine(this.bline)
      .setRoot()
      .setRefAnchor(this.r_BM)

    this.r_LM = new Anchor().setAnchorSide(direction.LEFT)
      .setAnchorPosition(direction.MID)
      .setMyLine(this.lline)
      .setRoot()
    this.r_LS = new Anchor().setAnchorSide(direction.LEFT)
      .setAnchorPosition(direction.START)
      .setMyLine(this.lline)
      .setRoot()
      .setRefAnchor(this.r_LM)
    this.r_LE = new Anchor().setAnchorSide(direction.LEFT)
      .setAnchorPosition(direction.END)
      .setMyLine(this.lline)
      .setRoot()
      .setRefAnchor(this.r_LM)

    this.r_CC = new Anchor().setAnchorSide(direction.CENTER)
      .setAnchorPosition(direction.MID)
      .setMyLine(this.noline)
      .setRoot()

    this.scene.add(this.tline)
    this.scene.add(this.rline)
    this.scene.add(this.bline)
    this.scene.add(this.lline)
    this.scene.add(this.r_TM)
    this.scene.add(this.r_TS)
    this.scene.add(this.r_TE)
    this.scene.add(this.r_BS)
    this.scene.add(this.r_BE)
    this.scene.add(this.r_BM)
    this.scene.add(this.r_LS)
    this.scene.add(this.r_LE)
    this.scene.add(this.r_LM)
    this.scene.add(this.r_RS)
    this.scene.add(this.r_RE)
    this.scene.add(this.r_RM)
    this.scene.add(this.r_CC)

    this.mat = new THREE.MeshBasicMaterial({ color: color.white })
    this.geo = new THREE.PlaneGeometry(1, 1, 2, 2)
    this.roomMin = new Room(this.geo, this.mat)
    this.roomMin.applyMatrix(this.planeRotation)
    let roomCatcherGeometry = new THREE.BoxBufferGeometry(1, 1, 1)
    let roomCatcherMaterial = new THREE.MeshBasicMaterial({ 'color': color.roomCatcher, 'transparent': true, 'opacity': 0 })

    this.roomCatcher = new THREE.Mesh(roomCatcherGeometry, roomCatcherMaterial)
    this.roomCatcher.position.y = -1
    this.sceneShifter.add(this.roomCatcher)

    this.allowedDragAreas.push(this.roomCatcher)
    this.roomMin.matrixWorldNeedsUpdate = true

    this.selectStage1.add(this.roomMin)

    let lineDashedMaterial = new THREE.LineDashedMaterial({
      color: color.lineDash,
      dashSize: 3,
      gapSize: 1,
      scale: 1,
    })
    let lineGeometry = new THREE.Geometry()
    lineGeometry.vertices.push(
      new THREE.Vector3(-1, 0.01, -1),
      new THREE.Vector3(1, 0.01, -1),
      new THREE.Vector3(1, 0.01, 1),
      new THREE.Vector3(-1, 0.01, 1),
      new THREE.Vector3(-1, 0.01, -1),
    )
    this.roomMax = new THREE.Line(lineGeometry, lineDashedMaterial)

    let roomMinBorderGeometry = new THREE.Geometry()
    roomMinBorderGeometry.vertices.push(
      new THREE.Vector3(-0.5, 0.01, -.5),
      new THREE.Vector3(0.5, 0.01, -0.5),
      new THREE.Vector3(0.5, 0.01, 0.5),
      new THREE.Vector3(-0.5, 0.01, 0.5),
      new THREE.Vector3(-0.5, 0.01, -0.5),
    )
    let roomMinBorderMaterial = new THREE.LineBasicMaterial({ 'color': color.roomMinBorder })
    this.roomMinBorder = new THREE.Line(roomMinBorderGeometry, roomMinBorderMaterial)
    this.selectStage3.add(this.roomMax)
    this.selectStage3.add(this.roomMinBorder)

    let selectorColor = color.gizmo
    let rsmat = new THREE.MeshBasicMaterial({ color: selectorColor, depthWrite: false })
    let rslmat = new THREE.LineBasicMaterial({ color: selectorColor, depthWrite: false })
    let rsgeo = new THREE.Shape()
    let size = 0.07
    let offset = 0.03

    let point1 = new THREE.Vector3(size, -offset, 0)
    let point2 = new THREE.Vector3(0, -offset - size, 0)
    let point3 = new THREE.Vector3(-size, -offset, 0)

    rsgeo.moveTo(point1.x, point1.y)
    rsgeo.lineTo(point2.x, point2.y)
    rsgeo.lineTo(point3.x, point3.y)
    rsgeo.lineTo(point1.x, point1.y)

    let lineGeo = new THREE.Geometry()
    lineGeo.vertices.push(point1, point2, point3, point1)
    let shapeGeo = new THREE.ShapeGeometry(rsgeo)

    this.roomScalerTop = new THREE.Line(lineGeo, rslmat)
    this.roomScalerBottom = new THREE.Line(lineGeo, rslmat)
    this.roomScalerLeft = new THREE.Line(lineGeo, rslmat)
    this.roomScalerRight = new THREE.Line(lineGeo, rslmat)

    this.roomScalerTop.visible = false
    this.roomScalerBottom.visible = false
    this.roomScalerLeft.visible = false
    this.roomScalerRight.visible = false

    this.roomScalerTopFace = new THREE.Mesh(shapeGeo, rsmat)
    this.roomScalerBottomFace = new THREE.Mesh(shapeGeo, rsmat)
    this.roomScalerLeftFace = new THREE.Mesh(shapeGeo, rsmat)
    this.roomScalerRightFace = new THREE.Mesh(shapeGeo, rsmat)

    this.roomScalerTopFace.visible = false
    this.roomScalerBottomFace.visible = false
    this.roomScalerLeftFace.visible = false
    this.roomScalerRightFace.visible = false

    this.roomScalerTop.add(this.roomScalerTopFace)
    this.roomScalerBottom.add(this.roomScalerBottomFace)
    this.roomScalerLeft.add(this.roomScalerLeftFace)
    this.roomScalerRight.add(this.roomScalerRightFace)

    this.roomScalerTop.applyMatrix(this.planeRotation)
    this.roomScalerBottom.applyMatrix(this.planeRotation)
    this.roomScalerBottom.rotateOnAxis(new THREE.Vector3(0, 0, 1), Math.PI)
    this.roomScalerLeft.applyMatrix(this.planeRotation)
    this.roomScalerRight.applyMatrix(this.planeRotation)
    this.roomScalerLeft.rotateOnAxis(new THREE.Vector3(0, 0, 1), Math.PI / 2)
    this.roomScalerRight.rotateOnAxis(new THREE.Vector3(0, 0, 1), -Math.PI / 2)

    this.sceneShifter.add(this.roomScalerTop)
    this.sceneShifter.add(this.roomScalerBottom)
    this.sceneShifter.add(this.roomScalerLeft)
    this.sceneShifter.add(this.roomScalerRight)

    this.updateRoomFromValues()

    let editor = document.getElementById('application-builder')
    let plan = document.getElementById('application-builder-editor-plan')
    plan.addEventListener(mouseActionType.mousedown, this.onDocumentMouseDown, true)
    editor.addEventListener(mouseActionType.mousemove, this.onDocumentMouseMove, true)
    editor.addEventListener(mouseActionType.mouseup, this.onDocumentMouseUp, true)

    document.addEventListener(mouseActionType.mousemove, this.onGeneralMouseMove, true)

    let cpgeo = new THREE.PlaneGeometry(1000, 1000, 8)
    let cpmat = new THREE.MeshBasicMaterial({ color: color.yellow, visible: false })

    this.catchers = new THREE.Group()
    this.scene.add(this.catchers)
    this.catchplane = new THREE.Mesh(cpgeo, cpmat)

    this.catchplane.applyMatrix(this.planeRotation)
    this.catchers.add(this.catchplane)

    this.gizmo = new Gizmo()
    this.gizmo.visible = false
    this.selectStage4.add(this.gizmo)

    this.anchors = []
    this.anchorLines = []

    this.dockToAnchor = null

    this.start()
  }

  mapFurnitureLibrary () {
    return this.props.furnitureLibrary ? mapFurnitureListTo3DObjectsXT(this.props.furnitureLibrary) : []
  }

  setupMaterials () {
    // Dashed Areas

    this.dashTexture = this.textureLoader.load(dashPattern, function (texture) {
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping
      texture.offset.set(0, 0)
      texture.repeat.set(1, 1)
    })
    this.dashedAreaMaterial = new THREE.MeshBasicMaterial({ depthWrite: true, color: color.white, map: this.dashTexture })
  }

  onGeneralMouseMove = (event) => {
    if (this.MOUSEACTION === mouseActionType.dragNewElement) {
      this.SELECTED.position.set(this.INTERSECTIONPOSITION.x, 0.05, this.INTERSECTIONPOSITION.z)
      this.findDockingPoints()
    }
  }

  limitSteps = (value) => {
    let threshold = 25
    let stepSize = 100
    value *= 100
    let nearest = Math.round(value / stepSize)
    let roundedValue = (nearest * stepSize)
    if (Math.abs((value - roundedValue)) < threshold) return roundedValue / 100
    else return value / 100
  }

  onDocumentMouseMove = (event) => {

    this.mouse.x = ((event.clientX - this.sidebar) / this.width) * 2 - 1
    this.mouse.y = -((event.clientY - this.topbar) / this.height) * 2 + 1

    let direction = 1
    let angle
    let axis
    let rotatedVector

    this.distanceLines.children = []

    switch (this.MOUSEACTION) {
      case mouseActionType.roomDepth:

        let z = -this.INTERSECTIONPOSITION.z + this.sceneCoordsAtMouseBeforeClick.z
        if (Math.abs(z) > this.minMoveDistanceForAction) {
          let depth = this.limitSteps(Math.abs(this.initialRoomSize.y * this.SCALEDIRECTION + z * 2))
          this.roomMin.scale.set(this.roomMin.scale.x, depth, 1)

          this.roomMinBorder.scale.set(this.roomMin.scale.x, 1, depth)
        } else {
          this.roomMin.scale.set(this.roomMin.scale.x, this.initialRoomSize.y, 1)

          this.roomMinBorder.scale.set(this.roomMin.scale.x, 1, this.initialRoomSize.y)
        }

        this.updateWalls()
        break
      case mouseActionType.roomWidth:

        let x = this.INTERSECTIONPOSITION.x - this.sceneCoordsAtMouseBeforeClick.x
        if (Math.abs(x) > this.minMoveDistanceForAction) {
          let width = this.limitSteps(Math.abs(this.initialRoomSize.x + x * 2 * this.SCALEDIRECTION))
          this.roomMin.scale.set(width, this.roomMin.scale.y, 1)
          this.roomMinBorder.scale.set(width, 1, this.roomMin.scale.y)
        } else {
          this.roomMin.scale.set(this.initialRoomSize.x, this.roomMin.scale.y, 1)
          this.roomMinBorder.scale.set(this.initialRoomSize.x, 1, this.roomMin.scale.y)
        }

        this.updateWalls()
        break
      case mouseActionType.furnXPos:
        this.positionDifference = new THREE.Vector3()
        this.positionDifference.add(this.INTERSECTIONPOSITION)
        this.positionDifference.sub(this.sceneCoordsAtMouseBeforeClick)

        angle = this.SELECTED.parentDegrees + this.SELECTED.getAngle(-this.SELECTED.rotationInherited + this.SELECTED.rotationAtParent + this.SELECTED.rotationSelf)

        // Rotate Screen Coordinates against Gizmo and eliminate  unuse values, then rotate back
        axis = new THREE.Vector3(0, 1, 0)
        this.positionDifference.applyAxisAngle(axis, -angle)
        this.positionDifference.z = 0
        this.positionDifference.y = 0
        this.positionDifference.applyAxisAngle(axis, +angle)

        rotatedVector = this.positionDifference

        this.gizmo.position.set(this.detachedGizmoPositionBeforeClick.x + rotatedVector.x, this.detachedGizmoPositionBeforeClick.y + rotatedVector.y,
          this.detachedGizmoPositionBeforeClick.z + rotatedVector.z)
        this.SELECTED.position.set(this.detachedObjectPositionBeforeClick.x + rotatedVector.x, this.detachedObjectPositionBeforeClick.y + rotatedVector.y,
          this.detachedObjectPositionBeforeClick.z + rotatedVector.z)
        this.selection.position.set(this.selectionPositionBeforeClick.x + this.positionDifference.x, this.selectionPositionBeforeClick.y,
          this.selectionPositionBeforeClick.z + this.positionDifference.z)

        drawDistanceLines(
          this.SELECTED,
          this.positionDifference,
          this.anchors,
          this.distanceLines,
        )
        break
      case mouseActionType.furnZPos:
        this.positionDifference = new THREE.Vector3()
        this.positionDifference.add(this.INTERSECTIONPOSITION)
        this.positionDifference.sub(this.sceneCoordsAtMouseBeforeClick)

        angle = this.SELECTED.parentDegrees + this.SELECTED.getAngle(-this.SELECTED.rotationInherited + this.SELECTED.rotationAtParent + this.SELECTED.rotationSelf)
        axis = new THREE.Vector3(0, 1, 0)

        this.positionDifference.applyAxisAngle(axis, -angle)
        this.positionDifference.x = 0
        this.positionDifference.y = 0
        this.positionDifference.applyAxisAngle(axis, +angle)

        rotatedVector = this.positionDifference

        this.gizmo.position.set(this.detachedGizmoPositionBeforeClick.x + rotatedVector.x, this.detachedGizmoPositionBeforeClick.y + rotatedVector.y,
          this.detachedGizmoPositionBeforeClick.z + rotatedVector.z)
        this.SELECTED.position.set(this.detachedObjectPositionBeforeClick.x + rotatedVector.x, this.detachedObjectPositionBeforeClick.y + rotatedVector.y,
          this.detachedObjectPositionBeforeClick.z + rotatedVector.z)
        this.selection.position.set(this.selectionPositionBeforeClick.x + this.positionDifference.x, this.selectionPositionBeforeClick.y,
          this.selectionPositionBeforeClick.z + this.positionDifference.z)

        drawDistanceLines(
          this.SELECTED,
          this.positionDifference,
          this.anchors,
          this.distanceLines,
        )
        break
      case mouseActionType.furnFreePos:
        this.positionDifference = new THREE.Vector3()
        this.positionDifference.add(this.INTERSECTIONPOSITION)
        this.positionDifference.sub(this.sceneCoordsAtMouseBeforeClick)
        let newVector = this.detachedObjectPositionBeforeClick.clone()
        newVector.add(this.positionDifference)

        //this.gizmo.position.set(this.INTERSECTIONPOSITION.x, this.gizmoPositionBeforeClick.y - this.positionDifference.y, this.INTERSECTIONPOSITION.z)
        this.gizmo.position.set(newVector.x, newVector.y, newVector.z)
        this.SELECTED.position.set(newVector.x, newVector.y, newVector.z)
        this.selection.position.set(this.selectionPositionBeforeClick.x + this.positionDifference.x, this.selectionPositionBeforeClick.y,
          this.selectionPositionBeforeClick.z + this.positionDifference.z)

        this.findDockingPoints()
        break
    }
  }

  updateRoomCatcher = () => {
    this.roomCatcher.geometry = new THREE.BoxGeometry(parseFloat(this.props.furnitureEnsemblePlan.minWidth) + 0.2, 0.1, parseFloat(this.props.furnitureEnsemblePlan.minDepth) + 0.2)
    this.allowedDragAreas = []
    this.roomCatcher.updateMatrix()
    this.allowedDragAreas.push(this.roomCatcher)
  }

  getNewFurnitureId = () => {
    this.placedNewFurniture++
    return this.placedNewFurniture
  }

  resetAllAnchors = () => {
    //hide all Anchors
    for (let p = 0; p < this.anchors.length; p++) {
      this.anchors[p].visible = false
      this.anchors[p].myLine.visible = false
    }
  }

  findDockingPoints = () => {
    this.resetAllAnchors()

    let searchpoints = [this.INTERSECTIONPOSITION]

    let nearestAnchors = findNearest(searchpoints, this.anchors, this.SELECTED.selectorPlane)

    let dockTo
    if (nearestAnchors.length > 1) {
      let lines = []
      for (let z = 0; z < nearestAnchors.length; z++) {
        if (nearestAnchors[z].refAnchor != null) {
          lines.push(nearestAnchors[z].refAnchor)
        } else {
          dockTo = nearestAnchors[0]
        }
      }

      let temp = findNearest([this.INTERSECTIONPOSITION], lines, null)[0]

      for (let z = 0; z < nearestAnchors.length; z++) {
        if (nearestAnchors[z].refAnchor === temp) {
          dockTo = nearestAnchors[z]
        }
      }

    } else {
      dockTo = nearestAnchors[0]
    }

    //highlight anchors

    if (dockTo) {
      dockTo.visible = true
      dockTo.myLine.visible = true
      this.dockToAnchor = dockTo
    }
  }

  onDocumentMouseUp = (event) => {
    this.positionDifference = new THREE.Vector3()
    this.positionDifference.add(this.INTERSECTIONPOSITION)
    if (this.sceneCoordsAtMouseBeforeClick instanceof THREE.Vector3) {

      this.positionDifference.sub(this.sceneCoordsAtMouseBeforeClick)
    }

    if (this.MOUSEACTION === mouseActionType.roomDepth) {

      let sizes = {
        'maxDepth': this.props.furnitureEnsemblePlan.maxDepth,
        'maxWidth': this.props.furnitureEnsemblePlan.maxWidth,
        'minDepth': this.roomMin.scale.y,
        'minWidth': this.props.furnitureEnsemblePlan.minWidth,
      }
      this.props.storeApplicationBuilderUndo()
      this.props.onChangeFurnitureEnsembleSize(sizes)
      this.updateRoomCatcher()
    }
    if (this.MOUSEACTION === mouseActionType.roomWidth) {

      let sizes = {
        'maxDepth': this.props.furnitureEnsemblePlan.maxDepth,
        'maxWidth': this.props.furnitureEnsemblePlan.maxWidth,
        'minDepth': this.props.furnitureEnsemblePlan.minDepth,
        'minWidth': this.roomMin.scale.x,
      }
      this.props.storeApplicationBuilderUndo()
      this.props.onChangeFurnitureEnsembleSize(sizes)
      this.updateRoomCatcher()
    }

    if ((this.MOUSEACTION === mouseActionType.furnXPos) || (this.MOUSEACTION === mouseActionType.furnZPos)) {
      let angle = this.SELECTED.parentDegrees + this.SELECTED.getAngle(-this.SELECTED.rotationInherited + this.SELECTED.rotationAtParent + this.SELECTED.rotationSelf)
      let axis = new THREE.Vector3(0, 1, 0)
      if (this.MOUSEACTION === mouseActionType.furnZPos) {
        this.positionDifference.applyAxisAngle(axis, -angle)
        this.positionDifference.x = 0
        this.positionDifference.y = 0
        this.positionDifference.applyAxisAngle(axis, +angle)
      }
      if (this.MOUSEACTION === mouseActionType.furnXPos) {
        this.positionDifference.applyAxisAngle(axis, -angle)
        this.positionDifference.z = 0
        this.positionDifference.y = 0
        this.positionDifference.applyAxisAngle(axis, +angle)
      }

      this.SELECTED.attach(this.scene, this.parentKeeper)

      let rotatedVector = this.SELECTED.rotateVectorForPlacement(this.positionDifference)

      let position = {
        'posX': -rotatedVector.x,
        'posY': rotatedVector.y,
        'posZ': rotatedVector.z,
      }

      this.deleteOnDropOut()

      if (this.positionDifference.length() > 0.01 && this.SELECTED) {
        this.props.storeApplicationBuilderUndo()
        this.props.onChangeFurniturePosition(position, this.SELECTED.source.uuid)

      }

      this.placeFurniture()
    }

    if (this.MOUSEACTION === mouseActionType.furnFreePos) {
      this.props.storeApplicationBuilderUndo()
      this.scene.remove(this.SELECTED)
      let newParent

      if (this.dockToAnchor instanceof Anchor) {
        this.dockToAnchor.visible = false
        if (!this.dockToAnchor.root)
          newParent = this.dockToAnchor.parent.linkedObject.source.uuid


        this.props.onChangeFurnitureParent(newParent, this.SELECTED.source.uuid, this.dockToAnchor.anchorSide, this.dockToAnchor.anchorPosition, this.dockToAnchor.root)
        this.dockToAnchor = null

      }

      this.deleteOnDropOut()
      this.placeFurniture()
    }

    if (this.MOUSEACTION === mouseActionType.dragNewElement) {
      this.props.storeApplicationBuilderUndo()
      this.scene.remove(this.SELECTED)
      let newParent

      if (this.dockToAnchor instanceof Anchor) {
        if (!this.dockToAnchor.root) newParent = this.dockToAnchor.parent.linkedObject.source.uuid

        this.props.onAddNewFurniture(newParent, this.SELECTED.source.type, this.SELECTED.source.uuid, this.dockToAnchor.anchorSide, this.dockToAnchor.anchorPosition, this.dockToAnchor.root,
          this.SELECTED.source.constructor)
        this.dockToAnchor = null
      }
      this.deleteOnDropOut()
      this.placeFurniture()
      this.props.updateFurnitureMenuItemDragId(null)

    }

    if (this.SELECTED instanceof FN3DExtended) {
      this.SELECTED = this.findFN3DExtendedInSceneByUUID(this.SELECTED.source.uuid)
      this.colorizeParentChild(this.SELECTED, new Color(color.selectionPlanHex))
    }

    this.MOUSEACTION = null
    this.orbitControls.enabled = true
    this.props.getUndoAvailable()

  }

  onDocumentMouseDown = (event) => {

    this.mousePositionBeforeClick.x = this.mouse.x
    this.mousePositionBeforeClick.y = this.mouse.y

    // Furniture
    if (this.HOVER instanceof Selector) {
      this.selectObject(this.HOVER.linkedObject)
      this.props.onChangeSelectElement(selectedElementType.FURNITURE, this.SELECTED.source.uuid)
    }
    // Wall
    else if (this.HOVER instanceof WallSelector) {
      this.selectObject(this.HOVER)
      this.props.onChangeSelectElement(selectedElementType.WALL, this.HOVER.parent.wallPosition)
    }
    // Floor
    else if (this.HOVER instanceof Room || this.INTERSECTED === null) {
      this.selectObject(null)

      this.props.onChangeSelectElement(selectedElementType.FLOOR, '')
    }

    // if a Resizer is active and no other Object is selected
    if (this.RESIZER !== null && this.ACTIVE === null && !this.props.roomSizeLocked) {

      if (this.MOUSEACTION === null) {
        this.sceneCoordsAtMouseBeforeClick = this.INTERSECTIONPOSITION.clone()
        this.initialRoomSize = this.roomMin.scale.clone()

        if ((this.RESIZER === this.roomScalerTop) || (this.RESIZER === this.roomScalerBottom)) {
          this.MOUSEACTION = mouseActionType.roomDepth
          if (this.RESIZER === this.roomScalerBottom) {
            this.SCALEDIRECTION = -1
          } else {
            this.SCALEDIRECTION = 1
          }

          this.orbitControls.enabled = false
          if (!this.SELECTED instanceof WallSelector)
            this.selectObject(null)
        }
        if ((this.RESIZER === this.roomScalerLeft) || (this.RESIZER === this.roomScalerRight)) {
          this.MOUSEACTION = mouseActionType.roomWidth
          if (this.RESIZER === this.roomScalerLeft) {
            this.SCALEDIRECTION = -1
          } else {
            this.SCALEDIRECTION = 1
          }

          this.orbitControls.enabled = false
          if (!this.SELECTED instanceof WallSelector)
            this.selectObject(null)
        }
      }
    }

    if (this.ACTIVE !== null && this.ACTIVE === this.gizmo) {

      if (this.MOUSEACTION === null) {

        this.sceneCoordsAtMouseBeforeClick = this.INTERSECTIONPOSITION.clone()

        if (this.ACTIVE.linkedObject) {
          this.objectPositionBeforeClick = this.ACTIVE.linkedObject.position.clone()
          this.gizmoPositionBeforeClick = this.gizmo.position.clone()
          this.selectionPositionBeforeClick = this.selection.position.clone()
        }

        let ag = this.gizmo.getNearest(this.INTERSECTIONPOSITION)
        if ((ag === this.gizmo.ScalerTop) || (ag === this.gizmo.ScalerBottom)) {
          this.MOUSEACTION = mouseActionType.furnZPos
          this.orbitControls.enabled = false
          this.parentKeeper = this.SELECTED.parent

          this.SELECTED.detach(this.SELECTED.parent, this.scene)
          this.gizmo.detach(this.gizmo.parent, this.scene)
          this.detachedObjectPositionBeforeClick = this.SELECTED.position.clone()
          this.detachedGizmoPositionBeforeClick = this.gizmo.position.clone()

        }
        if ((ag === this.gizmo.ScalerLeft) || (ag === this.gizmo.ScalerRight)) {
          this.MOUSEACTION = mouseActionType.furnXPos
          this.orbitControls.enabled = false
          this.parentKeeper = this.SELECTED.parent

          this.SELECTED.detach(this.SELECTED.parent, this.scene)
          this.gizmo.detach(this.gizmo.parent, this.scene)
          this.detachedObjectPositionBeforeClick = this.SELECTED.position.clone()
          this.detachedGizmoPositionBeforeClick = this.gizmo.position.clone()
        }
        if ((ag === this.gizmo.mover) || (ag === this.gizmo.mover)) {
          this.MOUSEACTION = mouseActionType.furnFreePos
          this.SELECTED.selectorPlane.removeAnchors(this.anchors)

          this.setOpacityOfMovingFurnitureByGizmo(this.gizmo)

          this.SELECTED.detach(this.SELECTED.parent, this.scene)
          this.gizmo.detach(this.gizmo.parent, this.scene)
          this.gizmo.position.x = this.SELECTED.position.x
          this.gizmo.position.z = this.SELECTED.position.z
          this.detachedObjectPositionBeforeClick = this.SELECTED.position.clone()
          this.detachedGizmoPositionBeforeClick = this.gizmo.position.clone()
          this.orbitControls.enabled = false
        }

        if (this.INTERSECTED) this.setHover(this.INTERSECTED.linkedObject)

      }
    }

  }

  setOpacityOfMovingFurnitureByGizmo (gizmoObj) {
    this.furniturePlacer.setOpacityOfFurniture(gizmoObj.linkedObject)
    gizmoObj.visible = false
    this.selection.visible = false
  }

  deleteOnDropOut () {
    if (this.SELECTED instanceof FN3DExtended) {
      let vector = new THREE.Vector3()

      vector.setFromMatrixPosition(this.SELECTED.matrixWorld)
      vector.y = 1

      let dir = new THREE.Vector3(0, -1, 0)
      let rc = new THREE.Raycaster()
      rc.set(vector, dir)

      let intersectsX = rc.intersectObjects(this.allowedDragAreas, false)
      if (intersectsX.length <= 0) {
        this.props.deleteFurniture(this.SELECTED.source.uuid)

        if (this.gizmo) {
          this.gizmo.visible = false
        }

        this.selection.visible = false
        this.resetAllAnchors()
        this.props.onChangeSelectElement(selectedElementType.FLOOR, '')
      }
    }
  }

  adjustRoomScalers () {
    this.roomScalerTop.position.set(0, 0.01, -this.roomMin.scale.y / 2)
    this.roomScalerBottom.position.set(0, 0.01, this.roomMin.scale.y / 2)
    this.roomScalerLeft.position.set(-this.roomMin.scale.x / 2, 0.01, 0)
    this.roomScalerRight.position.set(this.roomMin.scale.x / 2, 0.01, 0)

  }

  updateRoomFromValues () {
    this.roomMin.scale.set(this.props.furnitureEnsemblePlan.minWidth, this.props.furnitureEnsemblePlan.minDepth, 1)
    this.roomMinBorder.scale.set(this.props.furnitureEnsemblePlan.minWidth, 1, this.props.furnitureEnsemblePlan.minDepth)
    this.roomMin.updateMatrix()
    this.updateRoomAnchors()

    let maxWidth = this.props.furnitureEnsemblePlan.maxWidth / 2
    let maxDepth = this.props.furnitureEnsemblePlan.maxDepth / 2

    let diffWidth = (this.props.furnitureEnsemblePlan.maxWidth - this.props.furnitureEnsemblePlan.minWidth) / 2
    let diffDepth = (this.props.furnitureEnsemblePlan.maxDepth - this.props.furnitureEnsemblePlan.minDepth) / 2

    this.roomMax.geometry.vertices[0] = new THREE.Vector3(-maxWidth + diffWidth, 0.01, -maxDepth - diffDepth)
    this.roomMax.geometry.vertices[1] = new THREE.Vector3(maxWidth + diffWidth, 0.01, -maxDepth - diffDepth)
    this.roomMax.geometry.vertices[2] = new THREE.Vector3(maxWidth + diffWidth, 0.01, maxDepth - diffDepth)
    this.roomMax.geometry.vertices[3] = new THREE.Vector3(-maxWidth + diffWidth, 0.01, maxDepth - diffDepth)
    this.roomMax.geometry.vertices[4] = new THREE.Vector3(-maxWidth + diffWidth, 0.01, -maxDepth - diffDepth)
    this.roomMax.geometry.verticesNeedUpdate = true
    this.createWalls()
    this.updateRoomCatcher()

  }

  createWalls () {
    //setup Selectors
    this.wallSelectors = []
    this.selectStage3.children = []

    this.wallBottom = new Wall(0, this.dashedAreaMaterial)
    this.selectStage3.add(this.wallBottom)
    this.wallSelectors.push(this.wallBottom.selector)

    this.wallLeft = new Wall(1, this.dashedAreaMaterial)
    this.selectStage3.add(this.wallLeft)
    this.wallSelectors.push(this.wallLeft.selector)

    this.wallTop = new Wall(2, this.dashedAreaMaterial)
    this.selectStage3.add(this.wallTop)
    this.wallSelectors.push(this.wallTop.selector)

    this.wallRight = new Wall(3, this.dashedAreaMaterial)
    this.selectStage3.add(this.wallRight)
    this.wallSelectors.push(this.wallRight.selector)

    this.selectStage3.add(this.roomMax)
    this.selectStage3.add(this.roomMinBorder)

    this.updateWalls()
    this.updateWallCodes()
  }

  updateWalls () {

    this.wallTop.setSize(this.roomMin)
    this.wallLeft.setSize(this.roomMin)
    this.wallRight.setSize(this.roomMin)
    this.wallBottom.setSize(this.roomMin)

  }

  updateWallCodes () {

    this.wallTop.setCodes(this.props.furnitureEnsemblePlan.wallCode, this.props.furnitureEnsemblePlan.sideCode)
    this.wallLeft.setCodes(this.props.furnitureEnsemblePlan.wallCode, this.props.furnitureEnsemblePlan.sideCode)
    this.wallRight.setCodes(this.props.furnitureEnsemblePlan.wallCode, this.props.furnitureEnsemblePlan.sideCode)
    this.wallBottom.setCodes(this.props.furnitureEnsemblePlan.wallCode, this.props.furnitureEnsemblePlan.sideCode)

  }

  selectObject = (select) => {
    if (this.SELECTED instanceof FN3DExtended) {
      this.colorizeParentChild(this.SELECTED, new Color(color.whiteHex))
    }

    if (select instanceof FN3DExtended) {

      let vector = new THREE.Vector3()
      vector.setFromMatrixPosition(select.matrixWorld)
      let angle = select.getAngle(select.rotationAtParent + select.rotationSelf + select.rotationInherited) + select.parentDegrees

      this.selection.rotation.set(0, 0, 0)
      this.selection.rotateY(angle)
      this.selection.position.set(vector.x, 0.01, vector.z)

      this.selection.bbox.setFromObject(select)

      if (select.bbox) this.selection.scale.x = select.bbox.max.x * 2 + 0.05
      if (select.bbox) this.selection.scale.z = select.bbox.max.z * 2 + 0.05

      this.SELECTED = select

      this.colorizeParentChild(this.SELECTED, new Color(color.selectionPlanHex))

    } else if (select instanceof WallSelector) {

      let vector = new THREE.Vector3()
      vector.setFromMatrixPosition(select.matrixWorld)

      this.selection.rotation.set(0, 0, 0)
      this.selection.position.set(vector.x, .01, vector.z)

      this.selection.scale.x = select.scale.x
      this.selection.scale.z = select.scale.z

      this.SELECTED = select
      this.selection.visible = true

    } else {
      this.SELECTED = null
      this.selection.visible = false
    }
  }

  colorizeParentChild (parentFurniture, color, setOpacity = true) {
    if (!parentFurniture)
      return

    let selectedChildren = this.findAllChildrenOfType(parentFurniture, FN3DExtended)

    if (parentFurniture.children.length >= 1) {
      parentFurniture.children[0].material.color = color

      parentFurniture.children[0].material.opacity = .3
      if (setOpacity)
        parentFurniture.children[0].material.transparent = true
      else
        parentFurniture.children[0].material.transparent = false
    }

    selectedChildren.forEach((selectedChild) => {
      if (selectedChild.children.length >= 1) {
        selectedChild.children[0].material.color = color
        selectedChild.children[0].material.opacity = .1
        if (setOpacity)
          selectedChild.children[0].material.transparent = true
        else
          selectedChild.children[0].material.transparent = false
      }
    })
  }

  findFN3DExtendedInSceneByUUID (sourceUuid) {
    if (!this.scene) {
      return
    }

    return this.findAllChildrenOfType(this.scene, FN3DExtended)
      .find((child) => child.source.uuid === sourceUuid)
  }

  findAllChildrenOfType (parent, searchType) {
    if (!parent)
      return []

    return this.findAllChildren(parent.children)
      .filter(child => child instanceof searchType)
  }

  findAllChildren (parents) {
    let flatList = parents.flatMap(child => child.children)
    if (flatList.length > 0) {
      return parents.concat(this.findAllChildren(flatList))
    }
    return parents
  }

  updateSelection = () => {

    if (this.SELECTED instanceof FN3DExtended) {
      let vector = new THREE.Vector3()

      let newObject = this.getObjectByName(this.SELECTED.name)

      if (newObject instanceof FN3DExtended) {

        newObject.updateMatrixWorld()
        vector.setFromMatrixPosition(newObject.matrixWorld)

        this.selection.position.set(vector.x, 0.01, vector.z)

        let angle = newObject.getAngle(newObject.rotationAtParent + newObject.rotationSelf + newObject.rotationInherited) + newObject.parentDegrees
        this.selection.rotation.set(0, 0, 0)

        this.selection.rotateY(angle)
        this.selectionDirty = false
      }
    } else if (this.SELECTED instanceof WallSelector) {

      this.selection.position.set(this.SELECTED.position.x, .01, this.SELECTED.position.z)
      this.selection.scale.set(this.SELECTED.scale.x, .01, this.SELECTED.scale.z)
      this.selectionDirty = false
    }
  }

  getObjectByName (name) {

    let returnValue = null
    for (let i = 0; i < this.fnlist.length; i++) {
      if (this.fnlist[i].name === name)
        returnValue = this.fnlist[i]
    }
    return returnValue
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  }

  stop = () => {
    cancelAnimationFrame(this.frameId)
  }

  animate = () => {
    /* this.cube.rotation.x += 0.01;
     this.cube.rotation.y += 0.01;*/
    this.renderScene()
    this.frameId = window.requestAnimationFrame(this.animate)
  }

  renderScene = () => {
    this.orbitControls.update()

    this.adjustRoomScalers()
    this.raycaster.setFromCamera(this.mouse, this.camera)

    this.INTERSECTED = null
    let clearHover = false
    let clearActive = false
    let clearGizmo = false

    // Create coords over catchplane
    let intersects = this.raycaster.intersectObjects(this.catchers.children, false)
    if (intersects.length > 0) {
      this.INTERSECTIONPOSITION = intersects[0].point

    }

    if (this.MOUSEACTION === null) {

      // Stage 1 Intersection - the Room
      intersects = this.raycaster.intersectObjects(this.selectStage1.children, false)
      if (intersects.length > 0) {
        this.INTERSECTED = intersects[0].object
      }

      // Intersect Walls
      intersects = this.raycaster.intersectObjects(this.wallSelectors, false)
      if (intersects.length > 0) {
        this.INTERSECTED = intersects[0].object
      }

      // Intersect SelectorPlane
      intersects = this.raycaster.intersectObjects(this.selectors, false)
      if (intersects.length > 0) {
        for (let i = 0; i < intersects.length; i++) {
          this.INTERSECTED = intersects[i].object
        }

      }

      // Setup Hovers
      if (this.INTERSECTED !== this.LASTINTERSECTED) {

        this.setHover(this.INTERSECTED)
      }
    }

    if (this.INTERSECTED === null && this.LASTINTERSECTED !== null) this.setHover(null)

    this.LASTINTERSECTED = this.INTERSECTED
    // Process Active
    this.processActiveElements()

    this.renderer.clear('#fff')
    //this.renderer.autoClear = false
    this.renderer.render(this.scene, this.camera)
    if (this.selectionDirty)
      this.updateSelection()

  }

  processActiveElements = () => {
    if (this.HOVER instanceof Selector) {
      let over = this.gizmo.getNearest(this.INTERSECTIONPOSITION)
      this.ACTIVE = this.gizmo
      this.gizmo.hideElements()
      this.gizmo.showElement(over)
      this.LASTINTERSECTED = this.INTERSECTED
    }

    if (this.HOVER instanceof Room) {
      this.updateRoomResizer()
    }
  }

  setHover (object) {

    if (this.HOVER !== object) {

      // check actual HOVER is  Wall ?
      if (this.HOVER instanceof WallSelector) {
        this.HOVER.hideSelector()
        this.hideRoomResizer()
      }

      if (this.HOVER instanceof Selector) {
        //this.gizmo.visible = false
        const findObject = this.findFN3DExtendedInSceneByUUID(this.HOVER.linkedObject.source.uuid)
        this.colorizeParentChild(findObject, new Color(color.whiteHex), false)
        if (this.SELECTED instanceof FN3DExtended)
          this.colorizeParentChild(this.SELECTED, new Color(color.selectionPlanHex))

        this.ACTIVE = null
      }

      if (this.HOVER instanceof Room) {
        this.hideRoomResizer()
        this.ACTIVE = null

      }

      this.HOVER = object

      if (object instanceof WallSelector) {
        object.showSelector()
        this.showRoomResizer()
        this.updateRoomResizer()
        this.gizmo.visible = false
      } else if (object instanceof Selector) {
        this.gizmo.appendToFN(object.linkedObject)

        if (this.SELECTED instanceof FN3DExtended)
          this.colorizeParentChild(this.SELECTED, new Color(color.whiteHex))
        const findObject = this.findFN3DExtendedInSceneByUUID(object.linkedObject.source.uuid)
        this.colorizeParentChild(findObject, new Color(color.selectionPlanHex))

        this.gizmo.visible = true
        this.ACTIVE = this.gizmo
      } else if (object instanceof Room) {
        this.showRoomResizer()
        this.updateRoomResizer()
        this.gizmo.visible = false

      } else {
        this.RESIZER = null
      }
    }

  }

  updateRoomResizer () {

    if (this.INTERSECTED instanceof Room) {

      this.RESIZER = this.roomScalerTop
      let distanceTo0 = Math.abs(this.INTERSECTIONPOSITION.z - this.roomScalerTop.position.z)
      let distanceTo1 = Math.abs(this.INTERSECTIONPOSITION.z - this.roomScalerBottom.position.z)
      let distanceTo2 = Math.abs(this.INTERSECTIONPOSITION.x - this.roomScalerLeft.position.x)
      let distanceTo3 = Math.abs(this.INTERSECTIONPOSITION.x - this.roomScalerRight.position.x)

      if (distanceTo1 < distanceTo0) {
        distanceTo0 = distanceTo1
        this.RESIZER = this.roomScalerBottom
      }
      if (distanceTo2 < distanceTo0) {
        distanceTo0 = distanceTo2
        this.RESIZER = this.roomScalerLeft
      }
      if (distanceTo3 < distanceTo0) {
        distanceTo0 = distanceTo3
        this.RESIZER = this.roomScalerRight
      }
    } else if (this.INTERSECTED.parent === this.wallTop) {
      this.RESIZER = this.roomScalerTop
    } else if (this.INTERSECTED.parent === this.wallRight) {
      this.RESIZER = this.roomScalerRight
    } else if (this.INTERSECTED.parent === this.wallLeft) {
      this.RESIZER = this.roomScalerLeft
    } else if (this.INTERSECTED.parent === this.wallBottom) {
      this.RESIZER = this.roomScalerBottom
    }

    this.roomScalerTopFace.visible = false
    this.roomScalerBottomFace.visible = false
    this.roomScalerLeftFace.visible = false
    this.roomScalerRightFace.visible = false
    this.RESIZER.children[0].visible = true
  }

  tryAddFurniture (furniture) {
    const furniture3DObject = this.getFurnitureItem(furniture.type)

    if (furniture3DObject) {

      let furniture3DObjectCopy = furniture3DObject.duplicate()
      furniture3DObjectCopy.applyData(furniture)
      furniture3DObjectCopy.selectorType = 'selectorPlane'

      return this.furniturePlacer.connectFurniture(furniture3DObjectCopy, this.selectStage2, this.fnlist)

    }
  }

  placeFurniture () {
    this.selectStage2.children = []
    this.selectStage2.actualWidth = this.props.furnitureEnsemblePlan.minWidth / 2
    this.selectStage2.actualDepth = this.props.furnitureEnsemblePlan.minDepth / 2
    this.selectors = []
    this.anchors = []
    this.anchorLines = []
    this.fnlist = []

    this.tryLater = []

    if (this.furniture3DObjects.length && this.props.furnitureEnsemblePlan && (this.props.furnitureEnsemblePlan.furnitureList)) {
      for (let i = 0; i < this.props.furnitureEnsemblePlan.furnitureList.length; i++) {
        if (!this.tryAddFurniture(this.props.furnitureEnsemblePlan.furnitureList[i])) {
          this.tryLater.push(this.props.furnitureEnsemblePlan.furnitureList[i])
        }
      }
    }

    let tryToPlacedCounter = 0

    while ((this.tryLater.length > 0) && (tryToPlacedCounter < 100)) {
      for (let i = 0; i < this.tryLater.length; i++) {
        if (this.tryAddFurniture(this.tryLater[i])) {
          this.tryLater.splice(i, 1)
        }
        tryToPlacedCounter++
      }
    }

    for (let i = 0; i < this.fnlist.length; i++) {
      if (this.fnlist[i] instanceof FN3DExtended) {
        this.furniturePlacer.placeFurniture(this.fnlist[i])
        this.furniturePlacer._setPosition(this.fnlist[i])
        this.furniturePlacer.createSelector(this.fnlist[i], this.selectors)
        this.furniturePlacer.addAnchors(this.fnlist[i], this.anchors, this.anchorLines)

      }
    }

    for (let i = 0; i < this.fnlist.length; i++) {
      if (this.fnlist[i] instanceof FN3DExtended) {
        this.fnlist[i].scanParentRotations()
      }
    }

    this.addRoomAnchors()
    this.selectionDirty = true
  }

  addRoomAnchors () {
    this.anchors.push(this.r_TS)
    this.anchors.push(this.r_TM)
    this.anchors.push(this.r_TE)

    this.anchors.push(this.r_BS)
    this.anchors.push(this.r_BM)
    this.anchors.push(this.r_BE)

    this.anchors.push(this.r_LS)
    this.anchors.push(this.r_LM)
    this.anchors.push(this.r_LE)

    this.anchors.push(this.r_RS)
    this.anchors.push(this.r_RM)
    this.anchors.push(this.r_RE)
    this.anchors.push(this.r_CC)

    this.anchorLines.push(this.tline)
    this.anchorLines.push(this.bline)
    this.anchorLines.push(this.lline)
    this.anchorLines.push(this.rline)

  }

  updateRoomAnchors () {

    this.tline.children = []
    this.bline.children = []
    this.lline.children = []
    this.rline.children = []

    let topLineGeometry = new THREE.Geometry()
    topLineGeometry.vertices.push(new THREE.Vector3(-this.props.furnitureEnsemblePlan.minWidth / 2, 0.06, -this.props.furnitureEnsemblePlan.minDepth / 2),
      new THREE.Vector3(this.props.furnitureEnsemblePlan.minWidth / 2, 0.06, -this.props.furnitureEnsemblePlan.minDepth / 2))
    let topLine = new THREE.Line(topLineGeometry, this.lineMat)
    this.tline.add(topLine)

    let bottomLineGeometry = new THREE.Geometry()
    bottomLineGeometry.vertices.push(new THREE.Vector3(-this.props.furnitureEnsemblePlan.minWidth / 2, 0.06, this.props.furnitureEnsemblePlan.minDepth / 2),
      new THREE.Vector3(this.props.furnitureEnsemblePlan.minWidth / 2, 0.06, this.props.furnitureEnsemblePlan.minDepth / 2))
    let bottomLine = new THREE.Line(bottomLineGeometry, this.lineMat)
    this.bline.add(bottomLine)

    let leftLineGeometry = new THREE.Geometry()
    leftLineGeometry.vertices.push(new THREE.Vector3(-this.props.furnitureEnsemblePlan.minWidth / 2, 0.06, -this.props.furnitureEnsemblePlan.minDepth / 2),
      new THREE.Vector3(-this.props.furnitureEnsemblePlan.minWidth / 2, 0.06, this.props.furnitureEnsemblePlan.minDepth / 2))
    let leftLine = new THREE.Line(leftLineGeometry, this.lineMat)
    this.lline.add(leftLine)

    let rightLineGeometry = new THREE.Geometry()
    rightLineGeometry.vertices.push(new THREE.Vector3(this.props.furnitureEnsemblePlan.minWidth / 2, 0.06, this.props.furnitureEnsemblePlan.minDepth / 2),
      new THREE.Vector3(this.props.furnitureEnsemblePlan.minWidth / 2, 0.06, -this.props.furnitureEnsemblePlan.minDepth / 2))
    let rightLine = new THREE.Line(rightLineGeometry, this.lineMat)
    this.rline.add(rightLine)

    this.r_TM.position.set(0, 0.04, -this.props.furnitureEnsemblePlan.minDepth / 2)
    this.r_TS.position.set(-this.props.furnitureEnsemblePlan.minWidth / 2, 0.04, -this.props.furnitureEnsemblePlan.minDepth / 2)
    this.r_TE.position.set(this.props.furnitureEnsemblePlan.minWidth / 2, 0.04, -this.props.furnitureEnsemblePlan.minDepth / 2)
    this.r_BM.position.set(0, 0.04, this.props.furnitureEnsemblePlan.minDepth / 2)
    this.r_BS.position.set(this.props.furnitureEnsemblePlan.minWidth / 2, 0.04, this.props.furnitureEnsemblePlan.minDepth / 2)
    this.r_BE.position.set(-this.props.furnitureEnsemblePlan.minWidth / 2, 0.04, this.props.furnitureEnsemblePlan.minDepth / 2)
    this.r_LM.position.set(-this.props.furnitureEnsemblePlan.minWidth / 2, 0.04, 0)
    this.r_LS.position.set(-this.props.furnitureEnsemblePlan.minWidth / 2, 0.04, this.props.furnitureEnsemblePlan.minDepth / 2)
    this.r_LE.position.set(-this.props.furnitureEnsemblePlan.minWidth / 2, 0.04, -this.props.furnitureEnsemblePlan.minDepth / 2)
    this.r_RM.position.set(this.props.furnitureEnsemblePlan.minWidth / 2, 0.04, 0)
    this.r_RS.position.set(this.props.furnitureEnsemblePlan.minWidth / 2, 0.04, -this.props.furnitureEnsemblePlan.minDepth / 2)
    this.r_RE.position.set(this.props.furnitureEnsemblePlan.minWidth / 2, 0.04, this.props.furnitureEnsemblePlan.minDepth / 2)
    this.r_CC.position.set(0, 0.04, 0)

  }

  getFurnitureItem = (id) => {
    return this.furniture3DObjects
      .find(furniture => furniture.nid === id)
  }

  getFurnitureItemFromLib = (id) => {
    return this.props.furnitureLibrary
      .find(furniture => furniture.id === id)
  }

  limitValue (value) {

    while (value > 3) {
      value -= 4
    }
    while (value < 0) {
      value += 4
    }
    return value

  }

  hideRoomResizer () {
    this.roomScalerTop.visible = false
    this.roomScalerBottom.visible = false
    this.roomScalerLeft.visible = false
    this.roomScalerRight.visible = false
  }

  showRoomResizer () {
    this.roomScalerTop.visible = true
    this.roomScalerBottom.visible = true
    this.roomScalerLeft.visible = true
    this.roomScalerRight.visible = true
  }

  resetIntersection () {
    if (this.INTERSECTED) this.INTERSECTED.material.color.setHex(this.INTERSECTED.currentHex)
    this.INTERSECTED = null
  }

  createNewFurniture (fnuuid) {
    const furniture3DObject = this.getFurnitureItem(fnuuid)
    if (furniture3DObject) {
      this.MOUSEACTION = mouseActionType.dragNewElement
      this.sceneCoordsAtMouseBeforeClick = this.INTERSECTIONPOSITION.clone()

      let constructor = this.getFurnitureItemFromLib(fnuuid)

      let source = {
        'type': fnuuid,
        'mySide': 'BOTTOM',
        'rotationRad': 0,
        'posOffset': [0, 0],
        'constructor': constructor,

        'uuid': uuidv4(),
      }

      let furniture3DObjectCopy = furniture3DObject.duplicate()
      furniture3DObjectCopy.applyData(source)
      furniture3DObjectCopy.prepareSelectorPlane()
      furniture3DObjectCopy.updateSelectorPlane()
      furniture3DObjectCopy.selectorType = 'selectorPlane'
      this.furniturePlacer.setOpacityOfFurniture(furniture3DObjectCopy.children[0])

      furniture3DObjectCopy.position.set(this.INTERSECTIONPOSITION.x, this.INTERSECTIONPOSITION.y, this.INTERSECTIONPOSITION.z)

      furniture3DObjectCopy.moveSelectorPlane()
      this.selectStage2.add(furniture3DObjectCopy)
      this.selectObject(furniture3DObjectCopy)
    }

  }

  resize () {

    this.width = this.mount.clientWidth
    this.height = this.mount.clientHeight
    this.topbar = document.getElementById('application-builder-editor-plan').offsetTop
    this.sidebar = document.getElementById('application-builder-editor-plan').offsetLeft

    if (this.camera) {
      this.camera.aspect = this.width / this.height
      this.camera.updateProjectionMatrix()
    }
  }

  render () {

    return (

      <Measure
        bounds
        onResize={contentRect => {
          if (this.renderer) {
            this.renderer.setSize(contentRect.bounds.width, contentRect.bounds.height)
            this.resize()
          }
        }}>

        {({ measureRef }) => (
          <div ref={measureRef} id={'ApplicationEditor'} className={'plan-panel'}>
            <div className={'application-editor-container'} ref={(mount) => {this.mount = mount}}/>
          </div>
        )}

      </Measure>

    )
  }

}

ApplicationBuilderPlan.propTypes = {
  onChangeFurnitureParent: PropTypes.func,
  onChangeSelectElement: PropTypes.func,
  onChangeFurnitureEnsembleSize: PropTypes.func,
  onChangeFurniturePosition: PropTypes.func,
  onAddNewFurniture: PropTypes.func,
  deleteFurniture: PropTypes.func,
  updateFurnitureMenuItemDragId: PropTypes.func,
  furnitureLibrary: PropTypes.array,
  roomSizeLocked: PropTypes.bool,
  storeApplicationBuilderUndo: PropTypes.func,
  undoApplicationBuilder: PropTypes.func,
  redoApplicationBuilder: PropTypes.func,
}

export default ApplicationBuilderPlan