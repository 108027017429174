import React from 'react'

const SvgFloorsIconInactive = props => (
  <svg className={props.className} viewBox="0 0 60 54">
    <path d="M0 0h60v54H0z" data-name="Rechteck 216" fill="none"/>
    <path
      d="M38.384 26.032a1.153 1.153 0 0 1 1.495.451 1 1 0 0 1-.485 1.388L31.949 31.4a4.579 4.579 0 0 1-3.9 0l-7.442-3.519a1 1 0 0 1-.486-1.388 1.153 1.153 0 0 1 1.494-.452l7.444 3.519a2.2 2.2 0 0 0 1.881 0zm0 3.943a1.153 1.153 0 0 1 1.495.451 1 1 0 0 1-.485 1.388l-7.445 3.528a4.579 4.579 0 0 1-3.9 0l-7.442-3.518a1 1 0 0 1-.486-1.388 1.153 1.153 0 0 1 1.494-.452l7.444 3.516a2.2 2.2 0 0 0 1.881 0zm-6.968-11.661l7.834 3.664a1.108 1.108 0 0 1 .535 1.475 1.11 1.11 0 0 1-.539.536L31.4 27.615a3.339 3.339 0 0 1-2.8 0L20.755 24a1.108 1.108 0 0 1-.543-1.472 1.11 1.11 0 0 1 .536-.539l7.835-3.672a3.339 3.339 0 0 1 2.831 0z"
      data-name="Pfad 1033"
      fill="#8f939d"
    />
  </svg>
)

export default SvgFloorsIconInactive
