import React from 'react'

import Button from '../../common/components/Button'
import Dialog, { DialogButtonGroup } from '../../common/components/Dialog'

import './WrongAxesDialog.scss'

export default function WrongAxesDialog ({ onCancel = () => {}, hideModal = () => {}, onConfirm, text = 'Are you sure?', title = 'Please confirm your action', ...rest }) {

  return (
    <Dialog {...rest} className={'wrong-axes-dialog'} hideModal={hideModal} onCancel={onCancel} text={text} title={title}>
      <DialogButtonGroup className={'wrong-axes-dialog-button-group'}>
        <Button variant={'contained'}
                onClick={() => {
                  onConfirm()
                  hideModal()
                }}>
          {'confirm'}
        </Button>
      </DialogButtonGroup>
    </Dialog>
  )
}