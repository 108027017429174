import { httpClient } from '../../httpClient'
import { projectApi } from '../../utils'
import { FULFILLED, PENDING, REJECTED } from '../../globalConstants'

const FETCH_FRONTEND_LOCATION = 'FETCH_FRONTEND_LOCATION'
const UPDATE_FRONTEND_LOCATION = 'UPDATE_FRONTEND_LOCATION'

export const frontendLocationActionTypes = {

  FETCH_FRONTEND_LOCATION: FETCH_FRONTEND_LOCATION,
  FETCH_FRONTEND_LOCATION_PENDING: FETCH_FRONTEND_LOCATION + PENDING,
  FETCH_FRONTEND_LOCATION_FULFILLED: FETCH_FRONTEND_LOCATION + FULFILLED,
  FETCH_FRONTEND_LOCATION_REJECTED: FETCH_FRONTEND_LOCATION + REJECTED,

  UPDATE_FRONTEND_LOCATION: UPDATE_FRONTEND_LOCATION,
  UPDATE_FRONTEND_LOCATION_PENDING: UPDATE_FRONTEND_LOCATION + PENDING,
  UPDATE_FRONTEND_LOCATION_FULFILLED: UPDATE_FRONTEND_LOCATION + FULFILLED,
  UPDATE_FRONTEND_LOCATION_REJECTED: UPDATE_FRONTEND_LOCATION + REJECTED,

}

export function fetchFrontendLocation (projectId) {
  return {
    type: frontendLocationActionTypes.FETCH_FRONTEND_LOCATION,
    payload: httpClient.get(projectApi(projectId) + '/frontendLocation'),
  }
}

export function updateFrontendLocation (globalLocation, onboardingLocation) {
  const body = {
    globalLocation: globalLocation,
    onboardingLocation: onboardingLocation,
  }

  return {
    type: frontendLocationActionTypes.UPDATE_FRONTEND_LOCATION,
    payload: httpClient.post(projectApi() + '/frontendLocation', body),
  }
}