import { getVertexConfigByRepresentationType, Vertex } from '../geometries/VertexFactory'

let previewVertex = null

export class PreviewVertex extends Vertex {

  constructor (scene) {
    super()

    scene.add(this)

    this.name = 'previewVertex'
    this.position.z = 0.02

    previewVertex = this

    this.setActive(false)
  }

  setRepresentationType (representationType) {
    this.config = getVertexConfigByRepresentationType(representationType)
  }
}

export function setPreviewVertex (position, representationType) {
  if (!position) {
    console.log("PREVIEWVT")
    previewVertex.setActive(false)
    return
  }

  console.log("SET PREWIEW VERTEX "+position.x+"::"+position.y)
  previewVertex.setPosition(position)
  previewVertex.setRepresentationType(representationType)
  previewVertex.setActive(true)
}