import * as React from 'react'
const SvgWorkAreaActive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19 17C20.6569 17 22 18.3431 22 20V23H10V20C10 18.3431 11.3431 17 13 17H19Z" fill="url(#paint0_linear_12_4321)" stroke="#DA5B2B"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 9C17.6415 9 19 10.3585 19 12C19 13.6415 17.6415 15 16 15C14.3585 15 13 13.6415 13 12C13 10.3585 14.3019 9 16 9Z" fill="url(#paint1_linear_12_4321)" stroke="#DA5B2B"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.9999 14.5C26.6568 14.5 27.9999 15.8431 27.9999 17.5V18.5L23.7712 18.5005C23.3243 17.0773 22.2583 15.9282 20.8887 15.369C21.4305 14.8318 22.1764 14.5 22.9999 14.5H24.9999Z" fill="url(#paint2_linear_12_4321)" stroke="#DA5B2B"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 14.5C9.8235 14.5 10.5695 14.8318 11.1116 15.369C9.74175 15.9281 8.67559 17.0772 8.22873 18.5005L4 18.5V17.5C4 15.8431 5.34315 14.5 7 14.5H9Z" fill="url(#paint3_linear_12_4321)" stroke="#DA5B2B"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24 9C25.0943 9 26 9.90566 26 11C26 12.0943 25.0943 13 24 13C22.9057 13 22 12.0943 22 11C22 9.90566 22.8679 9 24 9Z" fill="url(#paint4_linear_12_4321)" stroke="#DA5B2B"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 9C9.09434 9 10 9.90566 10 11C10 12.0943 9.09434 13 8 13C6.90566 13 6 12.0943 6 11C6 9.90566 6.86792 9 8 9Z" fill="url(#paint5_linear_12_4321)" stroke="#DA5B2B"/>
    <defs>
      <linearGradient id="paint0_linear_12_4321" x1="21.9998" y1="23.0006" x2="21.9998" y2="17.0006" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
      <linearGradient id="paint1_linear_12_4321" x1="18.9999" y1="15.0006" x2="18.9999" y2="9.0006" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
      <linearGradient id="paint2_linear_12_4321" x1="27.9998" y1="18.5009" x2="27.9998" y2="14.5004" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
      <linearGradient id="paint3_linear_12_4321" x1="11.1115" y1="18.5009" x2="11.1115" y2="14.5004" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
      <linearGradient id="paint4_linear_12_4321" x1="25.9999" y1="13.0004" x2="25.9999" y2="9.0004" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
      <linearGradient id="paint5_linear_12_4321" x1="9.99993" y1="13.0004" x2="9.99993" y2="9.0004" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
    </defs>
  </svg>
)

export default SvgWorkAreaActive
