import { floorPlanActionTypes } from '../constants'
import { getPlacedOrganisationalUnit } from './organisationalUnits'
import { getMarker } from './markers'

let initialState = {
  connectables: [],
}

function connectablesReducer (state = initialState, action) {

  switch (action.type) {

    case floorPlanActionTypes.FETCH_CONNECTABLES_FULFILLED:
      return {
        ...state,
        connectables: action.payload.data,
      }

    default:
      return { ...state }
  }

}

export function getConnectableDepartments (state, departmentId) {

  if (!departmentId) {
    return []
  }

  return state.connectables.connectables
    .filter(connectable => connectable.id !== departmentId && !connectable.zoneMarker)
    .map(connectable => {
      let connectableOrganisationalUnit = getPlacedOrganisationalUnit(state, connectable.id)
      return { ...connectable, color: connectableOrganisationalUnit.color }
    })
}

export function getConnectableZoneMarker (state, markerId) {
  return getConnectableZoneMarkers(state)
    .find(marker => marker.id === markerId)
}

function getMarkerIconName (type) {
  switch (type) {
    case 'STAIRCASE':
      return 'stairs'
    case 'TOILET':
      return 'bathroom'
    case 'ENTRANCE':
      return 'door'
    case 'CAFETERIA':
      return 'cafe'
    case 'CUSTOM':
    default:
      return 'custom'
  }
}


export function getConnectableZoneMarkers (state) {
  return state.connectables.connectables
    .filter(connectable => connectable.zoneMarker)
    .map(connectable => {
      let connectableMarker = getMarker(state, connectable.id)
      return {
        ...connectable,
        type: connectableMarker.type,
        icon: getMarkerIconName(connectableMarker.type),
      }
    })
}

export default connectablesReducer



