import * as THREE from 'three'
import rightRotator from '../../assets/models/rightRotator.glb'
import rightRotatorHandle from '../../assets/models/rightRotatorHandle.glb'
import leftRotator from '../../assets/models/leftRotator.glb'
import leftRotatorHandle from '../../assets/models/leftRotatorHandle.glb'
import { ModelImporter } from './ModelImporter'

function Rotator () {
  THREE.Group.apply(this, arguments)

  this.init = function () {
    let importer = new ModelImporter()
    this.active = false
    this.isRotating = false
    this.isHighlighted = false
    this.startAngle = new THREE.Vector3()
    this.material = new THREE.MeshBasicMaterial({ color: 0xff6c4c, transparent: true, opacity: 0.6 })
    this.invMaterial = new THREE.MeshBasicMaterial({ color: 0xff007f, transparent: true, opacity: 0 })
    this.baseAngle = 0
    this.currentAngle = 0

    this.rightRotator = importer.loadFromFile(rightRotator, this.material)
    this.rightRotator.position.y = 0.01
    this.add(this.rightRotator)

    this.leftRotator = importer.loadFromFile(leftRotator, this.material)
    this.leftRotator.position.y = 0.01
    this.add(this.leftRotator)

    this.rightRotatorHandle = importer.loadFromFile(rightRotatorHandle, this.invMaterial)
    this.add(this.rightRotatorHandle)

    this.leftRotatorHandle = importer.loadFromFile(leftRotatorHandle, this.invMaterial)
    this.add(this.leftRotatorHandle)
  }

  this.isHovered = function () {
    return this.isHighlighted
  }

  this.highlight = function () {
    this.isHighlighted = true
    this.material.opacity = 1
  }

  this.unhighlight = function () {
    if (!this.isRotating) {
      this.isHighlighted = false
      this.material.opacity = 0.5
    }
  }

  this.enable = function (target) {
    this.active = true
    this.visible = true
    this.position.set(target.position.x, 0.04, target.position.z)
    let scaleFactor = 0.085
    this.scale.set(scaleFactor * target.maxSize, scaleFactor * target.maxSize, scaleFactor * target.maxSize)
    this.isRotating = false

    this.target = target
  }

  this.disable = function () {
    this.active = false
    this.visible = false
    this.isRotating = false
  }

  this.startRotation = function (startPosition) {
    this.baseAngle = this.getAngle(this.position, startPosition)
    this.isRotating = true
  }

  this.updateRotation = function (position) {

    if (position) {
      this.currentAngle = this.getAngle(this.position, position)

      let angle = this.currentAngle - this.baseAngle

      const roundValue = 5 * Math.PI / 180

      angle = -(Math.ceil(angle / roundValue) * roundValue)

      this.target.rotation.y = angle
    }
  }

  this.getAngle = function (origin, point) {
    let differenceVector = point.sub(origin)

    return Math.atan2(differenceVector.z, differenceVector.x)
  }

  this.getResultAngle = function () {
    return this.target.rotation.y
  }
}

Rotator.prototype = Object.create(THREE.Group.prototype)
Rotator.prototype.constructor = Rotator

export { Rotator }