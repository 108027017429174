import { floorPlanActionTypes } from '../constants'

let initApp = {

  selectedRowId: null,
  selectedApplicationId: null,

  selectedContextMenuId: null,

}

function floorPlanState (state = initApp, action) {

  switch (action.type) {

    case floorPlanActionTypes.SET_SELECTED_APPLICATION_ID:
      return { ...state, selectedApplicationId: action.id }

    case floorPlanActionTypes.SET_SELECT_ROW:
      return { ...state, selectedRowId: action.id }

    case floorPlanActionTypes.SET_SELECT_CONTEXT_MENU:
      return { ...state, selectedContextMenuId: action.id, selectedOrganisationalUnitId: action.organisationalUnitId }

    default:
      return state
  }

}

export default floorPlanState
