import React from 'react'
import { Scrollbar } from 'react-scrollbars-custom'

import MenuSection from '../../../common/components/MenuSection'
import CollapsibleMenuSection from '../../../common/components/CollapsibleMenuSection'

import { iconTypes } from '../../../common/components/icons/IconFactory'
import { IconWrapper } from '../../../common/components/IconWrapper'

import Text, { textTypes } from '../../../common/components/Text'

import IconButton from '../../../common/components/IconButton'
import OptimizeButton from './OptimizeButton'
import i18n from 'i18next'

import { viewModes } from '../plan/FloorplanPanel'

import './Dashboard.scss'

// TODO: move quality category calculation to backend
function LabelNumberGroup ({ title, currentValue, aimValue }) {
  return (
    <div className='label-number-group'>
      <div className='title-row'>
        <Text>{title}</Text>
      </div>
      <div className='number-row'>
        <div className={'number'}>
          <Text component={textTypes.paragraphBold}>{currentValue ? currentValue : 'n.a.'}&nbsp;</Text>
          <Text component={textTypes.paragraph}>/ {aimValue ? aimValue : 'n.a.'}</Text>
        </div>
        <div className={`quality-dot ${currentValue ? (currentValue >= aimValue ? 'fulfilled' : 'unfulfilled') : 'no-value'}`}/>
      </div>
    </div>
  )
}

function AreaSummary ({ floorplan }) {
  const areas = floorplan ? floorplan.analyticsAreas : []

  const openSpaceArea = areas
    .filter(area => area.faceType === 'ZONE' && area.area > 1)
    .map(area => area.area)
    .reduce((a, b) => a + b, 0)
    .toFixed(2)
  const roomArea = areas
    .filter(area => area.faceType === 'ROOM' && area.area > 1)
    .map(area => area.area)
    .reduce((a, b) => a + b, 0)
    .toFixed(2)
  const infrastructureArea = areas
    .filter(area => area.faceType === 'INFRASTRUCTURE' && area.area > 1)
    .map(area => area.area)
    .reduce((a, b) => a + b, 0)
    .toFixed(2)
  const blockedArea = areas
    .filter(area => area.faceType === 'NONE' && area.area > 1)
    .map(area => area.area)
    .reduce((a, b) => a + b, 0)
    .toFixed(2)

  return (
    <div className={'area-summary'}>
      <AreaElement title={'Open Space'} icon={iconTypes.drawZoneInactive} area={openSpaceArea}/>
      <AreaElement title={'Room'} icon={iconTypes.drawServiceInactive} area={roomArea}/>
      <AreaElement title={'Infrastructure'} icon={iconTypes.drawInfrastructureInactive} area={infrastructureArea}/>
      <AreaElement title={'Blocked'} icon={iconTypes.drawBlockedInactive} area={blockedArea}/>
    </div>
  )
}

function AreaElement ({ title, icon, area }) {
  return (
    <div className={'area-element'}>
      <IconWrapper name={icon} color={'#00293b'}/>
      <div className={'area-info'}>
        <Text>{title}</Text>
        <Text component={textTypes.paragraphBold}>{area} m²</Text>
      </div>
    </div>
  )
}

function QualityBar ({ quality }) {

  const qualityPercentage = Math.round(quality ? quality.overallQuality * 100 : null)
  const qualityString = qualityPercentage + '%'

  return (
    <div className='quality-bar'>
      <div className='quality-bar-title'>
        <Text>{i18n.t('overall_quality')}</Text>
        <Text component={textTypes.paragraphBold} center>{((qualityPercentage !== undefined && qualityPercentage !== null) ? qualityPercentage : '-- ') + '%'}</Text>
      </div>
      <div className='quality-bar-container'>
        <div className='quality-bar-indicator mep' style={{ width: qualityPercentage ? qualityString : 0 }}/>
      </div>
    </div>
  )
}

function HeatMapButtonGroup ({ viewMode, onViewModeChange }) {
  return <React.Fragment>
    <div>
      <IconButton tooltip={i18n.t('team')} className='heatmap-button' icon={iconTypes.teams} onClick={() => onViewModeChange(viewModes.team)} active={viewMode === viewModes.team}/>
      <IconButton tooltip={i18n.t('light')} className='heatmap-button' icon={iconTypes.light} onClick={() => onViewModeChange(viewModes.light)} active={viewMode === viewModes.light}/>
    </div>
    <div>
      <IconButton tooltip={i18n.t('noise')} className='heatmap-button' icon={iconTypes.blackWhite} onClick={() => onViewModeChange(viewModes.noise)} active={viewMode === viewModes.noise}/>
      <IconButton tooltip={i18n.t('activity')} className='heatmap-button' icon={iconTypes.activity} onClick={() => onViewModeChange(viewModes.activity)}
                  active={viewMode === viewModes.activity}/>
    </div>
  </React.Fragment>
}

function Dashboard ({ viewMode, onViewModeChange, summary, floorplan }) {

  return (
    <div id='dashboard'>
      <div className='dashboard-scroll-container'>
        <Scrollbar autoHide>
          <MenuSection className='optimize-section'>
            <OptimizeButton/>
          </MenuSection>
          <CollapsibleMenuSection className='quality-section' icon={iconTypes.quality} title={i18n.t('quality')}
                                  open>
            <LabelNumberGroup title={i18n.t('workplaces')}
                              currentValue={summary.placedNumberOfWorkplaces}
                              aimValue={summary.goalNumberOfWorkplaces}/>
            <LabelNumberGroup title={i18n.t('space_in_m2')}
                              currentValue={summary.placedArea}
                              aimValue={summary.goalArea}/>
            <LabelNumberGroup title={i18n.t('m2_per_workplace')}
                              currentValue={summary.placedAreaForWorkplace}
                              aimValue={summary.goalAreaForWorkplace}/>
            <QualityBar quality={summary.quality}/>
          </CollapsibleMenuSection>
          <CollapsibleMenuSection icon={iconTypes.area} className='area-section' title={i18n.t('m2_per_Area')}
                                  open>
            <AreaSummary floorplan={floorplan}/>
          </CollapsibleMenuSection>
          <CollapsibleMenuSection icon={iconTypes.heatmap} className='heatmap-section' title={i18n.t('heatmap')}
                                  open>
            <HeatMapButtonGroup viewMode={viewMode} onViewModeChange={onViewModeChange}/>
          </CollapsibleMenuSection>
        </Scrollbar>
      </div>
    </div>
  )
}

export default Dashboard