import { httpClient } from '../../httpClient'
import { getProjectId, projectApi } from '../../utils'
import { FULFILLED, PENDING, REJECTED } from '../../globalConstants'
import { floorPlanActionTypes } from '../../../floorplan/constants'
import { fetchPatternList } from '../../../patternbuilder/actions/actions'
import { getUndoInformation } from '../undo/undo-actions'

const UPLOAD_PATTERNS = 'UPLOAD_PATTERNS'
const DELETE_PATTERNS = 'DELETE_PATTERNS'

export const applicationActionTypes = {

  UPLOAD_PATTERNS: UPLOAD_PATTERNS,
  UPLOAD_PATTERNS_PENDING: UPLOAD_PATTERNS + PENDING,
  UPLOAD_PATTERNS_FULFILLED: UPLOAD_PATTERNS + FULFILLED,
  UPLOAD_PATTERNS_REJECTED: UPLOAD_PATTERNS + REJECTED,

  DELETE_PATTERNS: DELETE_PATTERNS,
  DELETE_PATTERNS_PENDING: DELETE_PATTERNS + PENDING,
  DELETE_PATTERNS_FULFILLED: DELETE_PATTERNS + FULFILLED,
  DELETE_PATTERNS_REJECTED: DELETE_PATTERNS + REJECTED,

}

export function deletePatterns () {
  return (dispatch) => {
    dispatch({
      type: applicationActionTypes.DELETE_PATTERNS,
      payload: httpClient.delete(encodeURI(`${projectApi()}/patterns`)),
    })
      .then(() => dispatch(fetchPatternList()))
      .then(() => dispatch(getUndoInformation(getProjectId())))
  }
}

export function exportPatterns () {
  return {
    type: floorPlanActionTypes.FETCH_FILE,
    payload: httpClient.getFile(encodeURI(`${projectApi()}/patterns/coa`)),
  }
}

export function importPatterns (file, replace) {
  let form = new FormData()
  form.append('file', file)
  form.append('replace', replace)

  return (dispatch) => {
    dispatch({
      type: applicationActionTypes.UPLOAD_PATTERNS,
      payload: httpClient.post(encodeURI(`${projectApi()}/patterns/coa`), form),
    })
      .then(() => dispatch(fetchPatternList()))
      .then(() => dispatch(getUndoInformation(getProjectId())))
  }

}