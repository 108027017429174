import React from 'react'
import Collapsible from 'react-collapsible'

import VerticalMenu from '../VerticalMenu'
import FloorMenu from './FloorMenu'

import Icon, { iconTypes } from '../icons/IconFactory'
import Text from '../Text'
import { IconWrapper } from '../IconWrapper'

import './FloorListItem.scss'

export function FloorListItem ({ floor, selectedFloorId, children, openFloor = () => {} }) {
  const contentStyleClass = (floor.id === selectedFloorId ? ' active' : '') + (openFloor ? ' clickable' : '')

  return (
    <div className={'floor-tree-list-container'}>
      <ul className={'floor-tree-list'}>
        <li className={'floor-list-item collapsible-floor-list-item'}>
          <div className={'floor-list-item-content' + contentStyleClass}>
            <div className={'floor-list-item-text'} onClick={openFloor ? () => openFloor(floor.id) : null}>
              <IconWrapper name={'floor'} styleClass={'floor-list-icon'}/>
              <Text>{floor.name}</Text>
            </div>
            <VerticalMenu>
              <FloorMenu floorId={floor.id}/>
            </VerticalMenu>
          </div>
          {children ?
            <Collapsible className={'floor-list-item-collapsible'}
                         openedClassName={'floor-list-item-collapsible open'}
                         triggerClassName={'floor-list-item-collapsible-trigger'}
                         triggerOpenedClassName={'floor-list-item-collapsible-trigger open'}
                         contentOuterClassName={'floor-list-item-collapsible-content-container'}
                         contentInnerClassName={'floor-list-item-collapsible-content'}
                         triggerTagName={'div'}
                         transitionTime={200}
                         trigger={<Icon className={'trigger-arrow '} name={iconTypes.triangle}/>}
                         lazyRender={false}>
              <ul className={'tree-list'}>
                {children}
              </ul>
            </Collapsible>
            : null}
        </li>
      </ul>
    </div>
  )
}
