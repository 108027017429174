export const splitArea = [
  {
    'uuid': '0E92028A-D832-46F1-AA08-F40E44AD165D',
    'geometryType': 'polygon',
    'representationType': 'outline',
    'isValidate': false,
    'errors': [],
    'properties': {
      'offsetLeft': 0.2,
      'offsetRight': 0.2,
    },
    'vertices': [
      {
        'uuid': 'E54E9522-2523-48F8-B11B-2062535F8A33',
        'x': -10.0,
        'y': 10.0,
        'z': 0,
      },
      {
        'uuid': '986A0412-E94A-4341-A4A9-E0E108EDF524',
        'x': 10.0,
        'y': 10.0,
        'z': 0,
      },
      {
        'uuid': '3C0BB900-C235-42A2-ADA3-7F55F306B98D',
        'x': 10.,
        'y': -10.0,
        'z': 0,
      },
      {
        'uuid': 'EF45B456-9AC8-4C5B-91D7-B9C49B85B151',
        'x': -10.0,
        'y': -10.0,
        'z': 0,
      },
    ],
    'edges': [
      {
        'uuid': 'BD8B6F9C-B71D-4919-863A-C9C6D15D1837',
        'startVertex': 'E54E9522-2523-48F8-B11B-2062535F8A33',
        'endVertex': '986A0412-E94A-4341-A4A9-E0E108EDF524',
      },
      {
        'uuid': 'B8DB86F4-3838-48C8-B6D8-680726BBA45C',
        'startVertex': '986A0412-E94A-4341-A4A9-E0E108EDF524',
        'endVertex': '3C0BB900-C235-42A2-ADA3-7F55F306B98D',
      },
      {
        'uuid': '203F70BB-AF04-4D08-87CC-DF90B9E4BEC5',
        'startVertex': '3C0BB900-C235-42A2-ADA3-7F55F306B98D',
        'endVertex': 'EF45B456-9AC8-4C5B-91D7-B9C49B85B151',
      },
      {
        'uuid': 'A14D7C73-DCB2-4000-99FA-FA7EEA3F2D8B',
        'startVertex': 'EF45B456-9AC8-4C5B-91D7-B9C49B85B151',
        'endVertex': 'E54E9522-2523-48F8-B11B-2062535F8A33',
      },
    ],
  },
  {
    'uuid': 'C04A50ED-11A8-46B1-A6AC-314F54491854',
    'geometryType': 'polyline',
    'representationType': 'wall',
    'isValidate': false,
    'errors': [],
    'properties': {
      'offsetLeft': 0.1,
      'offsetRight': 0.1,
    },
    'vertices': [
      {
        'uuid': '580F5789-F499-4BC8-B34E-7538F8E5E70C',
        'x': -10.0,
        'y': 0.0,
        'z': 0,
      },
      {
        'uuid': '7BFD4C4C-6FAE-44C5-A93F-A53C720CE6C0',
        'x': 10.0,
        'y': 0.0,
        'z': 0,
      },
    ],
    'edges': [
      {
        'uuid': 'DC9A400C-A81A-48D2-ACB6-BDA80250617B',
        'startVertex': '580F5789-F499-4BC8-B34E-7538F8E5E70C',
        'endVertex': '7BFD4C4C-6FAE-44C5-A93F-A53C720CE6C0',
      },
    ],
  },
  {
    'uuid': '0DD74345-F0AC-4414-8CF7-69B7EC181DD5',
    'geometryType': 'geometry',
    'representationType': 'space',
    'isValidate': false,
    'errors': [],
  },
]

export const crossWalls = [
  {
    "uuid": "1EAA7A57-08FF-4DCC-AF4C-32C344101A2D",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "C4BA2B66-2BFD-44A5-ABE2-2DD5D3A3599A",
        "x": -10,
        "y": 0,
        "z": 0
      },
      {
        "uuid": "9B985D7C-D96A-4F73-A5B1-159D18D638E2",
        "x": 10,
        "y": 0,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "02BBA230-5352-4906-B97A-4E6A7F7513AC",
        "startVertex": "C4BA2B66-2BFD-44A5-ABE2-2DD5D3A3599A",
        "endVertex": "9B985D7C-D96A-4F73-A5B1-159D18D638E2"
      }
    ]
  },
  {
    "uuid": "E16A19A8-B192-4CF2-BD24-9C64F3E1E56C",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "6BEE189F-27AF-4C6E-8656-AA928AF5C339",
        "x": 0,
        "y": -10,
        "z": 0
      },
      {
        "uuid": "DB81B8A5-D2E5-4894-AD00-1926DB057F5F",
        "x": 0,
        "y": 10,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "E0BCCFB3-59A4-4FD0-BDF5-42DC8D9A884B",
        "startVertex": "6BEE189F-27AF-4C6E-8656-AA928AF5C339",
        "endVertex": "DB81B8A5-D2E5-4894-AD00-1926DB057F5F"
      }
    ]
  }
]

export const crossArea = [
  {
    "uuid": "1A8D225C-EE40-4272-9A03-B1C3114B6339",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.2,
      "offsetRight": 0.2
    },
    "vertices": [
      {
        "uuid": "E1A49C5B-95A7-409C-BA6A-C1BA337DF8BC",
        "x": -10,
        "y": 10,
        "z": 0
      },
      {
        "uuid": "C0B4A362-6DAB-48BD-B75E-35E3C2FD59BF",
        "x": 10,
        "y": 10,
        "z": 0
      },
      {
        "uuid": "F5635A39-A926-48F8-955E-C3AB8D1A4E15",
        "x": 10,
        "y": -10,
        "z": 0
      },
      {
        "uuid": "AE5136F9-CEFE-46AD-937C-A03ED854D1FF",
        "x": -10,
        "y": -10,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "B03433BC-49DA-40D9-9FDB-18CA86AE5150",
        "startVertex": "E1A49C5B-95A7-409C-BA6A-C1BA337DF8BC",
        "endVertex": "C0B4A362-6DAB-48BD-B75E-35E3C2FD59BF"
      },
      {
        "uuid": "320A5358-30EC-4B47-BF69-F7FFA5D1516B",
        "startVertex": "C0B4A362-6DAB-48BD-B75E-35E3C2FD59BF",
        "endVertex": "F5635A39-A926-48F8-955E-C3AB8D1A4E15"
      },
      {
        "uuid": "5FB74676-0370-46DD-92B2-16C2C000A17F",
        "startVertex": "F5635A39-A926-48F8-955E-C3AB8D1A4E15",
        "endVertex": "AE5136F9-CEFE-46AD-937C-A03ED854D1FF"
      },
      {
        "uuid": "B5542BEE-04B2-44C1-8389-118DBFCB66AC",
        "startVertex": "AE5136F9-CEFE-46AD-937C-A03ED854D1FF",
        "endVertex": "E1A49C5B-95A7-409C-BA6A-C1BA337DF8BC"
      }
    ]
  },
  {
    "uuid": "FC086729-1FC2-42CF-94CA-F8CC23C05D12",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "A47365C9-0CFB-435F-B956-64EB2F7257AC",
        "x": -10,
        "y": 0,
        "z": 0
      },
      {
        "uuid": "1B331BC1-7C0B-4B8A-9E65-278486637FCD",
        "x": 10,
        "y": 0,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "A8BFB20A-C1AB-4DFE-9900-0FD9589B148B",
        "startVertex": "A47365C9-0CFB-435F-B956-64EB2F7257AC",
        "endVertex": "1B331BC1-7C0B-4B8A-9E65-278486637FCD"
      }
    ]
  },
  {
    "uuid": "EB666490-B609-4D6C-B3BF-6166B4DC4950",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "BE1CA296-6ADF-43D7-983B-AFCDFE6B97E5",
        "x": 0,
        "y": 10,
        "z": 0
      },
      {
        "uuid": "3D06570D-549E-4652-8E2B-C1F8CD42E5CD",
        "x": 0,
        "y": -10,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "F0F6FCE8-7516-4800-9967-31244A522401",
        "startVertex": "BE1CA296-6ADF-43D7-983B-AFCDFE6B97E5",
        "endVertex": "3D06570D-549E-4652-8E2B-C1F8CD42E5CD"
      }
    ]
  }
]

export const example = [
  {
    "uuid": "3655F5E3-7075-4861-BBF4-ABFC24A5C00F",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.2,
      "offsetRight": 0.2
    },
    "vertices": [
      {
        "uuid": "D42A41F3-C6CD-44DB-A4AC-965AC24F5860",
        "x": -4.780755884698045,
        "y": -4.132068739599198,
        "z": 0
      },
      {
        "uuid": "D97A3506-3C6C-4132-B1CB-0CCC09AB357A",
        "x": 7.883102144356998,
        "y": -4.132068739599198,
        "z": 0
      },
      {
        "uuid": "56AC49EB-5C46-4F36-A422-268FFBAEB4C7",
        "x": 7.883102144356998,
        "y": -14.44999896982929,
        "z": 0
      },
      {
        "uuid": "14211F6F-284D-4A46-8F3C-CC0E3B402F55",
        "x": -2.4555885088715455,
        "y": -14.44999896982929,
        "z": 0
      },
      {
        "uuid": "1439BCA6-AB83-46A9-A2E4-9B1E2330E5A5",
        "x": -2.4555885088715455,
        "y": -9.737382949180938,
        "z": 0
      },
      {
        "uuid": "6423D77C-E30E-456B-A0C7-46E79C4BF042",
        "x": -4.780755884698045,
        "y": -9.737382949180938,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "284F2AD2-D1F9-47AA-AA6F-4CCD4DF95CC1",
        "startVertex": "D42A41F3-C6CD-44DB-A4AC-965AC24F5860",
        "endVertex": "D97A3506-3C6C-4132-B1CB-0CCC09AB357A"
      },
      {
        "uuid": "0B42DCAB-5671-4FCE-A83A-6211260ACACC",
        "startVertex": "D97A3506-3C6C-4132-B1CB-0CCC09AB357A",
        "endVertex": "56AC49EB-5C46-4F36-A422-268FFBAEB4C7"
      },
      {
        "uuid": "375E95AF-A75F-460D-A814-A2B69ABFB976",
        "startVertex": "56AC49EB-5C46-4F36-A422-268FFBAEB4C7",
        "endVertex": "14211F6F-284D-4A46-8F3C-CC0E3B402F55"
      },
      {
        "uuid": "A6442475-C851-4528-A28B-128CADA78C73",
        "startVertex": "14211F6F-284D-4A46-8F3C-CC0E3B402F55",
        "endVertex": "1439BCA6-AB83-46A9-A2E4-9B1E2330E5A5"
      },
      {
        "uuid": "CB59C9DD-EE7A-43A1-820E-167DEA9F5E29",
        "startVertex": "1439BCA6-AB83-46A9-A2E4-9B1E2330E5A5",
        "endVertex": "6423D77C-E30E-456B-A0C7-46E79C4BF042"
      },
      {
        "uuid": "59F07631-C856-420B-AA93-F9AFF97C1BD9",
        "startVertex": "6423D77C-E30E-456B-A0C7-46E79C4BF042",
        "endVertex": "D42A41F3-C6CD-44DB-A4AC-965AC24F5860"
      }
    ]
  },
  {
    "uuid": "6FCC66B0-0988-4B86-8175-92D752E4A978",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "E34F9FA4-798F-47E9-ACD5-CA9BE412B600",
        "x": -2.4555885088715455,
        "y": -9.737382949180938,
        "z": 0
      },
      {
        "uuid": "930B4105-6F16-4BA1-B331-B35DC01AE19C",
        "x": 7.883102144356998,
        "y": -9.737382949180938,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "8A4B33E9-1B37-4141-9E29-870782679487",
        "startVertex": "E34F9FA4-798F-47E9-ACD5-CA9BE412B600",
        "endVertex": "930B4105-6F16-4BA1-B331-B35DC01AE19C"
      }
    ]
  }
]

export const splitArea2 = [
    {
      "uuid": "52E3007E-2778-45CE-B55E-52CBAA5920D0",
      "geometryType": "polygon",
      "representationType": "outline",
      "isValidate": false,
      "errors": [],
      "properties": {
        "offsetLeft": 0.2,
        "offsetRight": 0.2
      },
      "vertices": [
        {
          "uuid": "CD69D113-6366-4FA6-B095-650DEF2F545C",
          "x": -8.21215624426257,
          "y": 6.210874470450683,
          "z": 0
        },
        {
          "uuid": "67977DEE-C2B6-4610-AAE4-34EB41F095DA",
          "x": 1.9782785350324412,
          "y": 6.210874470450683,
          "z": 0
        },
        {
          "uuid": "3BC95681-AFCD-4368-908E-93E1D2C072E0",
          "x": 1.9782785350324412,
          "y": -7.591068797217502,
          "z": 0
        },
        {
          "uuid": "5799467B-CCA3-4EE8-A8F7-5127C66294AC",
          "x": -8.21215624426257,
          "y": -7.591068797217502,
          "z": 0
        }
      ],
      "edges": [
        {
          "uuid": "8C51433F-679B-4588-98CE-B9FB318AD56A",
          "startVertex": "CD69D113-6366-4FA6-B095-650DEF2F545C",
          "endVertex": "67977DEE-C2B6-4610-AAE4-34EB41F095DA"
        },
        {
          "uuid": "ACD5D576-D4CB-4311-98F4-866B997791B7",
          "startVertex": "67977DEE-C2B6-4610-AAE4-34EB41F095DA",
          "endVertex": "3BC95681-AFCD-4368-908E-93E1D2C072E0"
        },
        {
          "uuid": "78CB38AD-8703-4255-8354-20D9C68E12EF",
          "startVertex": "3BC95681-AFCD-4368-908E-93E1D2C072E0",
          "endVertex": "5799467B-CCA3-4EE8-A8F7-5127C66294AC"
        },
        {
          "uuid": "A47E062A-C689-4288-A577-7D50C24865B9",
          "startVertex": "5799467B-CCA3-4EE8-A8F7-5127C66294AC",
          "endVertex": "CD69D113-6366-4FA6-B095-650DEF2F545C"
        }
      ]
    },
    {
      "uuid": "F4F085FA-CB73-480C-B3D1-6C6C5870304C",
      "geometryType": "polyline",
      "representationType": "wall",
      "isValidate": false,
      "errors": [],
      "properties": {
        "offsetLeft": 0.1,
        "offsetRight": 0.1
      },
      "vertices": [
        {
          "uuid": "67947EF4-2911-4623-B064-33CFAB521DB9",
          "x": -8.21215624426257,
          "y": -0.06900971633834069,
          "z": 0
        },
        {
          "uuid": "950C04D3-3596-4A76-9F5B-192A88473C98",
          "x": -3.4964922944759405,
          "y": -0.06900971633834069,
          "z": 0
        },
        {
          "uuid": "64431F5B-C380-4C3F-8780-BC6736864143",
          "x": -3.4964922944759405,
          "y": -7.591068797217502,
          "z": 0
        }
      ],
      "edges": [
        {
          "uuid": "D3A9B157-18ED-4FF8-9A90-26C2D13D39D3",
          "startVertex": "67947EF4-2911-4623-B064-33CFAB521DB9",
          "endVertex": "950C04D3-3596-4A76-9F5B-192A88473C98"
        },
        {
          "uuid": "8C15E3DB-0AE0-450F-85B4-96F7A09FBF28",
          "startVertex": "950C04D3-3596-4A76-9F5B-192A88473C98",
          "endVertex": "64431F5B-C380-4C3F-8780-BC6736864143"
        }
      ]
    }
  ]

export const doubleSplitArea = [
  {
    "uuid": "DC5B504A-C1AA-44D6-9DAA-01F340612185",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.2,
      "offsetRight": 0.2
    },
    "vertices": [
      {
        "uuid": "F525A8AA-269C-45DD-8438-CD5597251244",
        "x": -13.894096980706246,
        "y": 8.949801208292762,
        "z": 0
      },
      {
        "uuid": "5E4066DE-9941-496B-BC78-F1CB9C46FDEE",
        "x": 2.722491975949199,
        "y": 8.949801208292762,
        "z": 0
      },
      {
        "uuid": "CC402839-AC17-4471-9329-CDE21FD9811C",
        "x": 2.722491975949199,
        "y": -6.4463603108682115,
        "z": 0
      },
      {
        "uuid": "29CACBE5-627A-4EC8-8373-57EF27FA5D34",
        "x": -13.894096980706246,
        "y": -6.4463603108682115,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "F670D2F5-B709-4F51-8C3C-AFE26946DD87",
        "startVertex": "F525A8AA-269C-45DD-8438-CD5597251244",
        "endVertex": "5E4066DE-9941-496B-BC78-F1CB9C46FDEE"
      },
      {
        "uuid": "25C40B1A-F891-4D30-A5D0-0B38E337A5FA",
        "startVertex": "5E4066DE-9941-496B-BC78-F1CB9C46FDEE",
        "endVertex": "CC402839-AC17-4471-9329-CDE21FD9811C"
      },
      {
        "uuid": "0DACF615-A7B7-4152-851B-F3C55B15F03A",
        "startVertex": "CC402839-AC17-4471-9329-CDE21FD9811C",
        "endVertex": "29CACBE5-627A-4EC8-8373-57EF27FA5D34"
      },
      {
        "uuid": "44716914-D913-4531-BF8E-161AF8D97FD7",
        "startVertex": "29CACBE5-627A-4EC8-8373-57EF27FA5D34",
        "endVertex": "F525A8AA-269C-45DD-8438-CD5597251244"
      }
    ]
  },
  {
    "uuid": "1F147C10-BE1F-43F6-B6E3-493D31ED9453",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "BCA598C5-8FC6-48A2-BF49-F7673F49005A",
        "x": -13.894096980706246,
        "y": 1.2830134599300815,
        "z": 0
      },
      {
        "uuid": "D3E5055C-B0AC-42B0-B09F-9334C626C7E7",
        "x": 2.722491975949199,
        "y": 1.2830134599300815,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "DD1A9276-86B3-4612-992E-CE6847F0415E",
        "startVertex": "BCA598C5-8FC6-48A2-BF49-F7673F49005A",
        "endVertex": "D3E5055C-B0AC-42B0-B09F-9334C626C7E7"
      }
    ]
  },
  {
    "uuid": "545A00AD-83B4-4B10-804D-323D329F80B4",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "D4888CBC-607A-4B0A-A75B-A542951C6102",
        "x": -5.78920707529427,
        "y": 1.2830134599300815,
        "z": 0
      },
      {
        "uuid": "7299F174-2FB1-4F24-B8FE-B76CBD963916",
        "x": -5.78920707529427,
        "y": 8.949801208292762,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "25E27EBD-C73B-4030-AB10-9CBB6B63DC3E",
        "startVertex": "D4888CBC-607A-4B0A-A75B-A542951C6102",
        "endVertex": "7299F174-2FB1-4F24-B8FE-B76CBD963916"
      }
    ]
  },
  {
    "uuid": "FD8912D8-2307-4889-BC07-B5AF0BA49123",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  },
  {
    "uuid": "7C087113-1B99-40E8-8E0F-D87C81C0CCD4",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  },
  {
    "uuid": "ABF01F0C-F7F2-4172-B967-40C312972F38",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  }
]

export const multipleHoles = [
  {
    "uuid": "8F9963FB-5C2D-4735-9D64-33C1EB3FE801",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.2,
      "offsetRight": 0.2
    },
    "vertices": [
      {
        "uuid": "621074E8-ADA9-4C22-90DD-45022D50AA36",
        "x": -7.4518992026018465,
        "y": 4.960648442787739,
        "z": 0
      },
      {
        "uuid": "70BBDA13-A807-41B5-85E0-E0E5342158CA",
        "x": 1.7263930703974972,
        "y": 4.960648442787739,
        "z": 0
      },
      {
        "uuid": "F24E9340-D835-404C-B8B5-18F0759F9806",
        "x": 1.7263930703974972,
        "y": -5.310297672235333,
        "z": 0
      },
      {
        "uuid": "E445A74D-A4EB-4797-9319-7A13BECA93DB",
        "x": -13.155552257965722,
        "y": -5.310297672235333,
        "z": 0
      },
      {
        "uuid": "70F201BF-E0DA-475E-BBB2-699C68DFB976",
        "x": -13.155552257965722,
        "y": 1.6608338398760716,
        "z": 0
      },
      {
        "uuid": "C7CC0495-6EAE-4F90-8FA3-A4E24ACD46D7",
        "x": -7.4518992026018465,
        "y": 1.6608338398760716,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "0E3EC07C-C8A4-4D96-A3C0-3FF873006957",
        "startVertex": "621074E8-ADA9-4C22-90DD-45022D50AA36",
        "endVertex": "70BBDA13-A807-41B5-85E0-E0E5342158CA"
      },
      {
        "uuid": "580B9DB7-47CF-4A66-9043-C37958A3A2B3",
        "startVertex": "70BBDA13-A807-41B5-85E0-E0E5342158CA",
        "endVertex": "F24E9340-D835-404C-B8B5-18F0759F9806"
      },
      {
        "uuid": "441DB083-5B29-4D3D-8F38-2DB65FF76A24",
        "startVertex": "F24E9340-D835-404C-B8B5-18F0759F9806",
        "endVertex": "E445A74D-A4EB-4797-9319-7A13BECA93DB"
      },
      {
        "uuid": "5F4185DA-B0A8-4E64-B20F-B147669A8510",
        "startVertex": "E445A74D-A4EB-4797-9319-7A13BECA93DB",
        "endVertex": "70F201BF-E0DA-475E-BBB2-699C68DFB976"
      },
      {
        "uuid": "AA4574D6-C43B-4646-B62E-0EAB3D34EBF2",
        "startVertex": "70F201BF-E0DA-475E-BBB2-699C68DFB976",
        "endVertex": "C7CC0495-6EAE-4F90-8FA3-A4E24ACD46D7"
      },
      {
        "uuid": "FBEFF70E-52CE-4FF7-829D-EF3815A260C4",
        "startVertex": "C7CC0495-6EAE-4F90-8FA3-A4E24ACD46D7",
        "endVertex": "621074E8-ADA9-4C22-90DD-45022D50AA36"
      }
    ]
  },
  {
    "uuid": "A37C8F16-5B5D-4F2C-93E3-04D087A459BF",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.2,
      "offsetRight": 0.2
    },
    "vertices": [
      {
        "uuid": "D0126B0C-AD3C-4886-B2A6-2EF37751FEC9",
        "x": -4.436174598616349,
        "y": 3.45278614079499,
        "z": 0
      },
      {
        "uuid": "BB93A4A0-4DB6-49C8-8330-0E49BF238D5E",
        "x": -0.3277961526071202,
        "y": 3.45278614079499,
        "z": 0
      },
      {
        "uuid": "C506D534-CA03-40E0-A018-D28473847B5E",
        "x": -0.3277961526071202,
        "y": 0.5900330746928151,
        "z": 0
      },
      {
        "uuid": "DEDC1B49-C96C-4811-9621-91D3B4E7DB18",
        "x": -4.436174598616349,
        "y": 0.5900330746928151,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "6CC8C898-1AEA-491B-988A-3DEF430220D6",
        "startVertex": "D0126B0C-AD3C-4886-B2A6-2EF37751FEC9",
        "endVertex": "BB93A4A0-4DB6-49C8-8330-0E49BF238D5E"
      },
      {
        "uuid": "F0BEC24D-8B6F-422C-B97A-171D6F60D406",
        "startVertex": "BB93A4A0-4DB6-49C8-8330-0E49BF238D5E",
        "endVertex": "C506D534-CA03-40E0-A018-D28473847B5E"
      },
      {
        "uuid": "21E874E7-310A-4E6C-A715-2718AFC42423",
        "startVertex": "C506D534-CA03-40E0-A018-D28473847B5E",
        "endVertex": "DEDC1B49-C96C-4811-9621-91D3B4E7DB18"
      },
      {
        "uuid": "9F645043-78EE-4F83-809E-1CE0505E4B4B",
        "startVertex": "DEDC1B49-C96C-4811-9621-91D3B4E7DB18",
        "endVertex": "D0126B0C-AD3C-4886-B2A6-2EF37751FEC9"
      }
    ]
  },
  {
    "uuid": "44767241-7738-4D5B-8696-A359C4C1E2E0",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.2,
      "offsetRight": 0.2
    },
    "vertices": [
      {
        "uuid": "EBA202E5-7F2C-403A-A388-A4CEE231A005",
        "x": -11.625836879132498,
        "y": -0.3715023062880683,
        "z": 0
      },
      {
        "uuid": "056CA201-C2B1-497D-9E8E-BA13897717AA",
        "x": -7.4518992026018465,
        "y": -0.3715023062880683,
        "z": 0
      },
      {
        "uuid": "673048CE-2D42-4CE2-AF39-59B4007E3456",
        "x": -7.4518992026018465,
        "y": -3.9335538312854323,
        "z": 0
      },
      {
        "uuid": "D07DF8A9-BCE0-43E3-A3B8-B138712DFB62",
        "x": -11.625836879132498,
        "y": -3.9335538312854323,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "9D640860-6DA5-4248-83A5-86A977D69DDD",
        "startVertex": "EBA202E5-7F2C-403A-A388-A4CEE231A005",
        "endVertex": "056CA201-C2B1-497D-9E8E-BA13897717AA"
      },
      {
        "uuid": "431E9C08-C3AD-44CC-9304-85EE0604ED21",
        "startVertex": "056CA201-C2B1-497D-9E8E-BA13897717AA",
        "endVertex": "673048CE-2D42-4CE2-AF39-59B4007E3456"
      },
      {
        "uuid": "D0261500-A7CC-4B23-9F5F-E6CCEBC0CA99",
        "startVertex": "673048CE-2D42-4CE2-AF39-59B4007E3456",
        "endVertex": "D07DF8A9-BCE0-43E3-A3B8-B138712DFB62"
      },
      {
        "uuid": "9571851A-13C0-4154-A2C0-587F6ACB919E",
        "startVertex": "D07DF8A9-BCE0-43E3-A3B8-B138712DFB62",
        "endVertex": "EBA202E5-7F2C-403A-A388-A4CEE231A005"
      }
    ]
  },
  {
    "uuid": "651E8443-340B-41EC-82AC-AEAEC0EFB4A2",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.2,
      "offsetRight": 0.2
    },
    "vertices": [
      {
        "uuid": "E0C2B376-D4C2-41B2-BC32-07BAD1DEF160",
        "x": -4.436174598616349,
        "y": -1.704539993557021,
        "z": 0
      },
      {
        "uuid": "8278DE5A-400C-4A7D-89A3-DF823A336162",
        "x": -0.3277961526071202,
        "y": -1.704539993557021,
        "z": 0
      },
      {
        "uuid": "CDE6C58D-88D2-40EA-A0E7-AFF765A2752E",
        "x": -0.3277961526071202,
        "y": -3.9335538312854323,
        "z": 0
      },
      {
        "uuid": "DD2F7B60-6EA1-4332-9F24-031D034E792E",
        "x": -4.436174598616349,
        "y": -3.9335538312854323,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "04198DE8-5D4B-4FD4-9F87-E0AC558E2352",
        "startVertex": "E0C2B376-D4C2-41B2-BC32-07BAD1DEF160",
        "endVertex": "8278DE5A-400C-4A7D-89A3-DF823A336162"
      },
      {
        "uuid": "C4A4BBC5-FE28-41FF-9D75-F7F5222A801B",
        "startVertex": "8278DE5A-400C-4A7D-89A3-DF823A336162",
        "endVertex": "CDE6C58D-88D2-40EA-A0E7-AFF765A2752E"
      },
      {
        "uuid": "EA78F4AD-E29A-4D6C-88D3-0945F1C7FCD0",
        "startVertex": "CDE6C58D-88D2-40EA-A0E7-AFF765A2752E",
        "endVertex": "DD2F7B60-6EA1-4332-9F24-031D034E792E"
      },
      {
        "uuid": "09F584C3-C156-45EB-A889-27BCF2E4662E",
        "startVertex": "DD2F7B60-6EA1-4332-9F24-031D034E792E",
        "endVertex": "E0C2B376-D4C2-41B2-BC32-07BAD1DEF160"
      }
    ]
  },
  {
    "uuid": "758936B5-F774-4956-AEB2-25942EE05BD5",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  }
]

export const splitAreaWithHole = [
  {
    "uuid": "9B99C15E-7077-40D3-B890-1CB172BFF6DB",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.2,
      "offsetRight": 0.2
    },
    "vertices": [
      {
        "uuid": "894CEFE3-FA00-45B3-ABE9-4BC6973AC539",
        "x": -7.657095152659962,
        "y": 5.5009602038773275,
        "z": 0
      },
      {
        "uuid": "1D538077-5F95-4A99-AB40-10D86E4770F6",
        "x": 5.900937364958414,
        "y": 5.5009602038773275,
        "z": 0
      },
      {
        "uuid": "E4111DCE-0CD1-45C9-ACD6-B531E24D6209",
        "x": 5.900937364958414,
        "y": -7.281298967541379,
        "z": 0
      },
      {
        "uuid": "C00CFCC5-D651-4FCF-B65B-50D1C12D2AFF",
        "x": -7.657095152659962,
        "y": -7.281298967541379,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "800A0B97-8817-4A5D-A6EF-EB59EE6194F3",
        "startVertex": "894CEFE3-FA00-45B3-ABE9-4BC6973AC539",
        "endVertex": "1D538077-5F95-4A99-AB40-10D86E4770F6"
      },
      {
        "uuid": "FB9FF095-A18F-433C-B426-83934FF34C08",
        "startVertex": "1D538077-5F95-4A99-AB40-10D86E4770F6",
        "endVertex": "E4111DCE-0CD1-45C9-ACD6-B531E24D6209"
      },
      {
        "uuid": "A0122C6E-EFE1-4E34-8C7B-8CDC88DBB52C",
        "startVertex": "E4111DCE-0CD1-45C9-ACD6-B531E24D6209",
        "endVertex": "C00CFCC5-D651-4FCF-B65B-50D1C12D2AFF"
      },
      {
        "uuid": "A7D32F15-7955-448A-811E-669E5430233E",
        "startVertex": "C00CFCC5-D651-4FCF-B65B-50D1C12D2AFF",
        "endVertex": "894CEFE3-FA00-45B3-ABE9-4BC6973AC539"
      }
    ]
  },
  {
    "uuid": "E41D1272-C769-46A7-842A-C1D7E2DA9501",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.2,
      "offsetRight": 0.2
    },
    "vertices": [
      {
        "uuid": "E20FA934-B850-4508-B294-7045AC16EC1B",
        "x": -3.7485251919023677,
        "y": 2.4433202264698277,
        "z": 0
      },
      {
        "uuid": "DFB010EC-AD4A-4F0F-B352-FAD97735E96D",
        "x": 3.0040755518042888,
        "y": 2.4433202264698277,
        "z": 0
      },
      {
        "uuid": "5479D221-B8A9-4874-BC8E-282B96156F94",
        "x": 3.0040755518042888,
        "y": -4.811901284567748,
        "z": 0
      },
      {
        "uuid": "E2E04AF0-CCD3-44EA-AFE9-C7C2639D439A",
        "x": -3.7485251919023677,
        "y": -4.811901284567748,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "DCAB1ECA-B034-4EEE-B642-895F8A61EF74",
        "startVertex": "E20FA934-B850-4508-B294-7045AC16EC1B",
        "endVertex": "DFB010EC-AD4A-4F0F-B352-FAD97735E96D"
      },
      {
        "uuid": "B4689A3E-0E98-4261-BC7A-5D21B60FE49B",
        "startVertex": "DFB010EC-AD4A-4F0F-B352-FAD97735E96D",
        "endVertex": "5479D221-B8A9-4874-BC8E-282B96156F94"
      },
      {
        "uuid": "93383467-168B-42DB-BE16-EE839CB71C96",
        "startVertex": "5479D221-B8A9-4874-BC8E-282B96156F94",
        "endVertex": "E2E04AF0-CCD3-44EA-AFE9-C7C2639D439A"
      },
      {
        "uuid": "9FFCF6FF-E34C-421A-AF74-68FD0304CFE5",
        "startVertex": "E2E04AF0-CCD3-44EA-AFE9-C7C2639D439A",
        "endVertex": "E20FA934-B850-4508-B294-7045AC16EC1B"
      }
    ]
  },
  {
    "uuid": "A866D5EC-6DFF-4C18-88A3-C610BDD19CD7",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "A3E7E492-321E-43CE-9F6B-2EEC7382231B",
        "x": -0.6016821268740211,
        "y": 2.4433202264698277,
        "z": 0
      },
      {
        "uuid": "02C0319C-160E-48CF-B417-47A989F9E1A3",
        "x": -0.6016821268740211,
        "y": 5.5009602038773275,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "80D70934-E014-4B60-8222-3735A73FF786",
        "startVertex": "A3E7E492-321E-43CE-9F6B-2EEC7382231B",
        "endVertex": "02C0319C-160E-48CF-B417-47A989F9E1A3"
      }
    ]
  },
  {
    "uuid": "EBE7C81C-00E9-46F4-BB85-2555256E20B2",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "900BAFF0-3162-412B-AE1D-956C4BFB20E1",
        "x": 3.0040755518042888,
        "y": -1.5339397584965544,
        "z": 0
      },
      {
        "uuid": "D88AE8F4-AC06-4638-9B77-D961BDAD2186",
        "x": 5.900937364958414,
        "y": -1.5339397584965544,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "94AA4BEB-FCEE-4E6A-9C36-778FF7618F1E",
        "startVertex": "900BAFF0-3162-412B-AE1D-956C4BFB20E1",
        "endVertex": "D88AE8F4-AC06-4638-9B77-D961BDAD2186"
      }
    ]
  },
  {
    "uuid": "4E2AAAC4-0F2F-4730-A1C7-4525F711F7E9",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "6B64B161-0714-4CA2-B9D3-333F37CFB906",
        "x": -0.6016821268740211,
        "y": -4.811901284567748,
        "z": 0
      },
      {
        "uuid": "CB8F0CD2-1109-414C-AC4E-27C8A37AF9D6",
        "x": -0.6016821268740211,
        "y": -7.281298967541379,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "4E7568A3-200E-47AE-9A2A-F920FE3FF471",
        "startVertex": "6B64B161-0714-4CA2-B9D3-333F37CFB906",
        "endVertex": "CB8F0CD2-1109-414C-AC4E-27C8A37AF9D6"
      }
    ]
  },
  {
    "uuid": "B16D0560-4142-4F97-93A2-08FDC6204E09",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  }
]

export const splitAreaWithAngle = [
  {
    "uuid": "E0820603-6D32-487D-AD37-F2C96179FE04",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.2,
      "offsetRight": 0.2
    },
    "vertices": [
      {
        "uuid": "D4C1750F-9101-475E-9156-8E196140B876",
        "x": -9.604809700233304,
        "y": 6.488670208165826,
        "z": 0
      },
      {
        "uuid": "0CF49B1E-B079-4255-8552-0669DB4531D2",
        "x": 5.029212471374729,
        "y": 6.488670208165826,
        "z": 0
      },
      {
        "uuid": "5A281CE5-FE64-4625-84A5-2522830E8C45",
        "x": 5.029212471374729,
        "y": -7.060619861773148,
        "z": 0
      },
      {
        "uuid": "3F3B7C0E-7092-40D3-A923-8029F68E3E81",
        "x": -9.604809700233304,
        "y": -7.060619861773148,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "AA6F1072-2C42-473C-A53F-8FBDD5E635EA",
        "startVertex": "D4C1750F-9101-475E-9156-8E196140B876",
        "endVertex": "0CF49B1E-B079-4255-8552-0669DB4531D2"
      },
      {
        "uuid": "1D9F2193-BF6E-4DBC-8FD5-44C8D651A59F",
        "startVertex": "0CF49B1E-B079-4255-8552-0669DB4531D2",
        "endVertex": "5A281CE5-FE64-4625-84A5-2522830E8C45"
      },
      {
        "uuid": "9505CC8C-FFF4-4976-B121-D0433B05E5AB",
        "startVertex": "5A281CE5-FE64-4625-84A5-2522830E8C45",
        "endVertex": "3F3B7C0E-7092-40D3-A923-8029F68E3E81"
      },
      {
        "uuid": "D743DC1E-CF52-48AE-9203-7C194EC7D310",
        "startVertex": "3F3B7C0E-7092-40D3-A923-8029F68E3E81",
        "endVertex": "D4C1750F-9101-475E-9156-8E196140B876"
      }
    ]
  },
  {
    "uuid": "AA72AE10-1FAF-44C1-9303-DE7B6B48588F",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "74534BA6-D47B-46FC-934A-8433CEEF1C28",
        "x": -9.604809700233304,
        "y": -0.3747256351220393,
        "z": 0
      },
      {
        "uuid": "4694E19F-7E70-4ECA-B059-34CDD11161EE",
        "x": -2.3864106236719307,
        "y": -0.3747256351220393,
        "z": 0
      },
      {
        "uuid": "F3ABC875-3C42-4688-9A38-35F0BBEF7EA6",
        "x": -2.3864106236719307,
        "y": 6.488670208165826,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "6876D9D0-B9DC-42B1-A68C-B5724F301DEB",
        "startVertex": "74534BA6-D47B-46FC-934A-8433CEEF1C28",
        "endVertex": "4694E19F-7E70-4ECA-B059-34CDD11161EE"
      },
      {
        "uuid": "9F0E30D1-FBC7-4D67-A5E0-1DA52A6BEA57",
        "startVertex": "4694E19F-7E70-4ECA-B059-34CDD11161EE",
        "endVertex": "F3ABC875-3C42-4688-9A38-35F0BBEF7EA6"
      }
    ]
  },
  {
    "uuid": "70B30131-F48E-4684-B116-65480D3C70DB",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "A5896219-5B23-44AD-BC6D-992A974DFD4C",
        "x": -2.3864106236719307,
        "y": -0.3747256351220393,
        "z": 0
      },
      {
        "uuid": "42BDC82B-7719-49A8-ABC6-DFF5CAD301B8",
        "x": 5.029212471374729,
        "y": -0.3747256351220393,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "C63329FA-B06D-41DD-8F57-62A0423A9C5C",
        "startVertex": "A5896219-5B23-44AD-BC6D-992A974DFD4C",
        "endVertex": "42BDC82B-7719-49A8-ABC6-DFF5CAD301B8"
      }
    ]
  },
  {
    "uuid": "359B4B74-517C-4D00-9A7C-EB59BB8C9604",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  },
  {
    "uuid": "BCB0C1B4-21CB-4CC4-A53C-0663849BF765",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  }
]

export const overlappingWalls = [
  {
    "uuid": "0E9FEAF9-0368-433B-80C2-3A82CD2C88DF",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.2,
      "offsetRight": 0.2
    },
    "vertices": [
      {
        "uuid": "3807E28B-3448-4ECF-A329-09F6D7F47D52",
        "x": -9.293308466896576,
        "y": 5.93483560509732,
        "z": 0
      },
      {
        "uuid": "342A2AF3-0DF8-4CC6-AF29-07A2B9940438",
        "x": 4.577644517109948,
        "y": 5.93483560509732,
        "z": 0
      },
      {
        "uuid": "E15CD785-CFE4-44DA-8807-B09B2733E5D2",
        "x": 4.577644517109948,
        "y": -4.623650994668842,
        "z": 0
      },
      {
        "uuid": "E47A61D5-63A2-4AF0-AFBD-02D2463AED01",
        "x": -9.293308466896576,
        "y": -4.623650994668842,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "B40B0A9B-6137-40A2-9868-5D75543C4CFD",
        "startVertex": "3807E28B-3448-4ECF-A329-09F6D7F47D52",
        "endVertex": "342A2AF3-0DF8-4CC6-AF29-07A2B9940438"
      },
      {
        "uuid": "5C67A434-F71F-4FE3-A524-1F843B6997F9",
        "startVertex": "342A2AF3-0DF8-4CC6-AF29-07A2B9940438",
        "endVertex": "E15CD785-CFE4-44DA-8807-B09B2733E5D2"
      },
      {
        "uuid": "187FDE41-23E3-430E-AB9A-3E3902F94BB6",
        "startVertex": "E15CD785-CFE4-44DA-8807-B09B2733E5D2",
        "endVertex": "E47A61D5-63A2-4AF0-AFBD-02D2463AED01"
      },
      {
        "uuid": "8086B4DD-1799-493F-B144-229B89F48CE1",
        "startVertex": "E47A61D5-63A2-4AF0-AFBD-02D2463AED01",
        "endVertex": "3807E28B-3448-4ECF-A329-09F6D7F47D52"
      }
    ]
  },
  {
    "uuid": "3EDCF8A1-05CC-473C-BB59-12409481C0B6",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "1CBED8F8-51ED-4947-8F06-C4D8811024DE",
        "x": -9.293308466896576,
        "y": 0.7361036409423033,
        "z": 0
      },
      {
        "uuid": "ECFEB9B1-EFE5-4606-9E68-29D80FC608D6",
        "x": 0.09201295511778755,
        "y": 0.7361036409423033,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "F8E31CA6-1B38-454D-96C7-5640C061EFED",
        "startVertex": "1CBED8F8-51ED-4947-8F06-C4D8811024DE",
        "endVertex": "ECFEB9B1-EFE5-4606-9E68-29D80FC608D6"
      }
    ]
  },
  {
    "uuid": "EE5DDCF1-6BB2-4EB3-AC5D-0394405AE307",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "02968C0E-7692-4B11-8C7B-EF9947AE54AC",
        "x": -3.0364275188870007,
        "y": 0.7361036409423033,
        "z": 0
      },
      {
        "uuid": "34D83D1D-0A98-4551-8CAD-53CA421816BA",
        "x": 4.577644517109948,
        "y": 0.7361036409423033,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "57DA6640-5156-4EE2-8844-7BE6ED97E2F3",
        "startVertex": "02968C0E-7692-4B11-8C7B-EF9947AE54AC",
        "endVertex": "34D83D1D-0A98-4551-8CAD-53CA421816BA"
      }
    ]
  }
]

export const mergeVertices = [
  {
    "uuid": "C7F3A7C7-1E92-4360-AF0C-E99F4C323AD9",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.2,
      "offsetRight": 0.2
    },
    "vertices": [
      {
        "uuid": "C2F7DBCD-10C6-47D4-842A-BC3BF0E03397",
        "x": -12.135793824080219,
        "y": 7.45484477764928,
        "z": 0
      },
      {
        "uuid": "E91B8B71-51AA-4FF8-83F7-46089A5AC3BD",
        "x": 0.5894528428838943,
        "y": 7.45484477764928,
        "z": 0
      },
      {
        "uuid": "80B363C5-A7EF-4E5D-A12A-CFC80632CD96",
        "x": 0.5894528428838943,
        "y": -3.0859590009804,
        "z": 0
      },
      {
        "uuid": "B1D816EE-21F3-4EB1-ADAF-C8A86768852A",
        "x": -12.135793824080219,
        "y": -3.0859590009804,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "043A454B-0A8B-4EAF-9518-0CCFB74B5FBC",
        "startVertex": "C2F7DBCD-10C6-47D4-842A-BC3BF0E03397",
        "endVertex": "E91B8B71-51AA-4FF8-83F7-46089A5AC3BD"
      },
      {
        "uuid": "B150105B-7522-4F4D-A3E5-1A877C9E67F4",
        "startVertex": "E91B8B71-51AA-4FF8-83F7-46089A5AC3BD",
        "endVertex": "80B363C5-A7EF-4E5D-A12A-CFC80632CD96"
      },
      {
        "uuid": "39FF5E4D-9985-4483-BD51-C330151D1539",
        "startVertex": "80B363C5-A7EF-4E5D-A12A-CFC80632CD96",
        "endVertex": "B1D816EE-21F3-4EB1-ADAF-C8A86768852A"
      },
      {
        "uuid": "903D15F7-B114-40CC-B4C3-41E18036FA6D",
        "startVertex": "B1D816EE-21F3-4EB1-ADAF-C8A86768852A",
        "endVertex": "C2F7DBCD-10C6-47D4-842A-BC3BF0E03397"
      }
    ]
  },
  {
    "uuid": "DD72660A-9757-49AF-BF31-F03A06C5B816",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "15834E7A-77A8-4223-ABFD-5EEFEACC87B9",
        "x": -12.135793824080219,
        "y": 2.36472221458895,
        "z": 0
      },
      {
        "uuid": "AA10ACBA-0498-4889-AF3E-7E64D0DB2C05",
        "x": -5.969362262515953,
        "y": 2.36472221458895,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "A03A0038-B3EB-4E35-9E22-2D4246046554",
        "startVertex": "15834E7A-77A8-4223-ABFD-5EEFEACC87B9",
        "endVertex": "AA10ACBA-0498-4889-AF3E-7E64D0DB2C05"
      }
    ]
  },
  {
    "uuid": "D8F672AD-8CFE-496F-9101-124B52BE730D",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "11241FA8-7B4E-4144-9B0A-C2C1A5ABCB56",
        "x": -5.969362262515953,
        "y": 2.36472221458895,
        "z": 0
      },
      {
        "uuid": "AFFC8620-200C-4EFA-9049-D712C548268A",
        "x": 0.5894528428838943,
        "y": 2.36472221458895,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "525FDEA3-5F95-4352-998B-593D6D5F7AD8",
        "startVertex": "11241FA8-7B4E-4144-9B0A-C2C1A5ABCB56",
        "endVertex": "AFFC8620-200C-4EFA-9049-D712C548268A"
      }
    ]
  }
]

export const SingleCrossWalls = [
  {
    "uuid": "E01A2507-A832-4AB0-B3F4-188DD10DCFD5",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.2,
      "offsetRight": 0.2
    },
    "vertices": [
      {
        "uuid": "4909F0C4-87B5-4D32-8CE4-D5F0CD266AA5",
        "x": -7.855305570041927,
        "y": 5.028038125609659,
        "z": 0
      },
      {
        "uuid": "79990E53-0DBC-4912-8983-EBC5601661E7",
        "x": 4.835269890762006,
        "y": 5.028038125609659,
        "z": 0
      },
      {
        "uuid": "E0653DF9-B0E2-494B-B6E7-1209E124BD32",
        "x": 4.835269890762006,
        "y": -5.606342830152623,
        "z": 0
      },
      {
        "uuid": "7D5E97A0-B0E3-414C-85B3-F10E31ED0FDB",
        "x": -7.855305570041927,
        "y": -5.606342830152623,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "3FF1D40D-8C8C-4A8C-B920-BE9D173A8B1A",
        "startVertex": "4909F0C4-87B5-4D32-8CE4-D5F0CD266AA5",
        "endVertex": "79990E53-0DBC-4912-8983-EBC5601661E7"
      },
      {
        "uuid": "D7480161-0A1F-40A7-BB20-98F881BCED1F",
        "startVertex": "79990E53-0DBC-4912-8983-EBC5601661E7",
        "endVertex": "E0653DF9-B0E2-494B-B6E7-1209E124BD32"
      },
      {
        "uuid": "E3234B75-CD6D-4AC6-8383-DE32D9895E18",
        "startVertex": "E0653DF9-B0E2-494B-B6E7-1209E124BD32",
        "endVertex": "7D5E97A0-B0E3-414C-85B3-F10E31ED0FDB"
      },
      {
        "uuid": "871BE520-4B80-4EE8-95C9-6A2211131743",
        "startVertex": "7D5E97A0-B0E3-414C-85B3-F10E31ED0FDB",
        "endVertex": "4909F0C4-87B5-4D32-8CE4-D5F0CD266AA5"
      }
    ]
  },
  {
    "uuid": "C14F8C20-8B7A-425B-8440-0859ABCDA1D4",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "975B2DF4-D563-4930-8EAB-65430EB25F74",
        "x": -7.855305570041927,
        "y": 0.405012551772796,
        "z": 0
      },
      {
        "uuid": "25C2602C-898A-43E3-9195-BC674FF8DBAC",
        "x": -2.0453782367451216,
        "y": 0.405012551772796,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "047FCF17-D21F-4DE5-926C-B19B92D3DAB7",
        "startVertex": "975B2DF4-D563-4930-8EAB-65430EB25F74",
        "endVertex": "25C2602C-898A-43E3-9195-BC674FF8DBAC"
      }
    ]
  },
  {
    "uuid": "3403DACC-C277-4A19-ADE9-6F42D19F81B2",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "E48C3DAA-105E-41BE-B762-0E48C16A1197",
        "x": -2.0453782367451216,
        "y": -5.606342830152623,
        "z": 0
      },
      {
        "uuid": "E5BC912B-96BD-4C8E-8DD8-B5C291A13784",
        "x": -2.0453782367451216,
        "y": 0.405012551772796,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "8CB88C28-297A-4985-80CB-7E4D6F0674F0",
        "startVertex": "E48C3DAA-105E-41BE-B762-0E48C16A1197",
        "endVertex": "E5BC912B-96BD-4C8E-8DD8-B5C291A13784"
      }
    ]
  },
  {
    "uuid": "C4F6BF31-999C-489E-B231-48569D2C7A86",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  }
]

export const concatOutline = [
  {
    "uuid": "79D2370C-F5ED-4B49-B76F-3099EDFBD226",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0,
      "offsetRight": 0.4
    },
    "vertices": [
      {
        "uuid": "973F9607-0324-4BE0-87B7-7824BF383BA4",
        "x": -11.671600626297352,
        "y": 5.419628789559164,
        "z": 0
      },
      {
        "uuid": "C6A1B826-592C-455F-B449-C0E37A209781",
        "x": -1.6908981029507708,
        "y": 5.419628789559164,
        "z": 0
      },
      {
        "uuid": "04FDC9C9-178F-420F-AADD-F81301623A1C",
        "x": -1.6908981029507708,
        "y": -4.748882652237486,
        "z": 0
      },
      {
        "uuid": "2083D46C-A01A-43AE-B9D4-7FFFB746610A",
        "x": -11.671600626297352,
        "y": -4.748882652237486,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "B0334FFE-AB3D-4273-998B-6561032B7E93",
        "startVertex": "973F9607-0324-4BE0-87B7-7824BF383BA4",
        "endVertex": "C6A1B826-592C-455F-B449-C0E37A209781"
      },
      {
        "uuid": "E4048564-B3A1-41AB-9728-FDA34242FC29",
        "startVertex": "C6A1B826-592C-455F-B449-C0E37A209781",
        "endVertex": "04FDC9C9-178F-420F-AADD-F81301623A1C"
      },
      {
        "uuid": "5E03C177-C94D-4E5E-B682-1152A8ABD1D8",
        "startVertex": "04FDC9C9-178F-420F-AADD-F81301623A1C",
        "endVertex": "2083D46C-A01A-43AE-B9D4-7FFFB746610A"
      },
      {
        "uuid": "05E22F37-8F20-4FAE-A220-44E8C14FA3A7",
        "startVertex": "2083D46C-A01A-43AE-B9D4-7FFFB746610A",
        "endVertex": "973F9607-0324-4BE0-87B7-7824BF383BA4"
      }
    ]
  },
  {
    "uuid": "9CE1213F-3AD1-49D4-A3AE-C2F94B09CD88",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "032EDA34-C499-49B6-8402-6C9AD63F3EC7",
        "x": -11.671600626297352,
        "y": 0.670746137321679,
        "z": 0
      },
      {
        "uuid": "EC230384-4283-486D-B092-08F122D7830D",
        "x": -5.822694308852313,
        "y": 0.670746137321679,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "375E032E-1258-423F-BAC6-772C5AF348BE",
        "startVertex": "032EDA34-C499-49B6-8402-6C9AD63F3EC7",
        "endVertex": "EC230384-4283-486D-B092-08F122D7830D"
      }
    ]
  },
  {
    "uuid": "E2DD1EEA-B934-4649-872C-2187878ADB8D",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  }
]

export const offsetOutlines = [
  {
    "uuid": "A91085F9-EC41-4919-9533-3E6A5EEC175C",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0,
      "offsetRight": 0.4
    },
    "vertices": [
      {
        "uuid": "352AB5F3-F0EB-41C9-8BD3-58DC00E28815",
        "x": -8.515625,
        "y": 5.46875,
        "z": 0
      },
      {
        "uuid": "8218B090-CA2C-483E-BD42-33B1CA13ED6A",
        "x": 2.2500000000000004,
        "y": 5.46875,
        "z": 0
      },
      {
        "uuid": "1859DE54-43F9-48C7-92E1-4A7AB80416E1",
        "x": 2.2500000000000004,
        "y": -4.203125000000001,
        "z": 0
      },
      {
        "uuid": "E138C357-81EA-406C-B5BC-1421A7AEC71E",
        "x": -8.515625,
        "y": -4.203125000000001,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "329C56AA-DA83-4CE2-8AD2-730D753039D4",
        "startVertex": "352AB5F3-F0EB-41C9-8BD3-58DC00E28815",
        "endVertex": "8218B090-CA2C-483E-BD42-33B1CA13ED6A"
      },
      {
        "uuid": "334D974B-4E05-438F-ADA1-5A72C6A3541E",
        "startVertex": "8218B090-CA2C-483E-BD42-33B1CA13ED6A",
        "endVertex": "1859DE54-43F9-48C7-92E1-4A7AB80416E1"
      },
      {
        "uuid": "D598BC2B-286E-4986-A3BA-65BF7D67D586",
        "startVertex": "1859DE54-43F9-48C7-92E1-4A7AB80416E1",
        "endVertex": "E138C357-81EA-406C-B5BC-1421A7AEC71E"
      },
      {
        "uuid": "FFEF9D59-A99A-4201-966F-9E933257830B",
        "startVertex": "E138C357-81EA-406C-B5BC-1421A7AEC71E",
        "endVertex": "352AB5F3-F0EB-41C9-8BD3-58DC00E28815"
      }
    ]
  },
  {
    "uuid": "19208907-A107-4843-825E-AD451DC6A508",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.4,
      "offsetRight": 0
    },
    "vertices": [
      {
        "uuid": "290705C9-783B-4C3D-ADD0-D9C37F49AE41",
        "x": -5.515624999999998,
        "y": 2.78125,
        "z": 0
      },
      {
        "uuid": "E99A82BA-F775-42D8-8FC4-30455062F3B5",
        "x": -0.7500000000000001,
        "y": 2.78125,
        "z": 0
      },
      {
        "uuid": "2256972C-4E71-4CC4-B62B-7F53B430C4CD",
        "x": -0.7500000000000001,
        "y": -1.6406249999999998,
        "z": 0
      },
      {
        "uuid": "607B05F4-BD3B-4786-A3A0-F4405B1EE4EB",
        "x": -5.515624999999998,
        "y": -1.6406249999999998,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "724B9B94-F936-4BA9-88AA-B659C8B7EB14",
        "startVertex": "290705C9-783B-4C3D-ADD0-D9C37F49AE41",
        "endVertex": "E99A82BA-F775-42D8-8FC4-30455062F3B5"
      },
      {
        "uuid": "93FCB555-0790-42DA-8DE3-10490B7C5695",
        "startVertex": "E99A82BA-F775-42D8-8FC4-30455062F3B5",
        "endVertex": "2256972C-4E71-4CC4-B62B-7F53B430C4CD"
      },
      {
        "uuid": "FF4C821A-E9C7-4AA4-91A9-E1167BFB850F",
        "startVertex": "2256972C-4E71-4CC4-B62B-7F53B430C4CD",
        "endVertex": "607B05F4-BD3B-4786-A3A0-F4405B1EE4EB"
      },
      {
        "uuid": "FE1A3B7C-893A-4672-945E-7164E2B85D67",
        "startVertex": "607B05F4-BD3B-4786-A3A0-F4405B1EE4EB",
        "endVertex": "290705C9-783B-4C3D-ADD0-D9C37F49AE41"
      }
    ]
  },
  {
    "uuid": "77AED3CB-CDEF-4154-8875-BE3B53D2FE45",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "71D5C18F-FB80-4E45-9C78-C0E4F71E99DA",
        "x": -5.515624999999998,
        "y": 2.78125,
        "z": 0
      },
      {
        "uuid": "22D5A955-FCF9-4B5F-BD8D-8AD5D4501FE0",
        "x": -5.515624999999998,
        "y": 5.46875,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "4A78D091-9646-4D94-A82B-71F11EFEFA6A",
        "startVertex": "71D5C18F-FB80-4E45-9C78-C0E4F71E99DA",
        "endVertex": "22D5A955-FCF9-4B5F-BD8D-8AD5D4501FE0"
      }
    ]
  },
  {
    "uuid": "D3742901-F6E7-4D0E-BCEA-1331949E6375",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  },
  {
    "uuid": "712A3228-95B3-467B-BEB5-C7CC9427B67D",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  }
]

export const multipleOutlines = [
  {
    "uuid": "3A5F12F1-DB92-4B61-ADE9-9D27035D0AC4",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.2,
      "offsetRight": 0.2
    },
    "vertices": [
      {
        "uuid": "0B6D5DE9-7BCC-4FDD-A68B-8AC7519E7132",
        "x": -8.50627191727028,
        "y": 4.721543002537686,
        "z": 0
      },
      {
        "uuid": "F07B6B7E-3419-44C3-B9DD-19614621EB01",
        "x": 4.290608522147341,
        "y": 4.721543002537686,
        "z": 0
      },
      {
        "uuid": "B1DC426D-FEA0-40AD-9BFF-5C4D187E6DEC",
        "x": 4.290608522147341,
        "y": -5.995610161952618,
        "z": 0
      },
      {
        "uuid": "E3122BCA-9D00-4861-8AD7-612EC4FE3F2D",
        "x": -3.297585589073941,
        "y": -5.995610161952618,
        "z": 0
      },
      {
        "uuid": "2622C676-D911-44B2-9831-EE68096ABEDF",
        "x": -3.297585589073941,
        "y": -1.461429976975951,
        "z": 0
      },
      {
        "uuid": "3CE037D4-1D45-47FA-A54C-591DCDD63580",
        "x": -8.50627191727028,
        "y": -1.461429976975951,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "3C66C147-C557-41B6-93DB-761855F1DB04",
        "startVertex": "0B6D5DE9-7BCC-4FDD-A68B-8AC7519E7132",
        "endVertex": "F07B6B7E-3419-44C3-B9DD-19614621EB01"
      },
      {
        "uuid": "26B77E00-F153-46F1-80A1-5A71D650EFEB",
        "startVertex": "F07B6B7E-3419-44C3-B9DD-19614621EB01",
        "endVertex": "B1DC426D-FEA0-40AD-9BFF-5C4D187E6DEC"
      },
      {
        "uuid": "E5690AF6-6D45-4EAC-A558-572993587CCF",
        "startVertex": "B1DC426D-FEA0-40AD-9BFF-5C4D187E6DEC",
        "endVertex": "E3122BCA-9D00-4861-8AD7-612EC4FE3F2D"
      },
      {
        "uuid": "CEA1910F-48D0-4D53-A54D-BECF42BDE73D",
        "startVertex": "E3122BCA-9D00-4861-8AD7-612EC4FE3F2D",
        "endVertex": "2622C676-D911-44B2-9831-EE68096ABEDF"
      },
      {
        "uuid": "80513BDF-350E-4950-BEB3-A0106C6BA1F6",
        "startVertex": "2622C676-D911-44B2-9831-EE68096ABEDF",
        "endVertex": "3CE037D4-1D45-47FA-A54C-591DCDD63580"
      },
      {
        "uuid": "B52876A9-5556-472F-B63C-559D44311B10",
        "startVertex": "3CE037D4-1D45-47FA-A54C-591DCDD63580",
        "endVertex": "0B6D5DE9-7BCC-4FDD-A68B-8AC7519E7132"
      }
    ]
  },
  {
    "uuid": "8EBFAEEE-7B8B-41E8-9D28-8F084EA4DFC7",
    "geometryType": "polygon",
    "representationType": "outline",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0,
      "offsetRight": 0.4
    },
    "vertices": [
      {
        "uuid": "F78A601D-211E-4669-A53E-D706A8A1FFBB",
        "x": -27.40954092854986,
        "y": 4.119963943440478,
        "z": 0
      },
      {
        "uuid": "1E116CDD-6571-4D1C-B328-34FBF752139B",
        "x": -16.51601506090128,
        "y": 4.119963943440478,
        "z": 0
      },
      {
        "uuid": "A9299624-A722-4D69-B0FF-27F5FE27BB63",
        "x": -16.51601506090128,
        "y": -7.061919961881149,
        "z": 0
      },
      {
        "uuid": "9E4356C4-5C3E-41F9-B717-4981EC53128E",
        "x": -27.40954092854986,
        "y": -7.061919961881149,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "E906ADF5-F7D9-4545-B77C-BEABFFC4796E",
        "startVertex": "F78A601D-211E-4669-A53E-D706A8A1FFBB",
        "endVertex": "1E116CDD-6571-4D1C-B328-34FBF752139B"
      },
      {
        "uuid": "FAB34579-E1EE-4F13-BFCF-306A48651EDD",
        "startVertex": "1E116CDD-6571-4D1C-B328-34FBF752139B",
        "endVertex": "A9299624-A722-4D69-B0FF-27F5FE27BB63"
      },
      {
        "uuid": "2D8D49CD-39D4-4839-A839-66407CC51BC7",
        "startVertex": "A9299624-A722-4D69-B0FF-27F5FE27BB63",
        "endVertex": "9E4356C4-5C3E-41F9-B717-4981EC53128E"
      },
      {
        "uuid": "147E46F7-AA04-44EF-89AB-82BDBFE0A10D",
        "startVertex": "9E4356C4-5C3E-41F9-B717-4981EC53128E",
        "endVertex": "F78A601D-211E-4669-A53E-D706A8A1FFBB"
      }
    ]
  },
  {
    "uuid": "5D57EC7D-E29E-49BA-9D64-65E85A030BFF",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "1D98BF95-2A7F-4782-B41F-FF8DA282BE5E",
        "x": -4.346817367415649,
        "y": -1.461429976975951,
        "z": 0
      },
      {
        "uuid": "D4AE3344-4AD5-477E-898A-7B64E982966E",
        "x": -4.346817367415649,
        "y": 1.6487927945369703,
        "z": 0
      },
      {
        "uuid": "5B7F7509-6AA2-494E-8FFA-7F20CC8B6588",
        "x": -8.50627191727028,
        "y": 1.6487927945369703,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "28A42E67-F88E-4E81-9108-4B8FA5601C5D",
        "startVertex": "1D98BF95-2A7F-4782-B41F-FF8DA282BE5E",
        "endVertex": "D4AE3344-4AD5-477E-898A-7B64E982966E"
      },
      {
        "uuid": "5ABEAE37-02B4-49E5-A9C7-6E262C16F6D5",
        "startVertex": "D4AE3344-4AD5-477E-898A-7B64E982966E",
        "endVertex": "5B7F7509-6AA2-494E-8FFA-7F20CC8B6588"
      }
    ]
  },
  {
    "uuid": "DA22A4B8-A963-4A3B-9F1B-E12C6B755BA1",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "CAD40EAF-2E10-4D9B-A8DC-61008F7FF25C",
        "x": -4.346817367415649,
        "y": 1.6487927945369703,
        "z": 0
      },
      {
        "uuid": "42B2C9FA-435F-490B-9342-5EDBE50D5322",
        "x": -4.346817367415649,
        "y": 4.721543002537686,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "4BAAC5BD-9306-45EE-B711-43EF7BBCD83F",
        "startVertex": "CAD40EAF-2E10-4D9B-A8DC-61008F7FF25C",
        "endVertex": "42B2C9FA-435F-490B-9342-5EDBE50D5322"
      }
    ]
  },
  {
    "uuid": "AA080772-6BFA-482A-8ADF-76DD4CCDA951",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "C1E5A4A9-2445-46C5-BCFE-F3728161FA76",
        "x": -4.346817367415649,
        "y": 2.735497136390882,
        "z": 0
      },
      {
        "uuid": "8FEDAF6C-0151-453D-A43C-43434C39FFD8",
        "x": 0.24357166282932427,
        "y": 2.735497136390882,
        "z": 0
      },
      {
        "uuid": "F413EB8F-86F4-448E-A03F-D3B16FADA088",
        "x": 0.24357166282932427,
        "y": 4.721543002537686,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "8D08B491-0E24-4DB4-B8A6-5719DD859242",
        "startVertex": "C1E5A4A9-2445-46C5-BCFE-F3728161FA76",
        "endVertex": "8FEDAF6C-0151-453D-A43C-43434C39FFD8"
      },
      {
        "uuid": "2B7E15D3-4624-43A5-97E4-EA3ACE157C78",
        "startVertex": "8FEDAF6C-0151-453D-A43C-43434C39FFD8",
        "endVertex": "F413EB8F-86F4-448E-A03F-D3B16FADA088"
      }
    ]
  },
  {
    "uuid": "19DFAB61-27C9-4B1E-8EB0-B338A6184F75",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "9577150A-46DE-4333-9E78-C376A5ED4D97",
        "x": 0.24357166282932427,
        "y": -1.461429976975951,
        "z": 0
      },
      {
        "uuid": "31591198-1027-4E8B-BDD9-BEAD3E30A139",
        "x": 4.290608522147341,
        "y": -1.461429976975951,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "9BBF9884-BA7D-4BD9-86E7-DEBE1FC43068",
        "startVertex": "9577150A-46DE-4333-9E78-C376A5ED4D97",
        "endVertex": "31591198-1027-4E8B-BDD9-BEAD3E30A139"
      }
    ]
  },
  {
    "uuid": "F01445A9-77A3-4D88-BE10-C43E57C4CB9D",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "ABA17F3F-6385-49E4-8458-28D9061DB5BE",
        "x": 0.24357166282932427,
        "y": 0.8431326790245866,
        "z": 0
      },
      {
        "uuid": "A148AA1A-ED87-490E-9ACA-B6195EC3FFB8",
        "x": 2.3232989377566384,
        "y": 0.8431326790245866,
        "z": 0
      },
      {
        "uuid": "0488B283-04D8-457D-95D4-6EB52FB14F5F",
        "x": 2.3232989377566384,
        "y": -1.461429976975951,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "D4E63C85-B19B-4178-ACA8-27EB0870CA97",
        "startVertex": "ABA17F3F-6385-49E4-8458-28D9061DB5BE",
        "endVertex": "A148AA1A-ED87-490E-9ACA-B6195EC3FFB8"
      },
      {
        "uuid": "E86D49F7-285F-4700-B4C4-F08DCE384CEA",
        "startVertex": "A148AA1A-ED87-490E-9ACA-B6195EC3FFB8",
        "endVertex": "0488B283-04D8-457D-95D4-6EB52FB14F5F"
      }
    ]
  },
  {
    "uuid": "11E652D7-A2F0-46B2-8445-E1A734BC600F",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "DD24FEC8-47FA-497A-BB00-04CC7B42788B",
        "x": 4.290608522147341,
        "y": 2.735497136390882,
        "z": 0
      },
      {
        "uuid": "0646D31C-11D2-47D7-B1F6-D6D2139E2B0C",
        "x": 0.24357166282932427,
        "y": 2.735497136390882,
        "z": 0
      },
      {
        "uuid": "BB393603-5BD6-474C-828B-42AE968BDD29",
        "x": 0.24357166282932427,
        "y": -5.995610161952618,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "F26E9786-ADB4-4EDA-8393-E8DE1343A58E",
        "startVertex": "DD24FEC8-47FA-497A-BB00-04CC7B42788B",
        "endVertex": "0646D31C-11D2-47D7-B1F6-D6D2139E2B0C"
      },
      {
        "uuid": "DD773F95-771C-406C-BC87-652776804F70",
        "startVertex": "0646D31C-11D2-47D7-B1F6-D6D2139E2B0C",
        "endVertex": "BB393603-5BD6-474C-828B-42AE968BDD29"
      }
    ]
  },
  {
    "uuid": "D5EC7840-58A5-4DEF-8114-C7EEC693BEE2",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "B5957DBC-B1F5-4BE2-A447-FFECE068B07E",
        "x": -27.40954092854986,
        "y": -2.3520720132213238,
        "z": 0
      },
      {
        "uuid": "345CB885-8972-413F-A7F4-65CE3097C769",
        "x": -16.51601506090128,
        "y": -2.3520720132213238,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "95042CED-7669-472F-9522-77E8972A0B8C",
        "startVertex": "B5957DBC-B1F5-4BE2-A447-FFECE068B07E",
        "endVertex": "345CB885-8972-413F-A7F4-65CE3097C769"
      }
    ]
  },
  {
    "uuid": "A8CFD8B7-126F-47CF-866A-DF3B931D283D",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "C9333C6A-75FE-4045-81BD-8DDCFE4BF98D",
        "x": -21.578300611161502,
        "y": -7.061919961881149,
        "z": 0
      },
      {
        "uuid": "D72405CF-7E5B-4415-B431-336045E56AD6",
        "x": -21.578300611161502,
        "y": 4.119963943440478,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "8A46D586-ED61-4726-B38D-F2396B5E1429",
        "startVertex": "C9333C6A-75FE-4045-81BD-8DDCFE4BF98D",
        "endVertex": "D72405CF-7E5B-4415-B431-336045E56AD6"
      }
    ]
  },
  {
    "uuid": "BAE51391-539C-4290-B428-B35BCDFDAD2D",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "0F575780-72AD-4A30-B25C-2BC9C496C69B",
        "x": -21.578300611161502,
        "y": 0.8431326790245866,
        "z": 0
      },
      {
        "uuid": "F367CB16-EF79-415C-B201-5A938BD08E89",
        "x": -25.647352920547885,
        "y": 0.8431326790245866,
        "z": 0
      },
      {
        "uuid": "C94E0BCE-BE29-46B8-B506-8C6C7CB403EA",
        "x": -25.647352920547885,
        "y": 4.119963943440478,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "72FC2E4D-5A47-442B-90B6-0B4E65C6E657",
        "startVertex": "0F575780-72AD-4A30-B25C-2BC9C496C69B",
        "endVertex": "F367CB16-EF79-415C-B201-5A938BD08E89"
      },
      {
        "uuid": "80C84329-0F9D-4D59-AC75-A5A6C2EFAF1E",
        "startVertex": "F367CB16-EF79-415C-B201-5A938BD08E89",
        "endVertex": "C94E0BCE-BE29-46B8-B506-8C6C7CB403EA"
      }
    ]
  },
  {
    "uuid": "F0393D2E-31F7-47CE-BCCC-B6FE61DC2700",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "D262BDAE-C47B-4BE6-AC0D-56DE32743521",
        "x": -21.578300611161502,
        "y": 2.735497136390882,
        "z": 0
      },
      {
        "uuid": "FD006A1C-C983-4165-9ED7-DFB6685A14E7",
        "x": -18.758799798358343,
        "y": 2.735497136390882,
        "z": 0
      },
      {
        "uuid": "F9C4E998-8E8A-4176-B46E-DAD409FD4626",
        "x": -18.758799798358343,
        "y": -5.491970645661208,
        "z": 0
      },
      {
        "uuid": "C87898FE-15AC-4973-8660-0DAE1029DA55",
        "x": -21.578300611161502,
        "y": -5.491970645661208,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "46C3FAA6-6F6B-452D-8342-20CA70C75A5F",
        "startVertex": "D262BDAE-C47B-4BE6-AC0D-56DE32743521",
        "endVertex": "FD006A1C-C983-4165-9ED7-DFB6685A14E7"
      },
      {
        "uuid": "5F6E0465-F0DC-48F0-807A-20B50D6C7043",
        "startVertex": "FD006A1C-C983-4165-9ED7-DFB6685A14E7",
        "endVertex": "F9C4E998-8E8A-4176-B46E-DAD409FD4626"
      },
      {
        "uuid": "61AE8549-7DC1-4C8E-9917-002BD2A9174C",
        "startVertex": "F9C4E998-8E8A-4176-B46E-DAD409FD4626",
        "endVertex": "C87898FE-15AC-4973-8660-0DAE1029DA55"
      }
    ]
  },
  {
    "uuid": "9000270C-8343-4DF7-B881-126792693459",
    "geometryType": "polyline",
    "representationType": "wall",
    "isValidate": false,
    "errors": [],
    "properties": {
      "offsetLeft": 0.1,
      "offsetRight": 0.1
    },
    "vertices": [
      {
        "uuid": "B2DA73D0-5750-492C-965D-AE77161A9B50",
        "x": -24.237602514146303,
        "y": 0.8431326790245866,
        "z": 0
      },
      {
        "uuid": "9A37341E-CCD4-48EA-8611-BC17E15C8E8C",
        "x": -24.237602514146303,
        "y": -4.050180457295956,
        "z": 0
      },
      {
        "uuid": "05E4BF33-A96F-4132-B7D9-95BAA5DDEA5A",
        "x": -21.578300611161502,
        "y": -4.050180457295956,
        "z": 0
      }
    ],
    "edges": [
      {
        "uuid": "D6B8D6DC-C7BD-4E14-8EE3-0D2FFA5BCCC6",
        "startVertex": "B2DA73D0-5750-492C-965D-AE77161A9B50",
        "endVertex": "9A37341E-CCD4-48EA-8611-BC17E15C8E8C"
      },
      {
        "uuid": "4F8D0CD7-7301-4667-A28A-ADC1B4DC4FFC",
        "startVertex": "9A37341E-CCD4-48EA-8611-BC17E15C8E8C",
        "endVertex": "05E4BF33-A96F-4132-B7D9-95BAA5DDEA5A"
      }
    ]
  },
  {
    "uuid": "CCEF29D1-7677-4C18-B6C8-4BD2E80C15BE",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  },
  {
    "uuid": "99164147-687C-4BAF-A2EC-7CD9A9B8A822",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  },
  {
    "uuid": "24E2B8FB-CD43-4E79-9421-1B68E27762E0",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  },
  {
    "uuid": "81B903BC-5D12-4417-A6C0-A3F8422FAA4E",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  },
  {
    "uuid": "A60DA479-F6B7-47F0-A990-03FC2EDF1A9B",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  },
  {
    "uuid": "0BFA21CE-24E3-430B-9B21-A6EB0FACBAAA",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  },
  {
    "uuid": "9657400D-1769-43AB-B464-049C5A11969A",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  },
  {
    "uuid": "DC02A08A-B300-4264-88CE-0FEAA7A38F5D",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  },
  {
    "uuid": "055FF694-2F0C-4FAB-83C8-5F1DEB500EC3",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  },
  {
    "uuid": "A20E49B0-BEB3-4EE0-9ACC-86156500BA8A",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  },
  {
    "uuid": "8EAD4711-8670-4216-88FD-E88338E29A89",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  },
  {
    "uuid": "D0EA98F8-CA47-40AA-9C49-7E9C786D948A",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  },
  {
    "uuid": "D44F7AFE-BAE1-4023-A669-1B6E310F42D4",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  },
  {
    "uuid": "4018BEAF-9F92-44F8-8180-CD37A415531D",
    "geometryType": "geometry",
    "representationType": "space",
    "isValidate": false,
    "errors": []
  }
]