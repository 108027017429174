import React from 'react'

const SvgBathroomIndicator = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g
      transform="translate(6 5.54)"
      fill={props.fillColor}
      fillRule="nonzero"
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx={2.52} cy={1.62} r={1.62}/>
      <path
        d="M2.173 13.86a1.035 1.035 0 01-1.025-.952l-.237-2.664A1.08 1.08 0 010 9.181V6.12A1.62 1.62 0 011.617 4.5h1.8c.895 0 1.62.725 1.62 1.62v3.06c0 .531-.385.983-.91 1.067l-.237 2.664a1.036 1.036 0 01-1.024.952l-.693-.003z"/>
      <circle cx={9.757} cy={1.62} r={1.62}/>
      <path
        d="M9.374 13.86a1.036 1.036 0 01-1.021-.953l-.24-2.647H6.936a.477.477 0 01-.432-.631L7.71 5.813c.228-.769.928-1.3 1.73-1.313H10c.802.013 1.501.544 1.729 1.313l1.207 3.816a.477.477 0 01-.432.631h-1.177l-.238 2.647a1.037 1.037 0 01-1.023.953h-.692z"/>
    </g>
  </svg>
)

export default SvgBathroomIndicator
