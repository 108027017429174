import React, { Component } from 'react'
import { getAvailablePatternStyles } from '../../../common/redux/styles/styles'
import { connect } from 'react-redux'
import StyleSelectButton from '../../../common/components/StyleSelectButton'
import { Field, Form, Formik } from 'formik'

class TablePatternStyleSelectButton extends Component {

  render () {


    const allPatternStyles = [...this.props.allPatternStyles]

    const currentPatternStyle = allPatternStyles.find(patternStyle => patternStyle.id === this.props.patternStyle.id)

    if (this.props.patternStyle.custom) {
      currentPatternStyle.name = this.props.patternStyle.name + ' (Custom)'
    }

    const initialValue = { value: this.props.value }

    return (
      <Formik initialValues={initialValue} enableReinitialize>
        <Form onChange={(event) => this.handleStyleSelection(event.target.value)}>
          <Field component={StyleSelectButton}
                 styles={allPatternStyles}
                 name={'value'}/>
        </Form>
      </Formik>
    )
  }

  handleStyleSelection (selection) {
    let selectedStyle = this.props.patternStyles.find(style => style.id === selection)
    this.props.onChange(this.props.unitId, { patternStyle: { id: selectedStyle.id } })
  }

}

let mapStateToProps = (state) => {
  return {
    patternStyles: getAvailablePatternStyles(state),
    allPatternStyles: state.styles.patternStyles,
  }
}

export default connect(mapStateToProps)(TablePatternStyleSelectButton)