import React from 'react'

const SvgSuitcase = props => (
  <svg
    viewBox="0 0 24 24"
  >
    <path
      d="M14 7.5a1 1 0 011 1v1h2a1 1 0 011 1v5a1 1 0 01-1 1H7a1 1 0 01-1-1v-5a1 1 0 011-1h2v-1a1 1 0 011-1h4zm0 1h-4v1h4v-1z"
      fill="#00293B"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgSuitcase