import { PolylineController } from './PolylineController'

export class OffsetPolylineController extends PolylineController {

  onArrowLeftDown (event, offsetPolyline) {
    if (!offsetPolyline)
      return

    offsetPolyline.shiftOffsetToLeft()
    this.floorPlanerController.saveChanges()
  }

  onArrowRightDown (event, offsetPolyline) {
    if (!offsetPolyline)
      return

    offsetPolyline.shiftOffsetToRight()

    this.floorPlanerController.saveChanges()
  }

  onArrowUpDown (event, offsetPolyline) {
    if (!offsetPolyline)
      return

    offsetPolyline.addToOffset(.2)
    this.floorPlanerController.saveChanges()
  }

  onArrowDownDown (event, offsetPolyline) {
    if (!offsetPolyline)
      return

    offsetPolyline.addToOffset(-.2)
    this.floorPlanerController.saveChanges()
  }

}