import React from 'react'

const SvgZoomAllIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M19 14.5a.5.5 0 01.492.41l.008.09v4a.5.5 0 01-.41.492L19 19.5h-4a.5.5 0 01-.09-.992L15 18.5h3.5V15a.5.5 0 01.5-.5zm-14 0a.5.5 0 01.5.5v3.5H9a.5.5 0 110 1H5a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5zM15 8a1 1 0 011 1v6a1 1 0 01-1 1H9a1 1 0 01-1-1V9a1 1 0 011-1h6zm4-3.5a.5.5 0 01.492.41L19.5 5v4a.5.5 0 01-.992.09L18.5 9V5.5H15a.5.5 0 01-.09-.992L15 4.5h4zm-14 0h4a.5.5 0 01.09.992L9 5.5H5.5V9a.5.5 0 01-.992.09L4.5 9V5a.5.5 0 01.41-.492L5 4.5h4z"
    />
  </svg>
)

export default SvgZoomAllIcon
