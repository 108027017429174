import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dialog, { DialogButtonGroup, DialogContent } from '../../common/components/Dialog'
import { Form, Formik } from 'formik'
import { updateAndFetchVariations } from '../../common/redux/variations/variations-actions'
import './VariationsRenameDialog.scss'
import Button from '../../common/components/Button'

class VariationsRenameDialog extends Component {

  close = () => {
    this.props.onClose()
  }

  render () {

    return (
      <Dialog onCancel={this.close}
              title={'Rename variation'}
              text={'How would you like to name the current variation?'}
              className={'variation-rename-dialog'}>
        <DialogContent>
          <Formik initialValues={{ variation: { ...this.props.variation }, variationId: this.props.variation.id, projectId: this.props.projectId }}
                  onSubmit={(values) => this.handleFormSubmit(values.projectId, values.variationId, values.variation)}
                  render={props => (
                    <Form id={'rename-variation-form'} onSubmit={props.handleSubmit}>
                      <input
                        type="text"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.variation.name}
                        name="variation.name"
                      />
                    </Form>
                  )}/>
        </DialogContent>
        <DialogButtonGroup>
          <Button onClick={this.close}>Cancel</Button>
          <Button form={'rename-variation-form'} type="submit" variant={'contained'}>Confirm</Button>
        </DialogButtonGroup>
      </Dialog>
    )
  }

  handleFormSubmit (project, variationId, variation) {
    this.props.updateVariation(project, variationId, variation)
    this.close()
  }
}

let mapDispatchToProps = {
  updateVariation: updateAndFetchVariations,
}

export default connect(null, mapDispatchToProps)(VariationsRenameDialog)