import React from 'react'
import './ButtonGroup.scss'

function ButtonGroup ({ className, noGap, ...rest }) {

  let styleClass = (className ? ' ' + className : '') + (noGap ? ' no-gap' : '')

  return (
    <div {...rest} className={'button-group' + styleClass}/>
  )
}

export default ButtonGroup