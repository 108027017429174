import React from 'react'

const SvgHeatmapNoiseActive = props => (
  <svg className={props.className} width={36} height={36}>
    <defs>
      <linearGradient
        id="heatmap-noise-active_svg__a"
        x1="88.5%"
        x2="18.9%"
        y1="100%"
        y2="-29.7%"
      >
        <stop offset="0%" stopColor="#F85C4F"/>
        <stop offset="100%" stopColor="#D13B53"/>
      </linearGradient>
    </defs>
    <path
      fill="url(#heatmap-noise-active_svg__a)"
      fillRule="evenodd"
      d="M19.23 10.005c.305.031.58.195.755.447.17.232.261.512.26.8v13.49a1.2 1.2 0 01-1.122 1.257 1.056 1.056 0 01-.72-.291L13.5 21.141H9.563A.6.6 0 019 20.513v-5.028a.6.6 0 01.562-.628H13.5l4.9-4.566c.22-.212.524-.317.829-.286zm4.963 1.078c.154.001.3.071.398.191A9.994 9.994 0 0127 17.879a9.994 9.994 0 01-2.417 6.6.521.521 0 01-.8.008.682.682 0 01-.007-.889 8.658 8.658 0 002.094-5.724 8.658 8.658 0 00-2.09-5.719h.003a.682.682 0 01.007-.889.521.521 0 01.402-.183zM21.9 13.6a.522.522 0 01.409.158 5.952 5.952 0 011.7 4.229 5.952 5.952 0 01-1.7 4.229.522.522 0 01-.794-.052.681.681 0 01.047-.887 4.626 4.626 0 001.323-3.29 4.626 4.626 0 00-1.324-3.289v-.001a.681.681 0 01-.046-.887.522.522 0 01.385-.21z"
    />
  </svg>
);

export default SvgHeatmapNoiseActive
