import React from 'react'

const SvgInfoIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill={props.color} fillRule="evenodd">
      <path d="M11.8 19.6a7.8 7.8 0 100-15.6 7.8 7.8 0 000 15.6zm0-1.2a6.6 6.6 0 110-13.2 6.6 6.6 0 010 13.2z"/>
      <path
        d="M12.63 10.3v3.892c0 .34.1.491.299.526l.079.007.292.008v.967h-2.957v-.975h.3c.263 0 .432-.119.47-.353l.009-.107v-2.331l-.822-.396v-.64l1.76-.598h.57zm-.83-2.4c.499 0 .9.401.9.9s-.401.9-.9.9a.897.897 0 01-.9-.9c0-.499.401-.9.9-.9z"/>
    </g>
  </svg>
)

export default SvgInfoIcon
