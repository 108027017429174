import React from 'react'

const SvgAppCreatorElectronicsIconInactive = props => (
  <svg className={props.className} viewBox="0 0 54 54">
    <path data-name="Rechteck 426" fill="none" d="M0 0h54v54H0z"/>
    <path
      data-name="Pfad 921"
      d="M23.906 21.883c-.462 0-.977-.346-.977-.773v-3.336c0-.427.514-.774.977-.774s.977.347.977.774v3.336c-.001.427-.515.773-.977.773zm5.859 0c-.462 0-.976-.346-.976-.773v-3.336c0-.427.514-.774.976-.774s.977.347.977.774v3.336c0 .427-.514.773-.977.773zm-2.93 14.645a1.594 1.594 0 0 1-.241 0 1.514 1.514 0 0 1-1.374-1.52v-2.72a1.607 1.607 0 0 0-1-1.5 6.69 6.69 0 0 1-4.221-6.205v-.84a.894.894 0 0 1 .907-.883h11.859a.894.894 0 0 1 .907.883v.84a6.69 6.69 0 0 1-4.223 6.209 1.607 1.607 0 0 0-1 1.5v2.72a1.514 1.514 0 0 1-1.374 1.52 1.594 1.594 0 0 1-.24-.004z"
      fill="#8f939d"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgAppCreatorElectronicsIconInactive
