import * as THREE from 'three'
import {
  createEdge,
  createFaceGeometry,
  createTubeGeometry,
  createVertex,
  drawingSegments,
  drawingSize,
  getCircleGeometry,
  getDrawingTypesMaterial,
  insertChildIntoGroup,
  removeItemFromArray,
  threeMaterial,
} from './drawConstants'

function DrawPolygon () {
  THREE.Mesh.apply(this, arguments)
  this.vertexGroup = new THREE.Group()
  this.vertexGroup.name = 'vertices'
  this.edgeGroup = new THREE.Group()
  this.edgeGroup.name = 'edges'
  this.face = null
  this.closed = false
  this.selectedVertex = null

  this.handlerScale = 1

  this.add(this.vertexGroup)
  this.add(this.edgeGroup)

  this.init = function (position, type = 'polygon') {
    this.type = type

    const { innerMaterial, material } = getDrawingTypesMaterial(this.type)
    let vertexMesh = createVertex(material, innerMaterial, this.handlerScale)

    vertexMesh.position.set(position.x, 0.05, position.z)

    this.vertexGroup.add(vertexMesh)

    this.addNewVertex(position)

    this.scale.y = 0.01
  }

  this.reInit = function (vertices, type = 'polygon') {
    this.type = type

    vertices.forEach(vertex => {
      const { innerMaterial } = getDrawingTypesMaterial(this.type)
      const vertexMesh = createVertex(threeMaterial.transparent, innerMaterial, this.handlerScale)

      vertexMesh.position.set(vertex[0], 0.05, vertex[1])

      this.vertexGroup.add(vertexMesh)
    })

    const { innerMaterial } = getDrawingTypesMaterial(this.type)

    for (let i = 0; i < this.vertexGroup.children.length; i++) {
      const previousVertex = this.vertexGroup.children[i]
      const nextVertex = this.vertexGroup.children[(i + 1) % this.vertexGroup.children.length]

      const edgeMesh = createEdge(previousVertex, nextVertex, innerMaterial)

      this.edgeGroup.add(edgeMesh)
    }

    this.scale.y = 0.01
    const faceGeometry = createFaceGeometry(this.vertexGroup.children)
    const faceMesh = new THREE.Mesh(faceGeometry, threeMaterial.transparent)
    faceMesh.name = 'face'
    faceMesh.position.y -= .01

    this.face = faceMesh

    this.add(this.face)

    this.closed = true
    this.lastVertex = null
  }

  this.addNewVertex = function (position) {
    if (this.closed)
      return

    const { innerMaterial, material } = getDrawingTypesMaterial(this.type)
    const vertexMesh = createVertex(material, innerMaterial, this.handlerScale)

    vertexMesh.position.set(position.x, 0.05, position.z)

    this.lastVertex = vertexMesh
    this.vertexGroup.add(vertexMesh)

    const previousVertex = this.vertexGroup.children[this.vertexGroup.children.length - 2]

    const edgeMesh = createEdge(previousVertex, vertexMesh, innerMaterial)

    this.edgeGroup.add(edgeMesh)
  }

  this.removeNewVertex = function () {
    if (this.vertexGroup.children.length <= 2 && !this.closed) {
      return
    }

    this.vertexGroup.remove(this.vertexGroup.children[this.vertexGroup.children.length - 1])
    this.edgeGroup.remove(this.edgeGroup.children[this.edgeGroup.children.length - 1])

    this.lastVertex = this.vertexGroup.children[this.vertexGroup.children.length - 1]
  }

  this.deleteVertex = function (vertex) {
    if (this.vertexGroup.children.length <= 3)
      return

    let index = this.vertexGroup.children.indexOf(vertex)

    if (index < 0)
      return

    const previousVertex = this.vertexGroup.children[(index + this.vertexGroup.children.length - 1) % this.vertexGroup.children.length]
    const nextVertex = this.vertexGroup.children[(index + this.vertexGroup.children.length + 1) % this.vertexGroup.children.length]

    const neighborEdges = this.edgeGroup.children.filter(edge => vertex.edges.includes(edge.uuid))

    const { innerMaterial } = getDrawingTypesMaterial(this.type)

    const newEdge = createEdge(previousVertex, nextVertex, innerMaterial)

    this.edgeGroup = insertChildIntoGroup(this.edgeGroup, newEdge, index)

    this.edgeGroup.remove(neighborEdges[0])
    this.edgeGroup.remove(neighborEdges[1])

    this.vertexGroup.remove(vertex)

    this.vertexGroup.children.forEach(vertex => {
      removeItemFromArray(vertex.edges, neighborEdges[0].uuid)
      removeItemFromArray(vertex.edges, neighborEdges[1].uuid)
    })

    this.updateFace()

    this.deselectVertices()
  }

  this.selectVertex = function (vertex) {
    this.vertexGroup.children.filter(v => v !== vertex)
      .forEach(v => v.geometry = getCircleGeometry(drawingSize.handleSizeDeselected * this.handlerScale, drawingSegments.vertex))
    vertex.geometry = getCircleGeometry(drawingSize.handlerSizeSelected * this.handlerScale, drawingSegments.vertex)

    this.selectedVertex = vertex
  }

  this.deselectVertices = function () {
    this.vertexGroup.children.forEach(v => v.geometry = getCircleGeometry(drawingSize.handlerSize * this.handlerScale, drawingSegments.vertex))

    this.selectedVertex = null
  }

  this.updateVertex = function (vertex, position) {
    if (!vertex)
      vertex = this.lastVertex

    vertex.position.set(position.x, 0.05, position.z)

    const index = this.vertexGroup.children.findIndex(v => v.uuid === vertex.uuid)

    if (index < 0)
      return

    let previousVertex = this.vertexGroup.children[((index + this.vertexGroup.children.length) - 1) % this.vertexGroup.children.length]
    let nextVertex = this.vertexGroup.children[(index + 1) % this.vertexGroup.children.length]

    if (previousVertex) {
      let previousEdge = this.edgeGroup.children.find(edge => edge.vertices.includes(vertex.uuid) && edge.vertices.includes(previousVertex.uuid))

      if (previousEdge) {
        const path = new THREE.LineCurve(previousVertex.position, vertex.position)
        previousEdge.geometry = new THREE.TubeBufferGeometry(path, 0, drawingSize.edgeThickness)
        previousEdge.children[0].geometry = new THREE.TubeBufferGeometry(path, 0, drawingSize.edgeCatchThickness)
      }
    }

    if (nextVertex && this.closed) {
      let nextEdge = this.edgeGroup.children.find(edge => edge.vertices.includes(vertex.uuid) && edge.vertices.includes(nextVertex.uuid))

      if (nextEdge) {
        const path = new THREE.LineCurve(nextVertex.position, vertex.position)
        nextEdge.geometry = new THREE.TubeBufferGeometry(path, 0, drawingSize.edgeThickness)
        nextEdge.children[0].geometry = new THREE.TubeBufferGeometry(path, 0, drawingSize.edgeCatchThickness)
      }
    }

    if (this.face)
      this.face.geometry = createFaceGeometry(this.vertexGroup.children)
  }

  this.addVertexToEdge = function (edge, position) {
    const neighborVertices = this.vertexGroup.children.filter(vertex => edge.vertices.includes(vertex.uuid))

    if (neighborVertices.length !== 2)
      return

    const { material, innerMaterial } = getDrawingTypesMaterial(this.type)

    const vertexMesh = createVertex(material, innerMaterial, this.handlerScale)
    vertexMesh.material = material
    vertexMesh.position.set(position.x, 0.05, position.z)

    const deleteEdgeIndex = this.edgeGroup.children.indexOf(edge)

    let nextVertexIndex = this.vertexGroup.children.indexOf(neighborVertices[1])

    if (this.vertexGroup.children.indexOf(neighborVertices[0]) === 0 &&
      this.vertexGroup.children.indexOf(neighborVertices[1]) === this.vertexGroup.children.length - 1) {
      this.vertexGroup.add(vertexMesh)

      this.edgeGroup.add(createEdge(neighborVertices[1], vertexMesh, innerMaterial))
      this.edgeGroup.add(createEdge(vertexMesh, neighborVertices[0], innerMaterial))
    } else {
      const previousEdge = createEdge(neighborVertices[0], vertexMesh, innerMaterial)
      const nextEdge = createEdge(vertexMesh, neighborVertices[1], innerMaterial)

      this.edgeGroup = insertChildIntoGroup(this.edgeGroup, previousEdge, deleteEdgeIndex)
      this.edgeGroup = insertChildIntoGroup(this.edgeGroup, nextEdge, deleteEdgeIndex + 1)

      this.vertexGroup = insertChildIntoGroup(this.vertexGroup, vertexMesh, nextVertexIndex)
    }

    this.edgeGroup.remove(edge)

    removeItemFromArray(neighborVertices[0].edges, edge.uuid)
    removeItemFromArray(neighborVertices[1].edges, edge.uuid)

    this.updateFace()

    return vertexMesh
  }

  this.setHandlerScale = function (handlerScale, update = true) {
    this.handlerScale = handlerScale

    if (update) {
      this.edgeGroup.children.forEach(edge => this.updateEdgeCatchScale(edge))

      if (!this.selectedVertex) {
        this.vertexGroup.children.forEach(v => v.geometry = getCircleGeometry(drawingSize.handlerSize * handlerScale, drawingSegments.vertex))
      } else {
        this.selectVertex(this.selectedVertex)
      }
    }
  }

  this.updateEdgeCatchScale = function (edge) {
    const vertices = this.vertexGroup.children.filter(vertex => edge.vertices.includes(vertex.uuid))

    if (vertices.length !== 2 || edge.children.length === 0) {
      return
    }

    edge.children[0].geometry = createTubeGeometry(vertices[0].position, vertices[1].position, this.handlerScale * drawingSize.edgeCatchThickness)
  }

  this.closePolygon = function () {
    if (this.vertexGroup.children.length <= 3)
      return false

    this.vertexGroup.remove(this.vertexGroup.children[this.vertexGroup.children.length - 1])

    const { innerMaterial } = getDrawingTypesMaterial(this.type)

    this.edgeGroup.children[this.edgeGroup.children.length - 1] = createEdge(this.vertexGroup.children[this.vertexGroup.children.length - 1], this.vertexGroup.children[0], innerMaterial)

    const faceGeometry = createFaceGeometry(this.vertexGroup.children)
    const faceMesh = new THREE.Mesh(faceGeometry, threeMaterial.transparent)
    faceMesh.position.y -= .01

    this.face = faceMesh
    this.face.name = 'face'

    this.add(this.face)

    this.closed = true
    this.lastVertex = null
    this.setActive(false)

    return true
  }

  this.moveDelta = function (positionDelta) {
    this.vertexGroup.children.forEach(vertex => vertex.position.add(positionDelta))

    this.edgeGroup.children.forEach(edge => {
      const vertices = this.vertexGroup.children.filter(vertex => edge.vertices.includes(vertex.uuid))

      const path = new THREE.LineCurve(vertices[0].position, vertices[1].position)
      edge.geometry = new THREE.TubeBufferGeometry(path, 0, drawingSize.edgeThickness)
      edge.children[0].geometry = new THREE.TubeBufferGeometry(path, 0, drawingSize.edgeCatchThickness)
    })

    this.updateFace()
  }

  this.updateFace = function () {
    if (this.face)
      this.face.geometry = createFaceGeometry(this.vertexGroup.children)
  }

  this.getFirstVertex = function () {
    return this.vertexGroup.children ? this.vertexGroup.children[0] : null
  }

  this.getSecondLastVertexPosition = function () {
    if (this.vertexGroup.children.length > 1)
      return this.vertexGroup.children[this.vertexGroup.children.length - 2].position.clone()
  }

  this.getDistanceToPreviousVertex = function () {
    if (this.vertexGroup.children.length > 1) {
      return this.vertexGroup.children[this.vertexGroup.children.length - 2].position.distanceTo(this.vertexGroup.children[this.vertexGroup.children.length - 1].position)
    } else {
      return -1
    }
  }

  this.setActive = function (active) {
    const { material } = getDrawingTypesMaterial(this.type)

    this.vertexGroup.children.forEach(vertex => vertex.material = active ? material : threeMaterial.transparent)
    if (this.face) {
      this.face.material = active ? threeMaterial.face : threeMaterial.transparent
    }

    if (active) {
      this.deselectVertices()
    }
  }

  this.setFaceMaterial = function (material) {
    if (this.face) {
      this.face.material = material
    }
  }

  this.getGeometryAsJSON = function () {
    const vertices = []
    const geometryObject = { uuid: this.uuid, type: this.type, vertices: vertices, isValidate: this.isValidate, error: this.errorMessage, radius: this.radius }

    this.vertexGroup.children.forEach((vertex) => {
      const position = vertex.position
      vertices.push([position.x, -position.z])
    })

    return geometryObject
  }

  this.setGeometryFromJSON = function (json) {
    this.uuid = json.uuid
    this.isValidate = json.isValidate
    this.errorMessage = json.error
    this.radius = json.radius
    const vertices = json.vertices
    const flippedVertices = []
    vertices.forEach(vertex => flippedVertices.push([vertex[0], -vertex[1]]))

    this.reInit(flippedVertices, json.type)
  }

}

DrawPolygon.prototype = Object.create(THREE.Mesh.prototype)
DrawPolygon.prototype.constructor = DrawPolygon
export { DrawPolygon }