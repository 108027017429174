import React from 'react'
import {  useSelector } from 'react-redux'

import { viewNames } from '../../common/globalConstants'

import ProjectMenuItem from './ProjectMenuItem'
import FurnitureMenuItem from './FurnitureMenuItem'
import HelpMenuItem from './HelpMenuItem'

import { NavLinkMenuItem } from './NavLinkMenuItem'

import './NavigationMenu.scss'
import { useParams } from 'react-router'

function MenuItem (props) {

  const { projectId } = useParams()
  const urlBase = projectId ? `/projects/${projectId}` : ''
  const styleClass = props.className ? ' ' + props.className : ''

  return (
    <li className={styleClass}>
      <NavLinkMenuItem className={props.className}
                       icon={props.icon}
                       urlBase={urlBase}
                       view={props.view}
                       title={props.title}
                       darkMode={props.darkMode}/>
    </li>)
}

const NavigationMenu = () => {
  const { projectId } = useParams();

  const advancedSettings = useSelector((state) => state.settings.appSettings.advancedSettings);

  const isFurnitureMenuActive = () => {
    const applicationURL = `/projects/${projectId}/${viewNames.SETS}`;
    const patternURL = `/projects/${projectId}/${viewNames.PATTERNS}`;

    const pathname = window.location.pathname;

    return pathname && (pathname.startsWith(applicationURL) || pathname.startsWith(patternURL));
  };

  return (
    <ul>
      <div className={'navigation-menu' + (advancedSettings ? ' dark-mode' : '')}>
        <ProjectMenuItem />
        <MenuItem projectId={projectId} title={'Floorplan'} view={viewNames.FLOORPLAN} icon={'floorplan'} darkMode={advancedSettings}/>
        <MenuItem projectId={projectId} title={'Floors'} view={viewNames.FLOORS} darkMode={advancedSettings}/>
        <MenuItem projectId={projectId} title={'People'} view={viewNames.PEOPLE} darkMode={advancedSettings}/>
        {advancedSettings ? <FurnitureMenuItem projectId={projectId} active={isFurnitureMenuActive()}/> : null}
        <MenuItem projectId={projectId} title={'Settings'} view={viewNames.SETTINGS} darkMode={advancedSettings}/>
        <HelpMenuItem />
      </div>
    </ul>
  );
};

export default NavigationMenu;


