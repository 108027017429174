import { UPDATE_FURNITURE_MENU_ITEM_DRAG_ID } from './furnituredag-actions'

const initialState = {
  furnitureMenuItemDragId: 0,
}

function furnitureDragReducer (state = initialState, action) {

  if (action.type === UPDATE_FURNITURE_MENU_ITEM_DRAG_ID) {
    return { ...state, furnitureMenuItemDragId: action.id }
  } else {
    return { ...state }
  }
}

export default furnitureDragReducer