export const notificationsActionTypes = {

  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  RESOLVE_NOTIFICATION: 'RESOLVE_NOTIFICATION',

}

/*
* Manually add a notification. Usually there is not need to call this!!!
* A notification is created automatically an error occurs while performing a backend request
*/
export function addNotification (notification) {
  return {
    type: notificationsActionTypes.ADD_NOTIFICATION,
    notification: notification,
  }
}

export function resolveNotification (notification) {
  return {
    type: notificationsActionTypes.RESOLVE_NOTIFICATION,
    notification: notification,
  }
}