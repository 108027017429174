import { FULFILLED, PENDING, REJECTED } from '../floorplan/constants'

const FETCH_PATTERN_LIST = 'FETCH_PATTERN_LIST'
const FETCH_FURNITURE_LIBRARY = 'FETCH_FURNITURE_LIBRARY'

const CHANGE_PATTERN_FURNITURE_POSITION = 'CHANGE_PATTERN_FURNITURE_POSITION'
const CHANGE_PATTERN_FURNITURE_ROTATION = 'CHANGE_PATTERN_FURNITURE_ROTATION'
const CHANGE_PATTERN_FURNITURE_ROTATION_90 = 'CHANGE_PATTERN_FURNITURE_ROTATION_90'
const UPDATE_PATTERN_WORKPLACE_COUNT = 'UPDATE_PATTERN_WORKPLACE_COUNT'
const CHANGE_PATTERN_FURNITURE_PARENT = 'CHANGE_PATTERN_FURNITURE_PARENT'
const ADD_PATTERN_FURNITURE = 'ADD_PATTERN_FURNITURE'
const DELETE_FURNITURE = 'DELETE_FURNITURE'
const REPLACE_PATTERN_DATA = 'REPLACE_PATTERN_DATA'
const FETCH_PATTERN = 'FETCH_PATTERN'
const UPDATE_PATTERN = 'UPDATE_PATTERN'
const DELETE_PATTERN = 'DELETE_PATTERN'
const CREATE_PATTERN = 'CREATE_PATTERN'

export const patternBuilderActionTypes = {

  // App state changes

  SET_PATTERN: 'SET_PATTERN',
  RESET_DIRTY_FLAG: 'RESET_DIRTY_FLAG',

  // Frontend communication
  UPDATE_PATTERN_PLAN: 'UPDATE_PATTERN_PLAN',
  UPDATE_PATTERN_COUNT: 'UPDATE_PATTERN_COUNT',
  UPDATE_PATTERN_AREA: 'UPDATE_PATTERN_AREA',
  ADD_NEW_PATTERN: 'ADD_NEW_PATTERN',
  DISCARD_NEW_PATTERN: 'DISCARD_NEW_PATTERN',
  RENAME_PATTERN: 'RENAME_PATTERN',
  DUPLICATE_PATTERN: 'DUPLICATE_PATTERN',
  ROTATE_FURNITURE: 'ROTATE_FURNITURE',
  FURNITURE_ANCHOR_SIDE: 'FURNITURE_ANCHOR_SIDE',

  // Backend communication

  FETCH_PATTERN_LIST: FETCH_PATTERN_LIST,
  FETCH_PATTERN_LIST_PENDING: FETCH_PATTERN_LIST + PENDING,
  FETCH_PATTERN_LIST_FULFILLED: FETCH_PATTERN_LIST + FULFILLED,
  FETCH_PATTERN_LIST_REJECTED: FETCH_PATTERN_LIST + REJECTED,

  FETCH_PATTERN: FETCH_PATTERN,
  FETCH_PATTERN_PENDING: FETCH_PATTERN + PENDING,
  FETCH_PATTERN_FULFILLED: FETCH_PATTERN + FULFILLED,
  FETCH_PATTERN_REJECTED: FETCH_PATTERN + REJECTED,

  FETCH_FURNITURE_LIBRARY: FETCH_FURNITURE_LIBRARY,
  FETCH_FURNITURE_LIBRARY_PENDING: FETCH_FURNITURE_LIBRARY + PENDING,
  FETCH_FURNITURE_LIBRARY_FULFILLED: FETCH_FURNITURE_LIBRARY + FULFILLED,
  FETCH_FURNITURE_LIBRARY_REJECTED: FETCH_FURNITURE_LIBRARY + REJECTED,

  UPDATE_PATTERN: UPDATE_PATTERN,
  UPDATE_PATTERN_PENDING: UPDATE_PATTERN + PENDING,
  UPDATE_PATTERN_FULFILLED: UPDATE_PATTERN + FULFILLED,
  UPDATE_PATTERN_REJECTED: UPDATE_PATTERN + REJECTED,

  DELETE_PATTERN: DELETE_PATTERN,
  DELETE_PATTERN_PENDING: DELETE_PATTERN + PENDING,
  DELETE_PATTERN_FULFILLED: DELETE_PATTERN + FULFILLED,
  DELETE_PATTERN_REJECTED: DELETE_PATTERN + REJECTED,

  CREATE_PATTERN: CREATE_PATTERN,
  CREATE_PATTERN_PENDING: CREATE_PATTERN + PENDING,
  CREATE_PATTERN_FULFILLED: CREATE_PATTERN + FULFILLED,
  CREATE_PATTERN_REJECTED: CREATE_PATTERN + REJECTED,

  CHANGE_PATTERN_FURNITURE_POSITION: CHANGE_PATTERN_FURNITURE_POSITION,
  CHANGE_PATTERN_FURNITURE_ROTATION: CHANGE_PATTERN_FURNITURE_ROTATION,
  CHANGE_PATTERN_FURNITURE_ROTATION_90: CHANGE_PATTERN_FURNITURE_ROTATION_90,
  CHANGE_PATTERN_FURNITURE_PARENT: CHANGE_PATTERN_FURNITURE_PARENT,
  UPDATE_PATTERN_WORKPLACE_COUNT: UPDATE_PATTERN_WORKPLACE_COUNT,
  ADD_PATTERN_FURNITURE: ADD_PATTERN_FURNITURE,
  DELETE_FURNITURE: DELETE_FURNITURE,
  REPLACE_PATTERN_DATA: REPLACE_PATTERN_DATA,

}

