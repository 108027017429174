import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SvgIcon from './svgIcons/SvgIconFactory'
import './SvgDivider.scss'

class SvgDivider extends Component {

  constructor (props) {
    super(props)
    this.state = {
      hover: false,
    }
  }

  render () {
    //console.log("render SVG divider: "+this.props.icon)

    const styleClass = 'divider-button' +
      (this.props.className ? ' ' + this.props.className : '') +
      (this.props.selected ? ' selected' : '') +
      (this.props.border ? '' : ' no-border') +
      (this.props.disabled ? ' disabled' : '')
    {
      //console.log("not active on hover: "+this.props.selected+"  "+this.props.disabled)

      return (

          <SvgIcon disabled={this.props.disabled} name="divider" active={this.props.selected} highlight={this.props.highlight}/>

      )
    }

  }

  toggleHover () {
    this.setState({ hover: !this.state.hover })
  }

}

SvgDivider.protoType = {
  icon: PropTypes.string,
  active: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
}

export default SvgDivider
