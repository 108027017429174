import React from 'react'

import Text from './Text'
import Button from './Button'

import './InspectorHeader.scss'

function InspectorHeader ({ title, buttonText, buttonIcon, onButtonClick }) {

  return (
    <div className={'inspector-header'}>
      <Text component={'h3'} multiline>
        {title}
      </Text>
      {buttonText ? <Button className={'inspector-header-edit-outline'} secondIcon={buttonIcon} onClick={() => onButtonClick()}>{buttonText}</Button> : null}
    </div>
  )
}

export default InspectorHeader