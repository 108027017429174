import React from 'react'

const SvgArrowSmallDown = props => (
  <svg className={props.className} viewBox="0 0 44 44">
    <path
      d="M25.564 20.315a.479.479 0 00-.668-.03L22 22.878l-2.897-2.593a.479.479 0 00-.566-.053l-.072.053-.03.03a.384.384 0 00.03.542l3.192 2.858c.097.087.22.127.342.121a.477.477 0 00.342-.121l3.192-2.858a.384.384 0 00.03-.542z"
      fill="#464C56"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgArrowSmallDown
