import * as React from 'react'

const SvgMovingCar = props =>
  (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
    >
      <path
        d="M14.905 8l-3.5-4.78A.5.5 0 0011 3H1.5a.5.5 0 00-.5.5V12a.5.5 0 00.5.5h1.07a2 2 0 003.86 0h3.14a2 2 0 003.86 0h1.07a.5.5 0 00.5-.5V8.28a.507.507 0 00-.095-.28zM10 4h.745l2.565 3.5H10V4zm-5.5 9a1 1 0 110-2 1 1 0 010 2zm7 0a1 1 0 110-2 1 1 0 010 2zm2.5-1.5h-.57a2 2 0 00-3.86 0H6.43a2 2 0 00-3.86 0H2V4h7v4.5h5v3z"
        fill="#00293B"
        fillRule="evenodd"
      />
    </svg>
  )

export default SvgMovingCar
