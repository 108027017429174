import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import BuilderOverviewGroup from '../../../common/components/builder/BuilderOverviewGroup'
import BuilderOverviewNavBar from '../../../common/components/builder/BuilderOverviewNavBar'
import ModalConsumer from '../../../common/components/modal/ModalConsumer'
import Button from '../../../common/components/Button'
import ButtonToolbar from '../../../common/components/ButtonToolbar'
import UploadDialog from '../../../header/components/UploadDialog'
import { iconTypes } from '../../../common/components/icons/IconFactory'

import { deletePatterns, exportPatterns, importPatterns } from '../../../common/redux/patterns/patterns-actions'
import { deletePattern, duplicatePattern, renamePattern, updatePattern } from '../../actions/actions'

import './PatternBuilderOverview.scss'
import { viewNames } from '../../../common/globalConstants'

const PatternBuilderOverview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();
  const patterns = useSelector(state => state.pattern.patterns);

  const basePath = '/projects/' + projectId

  const createNewPattern = () => {
    navigate(`${basePath}/${viewNames.PATTERNS}/new${location.search}`);
  };

  const setCurrentPattern = (patternId) => {
    navigate(`${basePath}/${viewNames.PATTERNS}/${patternId}${location.search}`);
  };

  const handleRenamePattern = (pattern) => {
    dispatch(renamePattern(pattern.id, pattern.displayName));
    dispatch(updatePattern(projectId, pattern));
  };

  const handleDuplicateClicked = (pattern) => {
    dispatch(duplicatePattern(projectId, pattern));
  };

  return (
    <div className={'pattern-builder-overview'}>
      <BuilderOverviewNavBar icon={iconTypes.patternGreen} text={`Patterns (${patterns.length})`}>
        <div className={'button-container'}>
          <ModalConsumer>
            {({ showModal }) => (
              <ButtonToolbar>
                <Button className={'button'} variant={'text'}  icon={iconTypes.addPattern} onClick={() => showModal(UploadDialog, {
                  title: 'Import patterns file',
                  upload: (file, replace) => dispatch(importPatterns(file, replace)),
                  replace: false,
                  fileEndings: '.coa',
                })}>
                  Import...
                </Button>
                <Button className={'button'} variant={'text'} icon={iconTypes.replacePattern} onClick={() => showModal(UploadDialog, {
                  title: 'Replace patterns file',
                  upload: (file, replace) => dispatch(importPatterns(file, replace)),
                  replace: true,
                  fileEndings: '.coa',
                })}>
                  Replace...
                </Button>
                <Button className={'button'} variant={'text'} icon={iconTypes.exportFurnitureSets} onClick={() => dispatch(exportPatterns())}>
                  Export
                </Button>
                <Button className={'button'} variant={'text'} icon={iconTypes.delete} onClick={() => dispatch(deletePatterns())}>
                  Delete All
                </Button>
              </ButtonToolbar>
            )}
          </ModalConsumer>
        </div>
      </BuilderOverviewNavBar>

      <BuilderOverviewGroup projectId={projectId}
                            overviewContent={patterns}
                            onElementClicked={setCurrentPattern}
                            onCreateClicked={createNewPattern}
                            onDuplicateClicked={handleDuplicateClicked}
                            onDeleteClicked={(projectId, patternId) => dispatch(deletePattern(projectId, patternId))}
                            renameContent={handleRenamePattern}/>
    </div>
  );
};

export default PatternBuilderOverview;
