import { floorPlanActionTypes, FULFILLED, PENDING, REJECTED } from '../../../floorplan/constants'
import { httpClient } from '../../httpClient'
import { getProjectId, projectApi, projectVariationApi } from '../../utils'
import { fetchDepartmentAssignments, fetchFloorData, fetchPlacedOrganisationalUnits } from '../../../floorplan/actions/actions'
import { getPlacedOrganisationalUnit } from '../../../floorplan/reducers/organisationalUnits'
import store from '../../../store'
import { getUndoInformation } from '../undo/undo-actions'

const FETCH_ORGANISATIONAL_UNITS = 'FETCH_ORGANISATIONAL_UNITS'
const UPDATE_ORGANISATIONAL_UNIT = 'UPDATE_ORGANISATIONAL_UNIT'
const ADD_ORGANISATIONAL_UNIT = 'ADD_ORGANISATIONAL_UNIT'
const DELETE_ORGANISATIONAL_UNIT = 'DELETE_ORGANISATIONAL_UNIT'
const UNDO_ORGANISATIONAL_UNIT = 'UNDO_ORGANISATIONAL_UNIT'
const UPLOAD_CORPORATE_FILE = 'UPLOAD_CORPORATE_FILE'

export const organisationalUnitsActionTypes = {

  FETCH_ORGANISATIONAL_UNITS: FETCH_ORGANISATIONAL_UNITS,
  FETCH_ORGANISATIONAL_UNITS_PENDING: FETCH_ORGANISATIONAL_UNITS + PENDING,
  FETCH_ORGANISATIONAL_UNITS_FULFILLED: FETCH_ORGANISATIONAL_UNITS + FULFILLED,
  FETCH_ORGANISATIONAL_UNITS_REJECTED: FETCH_ORGANISATIONAL_UNITS + REJECTED,

  ADD_ORGANISATIONAL_UNIT: ADD_ORGANISATIONAL_UNIT,
  ADD_ORGANISATIONAL_UNIT_PENDING: ADD_ORGANISATIONAL_UNIT + PENDING,
  ADD_ORGANISATIONAL_UNIT_FULFILLED: ADD_ORGANISATIONAL_UNIT + FULFILLED,
  ADD_ORGANISATIONAL_UNIT_REJECTED: ADD_ORGANISATIONAL_UNIT + REJECTED,

  DELETE_ORGANISATIONAL_UNIT: DELETE_ORGANISATIONAL_UNIT,
  DELETE_ORGANISATIONAL_UNIT_PENDING: DELETE_ORGANISATIONAL_UNIT + PENDING,
  DELETE_ORGANISATIONAL_UNIT_FULFILLED: DELETE_ORGANISATIONAL_UNIT + FULFILLED,
  DELETE_ORGANISATIONAL_UNIT_REJECTED: DELETE_ORGANISATIONAL_UNIT + REJECTED,

  UPDATE_ORGANISATIONAL_UNIT: UPDATE_ORGANISATIONAL_UNIT,
  UPDATE_ORGANISATIONAL_UNITS_PENDING: UPDATE_ORGANISATIONAL_UNIT + PENDING,
  UPDATE_ORGANISATIONAL_UNIT_FULFILLED: UPDATE_ORGANISATIONAL_UNIT + FULFILLED,
  UPDATE_ORGANISATIONAL_UNIT_REJECTED: UPDATE_ORGANISATIONAL_UNIT + REJECTED,

  UPLOAD_CORPORATE_FILE: UPLOAD_CORPORATE_FILE,
  UPLOAD_CORPORATE_FILE_PENDING: UPLOAD_CORPORATE_FILE + PENDING,
  UPLOAD_CORPORATE_FILE_FULFILLED: UPLOAD_CORPORATE_FILE + FULFILLED,
  UPLOAD_CORPORATE_FILE_REJECTED: UPLOAD_CORPORATE_FILE + REJECTED,

  UNDO_ORGANISATIONAL_UNIT: UNDO_ORGANISATIONAL_UNIT,
  UNDO_ORGANISATIONAL_UNIT_PENDING: UNDO_ORGANISATIONAL_UNIT + PENDING,
  UNDO_ORGANISATIONAL_UNIT_FULFILLED: UNDO_ORGANISATIONAL_UNIT + FULFILLED,
  UNDO_ORGANISATIONAL_UNIT_REJECTED: UNDO_ORGANISATIONAL_UNIT + REJECTED,

}

export function fetchOrganisationalUnits () {
  console.log("fetchOrganisationalUnits")
  return {
    type: organisationalUnitsActionTypes.FETCH_ORGANISATIONAL_UNITS,
    payload: httpClient.get(projectVariationApi() + '/organisational-units'),
  }

}

export function updateOrganisationalUnit (unitId, unit) {

  return (dispatch) => {
    dispatch({
      type: organisationalUnitsActionTypes.UPDATE_ORGANISATIONAL_UNIT,
      payload: httpClient.patch(`${projectVariationApi()}/organisational-units/${unitId}`, unit),
    })
      .then(() => {
        console.log("fetch after single update")
        dispatch(fetchOrganisationalUnits())
        dispatch(fetchDepartmentAssignments())
        dispatch(fetchFloorData())
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function updateOrganisationalUnits (units) {
  console.log("UNITS:")
  console.log(units)

  return (dispatch) => {
    dispatch({
      type: organisationalUnitsActionTypes.UPDATE_ORGANISATIONAL_UNIT,
      payload: httpClient.patch(`${projectVariationApi()}/organisational-units`, units),
    })
      .then(() => {
        console.log("FETCH ORGANISATIONAL")
        dispatch(fetchOrganisationalUnits())
        dispatch(fetchDepartmentAssignments())
        dispatch(fetchFloorData())
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function updateOrganisationalUnitPlaced (unitId, unit) {
  console.log("updateOrganisationalUnitPlaced")
  return (dispatch) => {
    dispatch({
      type: organisationalUnitsActionTypes.UPDATE_ORGANISATIONAL_UNIT,
      payload: httpClient.patch(`${projectVariationApi()}/organisational-units/${unitId}`, unit),
    })
      .then(() => dispatch(fetchOrganisationalUnits()))
      .then(() => {
        const organisationalUnit = getPlacedOrganisationalUnit(store.getState(), unitId)
        dispatch(fetchPlacedOrganisationalUnits(organisationalUnit.buildingId, organisationalUnit.floorId))
        dispatch(getUndoInformation(getProjectId()))

      })
  }
}

export function addOrganisationalUnit (unit) {
  return (dispatch) => {
    dispatch({
      type: organisationalUnitsActionTypes.ADD_ORGANISATIONAL_UNIT,
      payload: httpClient.post(`${projectVariationApi()}/organisational-units`, unit),
    })
      .then(() => {
        dispatch(fetchOrganisationalUnits())
        dispatch(fetchDepartmentAssignments())
        dispatch(getUndoInformation(getProjectId()))

      })
  }

}

export function deleteOrganisationalUnit (unitId) {

  return (dispatch) => {
    dispatch({
      type: organisationalUnitsActionTypes.DELETE_ORGANISATIONAL_UNIT,
      payload: httpClient.delete(`${projectVariationApi()}/organisational-units/${unitId}`),
    })
      .then(() => {
        dispatch(fetchOrganisationalUnits())
        dispatch(fetchDepartmentAssignments())
        dispatch(fetchFloorData())
        dispatch(getUndoInformation(getProjectId()))

      })
  }
}

export function downloadCorporateDataAsXLSX () {
  return {
    type: floorPlanActionTypes.FETCH_FILE,
    payload: httpClient.getFile(encodeURI(`${projectApi()}/corporate-data/xlsx`)),
  }
}

export function uploadCorporateSettingsFile (file, replace) {

  let form = new FormData()
  form.append('replace', replace)
  form.append('file', file)

  return (dispatch) => {
    dispatch({
      type: UPLOAD_CORPORATE_FILE,
      payload: httpClient.post(encodeURI(`${projectApi()}/corporate-data/xlsx`), form),
    })
      .then(() => {
        dispatch(fetchOrganisationalUnits())
        dispatch(fetchDepartmentAssignments())
        dispatch(getUndoInformation(getProjectId()))
      })

  }
}

export function createRandomOrganisationalUnits () {

  return (dispatch) => {
    dispatch(createRandomOrganisationalUnitsAPI())
      .then(() => {
        dispatch(fetchOrganisationalUnits())
        dispatch(fetchDepartmentAssignments())
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function createRandomOrganisationalUnitsAPI () {
  return {
    type: floorPlanActionTypes.CREATE_RANDOM_ORGANISATIONAL_UNITS,
    payload: httpClient.post(encodeURI(`${projectVariationApi()}/organisational-units/create`)),
  }
}

export function createRandomOrganisationalUnits2 (value) {

  return (dispatch) => {
    dispatch(createRandomOrganisationalUnits2API(value))
      .then(() => {
        dispatch(fetchOrganisationalUnits())
        dispatch(fetchDepartmentAssignments())
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function createRandomOrganisationalUnits2API (value) {
  return {
    type: floorPlanActionTypes.CREATE_RANDOM_ORGANISATIONAL_UNITS,
    payload: httpClient.post(encodeURI(`${projectVariationApi()}/organisational-units/create2`), value),
  }
}

export function deleteAllOrganisationalUnits () {
  return (dispatch) => {
    dispatch(deleteAllOrganisationalUnitsAPI())
      .then(() => {
        dispatch(fetchOrganisationalUnits())
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function deleteAllOrganisationalUnitsAPI () {
  return {
    type: floorPlanActionTypes.DELETE_ALL_ORGANISATIONAL_UNITS,
    payload: httpClient.delete(encodeURI(`${projectVariationApi()}/organisational-units`)),
  }
}