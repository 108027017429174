import { applicationBuilderActionTypes } from '../constants'
import { undoActionTypes } from '../../common/redux/undo/undo-actions'

// ------------------- General -------------------

export function changeSelectedElement (category, uuid) {
  return {
    type: applicationBuilderActionTypes.CHANGE_SELECTED_ITEM,
    selectedElement: {
      type: category,
      data: uuid,
    },
  }
}

export function renameApplication (id, name) {
  return {
    type: applicationBuilderActionTypes.RENAME_APPLICATION,
    id: id,
    name: name,
  }
}

export function renameCurrentFurnitureEnsemblePlan (name) {
  return {
    type: applicationBuilderActionTypes.RENAME_FURNITURE_ENSEMBLE_PLAN,
    name: name,
  }
}

export function addNewApplication () {
  return {
    type: applicationBuilderActionTypes.ADD_APPLICATION,
  }
}

export function discardNewApplication () {
  return {
    type: applicationBuilderActionTypes.DISCARD_NEW_APPLICATION,
  }
}

export function resetDirtyFlag () {
  return {
    type: applicationBuilderActionTypes.RESET_DIRTY_FLAG,
  }
}

export function storeApplicationBuilderUndo () {
  return {
    type: undoActionTypes.STORE_APPLICATION,
  }
}

export function undoApplicationBuilder () {
  return {
    type: undoActionTypes.UNDO_APPLICATION,
  }
}

export function redoApplicationBuilder () {
  return {
    type: undoActionTypes.REDO_APPLICATION,
  }
}

export function getUndoAvailable () {
  return {
    type: undoActionTypes.UNDO_APPLICATION_AVAILABLE,
  }
}

export function discardUndo () {
  return {
    type: undoActionTypes.DISCARD_UNDO,
  }
}

// ------------------- Ground Plan Settings -------------------

export function addTagFurnitureEnsemblePlan (tag) {
  return {
    type: applicationBuilderActionTypes.ADD_TAG_FURNITURE_ENSEMBLE_PLAN,
    tag: tag,
  }
}

export function removeTagFurnitureEnsemblePlan (tag) {
  return {
    type: applicationBuilderActionTypes.REMOVE_TAG_FURNITURE_ENSEMBLE_PLAN,
    tag: tag,
  }
}

export function changeFurnitureEnsembleCategory (category) {
  return {
    type: applicationBuilderActionTypes.CHANGE_FURNITURE_ENSEMBLE_PLAN_CATEGORY,
    category: category,
  }
}

export function changeFurnitureEnsembleSize (sizes) {
  return {
    type: applicationBuilderActionTypes.CHANGE_FURNITURE_ENSEMBLE_PLAN_SIZE,
    sizes: sizes,
  }
}

// ------------------- Furniture Settings -------------------

export function lockRoomSize (locked) {
  return {
    type: applicationBuilderActionTypes.LOCK_ROOM_SIZE,
    locked: locked,
  }
}

export function changeFurniturePosition (offset, uuid) {
  console.log ("update pos")

  return {
    type: applicationBuilderActionTypes.CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_POSITION,
    offset: offset,
    uuid: uuid,
  }
}

export function changeFurnitureRotation (uuid, rotation) {
  return {
    type: applicationBuilderActionTypes.CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_ROTATION,
    rotation: rotation,
    uuid: uuid,
  }
}

export function changeFurnitureAnchorSide (uuid, anchorSide) {
  console.log("changeAnchorside")
  return {
    type: applicationBuilderActionTypes.CHANGE_FURNITURE_ANCHOR_SIDE,
    anchorSide: anchorSide,
    uuid: uuid,
  }
}

export function changeFurnitureParent (parent, uuid, connectorSide, posOnConnectorSide, root) {
  return {
    type: applicationBuilderActionTypes.CHANGE_FURNITURE_ENSEMBLE_PLAN_FURNITURE_PARENT,
    parent: parent,
    uuid: uuid,
    connectorSide: connectorSide,
    posOnConnectorSide: posOnConnectorSide,
    root: root,
  }
}

export function addNewFurniture (parent, uuid, newId, connectorSide, posOnConnectorSide, root, constructor) {
  return {
    type: applicationBuilderActionTypes.ADD_FURNITURE_ENSEMBLE_PLAN_FURNITURE,
    parent: parent,
    uuid: uuid,
    connectorSide: connectorSide,
    newId: newId,
    posOnConnectorSide: posOnConnectorSide,
    root: root,
    constructor: constructor
  }
}

export function deleteFurniture (id) {
  return {
    type: applicationBuilderActionTypes.DELETE_FURNITURE,
    id: id,
  }
}

// ------------------- Wall Settings -------------------

export function changeWallCode (wallCode) {
  return {
    type: applicationBuilderActionTypes.CHANGE_WALL_CODE,
    code: wallCode,
  }
}

export function changeSideCode (sideCode) {
  return {
    type: applicationBuilderActionTypes.CHANGE_SIDE_CODE,
    code: sideCode,
  }
}

