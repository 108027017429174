import React from 'react'

const SvgDrawNavbarNumber3Focus = props =>
  <svg
    viewBox="0 0 20 20"
    width={20} height={20}
    className={props.className}
  >
    <g fill="none" fillRule="evenodd">
      <circle stroke="#FFF" strokeWidth={2} cx={10} cy={10} r={9}/>
      <path
        d="M10.124 14.048c1.716 0 2.832-.84 2.832-2.172 0-.96-.624-1.644-1.584-1.8.72-.096 1.416-.744 1.416-1.68 0-1.308-.972-2.196-2.7-2.196C8.3 6.2 7.22 7.388 7.22 8.948l1.452.06c0-.972.504-1.596 1.38-1.596.828 0 1.236.456 1.236 1.128 0 .768-.576 1.056-1.212 1.056-.276 0-.504 0-.66-.024V10.7c.204-.024.42-.036.648-.036.78 0 1.356.372 1.356 1.092 0 .708-.552 1.08-1.296 1.08-.924 0-1.512-.48-1.548-1.452l-1.476-.06c0 1.68 1.14 2.724 3.024 2.724z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>

export default SvgDrawNavbarNumber3Focus