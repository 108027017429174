import { FN3D, FN3DExtended } from './classes'
import { cloneDeep } from 'lodash'

export function mapFurnitureListTo3DObjects (furnitureList) {
  return furnitureList
    .map(furniture => {
      let object3D = new FN3D()
      object3D.build(furniture)
      return object3D
    })
}

export function mapFurnitureListTo3DObjectsXT (furnitureList) {

  const furnListCopy = cloneDeep(furnitureList)

  return furnListCopy.map(furniture => {
    let object3D = new FN3DExtended()
    object3D.build(furniture)
    return object3D
  })
}

export function uuidv4 () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8))
    return v.toString(16)
  })
}