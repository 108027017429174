import React, { Component } from 'react'
import RadioButtonGroup from '../../../../common/components/RadioButtonGroup'
import RadioOption from '../../../../common/components/RadioOption'
import './OfficeLensRadioButtonGroup.scss'
import Text from '../../../../common/components/Text'

class OfficeLensRadioButtonGroup extends Component {
  render () {
    return (
      <div className={'office-lens-radio-button-group'}>
        <Text outerClassName={'office-lens-radio-button-group-label'}>{this.props.label}</Text>
        <RadioButtonGroup name={this.props.field.name}
                          value={this.props.field.value}
                          onChange={this.props.field.onChange}>
          <RadioOption value={'NO'}>No</RadioOption>
          <RadioOption value={'EVEN'}>Even</RadioOption>
          <RadioOption value={'YES'}>Yes</RadioOption>
        </RadioButtonGroup>
      </div>
    )
  }
}

export default OfficeLensRadioButtonGroup