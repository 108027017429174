import React, { Component } from 'react'
import './CultureLensController.scss'

class CultureLensController extends Component {

  constructor (props) {
    super(props)
    this.state = {
      create: 50,
      control: 50,
      collaborate: 50,
      compete: 50,
    }
    this.areasize = null
    this.knobsize = null
    this.mstoreX = 0
    this.mstoreY = 0
    this.stored = 0

    this.changeCollaborateAction = this.changeCollaborateAction.bind(this)
    this.changeCollaborate = this.changeCollaborate.bind(this)
    this.removeListenerCollaborate = this.removeListenerCollaborate.bind(this)

    this.changeCompeteAction = this.changeCompeteAction.bind(this)
    this.changeCompete = this.changeCompete.bind(this)
    this.removeListenerCompete = this.removeListenerCompete.bind(this)

    this.changeCreateAction = this.changeCreateAction.bind(this)
    this.changeCreate = this.changeCreate.bind(this)
    this.removeListenerCreate = this.removeListenerCreate.bind(this)

    this.changeControlAction = this.changeControlAction.bind(this)
    this.changeControl = this.changeControl.bind(this)
    this.removeListenerControl = this.removeListenerControl.bind(this)

  }

  componentWillMount () {
    if (this.props.lens) {
      this.setState({ ...this.props.lens })
    }
  }

  componentDidMount () {
    this.updateGFX()
  }

  componentWillUpdate (nextProps, nextState, nextContext) {
    if (nextProps.lens !== this.props.lens) {
      this.setState({ ...nextProps.lens })
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    this.updateGFX()
  }

  handleChangeEnd () {
    this.props.onChange(this.state)
  }

  changeCollaborate (event) {

    this.stored = this.state.collaborate

    if (event.type === 'touchstart') {
      this.mstoreX = event.touches[0].clientX
      this.mstoreY = event.touches[0].clientY

    } else {
      this.mstoreX = event.clientX
      this.mstoreY = event.clientY
    }

    document.addEventListener('touchmove', this.changeCollaborateAction, true)
    document.addEventListener('mousemove', this.changeCollaborateAction, true)

    document.addEventListener('mouseup', this.removeListenerCollaborate, true)
    document.addEventListener('touchend', this.removeListenerCollaborate, true)
  }

  changeCollaborateAction (event) {

    let x
    let y
    if (event.type === 'touchmove') {
      x = -(event.touches[0].clientX - this.mstoreX)
      y = -(event.touches[0].clientY - this.mstoreY)

    } else {
      x = -(event.clientX - this.mstoreX)
      y = -(event.clientY - this.mstoreY)
    }

    let dist = x
    if (y > x) dist = y

    let vchange = (dist / this.areasize) * 100
    let newval = this.stored + vchange
    if (newval < 0) newval = 0
    if (newval > 100) newval = 100

    this.setState({ collaborate: newval, compete: 100 - newval })
    this.updateGFX()

  }

  removeListenerCollaborate () {

    document.removeEventListener('mousemove', this.changeCollaborateAction, true)
    document.removeEventListener('touchmove', this.changeCollaborateAction, true)
    document.removeEventListener('mouseup', this.removeListenerCollaborate, true)
    document.removeEventListener('touchend', this.removeListenerCollaborate, true)
    this.handleChangeEnd()
  }

  changeCompete (event) {

    this.stored = this.state.compete

    if (event.type === 'touchstart') {
      this.mstoreX = event.touches[0].clientX
      this.mstoreY = event.touches[0].clientY

    } else {
      this.mstoreX = event.clientX
      this.mstoreY = event.clientY
    }

    document.addEventListener('touchmove', this.changeCompeteAction, true)
    document.addEventListener('mousemove', this.changeCompeteAction, true)

    document.addEventListener('mouseup', this.removeListenerCompete, true)
    document.addEventListener('touchend', this.removeListenerCompete, true)
  }

  changeCompeteAction (event) {

    let x
    let y
    if (event.type === 'touchmove') {
      x = (event.touches[0].clientX - this.mstoreX)
      y = (event.touches[0].clientY - this.mstoreY)
    } else {
      x = (event.clientX - this.mstoreX)
      y = (event.clientY - this.mstoreY)
    }

    let dist = x
    if (y > x) dist = y

    let vchange = (dist / this.areasize) * 100
    let newval = this.stored + vchange
    if (newval < 0) newval = 0
    if (newval > 100) newval = 100

    this.setState({ compete: newval, collaborate: 100 - newval })
    this.updateGFX()

  }

  removeListenerCompete () {

    document.removeEventListener('mousemove', this.changeCompeteAction, true)
    document.removeEventListener('touchmove', this.changeCompeteAction, true)
    document.removeEventListener('mouseup', this.removeListenerCompete, true)
    document.removeEventListener('touchend', this.removeListenerCompete, true)
    this.handleChangeEnd()
  }

  changeCreate (event) {

    this.stored = this.state.create

    if (event.type === 'touchstart') {
      this.mstoreX = event.touches[0].clientX
      this.mstoreY = event.touches[0].clientY

    } else {
      this.mstoreX = event.clientX
      this.mstoreY = event.clientY
    }

    document.addEventListener('touchmove', this.changeCreateAction, true)
    document.addEventListener('mousemove', this.changeCreateAction, true)

    document.addEventListener('mouseup', this.removeListenerCreate, true)
    document.addEventListener('touchend', this.removeListenerCreate, true)
  }

  changeCreateAction (event) {

    let x
    let y
    if (event.type === 'touchmove') {
      x = (event.touches[0].clientX - this.mstoreX)
      y = -(event.touches[0].clientY - this.mstoreY)

    } else {
      x = (event.clientX - this.mstoreX)
      y = -(event.clientY - this.mstoreY)
    }

    let dist = x
    if (y > x) dist = y

    let vchange = (dist / this.areasize) * 100
    let newval = this.stored + vchange
    if (newval < 0) newval = 0
    if (newval > 100) newval = 100

    this.setState({ create: newval, control: 100 - newval })
    this.updateGFX()

  }

  removeListenerCreate () {

    document.removeEventListener('mousemove', this.changeCreateAction, true)
    document.removeEventListener('touchmove', this.changeCreateAction, true)
    document.removeEventListener('mouseup', this.removeListenerCreate, true)
    document.removeEventListener('touchend', this.removeListenerCreate, true)
    this.handleChangeEnd()
  }

  changeControl (event) {

    this.stored = this.state.collaborate

    if (event.type === 'touchstart') {
      this.mstoreX = event.touches[0].clientX
      this.mstoreY = event.touches[0].clientY

    } else {
      this.mstoreX = event.clientX
      this.mstoreY = event.clientY
    }

    document.addEventListener('touchmove', this.changeControlAction, true)
    document.addEventListener('mousemove', this.changeControlAction, true)

    document.addEventListener('mouseup', this.removeListenerControl, true)
    document.addEventListener('touchend', this.removeListenerControl, true)
  }

  changeControlAction (event) {

    let x
    let y
    if (event.type === 'touchmove') {
      x = -(event.touches[0].clientX - this.mstoreX)
      y = (event.touches[0].clientY - this.mstoreY)

    } else {
      x = -(event.clientX - this.mstoreX)
      y = (event.clientY - this.mstoreY)
    }

    let dist = x
    if (y > x) dist = y

    let vchange = (dist / this.areasize) * 100
    let newval = this.stored + vchange
    if (newval < 0) newval = 0
    if (newval > 100) newval = 100

    this.setState({ control: newval, create: 100 - newval })
    this.updateGFX()

  }

  removeListenerControl () {

    document.removeEventListener('mousemove', this.changeControlAction, true)
    document.removeEventListener('touchmove', this.changeControlAction, true)
    document.removeEventListener('mouseup', this.removeListenerControl, true)
    document.removeEventListener('touchend', this.removeListenerControl, true)
    this.handleChangeEnd()
  }

  updateGFX () {
    if (this.areasize == null) {
      this.areasize = document.getElementById('area-collaborate').offsetWidth - document.getElementById('dot-collaborate').offsetWidth
      this.knobsize = document.getElementById('dot-collaborate').offsetWidth
    }

    let createValue = (this.state.create / 100) * this.areasize
    let competeValue = (this.state.compete / 100) * this.areasize
    let controlValue = (this.state.control / 100) * this.areasize
    let collaborateValue = (this.state.collaborate / 100) * this.areasize

    let createValueB = (70 / 100) * this.areasize
    let competeValueB = (50 / 100) * this.areasize
    let controlValueB = (30 / 100) * this.areasize
    let collaborateValueB = (50 / 100) * this.areasize

    document.getElementById('dot-create-touch').style.left = `${createValue}px`
    document.getElementById('dot-create-touch').style.top = `${this.areasize - createValue}px`

    document.getElementById('dot-compete-touch').style.left = `${competeValue}px`
    document.getElementById('dot-compete-touch').style.top = `${competeValue}px`

    document.getElementById('dot-control-touch').style.left = `${this.areasize - controlValue}px`
    document.getElementById('dot-control-touch').style.top = `${controlValue}px`

    document.getElementById('dot-collaborate-touch').style.left = `${this.areasize - collaborateValue}px`
    document.getElementById('dot-collaborate-touch').style.top = `${this.areasize - collaborateValue}px`

    document.getElementById('reddotted')
      .setAttribute('d', 'M' + (this.areasize - collaborateValue + (this.knobsize / 2)) + ' ' + (this.areasize - collaborateValue + (this.knobsize / 2)) + ' ' +
        'L' + (createValue + this.areasize + this.knobsize * 1.5) + ' ' + (-createValue + this.areasize + this.knobsize / 2) +
        'L' + (competeValue + this.areasize + this.knobsize * 1.5) + ' ' + (competeValue + this.areasize + this.knobsize * 1.5) +
        'L' + (-controlValue + this.areasize + this.knobsize / 2) + ' ' + (controlValue + this.areasize + this.knobsize * 1.5) +
        'L' + (this.areasize - collaborateValue + (this.knobsize / 2)) + ' ' + (this.areasize - collaborateValue + (this.knobsize / 2)),
      )

    document.getElementById('greyback')
      .setAttribute('d', 'M' + (this.areasize - collaborateValueB + (this.knobsize / 2)) + ' ' + (this.areasize - collaborateValueB + (this.knobsize / 2)) + ' ' +
        'L' + (createValueB + this.areasize + this.knobsize * 1.5) + ' ' + (-createValueB + this.areasize + this.knobsize / 2) +
        'L' + (competeValueB + this.areasize + this.knobsize * 1.5) + ' ' + (competeValueB + this.areasize + this.knobsize * 1.5) +
        'L' + (-controlValueB + this.areasize + this.knobsize / 2) + ' ' + (controlValueB + this.areasize + this.knobsize * 1.5) +
        'L' + (this.areasize - collaborateValueB + (this.knobsize / 2)) + ' ' + (this.areasize - collaborateValueB + (this.knobsize / 2)),
      )
  }

  render () {

    return (
      <div className={'culture-lens-controller'}>

        <div className={'label-top'}>
          <div className='label-left'>Collaborate</div>
          <div className='label-right'>Create</div>
        </div>
        <div id={'culture-lens-controller-main'}>

          <svg>
            <style>


            </style>
            <g id={'redline'}>
              <path id="greyback"/>

              <line className={'lgrey'} x1='0%' y1='10%' x2='100%' y2='10%'/>
              <line className={'lgrey'} x1='0%' y1='20%' x2='100%' y2='20%'/>
              <line className={'lgrey'} x1='0%' y1='30%' x2='100%' y2='30%'/>
              <line className={'lgrey'} x1='0%' y1='40%' x2='100%' y2='40%'/>
              <line className={'dgrey'} x1='0%' y1='50%' x2='100%' y2='50%'/>
              <line className={'lgrey'} x1='0%' y1='60%' x2='100%' y2='60%'/>
              <line className={'lgrey'} x1='0%' y1='70%' x2='100%' y2='70%'/>
              <line className={'lgrey'} x1='0%' y1='80%' x2='100%' y2='80%'/>
              <line className={'lgrey'} x1='0%' y1='90%' x2='100%' y2='90%'/>

              <line className={'lgrey'} x1='10%' y1='0%' x2='10%' y2='100%'/>
              <line className={'lgrey'} x1='20%' y1='0%' x2='20%' y2='100%'/>
              <line className={'lgrey'} x1='30%' y1='0%' x2='30%' y2='100%'/>
              <line className={'lgrey'} x1='40%' y1='0%' x2='40%' y2='100%'/>
              <line className={'dgrey'} x1='50%' y1='0%' x2='50%' y2='100%'/>
              <line className={'lgrey'} x1='60%' y1='0%' x2='60%' y2='100%'/>
              <line className={'lgrey'} x1='70%' y1='0%' x2='70%' y2='100%'/>
              <line className={'lgrey'} x1='80%' y1='0%' x2='80%' y2='100%'/>
              <line className={'lgrey'} x1='90%' y1='0%' x2='90%' y2='100%'/>


              <path id="reddotted"/>
            </g>
          </svg>

          <div id={'area-collaborate'} className="dot-area">
            <div id={'dot-collaborate-touch'} className="dot-touch"
                 onMouseDown={(event) => this.changeCollaborate(event)}
                 onTouchStart={(event) => this.changeCollaborate(event)}>
              <div id={'dot-collaborate'} className="dot"/>
            </div>
          </div>
          <div id={'area-create'} className="dot-area">
            <div id={'dot-create-touch'} className="dot-touch"
                 onMouseDown={(event) => this.changeCreate(event)}
                 onTouchStart={(event) => this.changeCreate(event)}>
              <div id={'dot-create'} className="dot"/>
            </div>
          </div>
          <div id={'area-control'} className="dot-area">
            <div id={'dot-control-touch'} className="dot-touch"
                 onMouseDown={(event) => this.changeControl(event)}
                 onTouchStart={(event) => this.changeControl(event)}>
              <div id={'dot-control'} className="dot"/>
            </div>
          </div>
          <div id={'area-compete'} className="dot-area">
            <div id={'dot-compete-touch'} className="dot-touch"
                 onMouseDown={(event) => this.changeCompete(event)}
                 onTouchStart={(event) => this.changeCompete(event)}>
              <div id={'dot-compete'} className="dot"/>
            </div>
          </div>
        </div>
        <div className={'label-bottom'}>
          <div className='label-left'>Control</div>
          <div className='label-right'>Compete</div>
        </div>
        <div>
        </div>


      </div>
    )

  }
}

export default CultureLensController