import React from 'react'

const SvgRotateIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M14.345 18.636a.5.5 0 01-.35.615 7.528 7.528 0 01-1.05.21.5.5 0 01-.126-.992c.308-.039.612-.1.911-.182a.5.5 0 01.615.349zm-5.19-.774c.281.137.569.253.862.347a.5.5 0 01-.307.952 7.56 7.56 0 01-.993-.4.5.5 0 11.438-.899zm8.431-1.586a.5.5 0 01.045.706 7.33 7.33 0 01-.765.751.5.5 0 01-.647-.762c.235-.2.457-.417.662-.65a.5.5 0 01.705-.045zm-10.985-.642c.172.258.362.503.57.733a.5.5 0 01-.745.669 7.472 7.472 0 01-.657-.847.5.5 0 01.832-.555zm12.398-3.01a.5.5 0 01.415.571 7.456 7.456 0 01-.244 1.044.5.5 0 01-.955-.297 6.44 6.44 0 00.211-.904.5.5 0 01.395-.411l.088-.01zM11.333 2.171L14.162 5l-2.829 2.828V5.535A6.422 6.422 0 009.4 6.04a.437.437 0 01-.114.075 6.52 6.52 0 00-.814.447.501.501 0 01-.111.053 6.489 6.489 0 00-1.797 1.819.412.412 0 01-.047.1 6.555 6.555 0 00-.242.416l-.11.216-.047.095-.043.095a.502.502 0 01-.053.091 6.446 6.446 0 00-.523 2.581l.004.058c.004.31.03.619.077.925a.5.5 0 01-.988.154 7.496 7.496 0 01-.088-1.102L4.5 12a7.5 7.5 0 016.833-7.47V2.171zm7.526 6.804c.145.327.267.663.364 1.007a.5.5 0 11-.962.272 6.543 6.543 0 00-.316-.873.5.5 0 01.914-.406zM16 5.671c.304.191.593.403.865.633a.5.5 0 11-.648.763 6.474 6.474 0 00-.749-.549.5.5 0 11.532-.847z"
    />
  </svg>
);

export default SvgRotateIcon
