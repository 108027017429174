import * as React from 'react'

const SvgSupportIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g
      fill="none"
      fillRule="evenodd"
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 17H6a1 1 0 01-1-1V8a1 1 0 011-1h12a1 1 0 011 1v8a1 1 0 01-1 1z"/>
      <path d="M18.625 7.446l-6.28 5.985a.498.498 0 01-.69 0l-6.284-5.99h0m-.001 9.244l4.783-4.558m3.68-.005h0l4.792 4.568"/>
    </g>
  </svg>
)

export default SvgSupportIcon
