import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import Parser from 'html-react-parser'

import './Block.scss'

export function Block ({ department, proportionalWidth, index }) {

  let style = { width: proportionalWidth + '%' }

  const { r, g, b } = getItemColor(department)

  let wpNeeded = 0
  let wpThere = 0
  if (department.summary) {
    wpNeeded = department.summary.goalNumberOfWorkplaces
    wpThere = department.summary.placedNumberOfWorkplaces
  }

  return (
    <Draggable draggableId={department.id} index={index}>
      {(provided) => (
        <div className={'block'}
             id={department.id}
             data-name={department.shortName}
             data-colr={r}
             data-colg={g}
             data-colb={b}
             data-worplaces={wpThere}
             data-workpacesneeded={wpNeeded}
             ref={provided.innerRef}
             {...provided.dragHandleProps}
             {...provided.draggableProps}
             style={{ ...style, ...provided.draggableProps.style }}>
          {Parser('&nbsp;')}
        </div>
      )}

    </Draggable>
  )
}

function getItemColor (department) {

  let rgba = [0, 0, 0, 1]
  let r = 1, g = 1, b = 1

  if (department.color) {
    let color = department.color
    r = Math.floor(255 * color.red)
    g = Math.floor(255 * color.green)
    b = Math.floor(255 * color.blue)
  }

  return { rgba: rgba, r: r, g: g, b: b }
}

export default Block