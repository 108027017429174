import React, { useEffect, useState } from 'react'
import PatternNavigation from './../../components/editor/PatternNavigation'
import PatternFurnitureMenu from './../../components/PatternFurnitureMenu'
import PatternInspector from './../../components/editor/PatternInspector'
import PatternBuilderPlan from './../../components/editor/PatternBuilderPlan'
import {
  addNewFurniture,
  addNewPattern,
  changeFurnitureParent,
  changeFurniturePosition,
  changeFurnitureRotation90,
  deleteFurniture,
  discardUndo,
  getUndoAvailable,
  redoPatternBuilder,
  replacePatternData,
  storePatternBuilderUndo,
  undoPatternBuilder,
  changeFurnitureAnchorSide
} from './../../actions/actions'
import { updateFurnitureDragId } from '../../../common/redux/furnituredrag/furnituredag-actions'
import { useDispatch, useSelector } from 'react-redux'
import PatternAreaSettings from './PatternAreaSettings'
import { changeFurnitureRotation, fetchPattern } from '../../actions/actions'
import BuilderFurnitureSettings from '../../../common/components/builder/BuilderFurnitureSettings'

import './PatternBuilder.scss'
import { useParams } from 'react-router-dom'
import { fetchApplicationSettings } from '../../../common/redux/settings/settings-actions'
import { fetchFurnitureLibrary } from '../../../floorplan/actions/actions'

const PatternBuilder = () => {
  const dispatch = useDispatch()

  const [showAreaSettings, setShowAreaSettings] = useState(false)
  const [selectedArea, setSelectedArea] = useState(null)
  const [showFurnitureMenu, setShowFurnitureMenu] = useState(false)
  const [furnitureId, setFurnitureId] = useState(null)
  const [furnitureList, setFurnitureList] = useState(null)
  const [areaWidth, setAreaWidth] = useState(null)
  const [areaDepth, setAreaDepth] = useState(null)
  const [dataLoaded, setDataLoaded] = useState(false)

  const { projectId, patternId } = useParams()

  const settings = useSelector(state => state.settings.appSettings)
  const furnitureLibrary = useSelector(state => state.furniture.furnitureLibrary)
  const furnitureMenuItemDragId = useSelector(state => state.furnitureDrag.furnitureMenuItemDragId)
  const patternPlan = useSelector(state => state.pattern.patternPlan)
  const undoAvailable = useSelector(state => state.pattern.undoAvailable)
  const redoAvailable = useSelector(state => state.pattern.redoAvailable)

  useEffect(() => {

    dispatch(fetchApplicationSettings())
    dispatch(fetchFurnitureLibrary(projectId)).then(() => {
      if (patternId) {
        dispatch(fetchPattern(projectId, patternId))
      } else {
        dispatch(addNewPattern())
      }
    })

  }, [])

  useEffect(() => {
    if (patternId) {
      handleHideFurnitureMenu()
      if (patternId) {
        dispatch(fetchPattern(projectId, patternId))
      } else {
        dispatch(addNewPattern())
      }
    }


  }, [patternId, dispatch])

  useEffect(() => {
    setFurnitureList(getFurnitureListFromPattern(patternPlan))
    setDataLoaded(true)
  }, [patternPlan])

  function getFurnitureListFromPattern (pattern) {
    let furnList = []

    Object.values(pattern)
      .forEach((p) => {
        if (p && p.furnitureList) {
          p.furnitureList.forEach((furn) => furnList.push(furn))
        }
      })

    return furnList
  }

  function handleShowAreaSettings (area, width, depth) {
    setShowAreaSettings(true)
    setSelectedArea(area)
    setAreaWidth(width)
    setAreaDepth(depth)
    setShowFurnitureMenu(false)
    setFurnitureId(null)
  }

  function handleHideAreaSettings (area) {
    setShowAreaSettings(false)
    setSelectedArea(null)
    setShowFurnitureMenu(false)
    setFurnitureId(null)

  }

  function handleShowFurnitureMenu (furnId) {
    setShowAreaSettings(false)
    setSelectedArea(null)
    setShowFurnitureMenu(true)
    setFurnitureId(furnId)
  }

  function handleHideFurnitureMenu () {
    setShowFurnitureMenu(false)
    setFurnitureId(null)
    setShowFurnitureMenu(false)
    setFurnitureId(null)
  }

  function handleSideMenuOnDeleteFurniture (id) {
    handleHideFurnitureMenu()
    if (id) {
      dispatch(deleteFurniture(id))
    }
  }

  return (
    <div id={'pattern-builder'}>

      <PatternNavigation
        projectId={projectId}
        currentPatternId={patternId}
        getUndoAvailable={handleGetUndoAvailable}
        undoPatternBuilder={handleUndoPatternBuilder}
        redoPatternBuilder={handleRedoPatternBuilder}
        undoAvailable={undoAvailable}
        redoAvailable={redoAvailable}
        discardUndo={handleDiscardUndo}
      />

      <div className="main">

        <div className={'sidebar left'}>
          <PatternFurnitureMenu furnitureLibrary={furnitureLibrary}/>
        </div>

        <div className={'content'}>
          {dataLoaded ?
            <PatternBuilderPlan
            furnitureLibrary={furnitureLibrary}
          onChangeFurniturePosition={(pos, uuid, area) => handleChangeFurniturePosition(pos, uuid, area)}
          onChangeFurnitureRotation={(rot, uuid) => handleChangeFurnitureRotation(rot, uuid)}
          onChangeFurnitureParent={(par, uuid, connectorSide, posOnConnectorSide, root, area) => handleChangeFurnitureParent(par, uuid, connectorSide, posOnConnectorSide, root, area)}
          onAddNewFurniture={(par, uuid, newId, connectorSide, posOnConnectorSide, root, constructor, area) => handleAddNewFurniture(par, uuid, newId, connectorSide, posOnConnectorSide, root,
            constructor, area)}
          deleteFurniture={(uuid) => handleSideMenuOnDeleteFurniture(uuid)}
          updateFurnitureMenuItemDragId={(id) => handleUpdateFurnitureDragId(id)}
          furnitureMenuItemDragId={furnitureMenuItemDragId}
          replacePatternData={(pattern) => handleReplacePatternData(pattern)}
          applicationSettings={settings}
          changeFurnitureRotation90={(uuid, areaType) => handleChangeFurnitureRotation90(uuid, areaType)}
          showAreaSettings={(area, width, depth) => handleShowAreaSettings(area, width, depth)}
          hideAreaSettings={() => handleHideAreaSettings()}
          showFurnitureMenu={(furnId) => handleShowFurnitureMenu(furnId)}
          hideFurnitureMenu={() => handleHideFurnitureMenu()}
          getUndoAvailable={handleGetUndoAvailable}
          storePatternBuilderUndo={handleStorePatternBuilderUndo}
        /> : null
        }

        </div>

        <div className={'sidebar right'}>
          {showAreaSettings ?
            <PatternAreaSettings
              area={selectedArea}
              areaWidth={areaWidth}
              areaDepth={areaDepth}
              close={() => handleHideAreaSettings()}
              getUndoAvailable={handleGetUndoAvailable}
              storePatternBuilderUndo={handleStorePatternBuilderUndo}
            />
            :
            showFurnitureMenu ?
              <BuilderFurnitureSettings furnitureList={furnitureList} selectedFurnitureId={furnitureId}
                                        onRotateFurniture={(furnId, rot) => handleChangeFurnitureRotation(furnId, rot)}
                                        onFurnitureChangeAnchorSide={(furnId, anchorSide) => handleChangeFurnitureAnchorSide(furnId, anchorSide)}
                                        onDeleteFurniture={(id) => handleSideMenuOnDeleteFurniture(id)}
                                        getUndoAvailable={handleGetUndoAvailable}
                                        storeApplicationBuilderUndo={handleStorePatternBuilderUndo}
              />
              :
              <PatternInspector/>}
        </div>

      </div>
    </div>
  )

  function handleAddNewFurniture (par, uuid, newId, connectorSide, posOnConnectorSide, root, constructor, area) {
    dispatch(addNewFurniture(par, uuid, newId, connectorSide, posOnConnectorSide, root, constructor, area))
  }

  function handleUpdateFurnitureDragId (furnitureMenuItemDragId) {
    dispatch(updateFurnitureDragId(furnitureMenuItemDragId))

  }

  function handleStorePatternBuilderUndo () {
    dispatch(storePatternBuilderUndo())

  }

  function handleGetUndoAvailable () {
    dispatch(getUndoAvailable())
  }

  function handleChangeFurniturePosition (offset, uuid, area) {
    dispatch(changeFurniturePosition(offset, uuid, area))
  }

  function handleChangeFurnitureParent (par, uuid, connectorSide, posOnConnectorSide, root, area) {
    dispatch(changeFurnitureParent(par, uuid, connectorSide, posOnConnectorSide, root, area))
  }


  function handleReplacePatternData (pattern) {
    dispatch(replacePatternData(pattern))
  }

  function handleChangeFurnitureRotation90 (uuid, areaType) {
    dispatch(changeFurnitureRotation90(uuid, areaType))

  }

  function handleChangeFurnitureRotation (rot, uuid) {
    dispatch(changeFurnitureRotation(rot, uuid))
  }

  function handleChangeFurnitureAnchorSide (uuid, anchorSide) {
    console.log("dispatch changeAnchorside")
    dispatch(changeFurnitureAnchorSide(uuid, anchorSide))
  }


  function handleUndoPatternBuilder () {
    dispatch(undoPatternBuilder())

  }

  function handleRedoPatternBuilder () {
    dispatch(redoPatternBuilder())

  }

  function handleDiscardUndo () {
    dispatch(discardUndo())
  }

}

export default PatternBuilder
