import React from 'react'

const SvgArrowSmall = props => (
  <svg className={props.className} viewBox="0 0 44 44">
    <path
      d="M20.926 18.436l-.03.03a.479.479 0 000 .638L23.49 22l-2.593 2.897a.479.479 0 00-.053.566l.053.072.03.03c.158.142.4.128.542-.03l2.858-3.192a.478.478 0 00.122-.342.477.477 0 00-.122-.342l-2.858-3.192a.384.384 0 00-.542-.03z"
      fill="#373B51"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgArrowSmall
