import React from 'react'
import Table from './Table'
import { get, set } from 'lodash'
import './EditableTable.scss'

// Create an editable cell renderer
export const EditableCell = ({
                               cell,
                               row,
                               column,
                               onCellValueChange, // custom function supplied to the table instance
                             }) => {

  const initialValue = cell.value

  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
  }

  // Only update when the input is blurred and value has changed
  const onBlur = () => {
    if (get(row.original, column.id) !== value) {
      const updateData = set({ id: row.original.id }, column.id, value)
      onCellValueChange(row.original.id, updateData)
    }
  }

  // If the initialValue is changed externally, sync it up with the state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const isEditable = column.editableCell !== undefined && column.editableCell !== null ? typeof column.editableCell === 'function' ? column.editableCell({ row, cell }) : !!column.editableCell : true

  return isEditable ? <input type={column.inputType} value={value} onChange={onChange} onBlur={onBlur}/> : String(value)
}

// Set editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
}

function EditableTable (props) {

  const styleClasses = props.className ? ' ' + props.className : ''

  return <Table {...props}
                className={'editable-table' + styleClasses}
                defaultColumn={defaultColumn}/>
}

export default EditableTable