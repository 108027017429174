import React from 'react'
import Icon from './icons/IconFactory'

import './IconWrapper.scss'

export function IconWrapper ({ styleClass, ...props }) {
  return (
    <div className={'icon-wrapper' + (styleClass ? ' ' + styleClass : '')}>
      <Icon {...props}/>
    </div>
  )

}
