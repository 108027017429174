import React from 'react'

const SvgEditValues = props => (

  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
  >
    <g stroke="#00293B" fill="none" fillRule="evenodd">
      <path
        fill="#00293B"
        strokeLinejoin="round"
        d="M15 11h2v2h-2zm-4 5h2v2h-2zM8 6h2v2H8z"
      />
      <path
        strokeLinecap="round"
        d="M5 7h3m4 0h7M5 12h8m-8 5h6m6-5h2m-4 5h4"
      />
    </g>
  </svg>
)

export default SvgEditValues