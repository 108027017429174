import { floorPlanActionTypes } from '../../floorplan/constants'

let initialState = {
  furnitureEnsembles: [],
  error: null,
}

function furnitureEnsemblesPolygons (state = initialState, action) {

  switch (action.type) {

    case floorPlanActionTypes.FETCH_FURNITURE_ENSEMBLES_POLYGONS_PENDING:
      return { ...state }

    case floorPlanActionTypes.FETCH_FURNITURE_ENSEMBLES_POLYGONS_FULFILLED:
      return {
        ...state,
        furnitureEnsembles: action.payload.data,
      }

    case floorPlanActionTypes.FETCH_FURNITURE_ENSEMBLES_POLYGONS_REJECTED:
      return { ...state, error: action.payload }

    default:
      return { ...state }
  }

}

export default furnitureEnsemblesPolygons