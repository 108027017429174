import furnitureEnsembles from './furnitureEnsembles'
import selectedFurnitureType from './selectedFurnitureType'
import uiStateReducer from './uiState'
import furnitureEnsemblesPolygons from './furnitureEnsemblesPolygons'
import tagsReducer from './tags'

let reducers = {
  applicationBuilderUiState: uiStateReducer,
  furnitureEnsembles: furnitureEnsembles,
  selectedFurnitureType: selectedFurnitureType,
  tags: tagsReducer,
  furnitureEnsemblesPolygons: furnitureEnsemblesPolygons,
}

export default reducers