import * as React from 'react'

const SvgTeam = props =>
  (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        transform="translate(5.5 6)"
        stroke="#00293B"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10.5 5.5h.5a2 2 0 012 2V9h0M6 9.5h1.5a2 2 0 012 2v.5h0M2.5 5.5H2a2 2 0 00-2 2V9h0M6 9.5h-.5a2 2 0 00-2 2v.5h0"/>
        <circle cx={10.25} cy={1.75} r={1.75}/>
        <circle cx={2.75} cy={1.75} r={1.75}/>
        <circle cx={6.5} cy={6} r={1.75}/>
      </g>
    </svg>
  )

export default SvgTeam
