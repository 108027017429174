import * as React from 'react'
const SvgDeleteAxesActive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 10H26" stroke="#DA5B2B"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7998 10H8.7998V25H22.7998V10Z" fill="url(#paint0_linear_12_4328)" stroke="#DA5B2B"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 10H11.5V6H20.5V10Z" fill="url(#paint1_linear_12_4328)" stroke="#DA5B2B"/>
    <path d="M16 13V22" stroke="#DA5B2B"/>
    <path d="M12.4004 13V22" stroke="#DA5B2B"/>
    <path d="M19.4004 13V22" stroke="#DA5B2B"/>
    <defs>
      <linearGradient id="paint0_linear_12_4328" x1="8.80004" y1="25.0015" x2="8.80004" y2="10.0015" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
      <linearGradient id="paint1_linear_12_4328" x1="11.5001" y1="5.9996" x2="11.5001" y2="9.9996" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
    </defs>
  </svg>
)

export default SvgDeleteAxesActive
