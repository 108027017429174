import React from 'react'

const SvgMarkerIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill={props.color} fillRule="evenodd">
      <path
        d="M12.24 6.361a5.55 5.55 0 01-.664-1.846l-.034-.237a.5.5 0 01.744-.505l6.928 4a.5.5 0 01-.054.893 5.522 5.522 0 01-2.443.434l-1.6 2.773a5.506 5.506 0 01.256 5.543l-.104.19a.5.5 0 01-.688.192l-8.66-5a.5.5 0 01-.184-.683 5.5 5.5 0 015.05-2.744l1.598-2.77-.146-.24zm.562-1.136a4.5 4.5 0 00.577 1.055.5.5 0 01.03.546l-1.915 3.32a.5.5 0 01-.489.246 4.502 4.502 0 00-4.128 1.804l7.747 4.472a4.503 4.503 0 00-.501-4.475.5.5 0 01-.03-.547l1.916-3.32a.5.5 0 01.488-.246c.321.036.644.037.963.004l.238-.032-4.896-2.827z"/>
      <path d="M9.185 14.106l-2.164 6.2a.3.3 0 00.51.295l4.296-4.97-2.642-1.525z"/>
    </g>
  </svg>
)

export default SvgMarkerIcon
