import React, { useEffect, useRef, useState } from 'react'
import Measure from 'react-measure'
import { connect } from 'react-redux'

import { representationTypes } from '../three/config/RepresentationTypes'

import { cleanUp, setUp } from '../three/common/SetUp'
import { resize } from '../three/common/Renderer'

import { getCurrentGeometryObjects } from '../reducer/drawingToolReducer'
import { redoGeometries, setCurrentTool, setScaleFactor, storeGeometries, undoGeometries } from '../actions/UI/drawingToolUIActions'

import { DrawingToolController } from '../three/controller/DrawingToolController'
import ScaleInputPopup from '../../buildings/components/editor/ScaleInputPopup'
import { getScreenPositionFromThreePosition } from '../three/common/Camera'
import { tools } from '../constants/Tools'
import { Vector2, Vector3 } from 'three'
import * as THREE from 'three'
import { updateGeometries } from '../actions/Backend/drawingToolActions'

function DrawingTool ({
                        geometries, currentTool, image,
                        storeGeometries, undoGeometries, redoGeometries,
                        updateGeometries,
                        setCurrentTool, setScaleFactor,
                      }) {

  console.log("DT INIT: "+geometries)
  const mount = useRef(null)
  const rendererRef = useRef(null)
  const cameraRef = useRef(null)
  const controller = useRef(null)
  const orbitControlsRef = useRef(null)
  const [scale, setScale] = useState(null)

  useEffect(() => {
    console.log("use effect DrawingToolController")
    const {
      scene,
      camera,
      raycaster,
      orbitControls,
      renderer,
      frameId,
    } = setUp(mount)

    rendererRef.current = renderer
    cameraRef.current = camera
    orbitControlsRef.current = orbitControls

    orbitControls.addOnZoomObserver(() => updateScaleInputPopup())
    orbitControls.addOnPanObserver(() => updateScaleInputPopup())

    controller.current =
      new DrawingToolController(
        mount, scene, camera, raycaster, orbitControls, renderer,
        [
          representationTypes.scale,
          representationTypes.outline,
          representationTypes.wall,
          representationTypes.space,
          representationTypes.door,
          representationTypes.window,
          representationTypes.pillar,
          representationTypes.axis,
          ])

    controller.current.setStoreGeometries((geometries) => storeGeometries(geometries))
    controller.current.setUndoGeometries(() => undoGeometries())
    controller.current.setRedoGeometries(() => redoGeometries())
    controller.current.setUpdateGeometries(() => updateGeometries())
    controller.current.setTool((tool) => setCurrentTool(tool))
    console.log("setting up drawing tool")
    console.log(controller.current)

    return () => {
      controller.current.deconstructor()
      cleanUp(mount, renderer, frameId)
    }
  }, [rendererRef, cameraRef])

  useEffect(() => {
    //geometries.geometries = multipleOutlines
    console.log("EFFECT - currentTool")

    if (controller.current && geometries) {
      console.log("setGeometriesAndTool")
      console.log(geometries)
      console.log(controller.current)

      //console.log(geometries.geometries)
      //console.log(geometries.currentTool)
      controller.current.setGeometries(
        geometries.geometries,
        geometries.selectedGeometriesUuid,
        geometries.currentTool
      )


    }
  }, [controller, geometries])

  useEffect(() => {
    console.log("EFFECT changeTool")
    controller.current.changeTool(currentTool)
  }, [controller, currentTool])

  useEffect(() => controller.current.setImage(image), [controller, image.base64Image, image.scale])

  useEffect(() => updateScaleInputPopup(), [geometries])

  useEffect(() => {
    if (currentTool === tools.pan) {
      orbitControlsRef.current.mouseButtons = { PAN: THREE.MOUSE.LEFT }
    } else {
      orbitControlsRef.current.mouseButtons = { PAN: THREE.MOUSE.RIGHT }
    }
  }, [currentTool])

  useEffect(() => {
    function handleResize() {
      resize(mount, rendererRef.current, cameraRef.current);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []); // Leeres Abhängigkeits-Array bedeutet, dieser useEffect wird nur beim Mounten und Unmounten ausgeführt.


  function updateScaleInputPopup () {
    console.log("updateScalePopup")
    const scaleGeometry = controller.current.getGeometriesAsJSON()
      .find(geometry => geometry.representationType === tools.scale)
    if (scaleGeometry) {
      const distance = getScaleDistance(scaleGeometry)
      console.log("SCALEDISTANCE_"+distance)
      if(distance>0.2){
        const scaleCenter = getScaleCenter(scaleGeometry)
        const position = getScreenPositionFromThreePosition(scaleCenter, mount.current, cameraRef.current)
        setScale({ x: position.x, y: position.y, distance: distance })
      }
    }else{
      console.log("no scale geometry")
    }
  }

  function getScaleGeometry(){
    console.log("scale Geometry:")
    const scaleGeometry = controller.current.getGeometriesAsJSON()
      .find(geometry => geometry.representationType === tools.scale)
    console.log(scaleGeometry)
    return scaleGeometry
  }

  return (
    <Measure bounds>
      {({ measureRef }) => (
        <div className={'drawing-tool'} ref={measureRef}>
          <div className={'canvas-container'} ref={mount} tabIndex={0}/>
          {scale && currentTool === tools.scale && getScaleGeometry()  ? <ScaleInputPopup show={true} scale={scale} onValueChanged={(value) => setScaleValue(value)} unit={'m'}/> : null}
        </div>
      )}
    </Measure>
  )

  /*
  return (
    <Measure bounds>
      {({ measureRef }) => (
        <div className={'drawing-tool'} ref={measureRef}>
          <div className={'canvas-container'} ref={mount} tabIndex={0}/>
          {scale && currentTool === tools.scale ? <ScaleInputPopup show={false} scale={scale} onValueChanged={(value) => setScaleValue(value)} unit={'m'}/> : null}
        </div>
      )}
    </Measure>
  )
   */

  function getScaleDistance (scaleGeometry) {
    const positionA = new Vector2(scaleGeometry.vertices[0].x, scaleGeometry.vertices[0].y)
    const positionB = new Vector2(scaleGeometry.vertices[1].x, scaleGeometry.vertices[1].y)
    return positionA.distanceTo(positionB)
  }

  function getScaleCenter (scaleGeometry) {
    const positionA = new Vector2(scaleGeometry.vertices[0].x, scaleGeometry.vertices[0].y)
    const positionB = new Vector2(scaleGeometry.vertices[1].x, scaleGeometry.vertices[1].y)
    const verticesCount = scaleGeometry.vertices.length
    const x = (positionA.x + positionB.x) / verticesCount
    const y = (positionA.y + positionB.y) / verticesCount
    return new Vector3(x, y)
  }

  function setScaleValue (value) {

    console.log("effect setScaleValue")
    const scaleGeometry = geometries.geometries.find(geometry => geometry.representationType === tools.scale)
    const axisController = controller.current.representationController.get(representationTypes.axis)
    console.log("axisController")
    console.log(axisController)
    //getOldScaleFactor
    if(scaleGeometry){
      if(axisController) {
        axisController.clear()
      }
      const distance = getScaleDistance(scaleGeometry)
      const scaleFactor = (value / distance) * image.scale
      console.log("SCALE FACTOR: "+scaleFactor)
      setScaleFactor(scaleFactor)


    }
  }
}

const mapStateToProps = (state) => {
  return {
    geometries: getCurrentGeometryObjects(state),
    currentTool: state.drawingTool.currentTool,
    image: state.drawingTool.image,
  }
}

const mapDispatchToProps = {
  storeGeometries: storeGeometries,
  undoGeometries: undoGeometries,
  redoGeometries: redoGeometries,
  updateGeometries: updateGeometries,

  setCurrentTool: setCurrentTool,
  setScaleFactor: setScaleFactor,
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawingTool)