import variationsReducer from '../redux/variations/variations-reducer'
import furniture from './furniture'
import license from './license'
import projectsReducer from '../redux/projects/projects-reducer'
import loaderReducer from './loaderReducer'
import downloader from './downloader'
import furnitureDragReducer from '../redux/furnituredrag/furnituredrag-reducer'
import settingsReducer from '../redux/settings/settings-reducer'
import organisationalUnitsReducer from '../redux/organisationunits/organisational-units-reducer'
import notificationsReducer from '../redux/notifications/notifications-reducer'
import appState from '../redux/appstate/appstate-reducer'
import undoDataUpdate from './undoReducer'
import dataLoaderReducer from '../redux/dataLoader/dataloader-reducer'
import frontendLocationReducer from '../redux/frontendLocation/frontend-location-reducer'

let reducers = {
  appState: appState,
  loaderState: loaderReducer,
  projects: projectsReducer,
  variations: variationsReducer,
  furniture: furniture,
  downloader: downloader,
  furnitureDrag: furnitureDragReducer,
  settings: settingsReducer,
  organisationalUnits: organisationalUnitsReducer,
  notifications: notificationsReducer,
  license: license,
  undoDataUpdate: undoDataUpdate,
  dataLoaded: dataLoaderReducer,
  frontendLocation: frontendLocationReducer,
}

export default reducers