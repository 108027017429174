import React, { Component } from 'react'
import './Legend.scss'

const initState = {
  status: 'collapsed',
  drag: false,
  draggingActive: false,
  storedMouseX: 0,
  storedMouseY: 0,
  storedWindowX: 0,
  storedWindowY: 0,
}

let legend, display, planPanel, openButton

class Legend extends Component {

  constructor (props) {
    super(props)
    this.state = initState
  }

  componentDidMount () {
    planPanel = document.getElementById(this.props.planPanel)
    legend = document.getElementsByClassName('legend-window')[0]
    display = document.getElementsByClassName(this.props.editorArea)[0]
    openButton = document.getElementsByClassName('open-button')[0]

    // planPanel.addEventListener('mousemove', this.onDocumentMouseMove, true)

  }

  componentWillUnmount () {

    // planPanel.removeEventListener('mousemove', this.onDocumentMouseMove, true)
    planPanel.removeEventListener('mouseup', this.onDocumentMouseUp, true)
  }

  render () {
    let sidebarClass = this.props.sideBar
    let open = ''
    if (this.props.dashboard) open = 'open'
    return (
      <div className={'legend-component'}>

        <div className={'open-button ' + sidebarClass + ' ' + open} onClick={this.changeLegendState}/>

        <div className={'legend-window ' + this.state.status}>
          <div className={'legend-content'}>
            {this.props.children}
            <div className={'close-legend'} onClick={this.changeLegendState}/>
          </div>
        </div>

      </div>
    )
  }

  onDocumentMouseMove = (event) => {
    event.preventDefault()

    if (this.state.drag && !this.state.draggingActive) {
      this.setState({
        draggingActive: true,
        storedMouseX: event.clientX,
        storedMouseY: event.clientY,
        storedWindowX: legend.offsetLeft,
        storedWindowY: legend.offsetTop,
      })
    }

    if (this.state.drag) {

      let x = this.state.storedWindowX - this.state.storedMouseX + event.pageX
      let y = this.state.storedWindowY - this.state.storedMouseY + event.pageY

      if (x > (display.offsetLeft + display.offsetWidth - legend.offsetWidth)) x = display.offsetLeft + display.offsetWidth - legend.offsetWidth
      if (y > (display.offsetTop + display.offsetHeight - legend.offsetHeight)) x = display.offsetTop + display.offsetHeight - legend.offsetHeight

      legend.style.left = x + 'px'
      legend.style.top = y + 'px'
    }
    return false
  }
  onDocumentMouseUp = () => {
    this.deactivateDrag()
    planPanel.removeEventListener('mouseup', this.onDocumentMouseUp, true)
  }

  changeLegendState = () => {
    if (this.state.status === 'collapsed') {
      this.setState({
        status: 'opened',
      })
      this.placeLegend()

      if (this.props.onLegendOpen)
        this.props.onLegendOpen()

    } else {

      this.setState({
        status: 'collapsed',
      })

      if (this.props.onLegendClose)
        this.props.onLegendClose()
    }
  }

  placeLegend = () => {
    legend.style.left = openButton.offsetLeft + openButton.offsetWidth - legend.offsetWidth + 'px'
    legend.style.top = openButton.offsetTop + openButton.offsetHeight - legend.offsetHeight + 'px'
  }

  activateDrag = (event) => {

    this.setState({
      drag: true,
    })

    planPanel.addEventListener('mouseup', this.onDocumentMouseUp, true)
  }

  deactivateDrag = () => {
    this.setState({
      drag: false,
      draggingActive: false,
    })
  }

}

export default Legend