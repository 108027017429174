import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import BuilderNavBar from '../../../common/components/builder/BuilderNavBar';
import {
  discardNewPattern, fetchPattern, fetchPatternList, renamePattern, resetDirtyFlag,
  saveNewPattern, updatePattern, undoPatternBuilder, redoPatternBuilder, discardUndo
} from '../../actions/actions';

const PatternNavigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();
  const patterns = useSelector(state => state.pattern.patterns);
  const patternPlan = useSelector(state => state.pattern.patternPlan);
  const dirtyFlag = useSelector(state => state.pattern.dirtyFlag);
  const undoAvailable = useSelector(state => state.pattern.undoAvailable);
  const redoAvailable = useSelector(state => state.pattern.redoAvailable);
  const basePath = `/projects/${projectId}`;
  console.log('basePath', basePath)


  useEffect(() => {
    if (patterns.length < 1) {
      dispatch(fetchPatternList(projectId));
    }
  }, [dispatch, patterns.length, projectId]);

  const handleUndo = () => {
    dispatch(undoPatternBuilder());
  };

  const handleRedo = () => {
    dispatch(redoPatternBuilder());
  };

  const handleDiscardUndo = () => {
    dispatch(discardUndo());
  };


  return (
    <BuilderNavBar
      navItems={patterns}
      current={patternPlan}
      onNavigation={(patternId) => navigate(`${basePath}/patterns/${patternId}${location.search}`)}
      backToOverview={() => navigate(`${basePath}/patterns${location.search}`)}
      dirtyFlag={dirtyFlag}
      resetDirtyFlag={() => dispatch(resetDirtyFlag())}
      renameTitle={(pattern) => dispatch(renamePattern(pattern.id, pattern.displayName))}
      discardChanges={patternPlan.id ? () => dispatch(fetchPattern(projectId, patternPlan.id)) : () => dispatch(discardNewPattern()).then((path) => navigate(path + location.search))}
      saveChanges={patternPlan.id ? () => dispatch(updatePattern(projectId, patternPlan)) : () => dispatch(saveNewPattern(projectId, patternPlan))}
      dialogMessage={['Your Pattern have been modified.', 'Please confirm or discard your changes.', 'To continue editing close this window.']}
      undoApplicationBuilder={handleUndo}
      redoApplicationBuilder={handleRedo}
      undoAvailable={undoAvailable}
      redoAvailable={redoAvailable}
      discardUndo={handleDiscardUndo}
    />
  );
};

export default PatternNavigation;