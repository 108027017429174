import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useParams } from 'react-router-dom'
import { Routes, useLocation, useNavigate } from 'react-router'
import { viewNames, viewNamesOnBoarding } from '../common/globalConstants'
import Step from './components/Step'

import './OnboardingApp.scss'
import { fetchProjectData } from '../floorplan/actions/actions'

function OnboardingApp () {
  const dispatch = useDispatch()
  const { projectId } = useParams()

  const dataLoaded = useSelector(state => state.dataLoaded.dataLoaded)

  useEffect(() => {
    if (!dataLoaded) {
      dispatch(fetchProjectData(projectId))
    }
  }, [dispatch, projectId, dataLoaded])

  return (
    <Step/>
  )
}

export default OnboardingApp