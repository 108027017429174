import React from 'react'

const SvgListOfAppsIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill="#373B51" fillRule="evenodd" transform="translate(6 7)">
      <rect width={12} height={2} y={8} rx={0.5}/>
      <rect width={12} height={2} y={4} rx={0.5}/>
      <rect width={9} height={2} rx={0.5}/>
    </g>
  </svg>
);

export default SvgListOfAppsIcon
