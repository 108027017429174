import React from 'react'
import Dialog, { DialogContent } from '../../common/components/Dialog'

import './AssistantDialog.scss'
import Text, { textTypes } from '../../common/components/Text'
import Button from '../../common/components/Button'

import { ReactComponent as AssistantIllustration } from '../../assets/images/svg/Assistant_Illustration.svg'

export function AssistantDialog ({ text, assistantButtonText, onClose = () => {}, onCreate = () => {} }) {

  return (
    <Dialog className={'assistant-dialog'}
            title={'Assistant'} onCancel={() => onClose()}>
      <DialogContent>
        <div className={'assistant-dialog-illustration-container'}>
          <AssistantIllustration className={'assistant-dialog-illustration'}/>
        </div>
        <div className={'assistant-dialog-content'}>
          <Text multiline component={textTypes.paragraph}>
            {text}
          </Text>
          <div className={'assistant-dialog-buttons'}>
            <Button className={'assistant-dialog-button'} variant={'orange'} onClick={() => onClose()}>Cancel</Button>
            <Button className={'assistant-dialog-button'} variant={'orange-filled'} onClick={() => {
              onClose()
              onCreate()
            }} secondIcon={'assistant'}>{assistantButtonText}</Button>
          </div>
        </div>
      </DialogContent>

    </Dialog>
  )

}
