import { httpClient } from '../../../httpClient'
import { getProjectId, projectApi } from '../../../utils'
import { FULFILLED, PENDING, REJECTED } from '../../../globalConstants'
import { fetchPatternTypes } from '../../../../floorplan/actions/actions'
import { getUndoInformation } from '../../undo/undo-actions'

const FETCH_FILE = 'FETCH_FILE'
const FETCH_PATTERN_STYLES = 'FETCH_PATTERN_STYLES'
const FETCH_PATTERN_TYPES = 'FETCH_PATTERN_TYPES'
const UPDATE_PATTERN_STYLES = 'UPDATE_PATTERN_STYLES'
const UPLOAD_PATTERN_STYLES = 'UPLOAD_PATTERN_STYLES'

export const patternStylesActionTypes = {

  FETCH_FILE: FETCH_FILE,

  FETCH_PATTERN_STYLES: FETCH_PATTERN_STYLES,
  FETCH_PATTERN_STYLES_PENDING: FETCH_PATTERN_STYLES + PENDING,
  FETCH_PATTERN_STYLES_FULFILLED: FETCH_PATTERN_STYLES + FULFILLED,
  FETCH_PATTERN_STYLES_REJECTED: FETCH_PATTERN_STYLES + REJECTED,

  FETCH_PATTERN_TYPES: FETCH_PATTERN_TYPES,
  FETCH_PATTERN_TYPES_PENDING: FETCH_PATTERN_TYPES + PENDING,
  FETCH_PATTERN_TYPES_FULFILLED: FETCH_PATTERN_TYPES + FULFILLED,
  FETCH_PATTERN_TYPES_REJECTED: FETCH_PATTERN_TYPES + REJECTED,

  UPDATE_PATTERN_STYLES: UPDATE_PATTERN_STYLES,
  UPDATE_PATTERN_STYLES_PENDING: UPDATE_PATTERN_STYLES + PENDING,
  UPDATE_PATTERN_STYLES_FULFILLED: UPDATE_PATTERN_STYLES + FULFILLED,
  UPDATE_PATTERN_STYLES_REJECTED: UPDATE_PATTERN_STYLES + REJECTED,

  UPLOAD_PATTERN_STYLES: UPLOAD_PATTERN_STYLES,
  UPLOAD_PATTERN_STYLES_PENDING: UPLOAD_PATTERN_STYLES + PENDING,
  UPLOAD_PATTERN_STYLES_FULFILLED: UPLOAD_PATTERN_STYLES + FULFILLED,
  UPLOAD_PATTERN_STYLES_REJECTED: UPLOAD_PATTERN_STYLES + REJECTED,

}

export function fetchPatternStyles () {
  return {
    type: patternStylesActionTypes.FETCH_PATTERN_STYLES,
    payload: httpClient.get(encodeURI(`${projectApi()}/pattern-styles`)),
  }
}

export function updatePatternStyle (unitId, unit) {
  return (dispatch) => {
    dispatch({
      type: patternStylesActionTypes.UPDATE_PATTERN_STYLES,
      payload: httpClient.patch(encodeURI(`${projectApi()}/pattern-styles/${unitId}`), unit),
    })
      .then(() => {
        dispatch(fetchPatternStyles())
        dispatch(fetchPatternTypes())
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

export function downloadPatternStylesFile () {
  return {
    type: patternStylesActionTypes.FETCH_FILE,
    payload: httpClient.getFile(encodeURI(`${projectApi()}/pattern-styles/xlsx`)),
  }
}

export function uploadPatternStylesFile (file, replace) {
  let form = new FormData()
  form.append('replace', replace)
  form.append('file', file)

  return (dispatch) => {
    dispatch({
      type: UPLOAD_PATTERN_STYLES,
      payload: httpClient.post(encodeURI(`${projectApi()}/pattern-styles/xlsx`), form),
    })
      .then(() => dispatch(fetchPatternStyles()))
  }
}

