import * as React from 'react'

const SvgAssistant = props =>
  (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
    >
      <g stroke="#00293B" fill="none" fillRule="evenodd">
        <path d="M12.5 6.5v-1h-1v1h1z"/>
        <path
          strokeLinejoin="round"
          d="M18 17H6a1 1 0 01-1-1v-1a1 1 0 011-1h12a1 1 0 011 1v1a1 1 0 01-1 1zM12 6.5c3.685 0 6.705 2.644 6.98 6H5.02c.275-3.356 3.295-6 6.98-6z"
        />
        <path d="M13 13.5v-1h-2v1h2z"/>
        <path strokeLinecap="round" strokeLinejoin="round" d="M14 5h-4"/>
      </g>
    </svg>
  )

export default SvgAssistant
