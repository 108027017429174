import React from 'react'

const SvgBlackWhiteActive = props => (
  <svg width={36} height={36} className={props.className}>
    <g fill="#373B51" fillRule="evenodd">
      <path d="M18 8c5.523 0 10 4.477 10 10s-4.477 10-10 10S8 23.523 8 18 12.477 8 18 8zm0 1.5a8.5 8.5 0 100 17 8.5 8.5 0 000-17z"/>
      <path d="M21.5 11.938a7 7 0 11-7 12.124 7 7 0 017-12.124zm-6.25 10.825a5.5 5.5 0 005.5-9.526l-5.5 9.526z"/>
    </g>
  </svg>
)

export default SvgBlackWhiteActive
