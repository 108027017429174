import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { fetchProjectData } from '../floorplan/actions/actions'
import { PeopleContent } from './PeopleContent'
import { useParams } from 'react-router-dom'

function PeopleApp() {

  const dataLoaded = useSelector(state => state.dataLoaded.dataLoaded)
  const { projectId } = useParams()
  const dispatch = useDispatch()


  useEffect(() => {
    if (!dataLoaded) {
      dispatch(fetchProjectData(projectId))
    }
  }, [])



    return (
      <PeopleContent/>
    )

}

export default PeopleApp