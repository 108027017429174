import * as React from 'react'

function SvgUndoActive (props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={props.color}
          fillRule="nonzero"
          d="M9 14.214L4.286 9.5 9 4.786z"
        />
        <path
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.5 18.5h1a4.5 4.5 0 000-9H8"
        />
      </g>
    </svg>
  )
}

export default SvgUndoActive
