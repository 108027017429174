import React from 'react'

const SvgUnlockedIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill={props.color} fillRule="evenodd" transform="translate(6.5 3)">
      <path d="M4.8 10.8H6v1.8a.6.6 0 01-1.2 0v-1.8z"/>
      <circle cx={5.4} cy={10.8} r={1.2}/>
      <path d="M0 7.8a.6.6 0 01.6-.6h9.6a.6.6 0 01.6.6v5.4a2.4 2.4 0 01-2.4 2.4h-6A2.4 2.4 0 010 13.2V7.8zm1.2.6v4.8a1.2 1.2 0 001.2 1.2h6a1.2 1.2 0 001.2-1.2V8.4H1.2z"/>
      <path d="M2.999 7.2v.6H1.8v-.6h1.199zM5.4.6a3.6 3.6 0 013.595 3.409L9 4.2l-.001 3.6H7.8V4.2a2.4 2.4 0 00-4.725-.6H1.85A3.601 3.601 0 015.4.6z"/>
    </g>
  </svg>
)

export default SvgUnlockedIcon
