import React from 'react'

import Text from '../../../../common/components/Text'

import './DataField.scss'

function DataField ({ label, data }) {

  return (
    <div className={'data-field'}>
      {label && <Text component={'label'} multiline>
        {label}
      </Text>}
      {data && <Text className={'data-field-data'} component={'label'} multiline>
        {data}
      </Text>}
    </div>
  )
}

export default DataField