import { applicationBuilderActionTypes } from '../constants'
import { httpClient } from '../../common/httpClient'
import { getProjectId, projectApi } from '../../common/utils'
import { floorPlanActionTypes } from '../../floorplan/constants'
import { getUndoInformation } from '../../common/redux/undo/undo-actions'
import { fetchFloorData } from '../../floorplan/actions/actions'

export function createFurnitureEnsembleAndFetch (projectId, ensemble) {
  return (dispatch) => {
    dispatch(createFurnitureEnsemble(projectId, ensemble))
      .then(() => {
        dispatch(fetchFurnitureEnsemblesPolygons(projectId))
        dispatch(getUndoInformation(projectId))
      })
  }
}

export function createFurnitureEnsemble (projectId, ensemble) {
  return {
    type: applicationBuilderActionTypes.CREATE_FURNITURE_ENSEMBLE,
    payload: httpClient.post(`/api/projects/${projectId}/furniture-ensembles`, ensemble),
  }
}

export function deleteFurnitureEnsemble (projectId, ensembleId) {
  return (dispatch) => {
    dispatch(deleteFurnitureEnsembleApiCall(projectId, ensembleId))
      .then(() => {
        dispatch(fetchFurnitureEnsembles(projectId))
        dispatch(getUndoInformation(projectId))
      })
  }
}

function deleteFurnitureEnsembleApiCall (projectId, ensembleId) {
  return {
    type: applicationBuilderActionTypes.DELETE_FURNITURE_ENSEMBLE,
    payload: httpClient.delete(`/api/projects/${projectId}/furniture-ensembles/${ensembleId}`),
  }
}

export function fetchFurnitureEnsembles () {
  return {
    type: applicationBuilderActionTypes.FETCH_FURNITURE_ENSEMBLES,
    payload: httpClient.get(`${projectApi()}/furniture-ensembles`),
  }
}

export function duplicateApplication (projectId, appId) {
  return (dispatch) => {
    dispatch(fetchFurnitureEnsemblePlan(projectId, appId))
      .then((response) => {

        let ensemble = response.action.payload.data
        ensemble.uuid = null
        ensemble.name = null
        ensemble.displayName = ensemble.displayName + ' (Copy)'

        dispatch(createFurnitureEnsemble(projectId, ensemble))
          .then(() => {
            dispatch(fetchFurnitureEnsembles())
              .then(() => {
                dispatch(fetchFurnitureEnsemblesPolygons(projectId))
                dispatch(getUndoInformation(projectId))
              })
          })
      })
  }
}


export function fetchFurnitureEnsemblePlan (projectId, ensembleId) {
  return {
    type: applicationBuilderActionTypes.FETCH_FURNITURE_ENSEMBLE_PLAN,
    payload: httpClient.get(`/api/projects/${projectId}/furniture-ensemble-plans/${ensembleId}`),
  }
}

export function fetchFurnitureEnsembleCategories () {
  return {
    type: applicationBuilderActionTypes.FETCH_FURNITURE_ENSEMBLE_CATEGORIES,
    payload: httpClient.get(`${projectApi()}/furniture-ensemble-categories`),
  }
}

export function fetchFurnitureLibrary (projectId) {
  return {
    type: applicationBuilderActionTypes.FETCH_FURNITURE_LIBRARY,
    payload: httpClient.get(`/api/projects/${projectId}/furniture`),
  }
}

export function fetchFurnitureEnsemblesPolygons (projectId) {
  return {
    type: applicationBuilderActionTypes.FETCH_FURNITURE_ENSEMBLES_POLYGONS,
    payload: httpClient.get(`${projectApi(projectId)}/furniture-ensembles-polygons-complete`),
  }
}

export function fetchTags () {
  return {
    type: floorPlanActionTypes.FETCH_TAGS,
    payload: httpClient.get(`${projectApi()}/tags`),
  }

}

export function createTag (displayName) {
  const tag = { displayName: displayName }

  return (dispatch) => {
    dispatch(createTagApi(tag))
      .then(() => {
        dispatch(fetchTags())
        dispatch(getUndoInformation(getProjectId()))
      })
  }

}

function createTagApi (tag) {
  return {
    type: floorPlanActionTypes.CREATE_TAG,
    payload: httpClient.post(`${projectApi()}/tags`, tag),
  }
}

export function deleteTag (tagId) {
  return (dispatch) => {
    dispatch(deleteTagApi(tagId))
      .then(() => {
        dispatch(fetchTags())
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function deleteTagApi (tagId) {
  return {
    type: floorPlanActionTypes.DELETE_TAG,
    payload: httpClient.delete(`${projectApi()}/tags/${tagId}`),
  }
}

export function updateFurnitureEnsemblePlan (projectId, ensembleId, partialEnsemblePlan) {
  return (dispatch) => {
    dispatch(updateFurnitureEnsemblePlanAPI(projectId, ensembleId, partialEnsemblePlan))
      .then(() => {
        dispatch(fetchFurnitureEnsemblesPolygons(projectId))
        dispatch(fetchFloorData(null, null, true))
      })
      .then(() => {
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function updateFurnitureEnsemblePlanAPI (projectId, ensembleId, partialEnsemblePlan) {
  return {
    type: applicationBuilderActionTypes.SAVE_APPLICATION_PLAN,
    payload: httpClient.put(
      `/api/projects/${projectId}/furniture-ensemble-plans/${ensembleId}`,
      partialEnsemblePlan),
  }
}

export function patchFurnitureEnsemble (projectId, ensembleId, partialEnsemble) {
  return {
    type: applicationBuilderActionTypes.PATCH_FURNITURE_ENSEMBLE_PLAN,
    payload: httpClient.patch(`/api/projects/${projectId}/furniture-ensembles/${ensembleId}`, partialEnsemble),
  }
}