import {Mesh, Vector3} from 'three'
import {createFaceBufferGeometry} from './FaceFactory'
import {getMaterial} from '../common/Materials'
import {isRectangleContainsVertex, isVerticesContainsAllVertices} from '../helper/GeometryHelper'
import {materialTypes} from '../common/MaterialTypes'

export class SelectionArea extends Mesh {

    #startPosition
    #vertices = []
    #minDraggedDistance = .2

    constructor(scene) {
        super()

        scene.add(this)
        this.material = getMaterial(materialTypes.selectionArea)
        this.visible = false
    }

    isActive() {
        return this.visible
    }

    setActive(active) {
        console.log("selctionarea setActive: "+active)
        this.visible = active
    }

    setStartPosition(position) {
        this.#startPosition = position
        this.setActive(true)
        this.updateArea(position)
    }

    updateArea(position) {
        const bottom = position.y > this.#startPosition.y ? this.#startPosition.y : position.y
        const top = position.y > this.#startPosition.y ? position.y : this.#startPosition.y
        const left = position.x > this.#startPosition.x ? this.#startPosition.x : position.x
        const right = position.x > this.#startPosition.x ? position.x : this.#startPosition.x

        this.#vertices = [
            new Vector3(left, top, 0),
            new Vector3(right, top, 0),
            new Vector3(right, bottom, 0),
            new Vector3(left, bottom, 0),
        ]

        if (this.geometry) {
            this.geometry.dispose()
        }
        this.geometry = createFaceBufferGeometry(this.#vertices)
    }

    isContainingAllVertices(vertices){
      let positions=[]
      for(let i=0; i<vertices.length; i++){
        positions. push(vertices[i].position)
      }
      return isVerticesContainsAllVertices(this.#vertices,positions)
    }

    getContainsVertices(vertices) {
        console.log("getContainsVertices")
        console.log(vertices)
        return this.#vertices.length === 4 ?
            vertices.filter(vertex => isRectangleContainsVertex(this.#vertices[0], this.#vertices[2], vertex.position))
            : []
    }

    isDragged() {
        return this.#vertices.length === 4 ? this.#vertices[0].distanceTo(this.#vertices[2]) > this.#minDraggedDistance : false
    }

}