import React, { Component } from 'react'
import { connect } from 'react-redux'

import Section from '../../common/components/layout/Section'
import SectionHeader from '../../common/components/layout/SectionHeader'
import ModalConsumer from '../../common/components/modal/ModalConsumer'
import XlsxUploadDialog from './dialogs/XlsxUploadDialog'

import './PatternStyles.scss'
import PatternStylesTable from './PatternStylesTable'
import { downloadPatternStylesFile, updatePatternStyle, uploadPatternStylesFile } from '../../common/redux/styles/pattern-styles/pattern-styles-actions'
import Button from '../../common/components/Button'
import TablePageButtonToolBar from './TablePageButtonToolBar'
import ButtonGroup from '../../common/components/ButtonGroup'
import { cloneDeep } from 'lodash'
import equal from 'fast-deep-equal'
import RouteLeavingGuard from '../../common/components/RouteLeavingGuard'

class PatternStyles extends Component {

  constructor (props) {
    super(props)
    this.state = {
      units: this.props.units,
      updatedUnits: [],
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!equal(this.props.units, prevProps.units)) {
      this.resetData()
    }
  }

  isChanged () {
    return this.state.updatedUnits && this.state.updatedUnits.length > 0
  }

  render () {
    return (
      <Section className={'pattern-styles'}>
        <SectionHeader title={'Pattern Styles'}/>

        <TablePageButtonToolBar>
          <ModalConsumer>
            {({ showModal }) => (
              <div className={'pattern-styles-buttons'}>
                <ButtonGroup className={'pattern-styles-buttons-import'}>
                  <Button variant={'contained'} onClick={() => showModal(XlsxUploadDialog, { upload: this.props.uploadPatternStylesFile, replace: false })}>
                    Add Data...
                  </Button>
                  <Button onClick={() => showModal(XlsxUploadDialog, { upload: this.props.uploadPatternStylesFile, replace: true })}>
                    Replace Data...
                  </Button>
                  <Button onClick={() => this.props.downloadPatternStylesFile()}>
                    Export XLSX
                  </Button>
                </ButtonGroup>
                <ButtonGroup className={'pattern-styles-buttons-send'}>
                  <Button onClick={() => this.resetData()} disabled={!this.isChanged()}>Discard</Button>
                  <Button variant={'contained'} onClick={() => this.sendUpdate()} disabled={!this.isChanged()}>Confirm</Button>

                </ButtonGroup>
              </div>
            )}
          </ModalConsumer>

        </TablePageButtonToolBar>

        <div>
          {this.state.units ? <PatternStylesTable data={this.state.units} onUnitChange={(id, unit) => this.updatePatternStyles(id, unit)}/> : null}
        </div>

        <RouteLeavingGuard
          when={this.isChanged()}
          confirm={() => this.sendUpdate()}
          lines={['Your Pattern Styles have been modified.', 'Please confirm or discard your changes.', 'To continue editing close this window.']}
        />

      </Section>
    )
  }

  sendUpdate () {
    this.state.updatedUnits.forEach(unitId => this.props.updatePatternStyle(unitId, this.state.units.find(unit => unit.id === unitId)))
    this.setState({ updatedUnits: [] })
  }

  resetData () {
    this.setState({
      units: cloneDeep(this.props.units),
      updatedUnits: [],
    })
  }

  updatePatternStyles (id, unit) {
    unit = this.updatePatternStyle(id, unit)
    let updatedUnits = cloneDeep(this.state.units)
    updatedUnits[updatedUnits.findIndex(unit => unit.id === id)] = cloneDeep(unit)

    this.setState({ units: updatedUnits })
    this.addToUpdatedUnits(id)
  }

  addToUpdatedUnits (id) {
    if (this.state.updatedUnits.indexOf(id) === -1) {
      this.setState({ updatedUnits: [...this.state.updatedUnits, id] })
    }
  }

  updatePatternStyle (id, unit) {

    let returnValue = null

    this.state.units.forEach((patternStyle) => {
      if (patternStyle.id === id) {
        Object.keys(unit)
          .forEach((key) => {
            patternStyle[key] = unit[key]
          })
        returnValue = patternStyle
      }
    })

    return returnValue
  }

}

let mapStateToProps = (state) => {
  return {
    units: state.styles.patternStyles,
  }
}

let mapDispatchToProps = {
  downloadPatternStylesFile: downloadPatternStylesFile,
  uploadPatternStylesFile: uploadPatternStylesFile,
  updatePatternStyle: updatePatternStyle,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatternStyles)
