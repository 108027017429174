import * as THREE from 'three'
import {
  createBufferLinesFromList,
  createLinesFromList,
  createPolygonGeometryFromDoublePairList,
  createShapeFromList,
  createSplineFromLineList,
  drawFacesGeometryOnly,
  getCenterOffsetFromList,
  shiftPointsFromList,
} from './threefunctions'
import { color } from './const'
import { getIcon } from './drawing/drawConstants'
import { getMaterial, materialTypes } from './common/materials'
import { getTextMesh } from './common/texts'

function CCD3D () {
  THREE.Mesh.apply(this, arguments)
  this.aname = ''
}

CCD3D.prototype = Object.create(THREE.Mesh.prototype)
CCD3D.prototype.constructor = CCD3D

export { CCD3D }

function FN3D (color) {

  /*

  FN 3D Structure

  FN3D Children
  |- [0] Lod Medium, Mesh, Furniture Geometry
  |   |- [0] Shadow Plane Geometry
  |   |- [1] Line Drawings
  |   |- [2] Line Drawings
  |- [1] LOD Low
  |- [n] Child Elements


   */
  THREE.Mesh.apply(this, arguments)

  this.nid = ''
  this.saved = null // JSON Furniture Object aus Furniture Library

  this.build = function (libItem) {

    this.type = 'LOD'
    this.nid = libItem.id
    this.selectorType = null
    this.name = null
    this.saved = libItem
    this.furnRotation = null
    this.referenceId = null

    this.buildGeometry(libItem)
  }

  this.buildGeometry = function (libItem) {

    let material = new THREE.MeshBasicMaterial({ color: '#ffffff', depthWrite: true })
    let whiteMaterial = new THREE.MeshBasicMaterial({ color: '#f8f8f8', depthWrite: true })

    let smaterial = new THREE.MeshBasicMaterial({ color: '#000000', depthWrite: false, transparent: true, opacity: 0.1, shadowSide: THREE.FrontSide, side: THREE.BackSide })
    let lineMaterial = new THREE.LineBasicMaterial({ color: '#555' })

    let shapeSimple = createShapeFromList(libItem.abstractPolys)
    let linesSimple = createLinesFromList(libItem.abstractPolys)

    let shape = createShapeFromList(libItem.polys, 0, 0, true, 0.001)
    let lines = createBufferLinesFromList(libItem.lines, true, 0.001, libItem.polys.length, false)
    let shapeLines = createBufferLinesFromList(libItem.polys, true, 0.001, 0)

    let objectMedium = new THREE.Mesh(shape, material)
    let objectMediumShadow = new THREE.Mesh(shape, smaterial)
    objectMediumShadow.position.set(0, .5, 0)
    objectMedium.position.set(0, .01, 0)
    objectMedium.add(objectMediumShadow)
    let lines1 = new THREE.LineSegments(lines, lineMaterial)
    let lines2 = new THREE.LineSegments(shapeLines, lineMaterial)
    let lines3 = new THREE.LineSegments(linesSimple, lineMaterial)
    objectMedium.add(lines2)
    objectMedium.add(lines1)

    lines3.visible = false
    lines3.type = 'lod1Lines'

    let objectLow = new THREE.Mesh(shapeSimple, whiteMaterial)

    objectLow.position.set(0, .0125, 0)
    objectLow.add(lines3)
    objectLow.type = 'lowLod'
    objectLow.visible = false

    this.add(objectMedium)
    this.add(objectLow)
  }

  this.duplicate = function () {
    let d = new FN3D()
    d.build(this.saved)

    return d
  }

}

FN3D.prototype = Object.create(THREE.Mesh.prototype)
FN3D.prototype.constructor = FN3D

export { FN3D }

function FN3DExtended () {
  THREE.Mesh.apply(this, arguments)

  this.source = null // Placed Furnite Description from Plan
  this.selectorPlane = null

  //Top Right Bottom Left
  this.rotationInherited = 0
  this.rotationAtParent = 0
  this.rotationSelf = 0

  this.furnitureRotationDeg = 0

  this.rotationValue = 0
  this.coordSystemRotationValue = 0
  this.connectorSide = 'TOP'
  this.mySide = 'TOP'
  this.posOnMySide = 'MID'
  this.posOnConnectorSide = 'MID'
  this.posOnMySideValue = 0
  this.posOnConnectorSideValue = 0
  this.inheritedStatic = false
  this.bboxComplete = new THREE.Box3()
  this.patternArea = null
  this.isChild = false

  // For Placment in Rooms etc
  //Positioning Lists on Connector
  this.posListX = []
  this.posListZ = []

  // add to SideCode (for Placement in room)
  this.addToSidecode = 0
  // make direaction negative
  this.neg = 1

  this.bboxS = new THREE.Box3()
  this.bboxM = new THREE.Box3()
  this.width = 0
  this.depth = 0
  this.unshiftArea = new THREE.Vector3()

  this.build = function (libItem) {

    this.nid = libItem.id
    this.selectorType = null
    this.name = null
    this.saved = libItem
    this.furnRotation = null
    this.referenceId = null
    this.planeRotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2)

    this.buildGeometry(libItem)
  }

  this.checkAdditions = function () {
    if (this.parent instanceof FN3DExtended) this.isChild = true
    if ((!this.isChild) && (this.connectorSide !== 'CENTER')) {
      this.addToSidecode = 2
      this.neg = -1
    }
  }

  this.setupBoundingBoxWithoutChildren = function () {
    this.bboxS = this.children[0].geometry.boundingBox
  }

  this.getRoom = function () {
    let p = this.parent
    if (p instanceof Room) return p
    return p.getRoom()
  }

  this.setupBoundingBoxWithChildren = function () {
    this.bboxM = new THREE.Box3().setFromObject(this, true)
    this.bboxM.min.x -= this.parent.position.x
    this.bboxM.max.x -= this.parent.position.x
    this.bboxM.min.z -= this.parent.position.z
    this.bboxM.max.z -= this.parent.position.z
    this.posListX = [this.bboxM.min.x, (this.bboxM.min.x + this.bboxM.max.x) / 2, this.bboxM.max.x]
    this.posListZ = [this.bboxM.min.z, (this.bboxM.min.z + this.bboxM.max.z) / 2, this.bboxM.max.z]

  }

  this.centerBbox = function () {
    let lx = this.bbox.max.x - this.bbox.min.x
    let lz = this.bbox.max.z - this.bbox.min.z
    this.bbox.max.x = lx / 2
    this.bbox.min.x = -lx / 2
    this.bbox.max.z = lz / 2
    this.bbox.min.z = -lz / 2
  }

  this.duplicate = function () {
    let d = new FN3DExtended()
    d.build(this.saved)

    return d
  }

  this.detach = function (parent, scene) {

    if (parent) {

      this.applyMatrix4(parent.matrixWorld)
      parent.remove(this)
      scene.add(this)
    }

  }

  this.attach = function (scene, parent) {

    this.applyMatrix4(new THREE.Matrix4().getInverse(parent.matrixWorld))

    scene.remove(this)
    parent.add(this)

  }

  this.buildGeometry = function (libItem) {

    let material = new THREE.MeshBasicMaterial({ color: '#ffffff', depthWrite: true })
    let lineMaterial = new THREE.LineBasicMaterial({ color: '#555' })
    let shape = createShapeFromList(libItem.polys, 0, 0, true, 0.001)

    let offset = getCenterOffsetFromList(libItem.lines, libItem.polys)
    shiftPointsFromList(libItem.lines, offset)
    shiftPointsFromList(libItem.polys, offset)

    let lines = createBufferLinesFromList(libItem.lines, true, 0.001, libItem.polys.length, false)
    let shapeLines = createBufferLinesFromList(libItem.polys, true, 0.001, 0)

    let objectMedium = new THREE.Mesh(shape, material)

    objectMedium.position.set(0, .01, 0)

    let lines1 = new THREE.LineSegments(lines, lineMaterial)
    let lines2 = new THREE.LineSegments(shapeLines, lineMaterial)

    //
    objectMedium.add(lines2)
    objectMedium.add(lines1)

    this.add(objectMedium)

  }

  this.applyData = function (singleFurnitureInEnsemble) {
    this.source = singleFurnitureInEnsemble
    this.name = singleFurnitureInEnsemble.uuid
    this.children[0].geometry.computeBoundingBox()
    this.children[0].position.set(0, 0.005, 0)

    this.updatePlacementInformations(singleFurnitureInEnsemble)
  }

  this.create = function (fn3d) {

  }

  this.centerPivot = function () {
    let maxX = -1000
    let maxZ = 1000
    let minX = 1000
    let minZ = -1000

    for (let i = 0; i < this.children.length; i++) {
      for (let j = 0; j < this.children[i].geometry.vertices.length; j++) {
        let n = this.children[i].geometry.vertices[j]

        if (n.z < maxZ) {maxZ = n.z}
        if (n.x > maxX) {maxX = n.x}
        if (n.z > minZ) {minZ = n.z}
        if (n.x < minX) {minX = n.x}



      }
      for (let j = 0; j < this.children[i].children.length; j++) {
        for (let k = 0; k < this.children[i].children[j].geometry.vertices.length; k++) {
          let n = this.children[i].children[j].geometry.vertices[k]
          if (n.z < maxZ) {maxZ = n.z}
          if (n.x > maxX) {maxX = n.x}
          if (n.z > minZ) {minZ = n.z}
          if (n.x < minX) {minX = n.x}
        }
      }


    }

    for (let k = 0; k < this.children.length; k++) {
      for (let j = 0; j < this.children[k].geometry.vertices.length; j++) {
        this.children[k].geometry.vertices[j].z -= ((maxZ - minZ) / 2)

        this.children[k].geometry.vertices[j].x -= ((maxX - minX) / 2)

      }
    }

    for (let i = 0; i < this.children.length; i++) {

      for (let k = 0; k < this.children[i].children.length; k++) {
        for (let j = 0; j < this.children[i].children[k].geometry.vertices.length; j++) {
          this.children[i].children[k].geometry.vertices[j].z -= ((maxZ - minZ) / 2)

          this.children[i].children[k].geometry.vertices[j].x -= ((maxX - minX) / 2)

        }
      }

      this.children[i].geometry.verticesNeedUpdate = true
      this.children[i].geometry.elementsNeedUpdate = true
    }

  }

  this.getAngle = function (number) {

    let returnval
    switch (this.limitValue(number)) {
      case 1:
        returnval = Math.PI / 2
        break
      case 2:
        returnval = 0
        break
      case 3:
        returnval = -Math.PI / 2
        break
      case 0:
      default:
        returnval = Math.PI
    }

    return returnval
  }

  this.rotateVector = function (vector) {
    let vec = vector.clone()
    let axis = new THREE.Vector3(0, 1, 0)
    let angle = 0
    if (this.parent instanceof FN3DExtended) {

      angle = this.getAngle(this.rotationInherited)

      if (this.rotationInherited === 0) angle -= this.parent.furnitureRotationDeg
      if (this.rotationInherited === 2) angle += this.parent.furnitureRotationDeg
      if (this.rotationInherited === 3) angle += this.parent.furnitureRotationDeg
      if (this.rotationInherited === 1) angle -= this.parent.furnitureRotationDeg

    } else {
      angle = Math.PI
    }
    vec.applyAxisAngle(axis, angle)
    return vec
  }

  this.rotateVectorForPlacement = function (vector) {
    let vec = vector.clone()
    let axis = new THREE.Vector3(0, 1, 0)
    let angle = 0
    let addangle = 0
    if (this.parent instanceof FN3DExtended) {

      if (this.parent.connectorSide === 'CENTER') addangle = 2
      //addangle = 2
      if ((this.parent.parent instanceof FN3DExtended) && (this.parent.parent.connectorSide !== 'CENTER')) addangle += 2
      angle = this.parent.getAngle(-this.parent.rotationInherited - this.parent.rotationAtParent + this.parent.rotationSelf - this.rotationAtParent - addangle)

      angle -= this.parent.furnitureRotationDeg

    } else {
      addangle = 2
      if (this.connectorSide === 'CENTER') addangle += 2

      angle = this.getAngle(-this.rotationAtParent + addangle)

    }

    vec.applyAxisAngle(axis, angle)

    return vec
  }

  // Mous Collision Selector

  this.prepareSelectorPlane = function () {
    let m = new THREE.MeshBasicMaterial({ 'color': '#aaff00', 'visible': false })
    //let m = new THREE.MeshBasicMaterial({'color': '#aaff00'})
    let g = new THREE.BoxBufferGeometry(1, 1, 1)
    let mesh = new Selector(g, m)

    mesh.scale.set(0, 0, 0)
    this.selectorPlane = mesh
    this.selectorPlane.linkedObject = this
    this.bbox = new THREE.Box3()
  }

  this.addSelectorPlane = function (target, array) {

    target.add(this.selectorPlane)
    array.push(this.selectorPlane)
    this.selectorPlane.createAnchors()

  }

  this.updateSelectorPlane = function () {
    this.bbox.setFromObject(this)
    this.bbox.setFromObject(this.children[0])

    this.scalePlane = 0.1
    this.w = this.bbox.max.x - this.bbox.min.x + this.scalePlane
    this.h = this.bbox.max.y - this.bbox.min.y + this.scalePlane
    this.d = this.bbox.max.z - this.bbox.min.z + this.scalePlane
    this.px = this.bbox.min.x + this.w / 2 - this.scalePlane / 2
    this.py = this.bbox.min.y + this.h / 2 - this.scalePlane / 2
    this.pz = this.bbox.min.z + this.d / 2 - this.scalePlane / 2

    let g = new THREE.BoxBufferGeometry(this.w, 0.001, this.d)
    this.selectorPlane.geometry = g
    this.scalePlane = 0.1
    this.selectorPlane.scale.set(1, 1, 1)
    this.selectorPlane.children = []
    this.selectorPlane.createAnchors(this.w, this.d)

    //this.selectorPlane.position.set(this.px, 0.003, this.pz)
  }

  this.moveSelectorPlane = function () {

    let vector = this.position

    if (this.selectorPlane) this.selectorPlane.position.set(vector.x, 0.003, vector.z)

  }

  this.rotateSelectorPlane = function (angle) {

    if (this.selectorPlane) this.selectorPlane.rotateY(this.furnRotation)
  }

  this.updatePlacementInformations = function (furniture) {
    this.posOnMySide = furniture.posOnMySide
    this.posOnConnectorSide = furniture.posOnConnectorSide
    this.connectorSide = furniture.connectorSide
    this.mySide = furniture.mySide

    switch (this.mySide) {
      case 'TOP':
      default:
        this.rotationSelf = 0
        break
      case 'RIGHT':
        this.rotationSelf = 1
        break
      case 'BOTTOM':
        this.rotationSelf = 2
        break
      case 'LEFT':
        this.rotationSelf = 3
        break
    }

    switch (this.connectorSide) {
      case 'TOP':
      default:
        this.rotationAtParent = 0
        break
      case 'RIGHT':
        this.rotationAtParent = 1
        break
      case 'BOTTOM':
        this.rotationAtParent = 2
        break
      case 'LEFT':
        this.rotationAtParent = 3
        break
    }

    switch (this.posOnConnectorSide) {
      case 'MID':
      default:
        this.posOnConnectorSideValue = 1
        break
      case 'START':
        this.posOnConnectorSideValue = 0
        break
      case 'END':
        this.posOnConnectorSideValue = 2
        break
    }

    switch (this.posOnMySide) {
      case 'MID':
      default:
        this.posOnMySideValue = 1
        break
      case 'START':
        this.posOnMySideValue = 0
        break
      case 'END':
        this.posOnMySideValue = 2
        break
    }

  }

  this.scanParentRotations = function () {

    this.rotationInherited = 0
    this.parentDegrees = 0

    this.parentLooper(this.parent)

    this.parentDegrees = this.parentLooperDegrees(this.parent)

    this.limitRotation()

  }

  this.parentLooper = function (parentElement) {

    if (parentElement instanceof FN3DExtended) {
      this.rotationInherited += parentElement.rotationAtParent - parentElement.rotationSelf
      this.rotationInherited += parentElement.rotationInherited

      //this.parentDegrees += parentElement.furnitureRotationDeg
      parentElement.parentLooper()

    }

  }

  this.parentLooperDegrees = function (parentElement) {

    if (parentElement instanceof FN3DExtended) {
      let retval = parentElement.furnitureRotationDeg
      retval += parentElement.parentLooperDegrees(parentElement.parent)
      return retval
    } else {
      return 0
    }

  }

  this.limitValue = function (value) {
    while (value > 3) {
      value -= 4
    }
    while (value < 0) {
      value += 4
    }
    return value
  }

  this.limitRotation = function () {
    while (this.rotationValue > 3) {
      this.rotationValue -= 4
    }
    while (this.coordSystemRotationValue > 3) {
      this.coordSystemRotationValue -= 4
    }
    while (this.rotationInherited > 3) {
      this.rotationInherited -= 4
    }
    while (this.rotationValue < 0) {
      this.rotationValue += 4
    }
    while (this.coordSystemRotationValue < 0) {
      this.coordSystemRotationValue += 4
    }
    while (this.rotationInherited < 0) {
      this.rotationInherited += 4
    }
  }
}

FN3DExtended.prototype = Object.create(THREE.Mesh.prototype)
FN3DExtended.prototype.constructor = FN3DExtended

/*
FN3DExtended Structure

# SelectorPlane:
# Selector

FN3DExtended
  |--SelectorPlane
      |--Children
      |   |--4 Lines
      |   |--16 AnchorPoints
      |--FN3DXT Recusive


SELECTOR:


 */

export { FN3DExtended }

function DPTMesh () {
  THREE.Mesh.apply(this, arguments)
  this.uuid = ''
  this.appUuid = ''
  this.type = ''
  this.isExtra = false
  this.locked = null
  this.bBox = null
  this.parentUuid = null
  this.shiftx = 0
  this.shifty = 0
  this.maxSize = 0

  this.parentUuid = null

  this.setHighlight = function (color) {
    this.material.color = color
  }

  this.setOpacity = function (opacity) {
    this.material.opacity = opacity
  }

  this.getMiddlePoint = function () {
    if (this.boundingBox.length === 8) {
      const x = (this.boundingBox[0] + this.boundingBox[4]) / 2
      const z = (this.boundingBox[1] + this.boundingBox[5]) / 2

      return new THREE.Vector3(x, 0, z)
    }

  }

}

DPTMesh.prototype = Object.create(THREE.Mesh.prototype)
DPTMesh.prototype.constructor = DPTMesh
export { DPTMesh }

function DPTHandle () {
  THREE.Mesh.apply(this, arguments)
  this.index = ''
  this.type = 'DepartmentHandle'
  this.parentUuid = null
  this.officeDescriptionId = null
  this.centerPointA = null
  this.centerPointB = null

  this.applyData = function (data, color, index) {
    this.index = index
    this.parentUuid = data.departmentId
    this.officeDescriptionId = data.officeDescriptionId

    let dptThreeColor = new THREE.Color(color.red, color.green, color.blue)
    this.material = new THREE.MeshBasicMaterial({ color: color })
    this.geometry = createPolygonGeometryFromDoublePairList(data.displayPolys)

    let outline = createSplineFromLineList(data.displayPolys, 0.05, 32)
    let outlineMesh = new THREE.Mesh(outline, new THREE.MeshBasicMaterial({ color: dptThreeColor, depthWrite: true }))
    this.add(outlineMesh)
    outlineMesh.position.set(0, 0.01, 0)

    //this.geometry = new THREE.SphereGeometry(3,16,16)
    //this.geometry.elementsNeedUpdate = true
    this.position.set(0, .2, 0)

    this.centerPointA = new THREE.Vector2(data.centerPointA[0], data.centerPointA[1])
    this.centerPointB = new THREE.Vector2(data.centerPointB[0], data.centerPointB[1])
    this.visible = false


  }

  this.setHighlight = function (color = null) {
    this.material.color = this.children[0].material.color
  }

  this.removeHighlight = function () {
    this.material.color = new THREE.Color(255, 255, 255)
  }

}

DPTHandle.prototype = Object.create(THREE.Mesh.prototype)
DPTHandle.prototype.constructor = DPTMesh

export { DPTHandle }

function ApplicationMesh () {
  THREE.Mesh.apply(this, arguments)
  this.uuid = ''
  this.type = ''
  this.parent = null
}

ApplicationMesh.prototype = Object.create(THREE.Mesh.prototype)
ApplicationMesh.prototype.constructor = ApplicationMesh

export { ApplicationMesh }

function Anchor () {
  THREE.Group.apply(this, arguments)
  this.anchorSide = 'BOTTOM'
  this.anchorPosition = 'MID'
  this.myLine = null
  this.refAnchor = null
  this.selectorPlane = null
  let size = 0.07
  this.planeRotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2)
  this.visible = false
  this.root = false

  let geometry = new THREE.CircleGeometry(size * .8, 12)
  let material = new THREE.MeshBasicMaterial({ color: color.anchor })
  let object = new THREE.Mesh(geometry, material)
  object.applyMatrix4(this.planeRotation)
  this.add(object)
  this.position.set(0, 0.04, 0)

  this.setAnchorSide = function (name) {
    this.anchorSide = name
    return this
  }
  this.setAnchorPosition = function(name) {
    this.anchorPosition = name
    return this
  }
  this.setMyLine = function (name) {
    this.myLine = name
    return this
  }
  this.setRefAnchor = function (name) {
    this.refAnchor = name
    return this
  }
  this.setRoot = function () {
    this.root = true
    return this
  }

}

Anchor.prototype = Object.create(THREE.Group.prototype)
Anchor.prototype.constructor = Anchor

export { Anchor }

function Selection () {
  THREE.Mesh.apply(this, arguments)
  let geo = new THREE.PlaneGeometry(1, 1)
  let mat = new THREE.LineBasicMaterial({ color: color.selectionPlan, opacity: 0, depthTest: false, transparent: false, depthWrite: false })
  this.mesh = new THREE.Mesh(geo, mat)
  this.bbox = new THREE.Box3()
  this.planeRotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2)
  this.mesh.applyMatrix4(this.planeRotation)
  this.renderOrder = 999
  this.add(this.mesh)

  this.setForPatternBuilder = function () {
    let mat = new THREE.MeshBasicMaterial({ color: color.selectionPlan, opacity: 0.3, depthTest: true, transparent: true, depthWrite: true })
    this.children[0].material = mat
    this.position.y = 0
  }

}

Selection.prototype = Object.create(THREE.Mesh.prototype)
Selection.prototype.constructor = Selection

export { Selection }

function MovableAxis () {
  THREE.Mesh.apply(this, arguments)

  this.setFromLineData = function(data) {
    this.point1 = new THREE.Vector3(data.pointList[0][0], 0.03, -data.pointList[0][1])
    this.point2 = new THREE.Vector3(data.pointList[1][0], 0.03, -data.pointList[1][1])

    if (!((this.point1.x === this.point2.x) && (this.point1.z === this.point2.z))) {

      this.directionVector = new THREE.Vector2(this.point1.x - this.point2.x, this.point1.z - this.point2.z)
      this.deletable = data.deletable
      this.hasMidZone = data.midZone

      let path = new THREE.LineCurve(this.point1, this.point2)
      let tube = new THREE.TubeGeometry(path, 1, 0.1)
      let tubeSelect = new THREE.TubeGeometry(path, 1, 1.5)
      let tubeMaterial = new THREE.MeshBasicMaterial({ color: color.moveableAxis })
      let tubeSelectMaterial = new THREE.MeshBasicMaterial({ color: color.moveableAxisSelection, visible: false })
      let mesh = new THREE.Mesh(tube, tubeMaterial)
      this.scale.y = 0.05

      this.add(mesh)
      this.geometry = tubeSelect
      this.material = tubeSelectMaterial
      this.axisId = -1
    }
  }


  this.updateLine = function () {
    let path = new THREE.LineCurve(this.point1, this.point2)
    let tube = new THREE.TubeGeometry(path, 1, 0.1)
    let tubeSelect = new THREE.TubeGeometry(path, 1, 1.5)
    this.children[0].geometry = tube
    this.geometry = tubeSelect
  }
}
MovableAxis.prototype = Object.create(THREE.Mesh.prototype)
MovableAxis.prototype.constructor = MovableAxis

export { MovableAxis }

function NonMovableAxis () {
  THREE.Mesh.apply(this, arguments)

  this.color = color.moveableAxis

  this.setColor = function (color) {
    this.color = color
  }

  this.setFromLineData = function (data) {
    this.point1 = new THREE.Vector3(data.pointList[0][0], 0.03, -data.pointList[0][1])
    this.point2 = new THREE.Vector3(data.pointList[1][0], 0.03, -data.pointList[1][1])

    let path = new THREE.LineCurve(this.point1, this.point2)
    let tube = new THREE.TubeGeometry(path, 1, 0.1)
    let tubeMaterial = new THREE.MeshBasicMaterial({ color: this.color })
    let tubeSelectMaterial = new THREE.MeshBasicMaterial({ color: color.moveableAxisSelection, visible: false })
    let mesh = new THREE.Mesh(tube, tubeMaterial)
    this.scale.y = 0.05

    this.add(mesh)
    //this.geometry = tubeSelect
    this.material = tubeSelectMaterial
    this.axisId = -1
  }

}

NonMovableAxis.prototype = Object.create(THREE.Mesh.prototype)
NonMovableAxis.prototype.constructor = NonMovableAxis

export { NonMovableAxis }

function ServiceArea (addLabel = false, labelVisible = true, debug = false) {
  THREE.Mesh.apply(this, arguments)

  this.setFromData = function (data, showPattern) {
    this.geometry = drawFacesGeometryOnly(data.triangles)
    this.material = new THREE.MeshBasicMaterial({ color: 0xffffff })
    this.currentScale = null
    this.createOverlayMaterial(this.material)

    let leftTopCorner = new THREE.Vector2(99999999, -999999)

    data.triangles.forEach(triangle => {
      for (let i = 0; i < triangle.length - 1; i += 2) {
        const vertex = new THREE.Vector2(triangle[i], -triangle[i + 1])
        if (vertex.x < leftTopCorner.x) {
          leftTopCorner = vertex
        } else if (vertex.x < leftTopCorner.x + .05 && vertex.y < leftTopCorner.y) {
          leftTopCorner = vertex
        }
      }
    })

    this.leftTopCorner = leftTopCorner
    this.uuid = data.id
    this.index = data.index
    this.area = data.area
    this.faceType = data.faceType
    this.coreFaceType = data.faceType
    this.faceTypeId = data.faceTypeId

    this.setFaceType(this.faceType, true, showPattern)

    if (this.area > 10) {
      this.text = getTextMesh(this.area + ' m²', .5)
      this.text.position.set(leftTopCorner.x + 1, 0, leftTopCorner.y + .7)
      this.add(this.text)
    }
  }

  this.createOverlayMaterial = function (material) {
    let overlay = new THREE.Mesh(this.geometry, material)
    this.add(overlay)
  }

  this.setFaceType = function (faceType, setValue = true, showPattern = true) {
    if (setValue) {
      this.faceType = faceType
    }

    switch (faceType) {
      /*
      case zoneTypes.zone:
        this.children[0].material = showPattern ? getMaterial(materialTypes.openSpacePattern) : getMaterial(materialTypes.white)
        break
      case zoneTypes.room:
        this.children[0].material = showPattern ? getMaterial(materialTypes.roomsPattern) : getMaterial(materialTypes.white)
        break
      case zoneTypes.infrastructure:
        this.children[0].material = getMaterial(materialTypes.infrastructurePattern)
        break
      case zoneTypes.blocked:
        this.children[0].material = getMaterial(materialTypes.blockedPattern)
        break

       */
      default:
        break
    }

    if (this.currentScale && this.children[0].material.map) {
      this.children[0].material.map.repeat.set(this.currentScale, this.currentScale)
    }

    this.setIcon(faceType)
  }

  this.setScale = function (scale) {
    this.currentScale = .01 / scale
    if (this.children[0].material.map) {
      this.children[0].material.map.repeat.set(this.currentScale, this.currentScale)
    }
  }

  this.setIcon = function (faceType) {
    if (!this.icon) {
      this.icon = getIcon(getMaterial(materialTypes.black), .6, .6)
      this.icon.position.set(this.leftTopCorner.x + .5, 1, this.leftTopCorner.y + .5)
      this.add(this.icon)
    }

    if (this.area < 10) {
      this.icon.visible = false
    }

    switch (faceType) {
      /*
      case zoneTypes.zone:
        this.icon.material = getMaterial(materialTypes.openSpaceIcon)
        break
      case zoneTypes.room:
        this.icon.material = getMaterial(materialTypes.roomsIcon)
        break
      case zoneTypes.infrastructure:
        this.icon.material = getMaterial(materialTypes.infrastructureIcon)
        break
      case zoneTypes.blocked:
        this.icon.material = getMaterial(materialTypes.blockedIcon)
        break
       */
      default:
        break
    }

  }

  this.findLabelPosition = function (data, debug = false) {
    let x = 1000000
    let z = -1000000
    let tolerance = 0.2
    for (let i = 0; i < data.triangles.length; i++) {

      if (data.triangles[i][1] >= z - tolerance) {
        if ((data.triangles[i][0] <= x) || (data.triangles[i][1] > z + tolerance)) {
          z = data.triangles[i][1]
          x = data.triangles[i][0]
          if (debug) console.log('Using:')

        }

      }
      if (debug) console.log('Tri ' + i + ' , Point 1: ' + data.triangles[i][0] + ' ' + data.triangles[i][1])

      if (data.triangles[i][3] >= z - tolerance) {
        if ((data.triangles[i][2] <= x) || (data.triangles[i][3] > z + tolerance)) {
          z = data.triangles[i][3]
          x = data.triangles[i][2]
          if (debug) console.log('Using:')

        }
      }

      if (debug) console.log('Tri ' + i + ' , Point 2: ' + data.triangles[i][2] + ' ' + data.triangles[i][3])

      if (data.triangles[i][5] >= z - tolerance) {
        if ((data.triangles[i][4] <= x) || (data.triangles[i][5] > z + tolerance)) {
          z = data.triangles[i][5]
          x = data.triangles[i][4]
          if (debug) console.log('Using:')

        }
      }

      if (debug) console.log('Tri ' + i + ' , Point 3: ' + data.triangles[i][4] + ' ' + data.triangles[i][5])

    }

    return new THREE.Vector3(x, 0.08, z)
  }

  this.setLabelVisibility = function (visible) {
    let labelObject = this.children.find(child => child instanceof THREE.Mesh && child.type === 'zoneLabel')

    if (labelObject)
      labelObject.visible = visible
  }
}

ServiceArea.prototype = Object.create(THREE.Mesh.prototype)
ServiceArea.prototype.constructor = ServiceArea

export { ServiceArea }

function AxisConnector () {
  THREE.Mesh.apply(this, arguments)
  const geometry = new THREE.SphereGeometry(.5)
  const material = new THREE.MeshBasicMaterial({ color: color.axisConnector })
  this.add(new THREE.Mesh(geometry, material))
  this.children[0].visible = false

  this.setFromAxisSideSegment = function (data) {
    this.geometry = new THREE.SphereGeometry(0.8)
    this.connectorId = data.id
    this.position.set(data.position[0], 0, -data.position[1])
    this.material = new THREE.MeshBasicMaterial({ color: color.axisConnectorSide, visible: false })
    this.type = 'outCorner'
  }

  this.setFromData = function (data) {
    this.geometry = new THREE.SphereGeometry(1)
    this.connectorId = data.id
    this.position.set(data.distancePoint[0], 0, -data.distancePoint[1])
    this.deletable = data.deletable

    switch (data.type) {
      case 'FREE':
      default:
        this.material = new THREE.MeshBasicMaterial({ color: color.axisConnectorFree, visible: false })
        this.type = 'FREE'
        break

      case 'CROP':
        this.material = new THREE.MeshBasicMaterial({ color: color.axisConnectorSide, visible: false })
        this.type = 'CROP'
        break
      case 'CONTINUOUS':
        this.material = new THREE.MeshBasicMaterial({ color: color.axisConnectorContinous, visible: false })
        this.type = 'CONTINUOUS'
        break
    }
  }
}

AxisConnector.prototype = Object.create(THREE.Mesh.prototype)
AxisConnector.prototype.constructor = AxisConnector

export { AxisConnector }


function Selector () {
  THREE.Mesh.apply(this, arguments)
  this.linkedObject = null
  this.active = true

  this.createAnchors = function (w, d, type) {

    // Top Right Bottom Left - Start Mid End -> Position names: Top Center = TC

    let hideRightEnd = false
    let hideBottom = false
    let hideTop = false
    if ((type === 'main') || (type === 'fill')) hideRightEnd = true
    if ((type === 'workBenchFront') || (type === 'corridor')) hideBottom = true
    if ((type === 'workBenchBack')) hideTop = true

    let lineMat = new THREE.LineBasicMaterial({ color: color.gizmo })
    let topLineGeo = new THREE.Geometry()
    let bottomLineGeo = new THREE.Geometry()
    let leftLineGeo = new THREE.Geometry()
    let rightLineGeo = new THREE.Geometry()
    topLineGeo.vertices.push(new THREE.Vector3(-w / 2, 0, -d / 2), new THREE.Vector3(w / 2, 0, -d / 2))
    bottomLineGeo.vertices.push(new THREE.Vector3(-w / 2, 0, d / 2), new THREE.Vector3(w / 2, 0, d / 2))
    leftLineGeo.vertices.push(new THREE.Vector3(-w / 2, 0, -d / 2), new THREE.Vector3(-w / 2, 0, d / 2))
    rightLineGeo.vertices.push(new THREE.Vector3(w / 2, 0, -d / 2), new THREE.Vector3(w / 2, 0, d / 2))
    this.topLine = new THREE.Line(topLineGeo, lineMat)
    this.bottomLine = new THREE.Line(bottomLineGeo, lineMat)
    this.leftLine = new THREE.Line(leftLineGeo, lineMat)
    this.rightLine = new THREE.Line(rightLineGeo, lineMat)

    let RM, RS, RE, BS, BM, TE, BE, LS, TS, TM, LE

    if (!hideTop) {
      TM = new Anchor()
      TM.position.set(0, 0.005, -d / 2)
      TM.anchorSide = 'TOP'
      TM.anchorPosition = 'MID'
      TM.selectorPlane = this
      TM.myLine = this.topLine
    }

    this.topLine.visible = false
    this.bottomLine.visible = false
    this.leftLine.visible = false
    this.rightLine.visible = false

    this.add(this.topLine)
    this.add(this.bottomLine)
    this.add(this.leftLine)
    this.add(this.rightLine)
    this.add(TM)

    if (!hideBottom) {
      BM = new Anchor()
      BM.position.set(0, 0.005, d / 2)
      BM.anchorSide = 'BOTTOM'
      BM.anchorPosition = 'MID'
      this.add(BM)
      BM.selectorPlane = this
      BM.myLine = this.bottomLine
    }

    let LM = new Anchor()
    LM.position.set(-w / 2, 0.005, 0)
    LM.anchorSide = 'LEFT'
    LM.anchorPosition = 'MID'
    this.add(LM)
    LM.selectorPlane = this
    LM.myLine = this.leftLine

    if (!hideRightEnd) {
      RM = new Anchor()
      RM.position.set(w / 2, 0.005, 0)
      RM.anchorSide = 'RIGHT'
      RM.anchorPosition = 'MID'
      this.add(RM)
      RM.selectorPlane = this
      RM.myLine = this.rightLine
    }

    if (!hideTop) {
      TS = new Anchor()
      TS.position.set(-w / 2, 0.005, -d / 2)
      TS.anchorSide = 'TOP'
      TS.anchorPosition = 'START'
      this.add(TS)
      TS.selectorPlane = this
      TS.myLine = this.topLine
      TS.refAnchor = TM

      LE = TS.clone()
      LE.position.y = 0.005
      LE.anchorSide = 'LEFT'
      LE.anchorPosition = 'END'
      this.add(LE)
      LE.selectorPlane = this
      LE.myLine = this.leftLine
      TS.refAnchor = LM
    }

    if (!hideRightEnd && !hideTop) {
      TE = new Anchor()
      TE.position.set(w / 2, 0.005, -d / 2)
      TE.anchorSide = 'TOP'
      TE.anchorPosition = 'END'
      this.add(TE)
      TE.selectorPlane = this
      TE.myLine = this.topLine
      TE.refAnchor = TM

      RS = TE.clone()
      RS.position.y = 0.005
      RS.anchorSide = 'RIGHT'
      RS.anchorPosition = 'START'
      this.add(RS)
      RS.selectorPlane = this
      RS.myLine = this.rightLine
      RS.refAnchor = RM
    }

    if (!hideRightEnd && !hideBottom) {
      RE = new Anchor()
      RE.position.set(w / 2, 0.005, d / 2)
      RE.anchorSide = 'RIGHT'
      RE.anchorPosition = 'END'
      this.add(RE)
      RE.selectorPlane = this
      RE.myLine = this.rightLine
      RE.refAnchor = RM

      BS = RE.clone()
      BS.anchorSide = 'BOTTOM'
      BS.anchorPosition = 'START'
      this.add(BS)
      BS.selectorPlane = this
      BS.myLine = this.bottomLine
      BS.refAnchor = BM
    }

    if (!hideBottom) {
      BE = new Anchor()
      BE.position.set(-w / 2, 0.005, d / 2)
      BE.anchorSide = 'BOTTOM'
      BE.anchorPosition = 'END'
      this.add(BE)
      BE.selectorPlane = this
      BE.myLine = this.bottomLine
      BE.refAnchor = BM

      LS = BE.clone()
      LS.anchorSide = 'LEFT'
      LS.anchorPosition = 'START'
      this.add(LS)
      LS.selectorPlane = this
      LS.myLine = this.leftLine
      LS.refAnchor = BM
    }
  }

  this.getAnchors = function () {
    let anchors = []

    for (let zeppelin = 0; zeppelin < this.children.length; zeppelin++) {
      if (this.children[zeppelin] instanceof Anchor) {
        anchors.push(this.children[zeppelin])
      }
    }

    return anchors
  }

  this.removeAnchors = function (array) {
    //this.children = []

    for (let d = array.length - 1; d >= 0; d--) {
      if (this.children.indexOf(array[d]) > -1) {
        array.splice(d, 1)
      }

    }

    for (let d = 0; d < this.linkedObject.children.length; d++) {

      if (this.linkedObject.children[d] instanceof FN3DExtended) {
        this.linkedObject.children[d].selectorPlane.removeAnchors(array)
      }
    }
  }

}

Selector.prototype = Object.create(THREE.Mesh.prototype)
Selector.prototype.constructor = Selector

export { Selector }

function Gizmo () {
  THREE.Mesh.apply(this, arguments)
  this.planeRotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2)
  this.visible = false

  let selectorColor = color.gizmo
  let rsmat = new THREE.MeshBasicMaterial({ color: selectorColor, depthWrite: true })
  let rslmat = new THREE.LineBasicMaterial({ color: selectorColor, depthWrite: true })
  let rsgeo = new THREE.Shape()

  let size = 0.07
  let circleSize = size * 0.8
  let offset = 0.03

  let point1 = new THREE.Vector3(size, -offset, 0)
  let point2 = new THREE.Vector3(0, -offset - size, 0)
  let point3 = new THREE.Vector3(-size, -offset, 0)

  let pointq1 = new THREE.Vector3(-0.5, -0.5)
  let pointq2 = new THREE.Vector3(0.5, -0.5)
  let pointq3 = new THREE.Vector3(0.5, 0.5)
  let pointq4 = new THREE.Vector3(-0.5, 0.5)

  rsgeo.moveTo(point1.x, point1.y)
  rsgeo.lineTo(point2.x, point2.y)
  rsgeo.lineTo(point3.x, point3.y)
  rsgeo.lineTo(point1.x, point1.y)

  let lineGeo = new THREE.Geometry()
  lineGeo.vertices.push(point1, point2, point3, point1)
  let shapeGeo = new THREE.ShapeGeometry(rsgeo)
  let squareShapeGeo = new THREE.Geometry()
  squareShapeGeo.vertices.push(pointq1, pointq2, pointq3, pointq4, pointq1)
  let circle = new THREE.CircleGeometry(circleSize, 12)
  //circle.vertices.shift();
  let circleLine = new THREE.EllipseCurve(0, 0, circleSize, circleSize, 0, 2.0 * Math.PI, false)
  let circlePath = new THREE.CurvePath()
  circlePath.add(circleLine)
  let circleGeometry = circlePath.createPointsGeometry(20)

  this.ScalerTop = new THREE.Line(lineGeo, rslmat)
  this.ScalerBottom = new THREE.Line(lineGeo, rslmat)
  this.ScalerLeft = new THREE.Line(lineGeo, rslmat)
  this.ScalerRight = new THREE.Line(lineGeo, rslmat)
  this.square = new THREE.Line(squareShapeGeo, rslmat)
  this.mover = new THREE.Line(circleGeometry, rslmat)

  this.ScalerTopFace = new THREE.Mesh(shapeGeo, rsmat)
  this.ScalerBottomFace = new THREE.Mesh(shapeGeo, rsmat)
  this.ScalerLeftFace = new THREE.Mesh(shapeGeo, rsmat)
  this.ScalerRightFace = new THREE.Mesh(shapeGeo, rsmat)
  this.moverFace = new THREE.Mesh(circle, rsmat)

  this.ScalerTopFace.visible = false
  this.ScalerBottomFace.visible = false
  this.ScalerLeftFace.visible = false
  this.ScalerRightFace.visible = false
  this.ScalerRightFace.visible = false
  this.moverFace.visible = false
  this.linkedObject = null

  this.ScalerTop.add(this.ScalerTopFace)
  this.ScalerBottom.add(this.ScalerBottomFace)
  this.ScalerLeft.add(this.ScalerLeftFace)
  this.ScalerRight.add(this.ScalerRightFace)
  this.mover.add(this.moverFace)

  this.ScalerTop.applyMatrix4(this.planeRotation)
  this.ScalerBottom.applyMatrix4(this.planeRotation)
  this.ScalerBottom.rotateOnAxis(new THREE.Vector3(0, 0, 1), Math.PI)
  this.ScalerLeft.applyMatrix4(this.planeRotation)
  this.ScalerRight.applyMatrix4(this.planeRotation)
  this.ScalerLeft.rotateOnAxis(new THREE.Vector3(0, 0, 1), Math.PI / 2)
  this.ScalerRight.rotateOnAxis(new THREE.Vector3(0, 0, 1), -Math.PI / 2)
  this.mover.applyMatrix4(this.planeRotation)
  this.square.applyMatrix4(this.planeRotation)

  this.add(this.square)
  this.add(this.ScalerTop)
  this.add(this.ScalerBottom)
  this.add(this.ScalerLeft)
  this.add(this.ScalerRight)
  this.add(this.mover)

  this.detach = function (parent, scene) {

    if (parent) {
      this.applyMatrix4(parent.matrixWorld)
      parent.remove(this)
      scene.add(this)
    }

  }

  this.appendToFN = function (fn3d) {

    if ((fn3d instanceof FN3DExtended) && (fn3d.parent !== null)) {

      this.ScalerTop.position.set(this.ScalerTop.position.x, 0.015, -fn3d.d / 2)
      this.ScalerBottom.position.set(this.ScalerTop.position.x, 0.015, fn3d.d / 2)
      this.ScalerLeft.position.set(-fn3d.w / 2, 0.015, 0)
      this.ScalerRight.position.set(fn3d.w / 2, 0.015, 0)
      this.mover.position.set(0, 0.015, 0)
      this.square.position.set(0, 0.015, 0)
      this.square.scale.set(fn3d.w, fn3d.d, 0.01)
      this.linkedObject = fn3d

      let angle = fn3d.getAngle(fn3d.rotationAtParent + fn3d.rotationSelf)
      this.rotation.set(0, 0, 0)
      this.position.set(fn3d.position.x, fn3d.position.y, fn3d.position.z)
      this.rotateY(angle)

      fn3d.parent.add(this)
      this.visible = true
    }
  }

  this.hideElements = function () {
    this.ScalerTopFace.visible = false
    this.ScalerBottomFace.visible = false
    this.ScalerLeftFace.visible = false
    this.ScalerRightFace.visible = false
    this.ScalerRightFace.visible = false
    this.moverFace.visible = false

  }

  this.getNearest = function (point) {

    let vector = new THREE.Vector3()
    vector.setFromMatrixPosition(this.mover.matrixWorld)
    let distance = vector.distanceTo(point) <= circleSize ? circleSize : Number.MAX_VALUE
    let out = this.mover

    vector.setFromMatrixPosition(this.ScalerTop.matrixWorld)
    if (vector.distanceTo(point) < distance) {
      distance = vector.distanceTo(point)
      out = this.ScalerTop
    }

    vector.setFromMatrixPosition(this.ScalerBottom.matrixWorld)
    if (vector.distanceTo(point) < distance) {
      distance = vector.distanceTo(point)
      out = this.ScalerBottom
    }

    vector.setFromMatrixPosition(this.ScalerLeft.matrixWorld)
    if (vector.distanceTo(point) < distance) {
      distance = vector.distanceTo(point)
      out = this.ScalerLeft
    }

    vector.setFromMatrixPosition(this.ScalerRight.matrixWorld)
    if (vector.distanceTo(point) < distance) {
      distance = vector.distanceTo(point)
      out = this.ScalerRight
    }

    return out
  }

  this.showElement = function (element) {

    for (let i = 0; i < this.children.length; i++) {
      if (this.children[i] === element) {
        this.children[i].children[0].visible = true
      }
    }
  }

}

Gizmo.prototype = Object.create(THREE.Mesh.prototype)
Gizmo.prototype.constructor = Gizmo

export { Gizmo }

function PlanWall () {
  THREE.Group.apply(this, arguments)
  this.any = true

  this.create = function (width, door) {
    let wallLengthBeforeDoor = 0.6
    let doorSize = 0.8

    let wallMaterial = new THREE.MeshBasicMaterial({ color: color.black })
    let wall = new THREE.Mesh(new THREE.BoxBufferGeometry(1, 1, 1), wallMaterial)
    let offset = new THREE.Matrix4().makeTranslation(-0.5, 0.5, -0.5)
    wall.geometry.applyMatrix4(offset)
    wall.updateMatrix()

    let thick = 0.05

    this.wall00 = wall.clone()
    this.wall01 = wall.clone()
    this.wall02 = wall.clone()

    this.wall00.scale.set(wallLengthBeforeDoor, 0.04, thick)
    this.wall00.position.set(0, 0.08, thick / 2)
    this.wall01.scale.set(doorSize, 0.04, thick)
    this.wall01.position.set(-wallLengthBeforeDoor, 0.08, thick / 2)
    this.wall02.scale.set(width - wallLengthBeforeDoor - doorSize, 0.04, thick)
    this.wall02.position.set(0 - wallLengthBeforeDoor - doorSize, 0.08, thick / 2)
    this.add(this.wall00)
    if (!door) this.add(this.wall01)
    this.add(this.wall02)
  }

}

PlanWall.prototype = Object.create(THREE.Group.prototype)
PlanWall.prototype.constructor = PlanWall

export { PlanWall }

function WallSelector () {
  THREE.Mesh.apply(this, arguments)

  this.showSelector = function () {
    this.children[0].material.opacity = 1
  }

  this.hideSelector = function () {
    this.children[0].material.opacity = 0
  }

}

WallSelector.prototype = Object.create(THREE.Mesh.prototype)
WallSelector.prototype.constructor = WallSelector

export { WallSelector }

function Wall (position, material) {
  THREE.Group.apply(this, arguments)

  const wsmat = new THREE.MeshBasicMaterial({ 'color': color.gizmo, 'transparent': true, 'opacity': 0 })
  const wsmatLine = new THREE.LineBasicMaterial({ 'color': color.gizmo, 'transparent': true, 'opacity': 0 })
  this.waymat = new THREE.MeshBasicMaterial({ 'color': color.way, 'transparent': false, 'opacity': 1, depthWrite: false })
  this.waymatLight = new THREE.MeshBasicMaterial({ 'color': color.way, 'transparent': true, 'opacity': .5, depthWrite: false })
  this.clearmat = material.clone()

  const wallmat = new THREE.MeshBasicMaterial({ 'color': color.black, 'transparent': true, 'opacity': 1 })

  const wsgeo = new THREE.BoxBufferGeometry(1, 1, 1)
  const wsgeoLine = new THREE.Geometry()
  wsgeoLine.vertices.push(
    new THREE.Vector3(-0.5, .5, -0.5),
    new THREE.Vector3(0.5, .5, -0.5),
    new THREE.Vector3(0.5, .5, 0.5),
    new THREE.Vector3(-0.5, .5, 0.5),
    new THREE.Vector3(-0.5, .5, -0.5),
  )

  this.selectorWidth = 0.15
  this.width = 0.15
  this.selector = new WallSelector(wsgeo, wsmat.clone())
  this.selector.add(new THREE.Line(wsgeoLine, wsmatLine))

  this.wallPosition = position
  this.wayWidth = 0.4
  this.wallWidth = 0.05
  this.wallLengthBeforeDoor = 0.6
  this.doorSize = 0.8

  //build distance / hallway Geometry
  this.way = new THREE.Mesh(wsgeo, this.waymat)

  this.add(this.way)

  this.wallGeo = new THREE.Group()
  this.add(this.wallGeo)
  this.add(this.selector)
  this.cornerWall = new THREE.Mesh(wsgeo, wallmat)
  this.wallBegin = new THREE.Mesh(wsgeo, wallmat)
  this.wallWindow = new THREE.Mesh(wsgeo, wallmat.clone())
  this.wallEnd = new THREE.Mesh(wsgeo, wallmat)

  this.wallGeo.add(this.cornerWall)
  this.wallGeo.add(this.wallBegin)
  this.wallGeo.add(this.wallWindow)
  this.wallGeo.add(this.wallEnd)

  this.cornerWall.scale.set(this.wallWidth, 0.02, this.wallWidth)
  this.cornerWall.position.set(-this.wallWidth / 2, 0, -this.wallWidth / 2)
  this.cornerWall.visible = false

  this.wallBegin.scale.set(this.wallLengthBeforeDoor, 0.02, this.wallWidth)
  this.wallBegin.position.set(this.wallLengthBeforeDoor / 2, 0, -this.wallWidth / 2)
  this.wallBegin.visible = false

  this.wallWindow.scale.set(this.doorSize, 0.02, this.wallWidth)
  this.wallWindow.position.set(this.wallLengthBeforeDoor + this.doorSize / 2, 0, -this.wallWidth / 2)
  this.wallWindow.visible = false

  this.wallEnd.scale.set(1, 0.02, this.wallWidth)
  this.wallEnd.position.set(this.wallLengthBeforeDoor + +this.doorSize + 0.5, 0, -this.wallWidth / 2)
  this.wallEnd.visible = false
  this.way.visible = false

  this.setCodes = function (wallCode, sideCode) {

    let wallCodes = wallCode.split('')
    let sideCodes = sideCode.split('')

    if ((wallCodes[this.wallPosition] !== '-') && (wallCodes[this.wallPosition] !== 0)) {
      this.wallBegin.visible = true
      this.wallWindow.visible = true
      this.cornerWall.visible = false
      this.wallEnd.visible = true
      let prev = this.wallPosition - 1
      if (prev < 0) prev = 3
      if ((wallCodes[prev] !== '-') && (wallCodes[prev] !== 0)) this.cornerWall.visible = true
      if (wallCodes[this.wallPosition] === 'd') this.wallWindow.visible = false
    } else {
      this.wallBegin.visible = false
      this.wallWindow.visible = false
      this.wallEnd.visible = false
      this.cornerWall.visible = false
    }

    if (sideCodes[this.wallPosition] === 'h') {
      this.way.visible = true
      this.way.material = this.waymatLight
      if (this.wallPosition === 0) this.way.material = this.waymat
    } else if (sideCodes[this.wallPosition] === 'c') {
      this.way.visible = true
      this.way.material = this.clearmat
    } else {
      this.way.visible = false
    }
  }

  this.buildDistance = function (x, y) {
    let rsgeo = new THREE.Shape()
    let point1 = new THREE.Vector3(-x, -y)
    let point2 = new THREE.Vector3(x, -y)
    let point3 = new THREE.Vector3(x, y)
    let point4 = new THREE.Vector3(-x, y)

    rsgeo.moveTo(point1.x, point1.y)
    rsgeo.lineTo(point2.x, point2.y)
    rsgeo.lineTo(point3.x, point3.y)
    rsgeo.lineTo(point4.x, point4.y)
    rsgeo.lineTo(point1.x, point1.y)

    let shapeGeo = new THREE.ShapeGeometry(rsgeo)
    return shapeGeo
  }

  this.setSize = function (room) {
    this.minX = -room.scale.x / 2
    this.minY = -room.scale.y / 2
    this.maxX = room.scale.x / 2
    this.maxY = room.scale.y / 2

    switch (this.wallPosition) {
      case 2:
        this.selector.position.set(0, 0, this.minY - this.selectorWidth)
        this.selector.scale.set(this.maxX * 2, 0.03, this.selectorWidth * 2)

        this.way.geometry = this.buildDistance(this.maxX, this.wayWidth)
        this.way.rotation.set(-Math.PI / 2, 0, 0)
        this.way.position.set(0, 0, this.minY - this.wayWidth)

        this.wallGeo.position.set(this.minX, 0, this.minY)
        this.wallEnd.scale.x = this.maxX * 2 - this.wallLengthBeforeDoor - this.doorSize
        this.wallEnd.position.x = this.wallLengthBeforeDoor + this.doorSize + this.wallEnd.scale.x / 2
        break
      case 3:
        this.selector.position.set(this.maxX + this.selectorWidth, 0, 0)
        this.selector.scale.set(this.selectorWidth * 2, 0.03, this.maxY * 2)

        this.way.geometry = this.buildDistance(this.wayWidth, this.maxY)
        this.way.rotation.set(-Math.PI / 2, 0, 0)
        this.way.position.set(this.maxX + this.wayWidth, 0, 0)

        this.wallGeo.rotation.set(0, -Math.PI / 2, 0)

        this.wallGeo.position.set(this.maxX, 0, this.minY)
        this.wallEnd.scale.x = this.maxY * 2 - this.wallLengthBeforeDoor - this.doorSize
        this.wallEnd.position.x = this.wallLengthBeforeDoor + this.doorSize + this.wallEnd.scale.x / 2
        break
      case 0:
      default:
        this.selector.position.set(0, 0, this.maxY + this.selectorWidth)
        this.selector.scale.set(this.maxX * 2, 0.03, this.selectorWidth * 2)

        this.way.position.set(0, 0, this.maxY + this.wayWidth)
        this.way.scale.set(100, 0.01, this.wayWidth * 2)
        this.wallGeo.rotation.set(0, -Math.PI, 0)
        this.wallGeo.position.set(this.maxX, 0, this.maxY)
        this.wallEnd.scale.x = this.maxX * 2 - this.wallLengthBeforeDoor - this.doorSize
        this.wallEnd.position.x = this.wallLengthBeforeDoor + this.doorSize + this.wallEnd.scale.x / 2
        this.way.visible = true
        break
      case 1:
        this.selector.position.set(this.minX - this.selectorWidth, 0, 0)
        this.selector.scale.set(this.selectorWidth * 2, 0.03, this.maxY * 2)

        this.way.geometry = this.buildDistance(this.wayWidth, this.maxY)
        this.way.rotation.set(-Math.PI / 2, 0, 0)
        this.way.position.set(this.minX - this.wayWidth, 0, 0)

        this.wallGeo.rotation.set(0, Math.PI / 2, 0)
        this.wallGeo.position.set(this.minX, 0, this.maxY)
        this.wallEnd.scale.x = this.maxY * 2 - this.wallLengthBeforeDoor - this.doorSize
        this.wallEnd.position.x = this.wallLengthBeforeDoor + this.doorSize + this.wallEnd.scale.x / 2
        break
    }

  }

  this.update = function (wallcode, wc) {

  }

}

Wall.prototype = Object.create(THREE.Group.prototype)
Wall.prototype.constructor = Wall

export { Wall }

function Room () {
  THREE.Mesh.apply(this, arguments)

  this.stdWidth = 1
  this.stdDepth = 1
  this.actualWidth = 1
  this.actualDepth = 1
  this.isClone = false
  this.patternData = null
  this.type = null
  this.contentDirectionX = 1
  this.contentDirectionY = 1
  this.static = false
  this.hasOffset = true
  this.selectorPlane = null
  this.tempFurnitureList = []
  this.workplaces = 0

  this.drawWalls = function () {
    let wallWidth = 0.05
    this.wallGeo = new THREE.BoxBufferGeometry(1, 1, 1)
    this.wallMat = new THREE.MeshBasicMaterial({ color: color.black })
    this.wallX = new THREE.Mesh(this.wallGeo, this.wallMat)
    this.wallZ = new THREE.Mesh(this.wallGeo, this.wallMat)

    if (this.patternData.hasHorizontalWall) {
      this.wallX.scale.set(this.actualWidth, 0.02, wallWidth)
      this.wallX.position.z = -this.actualDepth / 2
      this.wallX.position.x = this.actualWidth / 2
      this.add(this.wallX)
    }
    if (this.patternData.hasVerticalWall) {

      this.wallZ.scale.set(wallWidth, 0.02, this.actualDepth)
      this.wallZ.position.x = this.actualWidth / 2
      this.wallZ.position.z = -this.actualDepth / 2
      this.add(this.wallZ)
    }

  }

  this.createLine = function () {

    let positions = []
    let isLinePresent = false
    let line = null
    for (let i = 0; i < this.children.length; i++) {
      if (this.children[i] instanceof THREE.Line) {
        isLinePresent = true
        line = this.children[i]
      }
    }

    positions.push(
      0, 0, 0,
      this.actualWidth, 0, 0,
      this.actualWidth, 0, -this.actualDepth,
      0, 0, -this.actualDepth,
      0, 0, 0,
    )
    let geometry = new THREE.BufferGeometry()
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3))
    geometry.computeBoundingSphere()

    if (isLinePresent) {
      line.geometry = geometry
    } else {
      let line = new THREE.Line(geometry, new THREE.MeshBasicMaterial({ color: color.black }))
      if (!this.isClone) line.position.y = 0.0025
      this.add(line)
    }

  }

  this.mirrorY = function () {
    this.scale.z = -1
    this.position.z -= this.actualDepth

  }

  this.setStdSize = function (x, y) {
    this.stdWidth = x
    this.stdDepth = y
  }

  this.updateSize = function () {
    this.geometry = new THREE.BoxBufferGeometry(this.actualWidth, 0.001, this.actualDepth)
    this.geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(this.actualWidth / 2, 0, -this.actualDepth / 2))
  }

  this.forceSize = function (x, y) {
    if (x > this.actualWidth) this.actualWidth = x
    if (y > this.actualDepth) this.actualDepth = y

    this.geometry = new THREE.BoxBufferGeometry(this.actualWidth, 0.001, this.actualDepth)
    this.geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(this.actualWidth / 2, 0, -this.actualDepth / 2))
  }

  this.duplicate = function () {
    let clone = this.clone()

    for (let k = 0; k < clone.children.length; k++) {
      clone.children[k].material = new THREE.LineBasicMaterial({ color: color.gray })
      clone.children[k].position.y = 0
    }

    clone.static = true
    clone.actualDepth = this.actualDepth
    clone.actualWidth = this.actualWidth
    clone.patternData = this.patternData
    clone.position.y = 0.001
    clone.type = this.type
    clone.isClone = true
    clone.material = new THREE.MeshBasicMaterial({ color: color.white })
    clone.material.transparent = true
    clone.material.opacity = 0.2
    return clone
  }

  this.createSelectorPlaneWithAnchors = function (target, anchors, anchorLines, elementType) {
    let m = new THREE.MeshBasicMaterial({ 'color': '#ff007f', 'visible': false })
    let g = new THREE.BoxBufferGeometry(this.actualWidth, 0.01, this.actualDepth)
    let mesh = new Selector(g, m)
    mesh.position.set(this.position.x + this.actualWidth / 2, this.position.y, this.position.z - this.actualDepth / 2)

    this.selectorPlane = mesh
    this.selectorPlane.active = false
    this.selectorPlane.linkedObject = this

    this.selectorPlane.children = []
    this.selectorPlane.createAnchors(this.actualWidth, this.actualDepth, elementType)

    target.add(this.selectorPlane)
    let ancs = this.selectorPlane.getAnchors()
    for (let i = 0; i < ancs.length; i++) {
      ancs[i].setRoot()
      anchors.push(ancs[i])
    }
    anchorLines.push(this.selectorPlane.topLine)
    anchorLines.push(this.selectorPlane.bottomLine)
    anchorLines.push(this.selectorPlane.rightLine)
    anchorLines.push(this.selectorPlane.leftLine)
  }

  this.countWorkplaces = function () {
    this.workplaces = 0
    let parent = this
    this.findWorkplacesInGroup(this.children, parent)
    return this.workplaces
  }

  this.findWorkplacesInGroup = function (group, parent) {
    group.forEach(function (fn) {
      if (fn instanceof FN3DExtended) {
        //parent.workplaces += fn.saved.nseatsWork
        if (fn.saved.category === 'chair') parent.workplaces++
        parent.findWorkplacesInGroup(fn.children, parent)
      }
    })
  }
}

Room.prototype = Object.create(THREE.Mesh.prototype)
Room.prototype.constructor = Room

export { Room }