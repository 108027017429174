import { floorPlanActionTypes } from '../../floorplan/constants'
import fileDownload from 'js-file-download'

function extractFileNameFromHeaders (headers, defaultFileName = 'codesinger') {
  let header = headers['content-disposition']

  if (header) {
    return /attachment; filename="(.*)"/g.exec(header)[1]
  }

  return defaultFileName
}

function downloadFile (action) {
  let fileName = extractFileNameFromHeaders(action.payload.headers)

  fileDownload(action.payload.data, fileName)
}

function downloader (state, action) {

  switch (action.type) {

    case floorPlanActionTypes.FETCH_FILE_PENDING:
      return { ...state }

    case floorPlanActionTypes.FETCH_FILE_FULFILLED:

      downloadFile(action)

      return {
        ...state,
      }

    case floorPlanActionTypes.FETCH_FILE_REJECTED:
      return { ...state, error: action.payload }

    default:
      return { ...state }
  }

}

export default downloader