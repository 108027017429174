import * as React from 'react'

const SvgDrawInfrastructureActive = props =>
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#42495b"/>
        <stop offset="100%" stopColor="#42495b"/>
      </linearGradient>
      <linearGradient
        id="prefix__b"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#42495b"/>
        <stop offset="100%" stopColor="#42495b"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect width={32} height={32} fill="#EBEEF0" opacity={0.08} rx={2}/>
      <path
        fill="#EBEEF0"
        stroke="#22293b"
        strokeLinejoin="round"
        d="M15.278 10.126c.642-.152 1.971-.039 2.4.23l.118.078.129.102.141.127.077.075.165.17c.03.031.06.064.09.098l.195.22.218.257.242.297.27.339.146.186c.43.543.928 1.018 1.516 1.403l1.041.679c.475.317.611.928.294 1.403a1 1 0 01-1.403.294l-1.04-.679a8.285 8.285 0 01-1.924-1.742l-.472 4.144.248.246c.72.73 1.384 1.513 1.986 2.344.28.375.505 1.139.691 1.99l.108.518.1.525.296 1.68.037.196a1.177 1.177 0 01-2.314.405l-.066-.358-.262-1.513-.128-.672-.09-.422c-.125-.541-.26-.985-.406-1.188a15.086 15.086 0 00-2.064-2.258c-.292 1.408-.568 2.49-.829 3.244-.146.445-.643 1.066-1.233 1.707l-.363.386-.373.382-1.206 1.207-.14.143a1.176 1.176 0 01-1.659-1.663l1.345-1.343.322-.328.305-.322.279-.308c.305-.35.56-.808.632-1.015.327-.986.526-1.85.645-2.88l.086-1.097.339-4.158a6.828 6.828 0 00-1.335 1.652l-.498.905c-.181.34-.543.521-.883.521-.158 0-.34-.045-.475-.113-.498-.25-.679-.86-.43-1.358l.498-.905a8.4 8.4 0 013.326-3.326 4.965 4.965 0 011.308-.505z"
      />
      <circle
        cx={16.67}
        cy={7.25}
        r={2.25}
        fill="#EBEEF0"
        stroke="#22293b"
        strokeLinejoin="round"
      />
    </g>
  </svg>

export default SvgDrawInfrastructureActive