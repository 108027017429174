import { floorPlanActionTypes } from '../constants'
import { getSelectedOrganisationalUnitId } from '../../common/redux/appstate/appstate-reducer'
import { getConnectableZoneMarker } from './connectables'

let initialState = {
  connections: [],
}

function connectionsReducer (state = initialState, action) {

  switch (action.type) {

    case floorPlanActionTypes.FETCH_CONNECTIONS_FULFILLED:
      return {
        ...state,
        connections: action.payload.data,
      }

    case floorPlanActionTypes.CREATE_CONNECTION_FULFILLED:
      return {
        ...state,
        connections: [...state.connections, action.payload.data],
      }

    default:
      return { ...state }
  }

}

function enhanceConnectableZoneMarkerInfo (state, connectable) {
  return { ...connectable, ...getConnectableZoneMarker(state, connectable.id) }
}

export function getConnectionsOfDepartment (state) {

  const departmentId = getSelectedOrganisationalUnitId(state)

  if (!departmentId) {
    return []
  }

  return state.connections.connections
    .filter(connection => connection.connectableIdA === departmentId || connection.connectableIdB === departmentId)
    .map(connection => {
      if (connection.connectableA.zoneMarker) {
        return ({ ...connection, connectableA: enhanceConnectableZoneMarkerInfo(state, connection.connectableA) })
      }

      return connection
    })
    .map(connection => {
      if (connection.connectableB.zoneMarker) {
        return ({ ...connection, connectableB: enhanceConnectableZoneMarkerInfo(state, connection.connectableB) })
      }

      return connection
    })
}

export default connectionsReducer



