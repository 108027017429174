import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { thunk } from 'redux-thunk'
import promise from 'redux-promise-middleware'
import license from './common/reducers'
import commonReducers from './common/reducers'
import floorPlanReducers from './floorplan/reducers'
import buildingsReducer from './buildings/reducers'
import drawingToolReducer from './DrawingTool/reducer'
import applicationBuilderReducers from './applicationbuilder/reducers'
import patternBuilderReducers from './patternbuilder/reducers'
import settingsReducers from './settings/redux'
import floorPlanAnalyticsReducer from './buildings/reducers'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

const createRootReducer = () => combineReducers({
  ...commonReducers,
  ...floorPlanReducers,
  ...applicationBuilderReducers,
  ...patternBuilderReducers,
  ...buildingsReducer,
  ...drawingToolReducer,
  ...settingsReducers,
  ...license,
  ...floorPlanAnalyticsReducer,
})
const middleware = applyMiddleware(thunk, promise);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const enhancer = composeEnhancers(middleware)

const store = createStore(createRootReducer(history), enhancer)

export default store