import React from 'react'
import { IconWrapper } from './IconWrapper'

import './Button.scss'

export const buttonVariants = {
  orangeFilledRound: 'orange-filled-round',
  greenFilled: 'green-filled',
}

export default function Button ({ children, disabled, icon, secondIcon, variant = 'outlined', className, onClick, onDisabledClick, iconColor, ...rest }) {

  const styleClass = 'button-component ' + variant + '-button' +
    (icon ? ' with-icon' : '') +
    (className ? ' ' + className : '') +
    (disabled ? ' disabled' : '')

  const isDisabled = disabled && !onDisabledClick
  onClick = disabled && onDisabledClick ? onDisabledClick : onClick

  const inverted = variant === 'contained' ||
    variant === 'inverted' ||
    variant === 'filled' ||
    variant === buttonVariants.orangeFilledRound ||
    variant === buttonVariants.greenFilled

  return (
    <button className={styleClass} disabled={isDisabled} onClick={onClick} {...rest}>
      {icon ? <IconWrapper name={icon} inverted={inverted} disabled={disabled} color={iconColor}/> : null}
      <div className={'button-inner' + (secondIcon ? ' right-icon' : '')}>
        <span>{children}</span>
      </div>
      {secondIcon ? <IconWrapper name={secondIcon} inverted={inverted} disabled={disabled} color={iconColor}/> : null}
    </button>
  )
}