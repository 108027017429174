import * as React from 'react'
const SvgOuterWallsInactive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6" y="6" width="20" height="20" stroke="#00293B"/>
    <path d="M6 11H26" stroke="#00293B" stroke-linejoin="round"/>
    <path d="M6 16H26" stroke="#00293B" stroke-linejoin="round"/>
    <path d="M6 21H26" stroke="#00293B" stroke-linejoin="round"/>
    <path d="M16 6V11" stroke="#00293B" stroke-linejoin="round"/>
    <path d="M11.5 11V16" stroke="#00293B" stroke-linejoin="round"/>
    <path d="M11.5 21V26" stroke="#00293B" stroke-linejoin="round"/>
    <path d="M20.5 11V16" stroke="#00293B" stroke-linejoin="round"/>
    <path d="M20.5 21V26" stroke="#00293B" stroke-linejoin="round"/>
    <path d="M16 16V21" stroke="#00293B" stroke-linejoin="round"/>
  </svg>
)

export default SvgOuterWallsInactive