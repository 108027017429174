import * as React from 'react'

const SvgOpenDemoIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#00293B"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M6 16V8a1 1 0 01.883-.993L7 7h1a1 1 0 01.993.883L9 8h7a1 1 0 011 1v.688"/>
      <path d="M16.09 17H7.018a1 1 0 01-.92-1.394l2.143-5c.158-.368.52-.606.92-.606h9.073a1 1 0 01.92 1.394l-2.143 5c-.158.368-.52.606-.92.606z"/>
      <path
        strokeDasharray={5}
        d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11z"
      />
    </g>
  </svg>
)

export default SvgOpenDemoIcon
