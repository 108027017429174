import { Group } from 'three'
import { Vertex } from './VertexFactory'
import { geometryTypes } from '../config/GeometryTypes'
import { insertChildIntoParent } from '../helper/GroupHelper'

export class VertexGroup extends Group {

  constructor (parent) {
    super()

    this.name = 'vertexGroup'
    this.currentScale = 1
    this.geometryType = geometryTypes.vertexGroup

    this.position.z = 0.03

    parent.add(this)
  }

  setEdgeGroup (edgeGroup) {
    this.edgeGroup = edgeGroup
  }

  addVertex (position) {
    const vertex = new Vertex(this.currentScale, this.getRepresentationType())
    vertex.setPosition(position)
    vertex.setActive(true)
    this.add(vertex)

    return vertex
  }

  addVertexAtIndex (position, index) {
    const vertex = new Vertex(this.currentScale, this.getRepresentationType())
    vertex.setPosition(position)
    insertChildIntoParent(this, vertex, index)
    return vertex
  }

  updateVertex (vertex, position) {
    vertex.position.set(position.x, position.y)

    vertex.getEdges()
      .forEach(edge => {
        this.edgeGroup.updateEdge(edge)
      })
  }

  moveDeltaAllVertices (movement) {
    this.children.forEach(vertex => vertex.moveDelta(movement))
    if (this.edgeGroup) {
      this.edgeGroup.updateAllEdges()
      return true
    }

    return false
  }

  removeVertex (vertex) {
    vertex.getEdges()
      .forEach(edge =>
        this.edgeGroup.removeEdge(edge),
      )

    this.remove(vertex)
  }

  findVertexByUuid (uuid) {
    return this.children.find(child => child.uuid === uuid)
  }

  findVerticesByUuids (uuids) {
    const vertices = []
    uuids.forEach(uuid => vertices.push(this.children.find(vertex => vertex.uuid === uuid)))
    return vertices
  }

  getVertexByIndex (index) {
    return this.children.length ? this.children[(index + this.children.length) % this.children.length] : null
  }

  getPositionFromVertex (uuid) {
    const vertex = this.findVertexByUuid(uuid)
    return vertex ? vertex.position : vertex
  }

  getPositions () {
    return this.getVertices()
      .map(vertex => vertex.position)
  }

  getRepresentationType () {
    if (this.parent && this.parent.representationType)
      return this.parent.representationType
  }

  updateScale (scale) {
    this.children.forEach(vertex => vertex.updateScale(scale))

    this.currentScale = scale
  }

  setActive (active) {
    this.children.forEach(vertex => vertex.setActive(active))
  }

  setVertexOrder (vertex, index) {
    index = (index + this.children.length) % this.children.length
    index = index > this.children.length ? this.children.length - 1 : index
    this.remove(vertex)
    const children = this.children

    this.children = []

    children.splice(index, 0, vertex)

    children.forEach(child => this.add(child))
  }

  getCount () {
    return this.children.length
  }

  getVertices () {
    const multipleEdgesFromVertex = this.children.filter(vertex => vertex.getEdges().length > 2)

    // Return unordered
    if (multipleEdgesFromVertex.length || !this.edgeGroup || !this.edgeGroup.children.length) {
      return this.children
    }

    // Order vertices after the connected edges
    const vertices = []
    let startVertex = this.children.find(vertex => vertex.getEdges().length === 1)
    let startEdge = startVertex ? startVertex.getEdges()[0] : this.edgeGroup.children[0]
    const startVertices = startEdge.getVertices()
    vertices.push(startVertices[0])
    vertices.push(startVertices[1])
    let currentVertex = startVertices[1]
    let breakWhile = false

    while (vertices.length !== this.children.length && !breakWhile) {
      const nextEdge = this.edgeGroup.children.find(edge => edge.getVertices()
          .includes(currentVertex) &&
        ((!vertices.includes(edge.getVertices()[0]) && vertices.includes(edge.getVertices()[1])) ||
          (vertices.includes(edge.getVertices()[0]) && !vertices.includes(edge.getVertices()[1]))))

      if (nextEdge) {
        currentVertex = nextEdge.getVertices()
          .find(vertex => vertex !== currentVertex)
        vertices.push(currentVertex)
      } else {
        breakWhile = true
      }
    }

    return vertices
  }

  dispose () {
    this.children.forEach(vertex => vertex.dispose())
  }

  fromJSON (verticesJSON) {
    verticesJSON.forEach(json => {
      const vertex = new Vertex(this.currentScale, this.getRepresentationType())
      vertex.fromJSON(json)
      this.add(vertex)
    })
  }

  toJSON () {
    return this.children.map(vertex => vertex.toJSON())
  }
}