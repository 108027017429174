import * as React from 'react'

const DrawInnerWall = props =>
  <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <g fillRule="nonzero" fill="none">
      <g fill="#001D34">
        <path
          d="M15.25 10a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM21.25 11a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM21.25 16.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM18.25 20.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM10.75 20.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM10.75 12.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM15.25 15.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Z"/>
      </g>
      <rect stroke={props.color} x={6.5} y={7} width={19} height={18} rx={1}/>
    </g>
  </svg>
export default DrawInnerWall