import React from 'react'

const SvgMoveAxisIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M12.057 5a.5.5 0 01.492.41l.008.09v12.685a.5.5 0 01-.992.09l-.008-.09V5.5a.5.5 0 01.5-.5zm2.813 2.433l4.242 4.243-4.242 4.243V7.433zm-5.627 0v8.486L5 11.676l4.243-4.243z"
    />
  </svg>
)

export default SvgMoveAxisIcon
