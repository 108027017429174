import { iconTypes } from '../icons/IconFactory'

export function parseToTreeListNodes (units, assigned, shortVersion = false, iconButtonClicked = () => {}, level = -1, parentKey = null) {
  level++

  return units.map((unit, index) => {
    let secondLine = unit.type === 'SUPER_DEPARTMENT' ? getChildArea(unit.organisationalUnits, assigned) : unit.requirement.area
    secondLine += ' m²'
    secondLine = unit.type === 'TEAM' ? '' : secondLine

    return {
      parentKey: parentKey,
      key: unit.id,
      level: level,
      index: index,
      icon: getIconByType(unit.type),
      iconButtonPermanent: unit.locked,
      iconButton: unit.type === 'DEPARTMENT' ? unit.locked ? iconTypes.locked : iconTypes.unlocked : null,
      iconButtonClicked: unit.type === 'DEPARTMENT' ? () => iconButtonClicked() : false,
      label: shortVersion ? unit.fourLetterName : unit.name,
      labelValue: shortVersion ? secondLine : null,
      color: unit.type !== 'SUPER_DEPARTMENT' ? unit.color : { red: 0.0, green: 0.0, blue: 0.0, alpha: 0.0 },
      nodes: parseToTreeListNodes(unit.organisationalUnits, assigned, shortVersion, iconButtonClicked, level, unit.id),
    }
  })
}

function getChildArea (units, assign) {
  let sum = 0

  units.filter(unit => unit.assigned === assign)
    .forEach(unit => sum += unit.area)

  return sum.toFixed(2)
}

function getIconByType (unitType) {
  switch (unitType) {
    case 'SUPER_DEPARTMENT':
      return iconTypes.superDepartment
    case 'DEPARTMENT':
      return iconTypes.department
    case 'TEAM':
    default:
      return iconTypes.team
  }
}