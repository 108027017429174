import * as React from 'react'

const SvgDrawDoorInactive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32">
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#DA001C"/>
        <stop offset="100%" stopColor="#FF3C55"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="#ff5e0f" strokeLinejoin="round">
      <path d="M8 24.008V5.992A.994.994 0 019 5h14c.552 0 1 .455 1 .992v18.016a.995.995 0 01-1.01.992H19"/>
      <path d="M8 5.992c0-.548.405-.808.919-.574l9.162 4.164c.508.231.919.873.919 1.41v18.016c0 .548-.405.808-.919.574L8.92 25.418c-.51-.231-.92-.873-.92-1.41V5.992z"/>
      <path
        fill="url(#prefix__a)"
        strokeLinecap="round"
        d="M7 11.5l4 2"
        transform="translate(8 5)"
      />
    </g>
  </svg>

export default SvgDrawDoorInactive