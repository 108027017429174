import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, Form, Formik } from 'formik'

import { updateOrganisationalUnitRequirements } from '../../../actions/actions'

import AutoSave from '../../../../common/components/AutoSave'
import NumberInput from '../../../../common/components/NumberInput'
import MenuSection from '../../../../common/components/MenuSection'
import LockableField from '../../../../common/components/LockableField'
import NonLockableField from '../../../../common/components/NonLockableField'
import ToggleIcon from '../../../../common/components/ToggleIcon'
import DataField from './DataField'
import { getSelectedOrganisationalUnit } from '../../../reducers/organisationalUnits'
import { iconTypes } from '../../../../common/components/icons/IconFactory'

class DataFlyOut extends Component {

  isTeam (unit) {
    return unit.type === 'TEAM'
  }

  updateValues = (buildingId, floorId, unitId, values) => {
    let req = {}

    Object.keys(values)
      .forEach((prop) => {
      if (values[prop] !== values.unit.requirement[prop]) {
        req[prop] = values[prop]
      }
      })

    this.props.updateRequirement(values.unit.buildingId, values.unit.floorId, values.unit.id, req)
  }

  render () {

    let disabled = !this.props.unit.requirement

    return (
      <Formik initialValues={{ ...this.props.unit.requirement, unit: this.props.unit }} enableReinitialize>
        <Form>
          <AutoSave onSave={(values) => this.updateValues(values.unit.buildingId, values.unit.floorId, values.unit.id, values)}/>
          <MenuSection className={'data-flyout-menu-section'}>
            {this.isTeam(this.props.unit) ?
              <LockableField>
                <Field label={'Desk Sharing Ratio'}
                       name={'deskSharingRatio'}
                       component={NumberInput}
                       default={1}
                       disabled={disabled || this.props.unit.requirement.deskSharingRatioLocked}
                       digits={2}
                       step={0.1}/>
                <Field
                  name={'deskSharingRatioLocked'}
                  component={ToggleIcon}
                  activeIcon={iconTypes.locked}
                  disabledIcon={iconTypes.unlocked}/>
              </LockableField> :
              <DataField label={'Desk Sharing Ratio'} data={!disabled ? this.props.unit.requirement.deskSharingRatio : 0}/>
            }

            {this.isTeam(this.props.unit) ?
              !this.props.useEmployee ?
              <LockableField>
                <Field label={'Headcount'}
                       name={'numberOfEmployees'}
                       component={NumberInput}
                       digits={0}
                       step={1}
                       disabled={disabled || this.props.unit.requirement.numberOfEmployeesLocked}/>
                <Field
                  name={'numberOfEmployeesLocked'}
                  component={ToggleIcon}
                  activeIcon={iconTypes.locked}
                  disabledIcon={iconTypes.unlocked}/>
              </LockableField>
                : <LockableField>
                  <Field label={'Workplaces'}
                         name={'numberOfWorkplaces'}
                         component={NumberInput}
                         digits={0}
                         step={1}
                         disabled={disabled || this.props.unit.requirement.numberOfWorkplacesLocked}/>
                  <Field
                    name={'numberOfWorkplacesLocked'}
                    component={ToggleIcon}
                    activeIcon={iconTypes.locked}
                    disabledIcon={iconTypes.unlocked}/>
              </LockableField>
              : !this.props.useEmployee ?
                <DataField label={'Headcount'} data={!disabled ? this.props.unit.requirement.numberOfEmployees : 0}/> :
                <DataField label={'Workplaces'} data={!disabled ? this.props.unit.requirement.numberOfWorkplaces : 0}/>
            }


            {this.isTeam(this.props.unit) ?
              !this.props.useEmployee ?
              <NonLockableField>
                <Field label={'Workplaces'}
                       name={'numberOfWorkplaces'}
                       component={NumberInput}
                       digits={0}
                       step={1}
                       disabled={disabled || this.props.unit.requirement.numberOfWorkplacesLocked}/>
              </NonLockableField>
              :
              <NonLockableField>
                <Field label={'Headcount'}
                       name={'numberOfEmployees'}
                       component={NumberInput}
                       digits={0}
                       step={1}
                       disabled={disabled || this.props.unit.requirement.numberOfEmployeesLocked}/>
              </NonLockableField>
              : !this.props.useEmployee ?
                <DataField label={'Workplaces'} data={!disabled ? this.props.unit.requirement.numberOfWorkplaces : 0}/> :
                <DataField label={'Headcount'} data={!disabled ? this.props.unit.requirement.numberOfEmployees : 0}/>
            }

            {!this.isTeam(this.props.unit) ?
              <div className={'removable-block'}>
                {!this.props.useEmployee ?
                  <LockableField>
                    <Field label={'m2/Workplace'}
                           name={'areaForWorkplace'}
                           component={NumberInput}
                           digits={2}
                           step={0.1}
                           disabled={disabled || this.props.unit.requirement.areaForLocked}/>
                    <Field
                      name={'areaForLocked'}
                      component={ToggleIcon}
                      activeIcon={iconTypes.locked}
                      disabledIcon={iconTypes.unlocked}/>
                  </LockableField> :

                  <LockableField>
                    <Field label={'m2/Employee'}
                           name={'areaForEmployee'}
                           component={NumberInput}
                           digits={2}
                           step={0.1}
                           disabled={disabled || this.props.unit.requirement.areaForLocked}/>
                    <Field
                      name={'areaForLocked'}
                      component={ToggleIcon}
                      activeIcon={iconTypes.locked}
                      disabledIcon={iconTypes.unlocked}/>
                  </LockableField>
                }

                <LockableField>
                  <Field label={'Usable net area'}
                         name={'area'}
                         component={NumberInput}
                         digits={2}
                         step={0.1}
                         disabled={disabled || this.props.unit.requirement.areaLocked}/>
                  <Field
                    name={'areaLocked'}
                    component={ToggleIcon}
                    activeIcon={iconTypes.locked}
                    disabledIcon={iconTypes.unlocked}/>
                </LockableField>
              </div> : null}

          </MenuSection>
        </Form>
      </Formik>
    )
  }

}

let mapStateToProps = (state) => {

  return {
    unit: getSelectedOrganisationalUnit(state),
    useEmployee: state.settings.appSettings.employeeBasedCalculation,
  }
}

let mapDispatchToProps = {
  updateRequirement: updateOrganisationalUnitRequirements,
}

export default connect(mapStateToProps, mapDispatchToProps)(DataFlyOut)