import { FULFILLED, PENDING, REJECTED } from '../../globalConstants'
import { httpClient } from '../../httpClient'

const CREATE_PROJECT_ACTIVATION = 'CREATE_PROJECT_ACTIVATION'
const DELETE_PROJECT_ACTIVATION = 'DELETE_PROJECT_ACTIVATION'

export const projectActivationActionTypes = {
  CREATE_PROJECT_ACTIVATION: CREATE_PROJECT_ACTIVATION,
  CREATE_PROJECT_ACTIVATION_PENDING: CREATE_PROJECT_ACTIVATION + PENDING,
  CREATE_PROJECT_ACTIVATION_FULFILLED: CREATE_PROJECT_ACTIVATION + FULFILLED,
  CREATE_PROJECT_ACTIVATION_REJECTED: CREATE_PROJECT_ACTIVATION + REJECTED,

  DELETE_PROJECT_ACTIVATION: DELETE_PROJECT_ACTIVATION,
  DELETE_PROJECT_ACTIVATION_PENDING: DELETE_PROJECT_ACTIVATION + PENDING,
  DELETE_PROJECT_ACTIVATION_FULFILLED: DELETE_PROJECT_ACTIVATION + FULFILLED,
  DELETE_PROJECT_ACTIVATION_REJECTED: DELETE_PROJECT_ACTIVATION + REJECTED,
}

export function activateProject (projectId) {
  return {
    type: projectActivationActionTypes.CREATE_PROJECT_ACTIVATION,
    payload: httpClient.post('/api/project-activation', { projectId: projectId }),
  }
}

export function deactivateProject () {
  return {
    type: projectActivationActionTypes.CREATE_PROJECT_ACTIVATION,
    payload: httpClient.delete('/api/project-activation'),
  }
}