import React from 'react'
import './Inspector.scss'

export default function Inspector (props) {
  return (
    <div className={'inspector'}>
      {props.children}
    </div>
  )
}



