import * as React from 'react'

const SvgCreateNewIcon = props => {
  return (
    <svg className={props.className} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#00293B"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18 13.5V17a1 1 0 01-1 1H7a1 1 0 01-1-1V7a1 1 0 011-1h3.5"
        />
        <path
          fill="#00293B"
          d="M9.933 12.746l5.185-5.197 1.33 1.332-5.191 5.188H9.933v-1.323zm6.605-6.622l1.337 1.335-.765.764-1.334-1.332.762-.767zM9.466 15h1.983c.125 0 .243-.05.33-.137l7.084-7.075a.465.465 0 000-.658l-1.995-1.993a.47.47 0 00-.66 0l-7.071 7.087a.477.477 0 00-.137.33v1.98c0 .258.208.466.466.466z"
        />
      </g>
    </svg>
  )
}

export default SvgCreateNewIcon
