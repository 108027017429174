import React from 'react'
import RadioGroup from './RadioGroup'

import './RadioButtonGroup.scss'

function RadioButtonGroup ({ children, name, onChange, value, disabled, ...rest }) {

  let options = children.map((option, index) => {

    if (!React.isValidElement(option)) {
      return null
    }

    return React.cloneElement(option, {
      key: index,
      disabled: disabled,
    })

  })

  return (
    <div {...rest} className={'radio-input-button-group'}>

      <RadioGroup
        name={name}
        value={value}
        onChange={onChange}>

        {options}

      </RadioGroup>

    </div>
  )
}

export default RadioButtonGroup