import React, { Component } from 'react'
import Dialog, { DialogButtonGroup, DialogContent } from '../../../common/components/Dialog'
import { Field, Form, Formik } from 'formik'
import { connect } from 'react-redux'
import './RoomCategoryDialog.scss'
import SelectButton from '../../../common/components/SelectButton'
import Button from '../../../common/components/Button'
import { updateRoom } from '../../actions/actions'

class RoomCategoryDialog extends Component {

  mapCategories = (categories) => {

    return categories.map(category => ({ value: category.id, label: category.displayName }))

  }

  render () {

    let defaultValues = this.props.emptyRoom

    return (
      <Dialog onCancel={this.close}
              title={'Room Category'}
              className={'room-category-dialog'}
              innerClassName={'room-category-dialog-inner'}>
        <DialogContent>
          <Formik initialValues={defaultValues} onSubmit={(values) => this.confirm(values)}>
            <Form id={'room-category-form'}>

              <Field name={'category'} component={SelectButton} options={this.mapCategories(this.props.categories)}/>

            </Form>
          </Formik>
        </DialogContent>
        <DialogButtonGroup className={'dialog-buttons'}>
          <Button onClick={this.close}>Cancel</Button>
          <Button form={'room-category-form'} type={'submit'} variant={'contained'}>Confirm</Button>
        </DialogButtonGroup>
      </Dialog>
    )
  }

  close = () => {
    this.props.onClose()
  }

  confirm = (values) => {

    let room = {
      category: values.category,
      zoneIndex: values.zoneIndex,
    }

    if (values.category) {
      this.props.updateRoom(values.zoneIndex, room)
    }
    this.close()
  }

}

let mapStateToProps = (state) => {
  return {
    furnitureEnsembleCategories: state.furnitureEnsembles.furnitureEnsembleCategories,
    categories: state.furnitureEnsembles.furnitureEnsembleCategories,

  }
}

let mapDispatchToProps = {
  updateRoom: updateRoom,
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomCategoryDialog)