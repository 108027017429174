import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.scss'
import { loadConfig } from './common/config.service'
import { applyPolyfills, defineCustomElements } from 'haworth-service-bar/loader'
import App from './App'

(async () => {

  await loadConfig()

  const container = document.getElementById('root');
  const root = createRoot(container); // Erstellt eine Root.
  root.render(<App />);

  // include the stencil component library
  // applyPolyfills is needed for targeting Edge or IE11
  applyPolyfills()
    .then(() => {
      defineCustomElements(window)
    })

})()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
