import React from 'react'

const SvgAddIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z"/>
      <path
        fill={props.color}
        d="M12.467 12.467h5.89a.555.555 0 000-1.11h-5.89v-5.89a.555.555 0 00-1.11 0v5.89h-5.89a.555.555 0 100 1.11h5.89v5.89a.552.552 0 00.555.555.555.555 0 00.555-.555v-5.89"
      />
    </g>
  </svg>
)

export default SvgAddIcon
