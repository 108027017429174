import React, { Component } from 'react'
import { Manager, Popper, Reference } from 'react-popper'

import IconButton from './IconButton'

import './VerticalMenu.scss'
import { iconTypes } from './icons/IconFactory'

class VerticalMenu extends Component {

  constructor (props) {
    super(props)

    this.state = {
      showMenu: false,
    }
  }

  render () {

    const childrenWithProps = React.Children.map(this.props.children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { onClose: () => this.handleClose() })
      }
      return child
    })

    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <div className={'more-vertical-container'} ref={ref}>
              <IconButton className={'more-vertical-button'}
                          icon={iconTypes.moreVertical}
                          onClick={() => this.handleMenuButtonClick()}/>
            </div>
          )}
        </Reference>
        {this.state.showMenu &&
        <Popper placement={'bottom-end'}>
          {({ ref, style, placement }) => (
            <div className={'vertical-menu-popper'} ref={ref} style={{ ...style }} data-placement={placement}>
              {childrenWithProps}
            </div>
          )
          }
        </Popper>}
      </Manager>
    )
  }

  handleMenuButtonClick () {
    let newValue = !this.state.showMenu
    this.setMenuState(newValue)
  }

  handleClose () {
    this.setMenuState(false)
  }

  setMenuState (show) {
    this.setState({
      showMenu: show,
    })
  }
}

export default VerticalMenu