import { countryActionTypes } from './country-actions'

let initialState = {
  countries: [],
}

function countryReducer (state = initialState, action) {
  if (action.type === countryActionTypes.FETCH_COUNTRY_FULFILLED) {
    const countries = action.payload.data

    const hongKongIndex = countries.findIndex(country => country === countries.find(country => country.name === 'Hong Kong'))
    countries.splice(hongKongIndex, 1)

    const taiwanIndex = countries.findIndex(country => country === countries.find(country => country.name === 'Taiwan'))
    countries.splice(taiwanIndex, 1)

    return {
      ...state,
      countries: countries,
    }
  }
  return state
}

export default countryReducer