import * as React from "react"

const ReplacePatternIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g
          stroke="#001D34"
          strokeLinecap="round"
          transform="translate(4.5 4.5)"
        >
          <path d="M10.875 12h.675a.45.45 0 00.45-.45v-8.1a.45.45 0 00-.45-.45h-8.1a.45.45 0 00-.45.45v.675h0" />
          <rect
            width={9}
            height={9}
            y={6}
            rx={0.3}
            transform="matrix(1 0 0 -1 0 21)"
          />
          <path d="M13.875 9h.675a.45.45 0 00.45-.45V.45a.45.45 0 00-.45-.45h-8.1A.45.45 0 006 .45v.675h0" />
        </g>
        <circle cx={16} cy={16} r={6} fill="#FFF" />
        <g stroke="#00293B" strokeLinecap="round" strokeLinejoin="round">
          <path d="M18 20.75l2-2-2-2M16 18.75h4" />
        </g>
        <g stroke="#00293B" strokeLinecap="round">
          <path strokeLinejoin="round" d="M15 12.75l-2 2 2 2" />
          <path d="M17 14.75h-4" />
        </g>
      </g>
    </svg>
  )
}

export default ReplacePatternIcon