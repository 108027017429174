import React, { Component } from 'react'
import IconButton from './IconButton'

import './HoverIconButton.scss'

class HoverIconButton extends Component {

  constructor (props) {
    super(props)

    this.state = {
      isHovered: false,
    }
  }

  setIsHovered (isHovered) {
    this.setState({
      isHovered: isHovered,
    })
  }

  render () {
    const { onClicked, icon, iconButtonPermanent } = this.props

    return (
      <div className={'hover-iconButton-container'}>
        {(this.state.isHovered && onClicked) || iconButtonPermanent ?
          <IconButton className={'hover-iconButton'} onMouseLeave={() => this.setIsHovered(false)} icon={icon} onClick={onClicked}/> :
          <div className={'hover-iconButton'} onMouseEnter={() => this.setIsHovered(true)} onMouseLeave={() => this.setIsHovered(false)}/>}
      </div>
    )
  }

}

export default HoverIconButton