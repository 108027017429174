import React from 'react'

const SvgCloseIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z"/>
      <path
        fill={props.color}
        d="M11.912 12.697l4.165 4.165a.555.555 0 00.785-.785l-4.165-4.165 4.165-4.165a.555.555 0 00-.785-.785l-4.165 4.165-4.165-4.165a.555.555 0 10-.785.785l4.165 4.165-4.165 4.165a.552.552 0 000 .785.555.555 0 00.785 0l4.165-4.165"
      />
    </g>
  </svg>
);

export default SvgCloseIcon
