import React, { Component } from 'react'
import { connect } from 'react-redux'
import InactiveProjectErrorDialog from './InactiveProjectErrorDialog'
import { getNotificationByType } from '../redux/notifications/notifications-reducer'

class ErrorDialogsContainer extends Component {

  render () {

    const { inactiveProjectError } = this.props

    if (inactiveProjectError) {
      return <InactiveProjectErrorDialog notification={inactiveProjectError}/>
    }

    return null
  }

}

let mapStateToProps = (state) => {
  return {
    inactiveProjectError: getNotificationByType(state, 'INACTIVE_PROJECT'),
  }
}

export default connect(mapStateToProps)(ErrorDialogsContainer)