import {materialTypes} from '../common/MaterialTypes'
import {getMaterial} from '../common/Materials'

export const axisConfig = {
  vertex: {
    innerActiveMaterial: getMaterial(materialTypes.green),
    innerInactiveMaterial: getMaterial(materialTypes.transparent),
    outerActiveMaterial: getMaterial(materialTypes.green),
    outerInactiveMaterial: getMaterial(materialTypes.transparent),
    innerSize: 0.1,
    outerSize: 0.3,
    innerSegments: 32,
    outerSegments: 32,
  },
  edge: {
    innerActiveMaterial: getMaterial(materialTypes.green),
    innerInactiveMaterial: getMaterial(materialTypes.transparent),
    outerActiveMaterial: getMaterial(materialTypes.green),
    outerInactiveMaterial: getMaterial(materialTypes.transparent),
    innerSize: 0.20,
    outerSize: 0.20,
  }

}