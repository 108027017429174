import React from 'react'
import { Route, Routes } from 'react-router'

import ApplicationBuilderOverview from './overview/ApplicationBuilderOverview'

import ApplicationBuilderEditor from './editor/ApplicationBuilderEditor'
import { viewNames } from '../../common/globalConstants'

function ApplicationBuilderContent() {


    return (
        <Routes>
          <Route path={'new'} element={<ApplicationBuilderEditor />}/>
          <Route path={':applicationId'} element={<ApplicationBuilderEditor />}/>
          <Route index element={<ApplicationBuilderOverview />}/>
        </Routes>
    )

}

export default ApplicationBuilderContent