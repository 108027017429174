import { Group } from 'three'
import { Base64ImageGeometry } from '../geometries/Base64ImageGeometry'
import { removeAllChildren } from '../../../common/three/drawing/drawConstants'

export class ImageController {

  imageGroup

  constructor (floorPlannerController) {

    this.imageGroup = new Group()
    this.imageGroup.name = 'imageGroup'
    floorPlannerController.scene.add(this.imageGroup)
  }

  updateScale(scale){

  }
  getImageScale() {
    //console.log("imagegroup:")
    //console.log(this.imageGroup)
    let nImages =this.imageGroup.children.length
    //console.log("NIMAGES: "+nImages)
    let scale=1.0
    for (let iImage=0; iImage<nImages; iImage++ ){
      let image=this.imageGroup.children[iImage]
      //console.log(image)
      scale=image.getScale()

      //console.log("imageScale: "+scale)
    }
    return scale
  }
  setImage(image) {
    //console.log("BackGroundImage: "+image)
    removeAllChildren(this.imageGroup)

    //console.log(image)
    const {base64Image, scale} = image
    //console.log("imagescale:")
    //console.log(scale)

    if (base64Image) {
      //console.log("BGImage: "+base64Image)
      this.imageGroup.add(new Base64ImageGeometry(base64Image, scale))
    }
    this.getImageScale()
  }

}