import { floorPlanActionTypes } from '../constants'

let initialState = {
    summaries: [],
    error: null,

}

function furnitureEnsembleSummaryReducer (state = initialState, action) {

    switch (action.type) {

      case floorPlanActionTypes.SET_SELECTED_ORGANISATIONAL_UNIT_ID:
        return { ...initialState }

      case floorPlanActionTypes.FETCH_PLACED_ORGANISATIONAL_UNIT_FURNITURE_ENSEMBLE_SUMMARY_PENDING:
            return { ...state }

      case floorPlanActionTypes.FETCH_PLACED_ORGANISATIONAL_UNIT_FURNITURE_ENSEMBLE_SUMMARY_FULFILLED:
            return {
                ...state,
                summaries: action.payload.data,
            }

      case floorPlanActionTypes.FETCH_PLACED_ORGANISATIONAL_UNIT_FURNITURE_ENSEMBLE_SUMMARY_REJECTED:
            return { ...state, error: action.payload }

        default:
            return { ...state }
    }

}

export default furnitureEnsembleSummaryReducer