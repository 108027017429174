import { PolylineGeometry } from './PolylineGeometry'
import { Mesh, Vector2 } from 'three'

import { geometryTypes } from '../config/GeometryTypes'
import { materialTypes } from '../common/MaterialTypes'
import { getMaterial } from '../common/Materials'

import { getPropertiesByRepresentationType } from '../factories/PropertiesFactory'
import { createFaceBufferGeometryLinearOffset,createFaceBufferGeometryRingOffset } from '../helper/Offsetting'
import {
  addToOffset,
  getOffset,
  getOffsets,
  setOffset,
  shiftOffsetToLeft,
  shiftOffsetToRight,
} from './utils/OffsetGeometryUtils'
import { addVertexToEdge, moveDelta, updateVertex } from './utils/GeometryBasicFunctionsUtils'

export class OffsetPolylineGeometry extends PolylineGeometry {

  constructor (parent, representationType, properties) {
    super(parent, representationType)

    if (properties) {
      this.offsetLeft = properties.offsetLeft
      this.offsetRight = properties.offsetRight
      this.properties = properties
    } else {
      const properties = getPropertiesByRepresentationType(representationType)
      this.offsetLeft = properties.offsetLeft
      this.offsetRight = properties.offsetRight
      this.properties = properties
    }

    this.addToOffset = (step) => {
      addToOffset(this, step)
      this.updateOffsetFace()
    }
    this.setOffset = (offset) => setOffset(this, offset)
    this.getOffset = () => {
      return getOffset(this)
    }
    this.getOffsets = () => {
      return getOffsets(this)
    }
    this.shiftOffsetToLeft = () => {
      shiftOffsetToLeft(this)
      this.updateOffsetFace()
    }
    this.shiftOffsetToRight = () => {
      shiftOffsetToRight(this)
      this.updateOffsetFace()
    }

    this.updateVertex = (position, vertex = null) => {
      updateVertex(position, this, vertex)

      this.updateOffsetFace()
    }
    this.addVertexToEdge = (edge, position) => {
      addVertexToEdge(edge, position, this)

      this.updateOffsetFace()
    }
    this.moveDelta = (movement) => {
      moveDelta(movement, this)

      if (this.offsetFace) {
        this.offsetFace.position.x += movement.x
        this.offsetFace.position.y += movement.y

        this.offsetFace.vertices.forEach(vertex => {
          vertex.x += movement.x
          vertex.y += movement.y
        })
      }
    }
  }

  close () {
    super.close()

    this.vertexGroup.setActive(false)
    this.edgeGroup.setActive(false)
    this.createOffsetFace()
  }

  getVerticesPositions () {
    const verticesPositions = super.getVerticesPositions()

    return this.offsetFace ?
      this.offsetLeft > 0 && this.offsetRight > 0 ?
        verticesPositions.concat(this.offsetFace.vertices)
        : this.offsetFace.vertices : verticesPositions
  }

  getNearestEdgeToPosition (position) {
    return this.edgeGroup.getNearestEdgeToPosition(position)
  }

  createOffsetFace () {
    console.log("create OffsetFace")
    let positions = this.vertexGroup.getPositions()
    let firstPosition = new Vector2(positions[0].x, positions[0].y)
    let lastPosition = new Vector2(positions[positions.length - 1].x, positions[positions.length - 1].y)
    let minDist = 0.001

    if((firstPosition.distanceTo(lastPosition) < minDist)){
      positions.pop()
      const { geometry, innerVertices, outerVertices } = createFaceBufferGeometryRingOffset(positions, this.offsetLeft, this.offsetRight)

      this.offsetFace = new Mesh(geometry, getMaterial(materialTypes.black))
      this.offsetFace.geometryType = geometryTypes.offsetFace
      this.offsetFace.innerVertices = innerVertices
      this.offsetFace.outerVertices = outerVertices
      this.offsetFace.isRing=true
      console.log(outerVertices)
      console.log(innerVertices)
      console.log(geometry)
      console.log("offsetFace-ring")
      console.log(this.offsetFace)
      this.add(this.offsetFace)
    }else{
      console.log("line")
      const { geometry, vertices } = createFaceBufferGeometryLinearOffset(this.vertexGroup.getPositions(), this.offsetLeft, this.offsetRight)
      console.log("vvertices")
      console.log(vertices)
      this.offsetFace = new Mesh(geometry, getMaterial(materialTypes.black))
      this.offsetFace.geometryType = geometryTypes.offsetFace
      this.offsetFace.vertices = vertices
      this.offsetFace.isRing=false
      console.log("offsetFace-line")
      console.log(this.offsetFace)
      this.add(this.offsetFace)
    }
  }

  updateOffsetFace () {
    /*
    console.log("create OffsetFace")
    let positions=this.vertexGroup.getPositions()
    let firstPosition=new Vector2(positions[0].x,positions[0].y)
    let lastPosition=new Vector2(positions[positions.length-1].x,positions[positions.length-1].y)
    let minDist=0.001

    if(firstPosition.distanceTo(lastPosition)<minDist){
      console.log("ring")
      positions.pop()
      const { geometry, innerVertices, outerVertices } = createFaceBufferGeometryRingOffset(positions, this.offsetLeft, this.offsetRight)
      this.offsetFace = new Mesh(geometry, getMaterial(materialTypes.black))
      this.offsetFace.geometryType = geometryTypes.offsetFace
      this.offsetFace.innerVertices = innerVertices
      this.offsetFace.outerVertices = outerVertices
      console.log(outerVertices)
      console.log(innerVertices)
      console.log(geometry)
      console.log("offsetFace-ring")
      console.log(this.offsetFace)
      this.add(this.offsetFace)
    }else{
      console.log("line")
      const { geometry, vertices } = createFaceBufferGeometryLinearOffset(this.vertexGroup.getPositions(), this.offsetLeft, this.offsetRight)

      this.offsetFace = new Mesh(geometry, getMaterial(materialTypes.black))
      this.offsetFace.geometryType = geometryTypes.offsetFace
      this.offsetFace.vertices = vertices
      console.log("offsetFace-line")
      console.log(this.offsetFace)
      this.add(this.offsetFace)
    }
    */

    const { offsetFace, vertexGroup, offsetLeft, offsetRight } = this

    if (!offsetFace)
      return

    console.log("update OffsetFace")
    offsetFace.position.set(0, 0, 0)
    offsetFace.geometry.dispose()


    const { geometry, vertices } = createFaceBufferGeometryLinearOffset(vertexGroup.getPositions(), offsetLeft, offsetRight)
    offsetFace.geometry = geometry
    offsetFace.vertices = vertices
    offsetFace.isRing = false

    this.edgeGroup.updateAllEdgesCallbacks()
  }

  dispose () {
    super.dispose()

    if (this.offsetFace && this.offsetFace.geometry) {
      this.offsetFace.geometry.dispose()
    }
  }

  fromJSON (json) {
    super.fromJSON(json)

    this.createOffsetFace()
  }

  toJSON () {
    const json = super.toJSON()
    console.log("oooffsetFace")
    console.log(this.offsetFace.vertices)
    console.log(this.offsetFace.edges)

    console.log("object")
    console.log(this.offsetFace)
    console.log("this")
    console.log(this)
    let vertices=[]
    let edges=[]
    //console.log("isRing")

    if(this.offsetFace.isRing){

      let nVerts = this.offsetFace.innerVertices.length
      console.log("isRing")
      for(let iVert=0;iVert<=nVerts; iVert++){
        let cVert=this.offsetFace.innerVertices[iVert]
        let nexti=iVert+1
        if(nexti>=nVerts) nexti=0
        let nVert=this.offsetFace.innerVertices[nexti]
        console.log("nextVertex")
        console.log(nVert)

        //vertices.push()
      }
    }
    json.properties = {
      offsetLeft: this.offsetLeft,
      offsetRight: this.offsetRight,
      isRing: this.offsetFace.isRing,
      // put all verrtices and edges here

    }


    return json
  }

}