import { Vector2, Vector3 } from 'three'

export function convertVector3ToVector2(vector3) {
  return new Vector2(vector3.x, vector3.y)
}

export function convertListVector2ToVector3(vector2List, z = 0) {
  return vector2List.map(vector2 => new Vector3(vector2.x, vector2.y, z))
}

export function subtractVector(vectorA, vectorB) {
  return vectorA.clone().add(vectorB.clone().multiplyScalar(-1))
}

export function includesVector(vectorList, vector) {
  for (let i = 0; i < vectorList.length; i++) {
    if (vectorList[i].distanceToSquared(vector) < 0.001)
      return true
  }

  return false
}