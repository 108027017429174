import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Collapsible from 'react-collapsible'

import { getDepartmentListItemStyle, getRgbaColor } from '../utils'

import { IconWrapper } from './IconWrapper'
import Icon, { iconTypes } from './icons/IconFactory'
import Text from './Text'

import HoverIconButton from './HoverIconButton'

import './TreeList.scss'

function ListItemContent ({ node, getStyle, onLevelItemClickHandler, onLevelItemDraggedHandler }) {
  const handleListItemClick = node.level >= 0 && onLevelItemClickHandler && onLevelItemClickHandler[node.level] ? onLevelItemClickHandler[node.level] : () => {}
  const handleListItemDragged = node.level >= 0 && onLevelItemDraggedHandler && onLevelItemDraggedHandler[node.level] ? onLevelItemDraggedHandler[node.level] : () => {}

  const selectedStyle = getStyle(node)
  let listItemContentStyle = getDepartmentListItemStyle(node.color, node.disabled)

  if (selectedStyle.includes('selected')) {
    listItemContentStyle['background'] = `rgba(${getRgbaColor(node.color, .8)})`
  }

  const width = 17.3 - (node.level * 1.8) + (node.nodes && node.nodes.length ? 0 : 1.6)

  return (
    <div className={'list-item-content ' + selectedStyle + (node.level && handleListItemClick && !node.disabled ? 'selectable' : '')}
         onMouseDown={() => handleListItemDragged(node)}
         style={listItemContentStyle}>
      <div className={'list-item-content-icon-text'}
           onClick={() => handleListItemClick(node.key, node.parentKey)}>
        {node.icon && <IconWrapper name={node.icon} styleClass={'list-item-icon'}/>}
        <div className={'list-item-content-text'}
             style={{ width: width + 'rem' }}>
          <Text>{node.label}</Text>
          {node.labelValue ? <Text outerClassName={'list-item-content-label-value'}>{node.labelValue}</Text> : null}
        </div>
      </div>
      {node.iconButton && <HoverIconButton icon={node.iconButton} onClicked={node.iconButtonClicked} iconButtonPermanent={node.iconButtonPermanent}/>}
    </div>
  )
}

function isOpenCollapsible (node, index) {
  return true //node.open || ((node.open === undefined || node.open === null) && index === 0)
}

class ListItem extends Component {

  render () {
    const { node, maxLevel, onLevelOpening, openCollapsible = false } = this.props

    const childNodes = (!node.level || node.level < maxLevel) ? node.nodes ? node.nodes.map((node, index) =>
      <ListItem {...this.props}
                openCollapsible={isOpenCollapsible(node, index)}
                key={node.key} node={node}/>) : null : null

    const onOpening = (!node.level !== null && node.level !== undefined) && onLevelOpening && onLevelOpening.length > node.level ? onLevelOpening[node.level] : () => {}

    if (childNodes) {

      return (
        <li className={'list-item collapsible-list-item'}>
          <ListItemContent {...this.props}/>
          <Collapsible className={'list-item-collapsible'}
                       openedClassName={'list-item-collapsible open'}
                       triggerClassName={'list-item-collapsible-trigger'}
                       triggerOpenedClassName={'list-item-collapsible-trigger open'}
                       contentOuterClassName={'list-item-collapsible-content-container'}
                       contentInnerClassName={'list-item-collapsible-content'}
                       triggerTagName={'div'}
                       transitionTime={200}
                       triggerDisabled={node.disabled}
                       trigger={<Icon className={'trigger-arrow'} name={iconTypes.triangle}/>}
                       onOpening={() => onOpening(node.key)}
                       lazyRender={false}
                       open={openCollapsible || node.key === this.props.activeLevel0 || node.key === this.props.activeLevel1 || node.key === this.props.activeLevel2}>
            <ul className={'tree-list'}>
              {childNodes}
            </ul>
          </Collapsible>
        </li>
      )
    } else {
      return (
        <li className={'list-item'}>
          <ListItemContent {...this.props}/>
        </li>
      )
    }
  }
}

/**
 * Example input data:
 *
 *[
 * {
 *    key: 'first-level-node-1',
 *    label: 'first-level',
 *    level: 0
 *    index: 0
 *    color: {
 *              "r": 0,
 *              "g": 0.466,
 *              "b": 0,
 *              "a": 0.5
 *    },
 *    nodes: [
 *      key: 'second-level-node-1',
 *       label: 'second-level',
 *       level: 0
 *       index: 0
 *       color: {
 *                 "r": 0,
 *                 "g": 0.466,
 *                 "b": 0,
 *                 "a": 0.5
 *       },...
 *     ]
 *  },
 *]
 */
class TreeList extends Component {

  render () {
    let maxLevel = this.props.maxLevel ? this.props.maxLevel : 2

    let nodes = this.props.nodes ? this.props.nodes.map((node, index) => <ListItem key={node.key}
                                                                openCollapsible={isOpenCollapsible(node, index)}
                                                                activeLevel0={this.props.activeLevel0}
                                                                activeLevel1={this.props.activeLevel1}
                                                                activeLevel2={this.props.activeLevel2}
                                                                node={node}
                                                                maxLevel={maxLevel}
                                                                onLevelItemClickHandler={this.props.onLevelItemClick}
                                                                onLevelItemDraggedHandler={this.props.onLevelItemDragged}
                                                                onLevelOpening={this.props.onLevelOpening}
                                                                getStyle={(node) => `${this.getListItemSelectedStyleClass(node)}
                                                                                     ${this.getListItemVisibilityStyleClass(node)}`}/>)
      : null

    const className = this.props.className ? ' ' + this.props.className : ''

    return (
      <div className={'tree-list-container' + className}>
        <ul className={'tree-list'}>
          {nodes}
        </ul>
      </div>
    )
  }

  getListItemExpandStyleClass (node) {
    return node.secondLine ? 'expand ' : ''
  }

  getListItemSelectedStyleClass (node) {
    return this.isSelected(node) ? 'selected ' : ''
  }

  getListItemVisibilityStyleClass (node) {
    let disabled = node.disabled !== null && node.disabled !== undefined ? node.disabled : false
    return disabled ? 'disabled-item ' : ''
  }

  isSelected (node) {
    return node.key === this.props.selected || (node.selected !== undefined && node.selected)
  }
}

TreeList.protoTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.string,
  maxLevel: PropTypes.number,
  onLevelItemClick: PropTypes.arrayOf(PropTypes.func),
  onLevelItemDragged: PropTypes.arrayOf(PropTypes.func),
  onLevelOpening: PropTypes.arrayOf(PropTypes.func),
}

export default TreeList