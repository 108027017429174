import React, { Component, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Route, Routes, Navigate, useParams } from 'react-router'

import { getCurrentProjectId } from '../common/utils'
import { fetchProjectData } from '../floorplan/actions/actions'

import GeneralSettings from './components/GeneralSettings'
import ProjectInfo from './components/ProjectInfo'
import Spacing from './components/Spacing'
import ApplicationProfiles from './components/ApplicationProfiles'
import LayoutStyle from './components/LayoutStyle'
import PatternStyles from './components/PatternStyles'

import Inspector from '../common/components/Inspector'
import InspectorContainer from '../common/components/InspectorContainer'
import ListMenu from '../common/components/ListMenu'
import ListMenuNavItem from '../common/components/ListMenuNavItem'
import { PlanAppContainer } from '../common/components/layout/PlanAppContainer'

import './SettingsApp.scss'
import { viewNames } from '../common/globalConstants'

function NavItem (props) {
  return <ListMenuNavItem {...props} replace activeClassName={'settings-menu-nav-item-active'}/>
}

function SettingsApp () {
  const dispatch = useDispatch()
  const { projectId } = useParams()
  const dataLoaded = useSelector(state => state.dataLoaded.dataLoaded)
  const useLens = useSelector(state => state.settings.appSettings.useLens)
  const advancedSettings = useSelector(state => state.settings.appSettings.advancedSettings)
  const settings = useSelector(state => state.settings.appSettings)
  const baseUrl = `/projects/${projectId}/${viewNames.SETTINGS}/`;
  console.log("Load Settings APP")

  useEffect(() => {

    if (!dataLoaded) {
      dispatch(fetchProjectData(projectId))
    }
  }, [])


    return (
      <PlanAppContainer className={'settings-content'}>

        <div className="sidebar">
          <Inspector>
            <InspectorContainer headerTitle={'Settings'} className={'settings-inspector'}>
              <ListMenu>
                <NavItem title={'General'} to={`${baseUrl}general` }/>
                <NavItem title={'Project Information'} to={`${baseUrl}info` }/>
                <NavItem title={'Clearances & Spacing'} to={`${baseUrl}spacing` }/>
                <NavItem title={'Application Styles'} to={`${baseUrl}applicationstyle` } visible={advancedSettings}
                         disabled={useLens} tooltip={useLens ? 'Deactivate LENS in general settings to change styles' : ''}/>
                <NavItem title={'Layout Styles'} to={`${baseUrl}layoutstyle` } visible={advancedSettings}
                         disabled={useLens} tooltip={useLens ? 'Deactivate LENS in general settings to change styles' : ''}/>
                <NavItem title={'Pattern Styles'} to={`${baseUrl}patternstyle` } visible={advancedSettings}
                         disabled={useLens} tooltip={useLens ? 'Deactivate LENS in general settings to change styles' : ''}/>
              </ListMenu>
            </InspectorContainer>
          </Inspector>
        </div>

        <div className="content">
          <Routes>
            <Route path={`spacing`} element={<Spacing/>}/>
            <Route path={`patternstyle`} element={<PatternStyles/>}/>
            <Route path={`applicationstyle`} element={<ApplicationProfiles/>}/>
            <Route path={`layoutstyle`} element={<LayoutStyle/>}/>
            <Route path={`info`} element={<ProjectInfo/>}/>
            <Route path={`general`} element={<GeneralSettings/>}/>
            <Route
              path="*"
              element={<Navigate to={`general`} replace />}
            />          </Routes>
        </div>

      </PlanAppContainer>
    )

}

export default SettingsApp