import * as React from 'react'

const SvgDrawDoorActive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32">
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
      <linearGradient
        id="prefix__b"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#DA001C"/>
        <stop offset="100%" stopColor="#FF3C55"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g stroke="#D14720" strokeLinejoin="round" transform="translate(8 5)">
        <rect width={16} height={20} rx={1}/>
        <path
          fill="url(#prefix__a)"
          d="M0 .992C0 .444.405.184.919.418l9.162 4.164c.508.231.919.873.919 1.41v18.016c0 .548-.405.808-.919.574L.92 20.418c-.51-.231-.92-.873-.92-1.41V.992z"
        />
        <path fill="url(#prefix__b)" strokeLinecap="round" d="M7 11.5l4 2"/>
      </g>
    </g>
  </svg>

export default SvgDrawDoorActive