import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon, { iconTypes } from '../icons/IconFactory'

import './BuilderOverviewElementCreate.scss'
import { withTranslation } from 'react-i18next'

class BuilderOverviewElementCreate extends Component {

  render () {
    const {t} = this.props

    return (
      <div className={'builder-overview-element'} onClick={() => this.props.onClicked()}>
        <div className={'builder-overview-element-icon-button'}>
          <Icon className={'builder-overview-element-icon'} name={iconTypes.plus}/>
          {t('create_new')}
        </div>
      </div>
    )
  }
}

BuilderOverviewElementCreate.propTypes = {
  onClicked: PropTypes.func,
}

export default withTranslation() (BuilderOverviewElementCreate)
