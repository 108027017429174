import React from 'react';
import { NavLink } from 'react-router-dom';

import Icon from '../../common/components/icons/IconFactory';
import Text from '../../common/components/Text';

import './NavigationDropDownItem.scss';

export function NavLinkDropDownItem({ urlBase, view, icon, iconColor, children, onClose = () => {}, darkMode }) {

  return (
    <NavLink
      className="navigation-drop-down-item"
      onClick={onClose}
      to={`${urlBase}/${view}`}
    >
      <div className="navigation-drop-down-item-container">
        <div className="navigation-drop-down-item-content">
          {icon && (
            <div className="navigation-drop-down-item-icon">
              <Icon name={icon} color={iconColor} />
            </div>
          )}
          <Text children={children} darkMode={darkMode ? ' dark-mode' : ''} />
        </div>
      </div>
    </NavLink>
  );
}
