import React, { Component } from 'react'
import SliderInputLabel from '../../../../common/components/SliderInputLabel'

import './WorkStyleLensController.scss'

class WorkStyleLensController extends Component {

  constructor (props) {
    super(props)
    this.state = props.lens || {
      location: 50,
      autonomy: 50,
      interaction: 50,
      knowledge: 50,
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.lens !== this.props.lens) {
      this.setState({ ...nextProps.lens })
    }
  }

  handleChangeEnd () {
    this.props.onChange(this.state)
  }

  handleLocationSliderChange (event) {
    this.setState({ location: event.target.value })
  }

  handleAutonomySliderChange (event) {
    this.setState({ autonomy: event.target.value })
  }

  handleInteractionSliderChange (event) {
    this.setState({ interaction: event.target.value })
  }

  handleKnowledgeSliderChange (event) {
    this.setState({ knowledge: event.target.value })
  }

  render () {

    let disabled = !this.props.id

    return (
      <div className={'work-style-lens-controller'}>

        <SliderInputLabel
          className={'work-style-lens-slider'}
          label={'Location'}
          minLabel={'Fixed'}
          maxLabel={'Mobile'}
          disabled={disabled}
          value={this.state.location}
          onChange={(event) => this.handleLocationSliderChange(event)}
          onChangeEnd={() => this.handleChangeEnd()}/>

        <SliderInputLabel
          className={'work-style-lens-slider'}
          label={'Autonomy'}
          minLabel={'Low'}
          maxLabel={'High'}
          disabled={disabled}
          value={this.state.autonomy}
          onChange={(event) => this.handleAutonomySliderChange(event)}
          onChangeEnd={() => this.handleChangeEnd()}/>

        <SliderInputLabel
          className={'work-style-lens-slider'}
          label={'Interaction'}
          minLabel={'Face-toFace'}
          maxLabel={'Solo'}
          disabled={disabled}
          value={this.state.interaction}
          onChange={(event) => this.handleInteractionSliderChange(event)}
          onChangeEnd={() => this.handleChangeEnd()}/>

        <SliderInputLabel
          className={'work-style-lens-slider'}
          label={'Knowledge'}
          minLabel={'Strategic'}
          maxLabel={'Tactical'}
          disabled={disabled}
          value={this.state.knowledge}
          onChange={(event) => this.handleKnowledgeSliderChange(event)}
          onChangeEnd={() => this.handleChangeEnd()}/>

      </div>
    )
  }
}

export default WorkStyleLensController