import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import Measure from 'react-measure'

import { getAssignedDepartmentsOfSelectedBuilding } from '../../common/redux/organisationunits/organisational-units-reducer'
import { getFloorsOfSelectedBuilding } from '../../floorplan/reducers/buildings'

import { deleteOrganisationalUnitAssignment, updateOrganisationalUnitAssignment } from '../../floorplan/actions/actions'
import { getSelectedBuildingId } from '../../common/redux/appstate/appstate-reducer'

import { dropBlockingItem } from '../../floorplan/actions/ui-actions'

import Floor from './Floor'

import './BlockingEditor.scss'
import Text from '../../common/components/Text'

const deleteArea = 'deleteArea'

class BlockingEditor extends Component {

  render () {
    let floors = this.renderFloors()
    this.drawBars()

    return (
      <React.Fragment>
        <Text outerClassName={'blocking-header'} component={'h3'} multiline>{this.props.header}</Text>
        <Measure bounds
                 key={Math.round(Math.random() * 999999)}
                 onResize={() => {this.drawBars()}}>

          {({ measureRef }) => (
            <div ref={measureRef} className={'blocking-editor'}>
              <DragDropContext onDragEnd={(result) => this.handleOnDragEnd(result)}>
                <Droppable droppableId={deleteArea}>
                  {(provided) => (
                    <React.Fragment>
                      <div className={'floor-list-keeper'}
                           ref={provided.innerRef}
                           {...provided.droppableProps}>
                        <div className={'floors-list'}>
                          {floors}
                        </div>
                        <div>
                          {provided.placeholder}
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}

        </Measure>
      </React.Fragment>
    )

  }

  getLargestDPT (floors) {

    let maxArea = 0

    floors.forEach(floor => {
      let area = 0

      this.props.placedDepartments
        .filter(department => department.floorId === floor.id)
        .forEach(department => area += department.area)

      if (area > maxArea)
        maxArea = area
    })

    return maxArea
  }

  renderFloors () {
    if (!this.props.placedDepartments) {
      return
    }

    let maxArea = Math.max(...this.props.floors.map(floor => floor.area))

    let maxDepartmentArea = this.getLargestDPT(this.props.floors)

    if (maxDepartmentArea > maxArea)
      maxArea = maxDepartmentArea

    return this.props.floors.map((floor) => {
      const blocks = this.props.placedDepartments.filter(department => department.floorId === floor.id)

      return <Floor key={floor.id}
                    floor={floor}
                    blocks={blocks}
                    maxArea={maxArea}/>
    })

  }

  drawBars = () => {
    let list = document.getElementsByClassName('block')

    const barHeight = 18
    const offsetX = 70
    const offsetY = 30
    let opacity = 1

    for (let p = 0; p < list.length; p++) {
      const w = list[p].offsetWidth
      const h = list[p].offsetHeight

      const ow = w + offsetX

      const cr = list[p].dataset.colr
      const cg = list[p].dataset.colg
      const cb = list[p].dataset.colb
      const name = list[p].dataset.name
      const col1 = 'rgb(' + cr + ',' + cg + ',' + cb + ')'
      const col2 = 'rgb(' + cr * 1.2 + ',' + cg * 1.2 + ',' + cb * 1.2 + ')'
      const col22 = 'rgb(' + cr * 1.4 + ',' + cg * 1.4 + ',' + cb * 1.4 + ')'
      const col3 = 'rgb(' + cr * 0.8 + ',' + cg * 0.8 + ',' + cb * 0.8 + ')'

      const topLine1 = h - barHeight
      const topLine2 = topLine1 - offsetY
      const bottomLine = h - offsetY

      const wpNeeded = list[p].dataset.workplacesneeded
      const wpThere = list[p].dataset.workplaces

      let svg =

        '<svg width="' + w + '" height="' + h + '" overflow="visible" opacity="' + opacity + '">' +

        '<defs>' +
        '<linearGradient id="grad' + p + '" gradientTransform="rotate(90)">' +
        '<stop offset="5%" style="stop-color:' + col2 + ';stop-opacity:1" />' +
        '<stop offset="95%" style="stop-color:' + col22 + ';stop-opacity:1" />' +
        '</linearGradient>' +
        '</defs>' +

        '<polygon points="0,' + h + ' ' + w + ',' + h + ' ' + w + ',' + topLine1 + ' 0,' + topLine1 + '"/  fill="' + col1 + '" />' +
        '<polygon points="' + offsetX + ',' + topLine2 + ' ' + ow + ',' + topLine2 + ' ' + w + ',' + topLine1 + ' 0,' + topLine1 + '"/  fill="url(#grad' + p + ')" />' +
        '<polygon points="' + ow + ',' + topLine2 + ' ' + w + ',' + topLine1 + ' ' + w + ',' + h + ' ' + ow + ',' + bottomLine + '"/  fill="' + col3 + '" />'

      if ((wpNeeded > 0) && (wpThere < wpNeeded)) svg += '<circle cx="' + w * 0.5 + '" cy="' + h + '" r="10" fill="rgb(255,0,0)" opacity="0.8"/>'
      svg += '</svg>'
      list.item(p).innerHTML = svg

      list.item(p).innerHTML += '<span class="dptname">' + name + '</span>'

    }

    let floorList = document.getElementsByClassName('rowback')

    for (let p = 0; p < floorList.length; p++) {
      const w = floorList[p].offsetWidth
      const h = floorList[p].offsetHeight

      const topLine1 = h - barHeight
      const topLine2 = topLine1 - offsetY
      const topLineBack = topLine2 + barHeight
      const bottomLine = h - offsetY

      const ow = w + offsetX

      const col1 = 'rgb(244,245,246)'
      const col2 = 'rgb(196,198,210)'
      const col3 = 'rgb(232,233,238)'

      floorList.item(p).innerHTML =

        '<svg width="' + w + '" height="' + h + '" overflow="visible">' +

        '<defs>' +
        ' <filter id="f1" x="0" y="0" width="200%" height="200%">' +
        '<feOffset result="offOut" in="SourceGraphic" dx="3" dy="3" />' +
        '<feGaussianBlur result="blurOut" in="offOut" stdDeviation="5" />' +
        '<feBlend in="SourceGraphic" in2="blurOut" mode="normal" />' +
        '</filter>' +
        '</defs>' +
        '<polygon points="' + offsetX + ',' + bottomLine + ' ' + ow + ',' + bottomLine + ' ' + w + ',' + h + ' 0,' + h + '"/  fill="' + col1 + '" filter="url(#f1)" />' +
        '<polygon points="0,' + h + ' ' + w + ',' + h + ' ' + w + ',' + topLine1 + ' 0,' + topLine1 + '"/  fill="' + col3 + '" opacity = "0.35"/>' +
        '<polygon points="' + offsetX + ',' + topLineBack + ' ' + ow + ',' + topLineBack + ' ' + ow + ',' + topLine2 + ' ' + offsetX + ',' + topLine2 + '"/  fill="' + col3 + '" opacity = "0.25" />' +
        '<polygon points="' + ow + ',' + topLine2 + ' ' + w + ',' + topLine1 + ' ' + w + ',' + h + ' ' + ow + ',' + bottomLine + '"/  fill="' + col2 + '" opacity = "0.35" />' +

        '</svg>'

    }

    floorList = document.getElementsByClassName('floor')
    for (let p = 0; p < floorList.length; p++) {
      const width = floorList[p].getElementsByClassName('rowback')[0].offsetWidth

      let dptList = floorList[p].getElementsByClassName('block')
      for (let q = 0; q < dptList.length; q++) {
        const iWidth = dptList[q].offsetWidth
        const iPos = dptList[q].offsetLeft

        if ((iWidth + iPos) > (width)) {

          dptList[q].getElementsByTagName('svg')[0].setAttribute('opacity', 0.5)
        }
      }
    }

  }

  // TODO unused
  handleOnDragEnd = (result) => {
    const { destination, source, draggableId } = result

    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return
    }

    const sourceFloor = this.props.floors.find(floor => floor.id === source.droppableId)
    const destinationFloor = this.props.floors.find(floor => floor.id === destination.droppableId)

    if (destination.droppableId === deleteArea) {
      this.props.deleteOrganisationalUnitAssignment(sourceFloor.buildingId, sourceFloor.id, draggableId)
    } else {
      const assignment = {
        buildingId: destinationFloor.buildingId,
        floorId: destinationFloor.id,
        index: destination.index,
      }

      this.props.updateOrganisationalUnitAssignment(sourceFloor.buildingId, sourceFloor.id, draggableId, assignment)
    }

  }

}

let mapStateToProps = (state) => {
  return {
    uiState: state.floorPlanUiState,
    selectedBuildingId: getSelectedBuildingId(state),
    departments: getAssignedDepartmentsOfSelectedBuilding(state),
    floors: getFloorsOfSelectedBuilding(state),

    placedDepartments: state.placedOrganisationalUnits.placedDepartments,

    undoDataUpdate: state.undoDataUpdate,
  }
}

let mapDispatchToProps = {
  updateOrganisationalUnitAssignment: updateOrganisationalUnitAssignment, //unused
  deleteOrganisationalUnitAssignment: deleteOrganisationalUnitAssignment, //unused
  dropBlockingItem: dropBlockingItem,
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockingEditor)