import React, { Component } from 'react'

import SelectButton from '../../../common/components/SelectButton'

const appliedRulesOptions = [
  { label: 'Number of units', value: 'UNITS' },
  { label: 'Number of seats', value: 'SEATS' },
  { label: 'Headcount per unit', value: 'PER_UNIT' },
  { label: 'Headcount per seat', value: 'PER_SEAT' },
]

class TableAppliedRulesSelectButton extends Component {

  render () {
    return (
      <SelectButton onChange={(event) => this.handleAppliedRulesSelection(event.target.value)}
                    value={this.props.value}
                    options={appliedRulesOptions}/>
    )
  }

  handleAppliedRulesSelection (selection) {
    this.props.onChange({ placing: selection })
  }

}

export default TableAppliedRulesSelectButton
