import { httpClient } from '../../common/httpClient'
import { floorPlanActionTypes } from '../../floorplan/constants'
import { getBuildingId, getFloorId, getProjectId, projectApi, projectVariationApi } from '../../common/utils'
import { getUndoInformation } from '../../common/redux/undo/undo-actions'
import { fetchDepartmentAssignments, fetchFloorData } from '../../floorplan/actions/actions'
import { fetchAppState } from '../../common/redux/appstate/appstate-actions'
import { getGeometryObjectsToSave } from '../../DrawingTool/reducer/drawingToolReducer'

export function fetchBuildings () {
  console.log("fetchBuildings")
  return {
    type: floorPlanActionTypes.FETCH_BUILDINGS,
    payload: httpClient.get(projectVariationApi() + '/buildings'),
  }
}

export function fetchFloorPlan (buildingId, floorId) {
  console.log("fetchFloorPlan")
  return {
    type: floorPlanActionTypes.FETCH_FLOOR_PLAN,
    payload: httpClient.get(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}`),
  }
}

export function fetchFloorPlanAnalytics (buildingId, floorId) {
  return {
    type: floorPlanActionTypes.FETCH_FLOOR_PLAN_ANALYTICS,
    payload: httpClient.get(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/analytics`),
  }
}

export function updateAxisAndFetch (buildingId, floorId, updateObjects) {

  return function (dispatch) {
    dispatch(updateAndFetchAxisAPI(buildingId, floorId, updateObjects))
      .then(() => {
        dispatch(fetchFloorPlanAnalytics(buildingId, floorId))
      })
  }
}

export function updateAndFetchAxisAPI (buildingId, floorId, updateObjects) {
  updateObjects.newAxes.forEach(newAxe => {
    addAxis(buildingId, floorId, newAxe.points)
  })

  updateObjects.deleteAxes.forEach(deleteAxe => {
    deleteAxis(buildingId, floorId, deleteAxe.axeIndex)
  })

  return {
    type: floorPlanActionTypes.FETCH_FLOOR_PLAN_ANALYTICS,
    payload: httpClient.get(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/analytics`),
  }
}

export function addAndFetchAxis (points) {
  const buildingId = getBuildingId()
  const floorId = getFloorId()

  return function (dispatch) {
    dispatch(addAxis(buildingId, floorId, points))
      .then(() => {
        dispatch(fetchFloorPlanAnalytics(buildingId, floorId))
      })
  }
}

export function deleteAndFetchAxis (axeIndex) {
  const buildingId = getBuildingId()
  const floorId = getFloorId()

  return function (dispatch) {
    dispatch(deleteAxis(buildingId, floorId, axeIndex))
      .then(() => {
        dispatch(fetchFloorPlanAnalytics(buildingId, floorId))
      })
  }
}

function deleteAxis (buildingId, floorId, axisId) {

  return {
    payload: httpClient.delete(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/axis/${axisId}`),
  }
}

export function updateMainAxisPositionAndFetch (axeId, x, y) {
  const buildingId = getBuildingId()
  const floorId = getFloorId()

  const position = {
    x: x,
    y: y,
    z: 0,
  }

  return function (dispatch) {
    dispatch(updateMainAxisPosition(buildingId, floorId, axeId, position))
      .then(() => {
        dispatch(fetchFloorPlanAnalytics(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function updateMainAxisPosition (buildingId, floorId, axisId, position) {
  return {
    type: floorPlanActionTypes.UPDATE_ORGANISATIONAL_UNITS,
    payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/axis/${axisId}/position`, position),
  }
}

export function lengthenMainAxis (buildingId, floorId, connectorId, x, y) {

  const position = {
    x: x,
    y: y,
  }

  return function (dispatch) {
    dispatch(updateConnectorPosition(buildingId, floorId, connectorId, position))
      .then(() => {
        dispatch(fetchFloorPlanAnalytics(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function updateConnectorPosition (buildingId, floorId, connectorId, position) {
  return {
    payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/connector/${connectorId}/position`, position),
  }
}

export function addAreaObject (buildingId, floorId, areaDescription) {
  return function (dispatch) {
    dispatch(addArea(buildingId, floorId, areaDescription))
      .then(() => {
        dispatch(fetchFloorPlanAnalytics(buildingId, floorId))
        dispatch(fetchFloorPlan(buildingId, floorId))
      })
  }
}

export function setImageScale (buildingId, floorId, scale, scaleJson) {
  console.log("setImageScallle: ")
  console.log(scale)
  console.log(scaleJson)
  console.log("before dispatch")
  return function (dispatch) {
    console.log("real dispatch")
    dispatch(setImageScaleAPI(buildingId, floorId, scale, null))
      .then(() => {
        return Promise.all([
          //dispatch(fetchBuildings()),
          //dispatch(fetchFloorData(buildingId, floorId))
        ]);
      });
  }
}
export function setImageScaleWithPromise (scale) {
  console.log("setImageScallleProm: ")
  console.log(scale)
  console.log("before dispatch")
  const buildingId = getBuildingId()
  const floorId = getFloorId()
  return function (dispatch) {
    console.log("real dispatch")
    dispatch(setImageScaleAPI(buildingId, floorId, scale))
      .then(() => {
        return Promise.all([
          //dispatch(fetchBuildings()),
          //dispatch(fetchFloorData(buildingId, floorId))
        ]);
      });
  }
}
/*
return function (dispatch) {
  return dispatch(updateGeometriesAPI(buildingId, floorId, geometries))
    .then(() => {
      return Promise.all([
        //dispatch(fetchBuildings()),
        //dispatch(fetchFloorData(buildingId, floorId))
      ]);
    });
};
 */

export function setImageScaleAPI (buildingId, floorId, scale) {
  console.log("send request")
  const requestBody = {
    value: scale,
  }
  console.log(requestBody)
  console.log(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/scale`)
  return {
    payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/scale`, requestBody),
  }
}

export function updateDrawingAndFetch () {
  const buildingId = getBuildingId()
  const floorId = getFloorId()
  const geometries = getGeometryObjectsToSave()
  console.log("updateDrawingAndFetch")
  console.log(geometries)

  return function (dispatch) {
    dispatch(updateDrawingAPI(buildingId, floorId, geometries))
      .then(() => {
        dispatch(fetchBuildings())
        dispatch(fetchFloorData(buildingId, floorId))
      })
  }
}

function updateDrawingAPI (buildingId, floorId, geometries) {
  return {
    payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/drawing`, geometries),
  }
}

export function fetchDrawing (buildingId, floorId) {
  return {
    type: floorPlanActionTypes.FETCH_DRAWING,
    payload: httpClient.get(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/drawing`),
  }
}

export function setCurrentDrawingTab (currentDrawingTab) {
  const buildingId = getBuildingId()
  const floorId = getFloorId()

  return function (dispatch) {
    dispatch(setCurrentDrawingTabAPI(buildingId, floorId, currentDrawingTab))
      .then(() => {
        dispatch(fetchCurrentDrawingTab(buildingId, floorId))
      })
  }
}

function setCurrentDrawingTabAPI (buildingId, floorId, currentDrawingTab) {
  const data = {
    'value': currentDrawingTab,
  }

  return {
    payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/currentDrawingTab`, data),
  }
}

export function fetchCurrentDrawingTab (buildingId, floorId) {

  return {
    type: floorPlanActionTypes.FETCH_CURRENT_DRAWING_TAB,
    payload: httpClient.get(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/currentDrawingTab`),
  }
}

function addArea (buildingId, floorId, areaDescription) {
  return {
    payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/area`, areaDescription),
  }
}

export function addAxisAndFetch (buildingId, floorId, x1, y1, x2, y2) {

  const points = [
    {
      'x': x1,
      'y': y1,
    },
    {
      'x': x2,
      'y': y2,
    },
  ]
  return function (dispatch) {
    dispatch(addAxis(buildingId, floorId, points))
      .then(() => {
        dispatch(fetchFloorPlanAnalytics(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function addAxis (buildingId, floorId, points) {
  return {
    payload: httpClient.post(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/axis`, points),
  }
}

export function deleteConnectorAndFetch (buildingId, floorId, connectorId) {
  return function (dispatch) {
    dispatch(deleteConnector(buildingId, floorId, connectorId))
      .then(() => {
        dispatch(fetchFloorPlanAnalytics(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function deleteConnector (buildingId, floorId, connectorId) {
  return {
    payload: httpClient.delete(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/connector/${connectorId}`),
  }
}

export function changeAreaType (areaId, areaType) {
  const buildingId = getBuildingId()
  const floorId = getFloorId()

  const area = {
    floorType: areaType,
  }

  return function (dispatch) {
    dispatch(changeAreaTypeAPI(buildingId, floorId, areaId, area))
      .then(() => {
        dispatch(fetchBuildings())
        dispatch(fetchFloorPlanAnalytics(buildingId, floorId))
        dispatch(fetchFloorPlan(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function changeAreaTypeAPI (buildingId, floorId, areaId, area) {
  return {
    payload: httpClient.patch(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/area/${areaId}`, area),
  }
}

export function deleteAreaAndFetch (buildingId, floorId, areaId) {
  return function (dispatch) {
    dispatch(deleteArea(buildingId, floorId, areaId))
      .then(() => {
        dispatch(fetchFloorPlanAnalytics(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function deleteArea (buildingId, floorId, areaId) {
  return {
    payload: httpClient.delete(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/area/${areaId}`),
  }
}

export function autoGenerateAxesInArea (buildingId, floorId, areaId) {
  return function (dispatch) {
    dispatch(autoGenerateAxesInAreaAPI(buildingId, floorId, areaId))
      .then(() => {
        dispatch(fetchFloorPlanAnalytics(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function autoGenerateAxesInAreaAPI (buildingId, floorId, areaId) {
  console.log("autogenerate axes")
  return {
    payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/area/${areaId}`),
  }
}

export function deleteAxesInArea (buildingId, floorId, areaId) {
  return function (dispatch) {
    dispatch(deleteAxesInAreaAPI(buildingId, floorId, areaId))
      .then(() => {
        dispatch(fetchFloorPlanAnalytics(buildingId, floorId))
      })
  }
}

function deleteAxesInAreaAPI (buildingId, floorId, areaId) {
  return {
    payload: httpClient.delete(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/area/${areaId}`),
  }
}

export function rotateCornerAndFetch (buildingId, floorId, connectorId) {
  return function (dispatch) {
    dispatch(updateConnectorRotation(buildingId, floorId, connectorId))
      .then(() => {
        dispatch(fetchFloorPlanAnalytics(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function updateConnectorRotation (buildingId, floorId, connectorId) {
  return {
    payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/connector/${connectorId}/rotation`),
  }
}

export function switchCenterZoneAndFetch (buildingId, floorId, axisId) {
  return function (dispatch) {
    dispatch(switchCenterZone(buildingId, floorId, axisId))
      .then(() => {
        dispatch(fetchFloorPlanAnalytics(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function switchCenterZone (buildingId, floorId, axisId) {
  return {
    payload: httpClient.put(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/axis/${axisId}/centerzone`),
  }
}

export function addBuilding () {
  return function (dispatch) {
    dispatch(addNewBuilding())
      .then(() => {
        dispatch(fetchBuildings())
          .then(() => {
            dispatch(fetchAppState())
              .then(({ value }) => {
                const { buildingId, floorId } = value.data
                dispatch(fetchFloorData(buildingId, floorId))
                dispatch(getUndoInformation(getProjectId()))
              })
          })
      })
  }
}

function addNewBuilding () {
  return {
    payload: httpClient.put(`${projectVariationApi()}/buildings`),
  }
}

export function duplicateFloorAndFetchBuildings (buildingId, floorId) {
  return function (dispatch) {
    dispatch(duplicateFloor(buildingId, floorId))
      .then(() => {
        dispatch(fetchBuildings())
        dispatch(fetchDepartmentAssignments(buildingId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function duplicateFloor (buildingId, floorId) {
  return {
    payload: httpClient.post(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/duplicate`),
  }
}

export function moveFloorUpAndFetchBuildings (buildingId, floorId) {
  return function (dispatch) {
    dispatch(moveFloorUp(buildingId, floorId))
      .then(() => {
        dispatch(fetchBuildings())
        dispatch(fetchDepartmentAssignments(buildingId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function moveFloorUp (buildingId, floorId) {
  return {
    payload: httpClient.post(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/moveUp`),
  }
}

export function moveFloorDownAndFetchBuildings (buildingId, floorId) {
  return function (dispatch) {
    dispatch(moveFloorDown(buildingId, floorId))
      .then(() => {
        dispatch(fetchBuildings())
        dispatch(fetchDepartmentAssignments(buildingId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function moveFloorDown (buildingId, floorId) {
  return {
    payload: httpClient.post(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/moveDown`),
  }
}

export function addFloorAndFetchBuildings (buildingId) {
  return function (dispatch) {
    dispatch(addNewFloor(buildingId))
      .then(() => {
        dispatch(fetchBuildings())
        dispatch(fetchDepartmentAssignments(buildingId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function addNewFloor (buildingId) {
  return {
    payload: httpClient.post(`${projectVariationApi()}/buildings/${buildingId}/floors`, {}),
  }
}

export function deleteBuildingAndFetch (buildingId) {
  return function (dispatch) {
    dispatch(deleteBuilding(buildingId))
      .then(() => {
        dispatch(fetchBuildings())
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function deleteBuilding (buildingId) {
  return {
    payload: httpClient.delete(`${projectVariationApi()}/buildings/${buildingId}`),
  }
}

export function deleteFloorAndFetchBuildings (buildingId, floorId) {
  return function (dispatch) {
    dispatch(deleteFloor(buildingId, floorId))
      .then(() => {
        dispatch(fetchBuildings())
        dispatch(fetchDepartmentAssignments(buildingId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function deleteFloor (buildingId, floorId) {
  return {
    payload: httpClient.delete(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}`),
  }
}

export function renameBuildingAndFetch (buildingId, name) {
  return function (dispatch) {
    dispatch(updateBuilding(buildingId, { name: name }))
      .then(() => {
        dispatch(fetchBuildings())
        dispatch(fetchDepartmentAssignments(buildingId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function updateBuilding (buildingId, building) {
  return {
    payload: httpClient.patch(`${projectVariationApi()}/buildings/${buildingId}`, building),
  }
}

export function renameFloorAndFetch (buildingId, floorId, name) {
  return function (dispatch) {
    dispatch(renameFloor(buildingId, floorId, { name: name }))
      .then(() => {
        dispatch(fetchBuildings())
        dispatch(fetchDepartmentAssignments(buildingId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function renameFloor (buildingId, floorId, floor) {
  return {
    payload: httpClient.patch(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}`, floor),
  }
}

export function fetchBase64ImageForFloorAPI (buildingId, floorId) {
  console.log("fetchImageee")
  return {
    type: floorPlanActionTypes.FETCH_BASE_64_IMAGE,
    payload: httpClient.get(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/image`),
  }
}

export function uploadDFXForFloor (buildingId, floorId, file) {
  return function (dispatch) {
    dispatch(uploadFloorDXF(buildingId, floorId, file))
      .then(() => {
        dispatch(fetchBuildings())
        dispatch(fetchFloorData(buildingId, floorId))
        dispatch(getUndoInformation(getProjectId()))
      })
  }
}

function uploadFloorDXF (buildingId, floorId, file) {
  let form = new FormData()
  form.append('file', file)
  return {
    payload: httpClient.post(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/dxf`, form),
  }
}



export function uploadIFC (buildingId, file) {
  return function (dispatch) {
    dispatch(uploadIFCApi(buildingId, file))
      .then(() => {
        dispatch(fetchBuildings())
      })
  }
}

function uploadIFCApi (buildingId, file) {
  let form = new FormData()
  form.append('file', file)
  return {
    payload: httpClient.post(`${projectVariationApi()}/buildings/${buildingId}/floors/ifc`, form),
  }
}

export function uploadGeometriesFloor (buildingId, floorId, file) {
  return function (dispatch) {
    dispatch(uploadGeometriesFloorAPI(buildingId, floorId, file))
      .then(() => {
        dispatch(fetchDrawing(buildingId, floorId))
      })
  }
}

export function uploadGeometriesFloorAPI (buildingId, floorId, file) {
  let form = new FormData()
  form.append('file', file)
  return {
    payload: httpClient.post(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/geometries`, form),
  }
}

export function fetchGeometriesFileFloor (buildingId, floorId) {
  return {
    type: floorPlanActionTypes.FETCH_FILE,
    payload: httpClient.getFile(`${projectVariationApi()}/buildings/${buildingId}/floors/${floorId}/geometries`),
  }
}

export function fetchDownloadFloor (floorId) {
  return {
    type: floorPlanActionTypes.FETCH_FILE,
    payload: httpClient.getFile(`${projectApi()}/floor/${floorId}/download`),
  }
}

export function loadDemoFloor () {
  const buildingId = getBuildingId()
  const floorId = getFloorId()

  return function (dispatch) {
    return dispatch(loadDemoFloorAPI(buildingId, floorId))
      .then(() => {
        return Promise.all([
        dispatch(fetchBuildings()),
        dispatch(fetchFloorData())
      ])
      })
  }
}

export function loadDemoFloorAPI (buildingId, floorId) {
  console.log("loadDemoFloorAPI")
  return {
    payload: httpClient.put(`${projectApi()}/building/${buildingId}/floor/${floorId}/demo`),
  }
}