import React from 'react'

const SvgInterfererCheck = props => (
  <svg width={12} height={12} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M6 0a6 6 0 100 12A6 6 0 006 0z" fill="#E7DFB7"/>
      <path
        d="M7.65 3.47a.462.462 0 01.841.37l-.033.075-2.36 4.277a.462.462 0 01-.642.173l-.07-.052-1.77-1.584a.462.462 0 01.548-.738l.067.05 1.338 1.197 2.08-3.769z"
        fill="#001D34"
      />
    </g>
  </svg>
)

export default SvgInterfererCheck
