import React from 'react'

const SvgMid = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill="#373B51" fillRule="nonzero">
      <path
        d="M10.395 14.09l.21-7.2h-1.44l.21 7.2h1.02zm-.51 3.075c-.525 0-.885-.405-.885-.93 0-.54.375-.93.885-.93.54 0 .885.39.885.93 0 .525-.345.93-.885.93zM14.395 14.09l.21-7.2h-1.44l.21 7.2h1.02zm-.51 3.075c-.525 0-.885-.405-.885-.93 0-.54.375-.93.885-.93.54 0 .885.39.885.93 0 .525-.345.93-.885.93z"/>
    </g>
  </svg>
)

export default SvgMid
