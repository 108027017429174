import React from 'react'

const SvgToolboxZoomIcon = props => (
  <svg className={props.className} viewBox="0 0 32 32">
    <path d="M0 0h32v32H0z" data-name="Rechteck 713" fill="none"/>
    <path
      d="M23.19 25.31l-5.306-5.305a7.457 7.457 0 0 1-4.134 1.245 7.509 7.509 0 0 1-7.5-7.5 7.508 7.508 0 0 1 7.5-7.5 7.509 7.509 0 0 1 7.5 7.5 7.457 7.457 0 0 1-1.245 4.134l5.306 5.306a1.5 1.5 0 0 1 0 2.121 1.5 1.5 0 0 1-1.061.439 1.493 1.493 0 0 1-1.06-.44zM9.25 13.75a4.505 4.505 0 0 0 4.5 4.5 4.505 4.505 0 0 0 4.5-4.5 4.505 4.505 0 0 0-4.5-4.5 4.505 4.505 0 0 0-4.5 4.5z"
      data-name="Pfad 1046"
      fill="#8f939d"
    />
  </svg>
)

export default SvgToolboxZoomIcon
