import * as React from 'react'

const SvgDrawDeleteActive = props =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient
        id="prefix__a"
        x1="6.444%"
        x2="93.556%"
        y1="100%"
        y2="0%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
      <linearGradient
        id="prefix__b"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect width={32} height={32} fill="#00293B" opacity={0.08} rx={2}/>
      <path stroke="#D14720" strokeLinecap="round" d="M6 10h20"/>
      <path
        fill="url(#prefix__a)"
        stroke="#D14720"
        d="M8.8 10.2h14v13.602c0 .772-.62 1.398-1.394 1.398H10.194c-.77 0-1.394-.625-1.394-1.398V10.2z"
        transform="matrix(-1 0 0 1 31.6 0)"
      />
      <path
        fill="url(#prefix__b)"
        stroke="#D14720"
        d="M11.5 6h9v2.601c0 .773-.619 1.399-1.406 1.399h-6.188A1.402 1.402 0 0111.5 8.601V6z"
        transform="rotate(180 16 8)"
      />
      <path
        stroke="#D14720"
        strokeLinecap="round"
        d="M16 13.7v8.376M12.4 13.7v8.376m7-8.376v8.376"
      />
    </g>
  </svg>

export default SvgDrawDeleteActive