import {materialTypes} from '../common/MaterialTypes'
import {getMaterial} from '../common/Materials'

export const magicGuideLineConfig = {
    edge: {
        innerActiveMaterial: getMaterial(materialTypes.transparent),
        innerInactiveMaterial: getMaterial(materialTypes.transparent),
        outerActiveMaterial: getMaterial(materialTypes.pink),
        outerInactiveMaterial: getMaterial(materialTypes.transparent),
        innerSize: 0.05,
        outerSize: 0.05,
    }
}