import * as React from 'react'

const SvgImportIcon = props => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 14.496a.5.5 0 0 1 .5-.5h.261a2.257 2.257 0 0 0 2.347-2.163 2.256 2.256 0 0 0-2.167-2.342h-.43l-.05-.41a3.508 3.508 0 0 0-3.481-3.046A3.508 3.508 0 0 0 8.5 9.08l-.01.41h-.431a2.256 2.256 0 0 0-2.167 2.342 2.257 2.257 0 0 0 2.347 2.162h.26a.5.5 0 1 1 0 1.001h-.26a3.257 3.257 0 0 1-3.222-2.926 3.254 3.254 0 0 1 2.57-3.51A4.511 4.511 0 0 1 12 5a4.511 4.511 0 0 1 4.413 3.56 3.254 3.254 0 0 1 2.57 3.51 3.257 3.257 0 0 1-3.222 2.926h-.26a.5.5 0 0 1-.501-.5Zm-2.499-2.587v6.59a.501.501 0 1 1-1.002 0v-6.59l-.945.94a.501.501 0 0 1-.708-.001.498.498 0 0 1 0-.706l1.448-1.445a1 1 0 0 1 1.412 0l1.448 1.445a.498.498 0 0 1 0 .706.501.501 0 0 1-.708 0l-.945-.939Z"
      fill="#00293B"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgImportIcon