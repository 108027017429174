import store from '../store'
import { getSelectedBuildingId, getSelectedFloorId } from './redux/appstate/appstate-reducer'
import { getFirstVariation } from './redux/variations/variations-reducer'

/**
 * Sets the given value as native value of the given native input element.
 *
 * @param inputElement a native input element
 * @param value the value to set
 */
export function setNativeValue (inputElement, value) {
  const valueSetter = Object.getOwnPropertyDescriptor(inputElement, 'value').set
  const prototype = Object.getPrototypeOf(inputElement)
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(inputElement, value)
  } else {
    valueSetter.call(inputElement, value)
  }
}

/**
 * Returns a copy of the given routing path with all dynamic parts replaced by the given params
 *
 * Dynamic parts in the path are marked with double quotes (Pattern used in React Router):
 *
 * "/users/:id"
 *
 * In this example ":id" will be replaced by the param "id" if it is part of the params object
 *
 * @param path a React Router path with dynamic parts
 * @param params an object with the params
 * @returns copy of the given routing path with all dynamic parts replaced by the given params
 */
export function getPathWithParams (path, params) {
  return path.replace(/(:\w+)/g, (matchedParam) => {
    return params[matchedParam]
  })
}

/**
 * Returns the value of the given query param extracted from the locations query string string
 *
 * @param location the React Router location object
 * @param queryParamName the name of the query param to return
 * @returns the value of the query param or null if not found
 */
export function extractQueryParam (search, queryParamName) {
  const queryParams = new URLSearchParams(search)
  return queryParams.get(queryParamName) || null
}

export function getCurrentVariationId (path) {
  let v = null
    if(path.search)
      v = extractQueryParam(path.search, 'variation')
  return v
}

export function projectApi (projectId) {
  return '/api/projects/' + (projectId ? projectId : getCurrentProjectId(store.getState()))
}

function extractProjectId(url) {
  const regex = /projects\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\//;
  const match = url.match(regex);
  return match ? match[1] : null;
}

export function getCurrentProjectId (state) {

  return state.projects.project ? state.projects.project.id : extractProjectId(window.location.href)
  //return extractProjectId(window.location.href)
}


export function projectVariationApi () {
  let variationId = store.getState().variations.activeVariationId
  if (!variationId) {
    variationId = getFirstVariation(store.getState()).id
  }

  return projectApi() + '/variations/' + variationId
}

export function getProjectId () {
  return getCurrentProjectId(store.getState())
}

export function getBuildingId () {
  return getSelectedBuildingId(store.getState())
}

export function getFloorId () {
  return getSelectedFloorId(store.getState())
}

/**
 * Return the style as object needed to add the department color
 * to the left border of the html element (e.g. a list item) the returned style is applied on
 *
 * @param color the color object of the department
 * @param disabled if the style should be in disabled mode
 * @returns {{borderLeftColor: string}|{}}
 */
export function getDepartmentListItemStyle (color, disabled) {

  if (color) {

    let rgba

    if (disabled) {
      rgba = getRgbaColor(color, 0.5)
    } else {
      rgba = getRgbaColor(color)
    }
    return {
      borderLeftColor: `rgba(${rgba})`,
    }

  }

  return {}
}

export function getRgbaColor (color, alphaModifier = 1) {
  if (color)
    return [255 * color.red, 255 * color.green, 255 * color.blue, color.alpha * alphaModifier]
  return {}
}

export function getHSLFromHex (color) {
  const rgb = getRGBFromHex(color)

  const r = rgb.r / 255
  const g = rgb.g / 255
  const b = rgb.b / 255

  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0

  if (delta === 0)
    h = 0
  // Red is max
  else if (cmax === r)
    h = ((g - b) / delta) % 6
  // Green is max
  else if (cmax === g)
    h = (b - r) / delta + 2
  // Blue is max
  else
    h = (r - g) / delta + 4

  h = Math.round(h * 60)

  // Make negative hues positive behind 360°
  if (h < 0)
    h += 360

  return { h: h, s: s, l: l, a: 1 }
}

export function getRGBFromHex (hex) {
  hex = hex.replace('#', '')

  const aRgbHex = hex.match(/.{1,2}/g)

  const r = parseInt(aRgbHex[0], 16)
  const g = parseInt(aRgbHex[1], 16)
  const b = parseInt(aRgbHex[2], 16)

  return { r: r, g: g, b: b }
}

export function getHexColor (colorArray, opacity = true) {
  if (!colorArray || colorArray.length < 3) {
    return
  }

  let colorInHex = ''
  for (let i = 0; i < colorArray.length - 1; i++) {
    let numValue = colorArray[i].toString()
      .split('.')[0]
    let convertedNumber = Number(numValue)
      .toString(16)
    colorInHex = (convertedNumber.length % 2) > 0 ? colorInHex.concat('0' + convertedNumber) : colorInHex.concat(convertedNumber)
  }

  if (colorArray.length > 3 && opacity)
    colorInHex = colorInHex.concat(parseInt((parseFloat(colorArray[3]) * 255).toString())
      .toString(16))

  return '#'.concat(colorInHex)
}

export function getHexColorFromRGB (color, opacity = true) {
  if (!color)
    return

  let colorArray = []

  colorArray.push(color.red * 255)
  colorArray.push(color.green * 255)
  colorArray.push(color.blue * 255)
  colorArray.push(color.alpha)

  return getHexColor(colorArray, opacity)
}

/**
 * Converts a flat list to a tree list
 *
 * @param list
 * @param parentIdentifierPropertyName the name of the property in the child nodes that identifies its parent
 * @param childrenPropertyName the property name ot the array of children added to the parent
 * @returns {[]} a tree list
 */
export function listToTree (list, parentIdentifierPropertyName, childrenPropertyName) {
  let tree = [], mappedArr = {}, mappedNode

  // Create a hash table.
  for (let i = 0, len = list.length; i < len; i++) {
    let node = list[i]
    mappedArr[node.id] = node
    mappedArr[node.id][childrenPropertyName] = []
  }

  for (let id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      mappedNode = mappedArr[id]
      // If the element is not at the root level, add it to its parent list of children.
      if (mappedNode[parentIdentifierPropertyName]) {
        if (mappedArr[mappedNode[parentIdentifierPropertyName]])
          mappedArr[mappedNode[parentIdentifierPropertyName]][childrenPropertyName].push(mappedNode)
      }
      // If the element is at the root level, add it to first level elements list.
      else {
        tree.push(mappedNode)
      }
    }
  }
  return tree
}

export function updateObject (targetObject, obj) {
  Object.keys(obj)
    .forEach(key => {
      if ('object' === typeof obj[key] && !Array.isArray(obj[key])) {
        if (!targetObject[key]) {
          targetObject[key] = {}
        }
        updateObject(targetObject[key], obj[key])
      } else if (('string' === typeof obj[key] || 'number' === typeof obj[key])) {
        targetObject[key] = obj[key]
      }
    })

  return targetObject
}

export function recursiveFlatMap (rootList, key, result = []) {
  result.push(...rootList)

  const children = rootList.flatMap(unit => unit[key])

  if (children.length > 0) {
    recursiveFlatMap(children, key, result)
  }

  return result
}

export function recursiveFindFlatMap (rootList, key, find = () => {}) {
  const result = rootList.find(find)

  if (result)
    return result

  const children = rootList.flatMap(unit => unit[key])

  if (children.length) {
    const result = recursiveFindFlatMap(children, key, find)
    if (result)
      return result
  }
}
