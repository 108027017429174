import React, { Component } from 'react'
import './PatternAreaSettings.scss'
import { Field, Form, Formik } from 'formik'
import AutoSave from '../../../common/components/AutoSave'
import MenuSection from '../../../common/components/MenuSection'
import ToggleSwitch from '../../../common/components/ToggleSwitch'
import { updatePatternArea } from '../../actions/actions'
import { connect } from 'react-redux'
import NumberInput from '../../../common/components/NumberInput'
import Button from '../../../common/components/Button'

const plainTextUnitType = {
  UNKNOWN: '?',
  MAIN: 'main',
  FILL: 'fill',
  WBFRONT: 'workbench front',
  WBBACK: 'workbench back',
  CORRIDOR: 'corridor',
  FACADE: 'facade',
  HSEPARATOR: 'side separator',
  VSEPARATOR: 'face separator',
  VSEPARATORFILL: 'face separator fill',
  VENDSEPARATOR: 'back separator',
  VENDSEPARATORFILL: 'back separator fill',
  HSEPARATORCROSS: 'cross separator',
}

class PatternAreaSettings extends Component {

  render () {

    return (
      <div id={'pattern-area-settings'}>
        <Formik initialValues={this.props.pattern[this.props.area]} enableReinitialize>
          <Form>
            <AutoSave delay="0" onSave={(values) => this.handleChange(values)}/>
            <MenuSection title={plainTextUnitType[this.props.pattern[this.props.area].unitType]}>
              <MenuSection>
                <Field name={'fixedWidth'} component={ToggleSwitch} label={'Fixed Width'}/>
                {this.props.pattern[this.props.area].fixedWidth ? <Field name={'defaultWidth'} component={NumberInput} label={'Width'} step={0.1}/> : null}
                <Field name={'fixedDepth'} component={ToggleSwitch} label={'Fixed Depth'}/>
                {this.props.pattern[this.props.area].fixedDepth ? <Field name={'defaultDepth'} component={NumberInput} label={'Depth'} step={0.1}/> : null}
              </MenuSection>

              <MenuSection>
                <Field name={'hasHorizontalWall'} component={ToggleSwitch} label={'Horizontal Wall'}/>
                <Field name={'hasVerticalWall'} component={ToggleSwitch} label={'Vertical Wall'}/>
              </MenuSection>

              <MenuSection>
                <Field name={'numberWorkplaces'} component={NumberInput} label={'Workplaces'} digits={0} step={1}/>
              </MenuSection>
              <MenuSection className={'button-section'}>
                <Button variant={'contained'} onClick={() => this.props.close()}>Confirm</Button>
              </MenuSection>
            </MenuSection>
          </Form>
        </Formik>
      </div>
    )
  }

  handleChange (values) {

    this.props.updatePatternArea(values, this.props.area, this.props.areaWidth, this.props.areaDepth)

  }
}

let mapStateToProps = (state) => {
  return {
    pattern: state.pattern.patternPlan,

  }
}

let mapDispatchToProps = {

  updatePatternArea: updatePatternArea,

}

export default connect(mapStateToProps, mapDispatchToProps)(PatternAreaSettings)