import React from 'react'

const SvgDrawNavbarNumber1Focus = props =>
  <svg
    viewBox="0 0 20 20"
    width={20} height={20}
    className={props.className}
  >
    <g fill="none" fillRule="evenodd">
      <circle stroke="#FFF" strokeWidth={2} cx={10} cy={10} r={9}/>
      <path
        d="M11 13.784V6.2H9.894c-.276.792-.756 1.152-1.812 1.152v.984H9.5v5.448H11z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>

export default SvgDrawNavbarNumber1Focus