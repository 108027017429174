import * as React from 'react'

const SvgAddFloor = props =>
  (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(11 11.5)">
          <circle cx={5} cy={5} r={5} fill="#00293B"/>
          <path d="M7.5 5h-5M5 2.5v5" stroke="#FFF" strokeLinecap="round"/>
        </g>
        <path
          fill="#00293B"
          d="M10 4.636L16.364 8l-2.366 1.249 2.366 1.251-2.366 1.249L16.364 13 10 16.364 3.636 13 6 11.749 3.636 10.5 6 9.249 3.636 8 10 4.636zm-2.93 7.678L5.774 13 10 15.234 14.225 13l-1.296-.685L10 13.864l-2.93-1.55zm0-2.5l-1.296.686L10 12.734l4.224-2.234-1.296-.685L10 11.364l-2.93-1.55zM10 5.766L5.773 8 10 10.234 14.224 8 10 5.766z"
        />
      </g>
    </svg>
  )

export default SvgAddFloor
