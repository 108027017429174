import React, { Component } from 'react'

import { Field, Form, Formik } from 'formik'
import Dialog, { DialogButtonGroup, DialogContent } from '../../../common/components/Dialog'
import Button from '../../../common/components/Button'

import Text from '../../../common/components/Text'

import './BuildingEditDialog.scss'
import { withTranslation } from 'react-i18next'

class RenameDialog extends Component {

  render () {
    const{t} = this.props

    return (
      <Dialog className={'building-edit-dialog'}
              title={t('edit') + ' ' + this.props.type}
              onCancel={() => this.props.onClose()}>
        <Formik initialValues={this.props} enableReinitialize onSubmit={(values) => this.handleRename(values.name)}>
          <Form>

            <DialogContent>
              <Text component={'label'} htmlFor={'building-name-input'}>
                {this.props.type + ' ' + t('name')}
              </Text>
              <Field className={'name-input'} name={'name'} onKeyPress={(event) => {if (event.key === 'Enter') event.preventDefault()}}/>
            </DialogContent>
            <DialogButtonGroup>
              <Button onClick={() => this.props.onClose()}>{t('cancel')}</Button>
              <Button type="submit" variant={'contained'}>{t('confirm')}</Button>
            </DialogButtonGroup>

          </Form>
        </Formik>
      </Dialog>
    )
  }

  handleRename (name) {
    this.props.onClose()
    this.props.rename(name)
  }

}

export default withTranslation() (RenameDialog)