import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import Section from '../../common/components/layout/Section'
import SectionHeader from '../../common/components/layout/SectionHeader'
import { updateApplicationSettings } from '../../common/redux/settings/settings-actions'
import { Manager, Popper, Reference } from 'react-popper'

import NumberInput from '../../common/components/NumberInput'

import ToggleSwitch from '../../common/components/ToggleSwitch'
import IconButton from '../../common/components/IconButton'
import SpacingInfo from './SpacingInfo'
import Button from '../../common/components/Button'

import './Spacing.scss'
import { iconTypes } from '../../common/components/icons/IconFactory'

class Spacing extends Component {

  constructor (props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }

  render () {

    return (

      <Section className="spacing-information">
        <SectionHeader className={'spacing-section-header'} title={'Clearances & Spacing'}>
          <Manager>
            <div className={'info-image-popper-icon'}>
              <Reference>
                {({ ref }) => (
                  <div ref={ref} className={'info-expandable'}>
                    <IconButton className={'expand-button'} icon={iconTypes.info} onClick={() => this.handleExpandButtonClicked()}/>
                  </div>
                )}
              </Reference>
              {this.state.expanded &&
              <Popper placement="bottom-start">
                {({ ref, style, placement }) => (
                  <div ref={ref} style={{ ...style, zIndex: 3 }} data-placement={placement}>
                    <SpacingInfo onClose={() => this.handleExpandButtonClicked()}/>
                  </div>
                )}
              </Popper>}
            </div>
          </Manager>
        </SectionHeader>

        <Formik initialValues={{ ...this.props.settings }}
                onSubmit={(values) => this.handleSettingChanges(values)}
                enableReinitialize>
          <Form className={'spacing-settings-form'}>

            <Field name={'workingDepth'} component={NumberInput} label={'Working depth'} step={0.1}/>
            <Field name={'walkDistance'} component={NumberInput} label={'Walk Distance'} step={0.1}/>
            <Field name={'walkDistanceBtB'} component={NumberInput} label={'Walk Distance back to back'} step={0.1}/>
            <Field name={'distanceToFacade'} component={NumberInput} label={'Clearance to facade'} step={0.1}/>

            <div className={'walls-slider-zone'}>
              <Field name={'allowWallsInWindows'} component={ToggleSwitch} label={'Allow walls in windows'}/>
            </div>

            <div className={'spacing-form-buttons'}>
              <Button type="reset">Discard</Button>
              <Button type="submit" variant={'contained'}>Confirm</Button>
            </div>

          </Form>
        </Formik>

      </Section>
    )
  }

  handleExpandButtonClicked () {
    this.setState({ expanded: !this.state.expanded })
  }

  handleSettingChanges (values) {
    this.props.updateApplicationSettings(values)
  }
}

let mapStateToProps = (state) => {
  return {
    settings: state.settings.appSettings,
  }
}

let mapDispatchToProps = {
  updateApplicationSettings: updateApplicationSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(Spacing)