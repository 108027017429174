import React from 'react'
import './Section.scss'

function Section (props) {

  let styleClass = props.className ? ' ' + props.className : ''

  return (
    <div className={'section' + styleClass}>
      {props.children}
    </div>
  )
}

export default Section