import React from 'react'

import EditableTable from '../../common/components/EditableTable'
import ToggleSwitch from '../../common/components/ToggleSwitch'

import IconButton from '../../common/components/IconButton'

import TableAppliedRulesSelectButton from './selects/TableAppliedRulesSelectButton'

import './ApplicationProfilesTable.scss'
import NumberInput from '../../common/components/NumberInput'
import { iconTypes } from '../../common/components/icons/IconFactory'
import { Field, Form, Formik } from 'formik'

function ApplicationProfilesTable ({ data, onUnitChange }) {

  function isNotApplicationProfil ({ row: { depth } }) {
    return depth > 0
  }

  const columns = React.useMemo(

    () => [
      {
        // Expander column
        Header: () => null, // No header
        id: 'expander', // Expander Column ID
        className: 'application-profiles-expander-column',
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getExpandedToggleProps prop getter
          // to build the toggle for expanding a row
          row.getCanExpand() ? (
            <div className='expander-icon' style={{
              // Use the row.depth property and paddingLeft to
              // indicate the depth of the row
              paddingLeft: `${row.depth * 0.9}rem`,
            }}>
              <IconButton icon={row.getIsExpanded() ? iconTypes.triangleDown : iconTypes.triangle}
                          onClick={() => {
                            row.toggleExpanded(!row.getIsExpanded())
                          }}
              />
            </div>
          ) : null,
      },
      {
        Header: 'Application Style Name',
        className: 'application-style-name-column',
        id: 'name',
        accessor: (row) => row.categoryName ? row.categoryName : row.name,
        showCell: (row) => !isNotApplicationProfil(row),
        Cell: ({ row }) => <Formik initialValues={row.original} enableReinitialize>
          <Form>
            <Field name={'name'} type={'text'} onBlur={(event) => onUnitChange(row.original.uuid, null, { name: event.target.value })}/>
          </Form>
        </Formik>,
      },
      {
        Header: 'Application Type Name',
        className: 'application-type-name-column',
        id: 'categoryName',
        accessor: (row) => row.categoryName ? row.categoryName : row.name,
        showCell: (row) => isNotApplicationProfil(row),
        Cell: ({ row }) => <span>{row.original.categoryName}</span>,

      },
      {
        Header: 'Applied Rule',
        className: 'application-profiles-selection-column',
        inputType: 'select',
        id: 'applyingRule',
        accessor: (row) => row.placing ? row.placing : null,
        showCell: (data) => isNotApplicationProfil(data),
        Cell: ({ row }) => <TableAppliedRulesSelectButton value={row.original.placing}
                                                                                              onChange={(value) => onUnitChange(null, row.original.id, value)}/>,
      },
      {
        Header: 'Value',
        id : 'value',
        accessor: (row) => row.value ? row.value : 0,
        className: 'application-profiles-number-column',
        showCell: (data) => isNotApplicationProfil(data),
        Cell: ({ row }) => <NumberInput value={row.original.value}
                                                                            step={1}
                                                                            digits={0}
                                                                            onChange={(value) => onUnitChange(null, row.original.id, { value: value })}/>,
      },
      {
        Header: 'Exceed if possible',
        accessor: (row) => row.exceed ? row.exceed : false,
        className: 'application-profiles-toggle-column',
        id: 'exceed',
        showCell: (data) => isNotApplicationProfil(data),
        Cell: ({ row, cell: { value }, onCellValueChange }) => <ToggleSwitch value={row.original.exceed}
                                                                             id={row.original.id}
                                                                             onChange={() => onUnitChange(null, row.original.id, { exceed: !value })}/>,
      },

    ],
    [],
  )

  return (
    <EditableTable className='application-profiles-units-table'
                   columns={columns}
                   data={data}
                   getSubRows={(row) => row.wishes || []}

    />
  )

}

export default ApplicationProfilesTable