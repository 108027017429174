import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, Form, Formik } from 'formik'

import { updateProject } from '../../common/redux/projects/projects-actions'

import Section from '../../common/components/layout/Section'
import SectionHeader from '../../common/components/layout/SectionHeader'
import Button from '../../common/components/Button'
import SelectButton from '../../common/components/SelectButton'
import Text from '../../common/components/Text'
import { Scrollbar } from 'react-scrollbars-custom'

import './ProjectInfo.scss'

class ProjectInfo extends Component {

  render () {
    let industryOptions = this.props.industryAttributes.map(attribute => ({
      label: attribute.name,
      value: attribute.name,
    }))

    let countryOptions = this.props.countries.map(country => ({
      label: country.name,
      value: country.code,
    }))

    const initialValues = { ...this.props.project }

    return (

      <Scrollbar>
        <Section className="project-information">
          <SectionHeader title={'Project Information'}/>
          <Formik initialValues={initialValues}
                  onSubmit={(values) => this.handleUpdateProject(this.props.project.id, values)}
                  enableReinitialize>

            <Form className='project-information-form'>
              <Text outerClassName={'title'}>Project Name</Text>
              <Field type="text" name="name" placeholder="Project Name"/>
              <Text outerClassName={'title'}>Client Name</Text>
              <Field type="text" name="companyName" placeholder="Client Name"/>
              <Text outerClassName={'title'}>Contact Person Haworth</Text>
              <Field type="text" name="projectManager" placeholder="Project Manager"/>
              <Text outerClassName={'title'}>E-Mail</Text>
              <Field type="email" name="mail" placeholder="Email"/>
              <Text outerClassName={'title'}>Phone-Number</Text>
              <Field type="tel" name="phone" placeholder="Phone"/>
              <Text outerClassName={'title'}>Industry</Text>
              <Field name="industry"
                     options={industryOptions}
                     component={SelectButton}/>
              <Text outerClassName={'title'}>Country</Text>
              <Field name="country"
                     options={countryOptions}
                     component={SelectButton}/>
              <Text outerClassName={'title'}>Additional Notice</Text>

              <Field name="note" render={({ field }) => (
                <textarea {...field} placeholder="Notes"/>
              )}/>

              <div className="project-information-form-buttons">
                <Button type="reset">Cancel</Button>
                <Button type="submit" variant={'contained'}>Confirm</Button>
              </div>

            </Form>
          </Formik>
        </Section>
      </Scrollbar>
    )
  }

  handleUpdateProject (id, project) {
    this.props.updateProject(id, project)
  }

}

let mapStateToProps = (state) => {
  return {
    project: state.projects.project,
    industryAttributes: state.industryAttributes.industryAttributes,
    countries: state.countries.countries,
  }
}

let mapDispatchToProps = {
  updateProject: updateProject,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectInfo)