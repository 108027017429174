import * as React from 'react'

const DrawZoneNew = props =>
  <svg width={32} height={32} viewBox="0 0 32 32">
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#c5cee3"/>
        <stop offset="100%" stopColor="#c5cee3"/>
      </linearGradient>
      <linearGradient
        id="prefix__b"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#c5cee3"/>
        <stop offset="100%" stopColor="#c5cee3"/>
      </linearGradient>
      <linearGradient
        id="prefix__c"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#c5cee3"/>
        <stop offset="100%" stopColor="#c5cee3"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g stroke="#22293b" strokeLinejoin="round">
        <path
          fill="#EBEEF0"
          d="M9.5 8h6a3 3 0 013 3v3h0-12v-3a3 3 0 013-3z"
          transform="translate(4 9)"
        />
        <path
          fill="#EBEEF0"
          d="M21.425 6a3 3 0 013 3v1h-3.956a4.003 4.003 0 00-2.902-3.356A2.988 2.988 0 0119.425 6h2z"
          transform="translate(4 9)"
        />
        <path
          fill="#EBEEF0"
          d="M4.736 6a3 3 0 013 3v1H3.781A4.006 4.006 0 00.879 6.644 2.987 2.987 0 012.736 6h2z"
          transform="matrix(-1 0 0 1 12.236 9)"
        />
        <path
          fill="#EBEEF0"
          d="M20 .5c1.094 0 2 .906 2 2 0 1.094-.906 2-2 2-1.094 0-2-.906-2-2 0-1.094.868-2 2-2z"
          transform="translate(4 9)"
        />
        <path
          fill="#EBEEF0"
          d="M5 .5c1.094 0 2 .906 2 2 0 1.094-.906 2-2 2-1.094 0-2-.906-2-2 0-1.094.868-2 2-2z"
          transform="matrix(-1 0 0 1 14 9)"
        />
        <path
          fill="#EBEEF0"
          d="M12.5 0c1.642 0 3 1.358 3 3s-1.358 3-3 3-3-1.358-3-3 1.302-3 3-3z"
          transform="translate(4 9)"
        />
      </g>
    </g>
  </svg>

export default DrawZoneNew