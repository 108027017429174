import React from 'react'

const SvgExportFile = props => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#00293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.778 4v15h11.444V9l-5-5z"
      />
      <path
        stroke="#00293B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.222 4v5h5"
      />
      <circle cx={16} cy={16} r={5} fill="#00293B"/>
      <g stroke="#FFF" strokeLinecap="round" strokeLinejoin="round">
        <path d="M18 15.5l-2-2-2 2M16 18.5v-5"/>
      </g>
    </g>
  </svg>
)

export default SvgExportFile