import React from 'react'

const SvgAppCreatorOthersIconActive = props => (
  <svg className={props.className} viewBox="0 0 54 54">
    <path d="M0 0h54v54H0z" data-name="Rechteck 426" fill="none"/>
    <path
      d="M21.952 28.907a1.953 1.953 0 01-1.953-1.954A1.953 1.953 0 0121.952 25a1.953 1.953 0 011.953 1.953 1.953 1.953 0 01-1.953 1.954zm4.883 0a1.953 1.953 0 01-1.953-1.953A1.953 1.953 0 0126.835 25a1.953 1.953 0 011.953 1.953 1.953 1.953 0 01-1.953 1.954zm4.883 0a1.953 1.953 0 01-1.953-1.953A1.953 1.953 0 0131.719 25a1.953 1.953 0 011.953 1.953 1.953 1.953 0 01-1.953 1.954z"
      data-name="Pfad 923"
      fill="#454b5a"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgAppCreatorOthersIconActive
