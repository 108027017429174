import * as React from 'react'
const SvgInnerWallsInactive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="13" r="1" fill="#00293B"/>
    <circle cx="15" cy="11" r="1" fill="#00293B"/>
    <circle cx="15" cy="17" r="1" fill="#00293B"/>
    <circle cx="11" cy="21" r="1" fill="#00293B"/>
    <circle cx="19" cy="21" r="1" fill="#00293B"/>
    <circle cx="21" cy="16" r="1" fill="#00293B"/>
    <circle cx="21" cy="11" r="1" fill="#00293B"/>
    <rect x="6" y="6" width="20" height="20" stroke="#00293B"/>
  </svg>
)

export default SvgInnerWallsInactive
