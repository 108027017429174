import React from 'react'

const SvgCustomIndicator = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      d="M8.25 18.995l-.005-.003c-.947-.554-1.314-1.7-.82-2.555.19-.329.484-.578.844-.72l-2.207-1.274c-.395-.229-.548-.707-.341-1.067l1.274-2.208a.58.58 0 011.033.057l.026.06h0c.12.273.334.51.604.665.312.18.669.241 1.003.17.33-.07.599-.259.757-.533.33-.57.076-1.336-.565-1.707a1.427 1.427 0 00-.876-.19l-.066.007a.58.58 0 01-.566-.866h0l1.274-2.206a.718.718 0 01.482-.341.867.867 0 01.611.101L12.92 7.66c-.057-.383.011-.76.202-1.09.242-.419.65-.708 1.15-.813a2.1 2.1 0 011.47.243l.004.003.008.004.014.008c.45.268.782.674.936 1.146.158.486.112.984-.13 1.402-.19.33-.482.578-.842.72l2.207 1.274c.189.11.329.28.394.48a.717.717 0 01-.054.587l-1.274 2.207a.58.58 0 01-1.033-.058l-.026-.06h0a1.427 1.427 0 00-.604-.663c-.641-.37-1.43-.208-1.76.363a1.125 1.125 0 00-.084.923c.106.325.337.603.65.783.27.156.58.224.876.191l.066-.007a.58.58 0 01.565.865h0l-1.274 2.207c-.208.36-.699.467-1.094.239l-2.208-1.274c.057.383-.011.761-.201 1.09-.494.856-1.67 1.111-2.62.57l-.008-.005z"
      fill={props.fillColor}
      fillRule="nonzero"
      stroke={props.color}
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgCustomIndicator
