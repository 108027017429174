import React from 'react'

const SvgFloorplanIconActive = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      d="M10 16.5v-3.375M13 7.5v2.25m4.5 2.813h-4M6.5 7.5h11v9h-11z"
      fill="none"
      fillRule="evenodd"
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);

export default SvgFloorplanIconActive
