import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Text, { textTypes } from '../../../common/components/Text';
import IconButton from '../../../common/components/IconButton';
import { iconTypes } from '../../../common/components/icons/IconFactory';
import { viewNames } from '../../../common/globalConstants';
import Button, { buttonVariants } from '../../../common/components/Button';
import { IconWrapper } from '../../../common/components/IconWrapper';
import { updateFrontendLocation } from '../../../common/redux/frontendLocation/frontend-location-action';

import './TestFittingInterferer.scss';
import { useParams } from 'react-router'


function TestFittingInterferer () {

  const [showInterferer, setShowInterferer] = useState(false);
  const [interfererTimeout, setInterfererTimeout] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectId } = useParams();


  useEffect(() => {
    setInterfererTimeout(setTimeout(() => setShowInterferer(true), 45000));
  }, []);

  const handleOpenLink = () => {
    navigate(`/projects/${projectId}/` + viewNames.FLOORPLAN);
    dispatch(updateFrontendLocation('FLOORPLAN', 'TESTFIT'));
  };

  return (
    !showInterferer ?
      <div className={'interferer-container-small'} onClick={() => {
        setShowInterferer(true)
        if (interfererTimeout)
          clearTimeout(interfererTimeout)
      }}>
        <div className={'interferer-content-small'}>
          <Text component={textTypes.subTitle} outerClassName={'content-title'}>
            Try CoDesigner Pro
          </Text>
        </div>
      </div> :
      <div className={'interferer-container-big'}>
        <div className={'interferer-content-big'}>
          <div className={'content-header'}>
            <div className={'button-section'}>
              <IconButton icon={iconTypes.close} onClick={() => setShowInterferer(false)}/>
            </div>
            <div className={'text-section'}>
              <Text component={textTypes.numberTitle}>Try</Text>
              <Text component={textTypes.numberTitle}>CoDesigner Pro</Text>
            </div>
          </div>
          <div className={'content-body'}>
            <Text outerClassName={'info-text'} component={textTypes.paragraph} multiline>Take advantages of additional control over your floorplan layout:</Text>

            {renderItems()}

            <Text component={textTypes.link} center onClick={() => window.open('https://www.haworth.com/eu/de/expertise/services/codesigner.html', '_blank')}>any many more ...</Text>

            <Button className={'redirect-button'} onClick={() => handleOpenLink()} variant={buttonVariants.greenFilled}>Try CoDesigner Pro</Button>
          </div>
        </div>
      </div>
  )

  function renderItems () {
    const items = [
      'Change space between Patterns',
      'Add or change Furniture Modules',
      'Export as PDF, IFC, DXF',
      'Add Floors and Buildings',
      'Rearrange Departments',
      'Create 3+ variants',
    ]

    return (
      <div className={'info-item-container'}>
        {
          items.map((item, index) => (
            <div className={'info-item'} key={index}>
              <IconWrapper name={iconTypes.interfererCheck}/>
              <Text component={textTypes.paragraph}>{item}</Text>
            </div>
          ))}
      </div>)
  }


}


export default TestFittingInterferer