import React from 'react'
import { Manager, Popper, Reference } from 'react-popper'

import Text from '../../common/components/Text'
import { iconTypes } from '../../common/components/icons/IconFactory'
import { IconWrapper } from '../../common/components/IconWrapper'

import './ListSubMenuItem.scss'

export default function ListSubMenuItem ({ children, title, selectedMenuItem, className, onMouseEnter }) {
  const isOpen = selectedMenuItem === title

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <li className={'list-menu-item ' + className}
              role={'button'}
              onMouseEnter={() => onMouseEnter ? onMouseEnter(title) : {}}
              ref={ref}>
            <div className={'list-menu-item-container'}>
              <Text>{title}</Text>
              <IconWrapper styleClass={'open-submenu'} name={iconTypes.arrow}/>
            </div>
          </li>
        )}
      </Reference>
      {isOpen ?
        <Popper placement={'right-start'}>
          {({ ref, style, placement }) => (
            <div ref={ref} style={{ ...style, zIndex: 3 }} data-placement={placement}>
              {children}
            </div>
          )}
        </Popper>
        : null}
    </Manager>
  )
}