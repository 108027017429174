import React from 'react'
import Dialog, { DialogButtonGroup } from './Dialog'
import Button from './Button'
import Text, { textTypes } from './Text'
import i18n from 'i18next'

import './TemplateDialog.scss'

export default function TemplateDialog ({
                                          className = '',
                                          title = i18n.t('confirm_action'),
                                          text = i18n.t('are_you_sure'),
                                          leftButtonText = i18n.t('cancel'),
                                          leftButtonVariant = 'outlined',
                                          onLeftButtonClicked = () => {},
                                          rightButtonText = i18n.t('confirm'),
                                          rightButtonIcon = null,
                                          rightButtonSecondIcon = null,
                                          rightButtonVariant = 'contained',
                                          onRightButtonClicked = () => {},
                                          hideModal = () => {},
                                          ...rest
                                        }) {
  return (
    <Dialog {...rest} className={'pattern-dialog ' + className} hideModal={hideModal} onCancel={hideModal} title={title}>
      <Text component={textTypes.paragraph} multiline>{text}</Text>
      <DialogButtonGroup className={'pattern-dialog-button-group'}>
        <Button
          variant={leftButtonVariant}
          onClick={() => {
            onLeftButtonClicked()
            hideModal()
          }}>
          {leftButtonText}
        </Button>
        <Button variant={rightButtonVariant}
                onClick={() => {
                  onRightButtonClicked()
                  hideModal()
                }}
                icon={rightButtonIcon}
                secondIcon={rightButtonSecondIcon}>
          {rightButtonText}
        </Button>
      </DialogButtonGroup>
    </Dialog>
  )
}