import * as React from 'react'

const AxesAutoGenerateNew = props =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#white"/>
        <stop offset="100%" stopColor="#white"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect width={32} height={32} fill="#white" opacity={0.08} rx={2}/>
      <path
        fill="#EBEEF0"
        stroke="#22293b"
        d="M18.205 3.592L9.448 17.984a.45.45 0 00.411.683l5.472-.333h0l-2.451 9.819a.3.3 0 00.546.231l9.113-14.63a.45.45 0 00-.41-.686l-5.595.341h0l2.22-9.594a.3.3 0 00-.549-.223z"
        transform="rotate(15 16 16)"
      />
      <path
        fill="#22293b"
        d="M20.117 28l.592-1.895h2.534L23.854 28H25l-2.31-7h-1.38L19 28h1.117zm2.854-2.868h-2l.98-3.089H22l.97 3.089z"
      />
    </g>
  </svg>

export default AxesAutoGenerateNew