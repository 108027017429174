import { representationTypes } from '../config/RepresentationTypes'

import { OffsetPolygonGeometry } from '../geometries/OffsetPolygonGeometry'
import { OffsetPolylineGeometry } from '../geometries/OffsetPolylineGeometry'
import { ReferenceEdgeGeometry } from '../geometries/ReferenceEdgeGeometry'
import { PolygraphGeometry } from '../geometries/PolygraphGeometry'
import { TapeMeasureGeometry } from '../geometries/TapeMeasureGeometry'
import { BufferGeometry, Geometry, Matrix4, Shape, ShapeGeometry } from 'three'
import { StampGeometry } from '../geometries/StampGeometry'
import { SpaceGeometry } from '../geometries/SpaceGeometry'
import { PolylineGeometry } from '../geometries/PolylineGeometry'

export function createGeometryByRepresentationType (representationType, parent, properties) {
  switch (representationType) {
    case representationTypes.scale:
      return new TapeMeasureGeometry(parent, representationType, properties)
    case representationTypes.outline:
      return new OffsetPolygonGeometry(parent, representationType, properties)
    case representationTypes.wall:
      return new OffsetPolylineGeometry(parent, representationType, properties)
    case representationTypes.door:
      return new ReferenceEdgeGeometry(parent, representationType, properties)
    case representationTypes.window:
      return new ReferenceEdgeGeometry(parent, representationType, properties)
    case representationTypes.pillar:
      return new StampGeometry(parent, representationType, properties)
    case representationTypes.space:
      return new SpaceGeometry(parent, representationType, properties)
    case representationTypes.graph:
      return new PolygraphGeometry(parent, representationType, properties)
    case representationTypes.axis:
      return new PolylineGeometry(parent, representationType, properties)
    default:
      return null
  }
}

export function createFaceGeometry (vertices) {
  console.log("vvvvertices")
  console.log(vertices)
  const geometry = new Geometry()
  const face = new Shape()
  face.moveTo(vertices[0].position.x, -vertices[0].position.y)

  for (let i = 1; i < vertices.length; i++) {
    face.lineTo(vertices[i].position.x, -vertices[i].position.y)
  }

  const faceGeometry = new ShapeGeometry(face)
  geometry.merge(faceGeometry, new Matrix4().makeRotationZ(-Math.PI / 2))

  return new BufferGeometry().fromGeometry(geometry)
}
