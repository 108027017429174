import React from 'react'
import './ToggleSwitch.scss'
import { uniqueId } from 'lodash'
import Text, { textTypes } from './Text'

function ToggleSwitch ({ id, name, value, onChange, className, disabled, field, label }) {

  const elementId = (field ? 'switch-' + field.name : id) || uniqueId('switch-')
  const styleClasses = 'toggle-switch' + (disabled ? ' disabled' : '') + (className ? ' ' + className : '')

  return (
      <div className={styleClasses}>
        {label && <Text component={textTypes.paragraph} outerClassName={'toggle-switch-label noSelect'}>{label}</Text>}
        <div className={'toggle-switch-button'}>
          <input type={'checkbox'}
                 disabled={disabled}
                 id={elementId}
                 name={(field ? field.name : name) || null}
                 checked={(field ? field.value : value) || false}
                 onChange={field ? field.onChange : onChange}/>
          <label className={'switch'} htmlFor={elementId}>
            <div className={'slider'}/>
          </label>
        </div>
      </div>
  )
}

export default ToggleSwitch