import * as React from 'react'

function SvgRedoInactive (props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M15 4.786L19.714 9.5 15 14.214V10H9.5a4 4 0 00-.2 7.995l.2.005h1a.5.5 0 01.09.992L10.5 19h-1a5 5 0 01-.217-9.995L9.5 9H15V4.786z"
        fill={props.color}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgRedoInactive
