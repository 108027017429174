import React, { Component } from 'react'
import Dialog, { DialogButtonGroup, DialogContent } from '../../../common/components/Dialog'
import { Field, Form, Formik } from 'formik'
import { connect } from 'react-redux'
import NumberInput from '../../../common/components/NumberInput'
import { updateRowDepth } from '../../actions/actions'
import Button from '../../../common/components/Button'

class EditRowDialog extends Component {

  render () {

    return (
      <Dialog onCancel={this.close}
              title={'Corridor'}
              className={'row-edit-dialog'}>
        <DialogContent>
          <Formik initialValues={{ ...this.props.row }} onSubmit={(values) => this.confirm(values)}>
            <Form id={'row-edit-form'} className={'row-form'}>
              <Field name={'depth'} component={NumberInput} label={'Width (in m)'} step={0.1}/>
            </Form>
          </Formik>
        </DialogContent>
        <DialogButtonGroup className={'dialog-buttons'}>
          <Button onClick={this.close}>Cancel</Button>
          <Button type={'submit'} form='row-edit-form' variant={'contained'}>Confirm</Button>
        </DialogButtonGroup>
      </Dialog>
    )
  }

  close = () => {
    this.props.onClose()
  }

  confirm = (values) => {
    this.props.updateRowDepth(this.props.departmentUuid, values)
    this.close()
  }
}


let mapDispatchToProps = {
  updateRowDepth: updateRowDepth,
}

export default connect(null, mapDispatchToProps)(EditRowDialog)