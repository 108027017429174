export function addVertex(position, geometry) {
    const {vertexGroup, edgeGroup} = geometry
    vertexGroup.addVertex(position)

    const vertexCount = vertexGroup.children.length

    if (vertexCount > 1) {
        const startVertex = vertexGroup.getVertexByIndex(-2)
        const endVertex = vertexGroup.getVertexByIndex(-1)

        edgeGroup.addEdge(startVertex, endVertex)
    }
}

export function addVertexToEdge(edge, position, geometry) {
    let {vertexGroup, edgeGroup, face} = geometry
    geometry.draggedVertex = edgeGroup.addVertexToEdge(edge, position)

    if (face) {
        face.update(vertexGroup.getPositions())
    }
}

export function updateVertex(position, geometry, vertex = null) {
    const {vertexGroup, face, draggedVertex} = geometry
    //console.log("updateVertex vertexGroup")
    //console.log(vertexGroup)
    //console.log("face")
    //console.log(face)

    if (!vertex && draggedVertex) {
        vertex = draggedVertex
    }

    vertexGroup.updateVertex(vertex, position)

    if (face)
        face.update(vertexGroup.getPositions())
}

export function removeLastVertex(geometry) {
    const {vertexGroup} = geometry

    const lastVertex = vertexGroup.getVertexByIndex(-1)

    vertexGroup.removeVertex(lastVertex)
}

export function removeLastEdge(geometry) {
  const {edgeGroup} = geometry

  const lastEdge = edgeGroup.getEdgeByIndex(-1)

  edgeGroup.removeEdge(lastEdge)
}


export function moveDelta(movement, geometry) {
    if (movement.lengthSq() === 0)
        return

    const {vertexGroup, face} = geometry

    vertexGroup.moveDeltaAllVertices(movement)

    if (face) {
        face.update(vertexGroup.getPositions())
    }
}

export function getLastVertex(geometry) {
    const {vertexGroup} = geometry

    return vertexGroup.getVertexByIndex(-1)
}

export function getLastEdge(geometry) {
    const {edgeGroup} = geometry

    return edgeGroup.getEdgeByIndex(-1)
}

export function setDraggedVertex(vertex, geometry) {
    geometry.draggedVertex = vertex
}

export function setDraggedVertex1(vertex, geometry) {
  geometry.draggedVertex1 = vertex
}

export function setDraggedVertex2(vertex, geometry) {
  geometry.draggedVertex2 = vertex
}

export function setDraggedEdge(edge, geometry) {
  geometry.draggedEdge = edge
}

export function getVerticesCount(geometry) {
    const {vertexGroup} = geometry
    return vertexGroup.getCount()
}

