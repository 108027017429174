import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dialog, { DialogButtonGroup, DialogContent } from '../../../common/components/Dialog'
import SelectButton from '../../../common/components/SelectButton'
import { activateAndLoadProject, fetchUserProjectsList } from '../../../common/redux/projects/projects-actions'

import Button from '../../../common/components/Button'
import { useNavigate } from 'react-router'

function LoadUserProjectsDialog({onClose}) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const projects = useSelector(state => state.projects.userProjects).map(project => ({
    label: project.name,
    value: project.id,
  }))
    .reverse()
  const [selectedProject, setSelectedProject] = useState('')

  const handleActivateAndLoadProject = useCallback((projectId) => {
    dispatch(activateAndLoadProject(projectId)).then((url) => {
      navigate(url);
    });
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(fetchUserProjectsList())
  }, [dispatch])

  const selectProject = event => {
    setSelectedProject(event.target.value)
  }

  const load = () => {
    onClose()
    handleActivateAndLoadProject(selectedProject)
  }

  const close = () => {
    onClose()
  }

    return (
      <Dialog
        onCancel={close}
        title={'Open Project'} className={'load-project-dialog'}>
        <DialogContent>
          <SelectButton value={selectedProject}
                        options={projects}
                        onChange={event => selectProject(event)}
                        customSelectorText={'Select Project...'}
                        selectOption/>
        </DialogContent>
        <DialogButtonGroup>
          <Button onClick={close}>Cancel</Button>
          <Button className={'button'} onClick={load} disabled={!selectedProject} variant={'contained'}>Confirm</Button>
        </DialogButtonGroup>
      </Dialog>
    )

}


export default LoadUserProjectsDialog
