import * as THREE from 'three'
import { Rotator } from '../../../common/three/Rotator'
import OrbitControls from '../../../common/three/orbit'
import { getMaterial, materialTypes } from '../../../common/three/common/materials'
import { addFloorPlan } from '../../../common/three/FloorPlan'
import { createRectMapping, createRoundRectangle, createShape, createShapeFromeLineList, createShapeFromList, getWhiterColor } from '../../../common/three/threefunctions'
import { DPTHandle, DPTMesh, ServiceArea } from '../../../common/three/classes'
import { PlaceholderFurniture } from '../../../common/three/PlaceholderFurniture'
import { Mesh } from 'three'
import { fontTypes, getTextMesh } from '../../../common/three/common/texts'
import { getHexColorFromRGB } from '../../../common/utils'

/**
 *
 * Plan Layer Setup
 *
 * Scene
 *    |-Background
 *    |-Scene Shifter
 *        |-Floor
 *        |   |-White Ground Plane
 *        |   |-OuterWall InnerLines
 *        |   |-OuterWall OuterLines
 *        |   |-Stairs
 *        |   |-Inner Walls (Lines)
 *        |   |-Inner Walls Openings
 *        |   |-Outer Wall Polygons
 *        |   |-Pillars
 *        |   |-Wallshadow (not working yet)
 *        |   |-Dashed Areas
 *        |   |-Opening Entrance Polygons (?)
 *        |   |-Opening Room Polygons (?)
 *        |   |-WindowInner Lines
 *        |-Departments
 *        |   |-DPT BasePlane (Array) (Can this be Triangles ??)
 *        |       |-Carpets           (Can this be Triangles ??)
 *        |       |-Children
 *        |       |     |-Workplaces
 *        |       |-Applications
 *        |       |-Ways
 *        |       |-Labels
 *        |       |-Furniture
 *        |   |-Markers
 *        |-DptSelectors
 *        |-Shadowplane
 *        |-FloorSecond
 *
 *
 *
 */

const orbitControlsConstants = {
  initMinZoomOut: .5,
  initMaxZoomIn: 8,

  lodZoomLevel: 2.5,

  start: 'start',
  end: 'end',
  change: 'change',
}

const markerType = {
  STAIRCASE: 'STAIRCASE',
  TOILET: 'TOILET',
  ENTRANCE: 'ENTRANCE',
  CAFETERIA: 'CAFETERIA',
  CUSTOM: 'CUSTOM',
}

const mouseActionType = {
  none: 'none',
  drag: 'drag',
  resize: 'resize',
}

const newMouseActionTypes = {
  none: 'none',
  resizeDepartment: 'resizeDepartment',
  dragApplication: 'dragApplication',
  dragDepartment: 'dragDepartment',
  dragRow: 'dragRow',
  rotateApplication: 'rotateApplication',
}

const organisationalUnitTypes = {
  department: 'dpt',
  workplace: 'workplace',
  application: 'application',
  carpet: 'carpet',
  row: 'row',
  marker: 'marker',
  area: 'area',
}

export const organisationalUnitDrawHeight = {
  carpets: 0.001,
  department: 0.0019,
  departmentLocked: 0.000002,
  departmentLabels: 0.05,
  departmentLabelsText: 0.055,
  workplace: 0.005,
  furnitureInWorkplace: 0.001,
  application: 0.003,
  furnitureInApplication: 0.001,
  stairs: 0.03,
  pillars: 0.035,
  dashAreas: 0.018,
  window: 0.01,
  wallShadow: -0.1,
  wayGeneral: 0.01,
  waySelectable: 0.04,
  marker: 0.02,
  groundplane: -0.01,
  wallElements: 0.01,
}

export const viewModes = {
  activity: 'activity',
  light: 'light',
  noise: 'noise',
  team: 'team',
}

const lodLevel = {
  detailed: 0,
  abstract: 1,
}

class PlanThreeScene {

  constructor (mount) {
    console.log('PlanThreeScene constructor')

    this.setSelectedOrganisationalUnitHandler = null
    this.markers = []

    this.scene = new THREE.Scene()
    this.rotator = new Rotator()
    this.rotator.init()
    this.rotator.disable()
    this.mountCamera(mount)
    this.setupRenderer(mount)
    this.setupScene()
    this.start()
    this.handleSetScreenshotButton = null
    this.showScreenshotButton = null
    this.topbar = null
    this.sidebar = null
    this.flyout = null
    this.ui = null
    this.openFlyOut = null
    this.width = 0
    this.height = 0
    this.viewMode = null
    this.popper = null
    this.handleRotateApplication = null
    this.handleDropApplication = null
    this.handleDropRow = null
    this.handleDropDepartment = null
    this.handleResizeDepartment = null
    this.getFloorLightmap = null
    this.setContextMenu = null
    this.popperTypes = null
    this.hidePoppers = null
    this.ensembleCategories = null
    this.legend = null
    this.lightmapMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide })

    this.organisationalUnits = []
    this.analyticsAreas = []
    this.furniture3DObjects = []
    this.roomData = []
    this.applications = []
    this.floorPlan = null

  }

  mountCamera (mount) {
    const { clientWidth, clientHeight } = mount.current

    this.camera = new THREE.OrthographicCamera(
      -clientWidth / 2,
      clientWidth / 2,
      clientHeight / 2,
      -clientHeight / 2,
      -1,
      1000,
    )
    this.camera.enableRotate = false
    this.camera.position.y = 20
    this.camera.zoom = 1
    this.camera.needsUpdate = true
  }

  setupRenderer (mount) {
    const { clientWidth, clientHeight } = mount.current
    //this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true })
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: false, preserveDrawingBuffer: true })
    this.renderer.setClearColor('#ffffff', 0)
    //this.renderer.setClearColor('#99ff00', 0)
    this.renderer.setSize(clientWidth, clientHeight)
    let pr = window.devicePixelRatio
    this.renderer.setPixelRatio(pr)
  }

  setupScene () {
    this.mousePosition2D = new THREE.Vector2()
    this.mouseDragStartPosition = new THREE.Vector3()
    this.mouseDown = false

    // 0: Detailview
    // 1: Overview
    this.lastZoomValue = -1
    this.updateLOD = true
    this.planbackground = null
    this.planeRotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2)
    this.baseOpacityDPT = 0.3
    this.mouseAction = mouseActionType.none
    this.fadeopacity = 0.2
    this.onGui = false
    this.fnlist = []
    this.markerSize = [3, 3]

    this.savedColor = null

    this.raycaster = new THREE.Raycaster()

    this.lastRightMouseDownPosition = new THREE.Vector3()
    this.lastMousePositionLeftClickDown = null
    this.dragStartObjectPosition = null
    this.dragStartPosition = null
    this.currentHandler = null
    this.handlerStartPosition = null
    this.currentHoveredObject = null
    this.currentSelectedObject = null
    this.currentMouseActionType = newMouseActionTypes.none

    this.dptSelector = new THREE.Group()
    this.departments = new THREE.Group()
    this.markers = new THREE.Group()
    this.rooms = new THREE.Group()
    this.floor = new THREE.Group()
    this.floorSecond = new THREE.Group()
    this.floorAreaTypes = new THREE.Group()
    this.handles = new THREE.Group()

    this.mountMaterials()
    this.mountBackground()
    this.mountOrbitControls()

    this.disableInteractions = false

    this.scene.add(this.floor)
    this.scene.add(this.floorAreaTypes)

    this.scene.add(this.departments)
    this.scene.add(this.rooms)
    this.scene.add(this.dptSelector)
    this.scene.add(this.handles)
    this.scene.add(this.rotator)

    this.scene.add(this.camera)

    this.mountBackgroundImage()
    this.mountCatchPlane()
  }

  mountOrbitControls () {
    this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement)
    this.orbitControls.enableRotate = false
    this.orbitControls.enableKeys = false
    this.orbitControls.minZoom = orbitControlsConstants.initMinZoomOut
    this.orbitControls.maxZoom = orbitControlsConstants.initMaxZoomIn

    if (this.disableInteractions) {
      this.orbitControls.mouseButtons = { ZOOM: THREE.MOUSE.MIDDLE, PAN: THREE.MOUSE.LEFT }
    } else {
      this.orbitControls.mouseButtons = { ZOOM: THREE.MOUSE.MIDDLE, PAN: THREE.MOUSE.RIGHT }
    }

    this.orbitControls.addEventListener(orbitControlsConstants.change, () => {
      this.hidePoppers()
      this.checkCameraHeight()
    })

  }

  mountMaterials () {
    this.lightmapMaterial = getMaterial(materialTypes.white)
  }

  mountBackground () {
    // Create the Background
    const backgroundPlane = new THREE.PlaneGeometry(2000, 2000, 8)

    const backgroundMaterial = getMaterial(materialTypes.background)

    let background = new THREE.Mesh(backgroundPlane, backgroundMaterial)
    background.name = 'background'
    background.position.z = -25
    this.camera.add(background)
  }

  start () {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(() => this.animate())
    }
  }

  stop () {
    cancelAnimationFrame(this.frameId)
  }

  renderScene () {

    this.renderer.clear()
    this.renderer.render(this.scene, this.camera)

  }

  animate () {

    this.renderScene()
    this.frameId = window.requestAnimationFrame(() => this.animate())
  }

  mountBackgroundImage () {
    this.backgroundImageMaterial = getMaterial(materialTypes.placeholder)
    this.backgroundImageMaterial.visible = false
    const geometry = new THREE.PlaneGeometry(100, 100, 8, 8)
    this.backgroundImage = new THREE.Mesh(geometry, this.backgroundImageMaterial)

    this.backgroundImage.applyMatrix4(this.planeRotation)
    this.backgroundImage.name = 'backgroundImage'
    this.backgroundImage.position.set(0, -.8, 0)

    this.scene.add(this.backgroundImage)

    this.updateBackgroundImage()
  }

  mountCatchPlane () {
    let catchPlaneGeometry = new THREE.PlaneGeometry(1000, 1000, 8)
    this.catchPlane = new THREE.Mesh(catchPlaneGeometry, getMaterial(materialTypes.transparent))

    this.catchPlane.applyMatrix4(this.planeRotation)
    this.scene.add(this.catchPlane)
  }

  updateBackgroundImage (bgImage) {
    /*if (!bgImage)
      return

    this.backgroundImageMaterial.map = THREE.ImageUtils.loadTexture(bgImage)
    this.backgroundImageMaterial.needsUpdate = true

    setTimeout(() => {
      this.backgroundImageMaterial.visible = true
      this.backgroundImageMaterial.opacity = .4
      this.backgroundImageMaterial.transparent = true

      let width = this.backgroundImageMaterial.map.image && this.backgroundImageMaterial.map.image.width > 0 ? this.backgroundImageMaterial.map.image.width : 1
      let height = this.backgroundImageMaterial.map.image && this.backgroundImageMaterial.map.image.height > 0 ? this.backgroundImageMaterial.map.image.height : 1

      let scaleWidth = (width > height ? 1 : width / height) * imageScale
      let scaleHeight = (height > width ? 1 : height / width) * imageScale

      this.backgroundImage.geometry = new THREE.PlaneGeometry(100 * scaleWidth, 100 * scaleHeight, 8, 8)
    }, 1)

     */

  }

  drawRooms () {
    this.roomData.forEach((room) => {
      let stdOpacity = this.baseOpacityDPT
      let material = new THREE.MeshBasicMaterial({ color: 0x777777, depthWrite: false, transparent: true, opacity: stdOpacity })

      let geometry = createShapeFromList(room.polygons, 0.01)

      let dpt = new DPTMesh(geometry, material)

      dpt.uuid = room.id
      dpt.type = organisationalUnitTypes.area
      dpt.locked = room.locked
      dpt.isExtra = true
      dpt.currentHex = dpt.material.color.getHex()
      dpt.storedHex = dpt.material.color.getHex()
      dpt.position.set(0, organisationalUnitDrawHeight.department, 0)
      this.drawApplications(dpt, room, material, this.applications, this.furniture3DObjects, true)
      this.rooms.add(dpt)
    })
    this.updateLODs()
  }

  drawApplications (dpt, organisationalUnit, material, applications, furniture3DObjects, isExtra = false) {

    if (organisationalUnit.furnitureEnsemblePlacements) {
      organisationalUnit.furnitureEnsemblePlacements.forEach((furnitureEnsemblePlacement) => {

        if (!applications.find((application) => application.uuid === furnitureEnsemblePlacement.uuid))
          return

        if (!furnitureEnsemblePlacement.centerx) {
          let minx = 100000
          let miny = 100000
          let maxx = -100000
          let maxy = -100000

          for (let i = 0; i < furnitureEnsemblePlacement.boundingBox.length; i += 2) {
            if (furnitureEnsemblePlacement.boundingBox[i] < minx)
              minx = furnitureEnsemblePlacement.boundingBox[i]

            if (furnitureEnsemblePlacement.boundingBox[i + 1] < miny)
              miny = furnitureEnsemblePlacement.boundingBox[i + 1]

            if (furnitureEnsemblePlacement.boundingBox[i] > maxx)
              maxx = furnitureEnsemblePlacement.boundingBox[i]

            if (furnitureEnsemblePlacement.boundingBox[i + 1] > maxy)
              maxy = furnitureEnsemblePlacement.boundingBox[i + 1]
          }

          furnitureEnsemblePlacement.centerx = (maxx + minx) / 2
          furnitureEnsemblePlacement.centery = (maxy + miny) / 2

          for (let i = 0; i < furnitureEnsemblePlacement.boundingBox.length; i += 2) {
            furnitureEnsemblePlacement.boundingBox[i] -= furnitureEnsemblePlacement.centerx
            furnitureEnsemblePlacement.boundingBox[i + 1] -= furnitureEnsemblePlacement.centery
          }
        }

        let geo = createShape(furnitureEnsemblePlacement.boundingBox)

        geo.shiftx = furnitureEnsemblePlacement.centerx
        geo.shifty = furnitureEnsemblePlacement.centery

        let transparentMaterial = material.clone()

        transparentMaterial.opacity = 0

        let obj = new DPTMesh(geo, transparentMaterial)

        obj.position.set(furnitureEnsemblePlacement.centerx, organisationalUnitDrawHeight.application, -furnitureEnsemblePlacement.centery)
        obj.type = organisationalUnitTypes.application
        obj.uuid = furnitureEnsemblePlacement.id
        obj.typeUuid = furnitureEnsemblePlacement.ensembleTypeId
        obj.boundingBox = furnitureEnsemblePlacement.boundingBox
        obj.currentHex = material.color.getHex()
        obj.storedHex = material.color.getHex()
        obj.categoryId = furnitureEnsemblePlacement.categoryId
        obj.maxSize = furnitureEnsemblePlacement.maxDepth
        if (furnitureEnsemblePlacement.maxWidth > furnitureEnsemblePlacement.maxDepth)
          obj.maxSize = furnitureEnsemblePlacement.maxDepth

        if (isExtra)
          obj.isExtra = true
        this.bBox = furnitureEnsemblePlacement.boundingBox

        if (furnitureEnsemblePlacement.wallLines.length > 0) {

          furnitureEnsemblePlacement.wallLines.forEach((wallLines) => {

            wallLines.forEach((wallLine) => {

              let lineGeo = createShapeFromeLineList([
                [
                  wallLine[0] - furnitureEnsemblePlacement.centerx,
                  wallLine[1] - furnitureEnsemblePlacement.centery,
                  wallLine[2] - furnitureEnsemblePlacement.centerx,
                  wallLine[3] - furnitureEnsemblePlacement.centery]], 0.04)
              let l1 = new THREE.Mesh(lineGeo, new THREE.MeshBasicMaterial({ color: 0x000000 }))
              obj.add(l1)
            })

          })

        }

        furnitureEnsemblePlacement.furniturePlacements.forEach((furniturePlacement) => {

          const furniture3DObject = this.getFurnitureItem(furniturePlacement.furnitureId, furniture3DObjects)

          if (furniture3DObject) {
            let furniture3DObjectCopy = furniture3DObject.duplicate()
            furniture3DObjectCopy.rotateY(-furniturePlacement.rotation)
            furniture3DObjectCopy.position.set(furniturePlacement.position[0] - furnitureEnsemblePlacement.centerx, organisationalUnitDrawHeight.furnitureInApplication,
              -furniturePlacement.position[1] + furnitureEnsemblePlacement.centery)

            obj.add(furniture3DObjectCopy)
            this.fnlist.push(furniture3DObjectCopy)

          }
        })

        dpt.add(obj)
      })
      this.updateLODs()
    }
  }

  updateLODs () {
    const currentZoomValue = this.camera.zoom

    const zoomIn = currentZoomValue > orbitControlsConstants.lodZoomLevel
    const zoomOut = currentZoomValue < orbitControlsConstants.lodZoomLevel

    if (zoomIn) {

      this.setDepartmentDetailVisibility(true)
      this.handles.visible = true
    } else if (zoomOut) {

      this.resetSelectedObject()
      this.setDepartmentDetailVisibility(false)
      this.handles.visible = false
    }
  }

  setDepartmentDetailVisibility (visible) {
    this.setGroupDetailVisibility(visible, this.departments)

    this.setGroupDetailVisibility(visible, this.rooms)
    this.setLabelVisibility(visible)

  }

  setGroupDetailVisibility (visible, group) {
    let elements = [organisationalUnitTypes.workplace, organisationalUnitTypes.carpet]
    group.children.forEach((department) => {

        // Workplaces
        department.children
          .filter(furnitureEnsemble => elements.includes(furnitureEnsemble.type) && !furnitureEnsemble.noLod)
          .forEach(furnitureEnsemble => furnitureEnsemble.visible = visible)

        // Furniture in workplaces
        department.children
          .filter(furnitureEnsemble => furnitureEnsemble.type === 'LOD')
          .forEach(furnitureEnsemble => {
            if (furnitureEnsemble.children.length === 2) {
              furnitureEnsemble.children[0].visible = visible
              furnitureEnsemble.children[1].visible = !visible
            }
          })

        // Applications
        department.children
          .filter(furnitureEnsemble => furnitureEnsemble.type === organisationalUnitTypes.application)
          .forEach(application => {
            application.children
              .filter(child => child.type === 'LOD')
              .forEach(furniture => {
                if (furniture.children.length === 2) {
                  furniture.children[0].visible = visible
                  furniture.children[1].visible = !visible
                }
              })
          })
      },
    )

  }

  getCurrentLodLevel () {
    return this.camera.zoom < orbitControlsConstants.lodZoomLevel ? lodLevel.abstract : lodLevel.detailed
  }

  setLabelVisibility (visible) {
    this.departments.children.filter(child => child.type === 'dpt')
      .forEach(dpt => dpt.children.filter(child => child.type === 'label' || child.type === 'text')
        .forEach(child => {
          child.visible = visible
        }))
  }

  resetSelectedObject () {
    if (!this.currentSelectedObject)
      return

    switch (this.currentSelectedObject.type) {
      case organisationalUnitTypes.application:
        this.resetApplicationSelect(this.currentSelectedObject)
        break
      case organisationalUnitTypes.workplace:
        this.resetWorkplaceSelect(this.currentSelectedObject)
        break
      case organisationalUnitTypes.department:
        this.resetDepartmentSelect(this.currentSelectedObject)
        break
      case organisationalUnitTypes.row:
        this.resetRowSelect()
        break
      default:
        break
    }

    this.currentSelectedObject = null
  }

  resetApplicationSelect (application) {
    if (this.viewMode === viewModes.activity) {
      application.material.opacity = .6
    } else {
      application.material.opacity = 0
    }

    this.rotator.disable()
  }

  //region Workplace

  setWorkplaceHover (workplace) {
    workplace.material.opacity = .6
  }

  resetWorkplaceHover (workplace) {
    workplace.material.opacity = .3
  }

  setWorkplaceSelect (workplace) {
    workplace.material.opacity = .6

    if (workplace.parent)
      this.setSelectedOrganisationalUnitId(workplace.parent.uuid)
  }

  resetWorkplaceSelect (workplace) {
    workplace.material.opacity = .3
  }

  //endregion

  //region Department

  setDepartmentSelect (department, setSelection = true) {
    department.material.opacity = .6

    if (setSelection) {
      this.setSelectedOrganisationalUnitId(department.uuid)
      if (!this.ui.flyOut.open)
        this.openFlyOut()

    }
  }

  resetDepartmentSelect (department) {
    department.material.opacity = .2
  }

  setSelectedOrganisationalUnitId (id) {
    this.setSelectedOrganisationalUnitHandler(id)
  }

  resetRowSelect () {

  }

  //endregion

  centerCamera (selection, mount) {
    if (selection !== null && selection.length) {
      let minX = 10000000
      let minY = 10000000
      let maxX = -10000000
      let maxY = -10000000

      selection.forEach((row) => {
        row.forEach((value, index) => {
          if (index % 2 === 0) {
            if (value > maxX)
              maxX = value
            if (value < minX)
              minX = value
          } else {
            if (value > maxY)
              maxY = value
            if (value < minY)
              minY = value
          }
        })
      })

      let centerX = (minX + maxX) / 2
      let centerY = (minY + maxY) / 2

      this.orbitControls.target.x = centerX
      this.orbitControls.target.z = -centerY

      this.camera.position.x = centerX
      this.camera.position.z = -centerY

      this.catchPlane.position.x = centerX
      this.catchPlane.position.z = -centerY

      const distanceX = Math.abs(maxX - minX)
      const distanceY = Math.abs(maxY - minY)

      let cameraZoom = 1


      if (distanceX > 0 && distanceY > 0) {
        if (distanceX / distanceY > mount.clientWidth / mount.clientHeight) {
          const screenHeightThree = mount.clientWidth / 10

          cameraZoom = (screenHeightThree / (distanceX * 1.1)) * 1.25
        } else {
          const screenWidthThree = mount.clientHeight / 10

          cameraZoom = (screenWidthThree / (distanceY * 1.1)) * 1.25
        }
      }


      if (cameraZoom > this.orbitControls.maxZoom) {
        cameraZoom = this.orbitControls.maxZoom
      }

      if (cameraZoom === 0) {
        cameraZoom = 1
      }

      this.camera.zoom = cameraZoom
      this.camera.updateProjectionMatrix()
    }

    this.updateLODs()
  }

  clearRooms () {

    for (let i = this.rooms.children.length - 1; i >= 0; i--) {
      this.freeThreeMemory(this.rooms.children[i])
      this.rooms.remove(this.rooms.children[i])
    }

  }

  drawFloor () {
    if (this.floorPlan) {
      addFloorPlan(this.floor, this.floorPlan) //, this.props.geometries.drawOutlines)

      //Zones
      if (this.analyticsAreas &&
        this.analyticsAreas.length) {
        this.analyticsAreas.forEach(area => {
          let serviceArea = new ServiceArea()
          serviceArea.setFromData(area)
          this.floorAreaTypes.add(serviceArea)
        })
      }
      this.updateLODs()
    }
  }

  clearFloor () {
    for (let i = this.floor.children.length - 1; i >= 0; i--) {
      this.freeThreeMemory(this.floor.children[i])
      this.floor.remove(this.floor.children[i])
    }
    if (!this.floorAreaTypes) {
      for (let i = this.floorAreaTypes.children.length - 1; i >= 0; i--) {
        this.freeThreeMemory(this.floorAreaTypes[i])
        this.floorAreaTypes.remove(this.floorAreaTypes[i])
      }
    } else {
      this.floorAreaTypes = new THREE.Group()
    }
  }

  clearMarkers () {

      for (let i = this.departments.children.length - 1; i >= 0; i--) {
        const children = this.departments.children[i]

        if (children instanceof DPTMesh && children.type === 'marker') {
          this.freeThreeMemory(children)
          this.departments.remove(children)
        }
      }

  }

  clearDepartments () {

      for (let i = this.departments.children.length - 1; i >= 0; i--) {
        this.freeThreeMemory(this.departments.children[i])
        this.departments.remove(this.departments.children[i])
      }

    this.handles.children = []
    this.dptSelector.children = []
  }

  drawDepartments () {

    this.fnlist = []

    if (!this.organisationalUnits || this.organisationalUnits.length === null)
      return

    this.rotator.disable()

    this.organisationalUnits.forEach((organisationalUnit) => {
      if (!organisationalUnit.isExtra) {
        // DPT Base Plane
        let { red, green, blue } = organisationalUnit.color	//"#444400"

        let dptThreeColor = new THREE.Color(red, green, blue)
        let stdOpacity = this.baseOpacityDPT

        let material = new THREE.MeshBasicMaterial({ color: dptThreeColor, depthWrite: false, transparent: true, opacity: stdOpacity })

        let geometry = createShapeFromList(organisationalUnit.polygons, 0.01)

        let dpt = new DPTMesh(geometry, material)

        dpt.uuid = organisationalUnit.id
        dpt.type = organisationalUnitTypes.department
        dpt.locked = organisationalUnit.locked

        dpt.position.set(0, organisationalUnitDrawHeight.department, 0)
        dpt.currentHex = dpt.material.color.getHex()
        dpt.storedHex = dpt.material.color.getHex()

        //this.drawCarpets(dpt, organisationalUnit)

        //this.drawPolygons(dpt, organisationalUnit)

        this.drawWorkplaces(dpt, organisationalUnit, material)

        this.drawApplications(dpt, organisationalUnit, material, this.applications, this.furniture3DObjects)

        this.drawFurnitureInWorkplaces(dpt, organisationalUnit, this.furniture3DObjects)

        //this.drawGeneralWays(dpt, organisationalUnit, dptThreeColor)

        this.drawSelectableWays(dpt, organisationalUnit, dptThreeColor)

        this.drawDepartmentLabels(dpt, organisationalUnit, dptThreeColor)

        if (organisationalUnit.locked) {
          let lockMaterial = getMaterial(materialTypes.departmentLocked)

          let lockMesh = new THREE.Mesh(geometry, lockMaterial)
          lockMesh.position.set(0, organisationalUnitDrawHeight.departmentLocked, 0)
          lockMesh.name = 'lockedMesh'

          dpt.add(lockMesh)
        }

        this.addHandles(organisationalUnit)

        this.departments.add(dpt)
      }
    })

    this.drawMarkers()
    this.updateLODs()

  }

  addHandles (organisationalUnit) {
    if (organisationalUnit.departmentHandles) {
      for (let p = 0; p < organisationalUnit.departmentHandles.length; p++) {
        let dptHandle = new DPTHandle()
        dptHandle.applyData(organisationalUnit.departmentHandles[p], organisationalUnit.color, p)

        this.handles.add(dptHandle)
      }
    }
  }

  drawCarpets (dpt, organisationalUnit) {
    if (organisationalUnit.borderPolyWithOffset) {
      let geo = createShapeFromList(organisationalUnit.borderPolyWithOffset, 0)
      //let obj = new DPTMesh(geo, this.crossedAreaMaterial)
      let obj = new DPTMesh(geo, new THREE.MeshPhongMaterial({ color: 0xff0000, transparent: true, opacity: 0.6 }))

      obj.position.set(0, organisationalUnitDrawHeight.carpets, 0)
      obj.type = organisationalUnitTypes.carpet
      dpt.add(obj)
    }
  }

  drawSelectableWays (dpt, organisationalUnit, dptThreeColor) {
    if (organisationalUnit.rows) {
      let wayColor = getWhiterColor(dptThreeColor)
      let wayOpacity = 0.9
      let wayMaterial = new THREE.MeshBasicMaterial({
        color: wayColor.clone(),
        depthWrite: true,
        transparent: true,
        opacity: wayOpacity,
      })

      organisationalUnit.rows.forEach((row) => {
        let wayGeometry = createShapeFromList(row.polygons)
        let way = new DPTMesh(wayGeometry, wayMaterial.clone())
        way.position.set(0, organisationalUnitDrawHeight.waySelectable, 0)
        way.type = organisationalUnitTypes.row
        way.uuid = row.uuid
        way.parentUuid = organisationalUnit.id
        way.currentHex = way.material.color.getHex()
        dpt.add(way)
      })
    }
  }

  drawPolygons (dpt, organisationalUnit) {
    if (organisationalUnit.polygons.length) {

      let geo = createShapeFromeLineList(organisationalUnit.polygons, .05)

      let material = new THREE.MeshBasicMaterial({ color: 0xff00ff })

      let mesh = new DPTMesh(geo, material)

      mesh.position.set(0, 0, 0)

      dpt.add(mesh)
    }
  }

  drawWorkplaces (dpt, organisationalUnit, material) {
    //workplaces
    if (organisationalUnit.workplacePoly) {

      let geo = createShapeFromList(organisationalUnit.workplacePoly)
      let obj = new DPTMesh(geo, material.clone())
      obj.position.set(0, organisationalUnitDrawHeight.workplace, 0)
      obj.typeUuid = organisationalUnit.patternStyle.patternType.uuid
      obj.type = organisationalUnitTypes.workplace
      obj.parentUuid = organisationalUnit.parentId
      obj.uuid = organisationalUnit.id
      obj.currentHex = material.color.getHex()
      obj.storedHex = material.color.getHex()

      dpt.add(obj)
    }
  }

  drawFurnitureInWorkplaces = (dpt, orgUnit, furniture3DObjects) => {

    if (orgUnit.type !== 'DEPARTMENT')
      return

    // Furntiture from pattern
    orgUnit.furniturePlacements.forEach((furniturePlacement) => {
      const furniture3DObject = this.getFurnitureItem(furniturePlacement.furnitureId, furniture3DObjects)

      if (furniture3DObject) {
        let furniture3DObjectCopy = furniture3DObject.duplicate()
        furniture3DObjectCopy.rotateY(-furniturePlacement.rotation)
        furniture3DObjectCopy.position.set(furniturePlacement.position[0], organisationalUnitDrawHeight.furnitureInWorkplace, -furniturePlacement.position[1])
        dpt.add(furniture3DObjectCopy)
        this.fnlist.push(furniture3DObjectCopy)
      } else {

        let placeholder = new PlaceholderFurniture()

        placeholder.build(furniturePlacement)
        placeholder.rotateY(-furniturePlacement.rotation)
        placeholder.position.set(furniturePlacement.position[0], organisationalUnitDrawHeight.furnitureInWorkplace, -furniturePlacement.position[1])

        dpt.add(placeholder)
      }
    })
  }

  getFurnitureItem = (id, furniture3DObjects) => {

    return furniture3DObjects
      .find(furniture => furniture.nid === id)
  }

  drawDepartmentLabels (dpt, organisationalUnit, dptThreeColor) {
    if (organisationalUnit.labelPoly) {

      let newLabelPoly = []
      const labelPoly = organisationalUnit.labelPoly[0]

      const factor = .8
      const height = 1.5 * factor
      const width = 3.3 * factor
      const offsetX = -.5
      const offsetY = .5

      newLabelPoly.push(labelPoly[6] + offsetX)
      newLabelPoly.push(labelPoly[5] + offsetY)
      newLabelPoly.push(labelPoly[6] + width + offsetX)
      newLabelPoly.push(labelPoly[5] + offsetY)
      newLabelPoly.push(labelPoly[6] + width + offsetX)
      newLabelPoly.push(labelPoly[5] - height + offsetY)
      newLabelPoly.push(labelPoly[6] + offsetX)
      newLabelPoly.push(labelPoly[5] - height + offsetY)

      let labelGeo = createRoundRectangle(newLabelPoly)
      let labelMaterial = new THREE.MeshBasicMaterial({ color: dptThreeColor, depthWrite: true, transparent: true })
      let label = new Mesh(labelGeo, labelMaterial)
      label.position.set(0, organisationalUnitDrawHeight.departmentLabels, 0)
      label.material.opacity = .6
      label.type = 'label'
      dpt.add(label)

      const labelText = getTextMesh(organisationalUnit.fourLetterName, .6 * factor, fontTypes.foundersGroteskRegular, materialTypes.white)

      labelText.position.set(newLabelPoly[0] + .6 * factor, organisationalUnitDrawHeight.departmentLabelsText, -newLabelPoly[1] + height / 2 + .3 * factor)
      dpt.add(labelText)
    }
  }

  checkCameraHeight () {
    this.clearMarkers()
    this.drawMarkers()

    if (this.showScreenshotButton) {
      this.setShowScreenshotButton(false)
    }

    this.updateLODs()
  }

  setShowScreenshotButton (show) {
    this.setShowLockedArea(!show)
    this.setShowBackground(!show)
    this.setShowHandleOfSelectedDepartment(!show)
    this.setDepartmentDetailVisibility(show || this.getCurrentLodLevel() === lodLevel.detailed)

    this.handleSetScreenshotButton(show)
  }

  setShowBackground (show) {
    const background = this.camera.children.find(child => child.name === 'background')

    if (background) {
      background.visible = show
    }
  }

  setShowHandleOfSelectedDepartment (show) {
    if (!this.currentSelectedObject)
      return

    if (this.currentSelectedObject.type === organisationalUnitTypes.department) {
      this.handles.children
        .filter(handle => handle.parentUuid === this.currentSelectedObject.uuid)
        .forEach(handle => {
          handle.visible = show
        })
    }
  }

  setShowLockedArea (show) {
    this.departments.children
      .filter(department => department.locked)
      .forEach(department => {
        const lockedMesh = department.children.find(child => child.name === 'lockedMesh')
        if (lockedMesh)
          lockedMesh.visible = show
      })
  }

  freeThreeMemory (threeChildren) {
    for (let i = threeChildren.children.length - 1; i >= 0; i--) {
      let children = threeChildren.children[i]

      this.freeThreeMemory(children)

      if (children.material)
        children.material.dispose()
      if (children.geometry)
        children.geometry.dispose()
      if (children.texture)
        children.texture.dispose()

      threeChildren.remove(children)
    }
  }

  drawMarkers () {
    if (this.markers.length > 0) {

      let scale = this.camera.zoom < orbitControlsConstants.lodZoomLevel ? (1 / (this.camera.zoom / orbitControlsConstants.lodZoomLevel)) * orbitControlsConstants.lodZoomLevel /
        orbitControlsConstants.initMinZoomOut : orbitControlsConstants.lodZoomLevel / orbitControlsConstants.initMinZoomOut

      scale *= .2

      this.markers.forEach((marker) => {
        let markerGeo = new THREE.PlaneGeometry(scale * this.markerSize[0], scale * this.markerSize[1], 1, 1)
        markerGeo.applyMatrix4(this.planeRotation)
        let markerMesh = new DPTMesh(markerGeo, this.getMarkerMaterial(marker.type))
        markerMesh.position.set(marker.position[0], organisationalUnitDrawHeight.marker, -marker.position[1])
        markerMesh.type = organisationalUnitTypes.marker
        markerMesh.uuid = marker.id
        this.departments.add(markerMesh)
      })
    }
  }

  getMarkerMaterial (mType) {
    switch (mType) {
      case markerType.CAFETERIA:
        return getMaterial(materialTypes.cafeteriaMarker)
      case markerType.CUSTOM:
        return getMaterial(materialTypes.customMarker)
      case markerType.ENTRANCE:
        return getMaterial(materialTypes.entranceMarker)
      case markerType.STAIRCASE:
        return getMaterial(materialTypes.staircaseMarker)
      case markerType.TOILET:
        return getMaterial(materialTypes.toiletMarker)
      default:
        return getMaterial(materialTypes.black)
    }
  }

  //region View Modes

  setViewMode = (mode) => {
    this.unsetLightView()

    switch (mode) {
      case viewModes.activity:
        this.setActivityView()
        break
      case viewModes.noise:
        this.setPlanView()
        break
      case viewModes.light:
        this.getFloorLightmap()
        this.setLightView()
        break
      case viewModes.team:
      default:
        this.setTeamView()
        break
    }
  }

  setTeamView = () => {
    //Recolor Departments
    for (let i = 0; i < this.departments.children.length; i++) {
      if (this.departments.children[i] instanceof DPTMesh) {
        this.departments.children[i].material.color = new THREE.Color(this.departments.children[i].storedHex)
        this.departments.children[i].currentHex = this.departments.children[i].storedHex

        for (let k = 0; k < this.departments.children[i].children.length; k++) {
          let item = this.departments.children[i].children[k]

          // Make Workgroups White
          if ((item instanceof DPTMesh) && (item.type === organisationalUnitTypes.workplace)) {

            item.material.color = new THREE.Color(item.storedHex)
            item.currentHex = item.storedHex
            item.noLod = false

          }

          if ((item instanceof DPTMesh) && (item.type === organisationalUnitTypes.application)) {

            item.material.color = new THREE.Color(item.storedHex)
            item.currentHex = item.storedHex
            item.noLod = false
            item.material.opacity = 0
            item.keepColor = false
          }

          if ((item instanceof DPTMesh) && (item.type === organisationalUnitTypes.row)) {
            item.material.color = new THREE.Color('#FFFFFF')
          }

        }

      }

    }

    // hide Lod 0 Lines
    this.setLod1LineVisibility(false)
    this.setFurnitureColor(null)

  }

  setActivityView = () => {
    // Make Departments White
    for (let i = 0; i < this.departments.children.length; i++) {
      if (this.departments.children[i] instanceof DPTMesh) {
        this.departments.children[i].material.color = new THREE.Color('#FFFFFF')
        this.departments.children[i].currentHex = '#FFFFFF'

        for (let k = 0; k < this.departments.children[i].children.length; k++) {
          let item = this.departments.children[i].children[k]

          // Make Workgroups Green
          if ((item instanceof DPTMesh) && (item.type === organisationalUnitTypes.workplace)) {
            item.material.color = new THREE.Color('#DDDDDD')
            item.currentHex = '#DDDDDD'
            item.visible = true
            item.noLod = true
          }

          // Make Applications Colorful
          if ((item instanceof DPTMesh) && (item.type === organisationalUnitTypes.application)) {
            let category = this.ensembleCategories.find(cat => cat.id === item.categoryId)

            let color = category ? getHexColorFromRGB(category.color, false) : '#333333'

            item.material.color = new THREE.Color(color)
            item.currentHex = color
            item.visible = true
            item.noLod = true
            item.material.opacity = .6
            item.keepColor = true
          }

          if ((item instanceof DPTMesh) && (item.type === organisationalUnitTypes.row)) {

            item.material.color = new THREE.Color('#DDDDDD')

          }

        }
      }

    }

    // Draw Lod 0 Lines
    this.setLod1LineVisibility(false)
    this.setFurnitureColor(0xffffff)
  }

  setPlanView = () => {

    // Make Departments White
    for (let i = 0; i < this.departments.children.length; i++) {
      if (this.departments.children[i] instanceof DPTMesh) {
        this.departments.children[i].material.color = new THREE.Color(0xffffff)
        this.departments.children[i].currentHex = 0xffffff

        for (let k = 0; k < this.departments.children[i].children.length; k++) {
          let item = this.departments.children[i].children[k]

          // Make Workgroups White
          if ((item instanceof DPTMesh) && (item.type === organisationalUnitTypes.workplace)) {
            item.material.color = new THREE.Color(0xffffff)
            item.currentHex = 0xffffff
            item.noLod = false
          }

          if ((item instanceof DPTMesh) && (item.type === organisationalUnitTypes.application)) {

            item.material.color = new THREE.Color(item.storedHex)
            item.currentHex = item.storedHex
            item.noLod = false
            item.material.opacity = 0
            item.keepColor = false
          }

        }
      }

    }

    // Draw Lod 0 Lines
    this.setLod1LineVisibility(true)
    this.setFurnitureColor(null)
  }

  setLightView () {
    this.setPlanView()

    const groundPlane = this.floor.children.find(child => child.name === 'groundPlane')

    if (groundPlane) {
      createRectMapping(groundPlane)
      //console.log(this.lightmapMaterial)
      groundPlane.material = this.lightmapMaterial
    }

    this.setUnitFaceMaterialVisibility(false)
  }

  unsetLightView () {
    const groundPlane = this.floor.children.find(child => child.name === 'groundPlane')

    if (groundPlane) {
      groundPlane.material = getMaterial(materialTypes.white)
    }

    this.setUnitFaceMaterialVisibility(true)
  }

  //endregion

  setUnitFaceMaterialVisibility = (value) => {
    for (let i = 0; i < this.departments.children.length; i++) {
      if (this.departments.children[i] instanceof DPTMesh) {
        this.departments.children[i].material.visible = value
        for (let k = 0; k < this.departments.children[i].children.length; k++) {
          if (this.departments.children[i].children[k] instanceof DPTMesh) {
            this.departments.children[i].children[k].material.visible = value
          }
        }
      }
    }
  }

  setLod1LineVisibility = (value) => {
    for (let i = 0; i < this.fnlist.length; i++) {
      for (let k = 0; k < this.fnlist[i].children.length; k++) {
        for (let m = 0; m < this.fnlist[i].children[k].children.length; m++) {
          let item = this.fnlist[i].children[k].children[m]
          if (item.type === 'lod1Lines') item.visible = value
        }
      }
    }
  }

  setFurnitureColor = (value) => {
    let color = new THREE.Color(0xf8f8f8)
    if (value) color = new THREE.Color(value)

    for (let i = 0; i < this.fnlist.length; i++) {
      for (let k = 0; k < this.fnlist[i].children.length; k++) {
        let item = this.fnlist[i].children[k]
        if (item.type === 'lowLod') item.material.color = color

      }
    }
  }

  // SECTION MOUSE INTERACTION

  //region Mouse Down

  onDocumentMouseDown = (event) => {
    if (!this.onGui) {
      if (event.button === 0 && !this.popper.popperType && !this.showScreenshotButton) {
        this.onLeftMouseDown(event)
      } else if (event.button === 2) {
        this.onRightMouseDown(event)
      }

    }
  }

  onLeftMouseDown (event) {
    if (this.currentIntersectionPosition)
      this.lastMousePositionLeftClickDown = this.currentIntersectionPosition.clone()

    switch (this.getCurrentLodLevel()) {
      case lodLevel.detailed:
        this.onLeftMouseDownDetailed(event)
        break
      case lodLevel.abstract:
        this.onLeftMouseDownAbstract(event)
        break
      default:
        break
    }
  }

  onLeftMouseDownDetailed (event) {
    if (this.isDepartmentResizeHandlerClicked()) {
      return
    }

    if (this.isApplicationRotatorClicked()) {
      return
    }

    this.handleSelection()
  }

  onLeftMouseDownAbstract (event) {
    const departmentIntersects = this.raycaster.intersectObjects(this.departments.children)

    if (departmentIntersects.length > 0) {
      this.currentSelectedObject = departmentIntersects[0].object

      if (!departmentIntersects[0].object.locked) {

        this.dragStartPosition = this.currentIntersectionPosition.clone()
        this.dragStartObjectPosition = this.currentSelectedObject.position.clone()

        this.disableOrbitControl(false)

        this.currentMouseActionType = newMouseActionTypes.dragDepartment
      }
    } else {
      this.resetSelectedObject()
      this.setSelectedOrganisationalUnitId(null)
    }
  }

  onRightMouseDown (event) {
    this.lastRightMouseDownPosition = new THREE.Vector2(event.clientX, event.clientY)
  }

  //endregion

  //region Mouse Up

  onDocumentMouseUp = (event) => {
    if (event.button === 0) {
      this.onLeftMouseUp(event)
    } else if (event.button === 2) {
      this.onRightMouseUp(event)
    }
  }

  onLeftMouseUp (event) {
    switch (this.getCurrentLodLevel()) {
      case lodLevel.detailed:
        this.onLeftMouseUpDetailed(event)
        break
      case lodLevel.abstract:
        this.onLeftMouseUpAbstract(event)
        break
      default:
        break
    }
  }

  onLeftMouseUpDetailed (event) {
    const intersects = this.raycaster.intersectObject(this.catchPlane)

    if (intersects.length > 0) {
      this.currentIntersectionPosition = intersects[0].point
    } else {
      return
    }

    let x, y

    switch (this.currentMouseActionType) {
      case newMouseActionTypes.resizeDepartment:
        x = this.currentIntersectionPosition.x
        y = -this.currentIntersectionPosition.z

        this.handleResizeDepartment(this.currentHandler.parentUuid, this.currentHandler.index, x, y)

        this.resetSelectedObject()
        this.currentMouseActionType = newMouseActionTypes.none
        return
      case newMouseActionTypes.rotateApplication:
        this.enableOrbitControl()

        this.handleApplicationRotation(this.rotator.target, this.rotator.getResultAngle())

        this.rotator.disable()
        this.currentMouseActionType = newMouseActionTypes.none
        return
      case newMouseActionTypes.dragApplication:
        this.dropApplication()
        return
      case newMouseActionTypes.dragRow:
        this.dropRow()
        break
      default:
        break
    }
  }

  onLeftMouseUpAbstract (event) {
    switch (this.currentMouseActionType) {
      case newMouseActionTypes.dragDepartment:
        this.dropDepartment()
        return
      default:
        break
    }
  }

  onRightMouseUp (event) {
    const currentMousePosition = new THREE.Vector2(event.clientX, event.clientY)

    if (currentMousePosition.distanceTo(this.lastRightMouseDownPosition) === 0) {
      this.openContextMenu(currentMousePosition)
    }
  }

  //endregion

  onDocumentMouseMove = (event) => {

    this.checkMousePositionOnPlanPanel(event)

    this.raycaster.setFromCamera(this.mousePosition2D, this.camera)

    this.mousePosition2D.x = ((event.clientX - this.sidebar) / this.width) * 2 - 1
    this.mousePosition2D.y = -((event.clientY - this.topbar) / this.height) * 2 + 1

    const intersects = this.raycaster.intersectObject(this.catchPlane)

    if (intersects.length > 0) {
      this.currentIntersectionPosition = intersects[0].point
    } else {
      return
    }

    this.onMouseMove(event)
  }

  checkMousePositionOnPlanPanel (event) {
    if ((event.clientX > this.sidebar) && (event.clientY > this.topbar)) {
      if (this.ui.flyOut.open) {
        this.onGui = event.clientX <= (this.sidebar + this.flyout)
      } else this.onGui = false
    } else this.onGui = true

    if (!this.legend) {
      this.legend = document.getElementsByClassName('legend-window')[0]
    }

    if (this.legend && this.legend.classList.contains('opened')) {
      if (event.clientX > this.legend.offsetLeft && event.clientX < (this.legend.offsetLeft + this.legend.offsetWidth)) {
        if (event.clientY > this.legend.offsetTop && event.clientY < (this.legend.offsetTop + this.legend.offsetWidth)) {
          this.onGui = true
        }
      }
    }
  }

  onMouseMove (event) {
    switch (this.getCurrentLodLevel()) {
      case lodLevel.detailed:
        this.onMouseMoveDetailed(event)
        break
      case lodLevel.abstract:
        this.onMouseMoveAbstract(event)
        break
      default:
        break
    }
  }

  onMouseMoveDetailed (event) {
    switch (this.currentMouseActionType) {
      case newMouseActionTypes.resizeDepartment:
        this.resizeDepartment()
        return
      case newMouseActionTypes.rotateApplication:
        this.rotateApplication()
        return
      case newMouseActionTypes.dragApplication:
      case newMouseActionTypes.dragRow:
        this.dragCurrentSelectedObject()
        break
      case newMouseActionTypes.none:
      default:
        break
    }

    this.handleHoveringApplicationRotator()

    this.handleHoveringObjects([
      organisationalUnitTypes.workplace,
      organisationalUnitTypes.application,
      organisationalUnitTypes.row,
      organisationalUnitTypes.department,
      organisationalUnitTypes.marker,
    ])
  }

  onMouseMoveAbstract (event) {
    switch (this.currentMouseActionType) {
      case newMouseActionTypes.dragDepartment:
        this.dragCurrentSelectedObject()
        return
      case newMouseActionTypes.none:
      default:
        this.handleHoveringObjects([organisationalUnitTypes.department])
        break
    }

  }

  handleHoveringApplicationRotator () {
    if (this.rotator.active) {
      const rotatorIntersection = this.raycaster.intersectObjects(this.rotator.children, true)

      if (rotatorIntersection.length > 0) {
        if (!this.rotator.isHighlighted) {
          this.rotator.highlight()
        }
      } else if (this.rotator.isHighlighted) {
        this.rotator.unhighlight()
      }
    }
  }

  handleHoveringObjects (lookForDetailsTypes = []) {
    let intersects = this.raycaster.intersectObjects(this.departments.children, true)

    let intersectionObject = intersects.find(intersect => lookForDetailsTypes.includes((intersect.object.type)))

    if (!intersectionObject) {
      this.resetHoveredObject()
    } else if (this.currentHoveredObject !== intersectionObject.object) {
      this.setHoveredObject(intersectionObject.object)
    }
  }

  resetHoveredObject () {
    if (!this.currentHoveredObject)
      return

    if (this.currentHoveredObject !== this.currentSelectedObject) {
      switch (this.currentHoveredObject.type) {
        case organisationalUnitTypes.application:
          this.resetApplicationHover(this.currentHoveredObject)
          break
        case organisationalUnitTypes.workplace:
          this.resetWorkplaceHover(this.currentHoveredObject)
          break
        case organisationalUnitTypes.department:
          break
        case organisationalUnitTypes.row:
          this.resetRowHover(this.currentHoveredObject)
          break
        case organisationalUnitTypes.marker:
          break
        default:
          break
      }
    }

    this.currentHoveredObject = null
  }

  resetApplicationHover (application) {
    if (this.viewMode === viewModes.activity) {
      application.material.opacity = .6
    } else {
      application.material.opacity = 0
    }
  }

  setRowHover (row) {
    //row.material.opacity = .6
  }

  resetRowHover (row) {
    //row.material.opacity = 0
  }

  setHoveredObject (intersectionObject) {
    this.resetHoveredObject()

    this.currentHoveredObject = intersectionObject

    switch (this.currentHoveredObject.type) {
      case organisationalUnitTypes.application:
        this.setApplicationHover(this.currentHoveredObject)
        break
      case organisationalUnitTypes.workplace:
        this.setWorkplaceHover(this.currentHoveredObject)
        break
      case organisationalUnitTypes.department:
        break
      case organisationalUnitTypes.row:
        this.setRowHover(this.currentHoveredObject)
        break
      case organisationalUnitTypes.marker:
        break
      default:
        break
    }
  }

  setApplicationHover (application) {
    application.material.opacity = .8
  }

  resizeDepartment () {
    let rPoint = this.currentHandler.centerPointA.clone()
    rPoint.sub(this.currentHandler.centerPointB)
    let mPoint = new THREE.Vector2(this.currentIntersectionPosition.x - this.lastMousePositionLeftClickDown.x,
      this.currentIntersectionPosition.z - this.lastMousePositionLeftClickDown.z)
    let angle = rPoint.angle()
    let tempVector = mPoint.rotateAround(new THREE.Vector2(0, 0), angle)
    tempVector.x = 0
    let resultVector = tempVector.rotateAround(new THREE.Vector2(0, 0), -angle)

    this.currentHandler.position.x = this.handlerStartPosition.x + resultVector.x
    this.currentHandler.position.z = this.handlerStartPosition.z + resultVector.y
  }

  rotateApplication () {
    const catchPlanIntersections = this.raycaster.intersectObject(this.catchPlane)
    if (catchPlanIntersections.length)
      this.rotator.updateRotation(catchPlanIntersections[0].point)
  }

  dragCurrentSelectedObject () {
    if (!this.currentSelectedObject)
      return

    this.currentSelectedObject.position.x = this.dragStartObjectPosition.x + (this.currentIntersectionPosition.x - this.dragStartPosition.x)
    this.currentSelectedObject.position.z = this.dragStartObjectPosition.z + (this.currentIntersectionPosition.z - this.dragStartPosition.z)
  }

  dropApplication () {
    if (this.currentIntersectionPosition.distanceToSquared(this.dragStartPosition) < 0.1) {
      this.currentSelectedObject.position.x = this.dragStartObjectPosition.x
      this.currentSelectedObject.position.z = this.dragStartObjectPosition.z

      this.rotator.enable(this.currentSelectedObject)
    } else {
      const x = this.currentSelectedObject.position.x
      const y = -this.currentSelectedObject.position.z

      this.handleDropApplication(this.currentSelectedObject.parent.uuid, this.currentSelectedObject.uuid, x, y)
    }

    this.currentMouseActionType = newMouseActionTypes.none
  }

  dropRow () {
    if (this.currentIntersectionPosition.distanceToSquared(this.dragStartPosition) < 0.1) {
      this.currentSelectedObject.position.x = this.dragStartObjectPosition.x
      this.currentSelectedObject.position.z = this.dragStartObjectPosition.z

    } else {
      const x = this.currentIntersectionPosition.x
      const y = -this.currentIntersectionPosition.z

      this.handleDropRow(this.currentSelectedObject.parentUuid, this.currentSelectedObject.uuid, x, y)
    }

    this.currentMouseActionType = newMouseActionTypes.none
  }

  dropDepartment () {
    if (!this.currentSelectedObject)
      return

    if (this.currentIntersectionPosition.distanceToSquared(this.dragStartPosition) < 0.1) {
      this.currentSelectedObject.position.x = this.dragStartObjectPosition.x
      this.currentSelectedObject.position.z = this.dragStartObjectPosition.z

      this.setDepartmentSelect(this.currentSelectedObject)

    } else {
      const x = this.currentIntersectionPosition.x
      const y = -this.currentIntersectionPosition.z

      this.handleDropDepartment(this.currentSelectedObject.uuid, x, y)
    }

    this.enableOrbitControl()
    this.currentMouseActionType = newMouseActionTypes.none
  }

  //region Resize Department

  isDepartmentResizeHandlerClicked () {
    const handlerIntersects = this.raycaster.intersectObjects(this.handles.children)

    if (handlerIntersects.length > 0) {
      this.currentHandler = handlerIntersects[0].object
      this.handlerStartPosition = this.currentHandler.position.clone()

      this.currentMouseActionType = newMouseActionTypes.resizeDepartment
      return true
    }

    return false
  }

  //endregion

  //region Rotate Application

  isApplicationRotatorClicked () {
    const rotatorIntersections = this.raycaster.intersectObjects(this.rotator.children, true)

    if (rotatorIntersections.length > 0 && this.rotator.active) {
      this.disableOrbitControl()

      let intersectedObject = this.raycaster.intersectObject(this.catchPlane)
      this.rotator.startRotation(intersectedObject[0].point)

      this.currentMouseActionType = newMouseActionTypes.rotateApplication
      return true
    }

    return false
  }

  //endregion

  //region Orbit Control

  enableOrbitControl () {
    this.orbitControls.enableZoom = true
    this.orbitControls.enablePan = true
  }

  disableOrbitControl (disablePan = true) {
    this.orbitControls.enableZoom = false
    if (disablePan) {
      this.orbitControls.enablePan = false
    }
  }

  //endregion

  handleApplicationRotation (application, angle) {
    this.handleRotateApplication(application.parent.uuid, application.uuid, angle)
  }

  handleSelection (setSelection = true) {
    if (this.currentHoveredObject) {
      this.setSelectedObject(setSelection)
    } else {
      this.resetSelectedObject()
      this.setSelectedOrganisationalUnitId(null)
    }
  }

  setSelectedObject (setSelection = true) {
    this.resetSelectedObject()

    this.currentSelectedObject = this.currentHoveredObject

    switch (this.currentSelectedObject.type) {
      case organisationalUnitTypes.application:
        this.setApplicationSelect(this.currentSelectedObject)
        break
      case organisationalUnitTypes.workplace:
        this.setWorkplaceSelect(this.currentSelectedObject)
        break
      case organisationalUnitTypes.department:
        this.setDepartmentSelect(this.currentSelectedObject, setSelection)
        break
      case organisationalUnitTypes.row:
        this.setRowSelect(this.currentSelectedObject)
        break
      default:
        break
    }
  }

  setApplicationSelect (application) {
    application.material.opacity = .6

    if (application.parent.locked) {
      this.dragStartPosition = this.currentIntersectionPosition.clone()
      this.dragStartObjectPosition = application.position.clone()
      this.currentMouseActionType = newMouseActionTypes.dragApplication
    }

    this.setSelectedOrganisationalUnitId(application.parent.uuid)
  }

  setRowSelect (row) {
    //row.material.opacity = .6

    this.dragStartPosition = this.currentIntersectionPosition.clone()
    this.dragStartObjectPosition = row.position.clone()
    this.currentMouseActionType = newMouseActionTypes.dragRow
  }

  selectItem (id) {

    if (id) {

      this.departments.children.forEach((department) => {
        if (department.uuid === id) {
          this.dptSelector.children = []

          let selection = this.getOrganisationalUnitById(department.uuid)

          if (selection === null) {
            return
          }

          for (let k = 0; k < this.handles.children.length; k++) {

            if (this.handles.children[k].parentUuid === selection.id) {
              if (!selection.locked)
                this.handles.children[k].visible = true
            } else {
              this.handles.children[k].visible = false
            }
          }

          let shape = null

          if (selection.polygons) {
            shape = createShapeFromList(selection.polygons)
          }

          if (selection.workplacePoly) {
            shape = createShapeFromList(selection.workplacePoly)
          }

          if (shape) {
            let dptColor = selection.color	//"#444400"

            let dptThreeColor = new THREE.Color(dptColor.red, dptColor.green, dptColor.blue)

            let dptMaterial = new THREE.MeshBasicMaterial({ color: dptThreeColor, depthWrite: false, transparent: true, opacity: .6 })

            let mesh = new THREE.Mesh(shape, dptMaterial)
            mesh.position.set(0, organisationalUnitDrawHeight.department, 0)
            this.dptSelector.add(mesh)
          }

        }
      })
    } else {
      //this.resetSelectedApplication()
      this.dptSelector.children = []
      for (let k = 0; k < this.handles.children.length; k++) {
        this.handles.children[k].visible = false
      }

    }
  }

  getOrganisationalUnitById = (id) => {
    let returnValue = null

    if (this.organisationalUnits.length) {
      this.organisationalUnits.forEach((organisationalUnit) => {
        if (organisationalUnit.id === id)
          returnValue = organisationalUnit

      })
    }

    return returnValue
  }

  openContextMenu (mousePosition) {

    if (this.getCurrentLodLevel() === lodLevel.abstract) {
      this.openContextMenuAbstract(mousePosition)
    } else {
      this.openContextMenuDetailed(mousePosition)
    }
  }

  openContextMenuAbstract (mousePosition) {
    if (this.currentHoveredObject && this.currentHoveredObject.type === organisationalUnitTypes.department) {
      this.setContextMenu(this.popperTypes.addMarkerZoomIn, mousePosition, this.currentHoveredObject.uuid)
    } else {
      this.setContextMenu(this.popperTypes.addMarkerZoomOut, mousePosition)
    }

    this.handleSelection(false)
  }

  openContextMenuDetailed (mousePosition) {
    let popperType

    if (this.currentHoveredObject) {
      switch (this.currentHoveredObject.type) {
        case organisationalUnitTypes.application:
          popperType = this.currentHoveredObject.parent.locked ? this.popperTypes.editDeleteApplication : this.popperTypes.editApplication

          this.setContextMenu(popperType, mousePosition, this.currentHoveredObject.uuid, this.currentHoveredObject.parent.uuid, this.currentHoveredObject.typeUuid)
          this.handleSelection()
          return
        case organisationalUnitTypes.department:
          popperType = this.currentHoveredObject.locked ? this.popperTypes.departmentLocked : this.popperTypes.departmentUnlocked

          this.setContextMenu(popperType, mousePosition, this.currentHoveredObject.uuid)
          this.handleSelection(false)
          return
        case organisationalUnitTypes.marker:
          this.setContextMenu(this.popperTypes.editMarker, mousePosition, this.currentHoveredObject.uuid)
          return
        case organisationalUnitTypes.row:
          this.setContextMenu(this.popperTypes.editRow, mousePosition, this.currentHoveredObject.uuid, this.currentHoveredObject.parent.uuid)
          return
        case organisationalUnitTypes.workplace:
          this.setContextMenu(this.popperTypes.editWorkplace, mousePosition, this.currentHoveredObject.typeUuid)
          this.handleSelection()
          return
        default:
          break
      }
    }

    let roomsIntersects = this.raycaster.intersectObjects(this.rooms.children)

    let areaIntersects = this.raycaster.intersectObjects(this.floorAreaTypes.children)
      .filter(intersect => intersect.object.faceType === 'ROOM')

    if (areaIntersects.length > 0 && roomsIntersects.length) {
      this.setContextMenu(this.popperTypes.editRoom, mousePosition, areaIntersects[0].object.index)
    } else if (areaIntersects.length > 0) {
      this.setContextMenu(this.popperTypes.addApplicationToRoom, mousePosition, areaIntersects[0].object.index)
    } else {
      this.setContextMenu(this.popperTypes.addMarkerZoomOut, mousePosition)
      this.handleSelection()
    }
  }

  updateLightmap = (lightmap) => {
    if (lightmap) {
      let texture = new THREE.Texture()
      createImageBitmap(lightmap)
        .then(function (imageBitmap) {
          texture.image = imageBitmap
          texture.needsUpdate = true
          texture.wrapS = texture.wrapT = THREE.RepeatWrapping
          texture.offset.set(0, 0)
          texture.repeat.set(1, 1)
        })

      this.lightmapMaterial = new THREE.MeshBasicMaterial({
        name: 'lightmap',
        depthWrite: true,
        map: texture,
        transparent: true,
      })
    }
  }

  handleScreenshot () {
    this.setShowLockedArea(false)
    this.setShowBackground(false)

    const a = document.createElement('a')
    this.renderer.render(this.scene, this.camera)
    a.href = this.renderer.domElement.toDataURL('image/png', 1.0)
      .replace('image/png', 'image/octet-stream')
    a.download = 'Screenshot.png'
    a.click()

    this.setShowLockedArea(true)
    this.setShowBackground(true)
    this.setShowScreenshotButton(false)
  }

}

export default PlanThreeScene