import React, { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BuilderNavBar from '../../../common/components/builder/BuilderNavBar';
import FurnitureSelector from '../inspector/FurnitureSelector';
import Inspector from '../inspector/Inspector';
import ApplicationBuilderPlan from './ApplicationBuilderPlan';


import { selectedElementType } from '../../reducers/uiState'

import {
  createFurnitureEnsembleAndFetch,
  fetchFurnitureEnsemblePlan,
  patchFurnitureEnsemble, updateFurnitureEnsemblePlan,
} from '../../actions/backendActions'

import {
  addNewFurniture,
  changeFurnitureEnsembleSize,
  changeFurnitureParent,
  changeFurniturePosition,
  changeSelectedElement,
  deleteFurniture,
  discardNewApplication,
  discardUndo,
  getUndoAvailable,
  redoApplicationBuilder,
  renameApplication,
  resetDirtyFlag,
  storeApplicationBuilderUndo,
  undoApplicationBuilder,
} from '../../actions/frontendActions'

import { updateFurnitureDragId } from '../../../common/redux/furnituredrag/furnituredag-actions'

import './ApplicationBuilderEditor.scss'
import { viewNames } from '../../../common/globalConstants'

function ApplicationBuilderEditor() {
  const { projectId, applicationId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const prevApplicationId = React.useRef("");
  const prevProjectId = React.useRef("");

  const {
    furnitureEnsembles,
    furnitureEnsemblePlan,
    undoRandom,
    furnitureLibrary,
    furnitureMenuItemDragId,
    selectedElementId,
    roomSizeLocked,
    dirtyFlag,
    undoAvailable,
    redoAvailable,
  } = useSelector(state => ({
    furnitureEnsembles: state.furnitureEnsembles.furnitureEnsembles,
    furnitureEnsemblePlan: state.furnitureEnsembles.furnitureEnsemblePlan,
    undoRandom: state.furnitureEnsembles.undoRandom,
    furnitureLibrary: state.furniture.furnitureLibrary,
    furnitureMenuItemDragId: state.furnitureDrag.furnitureMenuItemDragId,
    selectedElementId: state.applicationBuilderUiState.selectedElement,
    roomSizeLocked: state.applicationBuilderUiState.roomSizeLocked,
    dirtyFlag: state.furnitureEnsembles.dirtyFlag,
    undoAvailable: state.furnitureEnsembles.undoAvailable,
    redoAvailable: state.furnitureEnsembles.redoAvailable,
  }));


  useEffect(() => {
    if (applicationId) {
      dispatch(fetchFurnitureEnsemblePlan(projectId, applicationId));
    }
  }, [projectId, applicationId, dispatch]);

  useEffect(() => {
    if (applicationId && (applicationId !== prevApplicationId.current || projectId !== prevProjectId.current)) {
      dispatch(fetchFurnitureEnsemblePlan(projectId, applicationId));
    }

    prevApplicationId.current = applicationId;
    prevProjectId.current = projectId;
  }, [applicationId, projectId, dispatch]);




  // saveChanges & discardChanges
    let saveLocalChanges
    let discardChanges

    if (applicationId) {
      saveLocalChanges = () => handleSaveChanges(projectId, furnitureEnsemblePlan)
      discardChanges = () => handleDiscardChanges(projectId, applicationId)
    } else {
      saveLocalChanges = () => createFurnitureEnsembleAndFetch(projectId, furnitureEnsemblePlan)
      discardChanges = () => {
        discardNewApplication()
      }
    }

  const renameTitle = (furnitureEnsemblePlan) => {
    dispatch(renameApplication(furnitureEnsemblePlan.id, furnitureEnsemblePlan.displayName))

    if (furnitureEnsemblePlan.id)
      dispatch(patchFurnitureEnsemble(projectId, furnitureEnsemblePlan.id, furnitureEnsemblePlan))
  }


  const handleDiscardChanges = (projectId, currentApplicationId) => {
    dispatch(changeSelectedElement(selectedElementType.FLOOR, ''))
    discardChanges(projectId, currentApplicationId)
  }

  const handleSaveChanges = (projectId, furnitureEnsemblePlan) => {
    dispatch(changeSelectedElement(selectedElementType.FLOOR, ''))
    dispatch(updateFurnitureEnsemblePlan(projectId, furnitureEnsemblePlan.id, furnitureEnsemblePlan))
  }

  const handleBackToOverview = () => {

    navigate(`/projects/${projectId}/${viewNames.SETS}${location.search}`)
  }

  const handleChangeDisplayedApplication = (applicationId)  => {
    dispatch(changeSelectedElement(selectedElementType.FLOOR, ''))

    navigate(`/projects/${projectId}/${viewNames.SETS}/${applicationId}${location.search}`)
  }

    const handleChangeSelectedElement = (selectedElement, id) => {
      dispatch(changeSelectedElement(selectedElement, id))
    }

    const handleResetDirtyFlag = () => {
      dispatch(resetDirtyFlag())
    }

    const handleUndoApplicationBuilder = () => {
      dispatch(undoApplicationBuilder())
    }

    const handleRedoApplicationBuilder = () => {
      dispatch(redoApplicationBuilder())
    }

    const handleDiscardUndo = () => {
      dispatch(discardUndo())
    }

    const handleChangeFurnitureEnsembleSize = (furnitureEnsemblePlan, size) => {
      dispatch(changeFurnitureEnsembleSize(furnitureEnsemblePlan, size))
    }

    const handleChangeFurniturePosition = (position, furnitureId) => {
      dispatch(changeFurniturePosition(position, furnitureId))
    }

    const handleChangeFurnitureParent = (par, uuid, connectorSide, posOnConnectorSide, root) => {
      dispatch(changeFurnitureParent(par, uuid, connectorSide, posOnConnectorSide, root))
    }

    const handleDeleteFurniture = (furnitureId) => {
      dispatch(deleteFurniture(furnitureId))
    }

    const handleAddNewFurniture = (par, uuid, newId, connectorSide, posOnConnectorSide, root,
                                   constructor) => {
      dispatch(addNewFurniture(par, uuid, newId, connectorSide, posOnConnectorSide, root, constructor))
    }

    const handleUpdateFurnitureDragId = (furnitureMenuItemDragId) => {
      dispatch(updateFurnitureDragId(furnitureMenuItemDragId))
    }

    const handleStoreApplicationBuilderUndo = () => {
      dispatch(storeApplicationBuilderUndo())
    }

    const handleGetUndoAvailable = () => {
      dispatch(getUndoAvailable())
    }

    return (
      <div className="application-builder-editor">
        <BuilderNavBar navItems={furnitureEnsembles}
                       projectId={projectId}
                       current={furnitureEnsemblePlan}
                       onNavigation={(appId) => handleChangeDisplayedApplication(appId)}
                       backToOverview={() => handleBackToOverview()}
                       dirtyFlag={dirtyFlag}
                       saveChanges={saveLocalChanges}
                       discardChanges={discardChanges}
                       resetDirtyFlag={() => handleResetDirtyFlag()}
                       renameTitle={(furnitureEnsemblePlan) => renameTitle(furnitureEnsemblePlan)}
                       dialogMessage={[t('application_has_been_modified'), t('confirm_or_discard_changes'), t('close_to_continue')]}
                       undoApplicationBuilder={() => {handleUndoApplicationBuilder()}}
                       redoApplicationBuilder={() => {handleRedoApplicationBuilder()}}
                       undoAvailable={undoAvailable}
                       redoAvailable={redoAvailable}
                       discardUndo={() => {handleDiscardUndo()}}
        />

        <div className="main">
          <div className="sidebar left">
            <FurnitureSelector/>
          </div>

          <div className="content">
            <div id='application-builder-editor-plan'>
              <ApplicationBuilderPlan
                furnitureEnsemblePlan={furnitureEnsemblePlan}

                onChangeFurnitureEnsembleSize={(sizes) => handleChangeFurnitureEnsembleSize(sizes)}
                onChangeFurniturePosition={(pos, uuid) => handleChangeFurniturePosition(pos, uuid)}
                onChangeFurnitureRotation={(rot, uuid) => handleChangeFurniturePosition(rot, uuid)}
                onChangeFurnitureParent={(par, uuid, connectorSide, posOnConnectorSide, root) => handleChangeFurnitureParent(par, uuid, connectorSide, posOnConnectorSide, root)}
                onAddNewFurniture={(par, uuid, newId, connectorSide, posOnConnectorSide, root, constructor) => handleAddNewFurniture(par, uuid, newId, connectorSide, posOnConnectorSide, root,
                  constructor)}
                onChangeSelectElement={(category, uuid) => handleChangeSelectedElement(category, uuid)}
                updateFurnitureMenuItemDragId={(id) => handleUpdateFurnitureDragId(id)}
                furnitureMenuItemDragId={furnitureMenuItemDragId}
                furnitureLibrary={furnitureLibrary}
                deleteFurniture={handleDeleteFurniture}
                selectedElementId={selectedElementId}
                roomSizeLocked={roomSizeLocked}
                storeApplicationBuilderUndo={handleStoreApplicationBuilderUndo}
                undoRandom={undoRandom}
                getUndoAvailable={handleGetUndoAvailable}

              />
            </div>
          </div>

          <div className="sidebar right">
            <Inspector/>
          </div>
        </div>

      </div>
    )


}
export default ApplicationBuilderEditor
