import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import Text from '../../../common/components/Text';
import { IconWrapper } from '../../../common/components/IconWrapper';

import './Footer.scss';
import { updateFrontendLocation } from '../../../common/redux/frontendLocation/frontend-location-action';
import { viewNames } from '../../../common/globalConstants';

function Footer({ footerText, footerLinkText, footerLinkIcon, footerLink }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenLink = (link) => {
    navigate(link);

    if (link.includes(viewNames.FLOORPLAN)) {
      dispatch(updateFrontendLocation('FLOORPLAN', 'TESTFIT'));
    }
  };

  return (
    <div className={'step-content-footer'}>
      <div className={'step-content-footer-container'}>
        {footerText && footerText.length ? <Text>{footerText}</Text> : null}
        <div className={'step-content-footer-link'} onClick={() => handleOpenLink(footerLink)}>
          <Text>{footerLinkText}</Text>
          {footerLinkIcon ? <IconWrapper name={footerLinkIcon}/> : null}
        </div>
      </div>
    </div>
  );
}

export default Footer;
