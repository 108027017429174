import Text, { textTypes } from '../../../common/components/Text'
import React from 'react'
import { IconWrapper } from '../../../common/components/IconWrapper'

import './Description.scss'

export default function Description ({ description }) {
  const { descriptions, infoTitle, infoItems } = description

  return (
    <React.Fragment>
      {descriptions ?
        descriptions.map((description, index) => {
          return description ?
            <Text outerClassName={'step-sidebar-description-text'} component={textTypes.paragraph} multiline key={index}>
              {description}
            </Text> :
            <div className={'step-sidebar-description-separator'} key={index}/>
        }) : null}
      {
        infoItems ?
          <div className={'step-sidebar-info'}>
            <Text component={textTypes.paragraph}>{infoTitle}</Text>
            {
              infoItems.map((infoItem, index) => {
                  const { icon, title, description } = infoItem
                  return (
                    <div className={'step-sidebar-info-item'} key={index}>
                      <IconWrapper name={icon}/>
                      <div className={'step-sidebar-info-item-text'}>
                        <Text outerClassName={'step-sidebar-info-item-title'} component={textTypes.paragraphBold}>{title}</Text>
                        {description ? <Text component={textTypes.paragraph} multiline>{description}</Text> : null}
                      </div>
                    </div>
                  )
                },
              )
            }
          </div>
          :
          null
      }
    </React.Fragment>
  )
}