import { httpClient } from '../../httpClient'
import { getProjectId, projectApi } from '../../utils'
import { FULFILLED, PENDING, REJECTED } from '../../globalConstants'
import { floorPlanActionTypes } from '../../../floorplan/constants'
import { fetchFurnitureEnsembles, fetchFurnitureEnsemblesPolygons } from '../../../applicationbuilder/actions/backendActions'
import { getUndoInformation } from '../undo/undo-actions'

const UPLOAD_APPLICATIONS = 'UPLOAD_APPLICATIONS'

export const applicationActionTypes = {

  UPLOAD_APPLICATIONS: UPLOAD_APPLICATIONS,
  UPLOAD_APPLICATIONS_PENDING: UPLOAD_APPLICATIONS + PENDING,
  UPLOAD_APPLICATIONS_FULFILLED: UPLOAD_APPLICATIONS + FULFILLED,
  UPLOAD_APPLICATIONS_REJECTED: UPLOAD_APPLICATIONS + REJECTED,

}

export function exportApplications () {
  return {
    type: floorPlanActionTypes.FETCH_FILE,
    payload: httpClient.getFile(encodeURI(`${projectApi()}/applications/coa`)),
  }
}

export function deleteApplications () {
  return (dispatch) => {
    dispatch({
      type: floorPlanActionTypes.DELETE_APPLICATIONS,
      payload: httpClient.delete(`${projectApi()}/applications`),
    })
      .then(() => dispatch(fetchFurnitureEnsembles()))
      .then(() => dispatch(fetchFurnitureEnsemblesPolygons()))
      .then(() => dispatch(getUndoInformation(getProjectId())))
  }
}

export function importApplications (file, replace) {
  let form = new FormData()
  form.append('file', file)
  form.append('replace', replace)

  return (dispatch) => {
    dispatch({
      type: applicationActionTypes.UPLOAD_APPLICATIONS,
      payload: httpClient.post(encodeURI(`${projectApi()}/applications/coa`), form),
    })
      .then(() => dispatch(fetchFurnitureEnsembles()))
      .then(() => dispatch(fetchFurnitureEnsemblesPolygons()))
      .then(() => dispatch(getUndoInformation(getProjectId())))
  }

}