import React from 'react'

const SvgDrawNavbarNumber4Active = props =>
  <svg
    viewBox="0 0 20 20"
    width={20} height={20}
    className={props.className}
  >
    <title>{'Group 4'}</title>
    <g fill="none" fillRule="evenodd">
      <circle fill="#C4C6D2" cx={10} cy={10} r={10}/>
      <path
        d="M11.692 13.76v-2.016h1.296v-.852h-1.296V6.2h-1.26L6.7 10.76v.984h3.972v2.016h1.02zm-1.02-2.868H7.6l3.072-3.756v3.756z"
        fill="#00293B"
        fillRule="nonzero"
      />
    </g>
  </svg>

export default SvgDrawNavbarNumber4Active