import { floorPlanActionTypes } from '../../../floorplan/constants'
import { httpClient } from '../../httpClient'
import { projectApi } from '../../utils'

export function addFurniture (xlsxFile, dxfFile) {

  let form = new FormData()

  form.append('xlsxFile', xlsxFile)
  form.append('dxfFile', dxfFile)

  return (dispatch) => {
    dispatch({
      type: floorPlanActionTypes.FETCH_FURNITURE_LIBRARY,
      payload: httpClient.post(projectApi() + '/furniture', form),
    })
  }

}