import { representationTypes } from '../config/RepresentationTypes'

import { OffsetPolygonController } from '../controller/OffsetPolygonController'
import { OffsetPolylineController } from '../controller/OffsetPolylineController'
import { ReferenceEdgeController } from '../controller/ReferenceEdgeController'
import { TapeMeasureController } from '../controller/TapeMeasureController'
import { SpaceController } from '../controller/SpaceController'
import { StampController } from '../controller/StampController'
import { AxisController } from '../controller/AxisController'

export function createControllerByRepresentationType (floorPlanerController, representationType, group) {

  switch (representationType) {
    case representationTypes.scale:
      return new TapeMeasureController(floorPlanerController, representationType, group)
    case representationTypes.outline:
      return new OffsetPolygonController(floorPlanerController, representationType, group)
    case representationTypes.wall:
      return new OffsetPolylineController(floorPlanerController, representationType, group)
    case representationTypes.door:
      return new ReferenceEdgeController(floorPlanerController, representationType, group)
    case representationTypes.window:
      return new ReferenceEdgeController(floorPlanerController, representationType, group)
    case representationTypes.pillar:
      return new StampController(floorPlanerController, representationType, group)
    case representationTypes.space:
      return new SpaceController(floorPlanerController, representationType, group)
    case representationTypes.axis:
      return new AxisController(floorPlanerController, representationType, group)
    default:
      return
  }
}
