import React from 'react'

import Text from '../../../../common/components/Text'

import './SelectField.scss'

function SelectField ({ label, data }) {

  return (
    <div className={'select-field'}>
      {label && <Text outerClassName={'label'} component={'label'} multiline>
        {label}
      </Text>}
      {data && <Text outerClassName={'data'} className={'data-inner'} component={'label'} multiline>
        {data}
      </Text>}
    </div>
  )
}

export default SelectField