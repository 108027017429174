import React from 'react'

const SvgDoorIndicator = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g
      transform="translate(7 6)"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect
        stroke={props.color}
        fill={props.fillColor}
        fillRule="nonzero"
        width={10.08}
        height={12.96}
        rx={0.72}
      />
      <path d="M7.514 5.67h2.16"
            stroke={props.color}/>
    </g>
  </svg>
)

export default SvgDoorIndicator
