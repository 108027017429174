import React, { Component } from 'react'
import { optimiseFloorPlacing } from '../../actions/actions'
import { connect } from 'react-redux'
import Button from '../../../common/components/Button'
import IconButton from '../../../common/components/IconButton'
import { analyticsService } from 'common/analytics/analytics.service'
import { iconTypes } from '../../../common/components/icons/IconFactory'
import { withTranslation } from 'react-i18next'

import './OptimizeButton.scss'

class OptimizeButton extends Component {

  handleClick () {
    this.props.optimiseFloorPlacing()
    //analyticsService.trackEvent('Optimize Floor Placing')
  }

  render () {
    const { t } = this.props

    if (this.props.iconOnly) {
      return (
        <IconButton icon={iconTypes.optimize} border
                    tooltip={t('calculate')}
                    className={this.props.className}
                    onClick={() => this.handleClick()}
                    disabled={!this.props.selectedFloorId}/>
      )
    }

    return (
      <Button icon={iconTypes.optimize}
              className={'optimize-button' + (this.props.className ? this.props.className : '')}
              onClick={() => this.handleClick()}
              disabled={!this.props.selectedFloorId}>
        {t('calculate')}
      </Button>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    selectedFloorId: state.appState.selectedFloorId,
    selectedBuildingId: state.appState.selectedBuildingId,
  }
}

let mapDispatchToProps = {
  optimiseFloorPlacing: optimiseFloorPlacing,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(OptimizeButton))