import React from 'react'

const SvgHigh = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      d="M12.235 14.09l.21-7.2h-1.44l.21 7.2h1.02zm-.51 3.075c-.525 0-.885-.405-.885-.93 0-.54.375-.93.885-.93.54 0 .885.39.885.93 0 .525-.345.93-.885.93zm-3.49-3.075l.21-7.2h-1.44l.21 7.2h1.02zm-.51 3.075c-.525 0-.885-.405-.885-.93 0-.54.375-.93.885-.93.54 0 .885.39.885.93 0 .525-.345.93-.885.93zm8.51-3.075l.21-7.2h-1.44l.21 7.2h1.02zm-.51 3.075c-.525 0-.885-.405-.885-.93 0-.54.375-.93.885-.93.54 0 .885.39.885.93 0 .525-.345.93-.885.93z"
      fill="#373B51"
    />
  </svg>
)

export default SvgHigh
