import React from 'react'

const SvgDrawPolygonInactive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd" stroke="#ff5e0f" strokeLinejoin="round">
      <path d="M8 23l6-14 10 10"/>
      <path
        fill="#FFF"
        d="M6.995 21.5a.492.492 0 00-.495.495v2.01c0 .273.216.495.495.495h2.01a.492.492 0 00.495-.495v-2.01a.492.492 0 00-.495-.495h-2.01zm16-4a.492.492 0 00-.495.495v2.01c0 .273.216.495.495.495h2.01a.492.492 0 00.495-.495v-2.01a.492.492 0 00-.495-.495h-2.01zm-10-10a.492.492 0 00-.495.495v2.01c0 .273.216.495.495.495h2.01a.492.492 0 00.495-.495v-2.01a.492.492 0 00-.495-.495h-2.01z"
      />
    </g>
  </svg>

export default SvgDrawPolygonInactive