import React, { Component } from 'react'
import { connect } from 'react-redux'
import { dialogNames } from '../../../floorplan/constants'
import Dialog, { DialogButtonGroup } from '../../../common/components/Dialog'
import { fetchXSLXFileAllFloors, fetchXSLXFileSingleFloor } from '../../../floorplan/actions/actions'
import Button from '../../../common/components/Button'

class XLSXDownloadDialog extends Component {

  render () {

    let dialogType = this.props.dialogType === dialogNames.XSLX_FURNITURE_FILE_DOWNLOAD ? 'furniture' : 'department'

    return (

      <Dialog onClick={this.close}
              title={'Export XSLX ' + dialogType + ' file'}
              text={'You can export the active floor only or the complete building'}
              className={'xslx-file-download-dialog'}>
        <DialogButtonGroup>
          <Button onClick={this.close}>Cancel</Button>
          <Button onClick={() => this.singleDownload()}
                  disabled={!this.props.floorId}
                  variant={'contained'}>
            Active
          </Button>
          <Button onClick={() => this.multiDownload()} variant={'contained'}>All</Button>
        </DialogButtonGroup>
      </Dialog>
    )
  }

  close = () => {
    this.props.onClose()
  }

  singleDownload () {
    let type
    if (this.props.dialogType === dialogNames.XSLX_FURNITURE_FILE_DOWNLOAD)
      type = 'furniture'
    else
      type = 'department'

    this.close()
    this.props.fetchXSLXFileSingleFloor(this.props.buildingId, this.props.floorId, type)
  }

  multiDownload () {
    let type
    if (this.props.dialogType === dialogNames.XSLX_FURNITURE_FILE_DOWNLOAD)
      type = 'furniture'
    else
      type = 'department'

    this.close()
    this.props.fetchXSLXFileAllFloors(this.props.buildingId, type)
  }
}

let mapStateToProps = (state) => {
  return {
    buildingId: state.appState.selectedBuildingId,
    floorId: state.appState.selectedFloorId,
    buildings: state.buildings.buildings,
  }
}

let mapDispatchToProps = {
  fetchXSLXFileSingleFloor: fetchXSLXFileSingleFloor,
  fetchXSLXFileAllFloors: fetchXSLXFileAllFloors,
}

export default connect(mapStateToProps, mapDispatchToProps)(XLSXDownloadDialog)