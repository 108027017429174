import React, { Component } from 'react'
import './InputGroup.scss'

class InputGroup extends Component {
  render () {

    let styleClass = this.props.className ? ' ' + this.props.className : ''

    return (
      <div className={'input-group' + styleClass}>
        {this.props.children}
      </div>
    )
  }
}

export default InputGroup