import React, { Component } from 'react'
import Dialog, { DialogButtonGroup, DialogContent } from '../../../../common/components/Dialog'
import ListMenu from '../../../../common/components/ListMenu'
import IconButton from '../../../../common/components/IconButton'
import { ListMenuItem } from '../../../../common/components/ListMenuItem'
import './AdjacencyCreateDialog.scss'
import { connect } from 'react-redux'
import { getConnectableDepartments, getConnectableZoneMarkers } from '../../../reducers/connectables'
import { getSelectedBuildingId, getSelectedFloorId } from '../../../../common/redux/appstate/appstate-reducer'
import { createConnection } from '../../../actions/actions'
import { priorityValues } from '../../../constants'
import { getDepartmentListItemStyle } from '../../../../common/utils'
import Text from '../../../../common/components/Text'
import Button from '../../../../common/components/Button'
import { iconTypes } from '../../../../common/components/icons/IconFactory'

class AdjacencyCreateDialog extends Component {

  constructor (props) {
    super(props)

    this.state = {
      connection: {
        priority: priorityValues.MIDDLE,
        connectableIdA: props.departmentId,
        connectableIdB: null,
      },
    }
  }

  mapDepartmentToMenuList () {
    return this.props.connectableDepartments.map((department) => {
      return (
        <ListMenuItem key={department.id}
                      onClick={() => this.setConnectableId(department.id)}
                      className={this.isSelected(department.id) ? 'selected-connectable' : null}
                      style={getDepartmentListItemStyle(department.color)} bordered>
          {department.displayName}
        </ListMenuItem>)
    })
  }

  mapMarkerToMenuList () {
    return this.props.connectableZoneMarkers
      .map((marker) => {
        return (
          <ListMenuItem key={marker.id}
                        icon={marker.icon} iconActive
                        onClick={() => this.setConnectableId(marker.id)}
                        className={this.isSelected(marker.id) ? 'selected-connectable' : null} bordered>
            {marker.displayName}
          </ListMenuItem>)
      })

  }

  render () {

    return (
      <Dialog onCancel={this.close}
              title={'Add Adjacency to Department or Marker'}
              className={'adjacency-create-dialog'}>

        <DialogContent>
          <div className={'adjacency-priority'}>
            <Text>Priority</Text>
            <div>
              <IconButton icon={iconTypes.low} onClick={() => this.setPriority(priorityValues.LOW)} className={'list-menu-icons-item-icon-button'}
                          selected={this.state.connection.priority === priorityValues.LOW}/>
              <IconButton icon={iconTypes.middle} onClick={() => this.setPriority(priorityValues.MIDDLE)} className={'list-menu-icons-item-icon-button'}
                          selected={this.state.connection.priority === priorityValues.MIDDLE}/>
              <IconButton icon={iconTypes.high} onClick={() => this.setPriority(priorityValues.HIGH)} className={'list-menu-icons-item-icon-button'}
                          selected={this.state.connection.priority === priorityValues.HIGH}/>
            </div>
          </div>
          <div className={'adjacency-marker-selection'}>
            <h2>Marker</h2>
            <ListMenu className={'adjacency-marker-list'} emptyListMessage={'No connectable Markers available'}>
              {this.mapMarkerToMenuList()}
            </ListMenu>
          </div>
          <div className={'adjacency-department-selection'}>
            <h2>Departments</h2>
            <ListMenu className={'adjacency-department-list'} emptyListMessage={'No connectable Departments available'}>
              {this.mapDepartmentToMenuList()}
            </ListMenu>
          </div>
        </DialogContent>

        <DialogButtonGroup>
          <Button onClick={this.close}>Cancel</Button>
          <Button onClick={this.confirm} disabled={!this.connectionIsValid()} variant={'contained'}>Confirm</Button>
        </DialogButtonGroup>
      </Dialog>
    )
  }

  setPriority (priority) {
    this.setState({ connection: { ...this.state.connection, priority: priority } })
  }

  setConnectableId (connectableId) {
    this.setState({ connection: { ...this.state.connection, connectableIdB: connectableId } })
  }

  close = () => {
    this.props.onClose()
  }

  confirm = () => {
    const { buildingId, floorId } = this.props
    this.props.createConnection(buildingId, floorId, this.state.connection)
    this.close()
  }

  connectionIsValid () {
    const { connection } = this.state
    return connection.priority && connection.connectableIdA && connection.connectableIdB
  }

  isSelected (connectableId) {
    return connectableId === this.state.connection.connectableIdB
  }
}

let mapStateToProps = (state, ownProps) => {
  return {
    connectableDepartments: getConnectableDepartments(state, ownProps.departmentId),
    connectableZoneMarkers: getConnectableZoneMarkers(state),
    buildingId: getSelectedBuildingId(state),
    floorId: getSelectedFloorId(state),
  }
}

let mapDispatchToProps = {
  createConnection: createConnection,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdjacencyCreateDialog)