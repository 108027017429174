import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ColorPicker from './ColorPicker'
import IconButton from './IconButton'
import onClickOutSide from 'react-onclickoutside'
import { Manager, Popper, Reference } from 'react-popper'

import './ColorInput.scss'
import { iconTypes } from './icons/IconFactory'

class ColorInput extends Component {

  constructor (props) {
    super(props)
    this.state = {
      expanded: false,
      color: null
    }
  }

  render () {

    const leftSideStyle = this.props.leftSide ? ' leftSide' : ''

    return (
      <Manager>
        <div className={'color-picker-input'} onClick={(event => event.stopPropagation())}>
          <Reference>
            {({ ref }) => (
              <div ref={ref} className={'color-picker-expandable'}>
                <div className={'color-preview'} onClick={() => this.handleExpandButtonClicked()} style={{ background: this.state.color ? this.state.color.hex : this.props.color }}/>
                {!this.props.disabled &&
                <IconButton className={'expand-button'} icon={iconTypes.triangleDown} onClick={() => this.handleExpandButtonClicked()}/>}
              </div>
            )}
          </Reference>

          {this.state.expanded &&
          <Popper placement="bottom-start">
            {({ ref, style, placement }) => (
              <div ref={ref} style={{ ...style, zIndex: 3 }} data-placement={placement}>
                <ColorPicker className={leftSideStyle} color={this.props.color} onChange={(color) => this.handleColorChange(color)}/>
              </div>
            )}
          </Popper>}
        </div>
      </Manager>
    )
  }

  handleExpandButtonClicked () {
    this.setState({ expanded: !this.state.expanded })
  }

  handleColorChange (color) {
    this.setState({
      color: color,
    })
  }

  // Needed for OnClickOutside HOC
  handleClickOutside (event) {
    if (this.props.onChange && this.state.color)
      this.props.onChange(this.state.color)

    this.setState({
      color: null,
      expanded: false,
    })

  }

}

ColorInput.propTypes = {
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default onClickOutSide(ColorInput)