// Global constants
export const CUSTOM_STYLE_ID = '___'

// Action Type suffixes used by Redux Promise Middleware
export const PENDING = '_PENDING'
export const FULFILLED = '_FULFILLED'
export const REJECTED = '_REJECTED'

// View names for Router
export const viewNames = {
  PROJECTS: 'projects',
  ONBOARDING: 'onboarding',
  FLOORPLAN: 'floorplan',
  FLOORS: 'floors',
  EDIT: 'edit',
  PEOPLE: 'people',
  SETS: 'sets',
  PATTERNS: 'patterns',
  SETTINGS: 'settings',
}

export const viewNamesOnBoarding = {
  START: 'start',
  STEP_1: 'step_1',
  STEP_2: 'step_2',
  STEP_3: 'step_3',
  PREVIEW: 'preview',
  TESTFIT: 'testfit',
}

export const globalActionTypes = {
  GET_LICENSE: 'GET_LICENSE',
  GET_LICENSE_FULFILLED: 'GET_LICENSE' + FULFILLED,

}
