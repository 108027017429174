import { globalActionTypes } from '../globalConstants'

const initialState = {
  hasActiveLicense: true,
}

function license (state = initialState, action) {
  switch (action.type) {

    case globalActionTypes.GET_LICENSE_FULFILLED:

      let lic
      if (action.payload.data)
        lic = action.payload.data.hasActiveLicense
      else
        lic = true
      return {

        hasActiveLicense: lic,
        info: action.payload.data,

      }

    default:
      return initialState
  }
}

export default license