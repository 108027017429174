import React from 'react'
import * as PropTypes from 'prop-types'
import IconButton from '../components/IconButton'
import Button from '../components/Button'
import './Notification.scss'
import Text from '../components/Text'
import Timer from '../utils/timer'
import { iconTypes } from './icons/IconFactory'

function NotificationContent ({ dismissible, title = '', message, action, onDismiss }) {
  return (
    <div className={'notification-content'}>
      <div className={'notification-text'}>
        <div className="notification-title">
          <Text outerClassName={'notification-title-text'}>{title}</Text>
          {(dismissible && !action) && <IconButton icon={iconTypes.close} onClick={onDismiss}/>}
        </div>
        <Text multiline className="notification-message">{message}</Text>
      </div>
      {action &&
      <div className={'notification-action'}>
        <Button className={'notification-action-button'}
                variant={'contained'}
                onClick={action.onClick}>
          {action.title ? action.title : 'Resolve'}
        </Button>
      </div>}
    </div>
  )
}

export default class Notification extends React.Component {

  componentDidMount () {
    if (this.props.notification.dismissible) {
      this.timer = new Timer(() => this.hideNotification(), 5000)
    }
  }

  onMouseEnter () {
    if (this.timer) {
      this.timer.pause()
    }
  }

  onMouseLeave () {
    if (this.timer) {
      this.timer.resume()
    }
  }

  hideNotification () {
    const { onDismiss, notification } = this.props
    this.setState({ show: false })

    if (this.timer) {
      this.timer.clear()
    }

    onDismiss(notification)
  }

  render () {
    let { className, children, action, notification: { severity, ...rest } } = this.props

    const CustomContent = children ? React.Children.only(children) : null

    return (

      <div className={`notification ${className} ${severity}`}
           onMouseLeave={() => this.onMouseLeave()}
           onMouseEnter={() => this.onMouseEnter()}>
        {CustomContent ? <CustomContent notification={this.props.notification}/> : <NotificationContent {...rest} action={action} onDismiss={() => this.hideNotification()}/>}
      </div>

    )
  }

}

Notification.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  notification: PropTypes.object,
  action: PropTypes.object,
  onDismiss: PropTypes.func,
}

Notification.defaultProps = { className: '' }