import React, { Component } from 'react'
import { updateOrganisationalUnitWorkStyle } from '../../../actions/actions'
import { connect } from 'react-redux'
import WorkStyleLensController from './WorkStyleLensController'
import MenuSection from '../../../../common/components/MenuSection'
import { getSelectedOrganisationalUnit } from '../../../reducers/organisationalUnits'

class WorkStyleLens extends Component {
  render () {
    return (
      <MenuSection title={'Workstyle'}>
        <WorkStyleLensController lens={this.props.unit.workStyle}
                                 id={this.props.unit.id}
                                 onChange={(workStyle) => this.props.updateWorkStyle(this.props.unit.buildingId, this.props.unit.floorId, this.props.unit.id, workStyle)}/>
      </MenuSection>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    unit: getSelectedOrganisationalUnit(state),
  }
}

let mapDispatchToProps = {
  updateWorkStyle: updateOrganisationalUnitWorkStyle,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkStyleLens)