import * as React from 'react'
const SvgUploadInactive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1527 20.2647C11.4274 20.7301 10.5615 21.0005 9.63158 21.0005C7.07363 21.0005 5 18.9538 5 16.4291C5 13.9809 6.94986 11.9822 9.40042 11.8633L9.71205 11.8584C10.1983 8.86455 12.8284 6.57617 16 6.57617C19.1716 6.57617 21.8017 8.86455 22.2879 11.8597L22.3684 11.8577C24.9264 11.8577 27 13.9044 27 16.4291C27 18.9538 24.9264 21.0005 22.3684 21.0005C21.4382 21.0005 20.572 20.7299 19.8463 20.264" stroke="#00293B" stroke-linejoin="round"/>
    <path d="M12.1527 20.2647C11.4274 20.7301 10.5615 21.0005 9.63158 21.0005C7.07363 21.0005 5 18.9538 5 16.4291C5 13.9809 6.94986 11.9822 9.40042 11.8633L9.71205 11.8584C10.1983 8.86455 12.8284 6.57617 16 6.57617C19.1716 6.57617 21.8017 8.86455 22.2879 11.8597L22.3684 11.8577C24.9264 11.8577 27 13.9044 27 16.4291C27 18.9538 24.9264 21.0005 22.3684 21.0005C21.4382 21.0005 20.572 20.7299 19.8463 20.264" stroke="#00293B"/>
    <path d="M16 14.9512L16 26.1431" stroke="#00293B" stroke-linejoin="round"/>
    <path d="M19.5355 18.2504L16 14.7148L12.4645 18.2504" stroke="#00293B" stroke-linejoin="round"/>
  </svg>
)

export default SvgUploadInactive