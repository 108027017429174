import { getSnappingPosition } from '../helper/MagicGuideLine'
import { createGeometryByRepresentationType } from '../factories/GeometryFactory'
import { PolyEdgeController } from './PolyEdgeController'
import { removeAllChildren } from '../../../common/three/drawing/drawConstants'
import { geometryTypes } from '../config/GeometryTypes'

export class TapeMeasureController extends PolyEdgeController {

  setActive (active) {
    /*
    this.group.children.forEach(child => child.visible = active)
    //removeAllChildren(this.group)
    console.log("TapeMeasuree: "+active )
     */
  }

  onLeftMouseDown (event, geometry, currentGeometries) {
    if (!geometry) {
      return this.onLeftMouseDownNew(event, currentGeometries)
    } else {
      return this.onLeftMouseDownEdit(event, geometry, currentGeometries)
    }
  }

  onLeftMouseDownNew (event, currentGeometries) {
    console.log("scale new")
    removeAllChildren(this.group)
    const scale = createGeometryByRepresentationType(this.representationType, this.group)

    return super.onLeftMouseDownNew(event, scale, currentGeometries)
  }

  onLeftMouseDownEdit (event, scale, currentGeometries) {
    console.log("scale.edit")
    if (!scale) {
      return false
    }
    console.log("currentGeometries: "+currentGeometries.length)
    switch (scale.getAction()) {
      case scale.actions.addVertex:
        if (currentGeometries.length !== 1)
          return false
        console.log("scale.add.vertex: "+currentGeometries.length)

        scale.setAction(scale.actions.idle)
        //removeAllChildren(this.group)
        this.removeGeometryFromCurrentGeometries(scale, currentGeometries)
        this.floorPlanerController.saveChanges()
        return true
      case scale.actions.select:
        console.log("scale.edit.down.select")
        return this.onLeftMouseDownEditSelect(scale)
      default:
        return false
    }
  }

  onLeftMouseDownEditSelect (scale) {
    console.log("scale.edit.select")
    const raycastHitObject = this.floorPlanerController.getRaycastHitObjectForGeometryByGeometryTypes(scale,
      [geometryTypes.vertex, geometryTypes.edge])

    if (!raycastHitObject)
      return false

    switch (raycastHitObject.geometryType) {
      case geometryTypes.vertex:
        scale.setDraggedVertex(raycastHitObject)
        scale.setAction(scale.actions.dragVertex)
        return true
      default:
        return false
    }
  }

  onMouseMoveCurrentGeometry (event, geometry, currentGeometries) {
    console.log("scale.move.current")
    console.log(geometry.getAction())
    switch (geometry.getAction()) {

      case geometry.actions.addVertex:
        if (currentGeometries.length !== 1)
          return false

        const lastVertex = geometry.getLastVertex()
        geometry.updateVertex(this.getCurrentPosition(event, lastVertex, geometry), lastVertex)
        return true
      case geometry.actions.dragVertex:
        geometry.updateVertex(this.getCurrentPosition(event, geometry.draggedVertex, geometry))
        return true
      default:
        return false
    }
  }

  onLeftMouseUp (event, geometry) {
    console.log("scale.edit.select")
    return super.onLeftMouseUp(event, geometry)
  }

  getCurrentPosition (event, ignoreVertex = null, ignoreGeometry = null) {
    console.log("scale.get.current.position")
    const otherScaleVertices = this.group.children
      .filter(geometry => geometry !== ignoreGeometry)
      .map(child => child.getVertices())
      .flat()

    const otherPositions = this.floorPlanerController.getAllVertices()
      .filter(vertex => !otherScaleVertices.includes(vertex) && ignoreVertex !== vertex)
      .map(vertex => vertex.position)
    return getSnappingPosition(event, this.floorPlanerController.getCurrentPosition(), otherPositions)
  }

}