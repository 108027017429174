import React from 'react'

const SvgLockedIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill={props.color} fillRule="evenodd" transform="translate(6.5 5)">
      <path d="M4.8 8.4H6v1.8a.6.6 0 01-1.2 0V8.4z"/>
      <circle cx={5.4} cy={8.4} r={1.2}/>
      <path d="M0 5.4a.6.6 0 01.6-.6h9.6a.6.6 0 01.6.6v5.4a2.4 2.4 0 01-2.4 2.4h-6A2.4 2.4 0 010 10.8V5.4zm1.2.6v4.8A1.2 1.2 0 002.4 12h6a1.2 1.2 0 001.2-1.2V6H1.2z"/>
      <path d="M1.8 5.4a.6.6 0 00.6.6h6a.6.6 0 00.6-.6V3.6a3.6 3.6 0 10-7.2 0v1.8zM3 4.8V3.6a2.4 2.4 0 114.8 0v1.2H3z"/>
    </g>
  </svg>
)

export default SvgLockedIcon
