import React from 'react'

const SvgDrawBlockedInactive = props =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <g
      stroke={props.color}
      transform="translate(6 6)"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx={10} cy={10} r={10}/>
      <path d="M17.006 2.995L3.004 16.997"/>
    </g>
  </svg>

export default SvgDrawBlockedInactive