import store from '../../store'
import { floorPlanActionTypes } from '../../floorplan/constants'
import equal from 'fast-deep-equal'
import { representationTypes } from '../three/config/RepresentationTypes'
import { splitArea } from '../../floorplanner/FloorPlanner/Example'
import { drawingToolUIActionTypes } from '../actions/UI/drawingToolUIActions'
import { drawingActionTypes } from '../../buildings/actions/drawingActions'
import { tools } from '../constants/Tools'

const defaultGeometries = {
  geometries: splitArea,
  selectedGeometriesUuid: [],
  //currentTool: representationTypes.select,

}

const initialState = {
  image: {
    base64Image: '',
    scale: 1,
  },
  storedGeometries: [],
  currentStoreIndex: 0,
  currentTool: tools.select,
  undoAvailable: false,
  redoAvailable: false,
  currentMaxOnboardingIndex: 1,
  drawingHelp: null,
}

function drawingToolReducer (state = initialState, action) {
  //console.log("REDUCER ACTION:")
  //console.log(action)

  switch (action.type) {
    case floorPlanActionTypes.FETCH_DRAWING_FULFILLED:
      if (!action.payload || !action.payload.data) {
        return {
          ...state,
          currentStoreIndex: 0,
          storedGeometries: [defaultGeometries],
          undoAvailable: false,
          redoAvailable: false,
        }
      }

      const {data:geometries} = action.payload

      geometries.forEach(geometry => {

          if (geometry.type){
            geometry.type = geometry.type.toLowerCase()
          }

        })



      return {
        ...state,
        currentStoreIndex: 0,
        storedGeometries: [{
          geometries: geometries,
          selectedGeometriesUuid: [],
          currentTool: representationTypes.select,
        }],
        undoAvailable: false,
        redoAvailable: false,
      }
    case floorPlanActionTypes.FETCH_BASE_64_IMAGE_FULFILLED:
      const image = {
        base64Image: action.payload.data.base64Image,
        scale: action.payload.data.scale,
      }

      return {
        ...state,
        image: image,
      }

    // Frontend
    case drawingToolUIActionTypes.STORE_GEOMETRIES:
      const oldGeometries = getCurrentGeometryObjects({ drawingTool: state })

      if (equal(oldGeometries, action.payload)) {
        return { ...state }
      }

      const storedGeometries = state.storedGeometries.splice(0, state.currentStoreIndex + 1)

      storedGeometries.push(action.payload)

      const currentStoreIndex = storedGeometries.length - 1
      const undoAvailable = currentStoreIndex > 0 && storedGeometries.length > 1

      return {
        ...state,
        storedGeometries: storedGeometries,
        currentStoreIndex: currentStoreIndex,
        undoAvailable: undoAvailable,
        redoAvailable: false,
      }
    case drawingToolUIActionTypes.UNDO_GEOMETRIES:
      const undoStoreIndex = state.currentStoreIndex > 0 ? state.currentStoreIndex - 1 : 0

      const undoAvailableAfterUndo = undoStoreIndex > 0 && state.storedGeometries.length > 1
      const redoAvailableAfterUndo = undoStoreIndex < state.storedGeometries.length - 1

      return {
        ...state,
        currentStoreIndex: undoStoreIndex,
        undoAvailable: undoAvailableAfterUndo,
        redoAvailable: redoAvailableAfterUndo,
      }
    case drawingToolUIActionTypes.REDO_GEOMETRIES:
      const redoStoreIndex = state.currentStoreIndex + 1 < state.storedGeometries.length ? state.currentStoreIndex + 1 : state.currentStoreIndex

      const undoAvailableAfterRedo = redoStoreIndex > 0 && state.storedGeometries.length > 1
      const redoAvailableAfterRedo = redoStoreIndex < state.storedGeometries.length - 1

      return {
        ...state,
        currentStoreIndex: redoStoreIndex,
        undoAvailable: undoAvailableAfterRedo,
        redoAvailable: redoAvailableAfterRedo,
      }

    case drawingToolUIActionTypes.SET_CURRENT_TOOL:
      //console.log("setCurrentToolll: "+action.currentTool)
      return {
        ...state,
        currentTool: action.currentTool,
      }

    case drawingToolUIActionTypes.SET_SCALE_FACTOR: {
      console.log("SET_SCALE_FACTOR")
      const image = state.image
      const updateFactor = action.scaleFactor / image.scale

      image.scale = action.scaleFactor

      const geometries = JSON.parse(JSON.stringify(getCurrentGeometryObjects({ drawingTool: state })))

      geometries.geometries.filter(geometry => geometry.vertices)
        .forEach(geometry => {
          geometry.vertices.forEach(vertex => {
            vertex.x *= updateFactor
            vertex.y *= updateFactor
          })
          if (geometry.properties) {
            const { radius, width } = geometry.properties
            if (radius) {
              geometry.properties.radius = radius * updateFactor
            }
            if (width) {
              geometry.properties.width = width * updateFactor
            }
          }
        })

      const storedGeometries = state.storedGeometries.splice(0, state.currentStoreIndex + 1)

      storedGeometries.push(geometries)

      const currentStoreIndex = storedGeometries.length - 1
      const undoAvailable = currentStoreIndex > 0 && storedGeometries.length > 1

      return {
        ...state,
        image,
        storedGeometries: storedGeometries,
        currentStoreIndex: currentStoreIndex,
        undoAvailable: undoAvailable,
        redoAvailable: false,
      }
    }

    case drawingToolUIActionTypes.SET_MAX_ONBOARDING_INDEX:
      //console.log("setMAXXXXOnboarding:")
      //console.log(action.payload)
      return {
        ...state,
        currentMaxOnboardingIndex: action.payload,
      }

    case drawingToolUIActionTypes.SET_DRAWING_HELP:

      console.log("setDrawingHELP")
      return {
        ...state,
        drawingHelp: action.payload,
      }

    default:
      return state
  }
}

export default drawingToolReducer

export function getCurrentGeometryObjects (state) {
  //console.log("stored geometries")
  //console.log(state)

  const { currentStoreIndex, storedGeometries } = state.drawingTool
  return currentStoreIndex < storedGeometries.length ? storedGeometries[currentStoreIndex] : []
}

export function getGeometryObjectsToSave () {
  console.log("getGeometryObjectsToSave")
  /*
  let geoms=getCurrentGeometryObjects(store.getState())

  let geometries=geoms.geometries
  console.log("n: "+geometries.length)
  console.log(geometries)
  */
  return getCurrentGeometryObjects(store.getState())
}