import * as React from 'react'
const SvgOuterWallsActive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6" y="6" width="20" height="20" fill="url(#paint0_linear_12_4316)" stroke="#DA5B2B"/>
    <path d="M6 11H26" stroke="#DA5B2B" stroke-linejoin="round"/>
    <path d="M6 16H26" stroke="#DA5B2B" stroke-linejoin="round"/>
    <path d="M6 21H26" stroke="#DA5B2B" stroke-linejoin="round"/>
    <path d="M16 6V11" stroke="#DA5B2B" stroke-linejoin="round"/>
    <path d="M11.5 11V16" stroke="#DA5B2B" stroke-linejoin="round"/>
    <path d="M11.5 21V26" stroke="#DA5B2B" stroke-linejoin="round"/>
    <path d="M20.5 11V16" stroke="#DA5B2B" stroke-linejoin="round"/>
    <path d="M20.5 21V26" stroke="#DA5B2B" stroke-linejoin="round"/>
    <path d="M16 16V21" stroke="#DA5B2B" stroke-linejoin="round"/>
    <defs>
      <linearGradient id="paint0_linear_12_4316" x1="25.9997" y1="26.002" x2="25.9997" y2="6.002" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
    </defs>
  </svg>
)

export default SvgOuterWallsActive
