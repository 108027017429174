import React, { Component } from 'react'

import DropdownMenu from '../../common/components/DropdownMenu'
import { ListMenuItem } from '../../common/components/ListMenuItem'
import ListMenu from '../../common/components/ListMenu'
import { connect } from 'react-redux'
import { iconTypes } from '../../common/components/icons/IconFactory'

const supportTitle = ''
const supportBody =
  'Dear Haworth CoDesigner Support,%0D%0A' +
  '%0D%0A' +
  'Unfortunately, I am experiencing problems using CoDesigner and need your support. I try to explain below what does not work:%0D%0A' +
  '%0D%0A' +
  '%0D%0A' +
  '%0D%0A' +
  '%0D%0A' +
  '%0D%0A' +
  'I’d appreciate your help with that. Please use one of the contact details below:%0D%0A' +
  'Phonenumber: %0D%0A' +
  'E-Mail: %0D%0A' +
  '%0D%0A' +
  'I eagerly awaiting your replay. Thank you very much in advance and%0D%0A' +
  'best regards %0D%0A'

class HelpMenu extends Component {

  render () {

    return (
      <DropdownMenu
        onClose={() => this.closeMenu()}
        className={'help-menu'}>
        <ListMenu className={this.props.advancedSettings ? 'dark-mode' : ''}>
          <ListMenuItem onClick={() => this.handleWebsite()} secondIcon={iconTypes.website} iconColor={this.props.advancedSettings ? '#ffffff' : null}>Website</ListMenuItem>
          <ListMenuItem onClick={() => this.handleSupport()} secondIcon={iconTypes.support} iconColor={this.props.advancedSettings ? '#ffffff' : null}>Support</ListMenuItem>
          <ListMenuItem onClick={() => this.handleWiki()} secondIcon={iconTypes.documentation} iconColor={this.props.advancedSettings ? '#ffffff' : null}>Wiki</ListMenuItem>
          <ListMenuItem onClick={() => this.handleELearning()} secondIcon={iconTypes.eLearning} iconColor={this.props.advancedSettings ? '#ffffff' : null}>E-Learning</ListMenuItem>
        </ListMenu>
      </DropdownMenu>
    )
  }

  handleWebsite () {
    window.open('https://info-codesigner.haworth.com', '_blank')
    this.closeMenu()
  }

  handleSupport () {
    window.location.href = 'mailto:CoDesigner@Haworth.com?subject=' + supportTitle + '&body=' + supportBody
    this.closeMenu()
  }

  handleWiki () {
    window.open('https://wiki.codesigner365.com/', '_blank')
    this.closeMenu()
  }

  handleELearning () {
    window.open('https://info-codesigner.haworth.com/learningtutorials/', '_blank')
    this.closeMenu()
  }

  closeMenu () {
    this.props.onClose()
  }

}

let mapStateToProps = (state) => {
  return {
    advancedSettings: state.settings.appSettings.advancedSettings,
  }
}

export default connect(mapStateToProps)(HelpMenu)


