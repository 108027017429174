import { CUSTOM_STYLE_ID, floorPlanActionTypes } from '../../../floorplan/constants'
import { getSelectedOrganisationalUnit } from '../../../floorplan/reducers/organisationalUnits'
import { getOrganisationalUnit } from '../organisationunits/organisational-units-reducer'
import { applicationProfilesActionTypes } from './application-profiles/application-profiles-actions'
import { layoutStylesActionTypes } from './layout-styles/layout-styles-actions'
import { patternStylesActionTypes } from './pattern-styles/pattern-styles-actions'

const initialState = {
  layoutStyles: [],
  patternStyles: [],
  workplaceTypes: [],
  patternTypes: [],
  applicationProfiles: [],
  tags: [],
  error: null,
}

const defaultCustomStyle = {
  name: 'Custom',
  id: CUSTOM_STYLE_ID,
  custom: true,
}

function stylesReducer (state = initialState, action) {

  switch (action.type) {

    case floorPlanActionTypes.FETCH_TAGS_PENDING: // fall trough
    case floorPlanActionTypes.FETCH_WORKPLACE_TYPES_PENDING: // fall trough
    case floorPlanActionTypes.FETCH_PATTERN_TYPES_PENDING: // fall trough
    case floorPlanActionTypes.FETCH_PATTERN_STYLES_PENDING: // fall trough
    case applicationProfilesActionTypes.FETCH_APPLICATION_PROFILES_PENDING: // fall trough
    case floorPlanActionTypes.FETCH_LAYOUT_STYLES_PENDING:
      return { ...state }

    case layoutStylesActionTypes.FETCH_LAYOUT_STYLES_FULFILLED:
    case floorPlanActionTypes.FETCH_LAYOUT_STYLES_FULFILLED:
      return {
        ...state,
        layoutStyles: action.payload.data,
      }

    case floorPlanActionTypes.FETCH_WORKPLACE_TYPES_FULFILLED:
      return {
        ...state,
        workplaceTypes: action.payload.data,
      }

    case patternStylesActionTypes.FETCH_PATTERN_TYPES_FULFILLED:
    case floorPlanActionTypes.FETCH_PATTERN_TYPES_FULFILLED:
      return {
        ...state,
        patternTypes: action.payload.data,
      }

    case patternStylesActionTypes.FETCH_PATTERN_STYLES_FULFILLED:
    case floorPlanActionTypes.FETCH_PATTERN_STYLES_FULFILLED:
      return {
        ...state,
        patternStyles: action.payload.data,
      }

    case floorPlanActionTypes.FETCH_TAGS_FULFILLED:
      return {
        ...state,
        tags: action.payload.data,
      }

    case applicationProfilesActionTypes.FETCH_APPLICATION_PROFILES_FULFILLED:
      return {
        ...state,
        applicationProfiles: action.payload.data,
      }

    case floorPlanActionTypes.FETCH_TAGS_REJECTED: // fall through
    case floorPlanActionTypes.FETCH_WORKPLACE_TYPES_REJECTED: // fall through
    case floorPlanActionTypes.FETCH_PATTERN_TYPES_REJECTED: // fall through
    case floorPlanActionTypes.FETCH_PATTERN_STYLES_REJECTED: // fall through
    case applicationProfilesActionTypes.FETCH_APPLICATION_PROFILES_REJECTED: // fall through
    case applicationProfilesActionTypes.UPLOAD_APPLICATION_PROFILES_REJECTED: //fall through
    case floorPlanActionTypes.FETCH_LAYOUT_STYLES_REJECTED:
      return { ...state, error: action.payload }

    default:
      return { ...state }
  }

}

// TODO CODE CLEAN UP
export function getAvailableLayoutStyles (state, unitId) {

  let layoutStyles = [...state.styles.layoutStyles]

  let selectedOrganisationalUnit = unitId ? getOrganisationalUnit(state, unitId) : getSelectedOrganisationalUnit(state)

  if (selectedOrganisationalUnit.layoutStyle && selectedOrganisationalUnit.layoutStyle.custom) {
    layoutStyles.push({ ...selectedOrganisationalUnit.layoutStyle })
  } else {
    layoutStyles.push({ ...defaultCustomStyle })
  }

  return layoutStyles

}

export function getAvailablePatternStyles (state) {
  return [...state.styles.patternStyles].filter(style => style.id !== CUSTOM_STYLE_ID)

}

export function getAvailableApplicationProfiles (state) {
  return [...state.styles.applicationProfiles].filter(style => style.id !== CUSTOM_STYLE_ID)
}

export default stylesReducer
