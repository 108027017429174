import React from 'react'

const SvgFolderIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#373B51"
        d="M5.19 6.262v10.811c.006.227.102.623.677.64 1.365.038 12.352.017 12.462.017.317-.013.481-.24.481-.682V9.224c0-.017 0-.253-.139-.4-.105-.11-.287-.168-.539-.168-1.322 0-6.862-.021-6.862-.021a.57.57 0 01-.41-.177L8.704 6.262H5.19zM12.153 19c-2.807 0-5.649-.008-6.318-.025-1.35-.038-1.837-1.157-1.835-1.902V5.631C4 5.286 4.266 5 4.595 5h4.347c.154 0 .301.063.412.177l2.159 2.196c1.073.004 5.462.02 6.619.02.74 0 1.172.308 1.404.564.48.535.466 1.22.463 1.296L20 17.048c0 .745-.274 1.186-.503 1.43a1.694 1.694 0 01-1.167.514h-.003c-.231 0-3.183.008-6.174.008z"
      />
      <path d="M0 0h24v24H0z"/>
    </g>
  </svg>
)

export default SvgFolderIcon
