import React from 'react'

import Dialog, { DialogButtonGroup } from './Dialog'
import Button from './Button'

import './RouteLeavingDialog.scss'
import i18n from 'i18next';

export default function RouteLeavingDialog ({ onDiscard = () => {},
                                              discardButton = i18n.t('discard'),
                                              onConfirm = () => {},
                                              confirmButton = i18n.t('confirm'),
                                              onCancel = () => {},
                                              hideModal = () => {},
                                              lines = [i18n.t('are_you_sure')],
                                              title = i18n.t('confirm_changes'),
                                              ...rest }) {
  return (
    <Dialog {...rest} className={'route-leaving-dialog'} hideModal={hideModal} onCancel={onCancel} lines={lines} title={title}>
      <DialogButtonGroup className={'route-leaving-dialog-button-group'}>
        <Button variant={'outlined'}
                onClick={() => {
                  onDiscard()
                  hideModal()
                }}>
          {discardButton}
        </Button>
        <Button variant={'contained'}
                onClick={() => {
                  onConfirm()
                  hideModal()
                }}>
          {confirmButton}
        </Button>
      </DialogButtonGroup>
    </Dialog>
  )
}