import React from 'react'

const SvgTagsIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      fill="#373B51"
      fillRule="evenodd"
      d="M14.186 6.696a1 1 0 01.923-.04l3.318 1.547a1 1 0 01.574.994l-.318 3.648a1 1 0 01-.497.78l-8.382 4.84a1 1 0 01-1.366-.367l-3-5.196a1 1 0 01.366-1.366l8.382-4.84zM15.5 9.134a1 1 0 101 1.732 1 1 0 00-1-1.732z"
    />
  </svg>
);

export default SvgTagsIcon
