import React from 'react'
import { trim } from 'lodash'

import './Chip.scss'
import Text from './Text'
import Icon from './icons/IconFactory'

function Chip ({ className, disabled, field, label, name, onChange, value, onDelete, deleteIcon = 'delete' }) {

  const checkedInput = field && field.value !== undefined && field.value !== null ? field.value : value
  const onChangeInput = field && field.onChange ? field.onChange : onChange
  const nameInput = field && field.name ? field.name : name
  const labelInput = field && field.label ? field.label : label

  const trimmedLabel = trim(labelInput)
    .toLowerCase()
  const id = `chip-${nameInput}-${trimmedLabel}`
  const styleClasses = className ? ' ' + className : ''
  const chipStyleClass = onDelete ? 'with-icon' : ''

  return (
    <div className={'chip' + styleClasses}>
      <input type={'checkbox'}
             id={id}
             name={nameInput}
             checked={checkedInput}
             onChange={onChangeInput}
             disabled={disabled}/>
      <label htmlFor={id} className={chipStyleClass}>
        <Text>{labelInput}</Text>
      </label>
      {onDelete && <button className={'chip-icon'} onClick={onDelete}>
        <Icon name={deleteIcon} inverted={checkedInput} disabled={disabled}/>
      </button>}
    </div>
  )
}

export default Chip