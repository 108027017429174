import { organisationalUnitsActionTypes } from './organisational-units-actions'
import { listToTree } from '../../utils'
import { getSelectedBuildingId } from '../appstate/appstate-reducer'
import { undoActionTypes } from '../undo/undo-actions'

let initialState = {
  lastAddedUnit: null,
  units: [],
  unitsTree: [],
}

function organisationalUnitsReducer (state = initialState, action) {

  switch (action.type) {

    case organisationalUnitsActionTypes.FETCH_ORGANISATIONAL_UNITS_FULFILLED:
    case undoActionTypes.FETCH_ORGANISATIONAL_UNITS_AFTER_UNDO_FULFILLED:
    case undoActionTypes.UNDO_OPTIMISATION_FULFILLED:

      return {
        ...state,
        units: action.payload.data,
        unitsTree: listToTree(action.payload.data, 'parentId', 'organisationalUnits'),
      }
    case organisationalUnitsActionTypes.ADD_ORGANISATIONAL_UNIT_FULFILLED:
      return {
        ...state,
        lastAddedUnit: action.payload.data,
      }
    case undoActionTypes.UPDATE_STACKING:
      getAssignedDepartmentsOfSelectedBuilding(state)
      return { ...state }
    default:
      return { ...state }
  }

}

export function getOrganisationalUnit (state, unitId) {
  if (!unitId)
    unitId = state.appState.selectedOrganisationalUnitId

  return state.organisationalUnits.units.find(unit => unit.id === unitId) || {}
}

export function getOrganisationalUnitsHierarchyTree (state) {
  return state.organisationalUnits.unitsTree
}

export function getAssignedDepartments (state) {
  const departments = state.organisationalUnits.units.filter(organisationalUnit =>
    organisationalUnit.hierarchicalLevel === 1)
    .filter(department => !!department.assigned)
  return departments
}

export function getAssignedDepartmentsOfSelectedBuilding (state) {
  const selectedBuildingId = getSelectedBuildingId(state)
  const departments = getAssignedDepartments(state)
    .filter(department => department.buildingId === selectedBuildingId)
  return departments
}

export default organisationalUnitsReducer