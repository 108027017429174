import React from 'react'

const SvgEditNameIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z"/>
      <path
        fill="#373B51"
        d="M19.397 18.792H5.603a.602.602 0 00-.603.604c0 .334.27.604.603.604h13.794c.334 0 .603-.27.603-.604a.602.602 0 00-.603-.604m-13.16-4.75l6.703-6.737 1.72 1.726-6.71 6.726H6.237v-1.714zm8.54-8.585l1.727 1.73-.988.991-1.724-1.727.984-.994zM5.634 16.965h2.563a.6.6 0 00.426-.177l9.158-9.173a.604.604 0 000-.853l-2.58-2.585a.602.602 0 00-.852 0l-9.141 9.19a.618.618 0 00-.177.426v2.568c0 .334.27.604.603.604z"
      />
    </g>
  </svg>
)

export default SvgEditNameIcon
