const keyEvents = {
    keyDown: 'keydown',
    keyUp: 'keyup',
}

export function addKeyEvents(onKeyDown, onKeyUp) {
    if (onKeyDown) {
        document.addEventListener(keyEvents.keyDown, onKeyDown, true)
    }
    if (onKeyUp) {
        document.addEventListener(keyEvents.keyUp, onKeyUp, true)
    }
}

export function removeKeyEvents(onKeyDown, onKeyUp) {
    if (onKeyDown) {
        document.removeEventListener(keyEvents.keyDown, onKeyDown, true)
    }
    if (onKeyUp) {
        document.removeEventListener(keyEvents.keyUp, onKeyUp, true)
    }
}

export const keyCodes = {
    digit1: 'Digit1',
    digit2: 'Digit2',
    digit3: 'Digit3',
    digit4: 'Digit4',
    digit5: 'Digit5',
    digit6: 'Digit6',
    digit7: 'Digit7',
    digit8: 'Digit8',
    digit9: 'Digit9',
    digit0: 'Digit0',

    backspace: 'Backspace',
    escape: 'Escape',
    enter: 'Enter',
    space: 'Space',
    altLeft: 'AltLeft',
    altRight: 'AltRight',
    controlLeft: 'ControlLeft',
    metaLeft: 'MetaLeft',

    arrowLeft: 'ArrowLeft',
    arrowRight: 'ArrowRight',
    arrowUp: 'ArrowUp',
    arrowDown: 'ArrowDown',

    f12: 'F12',

    keyA: 'KeyA',
    keyB: 'KeyB',
    keyD: 'KeyD',
    keyF: 'KeyF',
    keyG: 'KeyG',
    keyI: 'KeyI',
    keyL: 'KeyL',
    keyO: 'KeyO',
    keyR: 'keyR',
    keyS: 'KeyS',
    keyV: 'KeyV',
    keyY: 'KeyY',
    keyZ: 'KeyZ',

}