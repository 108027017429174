import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog, { DialogButtonGroup, DialogContent } from '../../common/components/Dialog';
import Button from '../../common/components/Button';
import FileInput from '../../common/components/FileInput';
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

function UploadDialog({ upload, title, fileEndings = '', onClose, replace }) {
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleUpload = () => {
    onClose(); // Annahme, dass `close` eigentlich `onClose` aufrufen soll
    upload(file, replace)

  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onCancel={handleClose}
            title={title}
            className={'uploader'}>
      <DialogContent>
        <FileInput name="Upload file" accept={fileEndings} onChange={setFile} />
      </DialogContent>
      <DialogButtonGroup>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleUpload} disabled={!file} variant={'contained'}>Confirm</Button>
      </DialogButtonGroup>
    </Dialog>
  );
}

UploadDialog.propTypes = {
  upload: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  fileEndings: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  replace: PropTypes.bool, // Angenommen basierend auf dem Aufruf von upload im ursprünglichen Code
};

export default UploadDialog;
