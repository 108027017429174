import React, { Component } from 'react'
import MenuSection from '../../../../common/components/MenuSection'
import { updateOrganisationalUnitOfficeLens } from '../../../actions/actions'
import { connect } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import OfficeLensRadioButtonGroup from './OfficeLensRadioButtonGroup'
import AutoSave from '../../../../common/components/AutoSave'
import { getSelectedOrganisationalUnit } from '../../../reducers/organisationalUnits'

class OfficeLens extends Component {

  render () {
    return (
      <MenuSection title={'Office'}>
        <Formik initialValues={{ ...this.props.unit.officeLens, unit: this.props.unit }}>
          <Form>
            <AutoSave onSave={(values) => this.props.updateOfficeLens(values.unit.buildingId, values.unit.floorId, values.unit.id, values)}/>

            <MenuSection title={'Meetings'}>
              <Field label={'Informal Meetings'} name={'informalMeetings'} component={OfficeLensRadioButtonGroup}/>
              <Field label={'Small Formal Meetings'} name={'smallFormalMeetings'} component={OfficeLensRadioButtonGroup}/>
              <Field label={'Big Formal Meetings'} name={'bigFormalMeetings'} component={OfficeLensRadioButtonGroup}/>
            </MenuSection>

            <MenuSection title={'Focused Activities'}>
              <Field label={'Space for Focused Work'} name={'spaceForFocusedWork'} component={OfficeLensRadioButtonGroup}/>
            </MenuSection>

            <MenuSection title={'Adjacencies'}>
              <Field label={'Importance of Neighborhoods'} name={'importanceOfNeighborhoods'} component={OfficeLensRadioButtonGroup}/>
            </MenuSection>

            <MenuSection title={'Storage'}>
              <Field label={'Personal Workplace Storage'} name={'personalWorkplaceStorage'} component={OfficeLensRadioButtonGroup}/>
              <Field label={'Additional Team Storage'} name={'additionalTeamStorage'} component={OfficeLensRadioButtonGroup}/>
            </MenuSection>

          </Form>
        </Formik>
      </MenuSection>
    )
  }

}

let mapStateToProps = (state) => {
  return {
    unit: getSelectedOrganisationalUnit(state),
  }
}

let mapDispatchToProps = {
  updateOfficeLens: updateOrganisationalUnitOfficeLens,
}

export default connect(mapStateToProps, mapDispatchToProps)(OfficeLens)