import {materialTypes} from '../common/MaterialTypes'
import {getMaterial} from '../common/Materials'

export const outlineConfig = {
    vertex: {
        innerActiveMaterial: getMaterial(materialTypes.orange),
        innerInactiveMaterial: getMaterial(materialTypes.transparent),
        outerActiveMaterial: getMaterial(materialTypes.orange),
        outerInactiveMaterial: getMaterial(materialTypes.transparent),
        innerSize: 0.1,
        outerSize: 0.3,
        innerSegments: 32,
        outerSegments: 32,
    },
    edge: {
        innerActiveMaterial: getMaterial(materialTypes.orange),
        innerInactiveMaterial: getMaterial(materialTypes.transparent),
        outerActiveMaterial: getMaterial(materialTypes.transparent),
        outerInactiveMaterial: getMaterial(materialTypes.transparent),
        innerSize: 0.1,
        outerSize: 0.3,
    },
    properties: {
      offsetLeft:  .2,
      offsetRight: .2,

      //offsetLeft:  .2,
      //offsetRight: .2,
    }
}