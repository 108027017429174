import React from 'react'

const SvgAddUnit = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M3 6h4v4H3z"/>
      <path
        d="M4.013 7.927l.81.81a.25.25 0 00.354 0l.81-.81a.25.25 0 00-.176-.427H4.189a.25.25 0 00-.176.427z"
        fill={props.color}
      />
      <path d="M5 10h4v4H5z"/>
      <path
        d="M6.013 11.927l.81.81a.25.25 0 00.354 0l.81-.81a.25.25 0 00-.176-.427H6.189a.25.25 0 00-.176.427zM7.5 9a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-11zm2 4a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-9zm2 4a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-7z"
        fill={props.color}
      />
    </g>
  </svg>
)

export default SvgAddUnit