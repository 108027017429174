import React from 'react'
import { uuidv4 } from './utils'

export function createSVG (width, height, polyList, polyLineList = null, className = 'svg', offSetX = 0, offSetY = 0) {
  const polygon = drawSVGElements(width, height, polyList, polyLineList, null, offSetX, offSetY)

  return <svg className={className} width={width} height={height}>{polygon}</svg>
}

export function createSVGApplication (width, height, polylist, polyLineList, className = 'svg', bounds, wallcode) {
  let polys = drawSVGElements(width, height, polylist, polyLineList, bounds)

  let minX = 100000
  let minY = 100000
  let maxX = -100000
  let maxY = -100000
  if (bounds) {
    minX = bounds[0]
    minY = bounds[1]
    maxX = bounds[2]
    maxY = bounds[3]

  } else {

    for (let a = 0; a < polylist.length; a++) {
      for (let b = 0; b < polylist[a].length; b++) {
        if (isOdd(b)) {
          if (polylist[a][b] < minY) minY = polylist[a][b]
          if (polylist[a][b] > maxY) maxY = polylist[a][b]
        } else {
          if (polylist[a][b] < minX) minX = polylist[a][b]
          if (polylist[a][b] > maxX) maxX = polylist[a][b]
        }
      }
    }
  }
  let scaleX = (width - 10) / maxY
  let scaleY = (height - 10) / maxY
  let scale = scaleX
  if (scaleX > scaleY) scale = scaleY

  scale *= 0.95

  let centerX = (width - (maxX * scale)) / 2
  let centerY = (height - (maxY * scale)) / 2
  let wheight = 0.05 * scale

  let wallLengthBeforeDoor = 0.6
  let doorSize = 0.8

  let wcs = wallcode.split('')

  if (wcs[0] === '1' || wcs[0] === 'd') polys.push(<rect className={'wall'} x={minX * scale + centerX - wheight} y={minY * scale + centerY - wheight} width={(maxX - minX) * scale + (3 * wheight)}
                                                         height={wheight} key={'linebot'}/>)
  if (wcs[1] === '1' || wcs[1] === 'd') polys.push(<rect className={'wall'} x={minX * scale + centerX - wheight} y={minY * scale + centerY} width={wheight} height={(maxY - minY) * scale}
                                                         key={'linelft'}/>)
  if (wcs[2] === '1' || wcs[2] === 'd') polys.push(<rect className={'wall'} x={minX * scale + centerX - wheight} y={maxY * scale + centerY + wheight} width={(maxX - minX) * scale + (3 * wheight)}
                                                         height={wheight} key={'linetop'}/>)
  if (wcs[3] === '1' || wcs[3] === 'd') polys.push(<rect className={'wall'} x={maxX * scale + centerX + wheight} y={minY * scale + centerY} width={wheight} height={(maxY - minY) * scale}
                                                         key={'lineright'}/>)

  if (wcs[0] === 'd') polys.push(<rect className={'door'} x={minX * scale + (maxX - minX) * scale + centerX - doorSize * scale - wallLengthBeforeDoor * scale} y={minY * scale + centerY - wheight}
                                       width={doorSize * scale} height={wheight} key={'doorbot'}/>)
  if (wcs[1] === 'd') polys.push(<rect className={'door'} x={minX * scale + centerX - wheight} y={minY * scale + centerY + doorSize * scale} width={wheight} height={doorSize * scale}
                                       key={'doorleft'}/>)
  if (wcs[2] === 'd') polys.push(<rect className={'door'} x={minX * scale + centerX + wallLengthBeforeDoor * scale} y={maxY * scale + centerY + wheight} width={doorSize * scale} height={wheight}
                                       key={'doortop'}/>)
  if (wcs[3] === 'd') polys.push(<rect className={'door'} x={maxX * scale + centerX + wheight} y={minY * scale + centerY + (maxY - minY) * scale - doorSize * scale - wallLengthBeforeDoor * scale}
                                       width={wheight} height={doorSize * scale} key={'doorright'}/>)

  return <svg className={className} width={width} height={height}>{polys}</svg>
}

function drawSVGElements (width, height, polyList, polyLineList, bounds, offSetX = 0, offSetY = 0) {
  let minX = Number.MAX_VALUE
  let minY = Number.MAX_VALUE
  let maxX = Number.MIN_VALUE
  let maxY = Number.MIN_VALUE
  let multi = 1

  if (bounds) {
    minX = bounds[0]
    minY = bounds[1]
    maxX = bounds[2]
    maxY = bounds[3]
    multi = 0.95

  } else {
    for (let a = 0; a < polyList.length; a++) {
      for (let b = 0; b < polyList[a].length; b++) {
        if (isOdd(b)) {
          if (polyList[a][b] < minY) minY = polyList[a][b]
          if (polyList[a][b] > maxY) maxY = polyList[a][b]
        } else {
          if (polyList[a][b] < minX) minX = polyList[a][b]
          if (polyList[a][b] > maxX) maxX = polyList[a][b]
        }
      }
    }
  }

  let zeroCenter = false
  if (minX < 0) zeroCenter = true

  let itemWidth = maxX
  let itemDepth = maxY

  if (zeroCenter) {
    itemWidth -= minX
    itemDepth -= minY
  }

  let scaleX = (width - 10) / itemWidth
  let scaleY = (height - 10) / itemDepth
  let scale = scaleX > scaleY ? scaleY : scaleX

  scale *= multi

  let centerX = (width - (maxX * scale)) / 2
  let centerY = (height - (maxY * scale)) / 2
  if (zeroCenter) {
    centerX += 5
    centerY += 5
  }

  let polys = polyList.map((polygon, index) => (
    drawLine(polygon, index, scale, centerX + offSetX, centerY + offSetY)
  ))

  let lines = polyLineList.map((polygon, index) => (
    drawLine(polygon, index, scale, centerX + offSetX, centerY + offSetY, false)
  ))

  let list = [...polys, ...lines]

  return list
}

function isOdd (num) {return num % 2}

function drawLine (polygon, index, scale, centerX, centerY, autoClose = true) {

  let scaled = scalePolygon(polygon, scale)
  let moved = movePolygon(scaled, centerX, centerY)
  let uuid = uuidv4()

  let converted = convertToSVGPoints(moved)

  if (autoClose)
    return <polygon key={uuid} points={converted}/>
  else
    return <polyline key={uuid} points={converted}/>
}

function convertToSVGPoints (polygons) {
  let outstring = ''
  for (let k = 0; k < polygons.length; k++) {

    outstring += polygons[k]
    if ((k / 2) === (Math.floor(k / 2))) outstring += ','
    else outstring += ' '

  }

  return outstring
}

function movePolygon (polygon, centerX, centerY) {

  let out = []
  for (let a = 0; a < polygon.length; a++) {
    if (isOdd(a)) {
      out.push(parseFloat(polygon[a]) + parseFloat(centerY))
    } else {
      out.push(parseFloat(polygon[a]) + parseFloat(centerX))
    }
  }
  return out
}

function scalePolygon (polygon, scale) {

  let factor = scale
  let tempPoly = []
  for (let l = 0; l < polygon.length; l++) {
    tempPoly.push(Math.floor(polygon[l] * factor * 10) / 10)
  }

  return tempPoly
}