import { representationTypes } from '../config/RepresentationTypes'

export const controllerActions = {
  onLeftMouseDown: 'onLeftMouseDown',
  onLeftMouseDoubleClick: 'onLeftMouseDoubleClick',
  onLeftMouseUp: 'onLeftMouseUp',

  onRightMouseDown: 'onRightMouseDown',
  onRightMouseDoubleClick: 'onRightMouseDoubleClick',
  onRightMouseUp: 'onRightMouseUp',

  onMouseMove: 'onMouseMove',
  onMouseWheel: 'onMouseWheel',

  onBackspaceDown: 'onBackspaceDown',
  onEnterDown: 'onEnterDown',
  onEscapeDown: 'onEscapeDown',

  onArrowLeftDown: 'onArrowLeftDown',
  onArrowRightDown: 'onArrowRightDown',
  onArrowUpDown: 'onArrowUpDown',
  onArrowDownDown: 'onArrowDownDown',

  onAltDown: 'onAltDown',
  onAltUp: 'onAltUp',

  onKeyADown: 'onKeyADown',
}

export class GeometryController {

  constructor (floorPlanerController, representationType, group) {
    this.floorPlanerController = floorPlanerController
    this.representationType = representationType
    this.group = group
  }

  setActive (active) {

  }

  removeGeometryFromGroup (geometry, currentGeometries) {
    console.log("remove Geometry")

    console.log("curretGeometries"+currentGeometries.length);
    let edges=geometry.edgeGroup.children
    console.log("NEdges: "+edges.length)

    const represetationGroupDoor = this.floorPlanerController.representationGroups.get(representationTypes.door)
    const geometriesDoor = represetationGroupDoor.children
    //const representationGroupWindow= this.floorPlanerController.representationGroups.get(representationTypes.window)
    //const geometriesWindows = representationGroupWindow.children

    // delete doors on EdgeGroup
    console.log("nDoors: "+geometriesDoor.length)
    //console.log("nWindows: "+geometriesWindows.length)
    for(let doorIndex=geometriesDoor.length-1; doorIndex>=0; doorIndex--){
      let door=geometriesDoor[doorIndex]
      let referenceEdge=door.referenceEdge
      if(referenceEdge===null){
        console.log("NO REFERENCE EDGE!!")
      }else{
        for(let edgeIndex=0; edgeIndex<edges.length; edgeIndex++) {
          let cEdge = edges[edgeIndex]
          console.log("cedge:" + cEdge + "  refEdge:" + referenceEdge)
          console.log("cedge:" + cEdge + "  refEdge:" + referenceEdge.parent)
          if (referenceEdge.parent === cEdge) {
            geometriesDoor.splice(doorIndex, 1)
          }
        }
      }
    }

    console.log("nDoors: "+geometriesDoor.length)
    geometry.dispose()
    if (this.group){
      this.group.remove(geometry)
    }

    this.removeGeometryFromCurrentGeometries(geometry, currentGeometries)

  }

  removeGeometryFromCurrentGeometries (geometry, currentGeometries) {
    console.log("remove from current")
    if (currentGeometries.includes(geometry)) {
      const index = currentGeometries.findIndex(geo => geo.uuid === geometry.uuid)
      currentGeometries.splice(index, 1)
    }
  }

}