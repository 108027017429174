import React, { Component } from 'react'

import DropdownMenu from '../../common/components/DropdownMenu'
import { viewNames } from '../../common/globalConstants'

import ListMenu from '../../common/components/ListMenu'
import { NavLinkDropDownItem } from './NavLinkDropDownItem'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

function FurnitureMenu ({projectId, onClose}) {



  const advancedSettings = useSelector(state => state.settings.appSettings.advancedSettings)

    const urlBase = projectId ? `/projects/${projectId}` : ''

    return (
      <DropdownMenu
        onClose={() => onClose()}
        className={'furniture-menu'}>
        <ListMenu className={advancedSettings ? 'dark-mode' : ''}>
          <NavLinkDropDownItem darkMode={advancedSettings}
                               onClose={() => onClose()}
                               urlBase={urlBase}
                               view={viewNames.PATTERNS}>
            Patterns
          </NavLinkDropDownItem>
          <NavLinkDropDownItem darkMode={advancedSettings}
                               onClose={() => onClose()}
                               urlBase={urlBase}
                               view={viewNames.SETS}>
            Furniture Sets
          </NavLinkDropDownItem>
        </ListMenu>
      </DropdownMenu>
    )


}


export default FurnitureMenu


