import React from 'react'

const SvgHeatmapLightInactive = props => (
  <svg className={props.className} width={36} height={36}>
    <defs>
      <filter
        id="heatmap-light-inactive_svg__b"
        width="103.8%"
        height="103.8%"
        x="-1.9%"
        y="-1.9%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur
          in="SourceAlpha"
          result="shadowBlurInner1"
          stdDeviation={0.5}
        />
        <feOffset in="shadowBlurInner1" result="shadowOffsetInner1"/>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.129971591 0"
        />
      </filter>
      <path
        id="heatmap-light-inactive_svg__a"
        d="M18 26a1 1 0 011 1v3a1 1 0 01-2 0v-3a1 1 0 011-1zm7.071-2.343l2.121 2.121a1 1 0 11-1.414 1.414l-2.121-2.121a1 1 0 011.414-1.414zm-12.728 0a1 1 0 010 1.414l-2.121 2.121a1 1 0 01-1.414-1.414l2.121-2.121a1 1 0 011.414 0zM18 11a7 7 0 110 14 7 7 0 010-14zm12 6a1 1 0 010 2h-3a1 1 0 010-2h3zM9 17a1 1 0 010 2H6a1 1 0 010-2h3zm18.192-8.192a1 1 0 010 1.414l-2.121 2.121a1 1 0 01-1.414-1.414l2.121-2.121a1 1 0 011.414 0zm-16.97 0l2.121 2.121a1 1 0 01-1.414 1.414l-2.121-2.121a1 1 0 011.414-1.414zM18 5a1 1 0 011 1v3a1 1 0 01-2 0V6a1 1 0 011-1z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#E8E9EB" xlinkHref="#heatmap-light-inactive_svg__a"/>
      <use
        fill="#000"
        filter="url(#heatmap-light-inactive_svg__b)"
        xlinkHref="#heatmap-light-inactive_svg__a"
      />
    </g>
  </svg>
);

export default SvgHeatmapLightInactive
