import * as React from 'react'
const SvgWindowsDisabled = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 6H24V25H8V6Z" stroke="#C4C6D2"/>
    <path d="M6 27H26V25H6V27Z" stroke="#C4C6D2"/>
    <path d="M16 6V25" stroke="#C4C6D2" stroke-linejoin="round"/>
    <path d="M24.5 15.5H7.5" stroke="#C4C6D2" stroke-linejoin="round"/>
  </svg>
)

export default SvgWindowsDisabled