import * as THREE from 'three'

import { createLinesFromList, createShapeFromeLineList, createShapeFromList, drawFaces, makeLinesFromQuads } from './threefunctions'
import { DPTMesh } from './classes'

import { getMaterial, materialTypes } from './common/materials'

import { removeAllChildren } from './drawing/drawConstants'
import { DrawPolygon } from './drawing/DrawPolygon'

const drawingHeight = {
  groundPlane: -.0001,
  outerWallPolygon: 0.0001,
  outerWall: 0.0002,
  innerWall: 0.0003,
  innerOpen: 0.0004,
  windowPolygon: 0.0005,
  window: 0.0006,
  stair: 0.0007,
  pillars: 0.0008,
}

export function addFloorPlan (floorGroup, floorPlan, outlines) {
  removeAllChildren(floorGroup)

  // Get materials
  const whiteMaterial = getMaterial(materialTypes.white)
  const greyMaterial = getMaterial(materialTypes.grey)
  const turquoiseMaterial = getMaterial(materialTypes.turquoise)
  const blackMaterial = getMaterial(materialTypes.black)

  //region White Ground Floor

  const { faceList } = floorPlan

  const groundPlaneFaces = drawFaces(faceList, whiteMaterial, drawingHeight.groundPlane)
  groundPlaneFaces.name = 'groundPlane'
  floorGroup.add(groundPlaneFaces)

  // Ground plane Shadow
  /*
  const shadowFaces = drawFaces(faceList, defaultMaterial)
  const shadowPlane = new THREE.Object3D()
  shadowPlane.position.set(1, drawingHeight.groundPlane, 1)
  shadowPlane.matrixAutoUpdate = false
  shadowPlane.castShadow = true
  shadowPlane.add(shadowFaces)
  floor.add(shadowPlane)
   */

  //endregion

  //region Line Segments

  // OuterWall InnerLines
  const { facadeLinesAsPosListsWithOpen } = floorPlan
  const outerWallInner = createLineSegments(facadeLinesAsPosListsWithOpen, blackMaterial)
  outerWallInner.position.set(0, drawingHeight.outerWall, 0)
  floorGroup.add(outerWallInner)

  // OuterWall OuterLines
  /*
  const { facadeOffsetPolygons } = floorPlan
  const outerWallOuter = createLineSegments(facadeOffsetPolygons, blackMaterial)
  outerWallInner.position.set(0, drawingHeight.outerWall, 0)
  floorGroup.add(outerWallOuter)
   */

  // Stairs inside & outside
  const { drawingLineList } = floorPlan
  const stairs = createLineSegments(drawingLineList, blackMaterial, true)
  stairs.position.set(0, drawingHeight.stair, 0)
  stairs.matrixAutoUpdate = false
  floorGroup.add(stairs)

  // Window lines
  const { openingWindowPolygons } = floorPlan
  const windowLines = makeLinesFromQuads(openingWindowPolygons, 0.7)
  const windows = createLineSegments(windowLines, blackMaterial)
  windows.position.set(0, drawingHeight.window, 0)
  floorGroup.add(windows)

  //endregion

  //region Mesh

  const { innerWallPolygons } = floorPlan

  const innerWall = createMeshFromShape(innerWallPolygons, blackMaterial)
  innerWall.position.set(0, drawingHeight.innerWall, 0)
  floorGroup.add(innerWall)

  const { innerOpenPolygons } = floorPlan
  const innerOpen = createMeshFromShape(innerOpenPolygons, whiteMaterial)
  innerOpen.position.set(0, drawingHeight.innerOpen, 0)
  floorGroup.add(innerOpen)

  //endregion

  //region DPTMesh

  //Outer Wall Polygon
  /*
  const { facadeLinesAsPosLists } = floorPlan
  const wallPolygons = makePolygonsFromPolys(facadeLinesAsPosLists, facadeOffsetPolygons)
  const outerWall = createDPTMeshFromShapeList(wallPolygons, greyMaterial)
  outerWall.position.set(0, drawingHeight.outerWallPolygon, 0)
  floorGroup.add(outerWall)
  */

  //Pillars
  const { pillarListAsPosLists } = floorPlan
  const pillar = createPillarMeshFromShapeList(pillarListAsPosLists, blackMaterial)
  pillar.position.set(0, 2, 0)
  floorGroup.add(pillar)

  //Wall shadow
  /*
  const wallShadow = createDPTMeshFromShapeList(wallPolygons, defaultMaterial)
  wallShadow.position.set(0, drawingHeight.wallShadow, 0)
  floorGroup.add(wallShadow)
  */

  // Draw Window Polygons
  const openingWindow = createDPTMeshFromShapeList(openingWindowPolygons, turquoiseMaterial, true)
  openingWindow.position.set(0, drawingHeight.windowPolygon, 0)
  openingWindow.matrixAutoUpdate = false
  floorGroup.add(openingWindow)

  /*
  // TODO: Entrance
  // Entrance
  const { openingEntrancePolygons } = floorPlan

  const openingEntrance = createDPTMeshFromShapeList(openingEntrancePolygons, greyMaterial)
  floorGroup.add(openingEntrance)
  */

  // TODO: Opening Room
  // Opening Room
  const { openingRoomPolygons } = floorPlan

  const openingRoom = createDPTMeshFromShapeList(openingRoomPolygons, greyMaterial)
  floorGroup.add(openingRoom)

  if (outlines) {
    outlines.forEach(outline => {
      const outlinePolygon = new DrawPolygon()
      outlinePolygon.setGeometryFromJSON(outline)
      outlinePolygon.position.y = -.05
      floorGroup.add(outlinePolygon)
    })
  }

  //endregion
}

function createLineSegments (lineList, material, matrixAutoUpdate = false) {
  const lines = createLinesFromList(lineList)
  const lineSegments = new THREE.LineSegments(lines, material)
  lineSegments.matrixAutoUpdate = matrixAutoUpdate

  return lineSegments
}

function createMeshFromShape (shapeList, material) {
  const shapes = createShapeFromeLineList(shapeList, 0.06)
  const shapeMesh = new THREE.Mesh(shapes, material)
  shapeMesh.scale.y = .02

  return shapeMesh
}

function createDPTMeshFromShapeList (shapeList, material, matrixAutoUpdate = false) {
  const shape = createShapeFromList(shapeList)
  const dptMesh = new DPTMesh(shape, material)
  dptMesh.matrixAutoUpdate = matrixAutoUpdate

  return dptMesh
}

function createPillarMeshFromShapeList (shapeList, material, matrixAutoUpdate = true) {
  const shape = createShapeFromList(shapeList)
  const dptMesh = new DPTMesh(shape, material)
  dptMesh.matrixAutoUpdate = matrixAutoUpdate

  return dptMesh
}