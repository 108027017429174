import * as React from 'react'

const SvgDrawBlockedActive = props =>
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect width={32} height={32} fill="#00293B" opacity={0.08} rx={2}/>
      <g stroke="#D14720" transform="translate(6 6)">
        <circle cx={10} cy={10} r={10} fill="url(#prefix__a)"/>
        <path strokeLinecap="round" d="M17.006 2.995L3.004 16.997"/>
      </g>
    </g>
  </svg>

export default SvgDrawBlockedActive