import { Mesh, PlaneGeometry } from 'three'
import { getMaterial } from '../common/Materials'
import { loadTextureFromBase64Image } from '../common/Textures'
import { getBuildingId, getFloorId, getProjectId, projectApi, projectVariationApi } from '../../../common/utils'
//import { setImageScaleWithPromise } from '../../../buildings/actions/actions.js'
//import { updateScaleWithPromise } from '../controller/DrawingToolController'
export class Base64ImageGeometry extends Mesh {

  constructor (base64Image, scale) {
    console.log("base64constructor")
    const geometry = new PlaneGeometry(100, 100)

    const imageMaterial = getMaterial()
    imageMaterial.name = 'image'
    imageMaterial.map = loadTextureFromBase64Image(base64Image)

    super(geometry, imageMaterial)
    this.imageMaterial = imageMaterial
    this.name = 'image'

    setTimeout(() => {
      this.imageMaterial.opacity = .4
      this.imageMaterial.transparent = true
      this.imageMaterial.visible = true

      let width = this.imageMaterial.map.image && this.imageMaterial.map.image.width > 0 ? this.imageMaterial.map.image.width : 1
      let height = this.imageMaterial.map.image && this.imageMaterial.map.image.height > 0 ? this.imageMaterial.map.image.height : 1

      let scaleWidth = (width > height ? 1 : width / height) * scale
      let scaleHeight = (height > width ? 1 : height / width) * scale

      console.log("IMAGESIZE: "+scaleWidth+" : "+scaleHeight)
      this.geometry = new PlaneGeometry(100 * scaleWidth, 100 * scaleHeight)
      this.scaleWidth=scaleWidth
      this.scaleHeight=scaleHeight
      this.imageScale=scale
      console.log("imageScale:" +this.imageScale)
      //updateScaleWithPromise(this.imageScale)
    }, 1)

  }

  getScale(){
    return this.imageScale
  }
  dispose() {
    this.geometry.dispose()
  }


}