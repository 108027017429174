import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({

    detection: {
      lookupCookie: 'language',
      cookieOptions: { path: '/' },
      caches: ['cookie'],
    },
    backend: {
      /* translation file path */
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json',
    },
    fallbackLng: 'en',
    lng: 'en',
    debug: false,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
            wait: true,
            useSuspense: false
        }
    })

i18n.defaultLocale = 'en'

export default i18n