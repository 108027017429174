import * as React from 'react'

const SvgDrawAxesInactive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd" stroke={props.color}>
      <path
        d="M8.885 19.372l.398-.397 4.242 4.243-.398.398-5.476 1.581c-.27.078-.428-.072-.348-.347l1.581-5.477zm.398-.397L22.014 6.243a.997.997 0 011.404-.003l2.842 2.842a.996.996 0 01-.003 1.404L13.525 23.218l-4.242-4.243z"/>
      <path d="M19.89 8.368L9.282 18.975l4.242 4.243L24.132 12.61zM8.176 21.825l2.498 2.498-3.024.875c-.27.078-.427-.072-.348-.348l.874-3.025zm10.66-12.403l4.114 4.114"/>
    </g>
  </svg>

export default SvgDrawAxesInactive