import * as React from 'react'
const SvgScaleActive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="7" y="18.5" width="18" height="6" fill="url(#paint0_linear_12_4315)" stroke="#DA5B2B"/>
    <path d="M7 24.285V15.5" stroke="#DA5B2B" stroke-linejoin="round"/>
    <path d="M25 24.285V15.5" stroke="#DA5B2B" stroke-linejoin="round"/>
    <path d="M7 10.5V8" stroke="#DA5B2B" stroke-linejoin="round"/>
    <path d="M25 10.5V8" stroke="#DA5B2B" stroke-linejoin="round"/>
    <path d="M7 14.25V11.75" stroke="#DA5B2B" stroke-linejoin="round"/>
    <path d="M25 14.25V11.75" stroke="#DA5B2B" stroke-linejoin="round"/>
    <path d="M9 9.5H23" stroke="#DA5B2B" stroke-linejoin="round"/>
    <path d="M10.2072 10.9142L8.79297 9.5L10.2072 8.08579" stroke="#DA5B2B"/>
    <path d="M21.9998 10.9142L23.4141 9.5L21.9998 8.08579" stroke="#DA5B2B"/>
    <defs>
      <linearGradient id="paint0_linear_12_4315" x1="24.9997" y1="24.5006" x2="24.9997" y2="18.5006" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
    </defs>
  </svg>
)

export default SvgScaleActive
