import * as React from 'react'
const SvgInnerWallsActive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6" y="6" width="20" height="20" fill="url(#paint0_linear_12_4317)" stroke="#DA5B2B"/>
    <circle cx="11" cy="13" r="1" fill="#DA5B2B"/>
    <circle cx="15" cy="11" r="1" fill="#DA5B2B"/>
    <circle cx="15" cy="17" r="1" fill="#DA5B2B"/>
    <circle cx="11" cy="21" r="1" fill="#DA5B2B"/>
    <circle cx="19" cy="21" r="1" fill="#DA5B2B"/>
    <circle cx="21" cy="16" r="1" fill="#DA5B2B"/>
    <circle cx="21" cy="11" r="1" fill="#DA5B2B"/>
    <defs>
      <linearGradient id="paint0_linear_12_4317" x1="25.9997" y1="26.002" x2="25.9997" y2="6.002" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
    </defs>
  </svg>
)

export default SvgInnerWallsActive
