import React from 'react'

const SvgDocumentation = props => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14 4.5a.5.5 0 01.367.16l3 3.25a.5.5 0 01.133.34V19a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5zm-.52 1H7.5v13h9V8.75h-2.52a.5.5 0 01-.492-.41l-.008-.09V5.5zm1.02 10a.5.5 0 01.09.992l-.09.008h-5a.5.5 0 01-.09-.992l.09-.008h5zm0-2.5a.5.5 0 01.09.992L14.5 14h-5a.5.5 0 01-.09-.992L9.5 13h5zm0-2.5a.5.5 0 01.09.992l-.09.008h-5a.5.5 0 01-.09-.992l.09-.008h5zm-.02-4.243V7.75h1.378L14.48 6.257z"
      fill={props.color}
      fillRule="evenodd"
    />
  </svg>
)

export default SvgDocumentation
