import React, { useEffect, useState } from 'react'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import './Table.scss'
import { Scrollbar } from 'react-scrollbars-custom'
import { random } from 'lodash/number'

function Table ({...props}) {

  const { columns, data, getSubRows, defaultColumn, onCellValueChange, getRowProps = () => ({}), getExpandedRows } = props

  const table = useReactTable(
    {
      defaultColumn,
      initialState: {
        expanded: {}, // Initialisieren Sie den Zustand für das Expanding
      },
      columns,
      data,
      getCoreRowModel: getCoreRowModel(),
      getSubRows,
    }
  );
  const rowModel = table.getCoreRowModel();
  const [expandedRows, setExpandedRows] = useState({});

  const styleClasses = props.className ? props.className : ''
  const isRowVisible = (row) => {
    const parent = rowModel.flatRows.find(r => r.id === row.parentId)
    const grandparent = row.parentId ? rowModel.flatRows.find(r => r.id === parent.parentId) : undefined
    if (row.depth === 0) return true
    if (row.parentId === undefined) return true
    if (parent.getIsExpanded() && (grandparent === undefined || grandparent.getIsExpanded())) return true

    return false
  }

  console.log(table)

  return (
    <div className={'ccd-table ' + styleClasses}>
      <div className='ccd-table-header'>
        <table border="0" cellPadding="0" cellSpacing="0">

          <thead>

          <tr>
            {columns.map(column => (
              <th key={column.id} className={column.className}>
                <span>{column.Header}</span>
              </th>
            ))}
          </tr>
          </thead>
        </table>
      </div>

      <div className='ccd-table-body'>
        <Scrollbar autoHide>
          <table border="0" cellPadding="0" cellSpacing="0">

            <tbody>

            {rowModel.flatRows.map((row, rowIndex) => (

              isRowVisible(row) ?
              <tr key={rowIndex}>
                {

                  row.getVisibleCells().map((cell, cellIndex) => {
                    const isVisible =  typeof cell.column.columnDef.showCell === 'function' ? cell.column.columnDef.showCell({ row, cell }) : true
                    return (

                      <td key={cell.id} className={cell.column.columnDef.className}>{isVisible ? flexRender(cell.column.columnDef.Cell, cell.getContext()) : null}</td>
                    )
                }
                )}
              </tr> : null
            ))}

            {/*
            {data.map((row, rowIndex) => (
              <tr key={rowIndex} >
                {columns.map((column, cellIndex) => {
                  // Hier können Sie den Wert der Zelle basierend auf dem Zugriff auf das Datenobjekt (row) und die Spalte (column) rendern
                  const cellContent = column.Cell({ row, cell: { value: row[column.accessor] }, onCellValueChange: () => {} });
                  return (
                    <td key={cellIndex} className={column.className}>
                      {cellContent}
                    </td>
                  );
                })}
              </tr>
            ))}*/}

            </tbody>
          </table>
        </Scrollbar>
      </div>
    </div>
  )
}

export default Table