export const materialTypes = {
  // Drawing
  background: 'background',
  placeholder: 'placeholder',
  departmentLocked: 'departmentLocked',

  cafeteriaMarker: 'cafeteriaMarker',
  customMarker: 'customMarker',
  entranceMarker: 'entranceMarker',
  staircaseMarker: 'staircaseMarker',
  toiletMarker: 'toiletMarker',

  openSpacePattern: 'openSpacePattern',
  roomsPattern: 'roomsPattern',
  infrastructurePattern: 'infrastructurePattern',
  blockedPattern: 'blockedPattern',

  openSpaceIcon: 'openSpaceIcon',
  roomsIcon: 'roomsIcon',
  infrastructureIcon: 'infrastructureIcon',
  blockedIcon: 'blockedIcon',

  // Floorplan
  default: 'default',
  defaultShadow: 'defaultShadow',
  defaultLine: 'defaultLine',

  selectionArea: 'selectionArea',

  red: 'red',
  green: 'green',
  cyan: 'cyan',
  blue: 'blue',
  pink: 'pink',
  black: 'black',
  yellow: 'yellow',
  turquoise: 'turquoise',
  grey: 'grey',
  white: 'white',
  orange: 'orange',
  transparent: 'transparent',
  brightOrange: 'brightOrange',

  scalePatternLow: 'scalePatternLow',
  scalePatternHigh: 'scalePatternHigh',

  spaceGeometry: 'spaceGeometry',
  blackWhitePattern: 'blackWhitePattern',

  random: 'random',
  whiteTransparent: 'whiteTransparent'
}