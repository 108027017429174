export const geometryTypes = {
    vertexGroup: 'vertexGroup',
    vertex: 'vertex',
    edgeGroup: 'edgeGroup',
    edge: 'edge',
    face: 'face',
    geometry: 'geometry',
    polygon: 'polygon',
    line: 'line',
    polyline: 'polyline',
    stamp: 'stamp',
    offsetFace: 'offsetFace',
}