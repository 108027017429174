import * as React from 'react'

const SvgDrawOutlineActive = props =>
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <linearGradient
        id="prefix__a"
        x1="49.998%"
        x2="49.998%"
        y1="100.01%"
        y2=".01%"
      >
        <stop offset="0%" stopColor="#FF8042"/>
        <stop offset="100%" stopColor="#FF5E0F"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect width={32} height={32} fill="#00293B" opacity={0.08} rx={2}/>
      <path
        d="M4 .495C4 .222 4.23 0 4.5 0h7c.276 0 .5.216.5.495v2.01c0 .273-.23.495-.5.495h-7a.494.494 0 01-.5-.495V.495zm-4 3C0 3.222.23 3 .5 3h7c.276 0 .5.216.5.495v2.01C8 5.778 7.77 6 7.5 6h-7a.494.494 0 01-.5-.495v-2.01zm4 3C4 6.222 4.23 6 4.5 6h7c.276 0 .5.216.5.495v2.01c0 .273-.23.495-.5.495h-7a.494.494 0 01-.5-.495v-2.01zm-4 3C0 9.222.23 9 .5 9h7c.276 0 .5.216.5.495v2.01c0 .273-.23.495-.5.495h-7a.494.494 0 01-.5-.495v-2.01zm4 3c0-.273.23-.495.5-.495h7c.276 0 .5.216.5.495V15H4.5a.494.494 0 01-.5-.495v-2.01zm4-9C8 3.222 8.23 3 8.5 3h7c.276 0 .5.216.5.495v2.01c0 .273-.23.495-.5.495h-7a.494.494 0 01-.5-.495v-2.01zm4 3c0-.273.23-.495.5-.495h7c.276 0 .5.216.5.495v2.01c0 .273-.23.495-.5.495h-7a.494.494 0 01-.5-.495v-2.01zm-4 3C8 9.222 8.23 9 8.5 9h7c.276 0 .5.216.5.495v2.01c0 .273-.23.495-.5.495h-7a.494.494 0 01-.5-.495v-2.01zm4 3c0-.273.23-.495.5-.495h7c.276 0 .5.216.5.495v2.01c0 .273-.23.495-.5.495H12v-2.505z"
        fill="url(#prefix__a)"
        stroke="#D14720"
        strokeLinejoin="round"
        transform="translate(6 8.5)"
      />
    </g>
  </svg>

export default SvgDrawOutlineActive