import * as React from 'react'
const SvgInfrastructureAreaActive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2783 10.1267C15.9196 9.97488 17.2491 10.088 17.6777 10.3556L17.7959 10.4348L17.9246 10.5367L18.0663 10.6639C18.0911 10.6874 18.1166 10.7121 18.1427 10.738L18.3084 10.9088C18.3375 10.9398 18.3674 10.9723 18.3982 11.0061L18.5931 11.2266L18.8107 11.4838L19.0533 11.7804L19.3232 12.1194C19.3706 12.1795 19.4192 12.2416 19.4692 12.3056C19.8991 12.8486 20.397 13.3238 20.9853 13.7085L22.0262 14.3874C22.5014 14.7042 22.6372 15.3152 22.3204 15.7904C22.0036 16.2656 21.3926 16.4013 20.9174 16.0845L19.8765 15.4057C19.1524 14.9305 18.4962 14.3421 17.9531 13.6633L17.4812 17.8073L17.7295 18.053C18.4495 18.7829 19.1132 19.5666 19.7149 20.3973C19.9955 20.7727 20.2199 21.536 20.4065 22.3867L20.514 22.9051L20.6134 23.4301L20.9096 25.1107C20.9221 25.1787 20.9345 25.2441 20.9468 25.3069C21.0319 25.9329 20.607 26.5142 19.9847 26.6231C19.3624 26.732 18.7653 26.3295 18.6328 25.7118L18.5671 25.3538L18.3047 23.8403L18.1767 23.1686L18.0861 22.7467C17.9624 22.2055 17.8275 21.7615 17.681 21.5583C17.0703 20.7372 16.3786 19.9808 15.6168 19.3004C15.6168 19.3004 15.1796 21.4131 14.7884 22.544C14.6417 22.9892 14.1452 23.6108 13.5554 24.2516L13.1924 24.6371L12.8194 25.0196L11.6125 26.2262C11.5641 26.2754 11.5176 26.3231 11.4732 26.3691C11.0093 26.798 10.2893 26.7833 9.84319 26.336C9.39704 25.8887 9.38429 25.1687 9.81432 24.7059L11.1592 23.3637L11.4805 23.0358L11.7861 22.7137L12.0649 22.4053C12.3703 22.0563 12.6247 21.5979 12.6969 21.3909C13.0243 20.4044 13.2228 19.5408 13.3419 18.5102L13.4285 17.414L13.7668 13.256C13.2463 13.7312 12.7938 14.2742 12.4317 14.9078L11.9339 15.813C11.7528 16.1524 11.3908 16.3334 11.0514 16.3334C10.893 16.3334 10.7119 16.2882 10.5762 16.2203C10.0783 15.9714 9.8973 15.3604 10.1462 14.8626L10.644 13.9574C11.4134 12.5545 12.5675 11.4004 13.9704 10.631C14.423 10.3854 14.859 10.2173 15.2783 10.1267Z" fill="url(#paint0_linear_12_4323)" stroke="#DA5B2B"/>
    <circle cx="16.6699" cy="7.25" r="2.25" fill="url(#paint1_linear_12_4323)" stroke="#DA5B2B"/>
    <defs>
      <linearGradient id="paint0_linear_12_4323" x1="22.4917" y1="26.683" x2="22.4917" y2="10.0603" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
      <linearGradient id="paint1_linear_12_4323" x1="18.9198" y1="9.50045" x2="18.9198" y2="5.00045" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
    </defs>
  </svg>
)

export default SvgInfrastructureAreaActive