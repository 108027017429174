import * as THREE from 'three'
//import GLTFLoader from 'three/examples/js/loaders/GLTFLoader' // in newer verisons "jsm instead of js
//import DRACOLoader from 'three/examples/js/loaders/DRACOLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

export function ModelImporter () {
  this.manager = new THREE.LoadingManager()

  // setup GLT Loader
  this.gltfLoader = new GLTFLoader(this.manager)
  this.dracoLoader = new DRACOLoader()
  this.dracoLoader.setDecoderPath('three/examples/js/libs/draco/')
  this.gltfLoader.setDRACOLoader(this.dracoLoader)

  this.loadFromFile = function (file, material = null) {


    let outputModel = new THREE.Group()
    this.gltfLoader.load(file, function (obj) {

      if (obj.scene) {
        if (obj.scene instanceof THREE.Group) {
          for (let i = 0; i < obj.scene.children.length; i++) {
            if (obj.scene.children[i] instanceof THREE.Mesh) {
              let temp = obj.scene.children[i]
              if (material) {
                temp.material = material
              }
              outputModel.add(temp)
            }
          }
        }
      }
    })

    return outputModel

  }

  this.loadFromURL = function (file) {
    /* to be implemented */
  }

}