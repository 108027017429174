import * as React from 'react'
const SvgRoomAreaActive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 16.5V5.5" stroke="#DA5B2B"/>
    <path d="M16 5.5L26 10.5V16.5V21.5L16 26.5L6 21.5V16.5V10.5L16 5.5Z" stroke="#DA5B2B"/>
    <path d="M16 16.5L26 21.5L16 26.5L6 21.5L16 16.5Z" fill="url(#paint0_linear_12_4322)"/>
    <path d="M16 16.5L16.2236 16.0528L16 15.941L15.7764 16.0528L16 16.5ZM6 21.5L5.77639 21.0528C5.607 21.1375 5.5 21.3106 5.5 21.5C5.5 21.6894 5.607 21.8625 5.77639 21.9472L6 21.5ZM26 21.5L26.2236 21.9472C26.393 21.8625 26.5 21.6894 26.5 21.5C26.5 21.3106 26.393 21.1375 26.2236 21.0528L26 21.5ZM16 26.5L15.7764 26.9472L16 27.059L16.2236 26.9472L16 26.5ZM16.2236 26.0528L6.22361 21.0528L5.77639 21.9472L15.7764 26.9472L16.2236 26.0528ZM25.7764 21.0528L15.7764 26.0528L16.2236 26.9472L26.2236 21.9472L25.7764 21.0528ZM15.7764 16.9472L25.7764 21.9472L26.2236 21.0528L16.2236 16.0528L15.7764 16.9472ZM6.22361 21.9472L16.2236 16.9472L15.7764 16.0528L5.77639 21.0528L6.22361 21.9472Z" fill="#DA5B2B"/>
    <path d="M13 18V11.5L10 13V19.5L13 18Z" stroke="#DA5B2B"/>
    <defs>
      <linearGradient id="paint0_linear_12_4322" x1="25.9997" y1="26.501" x2="25.9997" y2="16.501" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
    </defs>
  </svg>
)

export default SvgRoomAreaActive