import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import Text from './Text'

import './DropdownMenu.scss'

class DropdownMenu extends Component {

  // Needed for OnClickOutside HOC
  handleClickOutside (event) {
    this.props.onClose()
  }

  render () {
    const styleClass = this.props.className ? ' ' + this.props.className : ''

    return (
      <div className={'dropdown-menu' + styleClass}>
        {this.props.header &&
        <div className={'dropdown-menu-header'}>
          <Text component={'h4'} multiline>{this.props.header}</Text>
          <Text multiline>{this.props.subHeader ? this.props.subHeader : '\u00a0'}</Text>
        </div>}
        {this.props.children}
      </div>
    )
  }
}

export default onClickOutside(DropdownMenu)