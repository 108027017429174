import { floorPlanActionTypes } from '../../floorplan/constants'

const initialState = {
  floorPlanAnalytics: {
    fineGraphFaceList: [],
    fineAxes: [],
    fineAxesRoom: [],
    mainAxes: [],
    mainAxesRoom: [],
    floorFaceHighlight: [],
    analyticsAreas: [],
    doors: [],
    axisConnectors: [],
    axisOutCorners: [],
  },
}

function floorPlanAnalyticsReducer (state = initialState, action) {
  //console.log("floorPlanAnalyticsReducer: "+action.type)
  switch (action.type) {
    case floorPlanActionTypes.FETCH_FLOOR_PLAN_FULFILLED_ANALYTICS:
      console.log("FETCH FLOORPLAN ANALYTICS OLD "+state)
      return {
        ...state,
        floorPlanAnalytics: action.payload.data,
        timestamp: Date.now(),
      }

    case floorPlanActionTypes.FETCH_FLOOR_PLAN_FULFILLED_ANALYTICS_LIGHT:
      console.log("FETCH FLOORPLAN ANALYTICS LIGHTs")
      return {
        ...state,
        floorPlanAnalytics: action.payload.data,
        timestamp: Date.now(),
      }

    case floorPlanActionTypes.CLEAR_PROJECT:
      return { ...initialState }

    default:
      return state
  }
}

export default floorPlanAnalyticsReducer