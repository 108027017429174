import React from 'react'

const SvgHeatmapActivityActive = props => (
  <svg className={props.className} width={36} height={36}>
    <defs>
      <linearGradient
        id="heatmap-activity-active_svg__a"
        x1="82.2%"
        x2="18%"
        y1="100%"
        y2="-13.1%"
      >
        <stop offset="0%" stopColor="#56D179"/>
        <stop offset="100%" stopColor="#1DBFB0"/>
      </linearGradient>
    </defs>
    <path
      fill="url(#heatmap-activity-active_svg__a)"
      fillRule="evenodd"
      d="M19.876 11.911c.143.084.79.438.936.523 2.145 1.26 4.136 1.021 5.622-1.042.972-1.347 3.24-.055 2.259 1.307-1.897 2.632-4.56 3.634-7.36 2.784a96.385 96.385 0 00-2.515 4.558 22.53 22.53 0 013.3 2.227c.9.71 2.048 3.511 2.734 4.915a1.306 1.306 0 01-2.367 1.1c-.6-1.237-1.623-3.7-2.216-4.184a16.831 16.831 0 00-4.023-2.36 1.43 1.43 0 01-.2-.089 6.6 6.6 0 01-8.412 1.607 1.352 1.352 0 111.452-2.28c2.224 1.287 4.538-.36 5.586-2.423s1.79-3.514 2.64-4.975a3.682 3.682 0 00-4.378 1.61c-.97 1.346-3.24.055-2.258-1.307 2.309-3.2 5.75-4 9.2-1.97zM22.176 7a2.577 2.577 0 012.589 2.565 2.578 2.578 0 01-2.59 2.566 2.578 2.578 0 01-2.59-2.565A2.578 2.578 0 0122.176 7z"
    />
  </svg>
);

export default SvgHeatmapActivityActive
