import React from 'react'
import SliderInput from './SliderInput'
import Text from './Text'
import './SliderInputLabel.scss'

function SliderInputLabel ({ label, maxLabel, minLabel, value, field, max, min, ...rest }) {

  const minInput = min || 0
  const maxInput = max || 100
  const valueInput = field ? field.value : value

  return (
    <label className={'range-input'}>
      {label && <Text outerClassName={'range-input-label-title'}>
        {label} ({Math.round(valueInput)})
      </Text>}

      {minLabel && maxLabel &&

      <div className={'range-input-min-max-label'}>
        <div>
          <span>{minLabel} ({minInput})</span>
        </div>
        <div>
          <span>{maxLabel} ({maxInput})</span>
        </div>
      </div>

      }

      <SliderInput {...rest} field={field} value={valueInput} max={max} min={min} step={3}/>
    </label>
  )
}

export default SliderInputLabel