import { FULFILLED, REJECTED } from '../../globalConstants'
import { notificationsActionTypes } from './notifications-actions'
import { replace } from 'lodash'

const initialState = {
  count: 0,
  notifications: [],
}

// List of excluded notification types handled in another way (like showing Dialogs)
const excludes = [
  'INACTIVE_PROJECT',
]

function notificationsReducer (state = initialState, action) {

  // Create notification for failed actions
  if (action.type.endsWith(REJECTED)) {

    let notification = mapToNotification(action.payload)

    // Only add one error per type to the state, ignore others
    if (state.notifications.find(existing => existing.type === notification.type)) {
      return state
    }

    return addNotification(state, notification)
  }

  // Manually triggered creation of a notification
  if (action.type === notificationsActionTypes.ADD_NOTIFICATION) {
    return addNotification(state, action.notification)
  }

  // Resolve network error
  if (action.type.endsWith(FULFILLED)) {

    let filtered = state.notifications
      .filter(notification => notification.type !== 'NETWORK_ERROR')

    return { ...state, notifications: [...filtered] }
  }

  // Resolve (delete) a notification
  if (action.type === notificationsActionTypes.RESOLVE_NOTIFICATION) {

    let filtered = state.notifications
      .filter(notification => notification.count !== action.notification.count)

    return { ...state, notifications: [...filtered] }
  }

  return { ...state }

}

function addNotification (state, notification) {

  let currentCount = state.count + 1
  notification.count = currentCount

  return {
    ...state,
    count: currentCount,
    notifications: [...state.notifications, notification],
  }
}

function createErrorNotification (type, title, message, dismissible = true) {

  return {
    type: replace(type, ' ', '_')
      .toUpperCase(),
    severity: 'error',
    title: title,
    message: message,
    dismissible: dismissible,
  }

}

function getUnknownErrorNotification () {
  return createErrorNotification(
    'UNKNOWN',
    'Unknown error',
    'Oooops! An unknown error occurred. Please try again or reload the page',
  )
}

function getUnknownClientErrorNotification (response) {
  console.log("unknown client")
  return createErrorNotification(
    response.statusText,
    'Invalid Request',
    'We could not process your last request. Please reload the page, try again or contact support.',
  )
}

function getUnknownClientErrorNotificationWithData (responseData) {
  console.log("unknown client with data")
  return createErrorNotification(
    responseData.error,
    'Invalid Request',
    'We could not process your last request. Please reload the page, try again or contact support.',
  )
}

function getServiceUnavailableNotification (response) {
  return createErrorNotification(
    response.statusText,
    'Service not available',
    'It seems our service is currently not available. Please try again later',
  )
}

function getFatalErrorNotification (response) {
  return createErrorNotification(
    response.statusText,
    'Error',
    'Oooops! It seems something went wrong on our server. Please contact support.',
  )
}

function mapErrorResponse (response) {

  if (response.status >= 500) {

    // response from gateway no response data available
    if (response.status === 502 || response.status === 503) {
      return getServiceUnavailableNotification(response)
    }

    if (response.data.message && response.data.message.includes('license')) {
      return mapClientErrorResponseWithData(response)
    }

    return getFatalErrorNotification(response)

  } else if (response.status >= 400) {

    if (response.data && typeof response.data === 'object') {
      return mapClientErrorResponseWithData(response)
    }

    return getUnknownClientErrorNotification(response)

  }

  return getUnknownErrorNotification()

}

function mapClientErrorResponseWithData (response) {

  let error = response.data

  // detailed custom error data from server
  if (error.details) {
    return {
      ...error.details,
      severity: 'error',
      dismissible: true,
    }
  }

  switch (response.status) {

    case 401:
      return createErrorNotification(
        error.error,
        'Not authenticated',
        'Authentication is required. Please login with your user credentials.',
      )

    case 403:
      return createErrorNotification(
        error.error,
        'No permission',
        'You do not have the necessary permissions for this action. Please contact support.',
      )
    case 404:
      return createErrorNotification(
        error.error,
        'Not found',
        'The requested resource could not be found. Please check the url in the browser, reload the page or try again.',
      )

    case 420:
      return createErrorNotification(
        error.error,
        'Client outdated',
        'Your CoDesigner version is outdated. Please reload the page to get the updates and try again',
      )

    case 422:
      return createErrorNotification(
        error.error,
        'Invalid data send',
        'The information you entered was invalid. Please check the entered data.',
      )

    case 500:
      return createErrorNotification(
        error.error,
        'Authentification Error',
        'You do not have an active License',
      )

    default:
      return getUnknownClientErrorNotificationWithData(error)
  }

}

function mapLocalError (error) {

  if (error.message !== 'Network Error') {
    return getUnknownErrorNotification()
  }

  return {
    type: 'NETWORK_ERROR',
    severity: 'error',
    title: 'No internet connection',
    message: 'Please check your connection and retry.',
    details: {
      data: {
        request: error.request,
      },
    },
  }
}

function mapToNotification (error) {

  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    return mapErrorResponse(error.response)
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest
     */
    console.log(error.request)
    return mapLocalError(error)
  } else {
    // Something happened in setting up the request and triggered an Error
    console.log('Error', error.message)
    return getUnknownErrorNotification()
  }
}

/*
* Get a list notifications without excludes notification types, that are handled in another way (like showing Dialogs)
*/
export function getNotifications (state) {
  return state.notifications.notifications
    .filter(notification => !excludes.includes(notification.type))
}

export function getNotificationByType (state, type) {
  return state.notifications.notifications
    .find(notification => notification.type === type)
}

export default notificationsReducer