import React from 'react'

const SvgExpandedIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M24 0v24H0V0z"/>
      <path fill={props.color} d="M7.515 10.743l4.242 4.242L16 10.743z"/>
    </g>
  </svg>
)

export default SvgExpandedIcon
