import * as React from 'react'

const SvgDrawScaleInactive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd" stroke={props.color} strokeLinejoin="round">
      <path d="M7 18.5h18v6H7zm0 5.785V15.5m18 8.785V15.5m-18-5V8m18 2.5V8M7 14.5V12m18 2.5V12M9 9.5h14"/>
      <path
        strokeLinecap="round"
        d="M10.207 10.914L8.793 9.5l1.414-1.414M22 10.914L23.414 9.5 22 8.086"
      />
    </g>
  </svg>
export default SvgDrawScaleInactive