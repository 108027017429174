import * as React from 'react'
const SvgScaleInctive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="7" y="18.5" width="18" height="6" stroke="#00293B"/>
    <path d="M7 24.285V15.5" stroke="#00293B" stroke-linejoin="round"/>
    <path d="M25 24.285V15.5" stroke="#00293B" stroke-linejoin="round"/>
    <path d="M7 10.5V8" stroke="#00293B" stroke-linejoin="round"/>
    <path d="M25 10.5V8" stroke="#00293B" stroke-linejoin="round"/>
    <path d="M7 14.5V12" stroke="#00293B" stroke-linejoin="round"/>
    <path d="M25 14.5V12" stroke="#00293B" stroke-linejoin="round"/>
    <path d="M9 9.5H23" stroke="#00293B" stroke-linejoin="round"/>
    <path d="M10.2072 10.9142L8.79297 9.5L10.2072 8.08579" stroke="#00293B"/>
    <path d="M21.9998 10.9142L23.4141 9.5L21.9998 8.08579" stroke="#00293B"/>
  </svg>
)

export default SvgScaleInctive
