export function checkIntersectionForOutline (outlines, innerVerticesPerOutline) {
  innerVerticesPerOutline.forEach((innerVertices, index) => {
    if (isSelfIntersection(innerVertices)) {
      outlines[index].offsetFace.visible = false
      outlines[index].setActive(true)
    }
  })

  for (let i = 0; i < innerVerticesPerOutline.length; i++) {
    const innerVertices = innerVerticesPerOutline[i]
    if (isSelfIntersection(innerVertices)) {
      outlines[i].offsetFace.visible = false
      outlines[i].setActive(true)
      continue
    }
    for (let k = 0; k < innerVerticesPerOutline.length; k++) {
      if (i === k) {
        continue
      }
      const otherVertices = innerVerticesPerOutline[k]
      if (isIntersectionOtherVertices(innerVertices, otherVertices)) {
        outlines[i].offsetFace.visible = false
        outlines[i].setActive(true)
        outlines[k].offsetFace.visible = false
        outlines[k].setActive(true)
      }
    }
  }
}

export function checkIntersectionForWalls (walls, verticesPerWalls) {

  verticesPerWalls.forEach((vertices, index) => {
    if (isSelfIntersection(vertices)) {
      walls[index].offsetFace.visible = false
      walls[index].setActive(true)
    }
  })

  for (let i = 0; i < verticesPerWalls.length; i++) {
    const vertices = verticesPerWalls[i]
    if (isSelfIntersection(vertices)) {
      walls[i].offsetFace.visible = false
      walls[i].setActive(true)
      continue
    }
    for (let k = 0; k < verticesPerWalls.length; k++) {
      if (i === k) {
        continue
      }
      const otherVertices = verticesPerWalls[k]
      if (isIntersectionOtherVertices(vertices, otherVertices)) {
        walls[i].offsetFace.visible = false
        walls[i].setActive(true)
        walls[k].offsetFace.visible = false
        walls[k].setActive(true)
      }
    }
  }
}

export function isSelfIntersection (vertices) {
  for (let i = 0; i < vertices.length; i++) {
    for (let k = 0; k < vertices.length; k++) {
      if (i === k) {
        continue
      }
      const line1_point1 = vertices[i]
      const line1_point2 = vertices[(i + 1 + vertices.length) % vertices.length]
      const line2_point1 = vertices[k]
      const line2_point2 = vertices[(k + 1 + vertices.length) % vertices.length]
      if (isLinesIntersection(line1_point1, line1_point2, line2_point1, line2_point2, true)) {
        return true
      }
    }
  }

  return false
}

export function isIntersectionOtherVertices (vertices, otherVertices) {
  for (let i = 0; i < vertices.length; i++) {
    for (let k = 0; k < otherVertices.length; k++) {
      const line1_point1 = vertices[i]
      const line1_point2 = vertices[(i + 1 + vertices.length) % vertices.length]
      const line2_point1 = otherVertices[k]
      const line2_point2 = otherVertices[(k + 1 + otherVertices.length) % otherVertices.length]
      if (isLinesIntersection(line1_point1, line1_point2, line2_point1, line2_point2, true)) {
        return true
      }
    }
  }

  return false
}

function isLinesIntersection (line1_point1, line1_point2, line2_point1, line2_point2, shouldIncludeEndPoints = false) {
  const epsilon = 0.00001

  let isIntersecting = false

  const denominator = (line2_point2.y - line2_point1.y) * (line1_point2.x - line1_point1.x) -
    (line2_point2.x - line2_point1.x) * (line1_point2.y - line1_point1.y)

  //Make sure the denominator is > 0, if not the lines are parallel
  if (denominator !== 0) {
    const u_a = ((line2_point2.x - line2_point1.x) * (line1_point1.y - line2_point1.y) - (line2_point2.y - line2_point1.y) * (line1_point1.x - line2_point1.x)) / denominator
    const u_b = ((line1_point2.x - line1_point1.x) * (line1_point1.y - line2_point1.y) - (line1_point2.y - line1_point1.y) * (line1_point1.x - line2_point1.x)) / denominator

    //Are the line segments intersecting if the end points are the same
    if (shouldIncludeEndPoints) {
      //Is intersecting if u_a and u_b are between 0 and 1 or exactly 0 or 1
      if (u_a >= epsilon && u_a <= 1 - epsilon && u_b >= epsilon && u_b <= 1 - epsilon) {
        isIntersecting = true
      }
    } else {
      //Is intersecting if u_a and u_b are between 0 and 1
      if (u_a > epsilon && u_a < 1 - epsilon && u_b > epsilon && u_b < 1 - epsilon) {
        isIntersecting = true
      }
    }
  }

  return isIntersecting
}

function isPointInsideVertices (vertices, point) {
  const verticesCount = vertices.length
  let intersections = 0

  const x = point.x
  const y = point.y

  for (let i = 0; i < verticesCount; i++) {
    const x1 = vertices[i].x
    const x2 = vertices[(i + 1) % verticesCount].x
    const y1 = vertices[i].y
    const y2 = vertices[(i + 1) % verticesCount].y

    if ((y < y1) !== (y < y2) &&
      x < (x2 - x1) * (y - y1) / (y2 - y1) + x1) {
      intersections += 1
    }
  }

  return intersections % 2 !== 0
}

export function checkNestedForOutlines (outlines, innerVerticesPerOutline) {
  console.log("checkNestedForOutlines")
  console.log(outlines)

  for (let i = 0; i < innerVerticesPerOutline.length; i++) {
    const outline = innerVerticesPerOutline[i]
    for (let k = 0; k < innerVerticesPerOutline.length; k++) {
      if (i === k) {
        continue
      }

      if (innerVerticesPerOutline[k].find(vertex => isPointInsideVertices(outline, vertex))) {
        outlines[i].addChildGeometry(outlines[k])
      }
    }
  }

  outlines.forEach(outline => {
    const { nestedGeometries } = outline

    nestedGeometries.forEach(nestedGeometry => {
      const multiNestedGeometries = nestedGeometry.nestedGeometries.filter(nestedNestedGeometry => nestedGeometries.includes(nestedNestedGeometry))

      multiNestedGeometries.forEach(multiNestedGeometry => {
        outline.removeChildGeometry(multiNestedGeometry)
      })
    })
  })

  outlines.forEach(outline => outline.reconnectNestedGeometries())
}

export function checkNestedForWalls (outlines, outerVerticesPerOutlines,
                                     walls, verticesPerWalls) {
  for (let i = 0; i < verticesPerWalls.length; i++) {
    const verticesPerWall = verticesPerWalls[i]
    for (let k = 0; k < outerVerticesPerOutlines.length; k++) {
      const outline = outerVerticesPerOutlines[k]
      if (verticesPerWall.find(vertex => isPointInsideVertices(outline, vertex))) {
        outlines[k].addChildGeometry(walls[i])
      }
    }
  }
}