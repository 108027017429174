import React from 'react'

import Text, { textTypes } from '../../../common/components/Text'
import Button from '../../../common/components/Button'
import { iconTypes } from '../../../common/components/icons/IconFactory'

import './OpenDemoButton.scss'

export default function OpenDemoButton ({ onClick = () => {} }) {
  return (
    <div className={'step-sidebar-demo'}>
      <Text outerClassName={'demo-text-wrapper'} component={textTypes.paragraph}>Or use a complete prepared plan</Text>
      <Button className={'step-sidebar-open-demo-button'}
              variant={'round'}
              secondIcon={iconTypes.assistant}
              onClick={() => onClick()}>Demoplan</Button>
    </div>
  )
}

