import * as React from 'react'

const SvgOpenNewTabIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill={props.color} fillRule="evenodd">
      <path
        d="M18 13.5V17a1 1 0 01-1 1H7a1 1 0 01-1-1V7a1 1 0 011-1h3.5M14 6h3.5a.5.5 0 01.5.5V10h0m-.375-3.625L12 12"
        fill="none"
        fillRule="evenodd"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export default SvgOpenNewTabIcon
