import { FULFILLED, PENDING, REJECTED } from '../../floorplan/constants'

const initialState = {
  count: 0,
  active: false,
}

function loaderReducer (state = initialState, action) {

  if (action.type === 'INCREASE_LOADER' || action.type.endsWith(PENDING)) {

    let count = state.count + 1
    return { ...state, count: count, active: count > 0 }

  } else if (action.type === 'DECREASE_LOADER' || action.type.endsWith(FULFILLED) || action.type.endsWith(REJECTED)) {

    let count = state.count - 1
    return { ...state, count: count, active: count > 0 }

  } else {
    return state
  }

}

export default loaderReducer