import React, { Component } from 'react'
import { connect } from 'react-redux'

import Section from '../../common/components/layout/Section'
import SectionHeader from '../../common/components/layout/SectionHeader'
import ModalConsumer from '../../common/components/modal/ModalConsumer'
import XlsxUploadDialog from './dialogs/XlsxUploadDialog'
import { downloadApplicationProfilesFile, updateApplicationProfiles, uploadApplicationProfilesFile } from '../../common/redux/styles/application-profiles/application-profiles-actions'

import ApplicationProfilesTable from './ApplicationProfilesTable'

import './ApplicationProfiles.scss'
import Button from '../../common/components/Button'
import TablePageButtonToolBar from './TablePageButtonToolBar'
import ButtonGroup from '../../common/components/ButtonGroup'
import { cloneDeep } from 'lodash'
import RouteLeavingGuard from '../../common/components/RouteLeavingGuard'
import equal from 'fast-deep-equal'

class ApplicationProfiles extends Component {

  constructor (props) {
    super(props)
    this.state = {
      units: this.props.units,
      unitsToUpdate: [],
    }

  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!equal(this.props.units, prevProps.units)) {
      this.resetData()
    }
  }

  isChanged () {
    return this.state.unitsToUpdate && this.state.unitsToUpdate.length > 0
  }

  render () {
    return (
      <Section className={'application-profiles'}>
        <SectionHeader title={'Application Styles'}/>

        <TablePageButtonToolBar>
          <ModalConsumer>
            {({ showModal }) => (
              <div className={'application-profiles-buttons'}>
                <ButtonGroup className={'application-profiles-buttons-import'}>
                  <Button variant={'contained'} onClick={() => showModal(XlsxUploadDialog, { upload: this.props.uploadApplicationProfilesFile, replace: false })}>
                    Add Data...
                  </Button>
                  <Button onClick={() => showModal(XlsxUploadDialog, { upload: this.props.uploadApplicationProfilesFile, replace: true })}>
                    Replace Data...
                  </Button>
                  <Button onClick={() => this.props.downloadApplicationProfilesFile()}>
                    Export XLSX
                  </Button>
                </ButtonGroup>
                <ButtonGroup className={'application-profiles-buttons-send'}>
                  <Button onClick={() => this.resetData()} disabled={!this.isChanged()}>Discard</Button>
                  <Button variant={'contained'} onClick={() => this.sendUpdate()} disabled={!this.isChanged()}>Confirm</Button>
                </ButtonGroup>
              </div>
            )}
          </ModalConsumer>

        </TablePageButtonToolBar>

        <div>
          {this.state.units ? <ApplicationProfilesTable data={this.state.units} onUnitChange={(unitId, wishId, unit) => this.updateApplicationProfiles(unitId, wishId, unit)}/> : null}
        </div>

        <RouteLeavingGuard
          when={this.isChanged()}
          confirm={() => this.sendUpdate()}
          lines={['Your Application Profiles have been modified.', 'Please confirm or discard your changes.', 'To continue editing close this window.']}
        />
      </Section>
    )
  }

  updateApplicationProfiles (unitId, wishId, value) {

    let applicationProfile

    if (unitId) {
      applicationProfile = this.updateApplicationProfileName(unitId, value)
    }

    if (wishId) {
      applicationProfile = this.updateApplicationProfileWish(wishId, value)
    }

    const index = this.state.unitsToUpdate.findIndex(u => u.uuid === applicationProfile.uuid)

    const unitsToUpdate = this.state.unitsToUpdate

    if (0 <= index)
      unitsToUpdate[index] = applicationProfile
    else
      unitsToUpdate.push(applicationProfile)

    this.setState({ unitsToUpdate: unitsToUpdate })
  }

  updateApplicationProfileName (unitId, value) {
    const applicationProfile = this.state.units.find(unit => unit.uuid === unitId)

    if (applicationProfile && value.name)
      applicationProfile.name = value.name

    return applicationProfile
  }

  updateApplicationProfileWish (wishId, value) {
    let applicationProfile = null

    this.state.units.forEach(unit => {
      const wish = unit.wishes.find(wish => wish.id === wishId)

      if (wish) {
        if (value.name !== undefined)
          wish.name = value.name
        if (value.value !== undefined)
          wish.value = parseInt(value.value)
        if (value.exceed !== undefined)
          wish.exceed = value.exceed
        if (value.placing !== undefined)
          wish.placing = value.placing

        applicationProfile = unit
      }
    })

    return applicationProfile
  }

  sendUpdate () {
    this.state.unitsToUpdate.forEach(unit => {
      this.props.updateApplicationProfiles(unit.uuid, unit)
    })

    this.setState({ unitsToUpdate: [] })
  }

  resetData () {
    this.setState({
      units: cloneDeep(this.props.units),
      unitsToUpdate: [],
    })
  }
}

let mapStateToProps = (state) => {
  return {
    units: state.styles.applicationProfiles,
  }
}

let mapDispatchToProps = {
  downloadApplicationProfilesFile: downloadApplicationProfilesFile,
  uploadApplicationProfilesFile: uploadApplicationProfilesFile,
  updateApplicationProfiles: updateApplicationProfiles,
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationProfiles)
