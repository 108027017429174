import * as React from 'react'

const SvgFloorIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M12 6.636L18.364 10l-2.366 1.249 2.366 1.251-2.366 1.249L18.364 15 12 18.364 5.636 15 8 13.749 5.636 12.5 8 11.249 5.636 10 12 6.636zm-2.93 7.678L7.774 15 12 17.234 16.224 15l-1.296-.685L12 15.864l-2.93-1.55zm0-2.5l-1.296.686L12 14.734l4.225-2.234-1.296-.685L12 13.364l-2.93-1.55zM12 7.766L7.773 10 12 12.234 16.224 10 12 7.766z"
    />
  </svg>
)

export default SvgFloorIcon
