import React, { Component } from 'react'
import { connect } from 'react-redux'
import FurnitureMenu from '../../../common/components/builder/FurnitureMenu'
import './FurnitureSelector.scss'
import { updateFurnitureDragId } from '../../../common/redux/furnituredrag/furnituredag-actions'
import { addFurniture } from '../../../common/redux/furniture/furniture-actions'

class FurnitureSelector extends Component {

  render () {
    return (
      <FurnitureMenu furnitureLibrary={this.props.furnitureLibrary}
                     updateFurnitureMenuItemDragId={(id) => this.props.updateFurnitureDragId(id)}
                     uploadFurniture={(csvFile, dxfFile) => this.uploadFurniture(csvFile, dxfFile)}/>
    )
  }

  uploadFurniture (csvFile, dxfFile) {
    this.props.addFurniture(csvFile, dxfFile)
  }
}

let mapStateToProps = (state) => {
  return {
    furnitureLibrary: state.furniture.furnitureLibrary,
  }
}

let mapDispatchToProps = {
  updateFurnitureDragId: updateFurnitureDragId,
  addFurniture: addFurniture,

}

export default connect(mapStateToProps, mapDispatchToProps)(FurnitureSelector)