import { variationsActionTypes } from './variations-actions'

let initialState = {
  variations: [],
  activeVariationId: null,
  error: null,
}

function variationsReducer (state = initialState, action) {

  switch (action.type) {
    case variationsActionTypes.UPDATE_VARIATION_ID:
      console.log("UPDATE_VARIATION_ID IN REDUCER", action.payload)
      return {
        ...state,
        activeVariationId: action.payload,

      }

    case variationsActionTypes.FETCH_VARIATIONS_FULFILLED:
      return {
        ...state,
        variations: action.payload.data,
      }

    case variationsActionTypes.CREATE_VARIATION_FULFILLED:
      return {
        ...state,
        variations: [...state.variations, action.payload.data],
      }

    case variationsActionTypes.UPDATE_VARIATION_FULFILLED:
      return {
        ...state,
        variations: [...state.variations, action.payload.data],
      }

    case variationsActionTypes.DELETE_VARIATION_FULFILLED:
      return {
        ...state,
      }

    default:
      return { ...state }
  }

}

export function getVariation (state, variationId) {
  if (!variationId) {
    return null
  }
  return state.variations.variations
    .find(variation => variation.id === variationId)
}

export function getVariationFromVariations (variations, variationId) {
  if (!variationId) {
    return null
  }
  let v =  variations
    .find(variation => variation.id === variationId)

  return v
}

export function getFirstVariation (state) {
  return state.variations.variations.length ? state.variations.variations[0] : null
}

export default variationsReducer



