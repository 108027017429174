import * as React from 'react'
const SvgServiceAreaDisabled = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="19.4384" cy="11.0263" r="2.15223" stroke="#C4C6D2"/>
    <path d="M18.3776 5H20.5044L20.8168 6.40605C21.1376 6.50181 21.4443 6.63007 21.7332 6.787L22.9497 6.01515L24.4535 7.5191L23.6789 8.73648C23.8348 9.0249 23.9622 9.33103 24.0573 9.65107L25.4646 9.96583V12.0927L24.056 12.4058C23.9606 12.726 23.8327 13.0322 23.6763 13.3207L24.4487 14.5382L22.9449 16.0421L21.7285 15.268C21.4399 15.4244 21.1336 15.5521 20.8133 15.6475L20.4991 17.0525H18.3723L18.0603 15.6482C17.7385 15.5526 17.4308 15.4242 17.141 15.2671L15.927 16.0373L14.4232 14.5334L15.1948 13.3207C15.0375 13.0305 14.9091 12.7223 14.8135 12.4001L13.4121 12.0866V9.95974L14.8148 9.64801C14.9108 9.3256 15.0396 9.01734 15.1974 8.72708L14.428 7.51428L15.9318 6.01033L17.1457 6.78283C17.4352 6.62626 17.7425 6.49846 18.0638 6.40325L18.3776 5Z" stroke="#C4C6D2"/>
    <rect x="7.84961" y="18.3408" width="7.74802" height="3.44356" transform="rotate(60 7.84961 18.3408)" stroke="#C4C6D2"/>
    <path d="M8.28063 19.0868L10.2602 17.9439C11.5469 17.2011 13.1525 17.2965 14.3422 18.1865V18.1865C14.7794 18.5136 15.2833 18.7402 15.8181 18.8504L18.7891 19.462C19.5142 19.6113 19.9954 20.3023 19.884 21.0341V21.0341C19.7657 21.8106 19.0286 22.3352 18.2563 22.1928L14.5 21.5" stroke="#C4C6D2"/>
    <path d="M11.2937 24.3048V24.3048C12.0133 23.8894 12.862 23.757 13.6739 23.9337L17.6256 24.7934C18.2913 24.9382 18.9873 24.8141 19.5619 24.4482L26.1991 20.2215C26.6956 19.9054 26.9455 19.3157 26.8275 18.7391V18.7391C26.647 17.857 25.7018 17.3635 24.8749 17.7194L19.71 19.9427" stroke="#C4C6D2"/>
  </svg>
)

export default SvgServiceAreaDisabled