import * as React from 'react'

const SvgSuperDepartment = props =>
  (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        d="M12.25 5.25a.875.875 0 00.875-.875V1.75a.875.875 0 00-.875-.875H1.75a.875.875 0 00-.875.875v2.625c0 .483.392.875.875.875h4.813V7H3.938a.876.876 0 00-.876.875v1.75H1.75a.875.875 0 00-.875.875v1.75c0 .483.392.875.875.875h3.5a.875.875 0 00.875-.875V10.5a.875.875 0 00-.875-.875H3.937v-1.75h6.126v1.75H8.75a.875.875 0 00-.875.875v1.75c0 .483.392.875.875.875h3.5a.875.875 0 00.875-.875V10.5a.875.875 0 00-.875-.875h-1.313v-1.75A.874.874 0 0010.063 7H7.437V5.25h4.813zm-7 7h-3.5V10.5h3.5v1.75zm7 0h-3.5V10.5h3.5v1.75zM1.75 1.75h10.5v2.625H1.75V1.75z"
        fill="#00293B"
        fillRule="evenodd"
      />
    </svg>
  )

export default SvgSuperDepartment
