import { uuidv4 } from '../../../common/three/utils'

export class GraphEdge {

  constructor (vertexA, vertexB, representationType, geometryUuid) {
    this.uuid = uuidv4()
    this.vertices = [vertexA, vertexB]
    this.represenationType = representationType
    this.geometryUuid = geometryUuid

    if (!vertexA || !vertexB) {
      return
    }

    this.distance = vertexA.position.distanceTo(vertexB.position)

    vertexA.addEdge(this)
    vertexB.addEdge(this)
  }

  getDistance() {
    return this.vertices.length !== 2 ? -1 : this.distance
  }

  getOtherVertex(vertex) {
    return this.vertices.find(v => v !== vertex)
  }

  getVertices() {
    return this.vertices
  }

  getRepresentationType() {
    return this.represenationType
  }

  isRepresentationType(representationType) {
    return this.represenationType === representationType
  }

  getGeometryUuid() {
    return this.geometryUuid
  }

}