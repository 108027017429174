import { appStateActionTypes } from './appstate-actions'

let initApp = {

  selectedOrganisationalUnitId: null,
  selectedFloorId: null,
  selectedBuildingId: null,

}

function appState (state = initApp, action) {

  switch (action.type) {
    case appStateActionTypes.FETCH_APP_STATE_FULFILLED:
      const { buildingId, floorId } = action.payload.data

      return {
        ...state,
        selectedBuildingId: buildingId,
        selectedFloorId: floorId,
      }

    case appStateActionTypes.SET_SELECTED_ORGANISATIONAL_UNIT_ID:
      return { ...state, selectedOrganisationalUnitId: action.id }

    default:
      return state
  }

}

export function getSelectedBuildingId (state) {
  return state.appState.selectedBuildingId
}

export function getSelectedFloorId (state) {
  return state.appState.selectedFloorId
}

export function getSelectedOrganisationalUnitId (state) {
  return state.appState.selectedOrganisationalUnitId
}

export default appState
