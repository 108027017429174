import { getMaterial } from '../common/Materials'
import { materialTypes } from '../common/MaterialTypes'

export const spaceConfig = {
  vertex: {
    innerActiveMaterial: getMaterial(materialTypes.transparent),
    innerInactiveMaterial: getMaterial(materialTypes.transparent),
    outerActiveMaterial: getMaterial(materialTypes.transparent),
    outerInactiveMaterial: getMaterial(materialTypes.transparent),
    innerSize: 0.1,
    outerSize: 0.3,
    innerSegments: 32,
    outerSegments: 32,
  },
}