import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import StepSidebar from './StepSidebar'

import { advancedSteps, assistantSteps } from '../constants/onboarding-constants'
import './Step.scss'
import { viewNamesOnBoarding } from '../../common/globalConstants'

function Step() {
  const [step, setStep] = useState(viewNamesOnBoarding.START)
  const advancedSettings = useSelector(state => state.settings.appSettings.advancedSettings);

  const [steps, setSteps] = useState(advancedSettings ? advancedSteps : assistantSteps)

  const stepItem = steps.find(s => s.viewName === step);
  useEffect(() => {
    setSteps(advancedSettings ? advancedSteps : assistantSteps)

  }, [advancedSettings])

  if (!stepItem)
    {
      return null;
    }

  return (
    <div className={'step-container'}>
      <StepSidebar step={stepItem} setStep={setStep}/>
      <div className={'step-content'}>
        {stepItem.component && stepItem.component({ setStep })}
      </div>
      {stepItem.childComponents ? stepItem.childComponents.map((component, index) => React.cloneElement(component, { key: index })) : null}
    </div>
  );
}

export default Step