import {Group, Vector3} from 'three'
import {Vertex} from '../geometries/VertexFactory'
import {Edge} from '../geometries/EdgeFactory'
import { getOrtoProjectionOnSegment, getSubtraction, getOrtoProjectionOnLine } from './GeometryHelper'


let previewAxis = null
let originalA = null
let originalB = null

let active=false;
export class PreviewAxis extends Group {

  constructor(scene) {
    super()

    this.position.z=0.1
    this.representationType = 'previewAxis'

    //const startVertex = new Vertex(1, this.representationType, {x: 100000, y: 0, z:-0.001})
    //const endVertex = new Vertex(1, this.representationType, {x: -100000, y: 0, z:-0.001})

    const startVertex = new Vertex(1, this.representationType, {x: 0, y: 0, z:0.1})
    const endVertex = new Vertex(1, this.representationType, {x: 0, y: 0, z:0.1})

    this.edge = new Edge(startVertex, endVertex, 1, this.representationType)
    this.edge.name = 'previewAxis'
    this.edge.setActive(false)
    this.add(this.edge)

    scene.add(this)
    this.active=false
    this.name = 'previewAxis'

    previewAxis = this
  }

  updateAxis(position,originalA,originalB) {
    this.active=true

    let posOnOriginal=getOrtoProjectionOnLine(position,originalA,originalB)
    let vector=getSubtraction(position,posOnOriginal)
    console.log("previewAxis movement:")
    console.log(vector)
    //console.log(originalA.x+vector.x,originalA.y+vector.y)
    //console.log(originalB.x+vector.x,originalB.y+vector.y)
    this.edge.update({x: originalA.x+vector.x, y:originalA.y+vector.y, z:0.1}, {x: originalB.x+vector.x, y: originalB.y+vector.y, z:0.1})

    console.log("X: "+originalA.x+vector.x) //40
    const resultedge=[]

    resultedge[0]=new Vector3(originalA.x+vector.x,originalA.y+vector.y,0.1)
    resultedge[1]=new Vector3(originalB.x+vector.x,originalB.y+vector.y,0.1)


    console.log("resultEdge:")
    console.log(resultedge)
    this.edge.setActive(true)

    return resultedge;

    //console.log(this.edge.active)
  }

  disable(){
    this.edge.setActive(false)
  }

  updateScale(scale) {
    this.edge.updateScale(scale)
  }
}
