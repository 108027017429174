import AuthService from '../auth/auth.service'

export function configureInterceptors (httpClient) {
  httpClient.interceptors.request.use(
    async (request) => {
      const token = await AuthService.getInstance()
        .getToken()

      if (token) {
        request.headers.Authorization = `Bearer ${token}`
      }

      return request
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  httpClient.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        return AuthService.getInstance()
          .login()
      }
      return Promise.reject(error)
    },
  )
}