import * as React from 'react'

const AddPatternIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g
        stroke="#001D34"
        strokeLinecap="round"
        transform="translate(4.5 4.5)"
      >
        <path d="M10.875 12h.675a.45.45 0 00.45-.45v-8.1a.45.45 0 00-.45-.45h-8.1a.45.45 0 00-.45.45v.675h0"/>
        <rect
          width={9}
          height={9}
          y={6}
          rx={0.3}
          transform="matrix(1 0 0 -1 0 21)"
        />
        <path d="M13.875 9h.675a.45.45 0 00.45-.45V.45a.45.45 0 00-.45-.45h-8.1A.45.45 0 006 .45v.675h0"/>
      </g>
      <circle cx={16} cy={16} r={6} fill="#FFF"/>
      <g transform="translate(11 11)">
        <circle cx={5} cy={5} r={5} fill="#00293B"/>
        <path d="M7.5 5h-5M5 2.5v5" stroke="#FFF" strokeLinecap="round"/>
      </g>
    </g>
  </svg>
)

export default AddPatternIcon