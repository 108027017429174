import React from 'react'
import PropTypes from 'prop-types'

import './Dialog.scss'
import Text from './Text'
import IconButton from './IconButton'
import { iconTypes } from './icons/IconFactory'

export function DialogContent ({ className, ...rest }) {
  let styleClass = className ? ' ' + className : ''

  return (
    <div {...rest} className={'dialog-content' + styleClass}/>
  )
}

export function DialogButtonGroup ({ className, ...rest }) {

  let styleClass = className ? ' ' + className : ''

  return (
    <div {...rest} className={'dialog-button-group' + styleClass}/>
  )
}

function Dialog ({ onCancel = null, hideModal = () => {}, children, containerClassName, className, titleClassName, subtitleClassName, innerClassName, bodyStyleClass, text, lines, title, subtitle, ...rest }) {

  const additionalContainerStyleClass = containerClassName ? ' ' + containerClassName : ''
  const additionalStyleClass = className ? ' ' + className : ''
  const additionalInnerStyleClass = innerClassName ? ' ' + innerClassName : ''
  const additionalTitleStyleClass = titleClassName ? ' ' + titleClassName : ''
  const additionalSubtitleStyleClass = subtitleClassName ? ' ' + subtitleClassName : ''
  const additionalBodyStyleClass = bodyStyleClass ? ' ' + bodyStyleClass : ''

  function getText () {
    if (text) {
      return (
        <DialogContent>
          <Text multiline>{text}</Text>
        </DialogContent>)
    }
  }

  function getLines () {
    if (lines) {
      return (
        <DialogContent>
          {
            lines.map((line, index) => {
              return <Text multiline outerClassName={'dialog-multiline-text'} key={index}>{line}</Text>
            })
          }
        </DialogContent>)
    }
  }

  return (
    <div className={'dialog-background'}>
      <div className={'dialog-container' + additionalContainerStyleClass}>
        <div {...rest} className={'dialog-window' + additionalStyleClass}>
          <div className={'dialog-window-content' + additionalInnerStyleClass}>
            {title &&
            <div className={'dialog-headline' + additionalTitleStyleClass}>
              <Text>{title}</Text>
              {onCancel &&
              <IconButton className={'dialog-close-icon'} icon={iconTypes.close} onClick={() => {
                hideModal()
                onCancel()
              }}/>
              }
            </div>}
            {subtitle &&
            <div className={'dialog-subtitle' + additionalSubtitleStyleClass}>
              <Text>{subtitle}</Text>
            </div>}
            <div className={'dialog-body' + additionalBodyStyleClass}>
              {getText()}
              {getLines()}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Dialog.propTypes = {
  onCancel: PropTypes.func,
  hideModal: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  children: function (props, propName, componentName) {
    const prop = props[propName]

    React.Children.forEach(prop, function (child) {
      if (child.type !== DialogContent || child.type !== DialogButtonGroup) {
        return new Error('`' + componentName + '`: only children of type `DialogContent` or `DialogButtonGroup` allowed.')
      }
    })
  },
}

export default Dialog