import React from 'react'

const SvgAppCreatorContainerIconInactive = props => (
  <svg className={props.className} viewBox="0 0 54 54">
    <path data-name="Rechteck 426" fill="none" d="M0 0h54v54H0z"/>
    <path
      data-name="Pfad 913"
      d="M27.812 36.532h-1.953v.977h-3.907v-.977h-.977a.977.977 0 0 1-.976-.977v-4.883h13.673v4.883a.977.977 0 0 1-.977.977h-.977v.977h-3.907zm-6.836-19.533h11.723a.977.977 0 0 1 .977.977v4.883H19.999v-4.883a.977.977 0 0 1 .977-.977zm4.395 2.93h2.93a.488.488 0 0 0 .488-.488.488.488 0 0 0-.488-.488h-2.93a.488.488 0 0 0-.488.488.488.488 0 0 0 .488.488zm-5.372 3.906h13.673v5.864H19.999zm5.372 2.93h2.93a.488.488 0 0 0 .488-.488.488.488 0 0 0-.488-.488h-2.93a.488.488 0 0 0-.488.488.488.488 0 0 0 .488.488zm0 6.836h2.93a.488.488 0 0 0 .488-.488.488.488 0 0 0-.488-.488h-2.93a.488.488 0 0 0-.488.488.488.488 0 0 0 .488.486z"
      fill="#8f939d"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgAppCreatorContainerIconInactive
