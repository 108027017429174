import React, { Component } from 'react'

import Icon from './icons/IconFactory'

import './ListMenuIconsItem.scss'

class ListMenuIconsItem extends Component {
  render () {

    let isVisible = this.props.visible === undefined || this.props.visible === null ? true : this.props.visible

    if (!isVisible) {
      return null
    }

    return (
      <li className={'list-menu-icons-list-item'}>
        <div className={'list-menu-icons-item-container'}>
          <Icon className={'list-menu-icons-item-icon'} name={this.props.icon}/>
          <div className={'list-menu-icons-item-title'}>{this.props.title}</div>
          <div className={'list-menu-icons-item-icons-container'}>
            {this.props.children}
          </div>
        </div>
      </li>
    )
  }
}

export default ListMenuIconsItem