import * as React from 'react'
const SvgPanActive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 21.8303C12.5 23.5809 13.9191 25 15.6697 25H18.4296C20.1253 25 21.5 23.6253 21.5 21.9296C21.5 21.3235 21.6794 20.7309 22.0157 20.2265L22.717 19.1744C23.23 18.4049 23.5324 17.5144 23.5939 16.5916L23.889 12.165C23.9476 11.2865 23.214 10.56 22.3361 10.6269C21.5823 10.6844 21 11.3128 21 12.0688V15V8.58017C21 7.72109 20.2799 7.03751 19.422 7.0822C18.6249 7.12372 18 7.78207 18 8.58017V14V7.5C18 6.67157 17.3284 6 16.5 6C15.6716 6 15 6.67157 15 7.5V14V9C15 8.17157 14.3284 7.5 13.5 7.5C12.6716 7.5 12 8.17157 12 9V15L11.4522 13.3566C11.2025 12.6074 10.3926 12.2025 9.64341 12.4522C8.79108 12.7363 8.40882 13.7268 8.84928 14.5098L12.0929 20.2763C12.3598 20.7508 12.5 21.286 12.5 21.8303Z" fill="url(#paint0_linear_12_4313)" stroke="#DA5B2B"/>
    <defs>
      <linearGradient id="paint0_linear_12_4313" x1="23.9997" y1="25.0019" x2="23.9997" y2="6.0019" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
    </defs>
  </svg>
)

export default SvgPanActive