import React from 'react'

const SvgFloorsIconActive = props => (
  <svg className={props.className} viewBox="0 0 60 54">
    <defs>
      <linearGradient
        id="floors-icon-active_svg__a"
        x2={1}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fc7e1b"/>
        <stop offset={1} stopColor="#fbae00"/>
      </linearGradient>
    </defs>
    <path d="M0 0h60v54H0z" data-name="Rechteck 216" fill="none"/>
    <path
      d="M18.384-9.968a1.153 1.153 0 0 1 1.495.451 1 1 0 0 1-.485 1.388L11.949-4.6a4.579 4.579 0 0 1-3.9 0L.607-8.119a1 1 0 0 1-.486-1.388 1.153 1.153 0 0 1 1.494-.452L9.059-6.44a2.2 2.2 0 0 0 1.881 0zm0 3.943a1.153 1.153 0 0 1 1.495.451 1 1 0 0 1-.485 1.388L11.949-.658a4.579 4.579 0 0 1-3.9 0L.607-4.176a1 1 0 0 1-.486-1.388 1.153 1.153 0 0 1 1.494-.452L9.059-2.5a2.2 2.2 0 0 0 1.881 0zm-6.968-11.661l7.834 3.664a1.108 1.108 0 0 1 .535 1.475 1.11 1.11 0 0 1-.539.536L11.4-8.385a3.339 3.339 0 0 1-2.8 0L.755-12a1.108 1.108 0 0 1-.543-1.472 1.11 1.11 0 0 1 .536-.539l7.835-3.672a3.339 3.339 0 0 1 2.831 0z"
      data-name="Pfad 1033"
      transform="translate(20 36)"
      fill="url(#floors-icon-active_svg__a)"
    />
  </svg>
)

export default SvgFloorsIconActive
