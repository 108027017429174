import React, { Component } from 'react'
import './Inspector.scss'
import SettingsApplication from './settings/SettingsApplication'
import { connect } from 'react-redux'
import { selectedElementType } from '../../reducers/uiState'
import SettingsWall from './settings/SettingsWall'
import SettingsFurniture from './settings/SettingsFurniture'

class Inspector extends Component {

  constructor (props, context) {
    super(props, context)

    this.state = {}
  }

  render () {
    return (
      <div id="Inspector">
        {this.chooseInspectorType()}
      </div>
    )
  }

  chooseInspectorType () {
    switch (this.props.selectedElement.type) {
      case selectedElementType.WALL:
        return (
          <SettingsWall/>
        )

      case selectedElementType.FURNITURE:
        return (
          <SettingsFurniture/>
        )

      case selectedElementType.FLOOR:
      default:
        return (
          <SettingsApplication/>
        )
    }
  }
}

let mapStateToProps = (state) => {
  return {
    selectedElement: state.applicationBuilderUiState.selectedElement,
  }
}

export default connect(mapStateToProps)(Inspector)