import React from 'react'

const SvgPreview = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#00293B"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 15.13c-1.722 0-3.13-1.408-3.13-3.13S10.278 8.87 12 8.87s3.13 1.408 3.13 3.13-1.408 3.13-3.13 3.13z"/>
      <path d="M12 17.74c-3.684 0-7.369-2.552-8.87-5.74C4.495 8.812 8.316 6.26 12 6.26c3.684 0 7.369 2.552 8.87 5.74-1.501 3.188-5.322 5.74-8.87 5.74z"/>
    </g>
  </svg>
)

export default SvgPreview