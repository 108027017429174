import * as React from "react"

const FurnitureSetsIcon = (props) =>
  (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g stroke={props.color || '#00A2E1'} fill="none" fillRule="evenodd" strokeLinecap="round">
        <path d="M12.212 16.738l4.526-4.526a.3.3 0 000-.424l-4.526-4.526a.3.3 0 00-.424 0l-4.526 4.526a.3.3 0 000 .424l4.526 4.526a.3.3 0 00.424 0z" />
        <rect
          width={3.5}
          height={3.5}
          x={15.5}
          y={5}
          rx={1.75}
          transform="matrix(1 0 0 -1 0 13.5)"
        />
        <rect
          width={3.5}
          height={3.5}
          x={15.5}
          y={15.5}
          rx={1.75}
          transform="matrix(1 0 0 -1 0 34.5)"
        />
        <rect
          width={3.5}
          height={3.5}
          x={5}
          y={5}
          rx={1.75}
          transform="matrix(1 0 0 -1 0 13.5)"
        />
        <rect
          width={3.5}
          height={3.5}
          x={5}
          y={15.5}
          rx={1.75}
          transform="matrix(1 0 0 -1 0 34.5)"
        />
      </g>
    </svg>
  )

export default FurnitureSetsIcon
