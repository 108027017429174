import React from 'react'
import './ButtonToolbar.scss'

export default function ButtonToolbar ({ className, ...rest }) {

  let styleClass = className ? ' ' + className : ''

  return (
    <div {...rest} className={'button-toolbar-component' + styleClass}/>
  )
}