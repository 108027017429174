import React from 'react'

const SvgMoveIcon = props => (
  <svg className={props.className} viewBox="0 0 24 24">
    <g fill={props.color} fillRule="evenodd">
      <path d="M12.555 12.555h5.89a.555.555 0 000-1.11h-5.89v-5.89a.555.555 0 00-1.11 0v5.89h-5.89a.555.555 0 100 1.11h5.89v5.89A.552.552 0 0012 19a.555.555 0 00.555-.555v-5.89"/>
      <path d="M17 14.828L19.828 12 17 9.172zm-10 0L4.172 12 7 9.172zM9.172 17L12 19.828 14.828 17zm0-10L12 4.172 14.828 7z"/>
    </g>
  </svg>
)

export default SvgMoveIcon
