import React, { Component } from 'react'
import { connect } from 'react-redux'

import SelectButton from '../../../common/components/SelectButton'

class TableUsedPatternSelectButton extends Component {

  getPatternTypesAsSelectionOptions () {
    let selectionOptions = []

    this.props.patternTypes.forEach((patternType) => {
      let option = {
        label: patternType.name,
        value: patternType.id,
      }
      selectionOptions.push(option)
    })

    return selectionOptions
  }

  render () {
    let selectionOptions = this.getPatternTypesAsSelectionOptions()

    return (
      <SelectButton onChange={(event) => this.handleUsedPatternSelection(event.target.value)}
                    value={this.props.value.id}
                    options={selectionOptions}/>
    )
  }

  handleUsedPatternSelection (selection) {

    let returnValue = null

    this.getPatternTypesAsSelectionOptions()
      .forEach((option) => {
        if (option.value === selection) {
          returnValue = {
            id: option.value,
            name: option.label,
          }
        }
      })

    if (returnValue)
      this.props.onChange(this.props.unitId, { id: this.props.unitId, patternType: returnValue, usePattern: true })

  }

}

let mapStateToProps = (state) => {
  return {
    patternTypes: state.styles.patternTypes,
  }
}

export default connect(mapStateToProps)(TableUsedPatternSelectButton)
