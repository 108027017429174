import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getAllFloors } from '../../../floorplan/reducers/buildings'
import AssignedFloorSelectButton from '../../../common/components/AssignedFloorSelectButton'
import { Field, Form, Formik } from 'formik'

class TableAssignedFloorSelectButton extends Component {

  render () {
    const floors = this.props.floors.sort((floor0, floor1) => floor0.index > floor1.index ? 1 : -1)

    const initialValue = { value: this.props.value }

    //console.log(this.props.value)

    return (
      <Formik initialValues={initialValue} enableReinitialize>
        <Form onChange={(event) => this.handleFloorSelection(event.target.value)}>
          <Field component={AssignedFloorSelectButton}
                 floors={floors}
                 name={'value'}/>
        </Form>
      </Formik>
    )
  }

  handleFloorSelection (selection) {
    let selectedFloor = this.props.floors.find(floor => floor.id === selection)
    this.props.onChange(this.props.unit, selectedFloor)
  }

}

let mapStateToProps = (state) => {
  return {
    floors: getAllFloors(state),
  }
}

export default connect(mapStateToProps)(TableAssignedFloorSelectButton)