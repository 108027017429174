import * as React from 'react'

const SvgDrawZoneInactive = props =>
  <svg width={32} height={32} viewBox="0 0 32 32">
    <path
      fill="none"
      stroke={props.color}
      strokeLinejoin="round"
      d="M19.5 17a3 3 0 013 3v3h-12v-3a3 3 0 013-3h6zm5.925-2a3 3 0 013 3v1h-3.956a4.003 4.003 0 00-2.902-3.356A2.988 2.988 0 0123.425 15h2zM9.5 15c.702 0 1.347.24 1.857.644A4.002 4.002 0 008.456 19h0H4.5v-1a3 3 0 013-3h0zm7-6c1.642 0 3 1.358 3 3s-1.358 3-3 3-3-1.358-3-3 1.302-3 3-3zm7.5.5c1.094 0 2 .906 2 2 0 1.094-.906 2-2 2-1.094 0-2-.906-2-2 0-1.094.868-2 2-2zm-15 0c1.132 0 2 .906 2 2 0 1.094-.906 2-2 2-1.094 0-2-.906-2-2 0-1.094.906-2 2-2z"
    />
  </svg>
export default SvgDrawZoneInactive