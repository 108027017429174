import React from 'react'

const SvgAppCreatorChairIconInactive = props => (
  <svg className={props.className} viewBox="0 0 54 54">
    <path data-name="Rechteck 426" fill="none" d="M0 0h54v54H0z"/>
    <path
      data-name="Pfad 909"
      d="M26.35 34.164l-4.319.873a.488.488 0 0 1-.575-.382.488.488 0 0 1 .382-.575l4-.808V31.6h1.952v1.652l4 .809a.488.488 0 0 1 .382.575.488.488 0 0 1-.575.382l-4.271-.86v1.388a.488.488 0 0 1-.488.488.488.488 0 0 1-.488-.488zm7.3-5a1.465 1.465 0 0 1-1.465 1.465H21.442a1.465 1.465 0 0 1-1.465-1.468 1.465 1.465 0 0 1 1.465-1.461h10.743a1.465 1.465 0 0 1 1.465 1.461zM19.977 20.86a.977.977 0 0 1 .977.977v3.907a.977.977 0 0 1-.977.977.977.977 0 0 1-.977-.978v-3.907a.977.977 0 0 1 .977-.976zm13.673 0a.977.977 0 0 1 .977.977v3.907a.977.977 0 0 1-.977.977.977.977 0 0 1-.977-.977v-3.907a.977.977 0 0 1 .977-.977zM23.805 15h6.016a.977.977 0 0 1 .973.9l.9 10.824H21.93l.9-10.824a.977.977 0 0 1 .975-.9zM21.93 37.463a.977.977 0 0 1-.977-.977.977.977 0 0 1 .977-.977.977.977 0 0 1 .977.977.977.977 0 0 1-.977.977zm9.766 0a.977.977 0 0 1-.977-.977.977.977 0 0 1 .977-.977.977.977 0 0 1 .977.977.977.977 0 0 1-.973.977zm-4.883.976a.977.977 0 0 1-.977-.977.977.977 0 0 1 .977-.977.977.977 0 0 1 .977.977.977.977 0 0 1-.977.977z"
      fill="#8f939d"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgAppCreatorChairIconInactive
