import * as React from 'react'
const SvgStuetzenActive = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 18.0029V23C14 23.5523 14.8954 24 16 24C17.1046 24 18 23.5523 18 23V18.0003L18.4774 18C22.8019 18.5598 26 20.551 26 22.9215C26 25.7263 21.5228 28 16 28C10.4772 28 6 25.7263 6 22.9215C6 20.5554 9.186 18.5673 13.4981 18.0032L14 18.0029Z" fill="url(#paint0_linear_12_4320)"/>
    <path d="M14 18.0029H14.5V17.5025L13.9997 17.5029L14 18.0029ZM18 18.0003L17.9997 17.5003L17.5 17.5006V18.0003H18ZM18.4774 18L18.5416 17.5041L18.5095 17.5L18.4771 17.5L18.4774 18ZM13.4981 18.0032L13.4978 17.5032L13.4654 17.5032L13.4333 17.5074L13.4981 18.0032ZM13.5 18.0029V23H14.5V18.0029H13.5ZM13.5 23C13.5 23.5676 13.9471 23.9468 14.3622 24.1543C14.8072 24.3768 15.3869 24.5 16 24.5V23.5C15.5085 23.5 15.0883 23.3993 14.8094 23.2599C14.6701 23.1902 14.5845 23.1202 14.5389 23.0663C14.5171 23.0405 14.5074 23.022 14.5034 23.0122C14.5014 23.0073 14.5006 23.0042 14.5003 23.0027C14.5 23.0013 14.5 23.0005 14.5 23H13.5ZM16 24.5C16.6131 24.5 17.1928 24.3768 17.6378 24.1543C18.0529 23.9468 18.5 23.5676 18.5 23H17.5C17.5 23.0005 17.5 23.0013 17.4997 23.0027C17.4994 23.0042 17.4986 23.0073 17.4966 23.0122C17.4926 23.022 17.4829 23.0405 17.4611 23.0663C17.4155 23.1202 17.3299 23.1902 17.1906 23.2599C16.9117 23.3993 16.4915 23.5 16 23.5V24.5ZM18.5 23V18.0003H17.5V23H18.5ZM18.4771 17.5L17.9997 17.5003L18.0003 18.5003L18.4777 18.5L18.4771 17.5ZM26.5 22.9215C26.5 21.4925 25.5385 20.2688 24.1314 19.3615C22.7132 18.447 20.7565 17.7908 18.5416 17.5041L18.4132 18.4959C20.5229 18.7689 22.3275 19.3882 23.5894 20.2019C24.8625 21.0228 25.5 21.9799 25.5 22.9215H26.5ZM16 28.5C18.8233 28.5 21.4041 27.9199 23.2975 26.9583C25.1631 26.0109 26.5 24.6112 26.5 22.9215H25.5C25.5 24.0365 24.5983 25.1761 22.8447 26.0667C21.1188 26.9432 18.6995 27.5 16 27.5V28.5ZM5.5 22.9215C5.5 24.6112 6.83688 26.0109 8.70253 26.9583C10.5959 27.9199 13.1767 28.5 16 28.5V27.5C13.3005 27.5 10.8812 26.9432 9.15534 26.0667C7.40169 25.1761 6.5 24.0365 6.5 22.9215H5.5ZM13.4333 17.5074C11.2247 17.7963 9.27415 18.4529 7.86058 19.3667C6.45795 20.2734 5.5 21.4952 5.5 22.9215H6.5C6.5 21.9817 7.13505 21.0264 8.40345 20.2065C9.66091 19.3937 11.4595 18.7741 13.563 18.499L13.4333 17.5074ZM13.9997 17.5029L13.4978 17.5032L13.4984 18.5032L14.0003 18.5029L13.9997 17.5029Z" fill="#DA5B2B"/>
    <path d="M14 6V23C14 23.5523 14.8954 24 16 24C17.1046 24 18 23.5523 18 23V6" stroke="#DA5B2B"/>
    <path d="M14 6V23C14 23.5523 14.8954 24 16 24C17.1046 24 18 23.5523 18 23V6" stroke="#DA5B2B"/>
    <ellipse cx="16" cy="6" rx="2" ry="1" stroke="#DA5B2B"/>
    <defs>
      <linearGradient id="paint0_linear_12_4320" x1="25.9997" y1="28.001" x2="25.9997" y2="18.001" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF8042"/>
        <stop offset="1" stop-color="#FF5E0F"/>
      </linearGradient>
    </defs>
  </svg>
)

export default SvgStuetzenActive