import { Geometry } from './Geometry'
import { representationTypes } from '../config/RepresentationTypes'
import { doorDecoGeometry, Face } from './FaceFactory'
import { Mesh, Vector3 } from 'three'
import { getMaterial } from '../common/Materials'
import { getVerticesByRadiusAndSegments, getVerticesByWidthAndHeight } from '../helper/GeometryHelper'
import { materialTypes } from '../common/MaterialTypes'
import { getBoundingBox, getBoundingBoxByRadius, getBoundingBoxRectangle } from '../common/BoundingBox'

export const stampActions = {
  drag: 'drag',
}

export class StampGeometry extends Geometry {

  vertex
  vertices

  constructor (group, representationType, config) {
    super(group, representationType, stampActions)

    this.properties = config ? config : getStampConfigByRepresentationType(representationType)
    this.representationType = representationType
    this.updateStamp()

    this.setActive(false)
    this.setAction(this.actions.idle)
  }

  updateStamp () {

    console.log("update stamp: "+this.representationType);
    console.log(this)

    let vertices
    const { width, height } = this.properties

    if (width && height) {
      vertices = getVerticesByWidthAndHeight(width, height)
    }

    const { radius, segments } = this.properties

    if (radius && segments) {
      vertices = getVerticesByRadiusAndSegments(radius, segments)
    }

    if (!vertices) {
      return
    }

    this.vertices = vertices

    if (this.face) {
      this.face.update(vertices)
    } else {
      this.face = new Face(this.representationType, vertices)
      this.add(this.face)
    }

    this.face.setActive(true)

    const { angle } = this.properties

    if (angle !== null && angle !== undefined) {
      this.setAngle(angle)
    }

    const { deco } = this.properties

    if (deco && deco.length) {
      this.setDecorationMeshes(deco)
    }
  }

  setAngle (angle) {
    if (this.face) {
      this.face.rotation.z = 0
      this.face.rotateZ(angle * (Math.PI / 180))
    }
  }

  updatePosition (position) {
    console.log("update Position")
    this.position.setX(position.x)
    this.position.setY(position.y)

    if (!this.vertexGroup.getVertices().length) {
      this.vertex = this.vertexGroup.addVertex(new Vector3())
    } else if (!this.vertex) {
      this.vertex = this.vertexGroup.getVertices()[0]
    }

    this.vertexGroup.updateVertex(this.vertex, position)
  }

  setDecorationMeshes (decorations) {
    decorations.forEach(deco => {
      const { geometry, material } = deco
      const mesh = new Mesh(geometry, material)

      this.face.add(mesh)
    })
  }

  moveDelta (movement) {
    if (movement.lengthSq() === 0)
      return

    this.updatePosition(new Vector3(this.position.x + movement.x, this.position.y + movement.y, movement.z))
  }

  setActive (active) {
    if (this.face) {
      this.face.setActive(active)

      this.face.children.forEach(deco => deco.visible = active)
    }

    this.setAction(active ? this.actions.select : this.actions.idle)
  }

  addSegment (segment) {
    if (this.properties.segments && this.properties.segments + segment > 2) {
      this.properties.segments += segment
      this.updateStamp()
    }
  }

  rotate (angle) {
    if (this.properties.angle !== null && this.properties.angle !== undefined) {
      this.properties.angle = (this.properties.angle + angle) % 360
      this.updateStamp()
    }
  }

  addRadius (radius) {
    if (this.properties.radius !== null && this.properties.radius !== undefined &&
      this.properties.radius + radius > .1) {
      this.properties.radius += radius
      this.updateStamp()
    }
  }

  getBoundingBox () {
    return getBoundingBoxByRadius(this.properties.radius)
      .map(vertex => vertex.add(this.position))
  }

  fromJSON (json) {
    super.fromJSON(json)
    if (json.vertices.length) {
      const vertex = json.vertices[0]
      this.position.set(vertex.x, vertex.y, vertex.z)
    }
  }

  toJSON () {
    const json = super.toJSON()
    const vertex = json.vertices[0]
    const { radius, segments, angle } = this.properties
    this.properties.vertices = getVerticesByRadiusAndSegments(radius, segments, angle, vertex)

    return json
  }

}

export function getStampConfigByRepresentationType (representationType) {
  switch (representationType) {
    case representationTypes.door:
      return {
        width: 0.9,
        height: 0.2,
        deco: [
          {
            geometry: doorDecoGeometry,
            material: getMaterial(materialTypes.black),
          }],
      }
    case representationTypes.window:
      return {
        width: 0.9,
        height: 0.2,
      }
    case representationTypes.pillar:
      return {
        radius: .5,
        segments: 6,
        angle: 90,
      }
    default:
      return {
        radius: 0.9,
        segments: 4,
        angle: 0,
      }

  }
}