import * as React from 'react'

function SvgUndoInactive (props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M9 4.786L4.286 9.5 9 14.214V10h5.5a4 4 0 01.2 7.995l-.2.005h-1a.5.5 0 00-.09.992l.09.008h1a5 5 0 00.217-9.995L14.5 9H9V4.786z"
        fill={props.color}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgUndoInactive
