import React, { Component } from 'react'
import ListMenuIconsItem from '../../../../common/components/ListMenuIconsItem'
import IconButton from '../../../../common/components/IconButton'
import { priorityValues } from '../../../constants'
import { iconTypes } from '../../../../common/components/icons/IconFactory'

class Connection extends Component {

  delayTimer

  constructor (props, context) {
    super(props, context)
    this.state = {
      currentPriority: this.props.connection.priority,
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.connection.priority !== prevProps.connection.priority) {
      this.setState({ currentPriority: this.props.connection.priority })
    }
  }

  render () {

    const priorityIcon = this.mapPriorityIconName()
    const connectionName = this.getConnectionName()
    const iconName = this.getIconName()

    return (
      <ListMenuIconsItem key={this.props.connection.id} icon={iconName} title={connectionName}>
        <IconButton icon={priorityIcon} onClick={() => this.handlePriorityClicked()}/>
        <IconButton icon={iconTypes.delete} onClick={() => this.props.onDeleteClicked(this.props.connection)}/>
      </ListMenuIconsItem>
    )
  }

  handlePriorityClicked () {
    const connection = this.props.connection
    let nextPriority = this.getNextPriority(this.state.currentPriority)

    this.setState({ currentPriority: nextPriority })

    this.delay(() => {
      this.props.onPriorityClicked({ id: connection.id, priority: nextPriority })
    })
  }

  mapPriorityIconName () {
    switch (this.state.currentPriority) {
      case priorityValues.HIGH:
        return 'high'
      case priorityValues.MIDDLE:
        return 'mid'
      case priorityValues.LOW:
        return 'low'
      default:
        return 'mid'
    }
  }

  getConnectionName () {
    const { currentDepartment, connection } = this.props

    if (connection.connectableIdA === currentDepartment) {
      return connection.connectableB ? connection.connectableB.displayName : ''
    } else {
      return connection.connectableA ? connection.connectableA.displayName : ''
    }

  }

  getIconName () {
    const { currentDepartment, connection } = this.props

    if (connection.connectableIdA === currentDepartment) {
      return connection.connectableB.zoneMarker ? connection.connectableB.icon : iconTypes.department
    } else {
      return connection.connectableA.zoneMarker ? connection.connectableA.icon : iconTypes.department
    }

  }

  getNextPriority (priority) {
    switch (priority) {
      case priorityValues.HIGH:
        return priorityValues.LOW
      case priorityValues.MIDDLE:
        return priorityValues.HIGH
      case priorityValues.LOW:
      default:
        return priorityValues.MIDDLE
    }
  }

  delay (action) {
    clearTimeout(this.delayTimer)
    this.delayTimer = setTimeout(() => action(), 1000)
  }
}

export default Connection