import React from 'react'

const SvgDrawNavbarCheck = props =>
  <svg
    viewBox="0 0 20 20"
    width={20} height={20}
    className={props.className}
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0z"
        fill="#FFF"
      />
      <path
        d="M12.48 5.898a.77.77 0 011.403.616l-.056.127-3.933 7.127a.77.77 0 01-1.07.288l-.116-.086-2.952-2.64a.77.77 0 01.915-1.23l.111.084 2.23 1.995 3.468-6.281z"
        fill="#FF8042"
      />
    </g>
  </svg>

export default SvgDrawNavbarCheck