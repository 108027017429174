import React from 'react'

const SvgDrawNavbarNumber6Focus = props =>
  <svg
    viewBox="0 0 20 20"
    width={20} height={20}
    className={props.className}
  >
    <g fill="none" fillRule="evenodd">
      <circle stroke="#FFF" strokeWidth={2} cx={10} cy={10} r={9}/>
      <path
        d="M10.051 14.048c1.704 0 2.856-1.032 2.856-2.616 0-1.476-1.008-2.508-2.652-2.508-.996 0-1.692.48-1.992 1.152.024-1.572.612-2.676 1.812-2.676.816 0 1.248.444 1.344 1.152l1.404-.036C12.691 7.052 11.66 6.2 10.1 6.2c-2.148 0-3.204 1.68-3.204 4.02 0 2.352 1.092 3.828 3.156 3.828zm-.024-1.188c-.9 0-1.452-.576-1.452-1.392 0-.852.528-1.404 1.44-1.404.924 0 1.452.552 1.452 1.404 0 .828-.528 1.392-1.44 1.392z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>

export default SvgDrawNavbarNumber6Focus