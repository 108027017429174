import React from 'react'
import CultureLens from './CultureLens'
import WorkStyleLens from './WorkStyleLens'

function TeamLensToolsFlyOut () {

  return (
    <React.Fragment>
      <CultureLens/>
      <WorkStyleLens/>
    </React.Fragment>
  )
}

export default TeamLensToolsFlyOut