import React from 'react'

import { uniqueId } from 'lodash'

import i18n from 'i18next'
import './SelectButton.scss'

const renderOptions = options => options.map(option => (
  <option key={option.value} value={option.value} disabled={option.disabled}>{option.label}</option>
))

let optionText = i18n.t('select_option')

function SelectButton ({ label, disabled, field, name, onChange, options = [], value = '', customSelectorText = i18n.t('select_option'), selectOption = false, forceUpdate = false }) {
  if (customSelectorText)
    optionText = customSelectorText
  const optionElements = renderOptions(selectOption ? [{ label: optionText, value: '', disabled: true }, ...(options)] : [...(options)])
  const nameInput = field ? field.name : name
  const id = uniqueId('select_')

  return (
    <React.Fragment>
      {label && <label className={'select-button-label'} htmlFor={id}>{label}</label>}
      {(field || forceUpdate) ?
        <select id={id}
                className={'select-button'}
                disabled={disabled}
                value={field ? field.value : value}
                name={nameInput}
                onChange={field ? field.onChange : onChange}
                onClick={(event) => event.stopPropagation()}>
          {optionElements}
        </select>
        :
        <select id={id}
                className={'select-button'}
                disabled={disabled}
                defaultValue={field ? field.value : value}
                name={nameInput}
                onChange={field ? field.onChange : onChange}
                onClick={(event) => event.stopPropagation()}>
          {optionElements}
        </select>}
    </React.Fragment>
  )
}

export default SelectButton